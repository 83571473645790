import React, { useState } from 'react'
// import { useHistory } from 'react-router-dom'
// import moment from 'moment'

// import { getComparator, stableSort } from '../../utils/tableFunctions'
import useTableMethods from '../../customHooks/useTableMethods'

//icons
import loader from '../../assets/loader.svg'
import financeLogo from '../../assets/finance-inactive.svg'

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  // Checkbox,
  Avatar,
} from '@material-ui/core'

//components
import TableHead from './CustomTableHead'
import { formatDate, formatTime } from '../../utils'

const TransactionHistory = ({
  data,
  busy,
  // searchResults = [],
  search,
  page,
  count = 0,
  rowsPerPage = 0,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
}) => {
  // const {
  //   // handleSelectAllClick,
  //   // handleClick,
  //   // handleChangePage,
  //   // handleChangeRowsPerPage,
  //   // isSelected,
  //   // emptyRows,
  //   // order,
  //   // orderBy,
  //   // selected,
  //   // page,
  //   // rowsPerPage,
  // } = useTableMethods(data)

  // const [busy, setBusy] = React.useState(false)
  // const [data, setData] = React.useState([])
  // const [count, setCount] = React.useState(0)
  // const [page, setPage] = React.useState(0)
  // const [rowsPerPage, setRowsPerPage] = React.useState(0)
  // const [dateRange, setDateRange] = React.useState({
  //   start: moment(),
  //   end: moment().add(2, 'M'),
  // })
  // const [activePeriod, setActivePeriod] = React.useState(0)

  // const fetchTransactionHistory = async () => {
  //   setBusy(true)
  //   try {
  //     const res = await searchRetailerTransactions({
  //       search,
  //       retailer: params.retailer,
  //       end_date: dateRange.end.format('YYYY-MM-DD'),
  //       start_date: dateRange.start.format('YYYY-MM-DD'),
  //     })
  //     setData(res.data.results)
  //     // if (res.data.count === 0) setTransactionsNotFound(true)
  //     setCount(res.data.count)
  //   } catch (error) {
  //     toast.error("Failed to fetch retailer's transaction history")
  //   } finally {
  //     setBusy(false)
  //   }
  // }

  // React.useEffect(fetchTransactionHistory, [activePeriod, search, dateRange])

  return (
    <>
      {/* <DateActions
        activePeriod={activePeriod}
        changePeriod={setActivePeriod}
        className="justify-end"
        dateRange={dateRange}
        onDateRangeSelect={(range) => setDateRange(range)}
      /> */}
      <div className="w-full mt-5">
        {busy || data.length === 0 ? (
          <>
            <Table className="min-w-[750px]" size="small">
              <TableHead
                // numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                rowCount={count}
              />
            </Table>
            <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
              {busy ? (
                <img src={loader} alt="loading" className="w-5 animate-spin" />
              ) : search.length > 0 && !count ? (
                <>
                  <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                    No Search Result for "{search}"
                  </p>
                  <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                    <span className="text-[#8892A2] text-[14px] font-bold">
                      Search tips:{' '}
                    </span>
                    Some search terms require an exact match. Try typing the
                    entire term, or use a different word or phrase.
                  </p>
                </>
              ) : (
                <>
                  <img src={financeLogo} alt="" className="w-[72px] h-[72px]" />
                  <p className="text-[18px] text-[#3D4356] font-bold mt-2">
                    Transactions will show here.
                  </p>
                </>
              )}
            </div>
          </>
        ) : (
          <Table className="min-w-[750px]" size="small">
            <TableHead
              // numSelected={selected.length}
              // onSelectAllClick={handleSelectAllClick}
              rowCount={count}
            />

            <TableBody className="bg-white">
              {data.map((row, index) => {
                // const isItemSelected = isSelected(index)

                return (
                  <TableRow
                    hover
                    // role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    // selected={isItemSelected}
                    className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                  >
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, index)}
                        />
                      </TableCell> */}
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.order?.order_id}
                    </TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <Avatar className="w-8 h-8" src={row.src} />
                      <p className="text-sm font-medium text-gray2">
                        {row.order?.user.profile.first_name}{' '}
                        {row.order?.user.profile.last_name}
                      </p>
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.order?.payment_date
                        ? formatDate(row.order.payment_date)
                        : '-'}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.order?.payment_date
                        ? formatTime(row.order.payment_date)
                        : '-'}
                    </TableCell>
                    <TableCell className="font-medium text-black" align="right">
                      ₦
                      {Number(row.order?.final_cost.amount).toLocaleString('en')}
                    </TableCell>
                  </TableRow>
                )
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(_, pg) => onPageChange(pg + 1)}
          onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
        />
      </div>
    </>
  )
}

export default TransactionHistory
