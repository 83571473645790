import React, { useEffect, useState } from 'react'

function PreparationCheckbox({ day, onChange, workingHours }) {
  //variables
  const [selected, setSelected] = useState(false)
  const [time, setTime] = useState({
    hours: null,
    mins: null,
  })

  //cleaning up data
  useEffect(() => {
    if (workingHours.length > 0) {
      setSelected(
        workingHours.find((time) => time.day === day)?.order_prep_time
          ? true
          : false,
      )

      const time = workingHours.find(
        (time) => time.day === day,
      )?.order_prep_time

      if (time) {
        const [hours, mins] = time.split(':').map(Number)

        setTime({
          hours,
          mins: String(mins).padStart(2, '0'),
        })
        onChange({
          hours,
          mins,
        })
      } else {
        setTime({
          hours: null,
          mins: null,
        })
        onChange({
          hours: null,
          mins: null,
        })
      }
    }
  }, [workingHours])

  //form management
  const onTimeChange = (e) => {
    if (selected) {
      setTime({ ...time, [e.target.name]: e.target.value })
      onChange({ ...time, [e.target.name]: e.target.value })
    }
  }

  const checkboxChange = () => {
    if (selected) {
      setTime({
        ...time,
        hours: null,
        mins: null,
      })
      onChange({
        ...time,
        hours: null,
        mins: null,
      })
    }
    setSelected(!selected)
  }

  const handleKeyPress = (e, max) => {
    const isNumberKey = /\d/.test(e.key)
    const inputValue = e.target.value + e.key

    const isValidInput =
      /^\d*$/.test(inputValue) && parseInt(inputValue, 10) <= max

    if (!isNumberKey || !isValidInput) {
      e.preventDefault()
    }
  }

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex gap-5 items-center">
        <input
          type="checkbox"
          className="accent-[#38C985] rounded-sm"
          checked={selected}
          value={selected}
          onChange={checkboxChange}
        />
        <span className="text-[#28283E] font-semibold text-md leading-none">
          {day}
        </span>
      </div>
      <div className="flex items-center h-10 ">
        <input
          type="number"
          name="hours"
          value={time.hours ?? '0'}
          onChange={onTimeChange}
          onKeyPress={(e) => handleKeyPress(e, 23)}
          className="w-12 px-3 bg-[#F4F5F7] text-right border-none outline-none rounded-tl-md rounded-bl-md text-gray1 font-medium"
        />
        <div
          className="w-10 h-10 font-semibold flex items-center justify-center px-2"
          style={{ background: '#4C536A1A', color: '#28283E99' }}
        >
          hrs
        </div>

        <input
          type="number"
          name="mins"
          value={time.mins ?? '00'}
          max={'59'}
          onChange={onTimeChange}
          onKeyPress={(e) => handleKeyPress(e, 59)}
          className="w-12 text-right px-3 bg-[#F4F5F7] border-none outline-none text-gray1 font-medium"
        />
        <div
          className="w-10 h-10 flex items-center justify-center rounded-tr-md rounded-br-md px-2 font-semibold"
          style={{ background: '#4C536A1A', color: '#28283E99' }}
        >
          mins
        </div>
      </div>
    </div>
  )
}

export default PreparationCheckbox
