import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";

//assets
import designIcon from "../../assets/design-inactive.svg";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import FormInput from "../../components/FormInput/FormInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import SaveModal from "../../components/SaveModal/SaveModal";
import CreateFAQCategoryModal from "../../components/CreateFAQCategoryModal/CreateFAQCategoryModal";
// import Editor from "../../components/TinyMCE"

import { getFaqCategories, createFaqCategory, createFaq } from '../../services/design'

const AddFAQ = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false)

  async function fetchCategories() {
    try {
      const data = await getFaqCategories({ page_size: 50 })
      setCategories(data.results)
    } catch {}
  }

  const clearForm = () => {
    setQuestion('');
    setCategory('');
    setAnswer('');
  }

  async function addNewFAQ() {
    try {
      toast.info('Adding FAQ...')
      setBusy(true)
      await createFaq({
        title: question,
        content: answer,
        category: category.id,
      })
      toast.success('FAQ added successfully')
      setShowSavedModal(true)
    } catch(e) {
      toast.error('Failed to add FAQ')

    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const disabled = !(category && question && answer)
  
  return (
    <Layout>
      <div className="w-[750px] mx-auto pb-[100px]">
        <PageTitle 
          onBackPress={() => history.goBack()}
          title="Add New FAQ"
        />
        <form
          autoComplete="off"
          className="bg-white p-[20px] shadow-sm"
          onSubmit={(e) => {
            e.preventDefault()
            addNewFAQ()
          }}
        >
          <CustomSelect 
            options={categories}
            newBtnLabel={'Add a New Category'}
            onNewClick={() => setShowAddModal(true)}
            name="FAQ input"
            id="FAQ"
            label="Category"
            value={category}
            subCategory={true}
            setValue={setCategory}
          />
          <div className="h-4"/>
          <FormInput 
            id="question"
            name="question"
            label="Question"
            placeholder="Question"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <div className="h-4"/>
          {/*<Editor value={answer} onChange={setAnswer}/>*/}
          <FormInput
            rows={4}
            id="answer"
            name="answer"
            label="Answer"
            placeholder="Answer"
            type="textarea"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </form>
        <div
            className="flex flex-row items-center justify-end space-x-4 mt-[20px]"
          >
            <Button 
              variant="outlined"
              className='capitalize bg-white text-[16px] text-[#4C536A] font-semibold'
            >Cancel</Button>
            <Button
              variant="contained"
              disableElevation
              className={`capitalize ${busy || disabled ? "bg-orange/20" : "bg-orange"} 
              text-white font-semibold text-[16px]`}
              disabled={disabled}
              onClick={addNewFAQ}
            >
              Save
            </Button>
          </div>
      </div>
      <CreateFAQCategoryModal 
        open={showAddModal}
        closeModal={(created) => {
          if (created) {
            setCategories([...categories, created])
          }
          setShowAddModal(false)
        }}
      />
      <SaveModal 
        isOpen={showSavedModal}
        close={() => setShowAddModal(false)}
        icon={designIcon}
        progressLabel={'Saving New FAQ'}
        successLabel="New FAQ"
        busy={busy}
        btnLabel='Add another FAQ'
        handleAddPress={() => {
          clearForm()
          setShowSavedModal(false)
        }}
      />
    </Layout>
  )
}

export default AddFAQ;
