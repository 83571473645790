import { Modal } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import { IoClose } from 'react-icons/io5'
import { toast } from 'react-toastify'

//mui
import { Button } from '@material-ui/core'

// //icons
// import loader from '../../assets/loader.svg'

//services
import { updateStockItem } from '../../services/stock'

//utils
// import { preventNonNumericalInput } from '../../utils'
import { RootContext } from '../..'
import InventoryInput from '../InventoryInput/InventoryInput'

const InventoryModal = ({ open, onClose, data }) => {
  const root = useContext(RootContext)
  //   const history = useHistory();
  //   const { product } = useParams();

  const [busy, setBusy] = useState(false)
  // const [disabled, setDisabled] = useState(true);
  const [stockItem, setStockItem] = useState(data)
  const [updateValue, setUpdateValue] = useState(0)
  const [updateMoq, setUpdateMoq] = useState(0)
  const [newPrice, setNewPrice] = useState('')
  const [newDiscountPercentage, setNewDiscountPercentage] = useState(0)
  const [newInitialPrice, setNewInitialPrice] = useState('')

  // const checkFormForEmptyFields = useCallback(() => {
  //   if (
  //     newInitialPrice === 0 ||
  //     newPrice === 0 ||
  //     updateValue === 0
  //   )
  //     setDisabled(true);
  //   else {
  //     setDisabled(false);
  //   }
  // }, [newInitialPrice, newPrice, newDiscountPercentage, updateValue]);

  // useEffect(() => {
  //   checkFormForEmptyFields();
  // }, [
  //   newInitialPrice,
  //   newPrice,
  //   newDiscountPercentage,
  //   updateValue,
  //   checkFormForEmptyFields,
  // ]);

  const handlePriceChange = (stateHook) => {
    return (event) => {
      if (
        event.target.value.length === 1 &&
        event.target.value.substring(0, 1) !== '₦'
      ) {
        stateHook(`₦${event.target.value}`)
        return
      }
      stateHook(`${event.target.value}`)
    }
  }

  const updateInventory = (data) => {
    const item = { ...stockItem }
    item.quantity = data.quantity
    if (data.initial_price !== null) {
      item.initial_price = { amount: data.initial_price }
    }
    item.selling_price.amount = data.selling_price
    item.percentage = data.percentage
    item.is_discounted = data.is_discounted
    item.free_delivery = data.free_delivery
    item.moq = data.moq
    setStockItem(item)
  }

  const setQuantity = (value, hook) => {
    if (value === '') {
      hook('')
      return
    }
    if (value.substring(0, 1) === '0') {
      if (value.substring(1).length > 0) hook(parseInt(value.substring(1)))
      else hook('')
    } else hook(parseInt(value))
  }

  const updateStockData = async (fieldData, callback = () => {}) => {
    setBusy(true)

    try {
      const { data } = await updateStockItem(stockItem.uuid, fieldData)
      updateInventory(data)
      callback()
      setBusy(false)
      toast.success('Your inventory has been updated.')
    } catch (err) {
      setBusy(false)
      toast.error('Unable to update inventory. Please try again later.')
    }
  }

  // const handleSubmit = async () => {
  //   setBusy(true);

  //   const updateData = {
  //     initial_price: newInitialPrice.substring(1),
  //     selling_price: newPrice.substring(1),
  //     quantity: updateValue + stockItem.quantity,
  //     percentage: newDiscountPercentage,
  //     is_discounted: newDiscountPercentage > 0 ? true : false,
  //   };

  //   try {
  //     const { data } = await updateStockItem(stockItem.uuid, updateData);
  //     updateInventory(data);
  //     toast.success("Your inventory has been updated.");
  //     setNewInitialPrice(0);
  //     setNewPrice(0);
  //     setUpdateValue(0);
  //     setNewDiscountPercentage(0);
  //   } catch (error) {
  //     toast.error("Unable to update inventory. Please try again later.");
  //   } finally {
  //     setBusy(false);
  //   }
  // };

  return (
    <Modal open={open}>
      <div className="flex items-center justify-center w-full h-full">
        <div className="bg-white rounded-md w-[836px]">
          <div className="flex relative items-center justify-center py-4 border-b-2 border-gray5">
            <p className="text-xl text-center font-bold mb-0">
              Update Product Pricing
            </p>
            <button className="absolute ml-10 top-4 right-10" onClick={onClose}>
              <IoClose size={30} color={'#8892A2'} />
            </button>
          </div>

          <div className="flex items-start p-5 px-7">
            <div className="mr-10 space-y-3 w-[264px]">
              <img
                src={stockItem?.product?.image}
                alt=""
                className="w-full h-full"
              />
            </div>

            <div className="flex-grow space-y-3 ">
              <div>
                <h6 className="text-sm capitalize text-gray3">Product Name</h6>
                <p className="font-bold text-[18px] text-gray1">
                  {stockItem.product.name}
                </p>
              </div>

              <InventoryInput
                title="Initial Price"
                subtitle="Update Initial Price"
                field={stockItem.initial_price?.amount}
                value={newInitialPrice}
                hook={setNewInitialPrice}
                busy={busy}
                name="initial_price"
                placeholder="₦"
                type="price"
                width="100px"
                marginX="mr-2"
                margin="mr-2"
                padding="p-1"
                handleChange={handlePriceChange}
                handleSubmit={() =>
                  updateStockData(
                    {
                      initial_price: newInitialPrice.substring(1),
                      percentage:
                        Number(newInitialPrice.substring(1)) <=
                        Number(stockItem.selling_price.amount)
                          ? 0
                          : (
                              ((Number(newInitialPrice.substring(1)) -
                                Number(stockItem.selling_price.amount)) /
                                Number(newInitialPrice.substring(1))) *
                              100
                            ).toFixed(2),
                      is_discounted:
                        Number(newInitialPrice.substring(1)) >
                        Number(stockItem.selling_price.amount)
                          ? true
                          : false,
                    },
                    () => setNewInitialPrice(),
                  )
                }
                disabled={Boolean(!newInitialPrice)}
              />

              <InventoryInput
                title="Current Price"
                subtitle="Update Current Price"
                field={stockItem.selling_price?.amount}
                value={newPrice}
                hook={setNewPrice}
                placeholder="₦"
                busy={busy}
                name="selling_price"
                type="price"
                width="100px"
                marginX="mr-2"
                margin="mr-2"
                padding="p-1"
                handleChange={handlePriceChange}
                handleSubmit={() =>
                  updateStockData(
                    {
                      selling_price: newPrice.substring(1),
                      percentage:
                        stockItem.initial_price === null ||
                        Number(newPrice.substring(1)) >=
                          Number(stockItem.initial_price.amount)
                          ? 0
                          : (
                              ((Number(stockItem.initial_price.amount) -
                                Number(newPrice.substring(1))) /
                                Number(stockItem.initial_price.amount)) *
                              100
                            ).toFixed(2),
                      is_discounted:
                        Number(newPrice.substring(1)) <
                          Number(stockItem.selling_price.amount) &&
                        stockItem.initial_price !== null
                          ? true
                          : false,
                    },
                    () => setNewPrice(),
                  )
                }
                disabled={Boolean(!newPrice)}
              />

              <InventoryInput
                title="Available Stock"
                subtitle="Update Stock"
                field={stockItem.quantity}
                value={updateValue}
                hook={setUpdateValue}
                busy={busy}
                min={-stockItem.quantity}
                type="quantity"
                width="100px"
                padding="p-1"
                margin="mr-2"
                justify="justify-between"
                pdL="px-0"
                handleChange={setQuantity}
                handleSubmit={() =>
                  updateStockData(
                    { quantity: updateValue + stockItem.quantity },
                    () => {
                      setUpdateValue(0)
                    },
                  )
                }
                disabled={updateValue === 0}
              />

              <InventoryInput
                title="Discount (%)"
                subtitle="Update Discount (%)"
                field={stockItem.percentage}
                value={newDiscountPercentage}
                hook={setNewDiscountPercentage}
                busy={busy}
                width="100px"
                margin="mr-2"
                marginX="mr-2"
                padding="p-1"
                type="percentage"
                pdX="px-0"
                handleSubmit={() =>
                  updateStockData(
                    {
                      initial_price: (
                        Number(stockItem.selling_price.amount) /
                        (1 - Number(newDiscountPercentage) / 100)
                      ).toFixed(2),
                      percentage: newDiscountPercentage,
                      is_discounted: newDiscountPercentage > 0 ? true : false,
                    },
                    () => {
                      setNewDiscountPercentage(0)
                    },
                  )
                }
                disabled={
                  Boolean(newDiscountPercentage < 0) ||
                  Number(newDiscountPercentage) === Number(stockItem.percentage)
                }
              />

              <InventoryInput
                title="MOQ"
                subtitle="Update MOQ"
                field={stockItem.moq}
                value={updateMoq}
                hook={setUpdateMoq}
                busy={busy}
                width="100px"
                min={-stockItem.moq + 1}
                max={stockItem.quantity - stockItem.moq}
                margin="mr-2"
                name="moq"
                type="quantity"
                padding="p-1"
                justify="justify-between"
                pdL="px-0"
                handleChange={setQuantity}
                handleSubmit={() =>
                  updateStockData({ moq: updateMoq + stockItem.moq }, () => {
                    setUpdateMoq(0)
                  })
                }
                disabled={updateMoq === 0}
              />

              <div className="flex justify-end items-center space-x-3 py-5">
                <Button
                  variant="outlined"
                  className="capitalize"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                {/* <Button
                  variant="contained"
                  className={`text-white capitalize shadow-none
                ${
                  disabled
                    ? "bg-orange/30 cursor-not-allowed"
                    : "bg-orange cursor-pointer"
                }`}
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  Save
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default InventoryModal
