import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Popover, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import {Calendar, utils} from "react-modern-calendar-datepicker";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import FormInput from "../../components/FormInput/FormInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import SaveModal from "../../components/SaveModal/SaveModal";

//assets
import couponIcon from "../../assets/coupons-inactive.svg";

//services
import { createCoupon } from "../../services/coupons";

const defaultCouponData = {
  code: "",
  value: "",
  description: "",
  coupon_type: "",
  usage: 0,
  expiry_date: "",
  limit: "",
  quantity: 0
}
function AddCoupon() {
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [couponData, setCouponData] = useState(defaultCouponData)
  const [expiry, setExpiry] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const updateCouponData = obj => setCouponData({...couponData, ...obj})

  const handleSubmit = async() => {
    setBusy(true);
    setShowSaveModal(true);
    try {
      await createCoupon({
        ...couponData,
        expiry_date: new Date(expiry),
      });
      setBusy(false);
    } catch (error) {
      setShowSaveModal(false);
      setBusy(false)
      toast.error("An error occurred. Unable to add new coupon code.");
    }
  }

  const disabled = !(
    couponData.code
    && couponData.description
    && couponData.expiry_date
    && couponData.coupon_type
    && couponData.quantity
    && couponData.limit
    && couponData.coupon_type
  )

  return(
    <Layout>
      <div className="page-padding">
        <div className="w-[730px] mx-auto">
          <PageTitle 
            title="Create New Coupon Code"
            onBackPress={() => history.goBack()}
          />
        </div>
        <div className="w-[730px] flex flex-col mx-auto">
          <div className="w-full bg-white rounded-md p-[30px] flex flex-col shadow-md">
            <p className="text-[#3D4356] text-[16px] mb-3">Details</p>
            <FormInput 
              id="coupon-name"
              name="coupon-name"
              placeholder="Coupon Name"
              label="Coupon Name"
              required
              value={couponData.code}
              onChange={e => updateCouponData({ code: e.target.value })}
            />
            <div className="h-6"/>
            <FormInput
              id="coupon-description"
              name="coupon-description"
              placeholder="Coupon Description"
              label="Coupon Description"
              required
              value={couponData.description}
              onChange={e => updateCouponData({ description: e.target.value })}
            />
            <div className="h-6"/>
            <FormInput 
              id="expiry-date"
              name="expiry-date"
              placeholder="Expiry Date"
              label="Expiry Date"
              value={expiry}
              onChange={e => setExpiry(e.target.value)}
              onFocus={(e) => setAnchorEl(e.currentTarget)}
            />
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              disableRestoreFocus
            >
              <Calendar
                value={couponData.date}
                onChange={date => {
                  updateCouponData({ expiry_date: date })
                  setExpiry(`${date.year}-${`${date.month}`.padStart(2, '0')}-${date.day}`)
                  setAnchorEl(null)
                }}
                shouldHighlightWeekends={false}
                colorPrimary="#F3641F"
                colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                minimumDate={utils().getToday()}
                selectorStartingYear={new Date().getFullYear()}
              />
            </Popover>
          </div>
          <div
            className="w-full bg-white rounded-md p-[30px] flex flex-col mt-4 shadow-md"
          >
            <p className="text-[#3D4356] text-[16px] mb-3">Value</p>
            <form
              autoComplete="off"
              className="grid items-center grid-cols-2 space-x-4"
            >
              <CustomSelect 
                label="Coupon Type"
                placeholder="Coupon Type"
                name="counpon-type"
                id="coupon-type"
                options={['Fixed amount', 'Percent amount']}
                value={couponData.coupon_type}
                setValue={coupon_type => updateCouponData({ coupon_type })}
              />
              <FormInput 
                id="coupon-value"
                name="coupon-value"
                placeholder="Coupon Value"
                label="Coupon Value"
                value={couponData.value}
                onChange={e => updateCouponData({ value: e.target.value })}
              />
            </form>
          </div>
          <div
            className="w-full bg-white rounded-md p-[30px] flex flex-col mt-4 shadow-md"
          >
            <p className="text-[#3D4356] text-[16px] mb-3">Quantity / Usage</p>
            <form className="grid grid-cols-3 gap-4" autoComplete="off">
              <FormInput 
                id="quantity-value"
                type="number"
                name="quantity-value"
                placeholder="Quantity"
                label="Quantity"
                value={couponData.quantity}
                onChange={e => updateCouponData({ quantity: e.target.value })}
              />
              <CustomSelect 
                label="Limit"
                name="limit"
                id="limit"
                placeholder="Select a limit"
                options={['Daily', 'Weekly', 'Monthly']}
                value={couponData.limit}
                setValue={limit => updateCouponData({ limit })}
              />
              <FormInput 
                id="usage"
                type="number"
                name="usage"
                placeholder="Usage"
                label="Usage"
                value={couponData.usage}
                onChange={e => updateCouponData({ usage: e.target.value })}
              />
            </form>
          </div>
          <div
            className="flex flex-row items-center justify-end space-x-4 mt-[30px]"
          >
            <Button 
              variant="outlined"
              className='capitalize bg-white text-[16px] text-[#4C536A] font-semibold'
              onClick={() => history.goBack()}
            >Cancel</Button>
            <Button
              variant="contained"
              disableElevation
              className={`capitalize ${busy || disabled ? "bg-orange/20" : "bg-orange"}
              text-white font-semibold text-[16px]`}
              disabled={disabled}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <SaveModal 
        isOpen={showSaveModal}
        close={() => setShowSaveModal(false)}
        icon={couponIcon}
        progressLabel="Saving New Coupon Code"
        successLabel="New Coupon Code"
        btnLabel="Add Another Coupon"
        handleAddPress={() => {
          setShowSaveModal(false);
          setCouponData(defaultCouponData);
        }}
        busy={busy}
      />
    </Layout>
  );
}

export default AddCoupon;
