import React, { useState, useContext, createRef, useEffect } from 'react'
import { IoClose } from 'react-icons/io5'
import checkStrength from '../../utils/checkPasswordStrength'
import { preventNonNumericalInput } from '../../utils'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'

//mui
import {
  Button,
  Avatar,
  Dialog,
  IconButton,
  LinearProgress,
} from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import CustomizedSwitches from '../../components/CustomSwitch/CustomSwitch'
import FormInput from '../../components/FormInput/FormInput'
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert'
import { RootContext } from '../..'
import { toast } from 'react-toastify'
import { updateProfile } from '../../services/users'
import { updateRetailer } from '../../services/retailers'

//assets
import loader from '../../assets/loader.svg'
import { setNewPassword } from '../../services/auth'
import pen from '../../assets/pen.svg'

const Index = observer(() => {
  const history = useHistory()
  const root = useContext(RootContext)
  const { allow_delivery, allow_pickup, delivery_price } = root.authStore.user
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [file, setFile] = useState()
  const [busy, setBusy] = useState(false)
  const [deliveryPrice, setDeliveryPrice] = useState(delivery_price)

  const fileInput = createRef()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [reset, setReset] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  const [error] = useState('')

  const [strength, setStrength] = useState('')
  const [progress, setProgess] = useState(0)

  const handleChange = (e) => {
    const { name, value } = e.target
    const result = checkStrength(reset.newPassword)

    if (name === 'newPassword') {
      if (value === '') {
        setProgess(0)
        setStrength('')
      } else {
        setStrength(result.strength)
        setProgess(result.progress)
      }
    }

    setReset({
      ...reset,
      [name]: value,
    })
  }

  const openFileDialog = () => {
    fileInput.current.click()
  }

  const handleFileSelect = (e) => {
    let files = e.target.files
    handleFile(files)
  }

  const handleFile = (files) => {
    setFile(files[0])
  }

  const clearForm = () => {
    setReset({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    })
  }

  const saveProfile = async () => {
    setBusy(true)
    const form = new FormData()
    form.append('picture', file)
    try {
      const { data } = await updateProfile(
        form,
        root.authStore.user.profile.uuid,
      )
      root.authStore.updateProfilePicture(data.picture)
      setFile(null)
      toast.success('Profile updated successfully')
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to save profile image.')
    }
  }

  const updateDelivery = async (name, value) => {
    setBusy(true)
    toast.info('Updating delivery option...')
    try {
      await updateRetailer(root.authStore.user.retailerUuid, {
        [name]: value,
      })
      root.authStore.updateDeliveryProperty(name, value)
      toast.success('Delivery option updated successfully')
    } catch {
      toast.error('An error occurred. Unable to update delivery option.')
    } finally {
      setBusy(false)
    }
  }

  const submitNewPassword = async () => {
    setBusy(true)
    const form = {
      new_password: reset.newPassword,
      current_password: reset.oldPassword,
    }
    try {
      await setNewPassword(form)
      toast.success('New password set successfully')
      root.authStore.signOut()
      history.push('/login')
      setBusy(false)
      clearForm()
      setOpen(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to update password.')
    }
  }

  const checkPasswordForm = () => {
    if (
      reset.oldPassword.length > 0 &&
      reset.newPassword.length > 0 &&
      reset.confirmNewPassword.length > 0
    ) {
      if (reset.newPassword === reset.confirmNewPassword) setDisabled(false)
    } else setDisabled(true)
  }

  useEffect(() => {
    checkPasswordForm()
  }, [reset])

  return (
    <Layout>
      <div className="flex flex-col items-center py-10 space-y-5 ">
        <div className="w-[80%]">
          <h1 className="font-bold text-[30px] text-gray1">Profile Settings</h1>
        </div>

        <div className="w-[80%] bg-white p-10 rounded-md space-y-4">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-gray1 text-[20px] capitalize">
              Personal Information
            </h1>
            <Button
              variant="contained"
              className={`text-white capitalize shadow-none ${
                busy ? 'bg-orange/20' : 'bg-orange'
              } relative`}
              hidden={!file}
              onClick={saveProfile}
            >
              Save Image
              {busy && (
                <img
                  src={loader}
                  className="absolute right-0 w-5 animate-spin"
                  alt=""
                />
              )}
            </Button>
          </div>

          <div className="grid grid-cols-[1fr,4fr] gap-5">
            <div className="relative w-40 h-40">
              <Avatar
                className="w-full h-full"
                src={
                  file
                    ? URL.createObjectURL(file)
                    : root.authStore.user.profile.picture
                }
              ></Avatar>
              <button
                className="absolute px-2 py-2 rounded bottom-2 bg-orange right-2"
                onClick={openFileDialog}
              >
                <img src={pen} alt="" />
              </button>
              <input
                type="file"
                hidden
                ref={fileInput}
                accept=".png,.jpeg,.jpg"
                onChange={handleFileSelect}
              />
            </div>

            <div className="flex flex-col justify-between py-5">
              <div className="flex items-center divide-x ">
                <div className="px-12 capitalize ">
                  <h1 className="text-sm text-gray3">First Name</h1>

                  <p className="text-gray1">
                    {root.authStore.user.profile.first_name || 'no first name'}
                  </p>
                </div>

                <div className="px-12 capitalize">
                  <h1 className="text-sm text-gray3">Last Name</h1>

                  <p className="text-gray1">
                    {root.authStore.user.profile.last_name || 'no last name'}
                  </p>
                </div>

                <div className="px-12 ">
                  <h1 className="text-sm capitalize text-gray3">
                    Account Type
                  </h1>

                  <p className="text-gray1">
                    {root.authStore.user.account_type.includes('Admin')
                      ? 'Administrator'
                      : root.authStore.user.account_type}
                  </p>
                </div>
              </div>

              <div className="flex items-center divide-x ">
                <div className="px-12 ">
                  <h1 className="text-sm capitalize text-gray3">
                    Email Address
                  </h1>

                  <p className="text-blue">{root.authStore.user.email}</p>
                </div>

                <div className="px-12 ">
                  <h1 className="text-sm capitalize text-gray3">
                    Phone Number
                  </h1>

                  <p className="capitalize text-blue">
                    {root.authStore.user.phone_number || 'no number'}
                  </p>
                </div>

                <div className="px-12 ">
                  <h1 className="text-sm capitalize text-gray3">Password</h1>

                  <Button
                    className="flex justify-start p-0 font-bold underline capitalize cursor-pointer hover:bg-transparent text-orange"
                    disableRipple
                    onClick={handleOpen}
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[80%] bg-white p-10 rounded-md space-y-4">
          <div className="">
            <h1 className="font-semibold text-gray1 text-[20px] capitalize">
              Email and Notification Settings
            </h1>
            <p className="text-sm text-gray2">
              Choose what messages you’d like to receive for your Drinks
              accounts.
            </p>
          </div>

          <div className="border-t divide-y">
            {/* <div className="flex items-center py-4">
              <div className="flex-grow">
                <h1 className="font-semibold capitalize text-gray1">
                  Successful payments
                </h1>
                <p className="text-sm text-gray2">
                  Receive a notification for every successful payment.
                </p>
              </div>
              <CustomizedSwitches />
            </div> */}

            {root.authStore.user.account_type.includes('Retailer') && (
              <div>
                <section className="flex items-center pt-4 pb-2">
                  <div className="flex-grow">
                    <h3 className="font-semibold capitalize text-gray1">
                      New orders
                    </h3>
                    <p className="text-sm text-gray2">
                      Receive a notification for every order made.
                    </p>
                  </div>
                  <CustomizedSwitches checked />
                </section>

                <section className="pt-4 pb-2">
                  <h3 className="font-semibold capitalize text-gray1">
                    Delivery Options
                  </h3>
                  <div className="flex items-center justify-between mb-4">
                    <p className="text-sm mb-0 text-gray2">Pickup</p>
                    <CustomizedSwitches
                      checked={allow_pickup}
                      className="p-0"
                      onToggle={(bool) => updateDelivery('allow_pickup', bool)}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-sm mb-0 text-gray2">Delivery</p>
                    <CustomizedSwitches
                      checked={allow_delivery}
                      className="p-0"
                      onToggle={(bool) =>
                        updateDelivery('allow_delivery', bool)
                      }
                    />
                  </div>
                  <div className="flex items-center mt-3">
                    <div
                      className="bg-[#F4F5F7] rounded-md flex items-center px-2 mr-2"
                      style={{ width: 'fit-content' }}
                    >
                      <span>₦</span>
                      <input
                        type="text"
                        placeholder="0.00"
                        value={deliveryPrice}
                        className="h-full min-w-0 bg-transparent border-0"
                        onKeyPress={preventNonNumericalInput}
                        onChange={(e) => setDeliveryPrice(+e.target.value)}
                      />
                    </div>
                    <Button
                      variant="contained"
                      className={`relative text-white capitalize shadow-none m-0 ${
                        !allow_delivery ||
                        deliveryPrice === delivery_price ||
                        busy
                          ? 'bg-orange/30 cursor-not-allowed'
                          : 'bg-orange cursor-pointer'
                      }`}
                      disabled={
                        !allow_delivery ||
                        deliveryPrice === delivery_price ||
                        busy
                      }
                      onClick={() =>
                        updateDelivery('delivery_price', deliveryPrice)
                      }
                    >
                      Save
                      {busy && (
                        <img
                          src={loader}
                          alt=""
                          className="absolute right-0 w-5 animate-spin"
                        />
                      )}
                    </Button>
                  </div>
                </section>
                {/* <section className="flex items-center pt-4 pb-2">
                  <div className="flex-grow">
                    <h3 className="font-semibold capitalize text-gray1">
                      Store
                    </h3>
                    <p className="text-sm text-gray2">Close / Open Store</p>
                  </div>
                  <CustomizedSwitches checked />
                </section> */}
              </div>
            )}

            {/* <section className="flex items-center pt-4 pb-2">
              <div className="flex-grow">
                <h3 className="font-semibold capitalize text-gray1">Store</h3>
                <p className="text-sm text-gray2">Close / Open Store</p>
              </div>
              <CustomizedSwitches checked />
            </section> */}

            {/* <div className="flex items-center py-4">
              <div className="flex-grow">
                <h1 className="font-semibold capitalize text-gray1">
                  New customers
                </h1>
                <p className="text-sm text-gray2">
                  Receive a notification when a customers are added.
                </p>
              </div>
              <CustomizedSwitches />
            </div> */}

            {root.authStore.user.account_type.includes('Admin') && (
              <div className="flex items-center py-4">
                <div className="flex-grow">
                  <h1 className="font-semibold capitalize text-gray1">
                    New Retailers
                  </h1>
                  <p className="text-sm text-gray2">
                    Receive a notification when a retailers are added.
                  </p>
                </div>
                <CustomizedSwitches checked />
              </div>
            )}
          </div>
        </div>

        {/* <div className="w-[80%] bg-white p-10 rounded-md space-y-4">
          <div className="">
            <h1 className="font-semibold text-gray1 text-[20px] capitalize">
              Login sessions
            </h1>
            <p className="text-sm text-gray2">
              Places where you logged into Drinks NG
            </p>
          </div>

          <div className="grid grid-cols-4">
            <h1 className="text-[12px] uppercase text-gray2 font-semibold pl-5">
              Location
            </h1>

            <h1 className="text-[12px] uppercase text-gray2 font-semibold">
              device
            </h1>

            <h1 className="text-[12px] uppercase text-gray2 font-semibold">
              ip address
            </h1>

            <h1 className="text-[12px] uppercase text-gray2 font-semibold">
              time
            </h1>
          </div>

          <div className="border divide-y rounded-md">
            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>

            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>

            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>

            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>
          </div>
        </div>*/}
      </div>

      <Dialog open={open}>
        <div className="bg-white divide-y w-96">
          <div className="relative flex justify-center py-2 ">
            <p className="font-bold text-[20px] text-[#040A1D]">
              Change Password
            </p>

            <IconButton
              className="absolute p-0 top-2 right-5"
              onClick={handleClose}
            >
              <IoClose className="w-7 h-7 text-gray2" />
            </IconButton>
          </div>

          <form className="p-5 space-y-2">
            <div>
              <div className="space-y-5">
                <FormInput
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  placeholder="Old Password"
                  label="Old Password"
                  onChange={handleChange}
                  value={reset.oldPassword}
                />
                <FormInput
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  label="New Password"
                  onChange={handleChange}
                  value={reset.newPassword}
                />
              </div>

              <LinearProgress
                variant="determinate"
                value={progress}
                classes={{
                  root: 'bg-[#E3E7ED] mt-px',
                  bar: 'bg-[#38C985]',
                }}
              />

              <div className="flex items-center justify-center text-[12px] text-gray-300">
                Password strength:
                <span className="pl-[5px] font-semibold text-gray1">
                  {strength}
                </span>
              </div>
            </div>

            <ul className="text-[12px] text-gray2  w-[80%]">
              <li className="flex space-x-1">
                <span>&#8226;</span>
                <p>Use at least 8 characters.</p>
              </li>
              <li className="flex space-x-1">
                <span>&#8226;</span>
                <p className="">
                  Besides letters, include at least a number or symbol
                  (!@#$%^&*-_=+).
                </p>
              </li>
              <li className="flex space-x-1">
                <span>&#8226;</span>
                <p>Password is case sensitive.</p>
              </li>
            </ul>

            <FormInput
              id="confirmNewPassword"
              name="confirmNewPassword"
              type="password"
              placeholder="Confirm Password"
              label="Confirm Password"
              onChange={handleChange}
              value={reset.confirmNewPassword}
              error={error}
              color={strength === 'Strong'}
            />

            {error && <ErrorAlert error={error} />}

            <div className="flex items-center justify-end py-2 space-x-3">
              <Button
                variant="outlined"
                className="capitalize shadow-none"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                className={`relative text-white capitalize shadow-none ${
                  disabled || (busy && open)
                    ? 'bg-orange/30 cursor-not-allowed'
                    : 'bg-orange cursor-pointer'
                }`}
                disabled={disabled}
                onClick={submitNewPassword}
              >
                Continue
                {busy && open && (
                  <img
                    src={loader}
                    alt=""
                    className="absolute right-0 w-5 animate-spin"
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </Layout>
  )
})

export default Index
