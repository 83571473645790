import React, { useState, useEffect } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//mui
import { IconButton } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import Loader from "@components/Loader/Loader";
import UpdateProductInfo from "../../components/UpdateProductInfo/UpdateProductInfo";

//services
import { getProduct } from "../../services/products";

const ProductRequestEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [busy, setBusy] = useState(true);
  const [product, setProduct] = useState(null);

  const fetchProduct = async() => {
    try {
      const { data } = await getProduct(id);
      setProduct(data);
      setBusy(false);
    } catch (error) {
      toast.error('Unable to fetch requested product. Please try again later.');
      history.goBack();
    }
  }

  useEffect(() => {
    fetchProduct();
  }, [])

  return (
    <Layout>
      {busy ? (
        <Loader />
      ):(
        <div className="flex flex-col items-center py-10 pb-40 space-y-5">
          <div className="w-[70%] flex items-center space-x-3">
            <IconButton
              onClick={() => history.goBack()}
              disableRipple
              className="p-0 hover:bg-transparent"
            >
              <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
            </IconButton>
            <h1 className="font-bold text-[30px] text-gray1">
              Update Product Info
            </h1>
          </div>
          <UpdateProductInfo product={product}/>
        </div>
      )}
    </Layout>
  );
};

export default ProductRequestEdit;
