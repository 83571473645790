import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import TableActions from '../../components/TableActions/TableActions'
import EmptyTableView from '../../components/EmptyTableVIew/EmptyTableView'
import CustomersTable from '../../components/CustomersTable/CustomersTable'
import Loader from '@components/Loader/Loader'

//assets
import plus from '../../assets/plus.svg'
import customerIcon from '../../assets/customers-active.svg'
import { RootContext } from '../..'

//services
import { getRetailerCustomers, getAllCustomers } from '../../services/users'
import { exportData } from '../../utils'

const Index = observer(() => {
  const [busy, setBusy] = useState(true)
  const [search, setSearch] = useState('')
  const [notFound, setNotFound] = useState(false)
  const [activeTab, setActiveTab] = useState('all')
  const [customers, setCustomers] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [controller, setController] = useState(null)

  const root = useContext(RootContext)
  const history = useHistory()

  const getCustomers = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)

    try {
      let data
      const params = {
        search,
        page: currentPage,
        page_size: rowsPerPage,
      }

      const { retailerUuid } = root.authStore.user || {}
      if (retailerUuid) {
        const res = await getRetailerCustomers(
          {
            ...params,
            retailer_uuid: retailerUuid,
          },
          abc.signal,
        )
        data = res.data
      } else {
        const res = await getAllCustomers(params, abc.signal)
        data = res.data
      }

      setCustomers(data.results)
      setCount(data.count)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch customers. Please try again later.')
        setBusy(false)
      }
    }
  }

  const openAddCustomer = () => {
    history.push('/customers/add-customer')
  }

  const openCustomerDetail = (id) => {
    history.push(`/customers/customer-details/${id}`)
  }

  const openUpdateCustomer = (id) => {
    history.push(`/customers/update-customer/${id}`)
  }

  const sanitizeData = async () => {
    toast.info('Fetching data to export')

    try {
      let results

      const { retailerUuid } = root.authStore.user || {}
      if (retailerUuid) {
        const { data } = await getRetailerCustomers({
          search,
          retailer_uuid: retailerUuid,
          paginate: 'no',
        })
        results = data
      } else {
        const { data } = await getAllCustomers({ search, paginate: 'no' })
        results = data
      }

      exportData(
        results.map(({ profile, ...person }) => ({
          Name: [profile.first_name, profile.last_name]
            .filter(Boolean)
            .join(' '),
          Email: person.email || '',
          'Phone Number': person.phone_number || '',
        })),
        'Customers',
      )

      toast.success('Customers exported successfully.')
    } catch (e) {
      toast.error('Unable to export requested data. Please try again later')
    }
  }

  useEffect(() => {
    getCustomers()
  }, [
    search,
    currentPage,
    rowsPerPage,
    activeTab,
    // root.authStore.user.retailerUuid
  ])

  return (
    <Layout>
      <div className="px-[30px] pt-5 font-bold">
        <PageTitle title="Customers" />
        <TableActions
          activeTab={activeTab}
          changeTab={(tab) => {
            setCurrentPage(1)
            setActiveTab(tab)
          }}
          onSearchChange={(e) => {
            setCurrentPage(1)
            setSearch(e.target.value)
          }}
          searchPlaceholder="Search customers"
          tabLabels={['all']}
          exportData={sanitizeData}
        />
        {!!count ? (
          <div className="flex flex-row justify-end w-full mt-4">
            <Button
              variant="contained"
              color="primary"
              className="capitalize shadow-none"
              startIcon={<img src={plus} alt="plus" />}
              onClick={openAddCustomer}
            >
              Add New Customer
            </Button>
          </div>
        ) : null}
        {busy ? (
          <Loader />
        ) : !count ? (
          <EmptyTableView
            title={'Your customers will show here'}
            subtitle={"This is where you'll find info on your customers"}
            btnLabel="Add New Customer"
            icon={customerIcon}
            onClick={openAddCustomer}
          />
        ) : (
          <CustomersTable
            rows={customers}
            onViewClick={openCustomerDetail}
            onEditClick={openUpdateCustomer}
            search={search}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            busy={busy}
            notFound={notFound}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(rows) => {
              setCurrentPage(1)
              setRowsPerPage(rows)
            }}
          />
        )}
      </div>
    </Layout>
  )
})

export default Index
