import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: "#FAFAFA",
    titleFontColor: "#040A1D",
    bodyFontColor: "#477DFB",
    usePointStyle: true,
    callbacks: {
      title: function (tooltipItem, data) {
        return `₦${Number(
          data["datasets"][0]["data"][tooltipItem[0]["index"]]
        ).toLocaleString("en")}`;
      },
      label: function (tooltipItem, data) {
        return data["labels"][tooltipItem["index"]];
      },
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          fontColor: ["#9CA7B8"],
          beginAtZero: false,
          autoSkip: false,
        },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
};

const OrderAnalytics = ({ data = [], busy = false }) => {
  const [completed, setCompleted] = useState({count: 0, amount: 0});
  const [pending, setPending] = useState({count: 0, amount: 0});
  const [cancelled, setCancelled] = useState({count: 0, amount: 0});
  const [completedGraph, setCompletedGraph] = useState(
    {
      labels: [
        "12:00am", "1:00am", "2:00am", "3:00am", "4:00am", "5:00am", "6:00am",
        "7:00am", "8:00am", "9:00am", "10:00am", "11:00am", "12:00pm", "1:00pm",
        "2:00pm", "3:00pm", "4:00pm", "5:00pm", "6:00pm", "7:00pm", "8:00pm",
        "9:00pm", "10:00pm", "11:59pm",
      ],
      datasets: [
        {
          label: "Orders",
          data: [],
          borderColor: "#38C985",
          backgroundColor: "rgba(56, 201, 133, 0.1)",
          borderWidth: 2,
          tension: 0,
          fill: true,
          pointRadius: 0,
        },
        {
          label: "Value",
          data: [],
          fill: true,
          backgroundColor: "#EEF2F5",
          borderColor: "#EEF2F5",
          tension: 0,
          pointStyle: "line",
        },
      ],
    }
  );
  const [pendingGraph, setPendingGraph] = useState({
    labels: [
      "12:00am", "1:00am", "2:00am", "3:00am", "4:00am", "5:00am", "6:00am",
      "7:00am", "8:00am", "9:00am", "10:00am", "11:00am", "12:00pm", "1:00pm",
      "2:00pm", "3:00pm", "4:00pm", "5:00pm", "6:00pm", "7:00pm", "8:00pm",
      "9:00pm", "10:00pm", "11:59pm",
    ],
    datasets: [
      {
        label: "Orders",
        data: [],
        borderColor: "#477DFB",
        backgroundColor: "rgba(71, 125, 251, 0.1)",
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
      {
        label: "Value",
        data: [],
        fill: true,
        backgroundColor: "#EEF2F5",
        borderColor: "#EEF2F5",
        tension: 0,
        pointStyle: "line",
      },
    ],
  });
  const [cancelledGraph, setCancelledGraph] = useState({
    labels: [
      "12:00am", "1:00am", "2:00am", "3:00am", "4:00am", "5:00am", "6:00am",
      "7:00am", "8:00am", "9:00am", "10:00am", "11:00am", "12:00pm", "1:00pm",
      "2:00pm", "3:00pm", "4:00pm", "5:00pm", "6:00pm", "7:00pm", "8:00pm",
      "9:00pm", "10:00pm", "11:59pm",
    ],
    datasets: [
      {
        label: "Orders",
        data: [],
        borderColor: "#FF3D22",
        backgroundColor: "rgba(255, 61, 34, 0.1)",
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
    ],
  });

  const sortOrders = () => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.final_cost.amount);
    const completed = data.filter((item) => item.status === "Completed");
    const cancelled = data.filter((item) => item.status === 'Cancelled');
    const pending = data.filter((item) => item.status === 'Pending');

    setCompleted({amount: completed.reduce(amountReducer, 0), count: completed.length});
    setPending({amount: pending.reduce(amountReducer, 0), count: pending.length});
    setCancelled({amount: cancelled.reduce(amountReducer, 0), count: cancelled.length});

    const completedGraphData = parseHourlyData(completed);
    let completedData = {...completedGraph};
    completedData.datasets[0].data = [...completedGraphData];
    setCompletedGraph(completedData);

    const pendingGraphData = parseHourlyData(pending);
    let pendingData = {...pendingGraph};
    pendingData.datasets[0].data = [...pendingGraphData];
    setPendingGraph(pendingData);

    const cancelledGraphData = parseHourlyData(cancelled);
    let cancelledData = {...cancelledGraph};
    cancelledData.datasets[0].data = [...cancelledGraphData];
    setCancelledGraph(cancelledData);
  }

  const parseHourlyData = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.final_cost.amount);
    let graphData = [];

    for(let i = 0; i < 24; i++) {
      const hourArray = data.filter((order) => new Date(order.created_at).getHours() === i);
      if(hourArray.length > 0) {
        const total = hourArray.reduce(amountReducer, 0);
        graphData.push(total)
      } else {
        graphData.push(0)
      }
    }
    return graphData;
  }
  

  useEffect(() => {
    sortOrders();
  }, [data])

  return (
    <div>
      <div className="grid grid-cols-[1fr,1fr,5fr] border-b">
        <div className="flex flex-col justify-center">
          <p className="text-[12px] text-gray3">Completed</p>
          <div className="flex space-x-2">
            <h1 className="text-lg font-bold text-gray1 ">{completed.count}</h1>
            {/* <div className="flex items-center text-green">
              <IoArrowUpOutline className="w-3 h-3" />

              <p className="text-sm font-medium">20%</p>
            </div> */}
          </div>
        </div>

        <div className="flex items-center">
          <h1 className="font-semibold text-gray1">₦{Number(completed.amount).toLocaleString('en')}</h1>
        </div>

        <div className="flex-grow w-full h-[100px]">
          <Line
            data={completedGraph}
            options={options}
            width={null}
            height={null}
          />
        </div>
      </div>

      <div className="grid grid-cols-[1fr,1fr,5fr] border-b">
        <div className="flex flex-col justify-center">
          <p className="text-[12px] text-gray3">Pending</p>
          <div className="flex space-x-2">
            <h1 className="text-lg font-bold text-gray1 ">{Number(pending.count).toLocaleString('en')}</h1>
            {/* <div className="flex items-center text-green">
              <IoArrowUpOutline className="w-3 h-3" />

              <p className="text-sm font-medium">20%</p>
            </div> */}
          </div>
        </div>

        <div className="flex items-center">
          <h1 className="font-semibold text-gray1">₦{Number(pending.amount).toLocaleString('en')}</h1>
        </div>

        <div className="flex-grow w-full h-[100px]">
          <Line
            data={pendingGraph}
            options={options}
            width={null}
            height={null}
          />
        </div>
      </div>

      <div className="grid grid-cols-[1fr,1fr,5fr] border-b">
        <div className="flex flex-col justify-center">
          <p className="text-[12px] text-gray3">Cancelled</p>
          <div className="flex space-x-2">
            <h1 className="text-lg font-bold text-gray1 ">{Number(cancelled.count).toLocaleString('en')}</h1>
            {/* <div className="flex items-center text-red">
              <IoArrowDownOutline className="w-3 h-3" />

              <p className="text-sm font-medium">30%</p>
            </div> */}
          </div>
        </div>

        <div className="flex items-center">
          <h1 className="font-semibold text-gray1">₦{Number(cancelled.amount).toLocaleString('en')}</h1>
        </div>

        <div className="flex-grow w-full h-[100px]">
          <Line
            data={cancelledGraph}
            options={options}
            width={null}
            height={null}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderAnalytics;
