import { Button, Modal } from "@material-ui/core";
import { IoClose } from "react-icons/io5";

export default function ConfirmDeleteModal({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  children,
}) {
  return (
    <Modal open={open} className="flex items-center justify-center">
      <div class="bg-white rounded-md p-5 px-7">
        <button className="absolute ml-10 top-4 right-10" onClick={onClose}>
          <IoClose size={30} color={"#8892A2"} />
        </button>
        <h3 className="text-center font-size-[auto] mb-5">
          Are you sure about this?
        </h3>
        {children}
        <div className="flex flex-row items-center justify-center mt-8 space-x-2">
          <Button
            variant="outlined"
            className="capitalize flex-grow"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            className="text-white flex-grow capitalize shadow-none relative bg-[#e72a2a] cursor-pointer"
          >
            Yes, Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
