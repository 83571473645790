import React from "react";
import { Button } from "@material-ui/core"

const ErrorPage = ({onClick}) => {

  return(
    <div className="page-padding bg-white rounded h-[609px] mx-[30px] mt-[120px] flex flex-col justify-center items-center">
      <h1 className="font-semibold text-[68px] text-[#3D4356] mb-[12px]">Oops!</h1>
      <h3 className="text-[#3D4356] font-semibold text-[18px] my-0">Sorry, something went wrong</h3>
      <Button
        variant={"contained"}
        disableElevation
        className="mt-8 text-white capitalize bg-orange"
        onClick={onClick}
      >
        Go back to Dashboard
      </Button>
    </div>
  )
}

export default ErrorPage;