import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

//components
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import PickupHours from './PickupHours'
import PreparationHours from './PreparationHours'
import Loader from '../../../components/Loader/Loader'

//services
import { getWorkingHours } from '../../../services/retailers'
import { RootContext } from '../../..'
import Cin7 from './Cin7'

function RetailerSettings() {
  const root = useContext(RootContext)

  //state managements
  const [activeTab, setActiveTab] = useState(1)
  const [loading, setLoading] = useState(false)

  //getting data
  const [workingHours, setWorkingHours] = useState([])

  const fetchWorkingHours = async () => {
    setLoading(true)
    try {
      const res = await getWorkingHours(root.authStore.user.retailerUuid)
      setWorkingHours(res.data)
    } catch (error) {
      toast.error('Error fetching working hours!')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchWorkingHours()
  }, [])

  return (
    <Layout>
      <div className="w-[80%] mx-auto">
        <PageTitle title="Settings" />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-[80%] mx-auto flex gap-7 items-center mt-3">
            <p
              className={`h-10 pb-4 font-medium text-appGray ${
                activeTab === 1
                  ? 'text-black border-b-2 border-orange cursor-default'
                  : 'text-appGray cursor-pointer'
              }`}
              onClick={() => setActiveTab(1)}
            >
              Pickup/Delivery Hours
            </p>
            <p
              className={`h-10 pb-4 font-medium text-appGray ${
                activeTab === 2
                  ? 'text-black border-b-2 border-orange cursor-default'
                  : 'text-appGray cursor-pointer'
              }`}
              onClick={() => setActiveTab(2)}
            >
              Order Preparation Hours
            </p>
            {root.authStore.user.cin7_available && (
              <p
                className={`h-10 pb-4 font-medium text-appGray ${
                  activeTab === 3
                    ? 'text-black border-b-2 border-orange cursor-default'
                    : 'text-appGray cursor-pointer'
                }`}
                onClick={() => setActiveTab(3)}
              >
                Cin7 Integration
              </p>
            )}
          </div>

          {activeTab === 1 ? (
            <PickupHours data={workingHours} fetchData={fetchWorkingHours} />
          ) : activeTab === 2 ? (
            <PreparationHours
              data={workingHours}
              fetchData={fetchWorkingHours}
            />
          ) : (
            <Cin7 />
          )}
        </>
      )}
    </Layout>
  )
}

export default RetailerSettings
