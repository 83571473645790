import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

//mui
import { Avatar } from "@material-ui/core";

//components
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import CustomizedSwitches from "../../components/CustomSwitch/CustomSwitch";

import { RootContext } from "../..";

//assets
import { useLocation } from "react-router";
import { Switch } from "antd";

import CloseStoreModal from "../Modals/CloseStoreModal";
import { useState } from "react";

const Topbar = observer(() => {
  const root = useContext(RootContext);
  const { pathname } = useLocation();
  const [openStoreModal, setOpenStoreModal] = useState(false);

  const handleStoreStatus = async () => {
    setOpenStoreModal(true);
  };

  return (
    <>
      <div
        className={`sticky bg-[#EEF2F5] bg-opacity-80 top-0 z-50 flex items-center w-full px-10 py-5 justify-between`}
      >
        <div className="flex items-center gap-10 mr-auto">
          {root.authStore.user.account_type.includes("Retailer") &&
            pathname !== "/home" && (
              <div className="flex items-center space-x-2">
                <Avatar
                  className="w-10 h-10"
                  src={root.authStore.user.logo}
                ></Avatar>
                <div>
                  <h1 className="text-sm font-bold text-gray1">
                    {root.authStore.user.name}{" "}
                  </h1>
                  <p className="text-sm text-gray2">
                    {root.authStore.user.email}
                  </p>
                </div>
              </div>
            )}

          {root.authStore.user.account_type.includes("Retailer") && (
            <div
              className="flex justify-between gap-5 items-center"
              id="open-store"
            >
              <p className="text-sm text-[#28283E] m-0">Accepting Orders</p>
              {/* <CustomizedSwitches checked /> */}

              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={
                  root.authStore.user.store_availability === "Open"
                    ? true
                    : false
                }
                onChange={handleStoreStatus}
              />
            </div>
          )}
        </div>

        <div className="flex items-center space-x-3">
          {root.authStore.user.account_type.includes("Retailer") && (
            <>
              <p className="text-[14px] text-[#9CA7B8] font-medium m-0">
                {moment().format("D MMMM YY")}
                <span className="text-[14px] text-[#040A1D] font-[400] ml-2">
                  {moment().format("LT")}
                </span>
              </p>
              {/* <Button className="flex flex-row items-center text-[#9CA7B8] text-[14px] font-medium capitalize">
              <img
                src={speakerIcon}
                alt=""
                className="w-[24px] h-[24px] mr-1"
              />
              <p>Feedback?</p>
            </Button>
            <HelpDropdown /> */}
            </>
          )}
          <NotificationDropdown />

          <Avatar
            className="w-8 h-8 text-sm text-white uppercase bg-gray-400"
            src={root.authStore.user.profile.picture}
          >
            {/* {user.first_name[0]}
          {user.last_name[0]} */}
          </Avatar>

          <h1 className="font-medium text-gray-400 capitalize">
            {root.authStore.user.first_name} {root.authStore.user.last_name}
          </h1>

          <DropdownMenu />
        </div>
      </div>

      <CloseStoreModal isOpen={openStoreModal} setIsOpen={setOpenStoreModal} />
    </>
  );
});

export default Topbar;
