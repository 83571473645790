import React from "react";

const NoResultsView = ({ value }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-3">
      <h1 className="font-semibold text-[18px] text-gray1">
        No Search Result for “{value}”
      </h1>

      <p className="text-gray2">
        <span className="font-bold">Search tips</span>: Some search terms
        require an exact match. Try typing the entire term, or use a different
        word or phrase.
      </p>
    </div>
  );
};

export default NoResultsView;
