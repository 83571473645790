import React from "react";

import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

//components
import TableHead from "./CustomTableHead";

const EmptySearch = ({ value }) => {
  return (
    <div className="w-full mt-5">
      <Table className="min-w-[750px]" size="small">
        <TableHead />

        <TableBody className="bg-white">
          <TableRow className="h-60">
            <TableCell colSpan={12}>
              <div className="flex flex-col items-center justify-center">
                {value ? (
                  <h1 className="text-2xl font-bold">
                    No Search Results for "{value}"
                  </h1>
                ) : (
                  <h1 className="text-2xl font-bold">No Results</h1>
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default EmptySearch;
