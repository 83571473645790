import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const RevenueGrowthChart = ({data}) => {
  const tickCallback = (value, index, values) => {
    const date = new Date();
    const hour = date.getHours();
    if (compareHours(hour, value, index)) return `Now, ${value}`;
    else if (value === "12:00am") return "12:00am";
    else if (value === "12:00pm") return "12:00pm";
    else if (value === "11:59pm") return "11:59pm";
    return null;
  };

  const ytickCallback = () => {
    return '0';
  }

  const compareHours = (hour, tickValue, tickIndex) => {
    const tickHour = parseInt(tickValue.split(":")[0]);
    if (tickIndex === 0 && hour === 0) return true;
    else if (tickIndex <= 12 && tickHour === hour) return true;
    else if (tickIndex > 12 && tickHour + 12 === hour) return true;
    return false;
  };

  const chartOptions = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            drawBorder: false,
            color: 'white',
            zeroLineColor: '#FF4127',
          },
          ticks: {
            callback: ytickCallback,
            fontColor: 'white'
          }
        },
      ],
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: true,
            color: 'white',
            tickColor: 'white',
            //color: 'white',
          },
          ticks: {
            callback: tickCallback,
            maxRotation: 0,
            minRotation: 0,
            fontColor: ["#9CA7B8"],
            beginAtZero: false,
            autoSkip: false,
          },
          grid: {
            
          }
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: '#FAFAFA',
      titleFontColor: '#040A1D',
      bodyFontColor: '#477DFB',
      usePointStyle: true,
      callbacks: {
        title: function(tooltipItem, data) {
          return `₦${Number(data['datasets'][0]['data'][tooltipItem[0]['index']]).toLocaleString('en')}`
        },
        label: function(tooltipItem, data) {
          return data['labels'][tooltipItem['index']]
        },
      }
    }
  };

  return (
    <div className="h-full">
      <Line 
        data={data}
        options={chartOptions}
        id="analytics-revenue-growth"
        className="chart-canvas"
        height={null}
        width={null}
      />
    </div>
  )
}

export default RevenueGrowthChart;