import api from '../apiInstance'

// campaigns get services
export const getCampaigns = (params, signal) =>
  api.get('/api/campaign/', {
    params: {
      all: 'yes',
      ...params,
    },
    signal,
  })

export const getSingleCampaign = (id) => api.get(`/api/campaign/${id}/`)

export const searchCampaign = (query) =>
  api.get(`/api/campaign/`, {
    params: {
      search: query,
      page: 1,
    },
  })

export const getCampaignRetailers = (id) => api.get(`/api/retailer/${id}/`)

export const getCampaignProduct = (id) => api.get(`/api/product/${id}/`)

export const getCampaignBrand = (id) => api.get(`/api/brand/${id}/`)

//campaign post services
export const createCampaign = (form) => api.post(`/api/campaign/`, form)

export const updateCampaign = (id, form) =>
  api.patch(`/api/campaign/${id}/`, form)

export const campaignStatus = (id) =>
  api.post(`/api/campaign/${id}/set-active/`)

// campaign delete services
export const deleteSingleCampaign = (uuid) =>
  api.delete(`/api/campaign/${uuid}/`)

// segment get services
export const getSegment = (uuid) =>
  api.get('/api/segment/', {
    params: {
      campaign_uuid: uuid,
    },
  })

export const getSingleSegment = (id) => api.get(`/api/segment/${id}/`)

// segment post services
export const segmentStatus = (id) => api.post(`/api/segment/${id}/set-active/`)

export const createSegment = (form) => api.post(`/api/segment/`, form)

export const updateSegment = (id, form) => api.put(`/api/segment/${id}/`, form)

// segment delete services
export const deleteSegment = (id) => api.delete(`/api/segment/${id}/`)

// get products search
export const findRetailer = (query) =>
  api.get('/api/retailer-list-minimal/', {
    params: {
      search: query,
    },
  })

export const findBrands = (query) =>
  api.get('/api/brand-list-minimal/', {
    params: {
      search: query,
    },
  })

export const findProducts = (query) =>
  api.get('/api/product-list/', {
    params: {
      search: query,
    },
  })
