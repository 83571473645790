import React from "react";
import loader from "../../assets/loader.svg";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-40">
      <img src={loader} alt="" className="w-10 h-10 animate-spin" />
    </div>
  );
};

export default Loader;
