import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'

//mui
import { Button } from '@material-ui/core'

//assets
import location from '../../assets/location.svg'
import retailerIcon from '../../assets/retailors-inactive.svg'

//components
import Layout from '../../components/Layout/Layout'
import Loader from '@components/Loader/Loader'
import SaveModal from '../../components/SaveModal/SaveModal'
import PageTitle from '../../components/PageTitle/PageTitle'
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import FormInput from '../../components/FormInput/FormInput'

//services
import {
  getRetailer,
  updateRetailer,
  updateRetailerStatus,
} from '../../services/retailers'

//utils
import validatePhoneNumber from '../../utils/validatePhoneNumber'

//google maps
import { GoogleMap, Marker, LoadScript, useLoadScript } from '@react-google-maps/api'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import UnsavedChanges from '../../components/UnsavedChanges/UnsavedChanges'
import ImageInput from '../../components/ImageInput/ImageInput'
import { jsonToFormData } from '../../utils/jsonToFormData'

//styling
const containerStyle = {
  width: '70%',
  height: '167px',
  borderRadius: '10px',
}

const EditRetailer = () => {
  const { id } = useParams()
  const history = useHistory()
  const [busy, setBusy] = useState(true)
  const [retailer, setRetailer] = useState()
  const [status, setStatus] = useState()
  const [saving, setSaving] = useState(false)
  // const [edit, setEdit] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [loadedBanner, setLoadedBanner] = useState(false)
  const [modalOpen, setmodalOpen] = useState(false)

  //business information
  const [businessName, setBusinessName] = useState('')
  const [website, setWebsite] = useState('')
  const [countryCode, setCountryCode] = useState('+234')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [bio, setBio] = useState('')

  //address info
  const [address, setAddress] = useState('')
  // const [apartment, setApartment] = useState('')
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [region, setRegion] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')

  //business images
  const [businessLogo, setBusinessLogo] = useState()
  const [bannerImage, setBannerImage] = useState()

  //marker coords
  const [coords, setCoord] = useState(null)
  const [center, setCenter] = useState(null)

  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  })

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })

  //map functions
  const handleInput = (e) => {
    const { value } = e.target
    setValue(value)
    setAddress(value)
  }

  const onSelect = async (suggestion) => {
    const results = await getGeocode({ placeId: suggestion.place_id })
    const coordinates = await getLatLng(results[0])

    const addressComps = results[0].address_components

    const country = addressComps.find((info) => info.types.includes('country'))
    const city = addressComps.find((info) => info.types.includes('locality'))
    const district = addressComps.find((info) =>
      info.types.includes('sublocality'),
    )
    // const city = addressComps.find((info) =>
    //   info.types.includes("administrative_area_level_2")
    // );
    const region = addressComps.find((info) =>
      info.types.includes('administrative_area_level_1'),
    )
    const postalCode = addressComps.find((info) =>
      info.types.includes('postal_code'),
    )

    setValue(suggestion.description, false)

    clearSuggestions()

    setCoord(coordinates)
    setCenter(coordinates)
    setAddress(suggestion.description)
    setDistrict(district?.long_name)
    setCity(city?.long_name)
    setPostalCode(postalCode?.long_name)
    setRegion(region?.long_name)
    setCountry(country?.long_name)
  }

  const sanitizePhoneNumber = () => {
    if (phoneNumber.substring(0, 1) === '0')
      return `${countryCode}${phoneNumber.substring(1)}`
    else return `${countryCode}${phoneNumber}`
  }

  //fetch requests
  const fetchRetailer = async () => {
    try {
      const { data } = await getRetailer(id)
      setRetailer(data)

      //business info
      setBusinessName(data?.name)
      setWebsite(data?.website)
      setCountryCode(data?.business_phone.substring(0, 4))
      setPhoneNumber(data?.business_phone.substring(4))
      setBio(data?.bio)

      //business address
      setValue(data?.address?.address, false)
      setAddress(data?.address?.address)
      setCity(data?.address?.city)
      setPostalCode(data?.address?.post_code)
      setCountry(data?.address?.country)
      setDistrict(data?.address?.town)
      setRegion(data?.address?.town)
      setCoord({
        lng: parseFloat(data?.address?.lon),
        lat: parseFloat(data?.address?.lat),
      })
      setCenter({
        lng: parseFloat(data?.address?.lon),
        lat: parseFloat(data?.address?.lat),
      })

      //business images
      setLoaded(true)
      setLoadedBanner(true)
      setBusinessLogo(data?.logo)
      setBannerImage(data?.store_image)

      //state management
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch retailer data.')
      history.goBack()
    }
  }

  //post request
  const updateStatus = async () => {
    setSaving(true)
    setBusy(true)
    try {
      await updateRetailerStatus(retailer.uuid, status)
      setBusy(false)
    } catch (error) {
      setSaving(false)
      toast.error('An error occurred. Unable to update retailer.')
    }
  }

  const handleSubmit = async () => {
    const sanitizedPhone = sanitizePhoneNumber()

    const isBusinessPhoneNumberValid = validatePhoneNumber(sanitizedPhone)

    if (!isBusinessPhoneNumberValid) {
      return toast.error('Phone Number be in +XXXXXXX format')
    }

    setSaving(true)
    setBusy(true)

    try {
      await updateRetailer(
        retailer.uuid,
        jsonToFormData({
          // manager: retailer.manager,
          name: businessName,
          bio,
          business_phone: sanitizedPhone,
          website: website,
          address: {
            is_active: true,
            address,
            street_name: '',
            town: '',
            city,
            country,
            administrative_area_level_1: region,
            administrative_area_level_2: '',
            post_code: postalCode,
            plus_code: '',
          },
          logo: typeof businessLogo !== 'string' ? businessLogo : undefined,
          store_image:
            typeof bannerImage !== 'string' ? bannerImage : undefined,
        }),
      )

      setBusy(false)
    } catch (error) {
      console.log(error)
      setSaving(false)
      toast.error('Error updating retailer. Please try again')
    }
  }

  useEffect(() => {
    fetchRetailer()
  }, [])

  useEffect(() => {
    if (retailer) setStatus(retailer.status)
  }, [retailer])

  return (
    <Layout>
      {busy && !retailer ? (
        <Loader />
      ) : (
        <div className="px-[30px] pt-5 pb-10">
          <PageTitle
            onBackPress={() => setmodalOpen(true)}
            title={retailer ? retailer.name : ''}
          />
          <div className="bg-orange/10 border-dashed border border-orange rounded-md w-full h-[199px] mb-8 p-[40px]">
            <h1 className="text-[#040A1D] text-[20px] font-bold">
              Account Status
            </h1>
            <div className="w-[420px] flex flex-row items-center space-x-6 mt-[20px]">
              <div className="w-[323px]">
                <CustomSelect
                  name="Account Status"
                  id="account-status"
                  options={['Approved', 'Rejected', 'Pending']}
                  setValue={setStatus}
                  value={status}
                />
              </div>
              <Button
                disabled={retailer.status === status}
                variant="contained"
                className={`w-[68px] h-[43px] text-white ${
                  retailer.status === status ? 'bg-orange/30' : 'bg-orange'
                }`}
                onClick={updateStatus}
              >
                Save
              </Button>
            </div>
          </div>

          <div className="p-10 space-y-4 bg-white rounded-md">
            <div className="pb-10 space-y-5">
              <div className="flex items-center justify-between">
                <h1 className="font-semibold text-gray1 text-[20px] capitalize">
                  Business Information
                </h1>
              </div>

              <FormInput
                type="text"
                label="Business Name"
                placeholder="Business Name"
                value={businessName !== 'null' ? businessName : ''}
                onChange={(e) => setBusinessName(e.target.value)}
              />

              <div className="grid grid-cols-2 gap-5">
                <FormInput
                  type="text"
                  label="Website"
                  placeholder="Website"
                  value={website !== 'null' ? website : ''}
                  onChange={(e) => setWebsite(e.target.value)}
                />

                <div className="flex flex-row items-center space-x-1">
                  <CustomSelect
                    label="Country Code"
                    name="country-codes"
                    id="country-codes"
                    value={countryCode}
                    options={['+233', '+234']}
                    setValue={setCountryCode}
                  />
                  <FormInput
                    type="text"
                    label="Phone Number"
                    placeholder="Phone Number"
                    value={phoneNumber !== 'null' ? phoneNumber : ''}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

              <FormInput
                id="Bio"
                type="textarea"
                label="Bio"
                placeholder="Bio"
                rows={4}
                value={bio !== 'null' ? bio : ''}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>

            <div className="relative space-y-5">
              <div className="flex items-center justify-between">
                <h1 className="font-semibold text-gray1 text-[20px] capitalize">
                  Business Address
                </h1>
              </div>

              <FormInput
                type="text"
                label="Address"
                placeholder="Address"
                value={value !== 'null' ? value : ''}
                onChange={handleInput}
                // disabled={!ready}
              />

              {data.length > 0 && (
                <ul className="absolute z-50 w-full p-3 bg-white rounded-md shadow-lg top-16">
                  {data.map((suggestion, idx) => {
                    return (
                      <li
                        key={idx}
                        onClick={() => onSelect(suggestion)}
                        className="flex items-center p-2 space-x-3 capitalize cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                      >
                        <img src={location} alt="" />
                        <p className="font-medium">{suggestion.description}</p>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>

          {isLoaded && address && coords && center && (
            <div className="bg-white w-full py-3 px-10 space-y-5">
              <h1 className="mb-3 font-medium text-[#3D4356]">Map Location</h1>

              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                >
                  <Marker position={coords}></Marker>
                </GoogleMap>
              </LoadScript>
            </div>
          )}

          {loadError && <div>We're having trouble loading the map</div>}

          <div className="bg-white w-full pt-5 pb-10 px-10 grid grid-cols-2 gap-10">
            <ImageInput
              title="Business Logo"
              value={businessLogo}
              hook={setBusinessLogo}
              state={loaded}
              hookState={setLoaded}
              id="business-logo"
            />

            <ImageInput
              title="Banner Image"
              value={bannerImage}
              hook={setBannerImage}
              state={loadedBanner}
              hookState={setLoadedBanner}
              id="banner-image"
            />
          </div>

          <div className="bg-white w-full flex justify-start items-center gap-3 px-10 pb-10">
            <Button
              variant="outlined"
              className="capitalize bg-white shadow-none"
              onClick={() => setmodalOpen(true)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`text-white capitalize shadow-none ${
                businessName === '' ||
                website === '' ||
                phoneNumber === '' ||
                bio === '' ||
                address === '' ||
                district === '' ||
                city === '' ||
                region === '' ||
                country === ''
                  ? 'bg-orange/30 cursor-not-allowed'
                  : 'bg-orange cursor-pointer'
              }`}
              onClick={handleSubmit}
              disabled={
                businessName === '' ||
                website === '' ||
                phoneNumber === '' ||
                bio === '' ||
                address === '' ||
                district === '' ||
                city === '' ||
                region === '' ||
                country === ''
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}

      <SaveModal
        isOpen={saving}
        close={() => setSaving(false)}
        icon={retailerIcon}
        update={true}
        progressLabel={'Updating Retailer Status'}
        successLabel={'Retailer Status Updated'}
        busy={busy}
      />

      <UnsavedChanges isOpen={modalOpen} close={() => setmodalOpen(false)} />
    </Layout>
  )
}

export default EditRetailer
