import React, { useState, useEffect } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

//mui
import { IconButton, Button, Modal } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import ProductInfo from "../../components/ProductInfo/ProductInfo";
import Loader from "../../components/Loader/Loader";

//services
import { addRequestedProduct, declineRequestedProduct, getProduct } from "../../services/products";

//icons
import loader from "../../assets/loader.svg";


const ProductRequestDetails = () => {
  const history = useHistory();
  const location = useLocation()
  const { id } = useParams();

  const [busy, setBusy] = useState(true);
  const [declining, setDeclining] = useState(false);
  const [decline, setDecline] = useState(false);
  const [product, setProduct] = useState(null);

  const fetchProduct = async() => {
    try {
      const { data } = await getProduct(id);
      setProduct(data);
      setBusy(false)
    } catch (error) {
      toast.error('Unable to fetch requested product. Please try again later.');
      history.goBack();
    }
  }

  const addProduct = async() => {
    setBusy(true);
    try {
      await addRequestedProduct(product.uuid);
      setBusy(false);
      toast.success("Product request successfully approved.");
      history.goBack();
    } catch (error) {
      setBusy(false);
      toast.error("Unable to approve product request. Please try again later.");
    }
  }

  const declineProduct = async(reason) => {
    setDecline(false)
    setDeclining(true);
    try {
      toast.info("Declining product request.");
      await declineRequestedProduct(product.uuid, reason);
      toast.success("Product request successfully declined.");
      history.goBack();
    } catch (error) {
      toast.error("Unable to decline product request. Please try again later.");
    } finally {
      setDeclining(false);
    }
  }

  useEffect(() => {
    fetchProduct();
  }, [])

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-center py-10 space-y-5 ">
          <div className="w-[80%] flex items-center space-x-3">
            <IconButton
              onClick={() => history.goBack()}
              disableRipple
              className="p-0 hover:bg-transparent"
            >
              <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
            </IconButton>
            <h1 className="font-bold text-[30px] text-gray1">Product Details</h1>
          </div>

          <div className="w-[80%]">
            <ProductInfo showButton product={product}/>
          </div>
          <div className="flex flex-row items-center justify-end w-[80%] gap-4">
            <Button
              variant="outlined"
              disableElevation
              className="capitalize bg-white"
            >
              Cancel
            </Button>
            {location.search.includes('declined') ? null : (
              <Button
                variant="contained"
                disableElevation
                disabled={declining}
                className={`bg-red text-white relative capitalize`}
                onClick={() => setDecline(true)}
              >
                Decline
                {busy && product && (
                  <img src={loader} alt="" className="absolute right-0 w-5 animate-spin"/>
                )}
              </Button>
            )}
            <Button
              variant="contained"
              disableElevation
              className={`${busy && product ? 'bg-orange/30' : 'bg-orange'} text-white relative capitalize`}
              onClick={addProduct}
            >
              Add to Products
              {busy && product && (
                <img src={loader} alt="" className="absolute right-0 w-5 animate-spin"/>
              )}
            </Button>
          </div>
        </div>
      )}
      <DeclineModal
        isOpen={decline}
        close={() => setDecline(false)}
        onConfirm={declineProduct}
      />
    </Layout>
  );
};

const DeclineModal = ({isOpen, close, onConfirm=()=>{}}) => {
  const confirm = (e) => {
    e.preventDefault()
    onConfirm(e.target.elements.reason.value)
  }

  return (
    <Modal
      className="flex items-center justify-center overflow-y-scroll"
      open={isOpen}
      onClose={close}
    >
      <div className="w-[400px] bg-white flex flex-col gap-4 items-center pb-5 rounded-lg">
        <p className="text-center text-[18px] w-[80%] flex flex-col mt-5">
          Are you sure you want to decline this product request?
        </p>
        <form onSubmit={confirm} >
          <div className="bg-[#F4F5F7] px-2 pb-2 rounded-md w-[300px] flex items-center mb-4">
            <textarea
              name="reason"
              placeholder="Enter reason"
              className="w-full p-2 bg-transparent border-0 focus:outline-none focus:ring-0"
            />
          </div>
          <button
            className="bg-red rounded-md px-4 py-2 text-white font-medium w-full"
          >
            Confirm Decline
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default ProductRequestDetails;
