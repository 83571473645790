import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

//components
import AuthCard from "../../components/AuthCard/AuthCard";
import FormInput from "../../components/FormInput/FormInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

import { resetPassword } from "../../services/auth";

const NewPin = () => {
  const history = useHistory();
  const { token } = useParams();

  const [reset, setReset] = useState({
    newPin: "",
    confirmNewPin: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setReset({
      ...reset,
      [name]: value,
    });
  };

  const handleError = (error) => {
    if (typeof error.response.data === "object")
      setError(error.response.data.non_field_errors[0]);
    else setError("An unexpected error occurred. Please try again later");
  };

  const submit = async () => {
    const { newPin, confirmNewPin } = reset;

    setLoading(true);
    setError("");

    if (newPin !== confirmNewPin) {
      setError("Pins do not match, please try again.");
      setLoading(false);
      return;
    }

    const form = {
      token: token,
      new_pin: newPin,
    };

    try {
      await resetPassword(form);
      history.push(`/reset-pin/reset-success`);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  return (
    <AuthCard>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-2xl font-bold text-center">Reset Your PIN</h1>

        <p className="text-gray2">
          Create a new 4 digit PIN to secure your account.
        </p>
      </div>

      <div className="w-[65%]">
        <form className="space-y-2">
          <FormInput
            id="newPin"
            name="newPin"
            type="password"
            placeholder="New PIN"
            label="New PIN"
            onChange={handleChange}
            value={reset.newPin}
          />

          <FormInput
            id="confirmNewPin"
            name="confirmNewPin"
            type="password"
            placeholder="Confirm PIN"
            label="Confirm PIN"
            onChange={handleChange}
            value={reset.confirmNewPin}
            error={error}
          />

          {error && <ErrorAlert error={error} />}
        </form>

        <CustomButton
          text="Continue"
          onClick={submit}
          disabled={
            reset.newPin === "" ||
            reset.confirmNewPin === "" ||
            reset.newPin.length < 4
          }
          loading={loading}
        />

        <div className="flex items-center justify-center mt-2">
          <Link
            to="/login"
            className="font-bold text-[12px] underline text-blue"
          >
            Return to login
          </Link>
        </div>
      </div>
    </AuthCard>
  );
};

export default NewPin;
