import React from "react";
import logo from "../../assets/logo.svg";

const AuthCard = ({ children }) => {
  return (
    <div className="relative flex justify-center h-screen pt-10 pb-14 bg-grayBg">
      <div className="flex flex-col w-[450px] items-center pt-10 space-y-3 bg-white rounded-lg">
        <img src={logo} alt="" className="w-32" />

        {children}
      </div>

      <div className="absolute pb-2 text-gray3 bottom-0 flex items-center justify-center w-full text-[12px] space-x-5">
        <p>&copy;DrinksNG</p>
        <p>Contact</p>
        <p>Privacy & Terms</p>
      </div>
    </div>
  );
};

export default AuthCard;
