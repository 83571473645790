import React, { useContext } from 'react'
import logo from '../../assets/logo.svg'
import { observer } from 'mobx-react-lite'

//icons
import dashboardActive from '../../assets/dashboard-active.svg'
import dashboardInactive from '../../assets/dashboard-inactive.svg'
import homeActive from '../../assets/home-active.svg'
import homeInactive from '../../assets/home-inactive.svg'
import analyticsActive from '../../assets/analytics-active.svg'
import analyticsInactive from '../../assets/analytics-inactive.svg'
import ordersActive from '../../assets/orders-active.svg'
import ordersInactive from '../../assets/orders-inactive.svg'
import financeActive from '../../assets/finance-active.svg'
import financeInactive from '../../assets/finance-inactive.svg'
import productsActive from '../../assets/products-active.svg'
import productsInactive from '../../assets/products-inactive.svg'
import inventoryActive from '../../assets/inventory-active.svg'
import inventoryInactive from '../../assets/inventory-inactive.svg'
import customersActive from '../../assets/customers-active.svg'
import customersInactive from '../../assets/customers-inactive.svg'
import retailersActive from '../../assets/retailors-active.svg'
import retailersInactive from '../../assets/retailors-inactive.svg'
import usersActive from '../../assets/users-active.svg'
import usersInactive from '../../assets/users-inactive.svg'
import blogActive from '../../assets/blog-active.svg'
import blogInactive from '../../assets/blog-inactive.svg'
import couponsActive from '../../assets/coupons-active.svg'
import couponsInactive from '../../assets/coupons-inactive.svg'
import designActive from '../../assets/design-active.svg'
import designInactive from '../../assets/design-inactive.svg'
import campaignsActive from '../../assets/campaign-active.svg'
import campaignsInactive from '../../assets/campaign-inactive.svg'
import brands from '../../assets/brands.svg'
import settings from '../../assets/settings.svg'

//mui
import { Drawer } from '@material-ui/core'

//components
import MenuItem from '../MenuItem/MenuItem'
import { RootContext } from '../..'

const adminLinks = [
  {
    title: 'home',
    activeIcon: homeActive,
    inactiveIcon: homeInactive,
    path: '/home',
  },
  {
    title: 'analytics',
    activeIcon: analyticsActive,
    inactiveIcon: analyticsInactive,
    path: '/analytics',
  },
  {
    title: 'finance',
    activeIcon: financeActive,
    inactiveIcon: financeInactive,
    path: '/finance',
  },
  {
    title: 'products',
    activeIcon: productsActive,
    inactiveIcon: productsInactive,
    path: '/products',
  },
  {
    title: 'vouchers',
    activeIcon: couponsActive,
    inactiveIcon: couponsInactive,
    path: '/vouchers',
  },
  {
    title: 'customers',
    activeIcon: customersActive,
    inactiveIcon: customersInactive,
    path: '/customers',
  },
  {
    title: 'retailers',
    activeIcon: retailersActive,
    inactiveIcon: retailersInactive,
    path: '/retailers',
  },
  {
    title: 'brands',
    activeIcon: brands,
    inactiveIcon: brands,
    path: '/brands',
  },
  {
    title: 'users',
    activeIcon: usersActive,
    inactiveIcon: usersInactive,
    path: '/users',
  },
  {
    title: 'coupons',
    activeIcon: couponsActive,
    inactiveIcon: couponsInactive,
    path: '/coupons',
  },
  {
    title: 'blog',
    activeIcon: blogActive,
    inactiveIcon: blogInactive,
    path: '/blog',
  },
  {
    title: 'design',
    activeIcon: designActive,
    inactiveIcon: designInactive,
    path: '/design',
  },
  {
    title: 'campaigns',
    activeIcon: campaignsActive,
    inactiveIcon: campaignsInactive,
    path: '/campaigns',
  },
]

const retailerLinks = [
  {
    title: 'home',
    activeIcon: dashboardActive,
    inactiveIcon: dashboardInactive,
    path: '/home',
  },
  {
    title: 'orders',
    activeIcon: ordersActive,
    inactiveIcon: ordersInactive,
    path: '/orders',
  },
  {
    title: 'finance',
    activeIcon: financeActive,
    inactiveIcon: financeInactive,
    path: '/finance',
  },
  {
    title: 'inventory',
    activeIcon: inventoryActive,
    inactiveIcon: inventoryInactive,
    path: '/inventory',
  },
  {
    title: 'customers',
    activeIcon: customersActive,
    inactiveIcon: customersInactive,
    path: '/customers',
  },
  {
    title: 'users',
    activeIcon: usersActive,
    inactiveIcon: usersInactive,
    path: '/users',
  },
  {
    title: 'settings',
    activeIcon: settings,
    inactiveIcon: settings,
    path: '/settings',
  },
]

const Sidebar = observer(() => {
  const root = useContext(RootContext)

  return (
    <Drawer
      variant="permanent"
      className="w-40"
      classes={{
        paper: 'w-40',
        paperAnchorLeft: 'border-0',
      }}
    >
      <div className="flex justify-center px-2 pt-5 pb-10">
        <img src={logo} alt="" className="object-contain" />
      </div>

      <ul className="space-y-5">
        {root.authStore.user.account_type.includes('Admin')
          ? adminLinks.map((link, idx) => {
              return (
                <MenuItem
                  link={link}
                  key={idx}
                  hasDropdown={
                    link.title === 'finance' ||
                    link.title === 'products' ||
                    link.title === 'blog' ||
                    link.title === 'design'
                  }
                />
              )
            })
          : retailerLinks.map((link, idx) => {
              return (
                <MenuItem
                  link={link}
                  key={idx}
                  hasDropdown={link.title === 'finance'}
                />
              )
            })}
      </ul>
    </Drawer>
  )
})

export default Sidebar
