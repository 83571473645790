import api from '../apiInstance'

export const getCoupons = (query) => api.get(`/api/coupon/?${query}`)

export const createCoupon = (form) => api.post('/api/coupon/', form)

export const getCoupon = (id) => api.get(`/api/coupon/${id}/`)

export const updateCoupon = (id, form) => api.patch(`/api/coupon/${id}/`, form)

export const deleteCoupon = (id) => api.delete(`/api/coupon/${id}/`)
