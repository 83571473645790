import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { toast } from "react-toastify";

//icons
import loader from "../../assets/loader.svg";

//components
import RevenueGrowthChart from "./RevenueGrowthChart";
import DateActions from "../../components/DateActions/DateActions";
import { RootContext } from "../..";

//services
import { getTransactionsByPeriod, getTransactionsByDateRange } from "../../services/finance";


const RevenueGrowth = ({ title }) => {
  const { authStore: { user } } = useContext(RootContext)
  const [busy, setBusy] = useState(false);
  const [activePeriod, setActivePeriod] = useState("today");
  const [revenueDateRange, setRevenueDateRange] = useState({start: moment(), end: moment().add(2, 'M')});
  const [grossData, setGrossData] = useState([0,0,0,0,0,0,0,0,0,0]);
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [revenueChart, setRevenueChart] = useState({
    labels: [
      "12:00am", "1:00am", "2:00am", "3:00am", "4:00am", "5:00am", "6:00am",
      "7:00am", "8:00am", "9:00am", "10:00am", "11:00am", "12:00pm", "1:00pm",
      "2:00pm", "3:00pm", "4:00pm", "5:00pm", "6:00pm", "7:00pm", "8:00pm",
      "9:00pm", "10:00pm", "11:59pm",
    ],
    datasets: [
      {
        label: "Sales",
        data: [],
        borderColor: "#F3641F",
        backgroundColor: "rgba(243, 100, 31, 0.1)",
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
      {
        label: "Value",
        data: [],
        fill: true,
        backgroundColor: "#EEF2F5",
        borderColor: "#EEF2F5",
        tension: 0,
        pointStyle: "line",
      },
    ],
  })

  const filterByPeriod = async() => {
    setBusy(true);
    try {
      const { data } = await getTransactionsByPeriod(user.retailerUuid, activePeriod);
      parseHourlyData(data.results);
      setTransactions(data.results);
      computeTotal(data.results);
      setBusy(false);
    } catch (error) {
      toast.error("An error occurred. Unable to fetch requested data.");
      setBusy(false);
    }
  }

  const handleDateRangeChange = async(range) => {
    setRevenueDateRange(range);
    setBusy(true);
    try {
      const { data } = await getTransactionsByDateRange(user.retailerUuid, range.end.format('YYYY-MM-DD'), range.start.format('YYYY-MM-DD'))
      parseHourlyData(data.results);
      setTransactions(data.results);
      computeTotal(data.results)
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch requested data.")
    }
  }

  const computeTotal = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.amount.amount);
    const total = data.reduce(amountReducer, 0);
    setTotalTransactions(total);
  }

  const parseHourlyData = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.amount.amount);
    let graphData = [];

    for(let i = 0; i < 24; i++) {
      const hourArray = data.filter((transaction) => new Date(transaction.order.created_at).getHours() === i);
      if(hourArray.length > 0) {
        const total = hourArray.reduce(amountReducer, 0);
        graphData.push(total)
      } else {
        graphData.push(0)
      }
    }
    setGrossData(graphData);
  }

  // generate data for gray graph
  const generateGrayData = () => {
    const currentHour = new Date().getHours();

    //select data up until the current hour;
    const coloredData = grossData.slice(0, currentHour + 1);
    
    //rest of data that will be gray
    const grayData = grossData.slice(currentHour + 1);

    const grayArray = [];
    for(let i = 0; i < coloredData.length; i++) {
      grayArray.push(0);
    }

    const newGrossData = grossData.map((data, index) => {
      if(index <= currentHour)
        return data;
      return null;
    })

    grayArray.push(...grayData);
    let graphData = {...revenueChart};
    graphData.datasets[0].data = [...newGrossData];
    graphData.datasets[1].data = [...grayArray];
    setRevenueChart(graphData);
  }

  useEffect(() => {
    if(grossData.length > 0)
      generateGrayData();
  }, [grossData]);

  useEffect(() => {
    filterByPeriod();
  }, [activePeriod])

  return (
    <div className="relative p-6 bg-white">
      <div className="flex items-center justify-between py-6">
        <h1 className="font-medium text-gray2">{title}</h1>

        <DateActions
          activePeriod={activePeriod}
          changePeriod={setActivePeriod}
          dateRange={revenueDateRange}
          onDateRangeSelect={handleDateRangeChange}
        />
      </div>

      <div className="space-y-1">
        <div className="flex items-center space-x-2">
          <h1 className="text-[26px] font-semibold text-gray1">
            ₦{Number(totalTransactions).toLocaleString("en")}
          </h1>

          <div className="flex items-center text-green">
            {/* <IoArrowUpOutline className="w-3 h-3" /> */}

            {/* <p className="text-sm font-medium">0%</p> */}
          </div>
        </div>

        <div className="">
          <p className="text-sm font-medium text-gray3">
            {moment().format("LT")}
          </p>
        </div>

        <RevenueGrowthChart data={revenueChart} />
      </div>
      {busy && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-5">
          <img src={loader} alt="" className="w-6 animate-spin" />
        </div>
      )}
    </div>
  );
};

export default RevenueGrowth;
