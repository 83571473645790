import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { toast } from 'react-toastify'

//assets
import plusIcon from "../../assets/plus.svg";
import loader from "../../assets/loader.svg";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomAccordion from "../../components/CustomAccordion/CustomAccordion";
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'

import { getFaqs, deleteFaq } from '../../services/design'

const FAQ = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false)
  const [expanded, setExpanded] = useState('');
  const [deleteThis, setDeleteThis] = useState({})
  const [faqs, setFaqs] = useState([])

  const goToEdit = (uuid) => {
    history.push(`/design/FAQ/edit/${uuid}`)
  }

  const goToAdd = () => {
    history.push('/design/FAQ/add');
  }

  async function fetchFAQs() {
    try {
      setBusy(true)
      const res = await getFaqs({ page_size: 100 })
      const grouped = res.data.results.reduce((output, item) => {
        const category = item.category.name
        output[category] = output[category] || {
          category,
          questions: []
        }
        output[category].questions.push({
          uuid: item.uuid,
          title: item.title,
          content: item.content,
        })
        return output
      }, {})
      setFaqs(Object.values(grouped))
    } catch(e) {
    } finally {
      setBusy(false)
    }
  }

  async function deleteMarkedFaq() {
    try {
      toast.info('Deleting faq')
      await deleteFaq(deleteThis.uuid)
      toast.success('FAQ successfully deleted')
      setDeleteThis({})
      fetchFAQs()
    } catch {
      toast.error('Failed to delete FAQ')
    }
  }

  useEffect(() => {
    fetchFAQs()
  }, [])

  return (
    <Layout>
      <div className="w-[950px] mx-auto pb-[100px]">
        <PageTitle title="Design" subtitle="FAQ"/>
        <p className="text-[#040A1D] text-[16px] font-semibold">
          FAQ Preview
        </p>
        <div
          className="flex flex-row justify-end"
        >
          <Button
            variant="contained"
            color="primary"
            className="capitalize shadow-none"
            startIcon={<img src={plusIcon} alt="plus" className=""/>}
            onClick={goToAdd}
          >
            Add New FAQ
          </Button>
        </div>
        {busy && (
          <img src={loader} className="w-12 animate-spin" alt=""/>
        )}
        {faqs.map(({ category, questions }, index) => (
          <section key={index} class="mb-10">
            <p className="font-bold text-[24px]">{ category }</p>
            {questions.map(({ title, content, uuid }) => (
              <CustomAccordion
                key={uuid}
                details={content}
                summary={title}
                id={uuid}
                expanded={expanded === uuid}
                handleChange={(id) => {
                  setExpanded(expanded === uuid ? null : id)
                }}
                onEditClick={() => goToEdit(uuid)}
                onDeleteClick={() => setDeleteThis({title, content, uuid})}
              />
            ))}
          </section>
        ))}
        <ConfirmDeleteModal
          open={!!deleteThis.uuid}
          onConfirm={deleteMarkedFaq}
          onClose={() => setDeleteThis(null)}
        >
          <div class="text-center">
            You're about to delete faq <br/>
            "<strong>{ deleteThis.title }</strong>"
          </div>
        </ConfirmDeleteModal>
      </div>
    </Layout>
  )
}

export default FAQ;

const samples = [
  {
    summary: 'So what are you all about?',
    details: 'We are an online drinks store',
    uuid: 'non'
  },
  {
    summary: 'Can I link to your website',
    details: "No, you can't",
    uuid: 'nonio'
  }
]
