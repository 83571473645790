import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Button } from "@material-ui/core";


const CouponTabBar = ({
  children,
  onClick,
}) => {
  return (
    <div className="flex justify-between px-5 bg-white rounded-md h-[56px] items-center">
      <div className="space-x-6">{children}</div>
      <Button
        variant="contained"
        disableElevation
        className="text-white capitalize bg-orange h-[43px]"
        onClick={onClick}
      >
        <AiOutlinePlus size={24} className="mr-4"/>
        Create a Coupon
      </Button>
    </div>
  )
}

export default CouponTabBar