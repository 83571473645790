import React, { useState } from "react";
import useTableMethods from "../../customHooks/useTableMethods";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

//icons
import del from "../../assets/delete.svg";
import edit from "../../assets/edit.svg";
import view from "../../assets/view.svg";
import loader from "../../assets/loader.svg";
import campaignIcon from "../../assets/campaign-inactive.svg";

//mui
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  //   Checkbox,
  ButtonGroup,
  Button,
  TablePagination,
} from "@material-ui/core";

//components
import TableHead from "./CampaignTableHead";

//utils
import { copyToClipboard } from "@/utils";
import { formatDate } from "../../utils";

const CampaignTable = ({
  rows,
  viewLink,
  editLink,
  onDeleteClick = () => {},
  busy = false,
  notFound = false,
  search = "",
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}) => {
  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows);

  const addDefaultImage = (event) => {
    event.target.src = campaignIcon;
  };

  const copyUuid = async (uuid) => {
    await copyToClipboard(uuid);
    toast.success(`Copied uuid "${uuid}"`);
  };

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <TableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{" "}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <TableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />

          <TableBody className="bg-white">
            {rows.map((row, index) => {
              //   const isItemSelected = isSelected(index);

              return (
                <>
                  <TableRow
                    hover
                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    //   selected={isItemSelected}
                    className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                  >
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell> */}

                    <TableCell
                      title={`Click to copy uuid "${row.uuid}"`}
                      className="text-sm font-medium text-gray2"
                      onClick={() => copyUuid(row.uuid)}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {formatDate(row.start_date)}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {formatDate(row.end_date)}
                    </TableCell>

                    <TableCell align="right">
                      <ButtonGroup size="medium">
                        <Button onClick={() => onDeleteClick(row)}>
                          <img src={del} alt="delete campaign" />
                        </Button>

                        <Button>
                          <Link to={`/campaigns/edit-campaign/${row.uuid}`}>
                            <img src={edit} alt="edit campaign" />
                          </Link>
                        </Button>
                        <Button>
                          <Link to={`/campaigns/single-campaign/${row.uuid}`}>
                            <img src={view} alt="view campain" />
                          </Link>
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1);
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default CampaignTable;
