import { useHistory } from "react-router";
import { Accordion, AccordionSummary, AccordionDetails, ButtonGroup, Button } from "@material-ui/core";
import { MdExpandMore } from "react-icons/md";

//assets
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";

const CustomAccordion = ({
  summary,
  details,
  expanded,
  id,
  handleChange,
  onEditClick=()=>{},
  onDeleteClick=()=>{},
}) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={() => handleChange(id)}
      className="mt-1 mb-1 shadow-none"
    >
      <AccordionSummary
        id={`${id} header`}
        expandIcon={<MdExpandMore color={expanded ? '#F3641F' : '#9CA7B8'}/>}
        aria-controls={`${id}bh-content`}
      >
        <p className={`${expanded ? 'text-orange' : 'text-appBlack'} text-[16px] font-semibold m-0`}>
          {summary}
        </p>
      </AccordionSummary>
      <AccordionDetails className="flex flex-col">
        <p className="text-[16px]">{details}</p>
        <div
          className="flex flex-row justify-end"
        >
          <ButtonGroup size="medium">
            <Button onClick={onEditClick}>
              <img src={editIcon} alt=""/>
            </Button>
            <Button onClick={onDeleteClick}>
              <img src={deleteIcon} alt=""/>
            </Button>
          </ButtonGroup>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion;
