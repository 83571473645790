import { useEffect, useState, createRef } from "react";
import { useHistory } from 'react-router-dom';
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import Editor from '@components/TinyMCE';

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import FormInput from "../../components/FormInput/FormInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomMultiSelect from "../../components/CustomMultiSelect/CustomMultiSelect";
import CreateCategoryModal from "../../components/BlogCategoryModal/CreateCategoryModal";
import CreateTagModal from "../../components/BlogTagModal/CreateTagModal";

//utils
import { fetchAllBlogCategories } from "../../utils";

//icons
import upArrow from "../../assets/up-arrow.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import loader from "../../assets/loader.svg";

//services
import { createCategory, getBlogTags, createTag, createPost, updatePost } from "../../services/blog";

const AddBlog = () => {
  const history = useHistory();

  const [busy, setBusy] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showCategoryAddModal, setShowCategoryAddModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [content, setContent] = useState("");
  const fileInput = createRef();


  const checkForEmptyFields = () => {
    if(title.length > 0 && subtitle.length > 0 && author.length > 0 && image 
      && status.length > 0 && content.length > 0)
      setDisabled(false);
  }

  const fetchCategories = async() => {
    setBusy(true);
    try {
      const data = await fetchAllBlogCategories();
      setCategories(data);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("Unable to fetch blog categories.");
      history.goBack()
    }
  }

  const fetchTags = async() => {
    setBusy(true);
    try {
      const { data } = await getBlogTags();
      setTags(data.results);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch tags");
    }
  }

  const addTag = (tag) => {
    let copy = [...selectedTags];
    const index = copy.findIndex(item => item.uuid === tag.uuid);
    if(index === -1) {
      copy.push(tag);
      setSelectedTags(copy);
    }
  }

  const removeTag = (tag) => {
    let updatedList = selectedTags.filter(item => item.uuid !== tag.uuid);
    setSelectedTags(updatedList);
  }

  const addCategory = (category) => {
    let copy = [...selectedCategories];
    const index = copy.findIndex(item => item.uuid === category.uuid);
    if(index === -1) {
      copy.push(category);
      setSelectedCategories(copy);
    }
  }

  const removeCategory = (category) => {
    let updatedList = selectedCategories.filter(item => item.uuid !== category.uuid);
    setSelectedCategories(updatedList);
  }

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    setImage(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    fileInput.current.click();
    fileInput.current.onchange = () => {
      if (fileInput.current.files.length) {
        handleFiles(fileInput.current.files);
      }
    };
  };

  const handleAddSubmit = async(form) => {
    setBusy(false);
    try {
      await createTag(form);
      setBusy(false);
      fetchTags();
      setShowAddModal(false);
      toast.success("Tag created successfully");
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to create tag.")
    }
  }

  const handleCategoryCreateSubmit = async(form) => {
    setBusy(true);
    try {
      await createCategory(form);
      setBusy(false);
      fetchCategories();
      setShowCategoryAddModal(false);
      toast.success("Category created successfully");
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to create category.")
    }
  }

  const clearForm = () => {
    setTitle("");
    setSubTitle("");
    setAuthor("");
    setContent("");
    setImage(null);
    setFile(null);
    setStatus("");
    setSelectedTags([])
    setSelectedCategories([])
  }

  const extractIds = async(data) => {
    let list = [];
    data.forEach(element => {
      list.push(element.id);
    });
    return list;
  }

  const generateForm = async() => {
    const form = {
      "title": title,
      "sub_title": subtitle,
      'blog_author': author,
      'body': content,
    };
    if(status === "Private")
      form["status"] = "Draft";
    else
      form["status"] = status;
    if(selectedTags.length > 0) {
      const list = await extractIds(selectedTags);
      form["blog_tag"] = list;
    }
    if(selectedCategories.length > 0) {
      const list = await extractIds(selectedCategories);
      form["category"] = list;
    }
    return form;
  }

  const handleSubmit = async() => {
    setBusy(true);
    const form = await generateForm();
    try {
      await createPost(form)
        .then(async(res) => {
          const formData = new FormData();
          formData.append('img', file);
          await updatePost(res.data.uuid, formData);
        })
      clearForm();
      setBusy(false);
      toast.success('Blog post successfully created.')
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to add blog post.");
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchTags();
  }, [])

  useEffect(() => {
    checkForEmptyFields();
  }, [content, title, subtitle, selectedCategories, selectedTags, image, author, status])

  return (
    <Layout>
      <div className="page-padding">
        <PageTitle 
          title="Add New Story" 
          onBackPress={() => history.goBack()}
        />
        <div className="flex flex-row items-center justify-end mb-4">
          <Button
            variant="contained"
            disableElevation
            className={`relative text-white capitalize ${disabled || busy ? 'bg-orange/30' : 'bg-orange'}`}
            onClick={handleSubmit}
            disabled={disabled}
          >
            Save
            {busy && (
              <img src={loader} alt="" className="absolute right-0 w-5 animate-spin" />
            )}
          </Button>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2 p-5 space-y-3 bg-white rounded">
            <h1 className="">Story Information</h1>
            <FormInput 
              type="text"
              label="Title"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <FormInput 
              type="text"
              label="Subtitle"
              placeholder="Subtitle"
              value={subtitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <FormInput 
              type="text"
              label="Author"
              placeholder="Author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div className="col-span-1 p-5 space-y-3 bg-white rounded">
            <h1 className="">Story Information</h1>
            <form autoComplete="off">
              <CustomSelect 
                id="blog-status"
                name="Blog Status"
                label="Status/Visibility"
                options={["Private", "Published"]}
                value={status}
                setValue={setStatus}
              />
            </form>
          </div>
          <div className="col-span-2 p-5 space-y-3 bg-white">
            <h1>Content</h1>
            <Editor
              init={{
                height: "90%"
              }}
              value={content}
              onChange={setContent}
            />
          </div>
          <div className="col-span-1 space-y-6">
            <div className="p-5 space-y-3 bg-white rounded">
              <h1>Categories</h1>
              <CustomMultiSelect 
                optionKey={"category_name"}
                options={categories}
                addItem={addCategory}
                removeItem={removeCategory}
                selections={selectedCategories}
                newBtnLabel={"Add a new Category"}
                onNewClick={() => setShowCategoryAddModal(true)}
              />
            </div>
            <div className="p-5 space-y-3 bg-white rounded">
              <h1>Tags</h1>
              <CustomMultiSelect 
                optionKey={"tag_name"}
                options={tags}
                addItem={addTag}
                removeItem={removeTag}
                selections={selectedTags}
                newBtnLabel={"Add a new Tag"}
                onNewClick={() => setShowAddModal(true)}
              />
            </div>
            <div className="p-5 space-y-3 bg-white rounded">
              <h1>Feature Image</h1>
              <input type="file" hidden ref={fileInput} />
              {image ? (
                <div className="w-full border-dashed rounded h-[210px] relative mt-6 border border-[#E5E5E5]">
                  <img
                    src={image}
                    alt=""
                    className="w-full h-full rounded-md"
                  />
                  <div className="absolute left-0 right-0 flex flex-row items-center mx-auto bg-white rounded-md bottom-4 w-[fit-content]">
                    <button
                      onClick={() => {
                        setImage(null);
                        setFile(null);
                      }}
                      className="p-2"
                    >
                      <img
                        src={deleteIcon}
                        alt=""
                        className="w-[22.03px] h-[22.33px]"
                      />
                    </button>
                    <div className="h-[38px] w-[1px] bg-[#E3E7ED]"></div>
                    <button className="p-2" onClick={handleFileSelect}>
                      <img
                        src={editIcon}
                        alt=""
                        className="w-[22.03px] h-[22.33px]"
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                  className="flex flex-col items-center justify-center w-full mt-6 border border-dashed rounded-md py-[40px]"
                >
                  <img src={upArrow} alt="" className="w-[41.67px] h-[41.36px]" />
                  <button
                    onClick={handleFileSelect}
                    className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mt-3 font-medium"
                  >
                    Upload file
                  </button>
                  <p className="text-[#4C536A] text-[14px] mt-3">
                    or drop file to upload
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateTagModal 
        open={showAddModal}
        closeModal={() => setShowAddModal(false)}
        onSubmit={handleAddSubmit}
        busy={busy}
      />
      <CreateCategoryModal 
        open={showCategoryAddModal}
        closeModal={() => setShowCategoryAddModal(false)}
        onSubmit={handleCategoryCreateSubmit}
        busy={busy}
      />
    </Layout>
  )
}

export default AddBlog
