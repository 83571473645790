import React from "react";
import { Link } from "react-router-dom";

import useTableMethods from "../../customHooks/useTableMethods";

//icons
import edit from "../../assets/edit.svg";
import view from "../../assets/view.svg";
import loader from "../../assets/loader.svg";


import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
  Avatar,
  TablePagination
} from "@material-ui/core";

//components
import TableHead from "./CustomTableHead";

const RetailersTable = ({ rows, search = "", notFound = false, busy = false, count, 
  onPageChange, onRowsPerPageChange, page, rowsPerPage }) => {
  // const history = useHistory();

  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows);

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <TableHead 
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
                <img src={loader} alt="loading" className="w-5 animate-spin" />
              ) : (
                <>
                  <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                    No Search Result for "{search}"
                  </p>
                  <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                    <span className="text-[#8892A2] text-[14px] font-bold">
                      Search tips:{" "}
                    </span>
                    Some search terms require an exact match. Try typing the
                    entire term, or use a different word or phrase.
                  </p>
                </>
              )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <TableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />

          <TableBody className="bg-white">
            {rows.map((row, index) => {
              const isItemSelected = isSelected(index);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="flex items-center space-x-2">
                    <Avatar src={row.store_image}></Avatar>
                    <p className="text-sm font-medium text-gray2">{row.name}</p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.business_email}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.business_phone}
                  </TableCell>

                  <TableCell className="text-sm font-medium text-gray2">
                    {row.address && row.address.address}
                  </TableCell>

                  <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button>
                        <Link to={`/retailers/edit/${row.uuid}`}>
                          <img src={edit} alt="" />
                        </Link>
                      </Button>
                      <Button>
                        <Link to={`/retailers/${row.uuid}`}>
                          <img src={view} alt="" />
                        </Link>
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 40]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1);
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default RetailersTable;
