import { useContext, useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from '@sentry/react'

//routes
import AdminRoute from './routes/AdminRoute'
import RetailerRoute from './routes/RetailerRoute'
import ProtectedRoute from './routes/ProtectedRoute'
import AuthRoute from './routes/AuthRoute'
import ErrorPage from './pages/error-page'

//pages
import Login from './pages/login'
import LoginVerifyIdentity from './pages/login/verify-identity'
import ResetPassword from './pages/reset-password'
import ResetVerifyIdentity from './pages/reset-password/verify-identity'
import NewPassword from './pages/reset-password/new-password'
import ResetSuccess from './pages/reset-password/reset-success'
import ResetPin from './pages/reset-pin'
import ResetPinVerifyIdentity from './pages/reset-pin/verify-identity'
import NewPin from './pages/reset-pin/new-pin'
import ResetPinSuccess from './pages/reset-pin/reset-success'
import Home from './pages/home'
import Analytics from './pages/analytics'
import Orders from './pages/orders'
import CreateOrder from './pages/orders/createOrder'
import UpdateOrder from './pages/orders/updateOrder'
import Finance from './pages/finance'
import Products from './pages/products'
import AddNewProduct from './pages/products/add-new-product'
import ProductRequestDetails from './pages/products/product-request-details'
import ProductRequestEdit from './pages/products/product-request-edit'
import SingleProduct from './pages/products/single-product'
import Customers from './pages/customers'
import Retailers from './pages/retailers'
import Brands from './pages/brands'
import Vouchers from './pages/vouchers'
import AddNewRetailer from './pages/retailers/add-new-retailer'
import SingleRetailer from './pages/retailers/single-retailer'
import EditRetailer from './pages/retailers/edit-retailer'
import Users from './pages/users'
import AddNewUser from './pages/users/add-new-user'
import SingleUser from './pages/users/single-user'
import EditUser from './pages/users/edit-user'
import TransactionHistory from './pages/finance/transactionHistory'
import TransactionDetail from './pages/finance/transactionDetail'
import Profile from './pages/profile'
import CustomerDetails from './pages/customers/customerDetails'
import AddCustomer from './pages/customers/addCustomer'
import UpdateCustomer from './pages/customers/updateCustomer'
import Campaigns from './pages/campaigns'
import Inventory from './pages/inventory'
import InventorySingleProduct from './pages/inventory/single-product'
import InventoryProductDetails from './pages/inventory/product-request-details'
import InventoryProductEdit from './pages/inventory/product-request-edit'
import AddNewProductToInventory from './pages/inventory/add-new-product'
import ProductsToAdd from './pages/inventory/products-to-add'
import InventoryProductRequest from './pages/inventory/add-new-product-request'
import Attributes from './pages/products/attributes'
import Categories from './pages/products/categories'
import MobileView from './pages/mobile-view'
import BlogPosts from './pages/blog/index'
import BlogCategories from './pages/blog/categories'
import AddBlog from './pages/blog/add-blog'
import EditBlog from './pages/blog/edit-blog'
import Coupons from './pages/coupons/index'
import AddCoupon from './pages/coupons/add-coupon'
import SingleCoupon from './pages/coupons/single-coupon'
import SlideShow from './pages/design/slideshow'
import AboutDrinks from './pages/design/about'
import Contact from './pages/design/contact'
import PrivacyPolicy from './pages/design/privacy'
import TermsAndConditions from './pages/design/terms'
import NotFound from './pages/404'
import EditCoupon from './pages/coupons/edit-coupon'
import FAQ from './pages/design/faq'
import AddFAQ from './pages/design/add-faq'
import EditFAQ from './pages/design/edit-faq'
import RetailerSettings from './pages/settings/retailer/index'

import { RootContext } from '.'
import AddNewBrand from './pages/brands/add-new-brand'
import SingleBrand from './pages/brands/single-brand'
import EditBrand from './pages/brands/edit-brand'
import AddNewCampaign from './pages/campaigns/add-new-campaign'
import SingleCampaign from './pages/campaigns/single-campaign'
import SingleSegment from './pages/campaigns/single-segment'
import EditCampaign from './pages/campaigns/edit-campaign'

const App = observer(() => {
  const root = useContext(RootContext)

  useEffect(() => {
    root.authStore.autoSignIn().catch((_) => {
      toast.error('Error signing in. Check your network and try again.')
    })
  }, [root.authStore])

  return (
    <ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorPage onClick={resetError} />
      )}
    >
      <div className="tooSmall:hidden">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <Switch>
          <ProtectedRoute exact path="/">
            <Redirect to="/home" />
          </ProtectedRoute>

          <ProtectedRoute exact path="/home">
            <Home />
          </ProtectedRoute>

          <AdminRoute exact path="/analytics">
            <Analytics />
          </AdminRoute>

          <AuthRoute exact path="/login">
            <Login />
          </AuthRoute>

          <AuthRoute path="/login/:email/verify-identity">
            <LoginVerifyIdentity />
          </AuthRoute>

          <AuthRoute exact path="/reset-password">
            <ResetPassword />
          </AuthRoute>

          <AuthRoute path="/reset-password/:email/verify-identity">
            <ResetVerifyIdentity />
          </AuthRoute>

          <AuthRoute path="/reset-password/:email/new-password/:token">
            <NewPassword />
          </AuthRoute>

          <AuthRoute path="/reset-password/reset-success">
            <ResetSuccess />
          </AuthRoute>

          <AuthRoute exact path="/reset-pin">
            <ResetPin />
          </AuthRoute>

          <AuthRoute path="/reset-pin/:phoneNumber/verify-identity">
            <ResetPinVerifyIdentity />
          </AuthRoute>

          <AuthRoute path="/reset-pin/:phoneNumber/new-pin/:otp">
            <NewPin />
          </AuthRoute>

          <AuthRoute path="/reset-pin/reset-success">
            <ResetPinSuccess />
          </AuthRoute>

          <AdminRoute exact path="/products">
            <Products />
          </AdminRoute>

          <AdminRoute exact path="/products/add-new-product">
            <AddNewProduct />
          </AdminRoute>

          <AdminRoute exact path="/products/requests/details/:id">
            <ProductRequestDetails />
          </AdminRoute>

          <AdminRoute exact path="/products/requests/edit/:id">
            <ProductRequestEdit />
          </AdminRoute>

          <AdminRoute exact path="/products/single-product/:id">
            <SingleProduct />
          </AdminRoute>

          <AdminRoute exact path="/products/attributes">
            <Attributes />
          </AdminRoute>

          <AdminRoute exact path="/products/categories">
            <Categories />
          </AdminRoute>

          <AdminRoute exact path="/blog/add">
            <AddBlog />
          </AdminRoute>

          <AdminRoute exact path="/blog/edit/:id">
            <EditBlog />
          </AdminRoute>

          <AdminRoute exact path="/vouchers">
            <Vouchers />
          </AdminRoute>

          <RetailerRoute exact path="/inventory">
            <Inventory />
          </RetailerRoute>

          <RetailerRoute exact path="/inventory/requests/details/:product">
            <InventoryProductDetails />
          </RetailerRoute>

          <RetailerRoute exact path="/inventory/requests/edit">
            <InventoryProductEdit />
          </RetailerRoute>

          <RetailerRoute exact path="/inventory/:product">
            <InventorySingleProduct />
          </RetailerRoute>

          <RetailerRoute exact path="/inventory/product/add">
            <AddNewProductToInventory />
          </RetailerRoute>

          <RetailerRoute exact path="/inventory/products/products-to-add">
            <ProductsToAdd />
          </RetailerRoute>

          <RetailerRoute exact path="/inventory/requests/add">
            <InventoryProductRequest />
          </RetailerRoute>

          <RetailerRoute exact path="/orders">
            <Orders />
          </RetailerRoute>

          <RetailerRoute path="/orders/create-order">
            <CreateOrder />
          </RetailerRoute>

          <RetailerRoute path="/orders/update-order/:id">
            <UpdateOrder />
          </RetailerRoute>

          <RetailerRoute path="/settings">
            <RetailerSettings />
          </RetailerRoute>

          <ProtectedRoute exact path="/finance">
            <Finance />
          </ProtectedRoute>

          <ProtectedRoute exact path="/finance/history">
            <TransactionHistory />
          </ProtectedRoute>

          <ProtectedRoute exact path="/finance/transaction-detail/:id">
            <TransactionDetail />
          </ProtectedRoute>

          <ProtectedRoute exact path="/customers">
            <Customers />
          </ProtectedRoute>

          <ProtectedRoute exact path="/customers/customer-details/:slug">
            <CustomerDetails />
          </ProtectedRoute>

          <ProtectedRoute exact path="/customers/add-customer">
            <AddCustomer />
          </ProtectedRoute>

          <ProtectedRoute exact path="/customers/update-customer/:slug">
            <UpdateCustomer />
          </ProtectedRoute>

          <AdminRoute exact path="/retailers">
            <Retailers />
          </AdminRoute>

          <AdminRoute exact path="/retailers/add-new-retailer">
            <AddNewRetailer />
          </AdminRoute>

          <AdminRoute exact path="/retailers/:retailer">
            <SingleRetailer />
          </AdminRoute>

          <AdminRoute exact path="/retailers/edit/:id">
            <EditRetailer />
          </AdminRoute>

          <AdminRoute exact path="/retailers/single-product/:id">
            <SingleProduct />
          </AdminRoute>

          <AdminRoute exact path="/retailers/product/edit/:id">
            <ProductRequestEdit />
          </AdminRoute>

          <AdminRoute exact path="/blog">
            <BlogPosts />
          </AdminRoute>

          <AdminRoute exact path="/blog/categories">
            <BlogCategories />
          </AdminRoute>

          <AdminRoute exact path="/coupons">
            <Coupons />
          </AdminRoute>

          <AdminRoute exact path="/coupons/single-coupon/:id">
            <SingleCoupon />
          </AdminRoute>

          <AdminRoute exact path="/coupons/add-coupon">
            <AddCoupon />
          </AdminRoute>

          <AdminRoute exact path="/coupons/edit-coupon/:slug">
            <EditCoupon />
          </AdminRoute>
          <AdminRoute exact path="/design/slideshow">
            <SlideShow />
          </AdminRoute>

          <AdminRoute exact path="/design/terms">
            <TermsAndConditions />
          </AdminRoute>
          <AdminRoute exact path="/design/privacy">
            <PrivacyPolicy />
          </AdminRoute>
          <AdminRoute exact path="/design/contact">
            <Contact />
          </AdminRoute>
          <AdminRoute exact path="/design/about">
            <AboutDrinks />
          </AdminRoute>

          <AdminRoute exact path="/design/FAQ">
            <FAQ />
          </AdminRoute>

          <AdminRoute exact path="/design/FAQ/add">
            <AddFAQ />
          </AdminRoute>

          <AdminRoute exact path="/design/FAQ/edit/:id">
            <EditFAQ />
          </AdminRoute>

          <AdminRoute exact path="/brands">
            <Brands />
          </AdminRoute>

          <AdminRoute exact path="/brands/add-new-brand">
            <AddNewBrand />
          </AdminRoute>

          <AdminRoute exact path="/brand/:brand">
            <SingleBrand />
          </AdminRoute>

          <AdminRoute exact path="/brand/edit/:brand">
            <EditBrand />
          </AdminRoute>

          <ProtectedRoute exact path="/users">
            <Users />
          </ProtectedRoute>

          <ProtectedRoute exact path="/users/add-new-user">
            <AddNewUser />
          </ProtectedRoute>

          <ProtectedRoute exact path="/users/:userId">
            <SingleUser />
          </ProtectedRoute>

          <ProtectedRoute exact path="/users/edit/:userId">
            <EditUser />
          </ProtectedRoute>

          <AdminRoute exact path="/campaigns">
            <Campaigns />
          </AdminRoute>

          <AdminRoute exact path="/campaigns/single-campaign/:id">
            <SingleCampaign />
          </AdminRoute>

          <AdminRoute exact path="/campaigns/edit-campaign/:id">
            <EditCampaign />
          </AdminRoute>

          <AdminRoute exact path="/campaigns/add-new-campaign">
            <AddNewCampaign />
          </AdminRoute>

          <AdminRoute exact path="/campaigns/:slug/single-segment/:id">
            <SingleSegment />
          </AdminRoute>

          <ProtectedRoute exact path="/profile">
            <Profile />
          </ProtectedRoute>
          <ProtectedRoute exact path="*">
            <NotFound />
          </ProtectedRoute>
        </Switch>
      </div>
      <MobileView />
    </ErrorBoundary>
  )
})

export default App
