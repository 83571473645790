import { useEffect, useState } from "react";
import { Modal, Button, Checkbox } from "@material-ui/core";
import { IoClose, IoCloseCircle, IoArrowUp,  } from "react-icons/io5";
import Editor from '@components/TinyMCE'
import { toast } from "react-toastify";

//assets
import loader from "../../assets/loader.svg";

//components
import FormInput from "../FormInput/FormInput";
import CustomSelect from "../CustomSelect/CustomSelect";

import { copyToClipboard } from '@/utils'

const EditCategoryModal = ({ open, closeModal, parents = [], category, onSubmit }) => {
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState(category.name || '');
  const [metaTitle, setMetaTitle] = useState(category.title_meta || '');
  const [parent, setParent] = useState(category.parent);
  const [isFeatured, setIsFeatured] = useState(category.featured || false);
  const [desc, setDesc] = useState(category.description || '');
  const [metaDesc, setMetaDesc] = useState(category.description_meta || '');
  const [images, setImages] = useState(category.image ? [category.image] : []);
  const [imageAlt, setImageAlt] = useState(category.image_alt)
  const [disabled, setDisabled] = useState(true);

  const [viewId, setViewId] = useState(true)

  const handleClose = () => {
    clearForm();
    closeModal();
  }

  const generateForm = () => {
    const form = new FormData();
    form.append("name", name);
    if(parent)
      form.append("parent", parent.id);
    form.append('featured', isFeatured)
    form.append("description", desc);
    form.append("title_meta", metaTitle);
    form.append("description_meta", metaDesc);
    form.append("image_alt", imageAlt);
    if(images[0] !== category.image)
      form.append("image", images[0])
    return form;
  }

  const handleSubmit = async() => {
    setBusy(true);
    const form = generateForm();
    await onSubmit(form, category.id);
    setBusy(false);
  }

  const checkForEmptyFields = () => {
    if(name.length > 0 && desc.length > 0 && images.length > 0) {
      setDisabled(false);
    }
  }

  const clearForm = () => {
    setName("");
    setParent("");
    setDesc("");
    setImages([]);
  }

  const copyId = async () => {
    const text = viewId ? category.id : category.uuid
    await copyToClipboard(text)
    toast.success(`Copied category id "${text}"`)
  }

  useEffect(() => {
    checkForEmptyFields();
  }, [name, parent, desc, images])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <div className="bg-white w-[606px] rounded">
        <div className="relative flex flex-row items-center justify-center py-4 border-b-[#E3E7ED] border-b">
          <h2 className="text-[#040A1D] text-[20px] font-bold m-0">Edit Product Category</h2>
          <button
            className="absolute ml-10 top-4 right-10"
            onClick={closeModal}
          >
            <IoClose size={30} color={'#8892A2'}/>
          </button>
        </div>  
        <div className="p-10 px-14 overflow-auto h-[80vh] max-h-[700px]">
          <p className="text-sm mb-1 text-right">
            Click the text beside the button to switch between&nbsp;
            <span className={`font-semibold ${viewId ? 'text-orange' : ''}`}>id</span> and&nbsp;
            <span className={`font-semibold ${!viewId ? 'text-orange' : ''}`}>uuid</span>
          </p>
          <div className="bg-[#F4F5F7] rounded-lg p-1 pl-2 flex items-center mb-5 w-[fit-content]" style={{'margin-left': 'auto'}}>
            <span className="mr-3 font-bold" style={{ 'cursor': 'default' }} onClick={() => setViewId(!viewId)}>
              { viewId ? category.id : category.uuid }
            </span>
            <button className="bg-white p-1 rounded-md" onClick={copyId}>Copy</button>
          </div>
          <FormInput 
            id="name"
            name="name"
            placeholder="Add Category Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="py-2"></div>
          <CustomSelect
            id="parent"
            name="parent"
            label="Parent"
            value={parent ? parent : ""}
            subCategory={true}
            options={parents}
            setValue={(item) => setParent(item)}
          />
          <div className="py-2"></div>
          <div className="px-5 bg-[#F4F5F7] rounded-md">
            <label>Featured</label>
            <Checkbox
              checked={isFeatured}
              onClick={() => setIsFeatured(!isFeatured)}
            />
          </div>
          <div className="py-2"></div>
          <div className="col-span-2 p-5 space-y-3 bg-[#F4F5F7] rounded-md">
            <label>Add Description</label>
            <Editor
              value={desc}
              onChange={setDesc}
              className="bg-white"
            />
          </div>
          <div className="py-2"></div>
          <FormInput
            id="meta-title"
            name="meta-title"
            placeholder="Meta Title"
            label="Meta Title"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
          <div className="py-2"></div>
          <FormInput
            id="meta-description"
            name="meta-description"
            placeholder="Meta Description"
            label="Meta Description"
            value={metaDesc}
            onChange={(e) => setMetaDesc(e.target.value)}
          />
          <div className="py-2"></div>
          <h1 className="mt-8 font-medium text-[#3D4356]">Upload Category Image</h1>
          <div className="bg-white w-fullspace-y-3">
            <div
              className={`grid mb-4 gap-5 ${
                images.length > 0
                  ? "grid-cols-[2fr,1fr]"
                  : "grid-cols-[1fr,1fr,1fr]"
              }`}
            >
              {images.length > 0 && (
                <div className="grid grid-cols-2 gap-5 ">
                  {images.map((image, idx) => {
                    return (
                      <div className="relative border border-gray-300 border-dashed rounded-md">
                        <IoCloseCircle
                          color={"white"}
                          className="absolute cursor-pointer w-7 h-7 top-5 right-5"
                          onClick={() => {
                            setImages(images.filter((_, index) => index !== idx));
                          }}
                        />
                        <img
                          key={idx}
                          src={image}
                          alt=""
                          className="object-cover w-[216px] h-[219px] rounded-md"
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                  <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                </div>
                <label
                  htmlFor="product-category-images"
                  className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                >
                  <p>Upload File</p>
                  <input
                    type="file"
                    id="product-category-images"
                    accept=".png,.jpeg,.jpg"
                    onChange={(e) => {
                      setImages([...e.target.files]);
                    }}
                    className="hidden"
                  />
                </label>

                <p className="text-sm text-gray2">or drop file to upload</p>
              </div>
            </div>
            <FormInput
              id="alt-text"
              name="alt-text"
              placeholder="Image Alt Text"
              label="Image Alt Text"
              value={imageAlt}
              onChange={(e) => setImageAlt(e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center justify-end mt-8 space-x-4">
            <Button
              variant="outlined"
              className="capitalize"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={`text-white capitalize shadow-none relative ${
                disabled || busy
                  ? "bg-orange/30 cursor-not-allowed"
                  : "bg-orange cursor-pointer"
              }`}
            >
              Save
              {busy && (
                <img src={loader} className="absolute w-5 animate-spin" alt=""/> 
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditCategoryModal
