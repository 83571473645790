import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.svg";
import success from "../../assets/success.svg";

//components
import CustomButton from "../../components/CustomButton/CustomButton";

const ResetSuccess = () => {
  const history = useHistory();

  return (
    <div className="flex justify-center items-center h-screen pt-10 pb-16 bg-[#E5E5E5]">
      <div className="flex flex-col items-center space-y-5 w-[450px]">
        <img src={logo} alt="" className="w-32" />

        <div className="flex flex-col items-center py-10 space-y-5 bg-white rounded-md">
          <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
            <img src={success} alt="" className="" />
          </span>

          <h1 className="text-2xl font-bold text-center">
            Successful PIN reset
          </h1>

          <p className="text-center text-gray2 w-[65%]">
            You can now use your new PIN to log in to your account 🙌🏾
          </p>
        </div>

        <CustomButton
          text="Continue to login"
          onClick={() => {
            history.push("/login");
          }}
        />
      </div>
    </div>
  );
};

export default ResetSuccess;
