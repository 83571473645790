import { Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const history = createBrowserHistory()
const {
  REACT_APP_SENTRY_DSN: dsn,
  REACT_APP_SENTRY_ENVIRONMENT: environment,
  NODE_ENV,
} = process.env

Sentry.init({
  dsn,
  environment,
  enabled: ['gamma', 'beta'].includes(environment),
  debug: NODE_ENV === 'development',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export default Sentry
export const SentryRoute = Sentry.withSentryRouting(Route)
