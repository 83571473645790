import { useEffect, useState, createRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { toast } from 'react-toastify'
import Editor from '@components/TinyMCE'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import FormInput from '../../components/FormInput/FormInput'
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import CustomMultiSelect from '../../components/CustomMultiSelect/CustomMultiSelect'
import CreateCategoryModal from '../../components/BlogCategoryModal/CreateCategoryModal'
import CreateTagModal from '../../components/BlogTagModal/CreateTagModal'

//utils
import {
  fetchAllBlogCategories,
  formatDate,
  formatTime,
  copyToClipboard,
} from '../../utils'
//icons
import upArrow from '../../assets/up-arrow.svg'
import deleteIcon from '../../assets/delete.svg'
import editIcon from '../../assets/edit.svg'
import loader from '../../assets/loader.svg'

//services
import {
  createCategory,
  getBlogTags,
  createTag,
  updatePost,
  getPost,
} from '../../services/blog'
import Loader from '../../components/Loader/Loader'

const EditBlog = () => {
  const history = useHistory()
  const { id } = useParams()
  const [busy, setBusy] = useState(true)
  const [blogPost, setBlogPost] = useState()
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [showCategoryAddModal, setShowCategoryAddModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [title, setTitle] = useState('')
  const [subtitle, setSubTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [status, setStatus] = useState('')
  const [image, setImage] = useState()
  const [file, setFile] = useState()
  const [date, setDate] = useState('')
  const [content, setContent] = useState('')
  const fileInput = createRef()

  const fetchBlogPost = async () => {
    try {
      const { data } = await getPost(id)
      setBlogPost(data)
      setBusy(false)
    } catch (error) {
      toast.error('An error occurred. Unable to fetch blog post.')
      history.goBack()
    }
  }

  const fetchCategories = async () => {
    setBusy(true)
    try {
      const data = await fetchAllBlogCategories()
      setCategories(data)
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('Unable to fetch blog categories.')
      history.goBack()
    }
  }

  const fetchTags = async () => {
    setBusy(true)
    try {
      const { data } = await getBlogTags()
      setTags(data.results)
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch tags')
    }
  }

  const addTag = (tag) => {
    let copy = [...selectedTags]
    const index = copy.findIndex((item) => item.uuid === tag.uuid)
    if (index === -1) {
      copy.push(tag)
      setSelectedTags(copy)
    }
  }

  const removeTag = (tag) => {
    let updatedList = selectedTags.filter((item) => item.uuid !== tag.uuid)
    setSelectedTags(updatedList)
  }

  const addCategory = (category) => {
    let copy = [...selectedCategories]
    const index = copy.findIndex((item) => item.uuid === category.uuid)
    if (index === -1) {
      copy.push(category)
      setSelectedCategories(copy)
    }
  }

  const removeCategory = (category) => {
    let updatedList = selectedCategories.filter(
      (item) => item.uuid !== category.uuid
    )
    setSelectedCategories(updatedList)
  }

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const fileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length) {
      handleFiles(files)
    }
  }

  const handleFiles = (files) => {
    setImage(URL.createObjectURL(files[0]))
    setFile(files[0])
  }

  const handleFileSelect = (e) => {
    e.preventDefault()
    fileInput.current.click()
    fileInput.current.onchange = () => {
      if (fileInput.current.files.length) {
        handleFiles(fileInput.current.files)
      }
    }
  }

  const handleAddSubmit = async (form) => {
    setBusy(false)
    try {
      await createTag(form)
      setBusy(false)
      fetchTags()
      setShowAddModal(false)
      toast.success('Tag created successfully')
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to create tag.')
    }
  }

  const handleCategoryCreateSubmit = async (form) => {
    setBusy(true)
    try {
      await createCategory(form)
      setBusy(false)
      fetchCategories()
      setShowCategoryAddModal(false)
      toast.success('Category created successfully')
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to create category.')
    }
  }

  const extractIds = async (data) => {
    let list = []
    data.forEach((element) => {
      list.push(element.id)
    })
    return list
  }

  const generateForm = async () => {
    const form = {
      title: title,
      sub_title: subtitle,
      blog_author: author,
      body: content,
    }
    if (status === 'Private') form['status'] = 'Draft'
    else form['status'] = status
    if (selectedTags.length > 0) {
      const list = await extractIds(selectedTags)
      form['blog_tag'] = list
    }
    if (selectedCategories.length > 0) {
      const list = await extractIds(selectedCategories)
      form['category'] = list
    }
    return form
  }

  const handleSubmit = async () => {
    setBusy(true)
    const form = await generateForm()
    try {
      await updatePost(blogPost.uuid, form)
      if (file) {
        const formData = new FormData()
        formData.append('img', file)
        await updatePost(blogPost.uuid, formData)
      }
      setBusy(false)
      toast.success('Blog post updated successfully.')
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to update blog post.')
    }
  }

  const copyPreviewLink = async () => {
    await copyToClipboard(previewLink)
    toast.success(`Copied blog preview link`)
  }

  const updateFields = () => {
    setTitle(blogPost.title)
    setSubTitle(blogPost.sub_title)
    setAuthor(blogPost.blog_author)
    setStatus(blogPost.status)
    setContent(blogPost.body)
    setDate(blogPost.updated_at)
    setImage(blogPost.img)
    setSelectedCategories(blogPost.category)
    setSelectedTags(blogPost.blog_tag)
  }

  useEffect(() => {
    fetchTags()
    fetchBlogPost()
    fetchCategories()
  }, [])

  useEffect(() => {
    if (blogPost) {
      updateFields()
    }
  }, [blogPost])

  const previewLink = `${process.env.REACT_APP_WEB_URL}/blog/post/${blogPost?.slug}?agent=webview`

  return (
    <Layout>
      <div className="page-padding">
        <PageTitle title="Edit Story" onBackPress={() => history.goBack()} />
        {busy && !blogPost ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex-row items-center justify-end mb-4">
              <Button
                variant="contained"
                disableElevation
                onClick={handleSubmit}
                className={`relative text-white capitalize ${
                  busy ? 'bg-orange/30' : 'bg-orange'
                }`}
              >
                Save
                {busy && (
                  <img
                    src={loader}
                    alt=""
                    className="absolute right-0 w-5 animate-spin"
                  />
                )}
              </Button>
            </div>
            <div className="bg-grayBg rounded-lg p-1 flex items-center mb-5 w-[fit-content]">
              <button
                className="bg-green text-white py-1 px-2 rounded-md"
                onClick={copyPreviewLink}
              >
                Click to copy preview link
              </button>
              {/* <span className="ml-3 font-bold">{previewLink}</span> */}
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-2 p-5 space-y-3 bg-white rounded">
                <h1 className="">Story Information</h1>
                <FormInput
                  type="text"
                  label="Title"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <FormInput
                  type="text"
                  label="Subtitle"
                  placeholder="Subtitle"
                  value={subtitle}
                  onChange={(e) => setSubTitle(e.target.value)}
                />
                <FormInput
                  type="text"
                  label="Author"
                  placeholder="Author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
              <div className="col-span-1 p-5 space-y-3 bg-white rounded">
                <h1 className="">Story Information</h1>
                {blogPost && blogPost.status === 'Published' && (
                  <>
                    <FormInput
                      type="text"
                      label="Publish Date"
                      placeholder="Publish Date"
                      value={formatDate(date)}
                    />
                    <FormInput
                      type="text"
                      label="Publish Time"
                      placeholder="Publish Time"
                      value={formatTime(date)}
                      disabled={true}
                    />
                  </>
                )}
                <CustomSelect
                  id="blog-status"
                  name="Blog Status"
                  label="Status/Visibility"
                  options={['Private', 'Published']}
                  value={status}
                  setValue={setStatus}
                  disabled={true}
                />
              </div>
              <div className="col-span-2 p-5 space-y-3 bg-white">
                <h1>Content</h1>
                <Editor
                  init={{
                    height: '90%',
                  }}
                  value={content}
                  onChange={setContent}
                />
              </div>
              <div className="col-span-1 space-y-6">
                <div className="p-5 space-y-3 bg-white rounded">
                  <h1>Categories</h1>
                  <CustomMultiSelect
                    optionKey={'category_name'}
                    options={categories}
                    addItem={addCategory}
                    removeItem={removeCategory}
                    selections={selectedCategories}
                  />
                </div>
                <div className="p-5 space-y-3 bg-white rounded">
                  <h1>Tags</h1>
                  <CustomMultiSelect
                    optionKey={'tag_name'}
                    options={tags}
                    addItem={addTag}
                    removeItem={removeTag}
                    selections={selectedTags}
                    newBtnLabel={'Add a new Tag'}
                    onNewClick={() => setShowAddModal(true)}
                  />
                </div>
                <div className="p-5 space-y-3 bg-white rounded">
                  <h1>Feature Image</h1>
                  <input type="file" hidden ref={fileInput} />
                  {image ? (
                    <div className="w-full border-dashed rounded h-[210px] relative mt-6 border border-[#E5E5E5]">
                      <img
                        src={image}
                        alt=""
                        className="w-full h-full rounded-md"
                      />
                      <div className="absolute left-0 right-0 flex flex-row items-center mx-auto bg-white rounded-md bottom-4 w-[fit-content]">
                        <button
                          onClick={() => {
                            setImage(null)
                            setFile(null)
                          }}
                          className="p-2"
                        >
                          <img
                            src={deleteIcon}
                            alt=""
                            className="w-[22.03px] h-[22.33px]"
                          />
                        </button>
                        <div className="h-[38px] w-[1px] bg-[#E3E7ED]"></div>
                        <button className="p-2" onClick={handleFileSelect}>
                          <img
                            src={editIcon}
                            alt=""
                            className="w-[22.03px] h-[22.33px]"
                          />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={fileDrop}
                      className="flex flex-col items-center justify-center w-full mt-6 border border-dashed rounded-md py-[40px]"
                    >
                      <img
                        src={upArrow}
                        alt=""
                        className="w-[41.67px] h-[41.36px]"
                      />
                      <button
                        onClick={handleFileSelect}
                        className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mt-3 font-medium"
                      >
                        Upload file
                      </button>
                      <p className="text-[#4C536A] text-[14px] mt-3">
                        or drop file to upload
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <CreateTagModal
          open={showAddModal}
          closeModal={() => setShowAddModal(false)}
          onSubmit={handleAddSubmit}
          busy={busy}
        />
        <CreateCategoryModal
          open={showCategoryAddModal}
          closeModal={() => setShowCategoryAddModal(false)}
          onSubmit={handleCategoryCreateSubmit}
          busy={busy}
        />
      </div>
    </Layout>
  )
}

export default EditBlog
