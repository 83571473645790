import React, { useState, useEffect, createRef } from 'react'
import { IoArrowUp, IoCloseCircle } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Editor from '@components/TinyMCE'

//icons
import productsInactive from '../../assets/products-inactive.svg'
import loader from '../../assets/loader.svg'
import success from '../../assets/success.svg'
import editIcon from '../../assets/edit.svg'
import deleteIcon from '../../assets/delete.svg'

//mui
import { Button, Dialog, Checkbox } from '@material-ui/core'

//components
import FormInput from '../FormInput/FormInput'
import CreateAttributeModal from '../CreateAttributeModal/CreateAttributeModal'
import CreateCategoryModal from '../CreateCategoryModal/CreateCategoryModal'
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect'

//utils
import {
  fetchAllProductCategories,
  fetchAllAttributes,
  fetchAllBrands,
} from '../../utils'

//services
import {
  updateProduct,
  addProductMedia,
  deleteProductMedia,
  updateProductAttribute,
  addProductAttribute,
  removeProductAttribute,
} from '../../services/products'

const UpdateProductInfo = ({ product }) => {
  product.prod_attribute.sort((a, b) => a.sequence - b.sequence)
  const history = useHistory()
  const attributeMediaInput = createRef()
  const [busy, setBusy] = useState(false)
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [attributes, setAttributes] = useState([])
  const [name, setName] = useState(product.name)
  const [metaTitle, setMetaTitle] = useState(product.title_meta)
  const [desc, setDesc] = useState(product.description)
  const [metaDesc, setMetaDesc] = useState(product.description_meta)
  const [imageAlt, setImageAlt] = useState(product.image_alt)
  const [isFeatured, setIsFeatured] = useState(product.featured)
  const [engrave, setEngrave] = useState(product.engrave)
  // const [price, setPrice] = useState(product.price.amount);
  const [selectedAttributes, setSelectedAttributes] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([
    ...product.category,
  ])
  const [selectedBrands, setSelectedBrands] = useState([
    ...(product.brand || []),
  ])
  const [showCreateAttributeModal, setShowCreateAtrributeModal] =
    useState(false)
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false)
  const [parentAttributes, setParentAttributes] = useState([])
  const [parentCategories, setParentCategories] = useState([])
  const [media, setMedia] = useState(product.media ? product.media : [])
  const [newMainImage, setNewMainImage] = useState()
  const [deleted, setDeleted] = useState([])
  const [attributesMedia, setAttributesMedia] = useState([
    ...product.prod_attribute,
  ])
  const [activeAttribute, setActiveAttribute] = useState()
  const [removedAttributes, setRemovedAttributes] = useState([])

  const handleClose = () => {
    setOpen(false)
  }

  const fetchProductCategories = async () => {
    try {
      const data = await fetchAllProductCategories()
      const sortedList = await sortCategoriesOrAttributes(data, 'category')
      setCategories(sortedList)
    } catch (error) {
      toast.error('Unable to fetch product categories. Please try again later.')
      history.goBack()
    }
  }

  const fetchBrands = async () => {
    try {
      const data = await fetchAllBrands()
      setBrands(data)
    } catch (error) {
      toast.error('Unable to fetch brands. Please try again later.')
      history.goBack()
    }
  }

  const fetchAttributes = async () => {
    try {
      const data = await fetchAllAttributes()
      const sortedList = await sortCategoriesOrAttributes(data, 'attribute')
      setAttributes(sortedList)
    } catch (error) {
      toast.error('Unable to fetch product attributes. Please try again later.')
      history.goBack()
    }
  }

  const findSelectedAttributes = () => {
    const list = []
    const sortMap = {}
    product.attribute.forEach((element) => {
      const quarry = attributes.find((item) => item.name === element.name)
      if (quarry) list.push(quarry)
    })
    product.prod_attribute.forEach((element) => {
      const isPresent = list.find(
        (item) => item.name === element.attribute.name
      )
      sortMap[element.attribute.id] = element
      if (!isPresent) {
        const quarry = attributes.find(
          (item) => item.name === element.attribute.name
        )
        if (quarry) list.push(quarry)
      }
    })
    // sort selected attributes by sequence used during creation
    list.sort((a, b) => sortMap[a.id]?.sequence - sortMap[b.id]?.sequence)
    setSelectedAttributes(list)
  }

  useEffect(() => {
    if (attributes.length > 0) findSelectedAttributes()
  }, [attributes])

  // add parent information to subcategories
  const sortCategoriesOrAttributes = async (categories, type) => {
    const parents = []
    categories.forEach((element) => {
      if (element.parent) {
        const parent = categories.find((item) => item.id === element.parent)
        element.parent_name = parent.name
      } else {
        parents.push(element)
      }
    })
    if (type === 'category') setParentCategories(parents)
    else setParentAttributes(parents)
    return categories
  }

  const generateForm = () => {
    const form = {
      name: name,
      title_meta: metaTitle,
      description: desc,
      description_meta: metaDesc,
      image_alt: imageAlt,
      featured: isFeatured,
      engrave,
      // 'price': price,
      category: getIdList(selectedCategories),
      brand: getIdList(selectedBrands),
      attribute: getIdList(selectedAttributes),
    }
    return form
  }

  const handleProductMedia = async () => {
    images.forEach(async (element) => {
      const form = new FormData()
      form.append('product', product.id)
      form.append('media', element)
      await addProductMedia(form).catch((err) => {})
    })
  }

  const handleMediaDelete = async () => {
    deleted.forEach(async (element) => {
      await deleteProductMedia(element.id).catch((err) => {})
    })
  }

  const deleteRemovedAttributes = async () => {
    const map = product.prod_attribute.reduce((output, item) => {
      output[item.attribute.id] = item.id
      return output
    }, {})
    removedAttributes.forEach(({ id }) => {
      removeProductAttribute(map[id]).catch((err) => {})
    })
  }

  const updateMainImage = async () => {
    const form = new FormData()
    form.append('image', newMainImage)
    await updateProduct(form, product.uuid)
  }

  const handleSubmit = async () => {
    setBusy(true)
    setOpen(true)
    const form = generateForm()
    try {
      await updateProduct(form, product.uuid).then(async (res) => {
        if (newMainImage) await updateMainImage()
        if (images.length > 0) await handleProductMedia()
        if (deleted.length > 0) await handleMediaDelete()
        if (removedAttributes.length > 0) await deleteRemovedAttributes()
        await postNewAttributeMedia()
        setBusy(false)
      })
    } catch (error) {
      setOpen(false)
      toast.error('Unable to update product. Please try again later.')
    }
  }

  const handleAttributeAdd = (attribute) => {
    let attributesCopy = [...selectedAttributes]
    const isDuplicate = attributesCopy.find(
      (item) => item.uuid === attribute.uuid
    )
    if (!isDuplicate) {
      attributesCopy.push(attribute)
      setSelectedAttributes(attributesCopy)
    }
    openAttributeMediaDialog(attribute)
  }

  const handleAttributeRemove = (attribute) => {
    // let _removed
    let updatedArray = selectedAttributes.filter((item) => {
      if (item.uuid === attribute.uuid) {
        // _removed = item
        setRemovedAttributes(removedAttributes.concat(item))
      }
      return item.uuid !== attribute.uuid
    })

    setSelectedAttributes(updatedArray)
    removeAttributeMedia(attribute)
  }

  const handleCategoryAdd = (category) => {
    let categoriesCopy = [...selectedCategories]
    const isDuplicate = categoriesCopy.find(
      (item) => item.uuid === category.uuid
    )
    if (!isDuplicate) {
      categoriesCopy.push(category)
      setSelectedCategories(categoriesCopy)
    }
  }

  const handleCategoryRemove = (category) => {
    let updatedArray = selectedCategories.filter(
      (item) => item.uuid !== category.uuid
    )
    setSelectedCategories(updatedArray)
  }

  const handleBrandAdd = (brand) => {
    let brandsCopy = [...selectedBrands]
    const isDuplicate = brandsCopy.find((item) => item.uuid === brand.uuid)
    if (!isDuplicate) {
      brandsCopy.push(brand)
      setSelectedBrands(brandsCopy)
    }
  }

  const handleBrandRemove = (brand) => {
    let updatedArray = selectedBrands.filter((item) => item.uuid !== brand.uuid)
    setSelectedBrands(updatedArray)
  }

  const getIdList = (data) => {
    return data.map((item) => item.id)
  }

  const removeImage = (image) => {
    const newImages = media.filter((item) => item.id !== image.id)
    setMedia(newImages)
    setDeleted([...deleted, image])
  }

  const addAttributeMedia = (attribute, media) => {
    const isDuplicate = attributesMedia.find(
      (item) => item.attribute.name === attribute.name
    )
    if (isDuplicate) {
      updateAttributeMedia(attribute, media)
      return
    }
    const form = {
      attribute,
      image: media,
      id: 'new',
    }
    setAttributesMedia([...attributesMedia, form])
  }

  const updateAttributeMedia = (attribute, newMedia) => {
    const copy = [...attributesMedia]
    copy.forEach((element) => {
      if (attribute.attribute) {
        if (attribute.attribute.uuid === element.attribute.uuid)
          element.image = newMedia
      } else {
        if (attribute.uuid === element.attribute.uuid) element.image = newMedia
      }
    })
    setActiveAttribute(null)
    setAttributesMedia(copy)
  }

  const removeAttributeMedia = (attribute) => {
    const media = attributesMedia.filter(
      (item) => item.attribute.uuid !== attribute.uuid
    )
    setAttributesMedia(media)
    // const mediaObject = product.prod_attribute.find(
    //   (item) => item.attribute.id === attribute.id
    // )
    // if (mediaObject)
    //   setDeletedAttributeMedia([...deletedAttributeMedia, mediaObject])
  }

  const openAttributeMediaDialog = (attribute) => {
    if (attribute.image) return
    attributeMediaInput.current.click()
    setActiveAttribute(attribute)
  }

  const postNewAttributeMedia = async () => {
    attributesMedia.forEach(async (element, index) => {
      if (element.id !== 'new' && typeof element.image === 'object') {
        const form = new FormData()
        form.append('image', element.image)
        await updateProductAttribute(form, element.id).catch(() => {})
      } else if (typeof element.image === 'object') {
        const form = new FormData()
        form.append('image', element.image)
        form.append('product', product.id)
        form.append('attribute', element.attribute.id)
        form.append('sequence', index)
        await addProductAttribute(form).catch(() => {})
      }
    })
  }

  useEffect(() => {
    fetchProductCategories()
    fetchBrands()
    fetchAttributes()
  }, [])

  return (
    <>
      {/* Product details */}
      <div className="bg-white w-[70%] p-5 space-y-5">
        <FormInput
          type="text"
          label="Product Name"
          placeholder="Product Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="col-span-2 p-5 space-y-3 bg-[#F4F5F7] rounded-md">
          <label>Product Details</label>
          <Editor
            init={{
              height: 300,
            }}
            value={desc}
            onChange={setDesc}
          />
        </div>
        {/* Product Meta */}
        <FormInput
          type="text"
          label="Product Meta Title"
          placeholder="Product Meta Title"
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        <FormInput
          type="text"
          label="Product Meta Description"
          placeholder="Product Meta Description"
          value={metaDesc}
          onChange={(e) => setMetaDesc(e.target.value)}
        />
        <div className="px-4 bg-[#F4F5F7] rounded-md">
          <label className="mr-2">Featured</label>
          <Checkbox
            checked={isFeatured}
            onClick={() => setIsFeatured(!isFeatured)}
          />
          <label className="mr-2 ml-3">Engraving</label>
          <Checkbox checked={engrave} onClick={() => setEngrave(!engrave)} />
        </div>
      </div>

      {/*<div className="bg-white w-[70%] p-5 space-y-3">
        <h1 className="mb-3 font-medium text-[#3D4356]">Pricing</h1>
        <div className="grid grid-cols-2">
          <FormInput 
            type="text" 
            label="Price" 
            placeholder="Price" 
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>*/}

      <div className="bg-white w-[70%] p-5 space-y-3">
        <h1 className="mb-3 font-medium text-[#3D4356]">Category</h1>
        <CustomMultiSelect
          options={categories}
          selections={selectedCategories}
          addItem={handleCategoryAdd}
          removeItem={handleCategoryRemove}
          onNewClick={() => setShowCreateCategoryModal(true)}
          newBtnLabel={'Create New Category'}
        />
      </div>
      <div className="bg-white w-[70%] p-5">
        <h1 className="mb-1 font-medium text-[#3D4356]">Attributes</h1>
        <p className="mb-3">
          Double click on an attribute to change its media/image
        </p>
        <CustomMultiSelect
          options={attributes}
          selections={selectedAttributes}
          addItem={handleAttributeAdd}
          removeItem={handleAttributeRemove}
          onNewClick={() => setShowCreateAtrributeModal(true)}
          newBtnLabel={'Create New Attribute'}
          onItemPress={openAttributeMediaDialog}
        />
      </div>
      <div className="bg-white w-[70%] p-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Attributes Media</h1>
        <input
          ref={attributeMediaInput}
          type="file"
          hidden
          accept=".png,.jpeg,.jpg"
          onChange={(e) => {
            addAttributeMedia(activeAttribute, e.target.files[0])
          }}
        />
        <div className="grid grid-cols-5 gap-5">
          {attributesMedia.map((item, idx) => (
            <div className="flex flex-col">
              <div
                key={idx}
                className="relative mb-1 border border-gray-300 border-dashed rounded-md"
              >
                <img
                  src={
                    typeof item.image === 'object'
                      ? URL.createObjectURL(item.image)
                      : item.image
                  }
                  alt=""
                  className="object-cover w-[216px] h-[219px] rounded-md"
                />
                <label
                  htmlFor={`main-image${idx}`}
                  className="absolute left-0 right-0 p-2 mx-auto bg-white rounded bottom-6 w-[fit-content] cursor-pointer shadow"
                >
                  <img src={editIcon} alt="" className="w-[20px] h-[20px]" />
                </label>
                <input
                  type="file"
                  id={`main-image${idx}`}
                  accept=".png,.jpeg,.jpg"
                  onChange={(e) =>
                    updateAttributeMedia(item, e.target.files[0])
                  }
                  className="hidden"
                />
              </div>
              <p className="m-0 font-medium text-center text-md">
                {item.attribute ? item.attribute.name : item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white w-[70%] p-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Media</h1>
        <div
          className={`grid mb-4 gap-5 ${
            images.length > 0
              ? 'grid-cols-[2fr,1fr]'
              : 'grid-cols-[1fr,1fr,1fr]'
          }`}
        >
          <div className="grid grid-cols-2 gap-5 ">
            {product.image && (
              <div className="relative border border-gray-300 border-dashed rounded-md">
                <img
                  src={
                    newMainImage
                      ? URL.createObjectURL(newMainImage)
                      : product.image
                  }
                  alt=""
                  className="object-cover w-[216px] h-[219px] rounded-md"
                />
                <label
                  htmlFor="main-image"
                  className="absolute left-0 right-0 p-2 mx-auto bg-white rounded bottom-4 w-[fit-content] cursor-pointer"
                >
                  <img src={editIcon} alt="" className="w-[20px] h-[20px]" />
                </label>
                <input
                  type="file"
                  id="main-image"
                  accept=".png,.jpeg,.jpg"
                  onChange={(e) => {
                    setNewMainImage(e.target.files[0])
                  }}
                  className="hidden"
                />
              </div>
            )}
            {media.map((item, idx) => (
              <div className="relative border border-gray-300 border-dashed rounded-md">
                <img
                  key={idx}
                  src={item.media}
                  alt=""
                  className="object-cover w-[216px] h-[219px] rounded-md"
                />
                <button
                  onClick={() => removeImage(item)}
                  className="absolute left-0 right-0 p-2 mx-auto bg-white rounded bottom-4 w-[fit-content]"
                >
                  <img src={deleteIcon} alt="" className="w-[20px] h-[20px]" />
                </button>
              </div>
            ))}
            {images.map((image, idx) => {
              return (
                <div className="relative border border-gray-300 border-dashed rounded-md">
                  <IoCloseCircle
                    className="absolute cursor-pointer w-7 h-7 top-5 right-5"
                    onClick={() => {
                      setImages(images.filter((_, index) => index !== idx))
                    }}
                  />
                  <img
                    key={idx}
                    src={URL.createObjectURL(image)}
                    alt=""
                    className="object-cover w-[216px] h-[219px] rounded-md"
                  />
                </div>
              )
            })}
          </div>
          <div className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52">
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
              <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
            </div>
            <label
              htmlFor="product-images"
              className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
            >
              <p>Upload Files</p>
              <input
                type="file"
                id="product-images"
                multiple
                accept=".png,.jpeg,.jpg"
                onChange={(e) => {
                  setImages([...e.target.files])
                }}
                className="hidden"
              />
            </label>

            <p className="text-sm text-gray2">or drop files to upload</p>
          </div>
        </div>
        {/* Alt text */}
        <FormInput
          type="text"
          label="Media Alt Text"
          placeholder="Media Alt Text"
          value={imageAlt}
          onChange={(e) => setImageAlt(e.target.value)}
        />
      </div>

      {/* brands */}
      <div className="bg-white w-[70%] p-5 space-y-3">
        <h1 className="mb-3 font-medium text-[#3D4356]">Brands</h1>
        <CustomMultiSelect
          options={brands}
          selections={selectedBrands}
          addItem={handleBrandAdd}
          removeItem={handleBrandRemove}
          imageKey="logo"
        />
      </div>

      <div className="w-[70%] flex justify-end items-center space-x-3">
        <Button
          variant="contained"
          className="capitalize bg-white shadow-none"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="text-white capitalize shadow-none bg-orange"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>

      <CreateAttributeModal
        open={showCreateAttributeModal}
        closeModal={() => setShowCreateAtrributeModal(false)}
        parents={parentAttributes}
      />
      <CreateCategoryModal
        open={showCreateCategoryModal}
        closeModal={() => setShowCreateCategoryModal(false)}
        parents={parentCategories}
      />
      <Dialog open={open}>
        <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
          {busy ? (
            <>
              <img src={productsInactive} alt="" className="w-9 h-9" />
              <p className="font-medium capitalize text-gray2">
                Saving new product
              </p>
              <img src={loader} alt="" className="animate-spin" />
            </>
          ) : (
            <>
              <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                <img src={success} alt="" className="" />
              </span>

              <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                New Product Added Successfully
              </p>

              <div className="space-x-3">
                <Button
                  variant="contained"
                  className="capitalize bg-white border shadow-none"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Add New Product
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  onClick={() => {
                    handleClose()
                    history.push('/products')
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default UpdateProductInfo
