import api from '../apiInstance'

export const getCategories = (pageNumber, pageSize) =>
  api.get('/api/blog/category/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
    },
  })

export const getPosts = (pageNumber, pageSize) =>
  api.get('/api/blog/post/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
    },
  })

export const getBlogTags = () =>
  api.get(`/api/blog/tag/`, {
    params: {
      page: 1,
      page_size: 100,
    },
  })

export const updateCategory = (id, form) =>
  api.patch(`/api/blog/category/${id}/`, form)

export const createCategory = (form) => api.post(`/api/blog/category/`, form)

export const createTag = (form) => api.post(`/api/blog/tag/`, form)

export const createPost = (form) => api.post(`/api/blog/post/`, form)

export const updatePost = (id, form) => api.patch(`/api/blog/post/${id}/`, form)

export const searchPosts = (query) =>
  api.get('/api/blog/post/', {
    params: {
      page: 1,
      page_number: 100,
      search: query,
    },
  })

export const getPost = (id) => api.get(`/api/blog/post/${id}/`)

export const deletePost = (uuid) => api.delete(`/api/blog/post/${uuid}/`)
