import React, { useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";

//mui
import { IconButton } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import AddNewUserForm from "./AddNewUser/AddNewUser";
import UnsavedChanges from "../../components/UnsavedChanges/UnsavedChanges";

const AddNewRetailer = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Layout>
      <div className="flex flex-col items-center py-10 space-y-5 ">
        <div className="w-[70%] flex items-center space-x-3">
          <IconButton
            onClick={() => {
              setModalOpen(true);
            }}
            disableRipple
            className="p-0 hover:bg-transparent"
          >
            <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
          </IconButton>
          <h1 className="font-bold text-[30px] text-gray1 capitalize">
            Add New User
          </h1>
        </div>

        <AddNewUserForm />

        <UnsavedChanges isOpen={modalOpen} close={() => setModalOpen(false)} />
      </div>
    </Layout>
  );
};

export default AddNewRetailer;
