import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

//components
import TimeCheckbox from '../../../components/Settings/TimeCheckbox'

//icons
import bulb from '../../../assets/bulb.svg'
import loader from '../../../assets/loader.svg'

//mui
import { Button } from '@material-ui/core'

//services
import { updateWorkingHours } from '../../../services/retailers'
import { RootContext } from '../../..'

const PickupHours = ({ data, fetchData }) => {
  const root = useContext(RootContext)
  const history = useHistory()

  //variables
  const [submitting, setSubmitting] = useState(false)
  const [workingHours, setWorkingHours] = useState(data)
  const { watch, handleSubmit, control } = useForm()

  //functions
  const handleOnSubmit = async (values) => {
    setSubmitting(true)
    try {
      const working_hours = []

      for (const time of Object.keys(values)) {
        if (values[time]) {
          working_hours.push({ day: time, ...values[time] })
        }
      }

      await updateWorkingHours(root.authStore.user.retailerUuid, {
        working_hours,
      })
      toast.success('Working hours updated!')
      fetchData()
    } catch (error) {
      toast.error('Error updating working hours updated!')
    } finally {
      setSubmitting(false)
    }
  }

  const duplicateTime = () => {
    const time = watch('Monday')

    const new_workingHours = workingHours.map((item) => {
      return {
        ...item,
        opening_time: time.opening_time,
        closing_time: time.closing_time,
      }
    })

    setWorkingHours(new_workingHours)
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-[80%]">
        <div className="bg-white rounded-md p-5 space-y-10">
          <div>
            <p className="font-semibold text-[#040A1D] text-xl">
              Pickup/Delivery Hours
            </p>
            <p className="text-sm text-[#4C536A]">
              Choose the standard pickup/delivery hours for your business
            </p>
          </div>

          <form className="w-[50%]" onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="space-y-5">
              <div className="flex flex-col items-end border-b pb-2">
                <Controller
                  name="Monday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Monday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
                {watch('Monday') && (
                  <div className="flex justify-center text-sm w-[80%] gap-3 my-2 bg-teal/10 rounded-md border border-teal py-2">
                    <img src={bulb} alt="hint" className="h-5 w-5" />{' '}
                    <span>
                      Copy this working hours to all days?{' '}
                      <button
                        type="button"
                        className="font-semibold text-teal underline"
                        onClick={duplicateTime}
                      >
                        Yes Copy
                      </button>
                    </span>
                  </div>
                )}
              </div>
              <div className="border-b pb-2">
                <Controller
                  name="Tuesday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Tuesday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
              </div>
              <div className="border-b pb-2">
                <Controller
                  name="Wednesday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Wednesday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
              </div>
              <div className="border-b pb-2">
                <Controller
                  name="Thursday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Thursday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
              </div>
              <div className="border-b pb-2">
                <Controller
                  name="Friday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Friday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
              </div>
              <div className="border-b pb-2">
                <Controller
                  name="Saturday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Saturday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
              </div>
              <div className="border-b pb-2">
                <Controller
                  name="Sunday"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TimeCheckbox
                      day="Sunday"
                      onChange={onChange}
                      workingHours={workingHours}
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex justify-end items-center mt-20 gap-5">
              <Button
                variant="outlined"
                className={`text-[#4C536A] capitalize shadow-none relative bg-white border border-[#E3E7ED]`}
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={`text-white capitalize shadow-none relative flex ${
                  submitting
                    ? 'bg-orange/30 cursor-not-allowed'
                    : 'bg-orange cursor-pointer'
                }`}
              >
                Save
                {submitting && (
                    <img
                      src={loader}
                      className="w-5 animate-spin ml-1"
                      alt=""
                    />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default PickupHours
