import React, { useState, useEffect, createRef, useContext } from "react";
import { Popover } from "@material-ui/core";
import { Calendar } from "react-modern-calendar-datepicker";
import { GoogleMap, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { toast } from "react-toastify"
import { observer } from "mobx-react-lite";

// components
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/FormInput/FormInput";
import SaveModal from "../../components/SaveModal/SaveModal";
import FormSelect from "../../components/FormSelect/FormSelect";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import UnsavedChanges from "../../components/UnsavedChanges/UnsavedChanges";

// assets
import backArrow from "../../assets/back-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import customersIcon from "../../assets/customers-inactive.svg";
import location from "../../assets/location.svg";

// assets
import validateEmail from "../../utils/validateEmail";
import { trimDigits } from "../../utils";

// store
import { RootContext } from "../..";

//services
import { updateProfile, createCustomer, updateAddress } from "../../services/users";


const containerStyle = {
  width: "100%",
  height: "167px",
  borderRadius: "10px",
};

const libraries = ["places"];

const AddCustomer = observer(() => {
  const root = useContext(RootContext);

  const [busy, setBusy] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedDay, setSelectedDay] = useState(null);
  // const [date, setDate] = useState("");
  const [country, setCountry] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState("+234")
  const [phone, setPhone] = useState('');
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  // address information
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");

  //marker coords
  const [coords, setCoord] = useState(null);

  const fileInput = createRef();
  const open = Boolean(anchorEl);
  const id = open ? "calendar-popover" : undefined;

  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const goBack = () => {
    setShowExitModal(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(false);
  };

  // useEffect(() => {
  //   if (selectedDay)
  //     setDate(`${selectedDay.year}-${formatMonth(selectedDay.month)}-${selectedDay.day}`);
  // }, [selectedDay]);

  const genderChangeHandler = value => {
    setGender(value);
  }

  const formatMonth = (month) => {
    if (parseInt(month) < 10) return `0${month}`;
    return month;
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    } else if (file.size > 100000000) {
      return false;
    }
    return true;
  };

  const handleFiles = (files) => {
    if (validateFile(files[0])) {
      setSelectedAvatar(URL.createObjectURL(files[0]));
    }
  };

  const handleAddressInput = (e) => {
    const { value } = e.target;
    setAddress(value);
    setValue(value);
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    fileInput.current.click();
    fileInput.current.onchange = () => {
      if (fileInput.current.files.length) {
        handleFiles(fileInput.current.files);
      }
    };
  };

  const onSelect = async (suggestion) => {
    const results = await getGeocode({ placeId: suggestion.place_id });
    const coordinates = await getLatLng(results[0]);

    const addressComps = results[0].address_components;

    const country = addressComps.find((info) => info.types.includes("country"));
    const city = addressComps.find((info) => info.types.includes("locality"));
    const region = addressComps.find((info) => 
      info.types.includes("administrative_area_level_1"));
    const postalCode = addressComps.find((info) =>
      info.types.includes("postal_code"))

    setValue(suggestion.description, false);

    clearSuggestions();

    setCoord(coordinates);
    setAddress(suggestion.description);
    setCity(city?.long_name);
    setPostalCode(postalCode?.long_name);
    setRegion(region?.long_name);
    setCountry(country?.long_name);
  }

  const clearForm = () => {
    setPhone("");
    // setPassword("");
    // setConfirmPassword("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setAddress("");
    setSelectedAvatar(null);
    setGender("");
    // setDate("");
  }

  const generateForm = () => {
    const form = {
      phone_number: countryCode+phone.replace(/^0|[\s-]/g, ''),
      email: email,
      password: 'pwSx_3>9/:[J<Io',
      account_type: "Customer",
      profile: {
        first_name: firstName,
        last_name: lastName,
        // date_of_birth: date,
        gender: gender
      },
      address: {
        address,
        city,
        country,
        post_code: postalCode,
        lon: trimDigits(coords.lng),
        lat: trimDigits(coords.lat),
        is_active: true,
        is_dashboard: true,
        administrative_area_level_1: region,
      }
    }
    if(root.authStore.user.retailerId)
      form['related_retailers'] = [root.authStore.user.retailerId]
    return form
  }

  const checkFormForEmptyFields = () => {
    if (
      firstName.length > 0
      && lastName.length > 0
      && phone.length > 0
      && email.length > 0
      && address
      // && date.length > 0
    ) {
      setDisabled(false);
    }
  }

  useEffect(() => {
    checkFormForEmptyFields();
  }, [address, firstName, lastName, phone, email, selectedAvatar, /*date*/])

  const handleSubmit = async() => {
    if(!validateEmail(email)) {
      toast.info("Invalid email address");
      return;
    }
    setBusy(true);
    setShowSaveModal(true);
    const form = generateForm();
    const profile = form.profile;
    let address = form.address;
    delete form.address;
    delete form.profile;
    try {
      await createCustomer(form)
        .then(async(res) => {
          const response = await updateProfile(profile, res.data.profile);
          address.user = res.data.id;
          const { data } = await updateAddress(address);
          res.data.address = data;
          res.data.profile = response.data;
          return res;
        })
       setBusy(false);
       clearForm();
    } catch (error) {
      setShowSaveModal(false);
      setBusy(false);      
      toast.error(
        error.response.data?.email?.[0]
        || "Unable to create customer. Please try again later."
      )
    }
  }

  return (
    <Layout>
      <div className="flex items-center justify-center">
        <div className="w-[730px] flex flex-col">
          <div className="flex flex-row items-center">
            <button onClick={goBack}>
              <img
                src={backArrow}
                alt="back"
                className="w-[40px] h-[40px] mr-4"
              />
            </button>
            <h1 className="text-appBlack font-bold text-[30px]">
              Add New Customer
            </h1>
          </div>
          <div className="w-full bg-white rounded-md p-[30px] flex flex-col mt-4 shadow-md">
            <p className="text-[#3D4356] text-[16px] mb-3">Personal Details</p>
            <div className="flex space-x-4 items-center w-full mb-3">
              <FormInput
                id="first-name"
                name="first-name"
                placeholder="First Name"
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <FormInput
                id="last-name"
                name="last-name"
                placeholder="Last Name"
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="flex flex-row items-center mb-3">
            {/*
              <FormInput
                id="calendar-popover"
                name="date"
                placeholder="Date of Birth"
                label="Date of Birth"
                onFocus={(e) => setAnchorEl(e.currentTarget)}
                value={date}
              />
              <Popover
                id={id}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Calendar
                  value={selectedDay}
                  onChange={setSelectedDay}
                  shouldHighlightWeekends={false}
                  colorPrimary="#F3641F"
                  colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                />
              </Popover>*/}
              <div className="flex items-center w-full space-x-2">
                <CustomSelect
                  label="Country Code"
                  name="country-codes"
                  id="country-codes"
                  value={countryCode}
                  options={['+233', '+234']}
                  setValue={setCountryCode}
                />
                <FormInput
                  type="text"
                  id="phone-number"
                  name="phone-number"
                  placeholder="Phone Number"
                  label="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

            </div>
            <div className="flex space-x-4 items-center mb-3">
              <FormInput
                id="email"
                name="email"
                placeholder="Email Address"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormSelect 
                options={[{label:"male", value: "male"}, {label:"female", value: "female"}]}
                label={"Gender"}
                value={gender}
                handleChange={genderChangeHandler}
              />
            </div>
            {/*<div className="flex flex-row items-center w-full">
              <FormInput
                id="password"
                name="password"
                placeholder="Password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="w-8"></div>
              <FormInput
                id="confirm-password"
                name="confirm-password"
                placeholder="Confirm Password"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>*/}
          </div>

          <div className="w-full bg-white rounded-md p-[30px] flex flex-col mt-6 shadow-md">
            <p className="text-[#3D4356] text-[16px]">Profile Photo</p>
            <input type="file" hidden ref={fileInput} />
            {selectedAvatar ? (
              <div className="w-[200px] border-dashed rounded h-[210px] relative mt-6 border border-[#E5E5E5]">
                <img
                  src={selectedAvatar}
                  alt=""
                  className="w-full h-full rounded-md"
                />
                <div className="absolute left-0 right-0 flex flex-row items-center mx-auto bg-white rounded-md bottom-4 w-[fit-content]">
                  <button
                    onClick={() => setSelectedAvatar(null)}
                    className="p-2"
                  >
                    <img
                      src={deleteIcon}
                      alt=""
                      className="w-[22.03px] h-[22.33px]"
                    />
                  </button>
                  <div className="h-[38px] w-[1px] bg-[#E3E7ED]"></div>
                  <button className="p-2" onClick={handleFileSelect}>
                    <img
                      src={editIcon}
                      alt=""
                      className="w-[22.03px] h-[22.33px]"
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                className="flex flex-col items-center justify-center w-[200px] mt-6 border border-dashed rounded-md py-[40px]"
              >
                <img src={upArrow} alt="" className="w-[41.67px] h-[41.36px]" />
                <button
                  onClick={handleFileSelect}
                  className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mt-3 font-medium"
                >
                  Upload files
                </button>
                <p className="text-[#4C536A] text-[14px] mt-3">
                  or drop files to upload
                </p>
              </div>
            )}
          </div>
          <div className="w-full bg-white rounded-md p-[30px] flex flex-col mt-6 shadow-md relative">
            <p className="text-[#3D4356] text-[16px]">Shipping Address</p>
            <FormInput
              id="address-1"
              name="address-1"
              placeholder="Address"
              label="Address"
              value={address}
              onChange={handleAddressInput}
              // disabled={!ready}
            />
            {data.length > 0 && (
              <ul className="absolute z-50 p-3 bg-white rounded-md shadow-lg top-[140px] w-[670px]">
                {data.map((suggestion, idx) => {
                  return (
                    <li
                      key={idx}
                      onClick={() => onSelect(suggestion)}
                      className="flex items-center p-2 space-x-3 capitalize cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                    >
                      <img src={location} alt="" />
                      <p className="font-medium">{suggestion.description}</p>
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="h-4"></div>
            {address && coords && (
              <div className="relative">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={coords}
                  zoom={10}
                >
                  <Marker position={coords}></Marker>
                </GoogleMap>
              </div>
            )}
          </div>
          <div className="flex flex-row items-center justify-end mt-8 mb-[200px]">
            <button
              onClick={() => setShowExitModal(true)}
              className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mr-4"
            >
              Cancel
            </button>
            <button
              className={`
                ${disabled ? "bg-orange/30 cursor-not-allowed" : 
                "bg-[#F3641F] cursor-pointer"} rounded-md px-4 py-2 
                text-white font-medium`
              }
              onClick={handleSubmit}
              disabled={disabled}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <SaveModal
        isOpen={showSaveModal}
        close={() => setShowSaveModal(false)}
        icon={customersIcon}
        progressLabel={"Saving New Customer"}
        successLabel={"New Customer"}
        btnLabel={"Add Another Customer"}
        busy={busy}
        handleAddPress={() => {
          clearForm();
          setShowSaveModal(false);
        }}
      />
      <UnsavedChanges
        close={() => setShowExitModal(false)}
        isOpen={showExitModal}
      />
    </Layout>
  );
});

export default AddCustomer;
