import React, { useEffect, useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { IoClose, IoCloseCircle, IoArrowUp } from "react-icons/io5";
import Editor from '@components/TinyMCE'

//assets
import loader from "../../assets/loader.svg";

//components
import FormInput from "../FormInput/FormInput";
import CustomSelect from "../CustomSelect/CustomSelect";
import ImageInput from "../ImageInput";

const CreateCategoryModal = ({ open, closeModal, parents = [], onSubmit }) => {
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [parent, setParent] = useState('');
  const [desc, setDesc] = useState('');
  const [metaDesc, setMetaDesc] = useState('');
  const [cateImage, setCateImage] = useState(null);
  const [imageAlt, setImageAlt] = useState('')
  const disabled = !(name.length > 0 && desc.length > 0 && cateImage);

  const handleClose = () => {
    clearForm();
    closeModal();
  }

  const generateForm = () => {
    const form = new FormData();
    form.append("name", name);
    form.append("title_meta", metaTitle);
    if (parent?.id) {
      form.append("parent", parent.id);
    }
    form.append("description", desc);
    form.append("description_meta", metaDesc);
    form.append("image", cateImage)
    form.append("image_alt", imageAlt);
    return form;
  }

  const handleSubmit = async() => {
    setBusy(true);
    const form = generateForm();
    await onSubmit(form);
    setBusy(false);
    clearForm();
  }

  const clearForm = () => {
    setName("");
    setParent("");
    setDesc("");
    setCateImage(null);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <div className="bg-white w-[606px] rounded">
        <div className="relative flex flex-row items-center justify-center py-4 border-b-[#E3E7ED] border-b">
          <h2 className="text-[#040A1D] text-[20px] font-bold m-0">Create a New Product Category</h2>
          <button
            className="absolute ml-10 top-4 right-10"
            onClick={closeModal}
          >
            <IoClose size={30} color={'#8892A2'}/>
          </button>
        </div>  
        <div className="p-10 px-14 overflow-auto h-[80vh] max-h-[700px]">
          <FormInput 
            id="name"
            name="name"
            placeholder="Add Category Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="h-5"></div>
          <CustomSelect
            id="parent"
            name="parent"
            label="Parent"
            value={parent}
            subCategory={true}
            options={parents}
            setValue={(item) => setParent(item)}
          />
          <div className="h-5"></div>
          <div className="col-span-2 p-5 space-y-3 bg-[#F4F5F7] rounded-md">
            <label>Add Description</label>
            <Editor
              value={desc}
              onChange={setDesc}
              className="bg-white"
            />
          </div>
          <div className="py-2"></div>
          <FormInput
            id="meta-title"
            name="meta-title"
            placeholder="Meta Title"
            label="Meta Title"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
          <div className="py-2"></div>
          <FormInput
            id="meta-description"
            name="meta-description"
            placeholder="Meta Description"
            label="Meta Description"
            value={metaDesc}
            onChange={(e) => setMetaDesc(e.target.value)}
          />
          <div className="py-2"></div>
          <h1 className="mt-8 font-medium text-[#3D4356]">Upload Category Image</h1>
          <div className="bg-white w-fullspace-y-3">
            <div className={`${cateImage ? 'grid grid-cols-2 gap-5' : ''}`}>
              {cateImage && (
                <div className="relative rounded-md">
                  <IoCloseCircle
                    color={"white"}
                    className="absolute cursor-pointer w-7 h-7 top-5 right-5"
                    onClick={() => setCateImage(null)}
                  />
                  <img
                    src={URL.createObjectURL(cateImage)}
                    alt={cateImage.name}
                    className="object-cover h-52 w-[100%] rounded-md"
                  />
                </div>
              )}

              <div className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                  <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                </div>
                <button
                  htmlFor="product-category-images"
                  className="relative border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                >
                  <ImageInput onChange={setCateImage} title={""}/>
                  <span className="mb-0 cursor-pointer">Upload File</span>
                </button>

                <p className="text-sm text-gray2">or drop file to upload</p>
              </div>
            </div>
            <div className="py-2"></div>
            <FormInput
              id="alt-text"
              name="alt-text"
              placeholder="Image Alt Text"
              label="Image Alt Text"
              value={imageAlt}
              onChange={(e) => setImageAlt(e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center justify-end mt-8 space-x-4">
            <Button
              variant="outlined"
              className="capitalize"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={`text-white capitalize shadow-none relative ${
                disabled || busy
                  ? "bg-orange/30 cursor-not-allowed"
                  : "bg-orange cursor-pointer"
              }`}
            >
              Save Category
              {busy && (
                <img src={loader} className="absolute w-5 animate-spin" alt=""/> 
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateCategoryModal
