import { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { toast } from 'react-toastify'

//components
import FormInput from '../../../components/FormInput/FormInput'

//icons
import loader from '../../../assets/loader.svg'

//mui
import { Button } from '@material-ui/core'
import { Dialog } from '@material-ui/core'

//services
import { updateRetailer } from '../../../services/retailers'
import { syncStockWithCin7 } from '../../../services/stock'

const Cin7 = () => {
  const root = useContext(RootContext)

  //variables
  const [data, setData] = useState({
    account_id:
      root.authStore.user.cin7_accountId === null
        ? ''
        : root.authStore.user.cin7_accountId,
    api_key:
      root.authStore.user.cin7_apiKey === null
        ? ''
        : root.authStore.user.cin7_apiKey,
  })

  const [accountId, setAccountId] = useState(data.account_id)
  const [apiKey, setApiKey] = useState(data.api_key)

  //state management
  const [busy, setBusy] = useState(false)
  const [sync, setSync] = useState(false)
  const [open, setOpen] = useState(false)
  const disabled = accountId === '' || apiKey === '' ? true : false

  //functions
  const handleSync = async () => {
    setSync(true)

    try {
      await syncStockWithCin7({
        business_uuid: root.authStore.user.retailerUuid,
      })

      setOpen(true)

      setSync(false)
    } catch (err) {
      toast.error('Error syncing your stock with your Cin7 account! try again')

      setSync(false)
    }
  }

  const handleSubmit = async () => {
    setBusy(true)

    try {
      const { data } = await updateRetailer(root.authStore.user.retailerUuid, {
        cin7_application_account_id: accountId,
        cin7_application_key: apiKey,
      })

      toast.success(
        'Your Cin7 account has been successfully integrated! You can now sync your inventory.',
      )

      setBusy(false)

      root.authStore.updateCin7Data(
        data.cin7_application_account_id,
        data.cin7_application_key,
      )

      setData({
        account_id: data.cin7_application_account_id,
        api_key: data.cin7_application_key,
      })

      await handleSync()
    } catch (err) {
      toast.error('Error integrating your Cin7 account! try again')

      setBusy(false)
    }
  }

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="w-[80%]">
          <div className="bg-white rounded-md p-5 space-y-10">
            <div>
              <p className="font-semibold text-[#040A1D] text-xl">
                Cin7 Integration
              </p>
              <p className="text-sm text-[#4C536A]">
                Enter the Account ID and API key from your CIN7 account to
                enable the integration.
              </p>
            </div>

            <div className="w-[70%] space-y-2">
              <FormInput
                id="account-id"
                name="account-id"
                type="text"
                label="Your Cin7 Account ID"
                placeholder="Account ID"
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
              />

              <FormInput
                id="api-key"
                name="api-key"
                type="password"
                label="Your Cin7 API Key"
                placeholder="API Key"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </div>

            <div className="w-[70%] flex justify-end items-center space-x-3 pb-20">
              {root.authStore.user.cin7_accountId !== null &&
                root.authStore.user.cin7_apiKey !== null && (
                  <Button
                    variant="outlined"
                    className="text-[#4C536A] capitalize shadow-none relative bg-white border border-[#E3E7ED]"
                    onClick={() => {
                      handleSync()
                    }}
                  >
                    {sync ? (
                      <img
                        src={loader}
                        alt=""
                        className="w-5 animate-spin ml-1"
                      />
                    ) : (
                      'Sync'
                    )}
                  </Button>
                )}

              <Button
                variant="contained"
                className={`text-white capitalize shadow-none ${
                  disabled || (busy && !disabled)
                    ? 'bg-orange/30 cursor-not-allowed'
                    : 'bg-orange cursor-pointer'
                }`}
                onClick={() => {
                  handleSubmit()
                }}
                disabled={disabled}
              >
                {busy ? (
                  <img src={loader} alt="" className="w-5 animate-spin ml-1" />
                ) : root.authStore.user.cin7_accountId !== null &&
                  root.authStore.user.cin7_apiKey !== null ? (
                  'Update'
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={open}>
        <div className="flex flex-col items-center p-5 space-y-3 w-96 bg-[#FAFAFA] ">
          <h3 className="text-center font-bold mb-3">Sync in progress...</h3>
          <p>
            This sync might take a while, so feel free to leave this page.{' '}
            <br />
            You will receive an email alert when the process is complete.
          </p>
          <Button
            variant="contained"
            color="primary"
            className="capitalize shadow-none"
            onClick={() => {
              setOpen(false)
            }}
          >
            Okay
          </Button>
        </div>
      </Dialog>
    </>
  )
}
export default Cin7
