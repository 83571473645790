import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, ButtonGroup } from "@material-ui/core";
import { toast } from "react-toastify";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import ReceiptModal from "../../components/ReceiptModal/ReceiptModal";
import CouponOrderTable from "../../components/CouponOrdersTable/CouponOrdersTable";

//assets
import couponIcon from "../../assets/coupon.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import searchIcon from "../../assets/search.svg";
import excel from "../../assets/excel.svg";

//services
import { getCoupon, deleteCoupon } from "../../services/coupons";
import { getCouponOrders, searchCouponOrders } from "../../services/orders";

//utils
import { formatDate } from "../../utils";


const SingleCoupon = () => {
  const { id } = useParams();
  const history = useHistory();
  const [usedPercentage, setUsedPercentage] = useState(0);
  const [busy, setBusy] = useState(true);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [showOrder, setShowOrder] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  let typingTimer;

  const fetchCoupon = async() => {
    setBusy(true);
    try {
      const { data } = await getCoupon(id);
      setCoupon(data);
      computeUsedPercentage(data);
      setBusy(false);

    } catch (error) {
      toast.error('An error occurred. Unable to fetch coupon');
      history.goBack();

    } finally {
      setBusy(false);
    }
  }

  const fetchCouponOrders = async(uuid, pageNumber) => {
    if (uuid) {
      try {
        setIsLoadingOrders(true)
        const { data } = await getCouponOrders(uuid, pageNumber);
        setOrders(data.results);
        setCount(data.count);

      } catch (error) {
        toast.error('An error occurred. Unable to fetch coupon orders');

      } finally {
        setIsLoadingOrders(false)
      }
    }
  }

  const handleSearch = async(pageNumber) => {
    setBusy(true);
    try {
      const { data } = await searchCouponOrders(search, coupon.uuid, pageNumber)
      setOrders(data.results)
      setCount(data.count);
      if(data.count === 0)
        setNotFound(true)
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error('An error occurred. Unable to fetch requested data');
    }
  }

  const computeUsedPercentage = (data) => {
    const value = (data.total_amount_used / data.quantity) * 100;
    setUsedPercentage(value);
  }

  const onSearchKeyDown = () => {
    clearTimeout(typingTimer)
  }

  const onSearchKeyUp = () => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      handleSearch(1);
    }, 500);
  }

  const onPageChange = (_, n) => {
    fetchCouponOrders(coupon.uuid, n)
  }

  const deleteThisCoupon = async () => {
    try {
      setBusy(true)
      await deleteCoupon(id)
    } catch (err) {
      toast.error('Failed to delete coupon.')
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    fetchCoupon()
  }, [])

  useEffect(() => {
    if (search && coupon) {
      fetchCouponOrders(coupon.uuid, 1);
    }
  }, [search])

  useEffect(() => {
    if (coupon) {
      fetchCouponOrders(coupon.uuid, 1);
    }
  }, [coupon])

  return (
    <Layout>
      {busy && search.length === 0 ? (
        <Loader />
      ) : (
        <div className="page-padding">
          <PageTitle 
            title={`Coupon`}
            subtitle={`${coupon.coupon_type.toLowerCase() === 'fixed amount' ? '₦' : ""} ${coupon.value}${coupon.coupon_type.toLowerCase() === 'percent amount' ? '%' : ''} off`}
            onBackPress={() => history.goBack()}
          />
          <div className={`bg-white rounded-md py-[30px] px-[20px] relative`}>
            <div className={`flex flex-row items-center `}>
              <img 
                src={couponIcon}
                alt=""
                className="w-[57px] h-[57px] mr-[23px]"
              />
              <div className="flex flex-col mr-[70px]">
                <h1
                  className="font-semibold text-[#040A1D] text-[24px] m-0 leading-none"
                >
                  {coupon.coupon_type === 'Fixed amount' && '₦'} {coupon.value}{coupon.coupon_type === 'Percent amount' && '%'} off
                </h1>
                <p className="font-medium text-[14px] text-[#9CA7B8] m-0">Any purchase</p>
              </div>
              <div
                className="bg-[#F4F5F7] rounded-md flex flex-row justify-between h-[65px] w-[367px] items-center mr-[11px] px-[20px]"
              >
                <p className="text-[#8892A2] text-[14px] m-0">
                  Code Name
                </p>
                <p className="text-[#040A1D] text-[22px] font-medium m-0">
                  {coupon.code}
                </p>
              </div>
              <div
                className="bg-[#F4F5F7] rounded-md flex flex-row items-center h-[65px]  px-[20px]"
              >
                <div className="flex flex-col mr-[30px]">
                  <p className="text-[#8892A2] text-[14px] m-0">Date Created</p> 
                  <p className="text-[#040A1D] font-semibold text-[14px] m-0">{formatDate(coupon.created_at)}</p>  
                </div>
                <div className="flex flex-col">
                  <p className="text-[#8892A2] text-[14px] m-0">Expiration Date</p> 
                  <p className="text-[#040A1D] font-semibold text-[14px] m-0">{formatDate(coupon.expiry_date)}</p>
                </div>
              </div>
            </div>
            <ButtonGroup className="top-[49px] right-[21px] absolute">
              <Button
                className="border border-[#E3E7ED]"
                onClick={() => history.push(`/coupons/edit-coupon/${coupon.id}`)}
              >
                <img src={editIcon} alt="edit coupon"/>
              </Button>
              <Button
                className="border border-[#E3E7ED]"
                onClick={deleteThisCoupon}
              >
                <img src={deleteIcon} alt="delete coupon"/>
              </Button>
            </ButtonGroup>
            <div
              className="flex flex-row items-center justify-between mt-[55px]"
            >
              <p className="text-[#3D4356] text-[16px] font-medium mb-[11px]">Quantity/Amount</p>
              <p className="text-[#3D4356] text-[16px] font-medium mb-[11px]">{coupon.usage}/{coupon.limit}</p>
              <p className="text-[#3D4356] text-[16px] font-medium mb-[11px]">Total Amount Used</p>
            </div>
            <div className={`relative h-[54px] ${coupon.type === "fixed" ? "bg-[#38C985]" : "bg-[#b4cafd]"} bg-opacity-20 rounded-md w-full`}>
              <div
                style={{width: `${usedPercentage}%`}}
                className={`${coupon.type === "fixed" ? "bg-[#38C985]" : "bg-[#477DFB]"} rounded-tl-md rounded-bl-md h-full transition-all duration-100`}
              ></div>
              <p className="absolute text-white text-[26px] font-medium left-4 top-2">
                {coupon.quantity}
              </p>
              <p className="text-[#4C536A] text-[16px] font-medium absolute left-[50%] translate-x-[-50%] top-4">
                {usedPercentage}%
              </p>
              <p className="absolute text-[#040A1D] text-[26px] font-medium right-4 top-2">
                {coupon.total_amount_used}/{coupon.quantity}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-end justify-end mt-[40px]">
            <div className="px-2 rounded-md w-[300px] flex items-center focus-within:border-b-appBlue focus-within:border-b mr-4">
              <img src={searchIcon} alt="" />
              <input
                type="search"
                placeholder={"Search orders"}
                className="w-full px-2 py-2 text-sm bg-transparent border-0 focus:outline-none focus:ring-0"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                onKeyDown={onSearchKeyDown}
                onKeyUp={onSearchKeyUp}
              />
            </div>
            <Button
              className="capitalize bg-white contained text-[14px] text-[#9CA7B8] rounded-md border border-[#9CA7B8]"
            >
              Export
              <img 
                src={excel}
                className="ml-2"
                alt=""
              />
            </Button>
          </div>
          <CouponOrderTable
            rows={orders}
            busy={isLoadingOrders}
            notFound={notFound}
            onPageChange={onPageChange}
            count={count}
            page={currentPage - 1}
            type={coupon.coupon_type}
            value={coupon.value}
            onViewClick={setShowOrder}
            search={search}
          />
        </div>
      )}
      {showOrder ?
        <ReceiptModal
          isOpen={true}
          closeModal={() => setShowOrder(null)}
          data={showOrder}
          coupon={coupon}
        />
      : null}
    </Layout>
  )
}

export default SingleCoupon;

