export default function ImageInput({
  onChange,
  multiple = false,
  className = '',
  ...props
}) {
  const onFileChange = (e) => {
    let files = [...(e.target.files || e.dataTransfer.files)]
    if (!files.length) return
    if (!multiple) {
      files = files[0]
    }
    onChange(files)
  }
  return (
    <input
      className={`absolute w-[100%] h-[100%] top-0 left-0 z-10 cursor-pointer opacity-[0] ${className}`}
      type="file"
      accept="image/*"
      multiple={multiple}
      onChange={onFileChange}
      {...props}
    />
  )
}
