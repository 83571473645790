import React, { useState, useEffect } from "react";
import useTableMethods from "../../customHooks/useTableMethods";
import { toast } from "react-toastify";

//mui
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
} from "@material-ui/core";

//components
import TableHead from "./CustomTableHead";

//services
import { getInventoryHistory } from "../../services/stock";
import { formatDate, formatTime } from "../../utils";
import { getComparator, stableSort } from "../../utils/tableFunctions";

const OrderHistoryTable = ({product}) => {
  const [data, setData] = useState([]);

  const {
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    emptyRows,
    selected,
    page,
    order,
    orderBy,
    rowsPerPage,
  } = useTableMethods(data);

  const fetchInventoryHistory = async() => {
    try {
      const { data } = await getInventoryHistory(product);
      setData(data.results);
    } catch (error) {
      toast.error("Unable to fetch history. Please try again later.");
    }
  }

  useEffect(() => {
    fetchInventoryHistory();
  }, [])

  return (
    <div className="w-full mt-5">
      <Table className="min-w-[750px]" size="medium">
        <TableHead
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={data.length}
        />

        <TableBody className="bg-white">
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(index);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {formatDate(row.date)}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {formatTime(row.date)}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.previous ? row.previous : 0}
                  </TableCell>

                  <TableCell className="text-sm font-medium text-gray2">
                    {row.quantity - row.previous}
                  </TableCell>

                  <TableCell
                    className="text-sm font-medium text-orange"
                    align="right"
                  >
                    {row.quantity}
                  </TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default OrderHistoryTable;
