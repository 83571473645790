import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'
import { toast } from 'react-toastify'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import DateActions from '../../components/DateActions/DateActions'
import FinanceTable from '../../components/FinanceTable/FinanceTable'
import EmptyTableView from '../../components/EmptyTableVIew/EmptyTableView'
import Loader from '@components/Loader/Loader'
import { RootContext } from '../..'

//assets
import financeIcon from '../../assets/finance-active.svg'

//services
import {
  // exportTransaction,
  getFinanceTransactions,
} from '../../services/finance'
import { exportData, formatDate, formatTime } from '../../utils'
import { numberFormat } from '../../utils/formatNumber'
import SearchBar from '../../components/SearchBar/SearchBar'
import TabButton from '../../components/TabButton/TabButton'
import TransactionFilter from '../../components/TransactionFilter/TransactionFilter'

const TransactionHistory = () => {
  const {
    authStore: { user },
  } = useContext(RootContext)
  const [activeTab, setActiveTab] = useState('All')
  const [search, setSearch] = useState('')
  const [notFound, setNotFound] = useState(false)
  const [activePeriod, setActivePeriod] = useState('today')
  const [busy, setBusy] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [dateRange, setDateRange] = useState({
    start: moment(),
    end: moment(),
  })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [selectedFilter, setSelectedFilter] = useState('period')
  const [controller, setController] = useState(null)
  const [selectedRetailers, setSelectedRetailers] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])

  const menu = [
    { label: 'Retailers' },
    { label: 'Payment Type' },
    { label: 'Order Status' },
  ]

  const tabs = [
    { id: 'All', label: 'All' },
    { id: 'Paid', label: 'Paid' },
    { id: 'Cancelled', label: 'Cancelled' },
  ]

  const history = useHistory()

  const fetchTransactions = async () => {
    setBusy(true)
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    try {
      const { data } = await getFinanceTransactions(
        user.retailerUuid,
        {
          search,
          ...(activeTab === 'All' ? {} : { status: activeTab }),
          page: currentPage,
          page_size: rowsPerPage,
          ...(selectedFilter === 'range'
            ? {
                start_date: dateRange.start.format('YYYY-MM-DD'),
                end_date: dateRange.end.format('YYYY-MM-DD'),
              }
            : {
                date: activePeriod,
              }),
          retailer_uuid: String(selectedRetailers),
          order_status: String(selectedStatus),
          payment_channel: String(selectedTypes),
        },
        abc.signal,
      )

      setTransactions(data.results)
      setCount(data.count)
      if (data.results.length === 0) setNotFound(true)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch transactions. Please try again later.')
        setBusy(false)
        // setNotFound(true);
      }
    }
  }

  const sanitizeData = async () => {
    toast.info('Fetching data to export')

    try {
      const { data } = await getFinanceTransactions(user.retailerUuid, {
        search,
        ...(activeTab === 'All' ? {} : { status: activeTab }),
        ...(selectedFilter === 'range'
          ? {
              start_date: dateRange.start.format('YYYY-MM-DD'),
              end_date: dateRange.end.format('YYYY-MM-DD'),
            }
          : {
              date: activePeriod,
            }),
        paginate: 'no',
      })

      exportData(
        data.map(({ customer, order, ...entry }) => ({
          'Order Date': formatDate(order.created_at),
          'Order Time': formatTime(order.created_at),
          'Invoice ID': entry.transaction_id,
          Retailer: entry.retailer.name,
          Customer: [customer.profile.first_name, customer.profile.last_name]
            .filter(Boolean)
            .join(' '),
          Email: customer.email,
          'Phone Number': customer.phone_number || '',
          Status: order.status,
          'Order Items': order.order_item
            .map((item) => `x${item.quantity} ${item.product}`)
            .join('\n'),
          'Payment Date': formatDate(order.payment_date),
          'Delivery Option': order.delivery_options,
          'Delivery Address': order.delivery_address?.address,
          'Delivery Date': formatTime(order.delivery_date),
          'Delivery Time': order.delivery_time,
          'Delivery Fee': order.delivery_fee
            ? numberFormat(order.delivery_fee.amount).toCurrency(
                order.delivery_fee.currency,
              )
            : '',
          Discount: numberFormat(order.discount_total || 0).toCurrency(),
          Tax: numberFormat(order.tax).toCurrency(),
          Amount: numberFormat(order.final_cost.amount).toCurrency(
            order.final_cost.currency,
          ),
        })),
        'Transactions',
      )
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later')
      setBusy(false)
    } finally {
      toast.dismiss()
    }
  }

  const openTransactionDetail = (id) => {
    history.push(`/finance/transaction-detail/${id}`)
  }

  useEffect(() => {
    fetchTransactions()
  }, [
    search,
    activePeriod,
    dateRange,
    activeTab,
    currentPage,
    rowsPerPage,
    selectedRetailers,
    selectedStatus,
    selectedTypes,
  ])

  return (
    <Layout>
      <div className="px-[30px] pt-5 font-bold">
        <PageTitle title="Transaction History" />
        <SearchBar
          filterButton={
            <TransactionFilter
              activeTab={activeTab}
              menu={menu}
              onFinish={(retailers, status, types) => {
                setCurrentPage(1)
                setSelectedRetailers(retailers)
                setSelectedStatus(status)
                setSelectedTypes(types)
              }}
            />
          }
          placeholder="Search transaction history"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          onExport={sanitizeData}
        >
          {tabs.map((item) => (
            <TabButton
              key={item.id}
              activeTab={activeTab}
              onClick={() => {
                setCurrentPage(1)
                setActiveTab(item.id)
              }}
              tab={item.id}
              title={item.label}
            />
          ))}
        </SearchBar>
        <DateActions
          activePeriod={selectedFilter === 'period' ? activePeriod : undefined}
          changePeriod={(period) => {
            setSelectedFilter('period')
            setCurrentPage(1)
            setActivePeriod(period)
          }}
          className="justify-end w-full mt-4"
          dateRange={dateRange}
          onDateRangeSelect={(range) => {
            setSelectedFilter('range')
            setCurrentPage(1)
            setDateRange(range)
          }}
        />
        {busy && search.length === 0 ? (
          <Loader />
        ) : transactions.length === 0 && search.length === 0 ? (
          <EmptyTableView
            title={'Your transactions will show here'}
            subtitle={'This is where you will find your transactions'}
            icon={financeIcon}
          />
        ) : (
          <FinanceTable
            rows={transactions}
            onViewClick={openTransactionDetail}
            search={search}
            busy={busy}
            notFound={notFound}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(rows) => {
              setCurrentPage(1)
              setRowsPerPage(rows)
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default TransactionHistory
