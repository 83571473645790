export const statuses = {
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
  ON_DELIVERY: 'On Delivery',
  COMPLETED: 'Completed',
  REFUNDED: 'Refunded',
  DRAFTING: 'Drafting',
  PROCESSING: 'Processing',
  READY_FOR_PICKUP: 'Ready for Pickup',
}

export const orderStatus = [
  { label: 'Pending' },
  { label: 'Cancelled' },
  { label: 'On Delivery' },
  { label: 'Completed' },
  { label: 'Refunded' },
  { label: 'Drafting' },
  { label: 'Processing' },
  { label: 'Ready for Pickup' },
]

export const payments = [
  {
    label: 'Bank Transfer',
    value: 'Cheque',
  },
  {
    label: 'Cash',
    value: 'Cash',
  },
  {
    label: 'POS',
    value: 'Paystack',
  },
  {
    label: 'GTB POS',
    value: 'GTB POS',
  },
  {
    label: 'Providus POS',
    value: 'Providus POS',
  },
  {
    label: 'Providus',
    value: 'Providus',
  },
  {
    label: 'GTB Transfers',
    value: 'GTB',
  },
  {
    label: 'Online Payment',
    value: 'Online Payment',
  },
]

export const datePeriods = [
  { label: 'Today', value: 'today' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
]

export const deliveryOptions = {
  DELIVER: 'Deliver',
  PICKUP: 'Pickup',
}
