import React, { useContext, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

//icons
import pen from "../../assets/pen.svg";

//mui
import { Button } from "@material-ui/core";
import { RootContext } from "../..";

import { copyToClipboard } from '@/utils'


const ProductInfo = observer(({ showButton, stock, product }) => {
  const history = useHistory();
  const root = useContext(RootContext);
  const descRef = useRef(null);

  const [viewId, setViewId] = React.useState(true)

  const copyId = async () => {
    const text = viewId ? product.id : product.uuid
    await copyToClipboard(text)
    toast.success(`Copied product id "${text}"`)
  }

  useEffect(() => {
    descRef.current.innerHTML = product.description;
  }, []);

  return (
    <div className="p-10 bg-white">
      <div>
        <p className="text-sm mb-1 text-right">
          Click the text beside the button to switch between&nbsp;
          <span className={`font-semibold ${viewId ? 'text-orange' : ''}`}>id</span> and&nbsp;
          <span className={`font-semibold ${!viewId ? 'text-orange' : ''}`}>uuid</span>
        </p>
        <div className="bg-[#F4F5F7] rounded-lg p-1 pl-2 flex items-center mb-5 w-[fit-content]" style={{marginLeft: 'auto'}}>
          <span className="mr-3 font-bold" style={{ 'cursor': 'default' }} onClick={() => setViewId(!viewId)}>
            { viewId ? product.id : product.uuid }
          </span>
          <button className="bg-white p-1 rounded-md" onClick={copyId}>Copy</button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <h1 className="font-semibold text-gray1 text-[20px]">Product Info</h1>

        {showButton && (
          <Button
            variant="contained"
            className="text-white capitalize shadow-none bg-orange"
            startIcon={<img src={pen} alt="" />}
            onClick={() => {
              root.authStore.user.account_type.includes("Admin")
                ? history.push(`/products/requests/edit/${product.uuid}`)
                : history.push("/inventory/requests/edit");
            }}
          >
            Update Product Info
          </Button>
        )}
      </div>

      <div className="divide-y divide-[#E3E7ED]">
        <div className="pb-10 pt-5 space-y-5">
          <div className="flex items-center space-x-10">
            <div>
              <h1 className="text-sm text-gray3">Product Name</h1>
              <p className="font-medium text-gray2">{product.name}</p>
            </div>

            {stock ? (
              <div>
                <h1 className="text-sm text-gray3">Market Price</h1>
                <p className="font-medium text-gray2">
                  ₦{Number(stock.selling_price.amount).toLocaleString("en")}
                </p>
              </div>
            ) : null
            // <div>
            //   <h1 className="text-sm text-gray3">Market Price</h1>
            //   <p className="font-medium text-gray2">₦{Number(product.price.amount).toLocaleString('en')}</p>
            // </div>
            }
          </div>
          <div>
            <h1 className="text-sm text-gray3">Product Description</h1>
            <p ref={descRef} className="text-gray2"></p>
          </div>
          <div>
            <h1 className="text-sm text-gray3">Product Meta Title</h1>
            <p className="text-gray2">{product.title_meta || "N/A"}</p>
          </div>
          <div>
            <h1 className="text-sm text-gray3">Product Meta Description</h1>
            <p className="text-gray2">{product.description_meta || "N/A"}</p>
          </div>
          <div>
            <h1 className="text-sm text-gray3">Brands</h1>
            <p className="text-gray2">
              {product.brand.map(({ name }) => name).join(", ")}
            </p>
          </div>
          <div className="flex">
            <div>
              <h1 className="text-sm text-gray3">Featured</h1>
              <p className="text-gray2 mb-0">
                {product.featured ? "Yes" : "No"}
              </p>
            </div>
            <div className="ml-[3rem]">
              <h1 className="text-sm text-gray3">Engraving</h1>
              <p className="text-gray2 mb-0">
                {product.engrave ? "Yes" : "No"}
              </p>
            </div>
          </div>
        </div>

        {product.image && (
          <div className="py-10 space-y-5">
            <h1 className="text-gray1 font-semibold text-[20px]">Media</h1>

            <div className="grid grid-cols-5 gap-5">
              <img src={product.image} alt="" />
              {product.media &&
                product.media.map((item, index) => (
                  <img src={item.media} alt="" key={index} />
                ))}
            </div>
          </div>
        )}

        {stock && (
          <div className="py-10 space-y-5">
            <h1 className="text-gray1 font-semibold text-[20px]">Inventory</h1>

            <div className="flex items-center space-x-10">
              <div>
                <h1 className="text-sm text-gray3">SKU</h1>
                <p className="font-medium text-gray2">{stock.sku}</p>
              </div>
            </div>
            <div>
              <h1 className="text-sm text-gray3">Attribute</h1>
              <p className="font-medium text-gray2">
                {stock.attribute && stock.attribute.name}
              </p>
            </div>
          </div>
        )}

        <div className="py-10 space-y-5">
          <h1 className="text-gray1 font-semibold text-[20px]">Product Type</h1>

          {product.category.length > 0 && (
            <div>
              <h1 className="text-sm text-gray3">Category</h1>
              <p className="font-medium text-gray2">
                {product.category[0].name}
              </p>
            </div>
          )}

          {product.attribute.length > 0 && (
            <div>
              <h1 className="text-sm text-gray3">Volume</h1>
              <p className="font-medium text-gray2">
                {product.attribute[0].name}
              </p>
            </div>
          )}

          <div>
            <h1 className="text-sm text-gray3">Pack</h1>
            <p className="font-medium text-gray2">Bottle</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProductInfo;
