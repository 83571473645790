import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";

//mui
import { Badge, IconButton, Popover, Avatar } from "@material-ui/core";

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications] = useState([]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Badge badgeContent={notifications.length} color="primary">
        <IconButton className="p-0" onClick={handleClick}>
          <IoNotifications className="w-6 h-6 text-gray-400" />
        </IconButton>
      </Badge>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          root: "",
          paper: "bg-transparent shadow-none pt-2 pb-5",
        }}
      >
        <div className="bg-white w-[600px] shadow-md rounded-md pb-5">
          <div className="border-b border-[#E3E7ED] p-3">
            <h1 className="text-[20px] text-[#040A1D] font-bold">
              Notifications
            </h1>
          </div>

          <div className="divide-y divide-[#E3E7ED] px-6 overflow-y-scroll h-80">
            {notifications.length > 0 ? (
              <>
                {notifications.map((not, idx) => {
                  return (
                    <div key={idx} className="flex items-center py-4">
                      <Avatar className="">DS</Avatar>
                      <p className="w-[70%] ml-2 text-sm">
                        New user Daniel Sams{" "}
                        <span className="text-orange">
                          (daniel@drinksng.com)
                        </span>{" "}
                        accepted invite to join dashboard.
                      </p>

                      <p className="text-[12px] text-gray2 flex-grow text-right">
                        Yesterday 09:40
                      </p>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p>No Notifications</p>
              </div>
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default NotificationDropdown;
