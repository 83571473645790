import React, { useMemo, useRef, useState } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { IoClose } from "react-icons/io5";
import loader from "../../assets/loader.svg";

const CustomMultiSelectSearch = ({
  fetchOptions,
  data,
  debounceTimeout = 800,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([...data]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const tagRender = (props) => {
    return (
      <div className="bg-white mr-1 mb-1 flex gap-2 items-center p-1 rounded">
        {props.label}
        <span onClick={() => props.onClose()} className="cursor-pointer">
          <IoClose />
        </span>
      </div>
    );
  };
  return (
    <div className="bg-[#F4F5F7] p-2 rounded-md">
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={
          fetching ? (
            <div className="flex justify-center">
              <img src={loader} alt="" className="w-5 animate-spin" />
            </div>
          ) : (
            "Not found"
          )
        }
        {...props}
        options={options}
        bordered={false}
        showArrow={true}
        size="large"
        tagRender={tagRender}
      />
    </div>
  );
}; // Usage of DebounceSelect

export default CustomMultiSelectSearch;
