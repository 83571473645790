import { useEffect, useState } from "react"
import { Button } from "@material-ui/core";
import { IoAddOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

//components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchBar from "../../components/SearchBar/SearchBar";
import CreateAttributeModal from "../../components/CreateAttributeModal/CreateAttributeModal";
import EditAttributeModal from "../../components/EditAttributeModal/EditAttributeModal";

//utils
import { exportData, fetchAllAttributes } from "../../utils";
import AttributesTable from "../../components/ProductsTables/AttributesTable";
import { searchAttributes, createProductAttribute, updateProductAttribute } from "../../services/products";


const Attributes = () => {
  const history = useHistory();

  const [busy, setBusy] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [parents, setParents] = useState([]);
  const [activeAttribute, setActiveAttribute] = useState();
  const [attributes, setAttributes] = useState([]);
  const [search, setSearch] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  let typingTimer;

  const fetchAttributes = async() => {
    setBusy(true);
    try {
      const data = await fetchAllAttributes();
      const sortedList = await sortAttributes(data);
      setAttributes(sortedList);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("Unable to fetch product attributes. Please try again later.");
      history.goBack();
    }
  }

  const sortAttributes = async(attributes) => {
    const parents = []
    attributes.forEach(element => {
      if(element.parent) {
         const parent = attributes.find(item => item.id = element.parent);
         element.parent = parent;
      } else {
        parents.push(element);
      }
    });
    setParents(parents);
    return attributes;
  }

  const handleSearchKeyDown = () => {
    clearTimeout(typingTimer)
  }

  const handleSearchKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      handleSearch(1, 100)
    }, 500);
  }

  const handleSearch = async(pageNumber, pageSize) => {
    if(search.length === 0) {
      return;
    }
    setBusy(true);
    try {
      const { data } = await searchAttributes(search, pageNumber, pageSize);
      const sortedList = await sortAttributes(data.results);
      setAttributes(sortedList);
      if(data.count === 0)
        setNotFound(true);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      setNotFound(false);
      toast.error("Unable to fetch requested data")
    }
  }

  const handleCreateSubmit = async(form) => {
    try {
      await createProductAttribute(form);
      fetchAttributes();
      setShowAddModal(false);
      toast.success("Atrribute added successfully.")
    } catch (error) {
      setShowAddModal(false);
      toast.error("An error occurred. Unable to create attribute");
    }
  }

  const handleEditSubmit = async(form, id) => {
    try {
      await updateProductAttribute(form, id);
      fetchAttributes();
      setShowEditModal(false);
      toast.success("Attribute updated successfully.")
    } catch (error) {
      setShowEditModal(false)
      toast.error("An error occurred. Unable to create attribute");
    }
  }

  const sanitizeData = () => {
    const copy = [...attributes];
    copy.forEach(element => {
      delete element.uuid;
      delete element.parent;
      if(!element.description)
        element.description = '';
    });
    exportData(copy, 'Attributes')
  }

  const openEditModal = (attribute) => {
    setActiveAttribute(attribute);
    setShowEditModal(true);
  }

  const openCreateModal = () => {
    setShowAddModal(true);
  }

  useEffect(() => {
    fetchAttributes()
  }, [])

  return(
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Attributes" />

        <SearchBar
          placeholder="Search Attributes"
          onSearchKeyDown={handleSearchKeyDown}
          onSearchKeyUp={handleSearchKeyUp}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          onExport={sanitizeData}
        >
        </SearchBar>
        {busy && search.length === 0 ? (
          <Loader />
        ) : (
          <>
            <div className="py-5 space-y-5"> 
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={openCreateModal}
                > 
                  Add New Attribute
                </Button>
              </div>
              <AttributesTable 
                rows={attributes}
                onEditClick={openEditModal}
                busy={busy}
                search={search}
                notFound={notFound}                
              />
            </div>
          </>
        )}
        <CreateAttributeModal 
          open={showAddModal}
          closeModal={() => setShowAddModal(false)}
          parents={parents}
          onSubmit={handleCreateSubmit}
        />
        {activeAttribute && (
          <EditAttributeModal 
            open={showEditModal}
            closeModal={() => {
              setActiveAttribute(null);
              setShowEditModal(false);
            }}
            attribute={activeAttribute}
            parents={parents}
            onSubmit={handleEditSubmit}
          />
        )}
      </div>
    </Layout>
  )
}

export default Attributes;