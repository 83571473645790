import { Editor as TinyMCE } from '@tinymce/tinymce-react';

export default function Editor({ value='', onChange=()=>{}, init={}, ...props }) {
	return (
		<TinyMCE
		  value={value}
		  apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
		  init={{
		  	content_style: "body {font-family: 'IBM Plex Sans', sans-serif; font-size: 14px; margin: 0, padding: 0 15px}",
		    menubar: 'edit view insert',
		    menu: {
		    	view: {
		    		title: 'View',
		    		items: 'visualaid visualchars visualblocks | spellchecker | preview fullscreen'
		    	},
		    	insert: {
		    		title: 'Insert',
		    		items: 'image link media template codesample | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
		    	},
		    },
		    statusbar: false,
		    link_assume_external_targets: true,
		    plugins: [
		      'advlist autolink lists link image charmap preview anchor',
		      'searchreplace visualblocks',
		      'insertdatetime media table paste'
		    ],
		    default_link_target: "_blank",
		    toolbar: props.disabled ? false :
		      "bold underline strikethrough | formatselect fontsizeselect lineheight | align | " +
		      "bullist numlist | outdent indent | table | removeformat"
		    ,
		    table_toolbar: "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
		    ...init
		  }}
		  onEditorChange={(content, _) => onChange(content)}
		  {...props}
		/>
	)
}
