import React from "react";
import { useHistory } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";

//mui
import { IconButton } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import AddNewProductForm from "../../components/AddNewProductForm/AddNewProductForm";


const AddNewProduct = () => {
  const history = useHistory();

  return (
    <Layout>
      <div className="flex flex-col items-center py-10 space-y-5 mb-[20px]">
        <div className="w-[70%] flex items-center space-x-3">
          <IconButton
            onClick={() => history.goBack()}
            disableRipple
            className="p-0 hover:bg-transparent"
          >
            <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
          </IconButton>
          <h1 className="font-bold text-[30px] text-gray1 mb-0">Add New Product</h1>
        </div>

        <AddNewProductForm />
      </div>
    </Layout>
  );
};

export default AddNewProduct;
