import Providus from "../../assets/providusBank.png";
import Gtbank from "../../assets/Gtbank.png";
import { useState } from "react";
import SaveModal from "../../components/SaveModal/SaveModal";
import { getToken } from "../../utils";
import { addOrderSplitPayments, createOrder } from "../../services/orders";
import orders from "../../assets/orders-inactive.svg";
import { toast } from "react-toastify";
import PaymentMethod from "../../components/PaymentMethod/PaymentMethod";
import { Warning } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const SplitPayment = ({
  orderDetails,
  final,
  onUpdate,
  customer,
  clearForm,
}) => {
  //form values
  const [paymentTypes, setPaymentTypes] = useState([
    { name: "Providus", amount: "", checked: false, image: Providus },
    { name: "GTB", amount: "", checked: false, image: Gtbank },
  ]);
  const defaultTotalAmount = Number(final);

  //state management
  const [busy, setBusy] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [active, setActive] = useState(false);

  //form management
  const handleCheckboxChange = (index) => {
    const updatedPaymentTypes = [...paymentTypes];
    updatedPaymentTypes[index].checked = !updatedPaymentTypes[index].checked;
    if (!updatedPaymentTypes[index].checked) {
      updatedPaymentTypes[index].amount = 0;
    }
    setPaymentTypes(updatedPaymentTypes);
  };

  const handleAmountChange = (index, event) => {
    const { value } = event.target;
    const updatedPaymentTypes = [...paymentTypes];
    updatedPaymentTypes[index].amount = value;
    if (value === "") {
      updatedPaymentTypes[index].checked = false;
    } else {
      updatedPaymentTypes[index].checked = true;
    }
    setPaymentTypes(updatedPaymentTypes);

    setActive(true);
  };

  //Submit Form
  const submitOrder = async () => {
    setBusy(true);
    setShowSaveModal(true);

    const token = getToken();

    const data = paymentTypes.map((paymentType) => ({
      payment_channel: paymentType.name,
      amount: paymentType.amount === "" ? 0 : parseFloat(paymentType.amount),
    }));

    try {
      await createOrder(orderDetails).then(async (res) => {
        await addOrderSplitPayments({
          order_uuid: res.data.uuid,
          payment: data,
          is_dashboard: true,
          customer_uuid: customer.uuid,
        });
      });
      toast.success("Order successfully checked out");
      setBusy(false);
    } catch (error) {
      setShowSaveModal(false);
      setBusy(false);
      toast.error(
        error.error ||
          error.message ||
          "Unable to create order. Please try again later."
      );
    }
  };

  // Calculate the total amount
  const totalAmount = paymentTypes
    .filter((type) => type.amount !== "")
    .reduce((total, paymentType) => total + parseFloat(paymentType.amount), 0);

  return (
    <>
      <div
        className="w-[830px] bg-white pt-10 pb-5 flex flex-col gap-8"
        style={{ paddingLeft: "32px", paddingRight: "32px" }}
      >
        <div>
          <p className="text-gray2 mb-0">Amount Due</p>
          <p className="text-2xl font-medium text-gray1 mb-0">
            ₦{Number(defaultTotalAmount).toLocaleString("en")}
          </p>
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-gray2 mb-0">
            Please choose your mode of payment to continue
          </p>

          {paymentTypes.map((paymentType, index) => (
            <div key={index}>
              <PaymentMethod
                checked={paymentType.checked}
                handleCheck={() => handleCheckboxChange(index)}
                image={paymentType.image}
                handleChange={(event) => handleAmountChange(index, event)}
                value={paymentType.amount}
              />
            </div>
          ))}

          {totalAmount !== defaultTotalAmount && active && (
            <div className="flex items-center gap-2 bg-red w-full rounded-lg p-2 text-white">
              <Warning />
              <p className="mb-0">
                The total amount(s) entered do not equal the amount due. Amount
                left: {defaultTotalAmount - totalAmount}
              </p>
            </div>
          )}

          <div className="flex flex-row gap-2 items-center justify-end mt-[20px]">
            <Button
              variant="contained"
              className="capitalize bg-white shadow-none border border-[#E3E7ED]"
              onClick={() => onUpdate(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`text-white capitalize shadow-none
                ${
                  totalAmount !== defaultTotalAmount
                    ? "bg-orange/30 cursor-not-allowed"
                    : "bg-orange cursor-pointer"
                }
              `}
              onClick={submitOrder}
              disabled={totalAmount !== defaultTotalAmount}
            >
              Save Order
            </Button>
          </div>
        </div>
      </div>

      <SaveModal
        isOpen={showSaveModal}
        close={() => setShowSaveModal(false)}
        handleAddPress={clearForm}
        icon={orders}
        progressLabel={"Saving your order"}
        successLabel={"Your order has been"}
        btnLabel={"Add New Order"}
        busy={busy}
      />
    </>
  );
};
export default SplitPayment;
