import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

//utils
import validateEmail from "../../utils/validateEmail";

//components
import AuthCard from "../../components/AuthCard/AuthCard";
import FormInput from "../../components/FormInput/FormInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

import { sendResetPasswordEmail } from "../../services/auth";

const ResetPassword = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleError = (e) => {
    if(typeof e.response.data === "object") {
      try {
        setError(e.response.data.non_field_errors[0]);
      } catch (error) {
        setError("An unexpected error occurred. Please try again")
      }
    }
    else setError("An unexpected error occurred. Please try again");
  }

  const submit = async(e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const isEmailValid = validateEmail(email);

    if (!isEmailValid) {
      setError("Invalid email address");
      setLoading(false);
      return;
    }

    try {
      await sendResetPasswordEmail({"email": email});
      history.push(`/reset-password/${email}/verify-identity`);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  return (
    <AuthCard>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-2xl font-bold text-center">Reset Password</h1>

        <p className="leading-none text-center text-gray2 w-[60%] text-sm">
          Enter email address associated with your account and we'll send you a
          code to reset your password
        </p>
      </div>

      <div className="w-[65%]">
        <form className="space-y-2" onSubmit={submit}>
          {error && <ErrorAlert error={error} />}
          <FormInput
            id="email"
            name="email"
            type="email"
            placeholder="Email Address"
            label="Email Address"
            onChange={handleChange}
            value={email}
            error={error}
          />
        </form>

        <CustomButton
          text="Continue"
          onClick={submit}
          disabled={email === ""}
          loading={loading}
        />

        <div className="flex items-center justify-center mt-2">
          <Link
            to="/login"
            className="font-bold text-[12px] underline text-blue"
          >
            Return to login
          </Link>
        </div>

        <div className="flex items-center justify-center mt-3">
          <span className="text-[12px] text-gray-400">
            Don't have an account?{" "}
            <Link to="/login" className="font-bold underline text-blue">
              Sign up
            </Link>
          </span>
        </div>
      </div>
    </AuthCard>
  );
};

export default ResetPassword;
