import React, { useState, useContext, useEffect } from "react";
import { IoArrowUp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import validateEmail from "../../../utils/validateEmail";
import validatePhoneNumber from "../../../utils/validatePhoneNumber";
import { toast } from "react-toastify";

//icons
import productsInactive from "../../../assets/products-inactive.svg";
import loader from "../../../assets/loader.svg";
import success from "../../../assets/success.svg";
import deleteIcon from "../../../assets/delete.svg";
import editIcon from "../../../assets/edit.svg";

//mui
import { Button, Dialog, ButtonGroup } from "@material-ui/core";

//components
import FormInput from "../../../components/FormInput/FormInput";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import UnsavedChanges from "../../../components/UnsavedChanges/UnsavedChanges";

//services
import {
  createNewUser,
  createRetailerUser,
  updateProfile,
} from "../../../services/users";

//store
import { RootContext } from "../../..";

const AddNewProductForm = () => {
  const root = useContext(RootContext);
  const isRetailer =
    root.authStore.user &&
    root.authStore.user.account_type.includes("Retailer");

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  //modal state
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  //user details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+234");
  const [accountType, setAccountType] = useState("");
  const [gender, setGender] = useState("");
  const [image, setImage] = useState("");
  const [userCreateSuccess, setUserCreateSuccess] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUserCreateSuccess(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const isEmailValid = validateEmail(email);

    const phone = sanitizePhoneNumber();
    const isPhoneNumberValid = validatePhoneNumber(phone);

    if (!isEmailValid) {
      return toast.error("Check email format and try again.");
    }

    if (!isPhoneNumberValid) {
      return toast.error("Phone Number must be in +XXXXXXX format");
    }

    const data = {
      phone_number: phone,
      account_type: accountType,
      email,
      password,
    };

    const profile = {
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      image: image,
    };

    handleClickOpen();
    setLoading(true);

    try {
      const { retailerUuid } = root.authStore.user || {};

      if (retailerUuid) {
        const formData =
          accountType === "Customer"
            ? data
            : { retailer_uuid: retailerUuid, ...data };

        const res = await createRetailerUser(formData);
        await updateProfile(profile, res.data.profile);
      } else {
        const res = await createNewUser(data);
        await updateProfile(profile, res.data.profile);
      }

      setLoading(false);
      setUserCreateSuccess(true);
    } catch (error) {
      setOpen(false);
      setUserCreateSuccess(false);
      if (error.response.data) {
        for (let err in error.response.data) {
          toast.error(error.response.data[err][0]);
        }
      } else {
        toast.error("Error creating user.");
      }
    } finally {
      setLoading(false);
    }
  };

  const sanitizePhoneNumber = () => {
    if (phoneNumber.substring(0, 1) === "0")
      return `${countryCode}${phoneNumber.substring(1)}`;
    else return `${countryCode}${phoneNumber}`;
  };

  const clearInfo = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setPassword("");
    setImage("");
    setEmail("");
    setPhoneNumber("");
    setGender("");
  };

  return (
    <>
      <div className="bg-white w-[70%] p-5 space-y-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Personal Details</h1>
        <div className="grid grid-cols-2 gap-5">
          <FormInput
            type="text"
            label="First Name"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <FormInput
            type="text"
            label="Last Name"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <FormInput
            type="text"
            label="Email Address"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormInput
            type="password"
            label="Password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="flex flex-row items-center space-x-1">
            <CustomSelect
              label="Country Code"
              name="country-codes"
              id="country-codes"
              value={countryCode}
              options={["+233", "+234"]}
              setValue={setCountryCode}
            />
            <FormInput
              type="text"
              label="Phone Number"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <CustomSelect
            label="Account Type"
            placeholder="Account Type"
            id="accountType"
            value={accountType}
            setValue={setAccountType}
            options={
              isRetailer
                ? ["Customer", "Retailer Staff"]
                : [
                    "Customer",
                    "Retailer",
                    "Drinks Sales Person",
                    "Brands Analytics Admin",
                    "Brands Analytics User",
                    "Drinks Admin",
                  ]
            }
          />
          <CustomSelect
            options={["male", "female"]}
            label={"Gender"}
            value={gender}
            setValue={setGender}
          />
        </div>
      </div>

      <div className="bg-white w-[70%] p-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Profile Photo</h1>

        <div className="flex items-center space-x-5">
          {image && (
            <div className="w-60">
              <div className="relative border border-gray-300 border-dashed rounded-md">
                <div className="absolute left-0 flex justify-center w-full bottom-10">
                  <ButtonGroup className="bg-white">
                    <Button
                      onClick={() => {
                        setImage("");
                      }}
                    >
                      <img src={deleteIcon} alt="" />
                    </Button>
                    <Button>
                      <label
                        htmlFor="profile-image"
                        className="w-full h-full cursor-pointer"
                      >
                        <img src={editIcon} alt="" />
                        <input
                          type="file"
                          id="profile-image"
                          multiple
                          accept=".png,.jpeg,.jpg"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                          }}
                          className="hidden"
                        />
                      </label>
                    </Button>
                  </ButtonGroup>
                </div>

                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="object-cover w-full h-full rounded-md"
                />
              </div>
            </div>
          )}

          {!image && (
            <div
              className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                setImage(e.dataTransfer.files[0]);
              }}
            >
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
              </div>
              <label
                htmlFor="profile-image"
                className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
              >
                <p>Upload File</p>
                <input
                  type="file"
                  id="profile-image"
                  multiple
                  accept=".png,.jpeg,.jpg"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  className="hidden"
                />
              </label>

              <p className="text-sm text-gray2">or drop files to upload</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-[70%] flex justify-end items-center space-x-3">
        <Button
          variant="contained"
          className="capitalize bg-white shadow-none"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={`text-white capitalize shadow-none ${
            firstName === "" ||
            lastName === "" ||
            email === "" ||
            password === "" ||
            phoneNumber === "" ||
            image === ""
              ? "bg-orange/30 cursor-not-allowed"
              : "bg-orange cursor-pointer"
          }`}
          onClick={handleSubmit}
          disabled={
            firstName === "" ||
            lastName === "" ||
            email === "" ||
            password === "" ||
            phoneNumber === "" ||
            image === ""
          }
        >
          Save
        </Button>
      </div>

      <Dialog open={open}>
        {loading && (
          <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
            <img src={productsInactive} alt="" className="w-9 h-9" />
            <p className="font-medium capitalize text-gray2">Saving New User</p>
            <img src={loader} alt="" className="animate-spin" />
          </div>
        )}
        {userCreateSuccess && (
          <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
            <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
              <img src={success} alt="" className="" />
            </span>

            <p className="font-medium capitalize text-gray2 w-[38%] text-center">
              New User Added Successfully
            </p>

            <div className="space-x-3">
              <Button
                variant="contained"
                className="capitalize bg-white border shadow-none"
                onClick={() => {
                  clearInfo();
                  handleClose();
                }}
              >
                Add Another User
              </Button>

              <Button
                variant="contained"
                color="primary"
                className="capitalize shadow-none"
                onClick={() => {
                  handleClose();
                  history.push("/users");
                }}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </Dialog>

      <UnsavedChanges isOpen={modalOpen} close={() => setModalOpen(false)} />
    </>
  );
};

export default AddNewProductForm;
