import React from "react";

import CloseShop from "../../assets/close-shop.svg";
import OpenShop from "../../assets/open-shop.svg";

import { makeStyles, Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import { useContext } from "react";
import { RootContext } from "../..";
import { updateRetailer } from "../../services/retailers";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
});

function CloseStoreModal({ isOpen, setIsOpen }) {
  const classes = useStyles();
  const root = useContext(RootContext);

  const isStoreOpen =
    root.authStore.user.store_availability === "Open" ? true : false;

  const handleStoreStatus = async () => {
    try {
      setIsOpen(false);
      toast.info("updating status...", { autoClose: 2000 });
      const res = await updateRetailer(root.authStore.user.retailerUuid, {
        store_availability: !isStoreOpen ? "Open" : "Closed",
      });
      root.authStore.updateStoreAvailability(res.data.store_availability);
      toast.success("Status updated!");
    } catch (error) {
      toast.error("Error updating status");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={classes.modal}
    >
      <div className="w-[400px] bg-white flex flex-col gap-4 items-center pb-5 rounded-lg">
        <div className="py-1 border-b border-gray5 w-full">
          <p className="font-bold text-appBlack text-2xl text-center pt-5">
            {isStoreOpen ? "Close Shop" : "Open Shop"}
          </p>
        </div>

        {isStoreOpen ? (
          <img src={CloseShop} alt="close_shop" className="h-28 w-28" />
        ) : (
          <img src={OpenShop} alt="open_shop" className="h-28 w-28" />
        )}

        {isStoreOpen ? (
          <p className="text-center text-[18px] w-[80%] flex flex-col mt-5">
            When you close your shop, customers will not be able to make orders.
            <span className="font-bold">
              Are you sure you want to close your shop?
            </span>
          </p>
        ) : (
          <p className="text-center text-[18px] w-[80%] flex flex-col mt-5">
            When you open your shop, customers will now be able to make orders.
            <span className="font-bold">
              Are you sure you want to open your shop?
            </span>
          </p>
        )}

        <div className="space-x-5">
          <button
            className="bg-white py-2 px-5 rounded-lg text-gray2 border border-gray6 font-bold"
            onClick={() => setIsOpen(false)}
          >
            No, Cancel
          </button>
          <button
            className="bg-appRed text-white py-2 px-5 rounded-lg"
            onClick={handleStoreStatus}
          >
            Yes, {isStoreOpen ? "Close Shop" : "Open Shop"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CloseStoreModal;
