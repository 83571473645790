import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'

import { getComparator, stableSort } from '../../utils/tableFunctions'
import useTableMethods from '../../customHooks/useTableMethods'

//icons
import productIcon from '../../assets/products-active.svg'
import loader from '../../assets/loader.svg'

//temp
import drink from '../../assets/products-inactive.svg'

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  // Checkbox,
} from '@material-ui/core'

//components
import TableHead from './CustomTableHead'

//services
// import { getStock } from '../../services/stock'

// //store
// import { RootContext } from '../..'

const RetailersProducts = observer(
  ({
    data,
    busy,
    // searchResults = [],
    search,
    page,
    count = 0,
    rowsPerPage = 0,
    onPageChange = () => {},
    onRowsPerPageChange = () => {},
  }) => {
    // const root = useContext(RootContext)
    // const [busy, setBusy] = useState(true)
    // const [data, setData] = useState([])
    // const [count, setCount] = useState(0)
    // const [pageSize, setPageSize] = useState(10)
    // const [pageNumber, setPageNumber] = useState(1)

    // const {
    //   handleSelectAllClick,
    //   handleClick,
    //   handleChangePage,
    //   handleChangeRowsPerPage,
    //   isSelected,
    //   order,
    //   orderBy,
    //   selected,
    //   page,
    //   rowsPerPage,
    // } = useTableMethods(data)

    // const fetchProducts = async (pageNumber, pageSize) => {
    //   setBusy(true)
    //   try {
    //     const { data } = await getStock(retailerId, {
    //       page: pageNumber,
    //       page_size: pageSize
    //     });
    //     // updateParent(data.results);
    //     setData(data.results);
    //     setCount(data.count);
    //     setBusy(false);
    //   } catch (error) {
    //     setBusy(false);
    //     toast.error("An error occurred. Unable to fetch this retailer's products.")
    //   }
    // }

    // const handleRowsPerPageChange = (e) => {
    //   const newRowsPerPage = parseInt(e.target.value, 10);
    //   setPageSize(newRowsPerPage);
    //   fetchProducts(pageNumber, newRowsPerPage);
    // }

    // const handlePageChange = (_, newPage) => {
    //   setPageNumber(newPage + 1);
    //   fetchProducts(newPage + 1, pageSize)
    // }

    // useEffect(() => {
    //   fetchProducts(1, 10);
    // }, [])

    return (
      <div className="w-full mt-5">
        {busy || data.length === 0 ? (
          <>
            <Table className="min-w-[750px]" size="small">
              <TableHead
                // numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                rowCount={count}
              />
            </Table>
            <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
              {busy ? (
                <img src={loader} alt="loading" className="w-5 animate-spin" />
              ) : search.length > 0 && !count ? (
                <>
                  <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                    No Search Result for "{search}"
                  </p>
                  <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                    <span className="text-[#8892A2] text-[14px] font-bold">
                      Search tips:{' '}
                    </span>
                    Some search terms require an exact match. Try typing the
                    entire term, or use a different word or phrase.
                  </p>
                </>
              ) : (
                <>
                  <img src={productIcon} alt="" className="w-[72px] h-[72px]" />
                  <p className="text-[18px] text-[#3D4356] font-bold mt-2">
                    Products will show here.
                  </p>
                </>
              )}
            </div>
          </>
        ) : (
          <Table className="min-w-[750px]" size="small">
            <TableHead
              // numSelected={selected.length}
              // onSelectAllClick={handleSelectAllClick}
              rowCount={count}
            />

            <TableBody className="bg-white">
              {data.map((row, index) => {
                // const isItemSelected = isSelected(index)

                return (
                  <TableRow
                    hover
                    // role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    // selected={isItemSelected}
                    className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, index)}
                      />
                    </TableCell> */}
                    <TableCell className="flex items-center space-x-2">
                      <img
                        src={row.product.image || drink}
                        alt={row.product.name}
                        className="w-[50px]"
                      />

                      <p className="text-sm font-medium text-gray2">
                        {row.product.name}
                      </p>
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.quantity}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      <span
                        className={`px-3 py-1 rounded-2xl capitalize ${
                          !row.out_of_stock && 'bg-gray2/20 text-gray2'
                        } ${row.out_of_stock && 'bg-red/20 text-red'}
                        `}
                      >
                        {row.out_of_stock ? 'Out of Stock' : 'In stock'}
                      </span>
                    </TableCell>
                    <TableCell className="font-medium text-black" align="right">
                      ₦{Number(row.selling_price.amount).toLocaleString('en')}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
        {!busy && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={(_, pg) => onPageChange(pg + 1)}
            onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
          />
          // <TablePagination
          //   rowsPerPageOptions={[10, 25, 40]}
          //   component="div"
          //   count={search.length > 0 ? searchResults.length : count}
          //   rowsPerPage={search.length > 0 ? rowsPerPage : pageSize}
          //   page={search.length > 0 ? page : pageNumber - 1}
          //   onPageChange={
          //     search.length > 0 ? handleChangePage : handlePageChange
          //   }
          //   onRowsPerPageChange={
          //     search.length > 0
          //       ? handleChangeRowsPerPage
          //       : handleRowsPerPageChange
          //   }
          // />
        )}
      </div>
    )
  }
)

export default RetailersProducts
