import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
} from "@material-ui/core";

import { RootContext } from "../../..";

const headCells = [
  { id: "productName", label: "Product Name" },
  { id: "brand", label: "brand" },
  { id: "retailPrice", numeric: true, label: "retail Price" },
];

const ProductsTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  selected,
}) => {
  const history = useHistory();
  const root = useContext(RootContext)
  return (
    <TableHead className="bg-transparent">
      {numSelected > 0 ? (
        <TableRow className="h-12 bg-white rounded-md">
          <TableCell padding="checkbox" className="">
            <Checkbox
              indeterminate={numSelected > 0}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              className={`${
                numSelected > 0 && numSelected < rowCount && "text-blue"
              }`}
              classes={{
                root: "",
                colorPrimary: "",
                checked: "text-blue",
              }}
            />
          </TableCell>

          <TableCell className="uppercase text-[12px]" colSpan={12}>
            <div className="flex items-center space-x-5">
              <p className="text-sm font-medium capitalize text-green">
                <span className="px-2 py-1 mr-2 text-white rounded-md bg-green">
                  {numSelected}
                </span>
                Item(s) selected
              </p>

              <Button
                variant="outlined"
                className="font-semibold capitalize border text-gray2"
                onClick={() => {
                  root.inventoryStore.addToInventory(selected)
                  history.push("/inventory/products/products-to-add");
                }}
              >
                Add Selected Items to Inventory
              </Button>
            </div>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              className={`${
                numSelected > 0 && numSelected < rowCount && "text-blue"
              }`}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className="uppercase text-[12px]"
              align={headCell.numeric ? "right" : "left"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      )}
    </TableHead>
  );
};

export default ProductsTableHead;
