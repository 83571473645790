import { useEffect, useState } from "react"
import { Button, Modal } from "@material-ui/core";
import { IoAddOutline, IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

//components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchBar from "../../components/SearchBar/SearchBar";
import CreateCategoryModal from "../../components/CreateCategoryModal/CreateCategoryModal";
import CategoriesTable from "../../components/ProductsTables/CategoriesTable";
import EditCategoryModal from "../../components/EditCategoryModal/EditCategoryModal";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";

//utils
import { exportData, fetchAllProductCategories } from "../../utils";

//services
import {
  searchCategories,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
  getSubCategories,
} from "../../services/products";


const Categories = () => {
  const history = useHistory();

  const [busy, setBusy] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [parents, setParents] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteParent, setConfirmDeleteParent] = useState(null)
  const [proceedWithDeletion, setProceedWithDeletion] = useState(false)

  let typingTimer;

  const fetchCategories = async() => {
    setBusy(true);
    try {
      const data = await fetchAllProductCategories();
      const sortedList = await sortCategories(data);
      setCategories(sortedList);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("Unable to fetch product attributes. Please try again later.");
      history.goBack();
    }
  }

  const sortCategories = async(categories) => {
    const parents = []
    categories.forEach(element => {
      if(element.parent) {
         const parent = categories.find(item => item.id === element.parent);
         element.parent = parent;
      } else {
        parents.push(element);
      }
    });
    setParents(parents);
    return categories;
  }

  const handleSearchKeyDown = () => {
    clearTimeout(typingTimer)
  }

  const handleSearchKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      handleSearch(1, 100)
    }, 500);
  }

  const handleSearch = async(pageNumber, pageSize) => {
    if(search.length === 0) {
      return;
    }
    setBusy(true);
    try {
      const { data } = await searchCategories(search, pageNumber, pageSize);
      const sortedList = await sortCategories(data.results);
      setCategories(sortedList);
      if(data.count === 0)
        setNotFound(true);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      setNotFound(false);
      toast.error("Unable to fetch requested data")
    }
  }

  const handleCreateSubmit = async(form) => {
    try {
      await createProductCategory(form);
      fetchCategories();
      setShowAddModal(false);
      toast.success("Product category has been added successfully.")
    } catch (error) {
      setShowAddModal(false);
      toast.error("An error occurred. Unable to create category.")
    }
  }

  const handleEditSubmit = async(form, id) => {
    try {
      await updateProductCategory(form, id);
      fetchCategories();
      setShowEditModal(false);
      toast.success("Product category has been updated successfully.")
    } catch (error) {
      setShowEditModal(false);
      toast.error("An error occurred. Unable to update category.")
    }
  }

  const sanitizeData = () => {
    const copy = [...categories];
    copy.forEach(element => {
      delete element.image;
      delete element.image_2;
      delete element.uuid;
      delete element.parent;
      if(!element.description)
        element.description = ''
    });
    exportData(copy, 'Product Categories')
  }

  const openEditModal = (category) => {
    setActiveCategory(category);
    setShowEditModal(true);
  }

  const openCreateModal = () => {
    setShowAddModal(true);
  }

  const deleteCategory = async (item, deleteChildren=false) => {
    try {
      if (deleteChildren) {
        const children = await getSubCategories(item.id)
        toast.info(`Deleting sub categories of "${item.name}"...`)
        for (const child of children) {
          await deleteProductCategory(child.id)
        }
        toast.success(`Sub categories of "${item.name}" successfully deleted.`)
      }
      toast.info(`Deleting "${item.name}" category...`)
      await deleteProductCategory(item.id)
      toast.success(`"${item.name}" category successfully deleted.`)
      fetchCategories()
    } catch(err) {
      toast.error(err.message || 'Failed to delete category.')
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if(search.length === 0)
      fetchCategories();
  }, [search])

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Categories" />
        <SearchBar
          placeholder="Search Categories"
          onSearchKeyDown={handleSearchKeyDown}
          onSearchKeyUp={handleSearchKeyUp}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          onExport={sanitizeData}
        >
        </SearchBar>
        {busy && search.length === 0 ? (
          <Loader />
        ) : (
          <>
            <div className="py-5 space-y-5">
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={openCreateModal}
                > 
                  Add New Category
                </Button>
              </div>
              <CategoriesTable 
                rows={categories}
                onEditClick={openEditModal}
                onDeleteClick={item => {
                  if (item.parent) {
                    setConfirmDelete(item)
                  } else {
                    setConfirmDeleteParent(item)
                  }
                }}
                busy={busy}
                search={search}
                notFound={notFound}
              />
            </div>
          </>
        )}
        <CreateCategoryModal 
          open={showAddModal}
          closeModal={() => setShowAddModal(false)}
          parents={parents}
          onSubmit={handleCreateSubmit}
        />
        {activeCategory && (
          <EditCategoryModal 
            open={showEditModal}
            closeModal={() => {
              setActiveCategory(null);
              setShowEditModal(false);
            }}
            category={activeCategory}
            parents={parents}
            onSubmit={handleEditSubmit}
          />
        )}

        {/* delete parent confirmation modal */}
        <ConfirmDeleteModal
          open={Boolean(confirmDeleteParent)}
          onClose={() => setConfirmDeleteParent(null)}
          onConfirm={() => {
            deleteCategory(confirmDeleteParent, true)
            setConfirmDeleteParent(null)
          }}
        >
          <p className="text-center max-w-[32ch] mx-auto">
            Deleting&nbsp;
            <span className="text-orange font-medium">
              "{confirmDeleteParent?.name}"
            </span>
            &nbsp;will delete all of its sub categories.
          </p>
          <p className="max-w-[40ch] text-center mt-7">
            <span className="font-bold">Quick Tip</span> <br/>
            Create a new category and migrate all sub categories to the newly created category before deleting.
          </p>
        </ConfirmDeleteModal>

        {/* delete child confirmation modal */}
        <ConfirmDeleteModal
          open={Boolean(confirmDelete)}
          onClose={() => setConfirmDelete(null)}
          onConfirm={() => {
            deleteCategory(confirmDelete)
            setConfirmDelete(null)
          }}
        >
          <p className="text-center max-w-[40ch] mx-auto">
            You're about to delete&nbsp;
            <span className="text-orange font-medium">
              "{confirmDelete?.name}"
            </span>
            &nbsp;sub category.<br/>
            This action is irreversible.
          </p>
        </ConfirmDeleteModal>
      </div>
    </Layout>
  )
}

export default Categories;
