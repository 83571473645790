import React from "react";
import errorIcon from "../../assets/error.svg";

//mui
import { Alert } from "@material-ui/lab";

const ErrorAlert = ({ error }) => {
  return (
    <Alert
      severity="error"
      className="py-0 bg-red/10"
      classes={{
        message: "text-red text-[12px]",
      }}
      icon={<img src={errorIcon} alt="" />}
    >
      {error}
    </Alert>
  );
};

export default ErrorAlert;
