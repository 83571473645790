import React, { useState } from "react";
import { getComparator, stableSort } from "../../utils/tableFunctions";
import useTableMethods from "../../customHooks/useTableMethods";

//icons
import view from "../../assets/view.svg";
import completed from "../../assets/completed.svg";

//mui
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
  Avatar,
} from "@material-ui/core";

//components
import TableHead from "./OrderHistoryTableHead";
import OrderHistoryModal from "./OrderHistoryModal";
// import ReceiptModal from "../ReceiptModal/ReceiptModal";

const rows = [
  {
    invoiceId: "DND100928",
    customer: "Gloria Akuffo Ansah",
    src: "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=736&q=80",
    date: "June 28, 2021 12:17",
    status: "completed",
    amount: 500,
  },
  {
    invoiceId: "DND100928",
    customer: "Gloria Akuffo Ansah",
    src: "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=736&q=80",
    date: "June 28, 2021 12:17",
    status: "completed",
    amount: 500,
  },
  {
    invoiceId: "DND100928",
    customer: "Gloria Akuffo Ansah",
    src: "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=736&q=80",
    date: "June 28, 2021 12:17",
    status: "completed",
    amount: 500,
  },
  {
    invoiceId: "DND100928",
    customer: "Gloria Akuffo Ansah",
    src: "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=736&q=80",
    date: "June 28, 2021 12:17",
    status: "completed",
    amount: 500,
  },
  {
    invoiceId: "DND100928",
    customer: "Gloria Akuffo Ansah",
    src: "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=736&q=80",
    date: "June 28, 2021 12:17",
    status: "completed",
    amount: 500,
  },
];

const OrderHistoryTable = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    emptyRows,
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
  } = useTableMethods(rows);

  return (
    <div className="w-full mt-5">
      <Table className="min-w-[750px]" size="small">
        <TableHead
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={rows.length}
        />

        <TableBody className="bg-white">
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(index);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                      className=""
                    />
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.invoiceId}
                  </TableCell>
                  <TableCell className="flex items-center space-x-2">
                    <Avatar className="w-8 h-8" src={row.src} />
                    <p className="text-sm font-medium text-gray2">
                      {row.customer}
                    </p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.date}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    <span
                      className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 
                          ${
                            row.status.toLowerCase() === "completed" &&
                            "bg-green/20 text-green"
                          }`}
                    >
                      {row.status.toLowerCase() === "completed" && (
                        <img src={completed} alt="" />
                      )}

                      <p>{row.status}</p>
                    </span>
                  </TableCell>

                  <TableCell className="font-medium text-black" align="right">
                    ₦ {row.amount}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button onClick={handleOpen}>
                        <img src={view} alt="" />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* <ReceiptModal isOpen={open} closeModal={handleClose} /> */}

      <OrderHistoryModal onClose={handleClose} open={open} />
    </div>
  );
};

export default OrderHistoryTable;
