import { IoArrowUp } from "react-icons/io5"
import { Button, ButtonGroup } from '@material-ui/core'
import deleteIcon from '../../assets/delete.svg'
import editIcon from '../../assets/edit.svg'

const ImageInput = ({title="", value, hook, state, hookState, id}) => {
  return (
    <div>
      <h1 className="mb-3 font-medium text-[#3D4356]">{title}</h1>

      <div className="flex items-center space-x-5">
        {value ? (
          <div className=" w-60">
            <div className="relative border border-gray-300 border-dashed rounded-md">
              <div className="absolute left-0 flex justify-center w-full bottom-10">
                <ButtonGroup className="bg-white">
                  <Button
                    onClick={() => {
                      hook('')
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                  </Button>
                  <Button>
                    <label
                      htmlFor={id}
                      className="w-full h-full cursor-pointer"
                    >
                      <img src={editIcon} alt="" />
                      <input
                        type="file"
                        id={id}
                        multiple
                        accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          hookState(false)
                          hook(e.target.files[0])
                        }}
                        className="hidden"
                      />
                    </label>
                  </Button>
                </ButtonGroup>
              </div>

              <img
                src={state ? value : URL.createObjectURL(value)}
                alt=""
                className="object-cover w-full h-full rounded-md"
              />
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault()
              hookState(false)
              hook(e.dataTransfer.files[0])
            }}
          >
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
              <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
            </div>
            <label
              htmlFor={id}
              className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
            >
              <p>Upload File</p>
              <input
                type="file"
                id={id}
                multiple
                accept=".png,.jpeg,.jpg"
                onChange={(e) => {
                  hookState(false)
                  hook(e.target.files[0])
                }}
                className="hidden"
              />
            </label>

            <p className="text-sm text-gray2">or drop files to upload</p>
          </div>
        )}
      </div>
    </div>
  )
}
export default ImageInput
