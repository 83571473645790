import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

//mui
import { Avatar, Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import RevenueGrowth from '../../components/RevenueGrowth/RevenueGrowth'
import DateActions from '../../components/DateActions/DateActions'
import OrderAnalytics from './OrderAnalytics/OrderAnalytics'
import Loader from '@components/Loader/Loader'

//icons
import ProductsIcon from '../../assets/products-inactive.svg'
import RetailersIcon from '../../assets/retailors-inactive.svg'
import BrandsIcon from '../../assets/brands.svg'
import loader from '../../assets/loader.svg'

//services
import {
  getTodaysOrderSummary,
  getOrderSummary,
  getRetailerOrderSummary,
} from '../../services/orders'
import {
  getFinanceOverview,
  getRetailerFinanceOverview,
} from '../../services/finance'
import { getTopSellingProducts, getProducts } from '../../services/products'
import { getTopRetailers } from '../../services/retailers'
import { getTopSellingBrands } from '../../services/brands'
import { getAllCustomers, getRetailerCustomers } from '../../services/users'
import { fetchAllOrdersByDate, fetchAllOrdersByDateRange } from '../../utils'

//store
import { RootContext } from '../..'
import { getStock } from '../../services/stock'

const Index = observer(() => {
  const [busy, setBusy] = useState(true)
  const [activePeriod, setActivePeriod] = useState('today')
  const [totalOrders, setTotalOrders] = useState(0)
  const [totalOrdersCount, setTotalOrdersCount] = useState(0)
  const [ordersToday, setOrdersToday] = useState(0)
  const [todaySummary, setTodaySummary] = useState({
    pending: 0,
    cancelled: 0,
    completed: 0,
    processing: 0,
    readyForPickup: 0,
    onDelivery: 0,
  })
  const [revenue, setRevenue] = useState(0)
  const [topProducts, setTopProducts] = useState([])
  const [topBrands, setTopBrands] = useState([])
  const [topRetailers, setTopRetailers] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [totalCustomers, setTotalCustomers] = useState(0)
  const [analyticsData, setAnalyticsData] = useState([])
  const [analyticsBusy, setAnalyticsBusy] = useState(false)
  const [orderDateRange, setOrderDateRange] = useState({
    start: moment(),
    end: moment(),
  })
  const currency = '₦'

  const root = useContext(RootContext)

  const history = useHistory()

  const fetchProducts = async () => {
    try {
      const { data } = await getProducts({ page: 1, page_size: 1 })
      setTotalProducts(data.count)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch product data.')
    }
  }

  const fetchRetailerStock = async () => {
    try {
      const { data } = await getStock(root.authStore.user.retailerUuid)
      setTotalProducts(data.count)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch product data.')
    }
  }

  const fetchCustomers = async () => {
    try {
      if (root.authStore.user?.account_type?.includes?.('Admin')) {
        const { data } = await getAllCustomers({ page: 1, page_size: 1 })
        setTotalCustomers(data.count)
      } else {
        const { data } = await getRetailerCustomers({
          retailer_uuid: root.authStore.user.retailerUuid,
          page: 1,
          page_size: 1,
        })
        setTotalCustomers(data.count)
      }
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch customer data.')
    }
  }

  const fetchFinanceOverview = async () => {
    try {
      if (root.authStore.user.account_type.includes('Admin')) {
        const { data } = await getFinanceOverview()
        setRevenue(data.overview.amount.amount)
        setBusy(false)
      } else {
        const { data } = await getRetailerFinanceOverview(
          root.authStore.user.retailerUuid,
        )
        setRevenue(data.overview.amount.amount)
        setBusy(false)
      }
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch revenue data.')
    }
  }

  const fetchTodaysOrders = async () => {
    try {
      const { data } = await getTodaysOrderSummary(
        root.authStore.user.retailerUuid,
      )
      setOrdersToday(data.total_orders.count)
      setTodaySummary({
        pending: data.pending.count,
        cancelled: data.cancelled.count,
        completed: data.completed.count,
        processing: data.processing.count,
        readyForPickup: data.ready_for_pickup.count,
        onDelivery: data.on_delivery.count,
      })
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch order data.')
    }
  }

  const fetchOrderSummary = async () => {
    try {
      if (root.authStore.user.account_type.includes('Admin')) {
        const { data } = await getOrderSummary()
        setTotalOrders(data.total_orders.amount.amount)
        setTotalOrdersCount(data.total_orders.count)
      } else {
        const { data } = await getRetailerOrderSummary(
          root.authStore.user.retailerUuid,
        )
        setTotalOrders(data.total_orders.amount.amount)
        setTotalOrdersCount(data.total_orders.count)
      }
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch order summary')
    }
  }

  const fetchTopSellingProducts = async () => {
    try {
      const { data } = await getTopSellingProducts({
        retailer: root.authStore.user.retailerUuid,
      })
      setTopProducts(data.results)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch product data.')
    }
  }

  const fetchTopBrands = async () => {
    try {
      const { data } = await getTopSellingBrands({
        retailer: root.authStore.user.retailerUuid,
      })
      setTopBrands(data.results)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch brands data.')
    }
  }

  const fetchTopRetailers = async () => {
    try {
      const { data } = await getTopRetailers()
      setTopRetailers(data.results)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch retailer data.')
    }
  }

  const fetchAnalyticsByDate = async () => {
    setAnalyticsBusy(true)
    try {
      const data = await fetchAllOrdersByDate(
        activePeriod,
        root.authStore.user.retailerUuid,
      )
      setAnalyticsData(data)
      setAnalyticsBusy(false)
    } catch (error) {
      setAnalyticsBusy(false)
      toast.error('An error occurred. Unable to fetch order data.')
    }
  }

  const handleDateRangeSelect = async (range) => {
    setOrderDateRange(range)
    setAnalyticsBusy(true)
    try {
      const data = await fetchAllOrdersByDateRange(
        range.end.format('YYYY-MM-DD'),
        range.start.format('YYYY-MM-DD'),
        root.authStore.user.retailerUuid,
      )
      setAnalyticsData(data)
      setAnalyticsBusy(false)
    } catch (error) {
      setAnalyticsBusy(false)
      toast.error('An error occurred. Unable to fetch order data.')
    }
  }

  useEffect(() => {
    if (root.authStore.user.account_type.includes('Admin')) {
      fetchTopRetailers()
      fetchProducts()
    } else {
      fetchRetailerStock()
      fetchTodaysOrders()
    }

    fetchTopSellingProducts()
    fetchTopBrands()
    fetchFinanceOverview()
    fetchCustomers()
    fetchOrderSummary()
  }, [])

  useEffect(() => {
    if (root.authStore.user.account_type.includes('Retailer'))
      fetchAnalyticsByDate()
  }, [activePeriod])

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <div
          className={`${
            !root.authStore.user.account_type.includes('Retailer')
              ? 'page-padding'
              : 'page-padding grid grid-cols-[1fr,4fr] gap-5'
          }`}
        >
          {root.authStore.user.account_type.includes('Retailer') && (
            <div className="space-y-5 ">
              <div className="flex items-center space-x-2">
                <Avatar
                  className="w-10 h-10"
                  src={root.authStore.user.logo}
                ></Avatar>
                <div className="flex-grow">
                  <h1 className="w-48 text-sm font-bold truncate text-gray1">
                    {root.authStore.user.name}
                  </h1>
                  <p className="text-sm text-gray2">
                    {root.authStore.user.email}
                  </p>
                </div>
              </div>

              <h1 className="text-[26px] font-semibold text-gray1">Today</h1>

              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  today's orders
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">{ordersToday}</p>

                  {/* <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">0%</p>
                  </div> */}
                </div>

                <div className="flex flex-wrap items-center py-2 ">
                  <SummaryPill
                    count={todaySummary.completed}
                    title="Completed"
                    color="green"
                  />
                  <SummaryPill
                    count={todaySummary.pending}
                    title="Pending"
                    color="blue"
                  />
                  <SummaryPill
                    count={todaySummary.onDelivery}
                    title="Completed"
                    color="teal"
                  />
                  <SummaryPill
                    count={todaySummary.readyForPickup}
                    title="Ready For Pickup"
                    color="orange"
                  />
                  <SummaryPill
                    count={todaySummary.processing}
                    title="Processing"
                    color="yellow"
                  />
                  <SummaryPill
                    count={todaySummary.cancelled}
                    title="Cancelled"
                    color="red"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="space-y-5">
            <div className="grid grid-cols-2 gap-5">
              <div className="grid grid-cols-[2fr,1fr] gap-2">
                <div className="px-5 py-2 bg-white rounded-md">
                  <h1 className="text-[12px] text-gray3 capitalize">
                    total orders
                  </h1>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-1">
                      <p className="text-[20px] font-semibold">
                        {totalOrdersCount.toLocaleString()}
                      </p>

                      {/* <div className="flex items-center text-green">
                        <IoArrowUpOutline className="w-3 h-3" />

                        <p className="text-sm font-medium">0%</p>
                      </div> */}
                    </div>

                    <p className="text-sm font-medium text-gray1">
                      {currency}
                      {Number(
                        totalOrdersCount === 0 ? 0 : totalOrders,
                      ).toLocaleString('en')}
                    </p>
                  </div>
                </div>

                <div className="px-5 py-2 bg-white rounded-md">
                  <h1 className="text-[12px] text-gray3 capitalize">
                    Total Products
                  </h1>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-1">
                      <p className="text-[20px] font-semibold">
                        {totalProducts.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-[1fr,1fr] gap-2">
                <div className="flex flex-col px-5 py-2 bg-white rounded-md">
                  <h1 className="text-[12px] text-gray3 capitalize">
                    Total Customers
                  </h1>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-1">
                      <p className="text-[20px] font-semibold">
                        {totalCustomers.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col px-5 py-2 bg-white rounded-md">
                  <h1 className="text-[12px] text-gray3 capitalize">
                    Completed Orders (Sales)
                  </h1>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-1">
                      <p className="text-[20px] font-semibold">
                        {currency}
                        {Number(revenue).toLocaleString('en')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <RevenueGrowth title="Sales" />

            {!root.authStore.user.account_type.includes('Admin') && (
              <div className="grid grid-cols-2 gap-5">
                <Card>
                  <div className="h-[15%] flex justify-between items-center">
                    <Title title="Top Selling products" className="" />
                  </div>

                  <div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-hidden px-4">
                    {topProducts.length > 0 ? (
                      <>
                        {topProducts.map((product) => {
                          return (
                            <InfoCard
                              name={product.name}
                              subTitle={`${product.total_sold} Sold`}
                              src={product.image}
                              amount={product.revenue}
                              defaultImage={ProductsIcon}
                              key={product.uuid}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <img src={ProductsIcon} alt="" className="w-16 h-16" />
                      </div>
                    )}
                  </div>
                </Card>

                <Card>
                  <div className="h-[15%] flex justify-between items-center">
                    <Title title="Top Selling Brands" className="" />
                  </div>

                  <div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-hidden px-4">
                    {topBrands.length > 0 ? (
                      <>
                        {topBrands.map((brand) => {
                          return (
                            <InfoCard
                              name={brand.name}
                              subTitle={`${brand.total_sold} Sold`}
                              src={brand.logo}
                              amount={+brand.revenue}
                              defaultImage={brand.logo || BrandsIcon}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <img src={BrandsIcon} alt="" className="w-16 h-16" />
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            )}

            {!root.authStore.user.account_type.includes('Admin') && (
              <div className="relative p-5 space-y-3 bg-white rounded-md">
                <div className="flex items-center justify-between pb-5 border-b">
                  <Title title="Order Analytics" className="" />

                  <DateActions
                    activePeriod={activePeriod}
                    changePeriod={setActivePeriod}
                    dateRange={orderDateRange}
                    onDateRangeSelect={handleDateRangeSelect}
                  />
                </div>

                <OrderAnalytics data={analyticsData} busy={analyticsBusy} />
                {analyticsBusy && (
                  <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-5">
                    <img src={loader} alt="" className="w-6 animate-spin" />
                  </div>
                )}
              </div>
            )}

            <div className="grid grid-cols-2 gap-5">
              {root.authStore.user.account_type.includes('Admin') && (
                <Card>
                  <div className="h-[15%] flex justify-between items-center">
                    <Title title="Top Selling products" className="" />
                  </div>

                  <div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-hidden px-4">
                    {topProducts.length > 0 ? (
                      <>
                        {topProducts.map((product) => {
                          return (
                            <InfoCard
                              name={product.name}
                              subTitle={`${product.total_sold} Sold`}
                              src={product.image}
                              amount={product.revenue}
                              defaultImage={ProductsIcon}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <img src={ProductsIcon} alt="" className="w-16 h-16" />
                      </div>
                    )}
                  </div>
                </Card>
              )}

              {root.authStore.user.account_type.includes('Admin') && (
                <Card>
                  <div className="h-[15%] flex justify-between items-center">
                    <Title title="Top Retailers" className="" />
                  </div>

                  <div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-scroll px-4">
                    {topRetailers.length > 0 ? (
                      <>
                        {topRetailers.map((retailer) => {
                          return (
                            <InfoCard
                              name={retailer.name}
                              subTitle={retailer.email}
                              src={retailer.logo}
                              amount={retailer.revenue}
                              defaultImage={RetailersIcon}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <img src={RetailersIcon} alt="" className="w-16 h-16" />
                      </div>
                    )}
                  </div>
                </Card>
              )}

              {root.authStore.user.account_type.includes('Admin') && (
                <Card>
                  <div className="h-[15%] flex justify-between items-center">
                    <Title title="Top Selling Brands" className="" />
                  </div>

                  <div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-hidden px-4">
                    {topBrands.length > 0 ? (
                      <>
                        {topBrands.map((brand) => {
                          return (
                            <InfoCard
                              name={brand.name}
                              subTitle={brand.total_sold}
                              amount={brand.revenue}
                              src={brand.picture}
                              defaultImage={brand.logo || BrandsIcon}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <img src={BrandsIcon} alt="" className="w-16 h-16" />
                      </div>
                    )}
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
})

export default Index

const Title = ({ title, className }) => {
  return (
    <h1 className={`font-medium capitalize text-gray1 ${className}`}>
      {title}
    </h1>
  )
}

const InfoCard = ({ name, subTitle, amount, src, defaultImage }) => {
  return (
    <div className="flex justify-between py-3">
      <div className="flex items-center space-x-2">
        <Avatar className={`w-8 h-8 ${!src ? 'bg-white' : ''}`} src={src}>
          <img src={defaultImage} alt="" className="w-full" />
        </Avatar>
        <div>
          <h1 className="text-sm font-medium text-gray2">{name}</h1>
          <p className="text-[12px] text-gray3">{subTitle}</p>
        </div>
      </div>
      <div>
        <h1 className="text-sm font-semibold text-[#151515]">
          ₦ {Number(amount).toLocaleString('en')}
        </h1>
      </div>
    </div>
  )
}

const Card = ({ children }) => {
  return (
    <div className="p-5 bg-white divide-y rounded-md h-80 divide-[#EEF2F5]">
      {children}
    </div>
  )
}

const SummaryPill = ({ title, count, color }) => {
  const colorClassMap = {
    green: ['bg-green/10 text-green', 'bg-green'],
    red: ['bg-red/10 text-red', 'bg-red'],
    blue: ['bg-blue/10 text-blue', 'bg-blue'],
    teal: ['bg-secondary/10 text-secondary', 'bg-secondary'],
    orange: ['bg-accent-orange/10 text-accent-orange', 'bg-accent-orange'],
    yellow: ['bg-accent-yellow/10 text-accent-yellow', 'bg-accent-yellow'],
  }
  return (
    <div
      className={`flex items-center mb-2 mr-2 rounded-xl ${colorClassMap[color][0]}`}
    >
      <span
        className={`inline-block  text-white rounded-full ${colorClassMap[color][1]} p-[4px] text-[12px]`}
      >
        {count}
      </span>
      <p className="px-2 text-sm capitalize">{title}</p>
    </div>
  )
}
