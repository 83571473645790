import api from '../apiInstance'

export function getWebSliders(pg = 1) {
  return api.get(`/api/web-slider/?page=${pg}`)
}

export function createWebSlider(data) {
  return api.post('/api/web-slider/', data)
}

export function updateWebSlider(id, data) {
  return api.patch(`/api/web-slider/${id}/`, data)
}

export function deleteWebSlider(id) {
  return api.delete(`/api/web-slider/${id}/`)
}

export async function getFaqCategories(params) {
  const res = await api.get('/api/faq-category/', params)
  return res.data
}

export function createFaqCategory(payload) {
  return api.post('/api/faq-category/', payload)
}

export function getFaqs(params) {
  return api.get('/api/faq', {
    params,
  })
}

export async function getFaq(uuid) {
  const res = await api.get(`/api/faq/${uuid}/`)
  return res.data
}

export function createFaq(payload) {
  return api.post('/api/faq/', payload)
}

export function updateFaq(uuid, payload) {
  return api.patch(`/api/faq/${uuid}`, payload)
}

export function deleteFaq(uuid) {
  return api.delete(`/api/faq/${uuid}/`)
}

export function getPolicies(params) {
  return api.get('/api/policy/', params)
}

export function createPolicy(payload) {
  return api.post('/api/policy/', payload)
}

export function updatePolicy(uuid, payload) {
  return api.patch(`/api/policy/${uuid}/`, payload)
}

export async function getPolicy(title = 'privacy', params) {
  const { data } = await getPolicies({ page_size: 50 })
  return data.results.find((item) => item.title === title)
}

export const webslider = {
  getWebSliders,
  createWebSlider,
  updateWebSlider,
  deleteWebSlider,
}
