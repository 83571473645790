import React from "react";
import { withStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";

const GreenCustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#b7ebd3'
  },
  colorSecondary: {
    backgroundColor: "#38C985"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#38C985'
  }
}))(LinearProgress);

const BlueCustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: "#cddcfe"
  },
  colorSecondary: {
    backgroundColor: "#477DFB"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#477DFB"
  }
}))(LinearProgress);

const AvailableCouponsCard = ({label, fixedTotal, fixedUsed, 
  percentageTotal, percentageUsed}) => {
  return (
    <div className="flex flex-col w-full h-[110px] px-[20px] pt-[16px] rounded-lg bg-white">
      <p className="text-[16px] text-[#4C536A] font-medium m-0">{label}</p>
      <div className="grid items-center grid-cols-2 gap-4">
        <div className="flex flex-col w-full">
          <div className="flex flex-row items-center justify-between">
            <p className="text-[#9CA7B8] text-[14px] m-0 font-medium">Fixed</p>
            <p className="text-[#040A1D] text-[26px] m-0">
              {fixedUsed}/{fixedTotal}
            </p>
          </div>
          <GreenCustomLinearProgress 
            variant="determinate"
            value={fixedTotal ? (fixedUsed/fixedTotal) * 100 : 0}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row items-center justify-between">
            <p className="text-[#9CA7B8] text-[14px] m-0 font-medium">Percentage</p>
            <p className="text-[#040A1D] text-[26px] m-0">
              {percentageUsed}/{percentageTotal}
            </p>
          </div>
          <BlueCustomLinearProgress 
            variant="determinate"
            value={percentageTotal ? (percentageUsed/percentageTotal) * 100 : 0}
          />
        </div>
      </div>
    </div>
  )
}

export default AvailableCouponsCard;
