import React, { useState } from 'react'
import { withRouter, Link, useHistory } from 'react-router-dom'
import { Menu } from '@material-ui/core'

const MenuItem = ({ link, location, hasDropdown }) => {
  const { pathname } = location
  const { path, activeIcon, inactiveIcon, title } = link
  const [anchorEl, setAnchorEl] = useState(null)
  const [productAnchorEl, setProductAnchorEl] = useState(null)
  const [blogAnchorEl, setBlogAnchorEl] = useState(null)
  const [designAnchorEl, setDesignAnchorEl] = useState(null)

  const openMenu = (e) => {
    if (path.includes('finance')) setAnchorEl(e.currentTarget)
    else if (path.includes('product')) setProductAnchorEl(e.currentTarget)
    else if (path.includes('blog')) setBlogAnchorEl(e.currentTarget)
    else if (path.includes('design')) setDesignAnchorEl(e.currentTarget)
  }

  return (
    <li className={`relative flex flex-col items-start justify-center px-3 `}>
      {/* <div
        className={`absolute top-0 left-0 h-full w-[3px] rounded ${
          pathname.includes(path) ? "bg-orange" : "bg-transparent"
        }`}
      /> */}

      {hasDropdown ? (
        <button
          aria-controls="finance-menu"
          aria-haspopup="true"
          onClick={openMenu}
          className={`w-full flex items-center justify-start gap-2 flex-shrink-0  px-3 py-2 ${
            pathname.includes(path) ? 'bg-orange rounded-lg' : ''
          }`}
        >
          <div className="flex justify-center h-[26px]">
            <img
              src={`${pathname.includes(path) ? activeIcon : inactiveIcon}`}
              alt=""
              className="w-6"
            />
          </div>

          <h1
            className={`text-[13px] font-medium capitalize mb-0 whitespace-nowrap ${
              pathname.includes(path) ? 'text-white' : 'text-gray3'
            }`}
          >
            {title}
          </h1>
        </button>
      ) : (
        <Link
          to={path}
          className={`w-full flex items-center justify-start gap-2 flex-shrink-0 px-3 py-2 ${
            pathname.includes(path) ? 'bg-orange rounded-lg' : ''
          }`}
        >
          <div className="flex justify-center h-[26px]">
            <img
              src={`${pathname.includes(path) ? activeIcon : inactiveIcon}`}
              alt=""
              className="w-6"
            />
          </div>

          <h1
            className={`text-[13px] font-medium capitalize mb-0 whitespace-nowrap ${
              pathname.includes(path) ? 'text-white' : 'text-gray3'
            }`}
          >
            {title}
          </h1>
        </Link>
      )}
      <AnchorMenu
        id="design-menu"
        el={designAnchorEl}
        open={Boolean(designAnchorEl)}
        onClose={() => setDesignAnchorEl(null)}
      >
        <AnchorItem path="/design/slideshow">Slide Show</AnchorItem>
        <AnchorItem path="/design/terms">Terms & Conditions</AnchorItem>
        <AnchorItem path="/design/privacy">Privacy Policy</AnchorItem>
        <AnchorItem path="/design/about">About Drinks NG</AnchorItem>
        <AnchorItem path="/design/contact">Contact</AnchorItem>
        <AnchorItem path="/design/FAQ">FAQ</AnchorItem>
      </AnchorMenu>
      <AnchorMenu
        id="blog-menu"
        el={blogAnchorEl}
        open={Boolean(blogAnchorEl)}
        onClose={() => setBlogAnchorEl(null)}
      >
        <AnchorItem path="/blog">Blog Posts</AnchorItem>
        <AnchorItem path="/blog/categories">Categories</AnchorItem>
      </AnchorMenu>
      <AnchorMenu
        id="product-menu"
        el={productAnchorEl}
        open={Boolean(productAnchorEl)}
        onClose={() => setProductAnchorEl(null)}
      >
        <AnchorItem path="/products">Products</AnchorItem>
        <AnchorItem path="/products/attributes">Attributes</AnchorItem>
        <AnchorItem path="/products/categories">Categories</AnchorItem>
      </AnchorMenu>
      <AnchorMenu
        id="finance-menu"
        el={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <AnchorItem path="/finance">Overview</AnchorItem>
        <AnchorItem path="/finance/history">Transaction History</AnchorItem>
      </AnchorMenu>
    </li>
  )
}

function AnchorMenu({ id, el, open, onClose, children, ...props }) {
  return (
    <Menu
      anchorEl={el}
      open={open}
      onClose={onClose}
      elevation={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: '216px',
          borderRadius: '6px',
          padding: '0px 12px',
          marginLeft: '90px',
        },
      }}
      disableEnforceFocus
      disableRestoreFocus
      disableAutoFocus
      disableAutoFocusItem
      {...props}
    >
      {children}
    </Menu>
  )
}

function AnchorItem({ children, path = '' }) {
  const history = useHistory()
  const navigate = (path) => {
    history.push(path)
  }

  return (
    <div
      onClick={() => navigate(path)}
      className="cursor-pointer pl-2 flex items-center text-[#040A1D] text-[16px] font-medium w-full rounded-md bg-white hover:bg-[#F3641F] hover:bg-opacity-10 hover:text-[#F3641F]"
    >
      <p className="mb-0 py-2">{children}</p>
    </div>
  )
}

export default withRouter(MenuItem)
