import api from '../apiInstance'

export const getProducts = (params, signal) =>
  api.get('/api/product/', {
    params: {
      all: 'yes',
      ...params,
    },
    signal,
  })

export const getProduct = (id) => api.get(`/api/product/${id}/`)

export const searchProducts = (params) =>
  api.get(`/api/product/`, {
    params,
  })

// search product but not paginated, to be used for selects dropdowns
export const searchProducts_ = (query) =>
  api.get(`/api/product/?search=${query}`)

export const getProductCategories = () =>
  api.get('/api/product-category/?page_size=100')

// export const getProductSubCategories = (id) =>
//   api.get(`/api/product-category/${id}/product-category-children/`)

export const createProduct = (form) => api.post(`/api/product/`, form)

export const updateProduct = (form, id) =>
  api.patch(`/api/product/${id}/`, form)

export const deleteProduct = (uuid) => api.delete(`/api/product/${uuid}/`)

export const disableProduct = (uuid) =>
  api.patch(`/api/product/${uuid}/deactivate/`)

export const activateProduct = (uuid) =>
  api.patch(`/api/product/${uuid}/activate/`)

export const getAttributes = (pageNumber, pageSize) =>
  api.get(`/api/attribute/?page=${pageNumber}&page_size=${pageSize}`)

export const createProductCategory = (form) =>
  api.post('/api/product-category/', form)

export const createProductAttribute = (form) =>
  api.post('/api/attribute/', form)

export const getProductsByCategory = (categories, pageSize, pageNumber) =>
  api.get('/api/product/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
      category__uuid: categories,
    },
  })

export const getTopSellingProducts = (params, signal) =>
  api.get(`/api/order/top-selling-products/`, {
    params,
    signal,
  })

export const getOrderSummary = (productId) =>
  api.get(`api/product/${productId}/product-orders-summary/`)

export const getAvailableStock = (productId) =>
  api.get('api/stock/', {
    params: {
      product: productId,
      in_stock: true,
    },
  })

export const searchAttributes = (query, pageNumber, pageSize) =>
  api.get(`api/attribute/`, {
    params: {
      search: query,
      page: pageNumber,
      page_size: pageSize,
    },
  })

export const getSubCategories = async (id) => {
  const res = await api.get(
    `api/product-category/${id}/product-category-children/`,
  )
  return res.data
}

export const searchCategories = (query, pageNumber, pageSize) =>
  api.get(`api/product-category/`, {
    params: {
      search: query,
      page: pageNumber,
      page_size: pageSize,
    },
  })

export const updateProductAttribute = (form, id) =>
  api.patch(`/api/prod-attribute/${id}/`, form)

export const deleteProductAttribute = (id) =>
  api.delete(`/api/prod-attribute/${id}/`)

export const addProductAttribute = (form) =>
  api.post(`/api/prod-attribute/`, form)

export const updateProductCategory = (form, id) =>
  api.patch(`/api/product-category/${id}/`, form)

export const deleteProductCategory = (id) =>
  api.delete(`/api/product-category/${id}/`)

export const addProductMedia = (form) => api.post(`/api/product-media/`, form)

export const removeProductAttribute = (id) =>
  api.delete(`/api/prod-attribute/${id}/`)

export const makeProductRequest = (form) =>
  api.post(`/api/product/request-product/`, form)

export const getRequestedProducts = (params, signal) =>
  api.get('/api/product/get-request-product/', {
    params,
    signal,
  })

export const getDeclinedProducts = (params, signal) =>
  api.get('/api/product/get-request-declined-product/', {
    params,
    signal,
  })

// export const getRequestedProductsByCategory = (
//   pageNumber,
//   pageSize,
//   categories,
// ) =>
//   api.get(
//     `/api/product/get-request-product/?page=${pageNumber}&page_size=${pageSize}&category__uuid=${categories}`,
//   )

// export const searchRequestedProducts = (pageNumber, pageSize, query) =>
//   api.get(
//     `/api/product/get-request-product/?page=${pageNumber}&page_size=${pageSize}&search=${query}`,
//   )

export const addRequestedProduct = (id) =>
  api.post(`/api/product/${id}/request-approval/`)

export const declineRequestedProduct = (id, reason) =>
  api.post(`/api/product/${id}/request-decline/`, {
    reason,
  })

export const deleteProductMedia = (id) =>
  api.delete(`/api/product-media/${id}/`)
