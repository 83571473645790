import React from 'react';
import { Menu } from '@material-ui/core';

import ProductListItem from '../ProductListItem/ProductListItem';
import Loader from "@components/Loader/Loader";

const ProductListMenu = ({
  handleClose,
  anchorEl,
  style,
  onItemPress,
  products = [],
  fetching = false
}) => {
  return (
    <Menu
      id="products-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: style
      }}
      disableEnforceFocus
      disableRestoreFocus
      disableAutoFocus
      disableAutoFocusItem
    >
      {fetching ? (
        <Loader/>
      ) : !products.length ? (
        <div className="text-base text-center py-4">
          No products found in stock
        </div>
      ) : products.map((item, index) => (
        <ProductListItem 
          product={item}
          onClick={onItemPress}
          key={index}
        />
      ))}
      {/* <ProductListItem 
        product={{
          name: 'Martell Blue Swift – 75CL (x12Bottles)',
          inventory: 0,
          price: 14950
        }}
        onClick={onItemPress}
      /> */}
    </Menu>
  );
}

export default ProductListMenu;
