//components
import Layout from "../../components/Layout/Layout";
import AddNewCampaignForm from "../../components/AddNewCampaignForm/AddNewCampaignForm";

const AddNewCampaign = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center py-10 space-y-5 mb-[20px]">
        <AddNewCampaignForm />
      </div>
    </Layout>
  );
};

export default AddNewCampaign;
