import Cookies from 'js-cookie'
import exportFromJSON from 'export-from-json'
import api from '../apiInstance'

//services
import {
  getAttributes,
  getProductCategories,
  getProducts,
} from '../services/products'
import { getBrands } from '../services/brands'
import {
  getOrdersByDate,
  getOrdersByDateRange,
  getOrdersByDateRangeForAdmin,
  getOrdersByPeriodForAdmin,
} from '../services/orders'
import { getCategories, getPosts } from '../services/blog'

const initials = (firstName, lastName) => {
  const initials = `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`
  return initials
}

export const getTextContent = (html) => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.innerText
}

const getToken = () => {
  return Cookies.get('auth_token')
}

async function copyToClipboard(text) {
  await navigator.clipboard.writeText(text)
}

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

const formatTime = (dateString) => {
  const date = new Date(dateString)
  const options = { hour: '2-digit', minute: '2-digit' }
  return date.toLocaleTimeString('en-US', options)
}

const exportData = (tableData, title) => {
  const data = tableData
  const exportType = exportFromJSON.types.csv
  const date = new Date()
  const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const fileName = `${title}-${date.toLocaleDateString(
    'en-US',
    dateFormatOptions,
  )}`
  exportFromJSON({ data, fileName, exportType })
}

const preventNonNumericalInput = (e) => {
  e = e || window.event
  var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which
  var charStr = String.fromCharCode(charCode)

  if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
}

const fetchAllProductCategories = async () => {
  try {
    let { data } = await getProductCategories()
    let categories = [...data.results]
    if (data.next) {
      let execute = true
      do {
        const response = await api.get(data.next)
        categories.push(...response.data.results)
        data = response.data
        if (!data.next) execute = false
      } while (execute)
    }
    return categories
  } catch (error) {
    throw error
  }
}

const fetchAllBrands = async () => {
  try {
    let { data } = await getBrands({ page_size: 100, page: 1 })
    let brands = [...data.results]
    if (data.next) {
      let execute = true
      do {
        const response = await api.get(data.next)
        brands.push(...response.data.results)
        data = response.data
        if (!data.next) execute = false
      } while (execute)
    }
    return brands
  } catch (error) {
    throw error
  }
}

const fetchAllBlogCategories = async () => {
  try {
    let { data } = await getCategories(1, 100)
    let categories = [...data.results]
    if (data.next) {
      let execute = true
      do {
        const response = await api.get(data.next)
        categories.push(...response.data.results)
        data = response.data
        if (!data.next) execute = false
      } while (execute)
    }
    return categories
  } catch (error) {
    throw error
  }
}

const fetchAllBlogPosts = async () => {
  try {
    let { data } = await getPosts(1, 100)
    let categories = [...data.results]
    if (data.next) {
      let execute = true
      do {
        const response = await api.get(data.next)
        categories.push(...response.data.results)
        data = response.data
        if (!data.next) execute = false
      } while (execute)
    }
    return categories
  } catch (error) {
    throw error
  }
}

const fetchAllProducts = async () => {
  try {
    let { data } = await getProducts()
    let products = [...data.results]
    if (data.next) {
      let execute = true
      do {
        const response = await api.get(data.next)
        products.push(...response.data.results)
        data = response.data
        if (!data.next) execute = false
      } while (execute)
    }
    return products
  } catch (error) {
    throw error
  }
}

const fetchAllAttributes = async () => {
  try {
    let { data } = await getAttributes(1, 100)
    let attributes = [...data.results]
    if (data.next) {
      let execute = true
      do {
        const response = await api.get(data.next)
        attributes.push(...response.data.results)
        data = response.data
        if (!data.next) execute = false
      } while (execute)
    }
    return attributes
  } catch (error) {
    throw error
  }
}

const fetchAllOrdersByDate = async (date, retailerId) => {
  if (retailerId) {
    try {
      let { data } = await getOrdersByDate(date, retailerId, 1, 100)
      let orders = [...data.results]
      if (data.next) {
        let execute = true
        do {
          const response = await api.get(data.next)
          orders.push(...response.data.results)
          data = response.data
          if (data.next) execute = false
        } while (execute)
      }
      return orders
    } catch (error) {
      throw error
    }
  } else {
    try {
      let { data } = await getOrdersByPeriodForAdmin(1, 100, date)
      let orders = [...data.results]
      if (data.next) {
        let execute = true
        do {
          const response = await api.get(data.next)
          orders.push(...response.data.results)
          data = response.data
          if (data.next) execute = false
        } while (execute)
      }
      return orders
    } catch (error) {
      throw error
    }
  }
}

const fetchAllOrdersByDateRange = async (
  dateRangeBefore,
  dateRangeAfter,
  retailerId,
) => {
  if (retailerId) {
    try {
      let { data } = await getOrdersByDateRange(
        dateRangeBefore,
        dateRangeAfter,
        retailerId,
        1,
        100,
      )
      let orders = [...data.results]
      if (data.next) {
        let execute = true
        do {
          const response = await api.get(data.next)
          orders.push(...response.data.results)
          data = response.data
          if (data.next) execute = false
        } while (execute)
      }
      return orders
    } catch (error) {
      throw error
    }
  } else {
    try {
      let { data } = await getOrdersByDateRangeForAdmin(
        1,
        100,
        dateRangeBefore,
        dateRangeAfter,
      )
      let orders = [...data.results]
      if (data.next) {
        let execute = true
        do {
          const response = await api.get(data.next)
          orders.push(...response.data.results)
          data = response.data
          if (data.next) execute = false
        } while (execute)
      }
      return orders
    } catch (error) {
      throw error
    }
  }
}

const trimDigits = (float) => parseFloat(float?.toFixed?.(8))

export {
  initials,
  getToken,
  copyToClipboard,
  formatDate,
  formatTime,
  trimDigits,
  exportData,
  preventNonNumericalInput,
  fetchAllProductCategories,
  fetchAllBrands,
  fetchAllAttributes,
  fetchAllOrdersByDate,
  fetchAllOrdersByDateRange,
  fetchAllBlogCategories,
  fetchAllBlogPosts,
  fetchAllProducts,
}
