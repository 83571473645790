import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { toast } from "react-toastify";

//components
import AuthCard from "../../components/AuthCard/AuthCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

import {
  sendResetPasswordEmail,
  sendResetPasswordOtp,
} from "../../services/auth";

const VerifyIdentity = () => {
  const history = useHistory();
  const { email } = useParams();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [countdownKey, setCountdownKey] = useState(1);

  const resendCode = async () => {
    try {
      await sendResetPasswordEmail({ email: email });
      toast.success("Verification code has been sent");
      setCountdownKey((prevState) => prevState + 1);
    } catch (error) {
      setError("Couldn't resend code. Please try again later.");
    }
  };

  const submit = async () => {
    setLoading(true);
    setError("");
    try {
      const { data } = await sendResetPasswordOtp({ email: email, otp: otp });
      history.push(`/reset-password/${email}/new-password/${data.token}`);
    } catch (error) {
      setError("Incorrect code, please try again");
      setLoading(false);
    }
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return (
        <span
          className="text-[12px] text-blue font-bold cursor-pointer"
          onClick={resendCode}
        >
          Resend code
        </span>
      );
    } else {
      return (
        <span className="font-bold text-gray2">
          Resend code in 00:{seconds}
        </span>
      );
    }
  };

  return (
    <AuthCard>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-2xl font-bold text-center">Verify your identity</h1>

        <p className="leading-none text-center text-gray2 w-[60%] text-sm">
          Please enter verification code sent to{" "}
          <span className="font-bold">{email}</span>
        </p>
      </div>

      <div className="w-[65%]">
        <form className="space-y-2">
          {error && <ErrorAlert error={error} />}
          <OtpInput
            value={otp}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            inputStyle={`bg-[#F4F5F7] w-10 h-10 caret-blue border-0 border-transparent font-medium ${
              error
                ? "text-red border-b-2 border-red rounded-t-md rounded-b-none"
                : "text-gray1 rounded-md"
            }`}
            focusStyle="outline-none ring-0 border-b-2 border-b-blue rounded-t-md rounded-b-none text-blue transition duration-500 ease-in-out ring-transparent"
            separator={<span className="w-2" />}
          />
        </form>

        <CustomButton
          text="Continue"
          onClick={submit}
          disabled={otp === ""}
          loading={loading}
        />

        <div className="flex items-center justify-center mt-3">
          <span className="text-[12px] text-gray3">
            Didn't get code?{" "}
            <Countdown
              key={countdownKey}
              renderer={renderer}
              date={Date.now() + 59000}
              zeroPadTime={3}
            />
          </span>
        </div>

        {/* <div className="flex flex-col items-center mt-10 space-y-2">
          <Link to="/" className="underline text-[12px] text-gray2">
            Use another method to recieve the code
          </Link>
        </div> */}
      </div>
    </AuthCard>
  );
};

export default VerifyIdentity;
