import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { IoAddOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchBar from "../../components/SearchBar/SearchBar";
import TabButton from "../../components/TabButton/TabButton";
import Loader from "../../components/Loader/Loader";

//utils
import { exportData, fetchAllBlogPosts } from "../../utils";
import BlogPostTable from "../../components/BlogPostTable/BlogPostTable";
import { searchPosts, deletePost } from "../../services/blog";

const Index = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [stories, setStories] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [published, setPublished] = useState([]);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("drafts");

  let typingTimer;

  const fetchStories = async() => {
    setBusy(true);
    try {
      const data = await fetchAllBlogPosts();
      const drafts = data.filter(item => item.status === "Draft");
      const published = data.filter(item => item.status !== "Draft");
      setPublished(published);
      setDrafts(drafts);
      setStories(activeTab === 'drafts' ? drafts : published);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch stories.")
    }
  }

  const handleSearch = async () => {
    if(search.length === 0)
      return;
    setBusy(true)
    try {
      const { data } = await searchPosts(search);
      setStories(data.results);
      if(data.count === 0)
        setNotFound(true);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch requested data.");
    }
  }

  const handleSearchKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const handleSearchKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      handleSearch()
    }, 500);
  }

  const goToCreatePage = () => {
    history.push('/blog/add')
  }

  const goToEditPage = (post) => {
    history.push(`/blog/edit/${post.uuid}`)
  }

  const deleteBlog = async (post) => {
    try {
      toast.info('Deleting blog post...')
      await deletePost(post.uuid)
      fetchStories()
      toast.success('Blog post deleted successfully.')
    } catch {
      toast.error('Failed to delete blog post.')
    }
  }

  useEffect(() => {
    fetchStories();
  }, [])

  useEffect(() => {
    if(search.length === 0)
      fetchStories();
  }, [search])

  useEffect(() => {
    setStories(activeTab === "drafts" ? drafts : published);
  }, [activeTab])

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Your Stories" />
        <div className="flex justify-end">
          <Button
            variant="contained"
            color="primary"
            className="capitalize shadow-none"
            startIcon={<IoAddOutline />}
            onClick={goToCreatePage}
          > 
            Write New Story
          </Button>
        </div>
        <SearchBar
          placeholder="Search Blog"
          onSearchKeyDown={handleSearchKeyDown}
          onSearchKeyUp={handleSearchKeyUp}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        >
          <TabButton 
            activeTab={activeTab}
            onClick={() => setActiveTab("published")}
            title={`Published (${published.length})`}
            tab="Published"
          />
          <TabButton 
            activeTab={activeTab}
            onClick={() => setActiveTab("drafts")}
            title={`Drafts (${drafts.length})`}
            tab="Drafts"
          />
        </SearchBar>
        {busy && search.length === 0 ? (
          <Loader />
        ) : (
          <>
             <BlogPostTable 
              rows={stories}
              onEditClick={goToEditPage}
              onDeleteClick={deleteBlog}
              busy={busy}
              search={search}
              notFound={notFound}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default Index
