import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { IoAddOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import CreateCategoryModal from "../../components/BlogCategoryModal/CreateCategoryModal";
import EditCategoryModal from "../../components/BlogCategoryModal/EditCategoryModal";
import BlogCategoriesTable from "../../components/BlogCategoriesTable/BlogCategoriesTable";

//utils
import { fetchAllBlogCategories } from "../../utils";

//services
import { createCategory, updateCategory } from "../../services/blog";

const Categories = () => {
  const history = useHistory();

  const [busy, setBusy] = useState(false);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [parents, setParents] = useState([]);

  const fetchCategories = async() => {
    setBusy(true);
    try {
      const data = await fetchAllBlogCategories();
      const sortedList = await sortCategories(data);
      setCategories(sortedList);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("Unable to fetch blog categories.");
      // history.goBack()
    }
  }

  const sortCategories = async(categories) => {
    const parents = [];
    categories.forEach(element => {
      if(element.parent) {
        const parent = categories.find(item => item.id === element.parent);
        element.parent = parent;
      } else {
        parents.push(element);
      }
    });
    setParents(parents);
    return categories;
  }

  const openEditModal = (category) => {
    setActiveCategory(category);
    setShowEditModal(true);
  }

  const openCreateModal = () => {
    setShowAddModal(true);
  }

  const handleEditSubmit = async(id, form) => {
    setBusy(true);
    try {
      await updateCategory(id, form);
      setBusy(false);
      fetchCategories();
      toast.success("Product category has been updated successfully.")
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to update category.")
    }
  }

  const handleCreateSubmit = async(form) => {
    setBusy(true);
    try {
      await createCategory(form);
      setBusy(false);
      fetchCategories();
      toast.success("Product category has been added successfully.")
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to create category.")
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [])

  return(
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Blog Categories" />
        {busy && categories.length === 0 ? (
          <Loader />
        ) : (
          <>
            <div className="py-5 space-y-5">
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={openCreateModal}
                >
                  Add New Category
                </Button>
              </div>
              <BlogCategoriesTable 
                rows={categories}
                onEditClick={openEditModal}
                busy={busy}
                categories={categories}
              />
            </div>
          </>
        )}
        <CreateCategoryModal 
          open={showAddModal}
          closeModal={() => setShowAddModal(false)}
          onSubmit={handleCreateSubmit}
          busy={busy}
          parents={parents}
        />
        {activeCategory && (
          <EditCategoryModal 
            open={showEditModal}
            closeModal={() => {
              setActiveCategory(null);
              setShowEditModal(false);
            }}
            category={activeCategory}
            onSubmit={handleEditSubmit}
            busy={busy}
            parents={parents}
          />
        )}
      </div>  
    </Layout>
  )
}

export default Categories;
