import React, { useEffect, useState } from 'react'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'

//mui
import { IconButton } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import UnsavedChanges from '../../components/UnsavedChanges/UnsavedChanges'
import { useHistory, useParams } from 'react-router-dom'
import { getUser } from '../../services/users'
import EditUserForm from './EditUser/EditUser'
import Loader from '../../components/Loader/Loader'

const EditUser = () => {
  const { userId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()
  const [user, setUser] = useState(null)

  const fetchUser = async () => {
    setIsLoading(true)
    try {
      const { data } = await getUser(userId)
      setUser(data)
      setIsLoading(false)
    } catch (error) {
      history.goBack()
      toast.error('Unable to find requested user. Please try again later.')
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-center py-10 space-y-5 ">
          <div className="w-[70%] flex items-center space-x-3">
            <IconButton
              onClick={() => {
                setModalOpen(true)
              }}
              disableRipple
              className="p-0 hover:bg-transparent"
            >
              <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
            </IconButton>
            <h1 className="font-bold text-[30px] text-gray1 capitalize mb-0">
              Edit {user?.profile.first_name} {user?.profile.last_name}
            </h1>
          </div>

          <EditUserForm user={user} />

          <UnsavedChanges
            isOpen={modalOpen}
            close={() => setModalOpen(false)}
          />
        </div>
      )}
    </Layout>
  )
}

export default EditUser
