import React, { useContext } from "react";
import useTableMethods from "../../customHooks/useTableMethods";

// icons
import view from "../../assets/view.svg";
import edit from "../../assets/edit.svg";
import loader from "../../assets/loader.svg";

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
  Avatar,
  ButtonGroup,
  Button,
} from "@material-ui/core";

//components
import CustomTableHead from "./TableHead";

import { RootContext } from '../..'


const CustomersTable = ({
  rows,
  onEditClick,
  onViewClick,
  search = '',
  busy = false,
  notFound = false,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange
}) => {
  const { authStore } = useContext(RootContext)
  const isAdmin = authStore.user.account_type.includes("Admin")
  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected,
  } = useTableMethods(rows);
  
  return (
    <div className="w-full mt-5">
      {(busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead 
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
          {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">No Search Result for "{search}"</p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">Search tips: </span>
                  Some search terms require an exact match. Try typing the entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ): (
        <Table className="min-w-[750px]" size="small">
          <CustomTableHead 
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            exclude={!isAdmin ? ['actions'] : []}
          />
          <TableBody className="bg-white">
            {rows.map((row, index) => {
              const isItemSelected = isSelected(index);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="flex items-center space-x-2">
                    <Avatar className="w-8 h-8" src={row.profile.picture} />
                    <p className="text-sm font-medium text-gray2">
                      {row.profile.first_name} {row.profile.last_name}
                    </p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.email}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.phone_number}
                  </TableCell>
                  {/*<TableCell className="text-sm font-medium text-gray2" align="right">
                    ₦0
                  </TableCell>*/}
                  { isAdmin ? (
                    <TableCell align="right">
                      <ButtonGroup size="medium">
                        <Button onClick={() => onEditClick(row.uuid)}>
                          <img src={edit} alt="" />
                        </Button>
                        <Button onClick={() => onViewClick(row.uuid)}>
                          <img src={view} alt="" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  ) : null }
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      ))}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(e, pg) => {
            onPageChange(pg + 1);
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value);
          }}
        />
      )}
    </div>
  )
}

export default CustomersTable;
