import React, { useState, useEffect } from "react";
import { Modal, Popover } from "@material-ui/core";
import { toast } from "react-toastify";
import {Calendar} from "react-modern-calendar-datepicker";
// import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";


import closeIcon from "../../assets/close.svg";
import FormInput from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";
import location from "../../assets/location.svg";

import validateEmail from "../../utils/validateEmail";
import { trimDigits } from "../../utils";

// const { REACT_APP_GOOGLE_API_KEY } = process.env;
const libraries = ["places"];


const CreateCustomerModal = ({isOpen, close, onSubmit}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedDay, setSelectedDay] = useState(null);
  // const [date, setDate] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "calendar-popover" : undefined;

  // address information
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [coord, setCoord] = useState("");

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: REACT_APP_GOOGLE_API_KEY,
  //   libraries,
  // });

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  // const handlePopoverClose = () => {
  //   setAnchorEl(false);
  // }

  const formatMonth = (month) => {
    if(parseInt(month) < 10)
      return `0${month}`;
    return month;
  }

  // useEffect(() => {
  //   if(selectedDay)
  //     setDate(`${selectedDay.year}-${formatMonth(selectedDay.month)}-${selectedDay.day}`)
  // }, [selectedDay])

  const genderChangeHandler = value => {
    setGender(value);
  }

  const handleSave = () => {
    if(!validatePersonalInfo())
      return;
    
    if(!validateAddressInfo())
      return;
    
    onSubmit({
      phone_number: phone,
      email: email,
      password: '<?TIm(-x(YmmH&Ic',
      account_type: "Customer",
      related_retailers: [],
      profile: {
        first_name: firstName,
        last_name: lastName,
        // date_of_birth: date,
        gender: gender
      },
      address: {
        address, city,
        country,
        post_code: postalCode,
        lon: trimDigits(coord?.lng),
        lat: trimDigits(coord?.lat),
        is_active: true,
        is_dashboard: true,
        administrative_area_level_1: region
      }
    });
  }

  const validatePersonalInfo = () => {
    if(firstName.length === 0) {
      toast.info("Please enter first name");
      return false;
    }

    if(lastName.length === 0) {
      toast.info("Please enter your last name");
      return false;
    }

    if(email.length === 0) {
      toast.info("Please enter email");
      return false;
    } else if(!validateEmail(email)) {
      toast.error("Invalid email address");
      return false;
    }
    if(phone === '') {
      toast.info("Please enter phone number")
      return false;
    }

    /*
    if(password === '') {
      toast.info("Please enter a password");
      return false;
    }

    if(password !== confirmPassword) {
      toast.info("Passwords don't match");
      return false;
    }
    */
    return true;
  }

  const validateAddressInfo = () => {
    if(address.length === 0) {
      toast.info("Please enter billing address");
      return false
    }

    if(city.length === 0) {
      toast.info("Please enter city");
      return false;
    }

    if(country.length === 0) {
      toast.info("Please enter country");
      return false;
    }
    return true;
  }

  const handleAddressInput = (e) => {
    const { value } = e.target;
    setAddress(value);
    setValue(value);
  };

  const onSelect = async (suggestion) => {
    const results = await getGeocode({ placeId: suggestion.place_id });
    const coordinates = await getLatLng(results[0]);

    const addressComps = results[0].address_components;

    const country = addressComps.find((info) => info.types.includes("country"));
    const city = addressComps.find((info) => info.types.includes("locality"));
    const region = addressComps.find((info) => 
      info.types.includes("administrative_area_level_1"));
    const postalCode = addressComps.find((info) =>
      info.types.includes("postal_code"))

    setValue(suggestion.description, false);

    clearSuggestions();

    setCoord(coordinates);
    setAddress(suggestion.description);
    setCity(city?.long_name);
    setPostalCode(postalCode?.long_name);
    setRegion(region?.long_name);
    setCountry(country?.long_name);
  }
  
  return (
    <Modal
      open={isOpen}
      onClose={close}
      className="flex items-center justify-center overflow-y-scroll"
    >
      <div
        className="w-[751px] bg-white rounded flex flex-col py-[20px] relative"
      >
        <h1 className="text-center text-[20px] font-bold text-[#040A1D]">Create a New Customer</h1>
        <button className="absolute top-[25px] right-[50.48px]" onClick={close}>
          <img src={closeIcon} alt="close" className="w-[24px] h-[24px]"/>
        </button>
        <div className="bg-[#E3E7ED] h-[1px] w-full my-[20px]"></div>
        <div className="px-[30px]">
          <div className="relative flex flex-col w-full">
            <p className="text-[#3D4356] text-[16px] font-medium mb-[15px]">Personal Details</p>
            <div className="flex items-center space-x-4 w-full mb-3">
              <FormInput 
                id="first-name"
                name="first-name"
                placeholder="First Name"
                label="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <FormInput 
                id="last-name"
                name="last-name"
                placeholder="Last Name"
                label="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="flex items-center space-x-4 w-full mb-3">
              <FormInput 
                id="phone-number"
                name="phone-number"
                placeholder="+234xxxxxxx"
                label="Phone Number (+234xxxxxx)"
                onChange={(e) => setPhone(e.target.value)}
              />
              <FormInput 
                id="email"
                name="email"
                placeholder="Email Address"
                label="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-2 items-center mb-3">
              {/*<FormInput
                id="calendar-popover"
                name="date"
                placeholder="Date of Birth"
                label="Date of Birth"
                onFocus={(e) => setAnchorEl(e.currentTarget)}
                value={date}
              />
              <Popover
                id={id}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Calendar 
                  value={selectedDay}
                  onChange={setSelectedDay}
                  shouldHighlightWeekends={false}
                  colorPrimary="#F3641F"
                  colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                />
              </Popover>*/}
              <FormSelect 
                options={[{label:"male", value: "male"}, {label:"female", value: "female"}]}
                label={"Gender"}
                value={gender}
                handleChange={genderChangeHandler}
              />
              <div></div>
            </div>
            {/*<div className="flex items-center">
              <FormInput 
                id="password"
                name="password"
                placeholder="xxxxxxxxxx"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
              <div className="w-8"></div>
              <FormInput 
                id="confirm-password"
                name="confirm-password"
                placeholder="xxxxxxxxxx"
                label="Confirm Password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>*/}
            <p className="text-[#3D4356] text-[16px] font-medium mb-[15px] mt-[40px]">Address</p>            
            <FormInput 
              id="address-1"
              name="address-1"
              placeholder="Enter address here"
              label="Enter address here"
              onChange={handleAddressInput}
              value={address}
              // disabled={!ready}
            />
            {data.length > 0 && (
              <ul className="absolute z-50 p-3 bg-white rounded-md shadow-lg bottom-[-110px] w-full">
                {data.map((suggestion, idx) => {
                  return (
                    <li
                      key={idx}
                      onClick={() => onSelect(suggestion)}
                      className="flex items-center p-2 space-x-3 capitalize cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                    >
                      <img src={location} alt="" />
                      <p className="font-medium">{suggestion.description}</p>
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="h-2"></div>
            <div className="flex items-center justify-end mt-[30px]">
              <button className="w-[83px] h-[43px] bg-transparent rounded-md text-[#4C536A] text-[16px] font-bold border border-[#E3E7ED] mr-[20px]">
                Cancel
              </button>
              <button 
                onClick={handleSave}
                className="w-[144px] h-[43px] bg-[#F3641F] rounded-md text-white text-[16px] font-bold">
                Save Customer
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateCustomerModal;
