import React from 'react'
import { Modal, makeStyles, ButtonGroup, Button } from '@material-ui/core'

import moment from 'moment'
import { useReactToPrint } from 'react-to-print'

import logo from '../../assets/logo.svg'
import close from '../../assets/close.svg'
import clock from '../../assets/clock.svg'
import completed from '../../assets/completed.svg'
import cancelled from '../../assets/cancelled.svg'
import printImg from '../../assets/print.svg'
import loader from '../../assets/loader.svg'
import { toast } from 'react-toastify'
import pdf from '../../assets/pdf.svg'

import { statuses } from '../../enums'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { sendOrderInvoice } from '../../services/orders'

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
  },
})

const ReceiptModal = ({ isOpen, closeModal, data, coupon }) => {
  const classes = useStyles()
  let couponValueOff
  const isPercentage = coupon?.coupon_type === 'Percent amount'
  if (coupon) {
    couponValueOff = isPercentage ? `${+coupon.value}%` : `₦${+coupon.value}`
  }

  const modalRef = useRef()
  const [isPrinting, setIsPrinting] = useState(false)
  const [busy, setBusy] = useState(false)
  const promiseResolveRef = useRef(null)

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const sendInvoice = async () => {
    setBusy(true)
    try {
      await sendOrderInvoice(data.uuid)
      toast.success('Order invoice sent successfully')
      setBusy(false)
      closeModal()
    } catch (err) {
      toast.error('Unable to send order invoice, Please try again!')
      setBusy(false)
    }
  }

  return (
    <Modal open={isOpen} onClose={closeModal} className={classes.modal}>
      <div
        id="receipt"
        ref={modalRef}
        className={`min-w-[600px] min-h-[500px]  ${
          isPrinting
            ? 'overflow-y-none h-full'
            : 'overflow-y-auto h-[95%] max-h-[900px]'
        } bg-white rounded flex flex-col pb-5`}
      >
        <span className="w-full h-[6px] bg-[#F3641F] rounded-md mb-4"></span>
        <div className="flex flex-row items-center justify-between w-full px-7 py-3">
          <img src={logo} alt="logo" className="w-[129px] h-[32px]" />
          <button
            className={`${
              isPrinting ? 'hidden' : 'inline-block'
            } bg-transparent border-0`}
            onClick={closeModal}
          >
            <img src={close} alt="close" className="w-[21px] h-[21px]" />
          </button>
        </div>
        <div className="px-6 mt-4">
          <div className="w-full rounded-md bg-[#F3641F] bg-opacity-10 flex flex-col px-5 py-5">
            <h1 className="text-[26px] text-[#040A1D]">Invoice</h1>
            <div className="flex flex-row items-center justify-between">
              <span className="flex flex-row items-center">
                <p className="text-[#9CA7B8] mr-2">Date:</p>
                <p className="text-[#040A1D] mr-2 font-medium">
                  {moment(data.created_at).format('DD-MM-YYYY HH-MM')}
                </p>
                <p className="text-[#040A1D] font-medium">{data.order_id}</p>
              </span>
              <span className="w-[89px] h-[24px] rounded-md bg-[#477DFB] bg-opacity-10 text-[#477DFB] text-[14px] flex flex-row items-center justify-center">
                {(data.status === statuses.PENDING ||
                  data.status === statuses.ON_DELIVERY ||
                  data.status === statuses.DRAFTING) && (
                  <img
                    src={clock}
                    alt="clock"
                    className="w-[14px] h-[15px] mr-1"
                  />
                )}
                {data.status === statuses.COMPLETED && (
                  <img
                    src={completed}
                    alt="clock"
                    className="w-[14px] h-[15px] mr-1"
                  />
                )}
                {(data.status === statuses.CANCELLED ||
                  data.status === statuses.REFUNDED) && (
                  <img
                    src={cancelled}
                    alt="clock"
                    className="w-[14px] h-[15px] mr-1"
                  />
                )}
                {data.status}
              </span>
            </div>
            <div className="flex flex-row justify-between pr-6 mt-8 gap-6">
              {/*bill column*/}
              <div>
                <h4 className="text-[#F3641F] text-[12px] font-bold mb-1">
                  BILL FROM:
                </h4>
                <h2 className="text-[#040A1D] text-[18px] font-bold mb-2">
                  {data.retailer.name}
                </h2>
                <p className="text-[#4C536A] text-[14px] w-[177px]">
                  {data.retailer.address.address}
                </p>
                <p className="text-[#4C536A] text-[14px]"></p>
                <p className="text-[#4C536A] text-[14px] mt-2">
                  {data.retailer.business_phone}
                </p>
              </div>
              <div>
                <h4 className="text-appGreen text-[12px] font-bold mb-1">
                  BILL TO:
                </h4>
                <h2 className="text-[#040A1D] text-[18px] font-bold mb-2">
                  {data.user.profile.first_name} {data.user.profile.last_name}
                </h2>
                <p className="text-[#4C536A] text-[14px] w-[177px]">
                  {data.delivery_address?.address}
                </p>
                <p className="text-[#4C536A] text-[14px] mt-2">
                  {data.user.phone_number}
                </p>
                <p className="text-[#4C536A] text-[14px] mt-2 mb-0">
                  {data.user.email}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 mt-4 ">
          <table width="100%" className="max-h-96 overflow-y-auto">
            <tr className="h-[35px] bg-transparent">
              <th></th>
              <th className="text-[#9CA7B8] text-[12px] font-medium text-left pl-4">
                Item
              </th>
              <th className="text-[#9CA7B8] text-[12px] font-medium text-center">
                Qty
              </th>
              <th className="text-[#9CA7B8] text-[12px] font-medium text-right">
                Price
              </th>
              <th className="text-[#9CA7B8] text-[12px] font-medium text-right pr-4">
                Total
              </th>
            </tr>
            {data.order_item.map((item, index) => (
              <>
                <div className="page-break" />
                <tr className="bg-[#EEF2F5] rounded-md px-2">
                  <td className="text-[#3D4356] text-[14px] p-1 rounded-md bg-white font-medium">
                    {index + 1}
                  </td>
                  <td className="text-[#3D4356] text-[14px] pl-4 py-1 rounded-l-lg font-medium">
                    <div>
                      <span className="block">{item.product}</span>
                      <small>{item.variant}</small>
                    </div>
                  </td>
                  <td className="text-[#3D4356] text-[14px] py-1 font-medium text-center">
                    {item.quantity}
                  </td>
                  <td className="text-[#3D4356] text-[14px] py-1 font-medium text-right">
                    ₦{Number(item.price.amount).toLocaleString('en')}
                  </td>
                  <td className="text-[#3D4356] text-[14px] py-1 rounded-r-lg font-medium text-right pr-4">
                    ₦{Number(item.total_price.amount).toLocaleString('en')}
                  </td>
                </tr>
                <div className="h-2"></div>
              </>
            ))}
          </table>
        </div>
        <div className="relative flex flex-col items-end pr-10 mt-8">
          <div className="flex flex-row items-center mb-2 justify-end">
            <p className="text-[#8892A2] text-right mr-4 text-[12px] font-medium mb-0">
              Sub Total
            </p>
            <p className="text-[#040A1D] text-[14px] min-w-[7rem] text-right font-medium mb-0">
              ₦{Number(data.sub_total.amount).toLocaleString('en')}
            </p>
          </div>
          {data.delivery_options === 'Deliver' ? (
            <div className="flex flex-row items-center mb-2 justify-end">
              <p className="text-[#8892A2] text-right mr-4 text-[12px] font-medium mb-0">
                Shipping
              </p>
              <p className="text-[#040A1D] text-[14px] min-w-[7rem] text-right font-medium mb-0">
                ₦{Number(data.delivery_fee?.amount || 0).toLocaleString('en')}
              </p>
            </div>
          ) : null}
          <div className="flex flex-row items-center mb-2 justify-end">
            <p className="text-[#8892A2] text-right mr-4 text-[12px] font-medium mb-0">
              Discount
            </p>
            <p className="text-[#040A1D] text-[14px] min-w-[7rem] text-right font-medium mb-0">
              - ₦
              {Number(data.discount_amount?.amount || 0).toLocaleString('en')}
            </p>
          </div>
          <div className="flex flex-row items-center mb-2 justify-end">
            <p className="text-[#8892A2] text-right mr-4 text-[12px] font-medium mb-0">
              Reduction
            </p>
            <p className="text-[#040A1D] text-[14px] min-w-[7rem] text-right font-medium mb-0">
              - ₦{Number(data.discount_total || 0).toLocaleString('en')}
            </p>
          </div>
          {coupon ? (
            <div className="flex flex-row items-center mb-2 justify-end">
              <p className="text-[#8892A2] text-[12px] text-right font-medium mb-0 bg-opacity-5 bg-orange mr-4 p-2 rounded">
                <span class="bg-opacity-10 bg-green px-2 py-1 rounded mr-2">
                  {couponValueOff}
                </span>
                Promo Code Applied
              </p>
              <p className="text-[#040A1D] text-[14px] min-w-[7rem] text-right font-medium mb-0">
                -₦
                {Number(
                  !isPercentage
                    ? +coupon?.value || 0
                    : ((+coupon?.value || 0) / 100) * data.final_cost.amount,
                ).toLocaleString('en')}
              </p>
            </div>
          ) : null}
          <div className="flex flex-row items-center mb-2 justify-end">
            <p className="text-[#8892A2] text-right mr-4 text-[12px] font-medium mb-0">
              Order Amount
            </p>
            <p className="text-[#F3641F] text-[20px] min-w-[7rem] text-right font-medium mb-0">
              ₦{Number(data.final_cost.amount).toLocaleString('en')}
            </p>
          </div>
          {data.order_note ? (
            <div
              className="px-6 mb-10"
              style={{ 'max-width': '31.5rem', 'margin-right': 'auto' }}
            >
              <span className="text-[#8892A2]">Order Note</span>
              <div className="bg-[#EEF2F5] py-2 px-4 rounded-md">
                {data.order_note}
              </div>
            </div>
          ) : null}
          <div
            className={`absolute flex flex-row gap-2 items-center bottom-0 left-6 print-hidden ${
              isPrinting ? 'hidden' : 'block'
            }`}
          >
            <ButtonGroup size="medium">
              {/*<Button>
                <img src={pdf} alt="pdf" className="w-[18px] h-[20px]" />
              </Button>*/}
              <Button onClick={handlePrint}>
                <img src={printImg} alt="print icon" />
              </Button>
            </ButtonGroup>
            <Button
              variant="contained"
              className={`text-white capitalize flex ${
                busy ? 'bg-orange/20' : 'bg-orange'
              } relative`}
              onClick={sendInvoice}
            >
              Send Invoice
              {busy && (
                  <img
                    src={loader}
                    alt=""
                    className="w-5 animate-spin ml-1"
                  />
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReceiptModal
