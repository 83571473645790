import React from 'react';
import { Menu } from '@material-ui/core';

import plus from "../../assets/dark-plus.svg";
import loader from "../../assets/loader.svg";
import CustomerListItem from '../CustomerListItem/CustomerListItem';

const CustomerListMenu = ({handleClose, anchorEl, style, onItemPress, onCreateCustomerClick, customers = [], search, notFound, busy}) => {
  console.log(customers);
  return (
    <Menu
      id="customers-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: style
      }}
      disableEnforceFocus
      disableRestoreFocus
      disableAutoFocus
      disableAutoFocusItem
    >
      <button 
        onClick={onCreateCustomerClick}
        className="rounded-md border border-[#E3E7ED] flex flex-row items-center justify-center w-full h-[43px] text-[#4C536A] text-[16px] font-bold mb-2">
        <img src={plus} alt="add" className="w-[21px] h-[21px] mr-[18px]" />
        Create a new customer
      </button>
      {customers.length > 0 ? (
        customers.map((item, index) => (
          <CustomerListItem 
            customer={item}
            key={index}
            onClick={onItemPress}
          />
        ))
      ) : (
        <>
          {search !== '' && notFound && (
            <p className="text-sm text-center">No customers match "{search}"</p>
          )}
          {search !== '' && busy && (
            <div className="flex items-center justify-center">
              <img src={loader} className="w-5 animate-spin" alt="" />
            </div>
          )}
        </>
      )}
    </Menu>
  )
}

export default CustomerListMenu;