import { useHistory, useParams } from "react-router-dom";
import { IoAddOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

//mui
import { CheckOutlined, CloseOutlined } from "@material-ui/icons";
import { Button, Dialog, IconButton, Switch } from "@material-ui/core";

//components
import Loader from "../../components/Loader/Loader";
import Layout from "../../components/Layout/Layout";

//icons
import success from "../../assets/success.svg";
import error from "../../assets/alt-close.svg";

//utils
import { getSingleSegment, segmentStatus } from "../../services/campaigns";

const SingleSegment = () => {
  const history = useHistory();
  const { id, slug } = useParams();

  const [segment, setSegment] = useState(null);
  const [busy, setBusy] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchSegment = async () => {
    setBusy(true);
    try {
      await getSingleSegment(id).then(async (res) => {
        const response = res.data;
        setSegment(response);
        setBusy(false);
      });
    } catch (error) {
      toast.error("Error fetching Segment data");
      history.goBack();
    }
  };

  const handleSegmentStatus = async () => {
    try {
      setLoading(true);
      if (segment.is_active) {
        await segmentStatus(id);
        toast.success("Segment has been disabled successfully!");
      } else {
        await segmentStatus(id);
        toast.success("Segment has been enabled successfully!");
      }
      await fetchSegment();
      setOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error updating segment status!");
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchSegment();
  }, []);

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <>
          <div className="w-[70%] flex flex-col items-center py-10 space-y-5 mb-[20px] mx-auto">
            <div className="w-full flex justify-between">
              <div className="flex items-center space-x-3">
                <IconButton
                  onClick={() => history.goBack()}
                  disableRipple
                  className="p-0 hover:bg-transparent"
                >
                  <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
                </IconButton>
                <h1 className="font-bold text-[30px] text-gray1 mb-0">
                  {segment?.name}
                </h1>
              </div>
              {/* <Button
                variant="contained"
                color="primary"
                className="capitalize shadow-none"
                startIcon={<IoAddOutline />}
                onClick={() =>
                  history.push(`/campaigns/${slug}/edit-segment/${id}`)
                }
              >
                Update Segment Info
              </Button> */}
            </div>

            <div className="w-full bg-white p-5 space-y-5">
              <p className="text-[#040A1D] font-semibold text-[20px] mb-3">
                Segment Details
              </p>

              <div>
                <p className="text-[14px] text-[#9CA7B8] mb-0">Segment Name</p>
                <p className="text-base text-[#4C536A] font-medium">
                  {segment?.name}
                </p>
              </div>

              <div className="grid grid-cols-2 items-center w-full mb-3">
                <div>
                  <p className="text-[14px] text-[#9CA7B8] mb-0">
                    Min Threshold
                  </p>
                  <p className="text-base text-[#4C536A] font-medium">
                    NGN{segment?.min_purchase_value?.amount}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] text-[#9CA7B8] mb-0">
                    Max Threshold
                  </p>
                  <p className="text-base text-[#4C536A] font-medium">
                    {segment?.max_purchase_value === null
                      ? "Infinity"
                      : segment?.max_purchase_value?.amount}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 items-center w-full mb-3">
                <div>
                  <p className="text-[14px] text-[#9CA7B8] mb-0">
                    Qualifying Threshold
                  </p>
                  <p className="text-base text-[#4C536A] font-medium">
                    NGN{segment?.alert_threshold?.amount}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] text-[#9CA7B8] mb-0">
                    Users Able to Redeem
                  </p>
                  <p className="text-base text-[#4C536A] font-medium">
                    {segment.limit}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white w-full p-5 space-y-5">
              <p className="text-[#040A1D] text-[20px] mb-3 font-semibold">
                Segment Package
              </p>

              <div>
                <p className="text-[14px] text-[#9CA7B8] mb-0">Package</p>
                <p className="text-base text-[#4C536A] font-medium">
                  {segment.package.packages.join(", ")}
                </p>
              </div>
            </div>

            <div className="bg-white w-full p-5 space-y-5">
              <p className="text-[#040A1D] text-[20px] mb-3 font-semibold">
                Segment Settings
              </p>
              <p className=" text-[#9CA7B8]  text-base">
                This segment is currently{" "}
                <span className="text-[#4C536A] font-medium">active</span>
              </p>

              <div className="flex flex-col items-start gap-1">
                <div
                  className="flex items-center gap-3 text-sm border border-[#DEDEE4] bg-[#EFEFF2] p-3 rounded-lg"
                  id="store-status"
                >
                  <span className={`${segment.is_active && "#28283E"}`}>
                    Activate
                  </span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={segment.is_active}
                    onChange={() => setOpen(true)}
                  />
                  <span className={`${segment.is_active && "#8B8B9A"}`}>
                    Deactivate
                  </span>
                </div>
              </div>

              <div>
                <p className="text-[#28283E] text-[10px] py-2 px-3 rounded-xl bg-orange bg-opacity-10 w-[50%] text-center">
                  When you deactivate this segment, customers will be unable to
                  redeem.
                </p>
              </div>
            </div>
          </div>

          <Dialog open={open}>
            <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
              {segment.is_active ? (
                <span className="flex items-center justify-center w-16 h-16 rounded-full bg-red/20">
                  <img src={error} alt="" className="" />
                </span>
              ) : (
                <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                  <img src={success} alt="" className="" />
                </span>
              )}

              <p className="font-medium capitalize text-gray2 w-[70%] text-center">
                Are you sure you want to{" "}
                {segment.is_active ? "Deactivate" : "Activate"} this segment?
              </p>

              {loading ? (
                <Loader />
              ) : (
                <div className="space-x-3">
                  <Button
                    variant="contained"
                    className="capitalize bg-white border shadow-none"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    No, Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className="capitalize shadow-none"
                    onClick={handleSegmentStatus}
                  >
                    Yes, {segment.is_active ? "Deactivate" : "Activate"}
                  </Button>
                </div>
              )}
            </div>
          </Dialog>
        </>
      )}
    </Layout>
  );
};
export default SingleSegment;
