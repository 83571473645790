import React from 'react'

import searchIcon from '../../assets/search.svg'
import ExportButton from '../ExportButton/ExportButton'

const TableActions = ({
  activeTab,
  changeTab,
  onSearchChange,
  exportData,
  searchPlaceholder,
  tabLabels,
  onSearchKeyUp,
  onSearchKeyDown,
}) => {
  return (
    <div className="bg-white rounded-md w-full h-[61px] flex flex-row items-center px-[25px] mt-4 justify-between">
      {/* tabs */}
      <div className="flex flex-row items-center h-full">
        {tabLabels.map((item, index) => (
          <span
            key={index}
            onClick={() => {
              changeTab(item)
            }}
            className={
              activeTab === `${item}`
                ? 'text-[#040A1D] font-medium text-[16px] border-b-4 border-[#F3641F] h-full mr-[20px] pt-5 cursor-pointer capitalize'
                : 'font-medium text-[#9CA7B8] text-[16px] mr-4 cursor-pointer capitalize'
            }
          >
            {item}
          </span>
        ))}
      </div>
      {/* search and export */}
      <div className="flex-grow flex flex-row items-center">
        <div
          className="flex-grow flex flex-row items-center bg-[#F4F5F7] rounded-md pl-3 focus-within:border-b focus-within:border-appBlue"
          style={{ maxWidth: '300px', marginLeft: 'auto' }}
        >
          <img src={searchIcon} alt="search icon" />
          <input
            onChange={onSearchChange}
            placeholder={searchPlaceholder}
            onKeyDown={onSearchKeyDown}
            onKeyUp={onSearchKeyUp}
            className="bg-transparent font-medium text-[14px] flex-grow h-[34px] pl-3 focus:outline-none"
          />
        </div>
        <ExportButton onClick={exportData} />
      </div>
    </div>
  )
}

export default TableActions
