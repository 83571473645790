import React, { useState, useEffect, useContext } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

//icons
import retailersIcon from '../../assets/retailors-inactive.svg'

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import TabButton from '../../components/TabButton/TabButton'
import RetailersTable from '../../components/RetailersTable/RetailersTable'
import Loader from '@components/Loader/Loader'
import SearchBar from '../../components/SearchBar/SearchBar'
import { RootContext } from '../..'

//services
import { getRetailers } from '../../services/retailers'
import { exportData } from '../../utils'

const Index = observer(() => {
  const root = useContext(RootContext)
  const history = useHistory()

  const [busy, setBusy] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [activeTab, setActiveTab] = useState('Active Retailers')
  const [retailers, setRetailers] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [controller, setController] = useState(null)

  const fetchRetailers = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)

    try {
      const { data } = await getRetailers(
        {
          status:
            activeTab === 'Active Retailers'
              ? 'Approved'
              : activeTab === 'Pending Retailers'
              ? 'Pending'
              : 'Rejected',
          page_size: rowsPerPage,
          page: currentPage,
          search: searchValue,
        },
        abc.signal,
      )

      setRetailers(data.results)
      setCount(data.count)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('An error occurred. Unable to fetch retailers')
        setBusy(false)
      }
    }
  }

  const fetchExportData = async () => {
    toast.info('Fetching data to export...')

    try {
      const { data } = await getRetailers({
        status:
          activeTab === 'Active Retailers'
            ? 'Approved'
            : activeTab === 'Pending Retailers'
            ? 'Pending'
            : 'Rejected',
        search: searchValue,
        paginate: 'no',
      })
      exportData(
        data.results.map(({ address, ...retailer }) => ({
          Business: retailer.name || '',
          Email: retailer.business_email || '',
          'Phone Number': retailer.business_phone || '',
          Bio: retailer.bio || '',
          Website: retailer.website !== 'null' ? retailer.website || '' : '',
          Address:
            [
              address?.address,
              '',
              address?.street_name,
              address?.town,
              address?.city,
              address?.country,
            ]
              .filter((field) => field !== 'null')
              .join('\n') || '',
          Manager:
            [retailer.manager?.email, retailer.manager?.phone_number]
              .filter(Boolean)
              .join('\n') || '',
          Status: retailer.status,
          'Offers Delivery': retailer.allow_delivery ? 'Yes' : 'No',
          'Delivery Fee (NGN)': retailer.delivery_price || '',
          'Allows Pickup': retailer.allow_pickup ? 'Yes' : 'No',
          Availability: retailer.store_availability,
        })),
        `${activeTab === 'Retailers' ? 'Approved' : 'Pending'} Retailers`,
      )

      toast.success('Retailers data successfully exported.')
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later')
    } finally {
      toast.dismiss()
    }
  }

  useEffect(() => {
    fetchRetailers()
  }, [activeTab, currentPage, rowsPerPage, searchValue])

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Retailers" />

        <SearchBar
          placeholder="Search Retailers"
          onChange={(e) => {
            setCurrentPage(1)
            setSearchValue(e.target.value)
          }}
          value={searchValue}
          onExport={fetchExportData}
        >
          {['Active Retailers', 'Pending Retailers', 'Rejected Retailers'].map(
            (item, index) => (
              <TabButton
                key={index}
                activeTab={activeTab}
                onClick={() => setActiveTab(item)}
                title={item}
              />
            ),
          )}
        </SearchBar>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : (
          <>
            {retailers.length === 0 && searchValue.length === 0 ? (
              <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                <img src={retailersIcon} alt="" className="w-16 h-16" />
                <p className="text-[18px] font-semibold text-gray1">
                  All your retailers will show here
                </p>

                <p className="text-sm text-gray2">
                  This is where you add and update your retailers
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={() => history.push('/retailers/add-new-retailer')}
                >
                  Add New Retailer
                </Button>
              </div>
            ) : (
              <div className="py-5 space-y-5">
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="capitalize shadow-none"
                    startIcon={<IoAddOutline />}
                    onClick={() => history.push('/retailers/add-new-retailer')}
                  >
                    Add New Retailer
                  </Button>
                </div>

                <RetailersTable
                  rows={retailers}
                  search={searchValue}
                  busy={busy}
                  notFound={notFound}
                  count={count}
                  onRowsPerPageChange={(rows) => {
                    setCurrentPage(1)
                    setRowsPerPage(rows)
                  }}
                  onPageChange={setCurrentPage}
                  page={currentPage - 1}
                  rowsPerPage={rowsPerPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  )
})

export default Index
