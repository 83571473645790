import api from '../apiInstance'

export const getAllUsers = (pageSize, pageNumber) =>
  api.get('/api/users/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
    },
  })

export const getAllCustomers = (params, signal) =>
  api.get('/api/users/', {
    params: {
      ...params,
      account_type: 'Customer',
    },
    signal,
  })

export const getUser = (id) => api.get(`/api/users/${id}/`)

export const createNewUser = (data) =>
  api.post(`/api/users/dashboard-add-user/`, data)

export const createRetailerUser = (data) =>
  api.post(`/api/users/retailer-dashboard-add-user/`, data)

export const updateEmailOrPhoneNumber = (uuid, form) =>
  api.patch(`/api/users/${uuid}/`, form)

export const updateProfile = (form, id) =>
  api.patch(`/api/profile/${id}/`, form)

export const searchRetailerCustomers = (query, uuid, pageNumber, pageSize) =>
  api.get('/api/users/retailer-customer/', {
    params: {
      ...(pageNumber ? { page: pageNumber } : {}),
      ...(pageSize ? { page_size: pageSize } : {}),
      search: query,
      retailer_uuid: uuid,
    },
  })

export const searchCustomers = (query, pageNumber, pageSize) =>
  api.get(`/api/users/all-customers/`, {
    params: {
      page: pageNumber,
      page_size: pageSize,
      search: query,
    },
  })

export const updateAddress = (form) =>
  api.post(`/api/order-delivery-location/`, form)

export const createCustomer = (form) =>
  api.post(`/api/users/dashboard-add-user/`, form)

export const getRetailerCustomers = (params, signal) =>
  api.get(`/api/users/retailer-customer/`, {
    params,
    signal,
  })

export const getDeliveryAddress = (id) =>
  api.get(`/api/order-delivery-location/${id}/`)

export const createDeliveryAddress = (form) =>
  api.post(`/api/order-delivery-location/`, form)

export const updateDeliveryAddress = (id, form) =>
  api.put(`/api/order-delivery-location/${id}/`, form)

export const getUsers = (params, signal) =>
  api.get('/api/users/', {
    params,
    signal,
  })

export const getUsersTeam = (params, signal) =>
  api.get('/api/users/retailer-team/', {
    params,
    signal,
  })

export const updateUserDetails = (uuid, form) =>
  api.patch(`/api/users/${uuid}/`, form)

// user delete services
export const deleteSingleUser = (uuid) => api.delete(`/api/users/${uuid}/`)
