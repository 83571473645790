import React from "react";
// import { useHistory } from "react-router-dom";

import useTableMethods from "../../../customHooks/useTableMethods";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";

//components
import TableHead from "./ProductsTableHead";

//assets
import defaultIcon from "../../../assets/products-inactive.svg";

const Product = ({ rows, searchValue }) => {
  // const history = useHistory();

  
  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows);

  const addDefaultImage = (event) => {
    event.target.src = defaultIcon
  }

  return (
    <div className="w-full mt-5">
      <Table className="min-w-[750px]" size="small">
        <TableHead
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={rows.length}
          selected={selected}
        />

        <TableBody className="bg-white">
          {rows.map((row, index) => {
            const isItemSelected = isSelected(row);

            return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                // selected={isItemSelected}
                className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => handleClick(event, row)}
                    className=""
                    classes={{
                      root: "",
                      colorPrimary: "",
                      checked: "text-blue",
                    }}
                  />
                </TableCell>
                <TableCell className="flex items-center space-x-2">
                  <img src={row.image} alt="" className="w-[40px] h-[40px]" onError={addDefaultImage}/>

                  <p className={`text-sm font-medium text-gray2`}>{row.name}</p>
                </TableCell>
                <TableCell className="text-sm font-medium text-gray2">
                  {row.brand && row.brand.name}
                </TableCell>

                <TableCell className="font-medium text-black" align="right">
                  ₦{Intl.NumberFormat().format((+row.price.amount).toFixed(2))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default Product;
