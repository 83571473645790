import { useEffect, useState } from "react";
import moment from "moment";
import { Button, TablePagination } from "@material-ui/core";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

//assets
import couponIcon from "../../assets/coupons-inactive.svg";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import DateActions from "../../components/DateActions/DateActions";
import CouponSummaryCard from "../../components/CouponSummaryCard/CouponSummaryCard";
import AvailableCouponsCard from "../../components/AvailableCouponsCard/AvailableCouponsCard";
import CouponTabBar from "../../components/CouponTabBar/CouponTabBar";
import TabButton from "../../components/TabButton/TabButton";
import CouponListItem from "../../components/CouponListItem/CouponListItem";
import Loader from "../../components/Loader/Loader";

//services
import { getCoupons } from "../../services/coupons";

const Coupons = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [count, setCount] = useState(0);
  const [stats, setStats] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("all");
  const [activePeriod, setActivePeriod] = useState("month");
  const [dateRange, setDateRange] = useState({start: moment(), end: moment()})

  const fetchCoupons = async ({page=1, dateRange}) => {
    setBusy(true);
    try {
      let dateQs, before, after
      if (dateRange) {
        [before, after] = [dateRange.start, dateRange.end].map(d => d.format('YYYY-MM-DD'))
        dateQs = `date_range_before=${after}&date_range_after=${before}`
      } else {
        dateQs = `date=${activePeriod}`
      }
      const { data } = await getCoupons(`${activeTab !== 'all' ? `type=${activeTab}` : ''}&page=${page}&${dateQs}&page_size=9`)
      setCoupons(data.results);
      setCount(data.count);

    } catch(err) {
      toast.error('An error occurred. Unable to fetch requested data.');

    } finally {
      setBusy(false);
    }
  }

  const onPageChange = (_, newPage) => {
    setCurrentPage(newPage);
    fetchCoupons({page: newPage});
  }

  useEffect(() => {
    fetchCoupons({});
  }, [])

  useEffect(() => {
    fetchCoupons({});
  }, [activePeriod, activeTab])

  return (
    <Layout>
      <div className="page-padding">
        <PageTitle title="Coupon Codes" />
        {busy ? (
          <Loader />
        ) : (
          <>
            <DateActions 
              activePeriod={activePeriod}
              changePeriod={setActivePeriod}
              className=""
              dateRange={dateRange}
              onDateRangeSelect={range => fetchCoupons({ dateRange: range })}
            />
            <div className="grid grid-cols-[1fr,1fr,1fr,2fr] mt-[15px] gap-5 mb-[22px]">
              <CouponSummaryCard 
                label="Total Coupons Used"
                amount={stats.total_used || 0}
              />
              <CouponSummaryCard 
                label="Fixed Amount"
                amount={stats.fixed_amount || 0}
                accentColor="#38C985"
              />
              <CouponSummaryCard 
                label="Percentage"
                amount={stats.percent_amount || 0}
                accentColor="#477DFB"
              />
              <AvailableCouponsCard 
                label="Available Coupon Codes"
                fixedUsed={stats.fixed_amount_used || 0}
                fixedTotal={stats.fixed_amount_total || 0}
                percentageUsed={stats.percent_amount_used || 0}
                percentageTotal={stats.percent_amount_total || 0}
              />
            </div>
            <CouponTabBar onClick={() => history.push('/coupons/add-coupon')}>
              <TabButton
                activeTab={activeTab}
                onClick={() => setActiveTab("all")}
                title="All"
              />
              <TabButton
                activeTab={activeTab}
                onClick={() => setActiveTab("Fixed amount")}
                title="Fixed Amount"
                tab="Fixed Amount"
              />
              <TabButton
                activeTab={activeTab}
                onClick={() => setActiveTab("Percent amount")}
                title="Percentage Amount"
                tab="Percent amount"
              />
            </CouponTabBar>
            {/* empty view */}
            {!coupons.length ? (
              <div
                className="flex flex-col items-center mt-4 justify-center w-full bg-white rounded-md h-[451px]"
              >
                <img src={couponIcon} alt="" className="w-[72px] h-[72px]" />
                <p className="font-semibold text-[#3D4356] text-[18px] mb-[10px]">
                  Your Coupons will show here
                </p>
                <p className="text-sm text-center text-gray4 w-[330px] mt-0">
                  This is where you’ll fulfill orders, collect payments, and track order progress.
                </p>
                <Button
                  className="text-white capitalize bg-orange px-3 py-2"
                  onClick={() => history.push('/coupons/add-coupon')}
                >
                  <AiOutlinePlus size={18} className="mr-2"/>
                  Create a Coupon
                </Button>
              </div>
            ) : (
              <div
                className="grid grid-cols-3 gap-4 mt-4 3xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6"
              >
                {coupons.map((item, index) => (
                  <CouponListItem
                    key={index}
                    id={item.id}
                    value={item.value}
                    type={item.coupon_type}
                    code={item.code}
                    limit={item.limit}
                    usage={item.usage}
                    quantity={item.quantity}
                    used={item.total_amount_used}
                    creationDate={item.created_at}
                    expiryDate={item.expiry_date}
                  />
                ))}
              </div>
            )}
            {!busy && coupons.length > 0 && (
              <TablePagination 
                rowsPerPageOptions={[9]}
                component="div"
                count={count}
                rowsPerPage={9}
                page={currentPage - 1}
                onPageChange={(e, newPage) => onPageChange(e, newPage + 1)}
              />
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default Coupons;
