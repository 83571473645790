import React from "react";

import productIcon from "../../assets/products-inactive.svg";

const ProductListItem = ({product, onClick}) => {
  return (
    <div
      onClick={() => onClick(product)}
      className="flex flex-row items-center justify-between w-full rounded-md group hover:bg-[#F3641F] hover:bg-opacity-5 px-2 py-2 mb-4"
    >
      <span className="flex flex-row items-center">
        {product.product.image ? (
          <img src={product.product.image} alt="product" className={`w-[33px] h-[33px] ${product.out_of_stock && 'opacity-30'}`} />
        ) : (
          <img src={productIcon} alt="product" className={`w-[33px] h-[33px] ${product.out_of_stock && 'opacity-30'}`}/>
        )}
        <p 
          className={`${product.out_of_stock ? 'text-[#040A1D] opacity-30 text-[14px] font-medium': 'text-[#040A1D] text-[14px] font-medium'} ml-4 group-hover:text-[#F3641F] mr-4 mb-0`}
        >
          { product.product.name }<br/>
          <small>{ product.attribute.name }</small>
        </p>
        {product.out_of_stock ? (
          <div className="w-[95px] h-[24px] text-center rounded-md bg-[#FF3D22] text-[#FF3D22] bg-opacity-10 text-[14px] flex items-center justify-center">Out of Stock</div>
        ) : (
          <div className="text-[#4C536A] text-[14px] px-[10px] h-[24px] rounded-md bg-[#4C536A] bg-opacity-10">
            {product.quantity} Available
          </div>
        )}
      </span>
      <p className={`${product.out_of_stock ? 'opacity-30':'opacity-100'} group-hover:text-[#FF3D22] text-[#040A1D] text-[14px] mb-0`}>
        ₦{Number(product.selling_price.amount).toLocaleString('en')}
      </p>
    </div>
  );
}

export default ProductListItem;
