// import qs from 'qs'
import api from '../apiInstance'

export const getOrder = (id) => api.get(`/api/order/${id}/`)

export const getOrders = (retailerId, params, signal) =>
  api.get('/api/order/', {
    params: {
      ...params,
      ...(retailerId ? { retailer: retailerId } : {}),
    },
    signal,
  })

export const getOrdersSummary = (retailer) =>
  api.get('/api/order/order-summary/', {
    params: {
      ...(retailer ? { retailer } : {}),
    },
  })

// export const getOrdersByStatus = (status) =>
//   api.get(`/api/order/`, {
//     params: {
//       status,
//       ordered: true,
//     },
//   })

export const getOrdersByDate = (date, retailerId, pageNumber, pageSize) =>
  api.get('/api/order/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
      payment_day: date,
      retailer: retailerId,
      ordered: true,
    },
  })

export const getOrdersByDateRange = (
  dateRangeBefore,
  dateRangeAfter,
  retailerId,
  pageNumber,
  pageSize,
) =>
  api.get('/api/order/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
      end_date: dateRangeBefore,
      start_date: dateRangeAfter,
      retailer: retailerId,
      ordered: true,
    },
  })

export const createOrder = (form) => api.post(`/api/order/`, form)

export const addPaymentMethod = (order, method) =>
  api.post(`/api/order-payment/${order}/`, method)

export const addOrderSplitPayments = (method) =>
  api.post(`/api/order-split-payment/`, method)

export const updateOrder = (order, id) => api.patch(`/api/order/${id}/`, order)

export const updateOrderItem = (order, id) => api.patch(`/api/order-item/${id}/`, order)

export const sendOrderInvoice = (id) => api.post(`/api/order/${id}/send-invoice/`)

export const updateOrderStatus = (order, id) =>
  api.post(`/api/order/${id}/push-order-status/`, order)

export const getTodaysOrderSummary = (retailer) =>
  api.get('/api/order/order-summary/', {
    params: {
      date: 'today',
      ...(retailer ? { retailer } : {}),
    },
  })

export const getStockOrderSummary = (retailerId, stockId) =>
  api.get('/api/order/order-summary/', {
    params: {
      stock_uuid: stockId,
      // date: today,
      // date: 'today',
      ...(retailerId
        ? {
            retailer: retailerId,
          }
        : {}),
    },
  })

export const getOrderSummary = () => api.get('/api/order/order-summary/')

export const getRetailerOrderSummary = (retailer) =>
  api.get('/api/order/order-summary/', {
    params: {
      retailer,
    },
  })

export const getOrdersByPeriodForAdmin = (pageNumber, pageSize, date) =>
  api.get('/api/order/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
      date,
    },
  })

export const getOrdersByDateRangeForAdmin = (
  pageNumber,
  pageSize,
  dateRangeBefore,
  dateRangeAfter,
) =>
  api.get('/api/order/', {
    params: {
      page: pageNumber,
      page_size: pageSize,
      end_date: dateRangeBefore,
      start_date: dateRangeAfter,
      // retailer
    },
  })

export const getCustomersOrders = (id) =>
  api.get('/api/order/', {
    params: {
      user: id,
      page: 1,
      page_size: 100,
    },
  })

// export const getCustomersOrdersByDate = (id, dateBefore, dateAfter) =>
//   api.get('/api/order/', {
//     params: {
//       user: id,
//       page: 1,
//       page_size: 100,
//       end_date: dateBefore,
//       start_date: dateAfter,
//     },
//   })

export const searchCustomersOrders = (id, query) =>
  api.get('/api/order/', {
    params: {
      user: id,
      search: query,
      page: 1,
      page_size: 100,
    },
  })

export const getCouponOrders = (uuid, pageNumber) =>
  api.get('/api/order/', {
    params: {
      coupon__uuid: uuid,
      page: pageNumber,
      page_size: 10,
    },
  })

export const searchCouponOrders = (query, id, pageNumber) =>
  api.get('/api/order/', {
    params: {
      coupon__uuid: id,
      page: pageNumber,
      page_size: 10,
      search: query,
    },
  })

// export const exportOrders = (parameters) => {
//   const params = qs.stringify(parameters)
//   return api.get(`/api/order/?${params}&ordered=true`)
// }
