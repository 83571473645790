import { observer } from 'mobx-react-lite'
import { useState } from 'react'
// import { IoAddOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import moment from 'moment'

// icons
import voucherIcon from '../../assets/coupons-active.svg'

//mui
// import { Button } from '@material-ui/core'

// components
import Layout from '../../components/Layout/Layout'
import Loader from '../../components/Loader/Loader'
import PageTitle from '../../components/PageTitle/PageTitle'
import SearchBar from '../../components/SearchBar/SearchBar'
// import DateActions from '../../components/DateActions/DateActions'
import TabButton from '../../components/TabButton/TabButton'
import VouchersTable from '../../components/VouchersTable/VouchersTable'

// services
import { exportData, formatDate } from '../../utils'
import { getVouchers } from '../../services/vouchers'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

const tabs = [
  { label: 'All', key: 'all' },
  { label: 'Issued', key: 'issued' },
  { label: 'Unissued', key: 'unissued' },
]
const Index = observer(() => {
  // const history = useHistory()

  const [busy, setBusy] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [activeTab, setActiveTab] = useState(tabs[0].key)
  const [vouchers, setVouchers] = useState([])
  const [searchValue, setSearchValue] = useState('')
  // const [selectedFilter, setSelectedFilter] = useState({
  //   type: 'period',
  //   value: 'today',
  // })
  // const [activePeriod, setActivePeriod] = useState(
  //   Cookies.get('active-voucher-period') ?? 'today'
  // )
  // const [dateRange, setDateRange] = useState({
  //   start: moment(),
  //   end: moment().add(2, 'M'),
  // })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [controller, setController] = useState(null)

  const fetchVouchers = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)
    try {
      const { data } = await getVouchers(
        {
          page: currentPage,
          page_size: rowsPerPage,
          search: searchValue,
          ...(activeTab === 'all' ? {} : { is_issued: activeTab === 'issued' }),
          // ...(selectedFilter.type === 'range'
          //   ? {
          //       end_date: dateRange.end.format('YYYY-MM-DD'),
          //       start_date: dateRange.start.format('YYYY-MM-DD'),
          //     }
          //   : {
          //       payment_day: activePeriod,
          //     }),
        },
        abc.signal,
      )

      setVouchers(data.results)
      setCount(data.count)
      setBusy(false)
    } catch (error) {
      console.error(error)
      if (error.name !== 'CanceledError') {
        toast.error('An error occurred. Unable to fetch vouchers')
        setBusy(false)
      }
    }
  }

  const fetchExportData = async () => {
    toast.info('Fetching data to export...')
    try {
      const { data } = await getVouchers({
        search: searchValue,
        ...(activeTab === 'all' ? {} : { is_issued: activeTab === 'issued' }),
        paginate: 'no',
      })
      const tab = tabs.find((entry) => entry.key === activeTab)
      exportData(
        data.map((entry) => ({
          'Voucher Code': entry.code,
          'Order Id': entry.order_id || '',
          Customer: [
            entry.user?.profile?.first_name,
            entry.user?.profile?.last_name,
          ]
            .filter(Boolean)
            .join(' '),
          Email: entry.user?.email,
          'Phone Number': entry.user?.phone_number,
          // 'Account Type': entry.user?.account_type,
          Gender: entry.user?.profile?.gender,
          'Voucher Issued': entry.is_issued ? 'Yes' : 'No',
          'Date of Issue': entry.is_issued
            ? formatDate(entry.updated_at)
            : 'N/A',
        })),
        `${tab.label} Vouchers`,
      )
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later!')
    } finally {
      toast.dismiss()
    }
  }

  // const handleActivePeriodChange = (period) => {
  //   Cookies.set('active-voucher-period', period)
  //   setSelectedFilter({ type: 'period', value: period })
  //   setCurrentPage(1)
  //   setActivePeriod(period)
  // }

  useEffect(() => {
    fetchVouchers()
  }, [activeTab, searchValue, currentPage, rowsPerPage])

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Vouchers" />
        {/* <DateActions
          activePeriod={
            selectedFilter.type === 'period' ? activePeriod : undefined
          }
          changePeriod={handleActivePeriodChange}
          className="justify-end w-full mt-4"
          dateRange={dateRange}
          onDateRangeSelect={(range) => {
            setSelectedFilter({ type: 'range', value: range })
            setCurrentPage(1)
            setDateRange(range)
          }}
        /> */}
        <SearchBar
          placeholder="Search Vouchers"
          onChange={(e) => {
            setCurrentPage(1)
            setSearchValue(e.target.value)
          }}
          value={searchValue}
          onExport={fetchExportData}
        >
          {tabs.map((item, index) => (
            <TabButton
              key={index}
              activeTab={activeTab}
              onClick={() => setActiveTab(item.key)}
              title={item.label}
            />
          ))}
        </SearchBar>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : (
          <>
            {vouchers.length === 0 && searchValue.length === 0 ? (
              <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                <img src={voucherIcon} alt="" className="w-16 h-16" />
                <p className="text-[18px] font-semibold text-gray1">
                  {tabs.find((item) => item.key === activeTab)?.label} vouchers
                  will show here
                </p>

                {/* <p className="text-sm text-gray2">
                  This is where you add and update your vouchers
                </p> */}
                {/* <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={() => history.push('/vouchers/add-new-voucher')}
                >
                  Add New Voucher
                </Button> */}
              </div>
            ) : (
              <div className="py-5 space-y-5">
                {/* <div className="flex justify-end">
                      <Button
                        variant="contained"
                        color="primary"
                        className="capitalize shadow-none"
                        startIcon={<IoAddOutline />}
                        onClick={() =>
                          history.push('/vouchers/add-new-voucher')
                        }
                      >
                        Add New Voucher
                      </Button>
                    </div> */}

                <VouchersTable
                  rows={vouchers}
                  search={searchValue}
                  busy={busy}
                  notFound={notFound}
                  count={count}
                  onRowsPerPageChange={(rows) => {
                    setCurrentPage(1)
                    setRowsPerPage(rows)
                  }}
                  onPageChange={setCurrentPage}
                  page={currentPage - 1}
                  rowsPerPage={rowsPerPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  )
})

export default Index
