import useTableMethods from "../../customHooks/useTableMethods";
import { getComparator, stableSort } from "../../utils/tableFunctions";

//icons
import edit from "../../assets/edit.svg";
import loader from "../../assets/loader.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
  TablePagination,
} from "@material-ui/core";

//components
import CustomTableHead from "./AttributesTableHead";

const AttributesTable = ({ rows, onEditClick, busy = false,
  notFound = false, search = "" }) => {

  const { handleSelectAllClick, handleClick, isSelected, selected, emptyRows,
    order, orderBy, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } =
    useTableMethods(rows);

  return (
    <div className="w-full mt-5">
      {busy && search.length > 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            >
            </CustomTableHead>
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{" "}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <CustomTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />

          <TableBody className="bg-white">
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
              const isItemSelected = isSelected(index);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="flex items-center space-x-2">
                    <p className="text-sm font-medium text-gray2">{row.name}</p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.parent ? row.parent.name : ""}
                  </TableCell>

                  <TableCell className="font-medium text-black" align={"left"}>
                    {row.description}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button 
                        onClick={() => onEditClick(row)}
                      >
                        <img src={edit} alt="" />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination 
          rowsPerPageOptions={[10, 25, 40]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default AttributesTable;