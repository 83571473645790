import React from "react";

import { TableCell, TableHead, TableRow, Checkbox } from "@material-ui/core";

const headCells = [
  { id: "id", align: 'left', label: "id" },
  { id: "name", align: 'left', label: "product name" },
  { id: "retailers", align: 'left', label: "retailers" },
  { id: "featured", align: 'left', label: "featured" },
  { id: "price", align: 'right', label: "retail price" },
  { id: "status", align: 'right', label: 'product status'},
  { id: "actions", align: 'right', label: "actions" },
];

const AvailableProductsTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
}) => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            color="primary"
            className={`${
              numSelected > 0 && numSelected < rowCount && "text-orange"
            }`}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="uppercase text-[12px]"
            align={headCell.align}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AvailableProductsTableHead;
