import React from "react";

//components
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";

const Layout = ({ children }) => {
  return (
    <div className="flex">
      <Sidebar />

      <main className="h-screen flex-1 bg-[#EEF2F5] overflow-x-hidden">
        <Topbar />

        <div className="">{children}</div>
      </main>
    </div>
  );
};

export default Layout;
