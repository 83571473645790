import React, { useState } from "react";
import { Modal } from "@material-ui/core";

//assets
import closeIcon from "../../assets/close.svg";
import roundClose from "../../assets/round-close.svg";
import attachment from "../../assets/attachment.svg";
import file from "../../assets/file.svg";
import loader from "../../assets/loader.svg";
import orderIcon from "../../assets/orders-inactive.svg";
import success from "../../assets/success.svg";


const EmailInvoice = ({open, close}) => {
  const [busy, setBusy] = useState(false);
  const [recipients, setRecipients] = useState(['jakpasu@drinksnd.com', 'anon@darkarmy.com', 'mr.robot@hacker.com']);
  const [view, setView] = useState('compose');

  const deleteRecipient = (recipient) => {
    setRecipients(prevState => prevState.filter(item => item !== recipient));
  }

  return(
    <Modal
      open={open}
      className="flex items-center justify-center overflow-y-scroll"  
      disableBackdropClick
    >
      {view === 'compose' ? (
        <ComposeState 
          recipients={recipients}
          deleteRecipient={deleteRecipient}
          close={close}
          changeView={setView}
        />
      ) : view === 'sending' ? (
        <SendingState changeView={setView}/>
      ) : ( <SentState done={close} />)}
    </Modal>
  )
}

const ComposeState = ({recipients, deleteRecipient, close, changeView}) => {
  return (
    <div className="w-[906px] bg-white rounded">
      <div className="flex flex-row items-center justify-between px-6 py-4 border-b border-[#E3E7ED]">
        <h3 className="text-[#040A1D] text-[20px] font-bold">Email Invoice</h3>
        <button
          onClick={close}
        >
          <img src={closeIcon} alt="" className="w-[21.04px] h-[21.04px]"/>
        </button>
      </div>
      <div className="flex flex-row items-center px-6 py-3 border-b border-[#E3E7ED]">
        <p className="text-[#9CA7B8] text-[16px] font-medium">To:</p>
        <div className="flex flex-row items-center ml-3 overflow-x-scroll w-[90%]">
          {recipients.map((item, index) => (
            <span className={`text-[#477DFB] text-[16px] font-medium ${recipients.length > 1 && 'border border-[#477DFB] bg-[#477DFB] bg-opacity-10 rounded-lg px-3 mr-2'} flex flex-row items-center`} key={index}>
              {item}
              {recipients.length > 1 && (
                <button
                  onClick={() => deleteRecipient(item)}
                  className="ml-1"
                >
                  <img src={roundClose} alt="" className="w-[20px] h-[20px]"/>
                </button>
              )}
            </span>
          ))}
        </div>
        <div className="flex flex-row items-center float-right">
          <button
            className="text-[#9CA7B8] text-[18px] font-medium mr-3"
          >
            Cc
          </button>
          <button
            className="text-[#9CA7B8] text-[18px] font-medium"
          >
            Bcc
          </button>
        </div>
      </div>
      <div className="py-3 px-6 border-b border-[#E3E7ED] flex flex-row items-center">
        <p className="text-[#9CA7B8] text-[16px] font-medium">Subject:</p>
        <input 
          className="bg-transparent text-[#040A1D] text-[18px] font-medium outline-none ml-2 w-[100%]"
        />
      </div>
      <div className="py-3 px-6 border-b border-[#E3E7ED] flex flex-row items-center">
        <img src={attachment} alt="" className="w-[20px] h-[19px] mr-1" />
        <p className="text-[#9CA7B8] text-[16px] font-medium">Attachment:</p>
        <img src={file} alt="" className="w-[26px] h-[26px] ml-1"/> 
        <p className="text-[#4C536A] text-[16px] font-medium ml-1">Invoice# DND100928</p>
      </div>
      <textarea 
        className="p-[30px] w-full outline-none focus:ring-transparent focus:outline-none focus:border-0 border-0 resize-none" 
        rows={9}>
      </textarea>
      <div className="bg-[#F4F5F7] flex flex-row items-center justify-end px-6 py-4 rounded-b-md">
        <button 
          onClick={close}
          className="bg-white text-[#4C536A] text-[16px] font-bold border border-[#E3E7ED] rounded-md px-4 py-2"
        >
          Save as draft
        </button>
        <button
          onClick={() => changeView('sending')} 
          className="bg-[#F3641F] text-white text-[16px] font-bold rounded-md px-4 py-2 ml-3">
          Send
        </button>
      </div>
    </div>
  )
}

const SendingState = ({changeView}) => {
  useState(() => {
    setTimeout(() => {
      changeView('sent')
    }, 2000);
  })

  return (
    <div className="bg-[#FAFAFA] w-[404px] h-[170px] rounded-md flex flex-col justify-center items-center">
      <img src={orderIcon} alt="" className="w-[50px] h-[40px]"/>
      <p className="text-[#4C536A] text-[16px] font-medium mt-2">Sending Order Mail</p>
      <img src={loader} alt="" className="w-5 mt-2 animate-spin"/>
    </div>
  )
}

const SentState = ({done}) => {
  return (
    <div className="bg-[#FAFAFA] flex flex-col justify-center items-center w-[404px] h-[251px] rounded-md">
      <div className="w-[fit-content] bg-green/30 rounded-full p-2">
        <img src={success} alt="" className="w-[50px] h-[50px]" />
      </div>
      <p className="text-[#4C536A] text-[16px] font-medium mt-3">Invoice sent</p>
      <button 
        onClick={done}
        className="bg-[#F3641F] rounded-md p-3 text-white text-[16px] font-bold mt-10">
        Okay
      </button>
    </div>
  )
}

export default EmailInvoice