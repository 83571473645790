import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { observer } from 'mobx-react-lite'
import pen from '../../assets/pen.svg'
// import trash from "../../assets/trash.svg";

//mui
import { Button, Avatar, IconButton } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import { RootContext } from '../..'

//services
import { deleteSingleUser, getUser } from '../../services/users'
import { toast } from 'react-toastify'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'

const Index = observer(() => {
  const { userId } = useParams()
  const history = useHistory()
  const [user, setUser] = useState(null)
  const root = useContext(RootContext)

  const [open, setOpen] = useState(false)

  const fetchUser = async () => {
    try {
      const { data } = await getUser(userId)
      setUser(data)
    } catch (error) {
      history.goBack()
      toast.error('Unable to find requested user. Please try again later.')
    }
  }

  const deleteUser = async () => {
    try {
      toast.info("Deleting user...");
      await deleteSingleUser(user.uuid);
      toast.success("User deleted successfully.");
      setOpen(false);
      history.goBack()
    } catch {
      toast.error("Failed to delete user.");
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <Layout>
      <div className="flex flex-col items-center py-10 space-y-5 ">
        <div className="w-[80%] flex items-center space-x-3">
          <IconButton
            onClick={() => history.goBack()}
            disableRipple
            className="p-0 hover:bg-transparent"
          >
            <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
          </IconButton>
          <h1 className="font-bold text-[30px] text-gray1 capitalize">
            {user?.profile.firstName} {user?.profile.lastName}
          </h1>
        </div>

        <div className="w-[80%] space-y-1">
          {/* <div className="flex items-center justify-end p-3 space-x-3 bg-white rounded-md">
            <ButtonGroup className="">
              <Button className="border-[#E3E7ED]">
                <img src={pdf} alt="" />
              </Button>
              <Button className="border-[#E3E7ED]">
                <img src={print} alt="" />
              </Button>
            </ButtonGroup>

            <Button
              variant="contained"
              className="text-white capitalize shadow-none bg-orange"
              startIcon={<img src={pen} alt="" />}
            >
              Update User Info
            </Button>
          </div> */}
          <div className="p-10 space-y-4 bg-white rounded-md ">
            <div className="flex items-center justify-between">
              <h1 className="font-semibold text-gray1 text-[20px] capitalize">
                User Details
              </h1>

              <div className='flex gap-2'>
                <Button
                  variant="contained"
                  className="text-white capitalize shadow-none bg-appRed"
                  // startIcon={<img src={trash} alt="" />}
                  onClick={() => setOpen(true)}
                >
                  Delete User
                </Button>
                <Button
                  variant="contained"
                  className="text-white capitalize shadow-none bg-orange"
                  startIcon={<img src={pen} alt="" />}
                  onClick={() => history.push(`/users/edit/${user.uuid}`)}
                >
                  Update Info
                </Button>
              </div>
            </div>

            <div className="grid grid-cols-[1fr,4fr] gap-5">
              <div className="flex flex-col gap-2 items-center">
                <Avatar className="w-40 h-40" src={user?.profile.picture} />

                <div
                  className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 px-3 rounded-2xl capitalize bg-green/20 text-green`}
                >
                  <p>{user?.is_active ? 'active' : 'inactive'}</p>
                </div>
              </div>

              <div className="flex flex-col gap-4 py-5">
                <div className="flex items-center divide-x ">
                  <div className="pr-9">
                    <h1 className="text-sm capitalize text-gray3">
                      First Name
                    </h1>

                    <p className="text-gray1">{user?.profile.first_name}</p>
                  </div>

                  <div className="px-9 ">
                    <h1 className="text-sm capitalize text-gray3">Last Name</h1>

                    <p className="text-gray1">{user?.profile.last_name}</p>
                  </div>

                  <div className="px-9 ">
                    <h1 className="text-sm capitalize text-gray3">
                      Account Type
                    </h1>

                    <p className="text-gray1">
                      {user?.account_type === 'Admin'
                        ? 'Administrator'
                        : user?.account_type}
                    </p>
                  </div>

                  {/* <div className="px-9 ">
                    <h1 className="text-sm capitalize text-gray3">Password</h1>

                    <input
                      type="password"
                      value="password"
                      className="p-0 border-0 w-28"
                      disabled
                    />
                  </div> */}
                </div>

                <div className="flex items-center divide-x ">
                  <div className="pr-9">
                    <h1 className="text-sm capitalize text-gray3">
                      Email Address
                    </h1>

                    <p className="text-blue">{user?.email}</p>
                  </div>

                  <div className="px-12">
                    <h1 className="text-sm capitalize text-gray3">
                      Phone Number
                    </h1>

                    <p className="text-blue">{user?.phone_number}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="w-[80%] p-10 rounded-md space-y-4">
          <div className="">
            <h1 className="font-semibold text-gray1 text-[20px] capitalize">
              Login sessions
            </h1>
            <p className="text-sm text-gray2">
              Places where you logged into Drinks NG
            </p>
          </div>

          <div className="grid grid-cols-4">
            <h1 className="text-[12px] uppercase text-gray2 font-semibold pl-5">
              Location
            </h1>

            <h1 className="text-[12px] uppercase text-gray2 font-semibold">
              device
            </h1>

            <h1 className="text-[12px] uppercase text-gray2 font-semibold">
              ip address
            </h1>

            <h1 className="text-[12px] uppercase text-gray2 font-semibold">
              time
            </h1>
          </div>

          <div className="bg-white border divide-y rounded-md">
            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>

            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>

            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>

            <div className="grid grid-cols-4 py-2 ">
              <h1 className="pl-5 text-sm font-medium capitalize text-gray2">
                Accra
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                Safari - Mac OS
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                154.160.21.106
              </h1>

              <h1 className="text-sm font-medium capitalize text-gray2">
                May 13, 2021 12:23 AM
              </h1>
            </div>
          </div>
        </div> */}
      </div>

      <ConfirmDeleteModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={deleteUser}
      >
        <p className="text-center max-w-[40ch] mx-auto">
          You're about to delete
          <br />
          <span className="text-orange font-medium">"{user?.profile.first_name}"</span>
          <br />
          <br />
          This action is irreversible.
        </p>
      </ConfirmDeleteModal>
    </Layout>
  )
})

export default Index
