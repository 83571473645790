import { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { toast } from 'react-toastify'

//components
import Editor from '@components/TinyMCE'
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'

//assets
import editIcon from '../../assets/edit.svg'

import { getPolicy, updatePolicy, createPolicy } from '../../services/design'

const PrivacyPolicy = () => {
  const policyTitle = 'About'

  const [busy, setBusy] = useState(false)
  const [editing, setEditing] = useState(false)
  const [seeMore, setSeeMore] = useState(false)
  const [content, setContent] = useState('')
  const [policyId, setPolicyId] = useState(null)

  async function updateOrCreatePolicy() {
    try {
      toast.info()
      setBusy(true)
      toast.info('Updating About...')
      if (policyId) {
        await updatePolicy(policyId, { content })
      } else {
        await createPolicy({ title: policyTitle, content })
      }
      toast.success('About updated successfully.')
      setEditing(false)
    } catch {
      toast.error('Failed to update About.')
    } finally {
      setBusy(false)
    }
  }

  async function getPrivacyPolicy() {
    try {
      const data = await getPolicy(policyTitle)
      setPolicyId(data.id)
      setContent(data.content)
    } catch {}
  }

  useEffect(() => {
    getPrivacyPolicy()
  }, [])

  const disabled = !content

  return (
    <Layout>
      <div className="w-[950px] mx-auto pb-20">
        <PageTitle title="Design" subtitle="About Drinks NG" />
        <p className="text-[#040A1D] text-[16px] font-semibold mb-2">
          About Preview
        </p>
        <div className="px-[30px] pt-[40px] pb-[95px] bg-white rounded shadow-sm text-[16px] relative">
          {editing ? (
            <Editor
              value={content}
              onChange={setContent}
              init={{ height: '700px' }}
            />
          ) : (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: content || 'No content',
                }}
                className={`m-0 ${!seeMore && 'line-clamp-6'}`}
              />
              {!content.length ? null : (
                <p
                  className="uppercase text-appBlue text-[18px] font-semibold m-0 cursor-pointer"
                  onClick={() => setSeeMore(!seeMore)}
                >
                  {seeMore ? 'read less' : 'read more'}
                </p>
              )}
            </>
          )}

          <button
            onClick={() => setEditing(true)}
            className="border border-[#E3E7ED] rounded px-3 py-2 absolute bottom-[20px] right-[20px]"
          >
            <img src={editIcon} alt="" />
          </button>
        </div>
        {editing && (
          <div className="flex flex-row items-center justify-end space-x-4 mt-[30px]">
            <Button
              variant="outlined"
              className="capitalize bg-white text-[16px] text-[#4C536A] font-semibold"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              className={`capitalize ${
                busy || disabled ? 'bg-orange/20' : 'bg-orange'
              } 
              text-white font-semibold text-[16px]`}
              disabled={disabled}
              onClick={updateOrCreatePolicy}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
