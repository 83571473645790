import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

//mui
import { IconButton } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import AddNewProductForm from "../../components/AddNewProductForm/AddNewProductForm";

const ProductRequestEdit = () => {
  const history = useHistory();

  return (
    <Layout>
      <div className="flex flex-col items-center py-10 space-y-5 ">
        <div className="w-[70%] flex items-center space-x-3">
          <IconButton
            onClick={() => history.goBack()}
            disableRipple
            className="p-0 hover:bg-transparent"
          >
            <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
          </IconButton>
          <h1 className="font-bold text-[30px] text-gray1">
            Product Request Form
          </h1>
        </div>

        <AddNewProductForm request={true} />
      </div>
    </Layout>
  );
};

export default ProductRequestEdit;
