const PaymentMethod = ({
  checked,
  handleCheck = () => {},
  handleChange = () => {},
  image,
  value,
}) => {
  return (
    <div
      className={`w-full flex items-center justify-between py-4 px-5 border border-[#F4F4F6] ${
        checked ? "bg-tint" : " "
      }`}
    >
      <div className="flex gap-7 items-center">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheck}
          className={`rounded-sm w-5 h-5 ${checked ? "bg-green" : ""}`}
        />
        <img src={image} alt="" />
      </div>
      <div
        className="flex flex-col rounded-md bg-grayBg1"
        style={{ width: "323px", padding: "9px 20px", height: "60px" }}
      >
        <p className="mb-0 text-sm text-gray2">Amount</p>
        <div className="flex items-center">
          <p className="mb-0 text-lg font-medium text-gray">₦</p>
          <input
            type="number"
            name="amount"
            className="w-[200px] border-none outline-none bg-transparent text-lg font-medium text-gray"
            placeholder="0"
            value={value}
            onChange={(e) => {
              handleChange(e);
            }}
            style={{ height: "23px", border: "none" }}
          />
        </div>
      </div>
    </div>
  );
};
export default PaymentMethod;
