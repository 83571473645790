import React, { useState, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Editor from "@components/TinyMCE";
import { IoArrowUp } from "react-icons/io5";

//icons
import brandIcon from "../../assets/brands.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";

//mui
import { Button, ButtonGroup } from "@material-ui/core";

//components
import UnsavedChanges from "../../components/UnsavedChanges/UnsavedChanges";
import { RootContext } from "../..";
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import SaveModal from "../../components/SaveModal/SaveModal";
import CustomMultiSelectSearch from "../../components/CustomMultiSelect/CustomMultiSelectSearch";

// services
import {
  getProducts,
  searchProducts_,
  updateProduct,
} from "../../services/products";
import {
  getSingleBrand,
  getSingleBrandProducts,
  updateBrand,
  getBrands
} from "../../services/brands";
import Slider from "react-slick";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const sliderSettings = {
  dots: true,
  dotsClass: "custom-slick-dots",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0,
};

const EditBrand = () => {
  const root = useContext(RootContext);
  const params = useParams();

  const history = useHistory();

  const [saving, setSaving] = useState(false);
  const [busy, setBusy] = useState(false);

  //modal state
  const [modalOpen, setmodalOpen] = useState(false);

  //brands
  const [brands, setBrands] = useState([]);

  //brand details
  const [brand, setBrand] = useState();
  const [brandName, setBrandName] = useState("");
  const [description, setDescription] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [brandBanner, setBrandBanner] = useState("");
  const [officialStore,setOfficialStore]=useState({name:'',value:''})

  // products
  const [products, setProducts] = useState([]);
  const [brandProducts, setBrandProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [controller, setController] = useState(null)


  const fetchData = async () => {
    setBusy(true);
    try {
        await getSingleBrand(params.brand).then(async (res) => {
              const {data} = res;
              setBrand(data);
              setBrandName(data.name);
              setDescription(data.description);
              setBrandLogo(data.logo);
              setBrandBanner(data.cover_photo);

              const req = await getSingleBrandProducts(data.uuid);
              const items = req.data.results.map((product) => ({
                label: (
                    <div className="flex gap-2">
                      <img src={product.image} alt="" className="w-6"/>
                      <span>{product.name}</span>
                    </div>
                ),
                value: product.uuid,
                ...product,
              }));

              setSelectedProducts(items);
              setBrandProducts(items);
          });

      // get all products
      await getProducts().then((res) => {
        const items = res.data.results.map((product) => ({
          label: (
            <div className="flex gap-2">
              <img src={product.image} alt="" className="w-6" />
              <span>{product.name}</span>
            </div>
          ),
          value: product.uuid,
          ...product,
        }));
        setProducts(items);
      });

      setBusy(false);

    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch brand data.");
      // history.goBack();
    }
  };

  const fetchBrands=async ()=>{
    try {
      const res = await getBrands({paginate:'no'});
      const brandItems = res.data.map((brand) => ({
        name: brand.name,
        value: brand.id,
      }));

      setBrands(brandItems);

      setBusy(false);
    } catch (error){

        toast.error("Error fetching brands.");
        history.goBack();
        setBusy(false);

    }
  }

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("name", brandName);
    formData.append("description", description);
    if(officialStore!== null) formData.append("parent",officialStore.value)

    if (typeof brandLogo === "object" && brandLogo !== null) {
      formData.append("logo", brandLogo);
    }

    if (typeof brandBanner === "object" && brandBanner !== null) {
      formData.append("cover_photo", brandBanner);
    }

    setSaving(true);
    setBusy(true);
    try {
      await updateBrand(brand.uuid, formData).then((res) => {
        try {
          const isSameItem = (a, b) => a.value === b.value;

          const onlyInLeftArray = (left, right, compareFunction) =>
            left.filter(
              (leftValue) =>
                !right.some((rightValue) =>
                  compareFunction(leftValue, rightValue)
                )
            );

          const discardedProducts = onlyInLeftArray(
            brandProducts,
            selectedProducts,
            isSameItem
          );

          if (selectedProducts.length > 0) {
            selectedProducts.forEach(async (product) => {
              const data = { brand: [brand.id] };

              await updateProduct(data, product.value);
            });
          }

          if (discardedProducts.length > 0) {
            discardedProducts.forEach(async (product) => {
              const data = { brand: [] };
              await updateProduct(data, product.value);
            });
          }
        } catch (error) {
          setSaving(false);
          toast.error("An error occurred. Unable to update brand products.");
        }
      });
      setBusy(false);
    } catch (error) {
      setSaving(false);
      toast.error("An error occurred. Unable to update brand.");
    }
  };

  const fetchSearchProduct = async (value) => {
    try {
      const res = await searchProducts_(value);

      const items = res.data.results.map((product) => ({
        label: (
          <div className="flex gap-2">
            <img src={product.image} alt="" className="w-6" />
            <span>{product.name}</span>
          </div>
        ),
        value: product.uuid,
        ...product,
      }));

      return items;
    } catch (error) {
      toast.error("An error occurred. Unable to search product.");
    }
  };

  useEffect(() => {
    const fetchAllData=async()=>{
      await Promise.all([
            fetchData(),
            fetchBrands()
          ]);
    }
    fetchAllData();

    return ()=> {
      setBrand(null)
      setOfficialStore({})
      setBrands([])
    }
  }, []);

  const brandSelect=useMemo(()=>{


    if(brands.length>0) {
      let currentBrand=brands?.filter(item=>item.value===brand?.parent);
      let parentBrand=currentBrand.length>0 ? officialStore.name!==''? officialStore: currentBrand.map(item=>({name:item.name,value:item.value}))[0]
                                            :officialStore;

      return <CustomSelect
                label={'Select a parent store'}
                name="official-store-1"
                id="official-store-1"
                labelKey={'name'}
                subCategory
                value={parentBrand}
                options={brands}
                setValue={setOfficialStore}
            />
    }
    else{
      return <>Loading Brands</>
    }

  },[brands,officialStore,brand])


  const productSelect=useMemo(()=>{
    if(products.length>0){
      return <CustomMultiSelectSearch
                mode="multiple"
                value={selectedProducts}
                placeholder="Select products"
                fetchOptions={fetchSearchProduct}
                data={products}
                onChange={(newValue) => {
                  setSelectedProducts(newValue);
                }}
                style={{
                  width: "100%",
                }}
            />
    }else{
      return <>Loading Products</>
    }
  },[products,selectedProducts])

  return (
    <Layout>
      {busy && !brand && products.length === 0? (
        <Loader />
      ) : (
        <div className="px-[30px] py-5  w-[70%] mx-auto">
          <PageTitle
            onBackPress={() => history.goBack()}
            title={brand ? brand.name : "Brnad name"}
          />
          <p className="text-[#040A1D] text-[16px] font-semibold">
            Banner Preview
          </p>
          {brandBanner ? (
            <div>
              <Slider {...sliderSettings}>
                <div>
                  <img
                    src={
                      typeof brandBanner === "object"
                        ? URL.createObjectURL(brandBanner)
                        : brandBanner
                    }
                    alt="img"
                    className="w-full object-cover"
                    style={{
                      aspectRatio: "1440/428",
                      objectPosition: "left top",
                    }}
                  />
                </div>
              </Slider>
            </div>
          ) : (
            <p>Preview of selected image will appear here</p>
          )}
          <p className="text-[#4C536A] text-[14px] mb-1 text-left w-[70%]">
            Regular banner image size sholud be&nbsp;
            <span className="font-bold"> 1440x428 </span> pixels
          </p>
          <div className="bg-white p-5 my-5">
            <div
              className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-36 w-40"
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                setBrandBanner(e.dataTransfer.files[0]);
              }}
            >
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
              </div>
              <label
                htmlFor="banner-image"
                className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
              >
                <p className="mb-0">Upload File</p>
                <input
                  type="file"
                  id="banner-image"
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    setBrandBanner(e.target.files[0]);
                  }}
                  className="hidden"
                />
              </label>

              <p className="text-sm text-gray2">or drop files to upload</p>
            </div>
          </div>
          <div className="bg-white p-5 space-y-5 my-5">
            <div className="relative flex items-end bg-[#F4F5F7] rounded-md w-full h-14">
              <input
                id="manager-search"
                placeholder="name"
                onChange={(e) => setBrandName(e.target.value)}
                value={brandName}
                className="w-full h-10 pl-4 font-medium placeholder-transparent transition duration-500 ease-in-out bg-transparent border-b-2 border-transparent text-gray1 focus:ring-0 peer focus:outline-none focus:border-b-blue caret-blue"
              />
              <label
                htmlFor="manager-search"
                className="absolute left-4 top-[5px] text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray2 peer-placeholder-shown:top-4 peer-focus:top-[5px] peer-focus:text-blue peer-focus:text-sm"
              >
                Name
              </label>
            </div>
          </div>

          <div className="bg-white p-5 space-y-5">
            <div className="relative flex flex-col  bg-[#F4F5F7] rounded-md w-full p-5">
              <label className="mb-3 font-medium text-[#3D4356]">
                Brand Description
              </label>

              <Editor
                init={{
                  height: 300,
                }}
                value={description}
                onChange={setDescription}
              />
            </div>
          </div>

          <div className="bg-white p-5 grid grid-cols-2 gap-10 space-y-5 mt-5 ">
            <div>
              <h1 className="mb-3 font-medium text-[#3D4356]">Brand Logo</h1>
              <div className="flex items-center space-x-5">
                {brandLogo ? (
                  <div className="w-60">
                    <div className="relative border border-gray-300 border-dashed rounded-md">
                      <div className="absolute left-0 flex justify-center w-full bottom-10">
                        <ButtonGroup className="bg-white">
                          <Button
                            onClick={() => {
                              setBrandLogo("");
                            }}
                          >
                            <img src={deleteIcon} alt="" />
                          </Button>
                          <Button>
                            <label
                              htmlFor="business-logo"
                              className="w-full h-full cursor-pointer"
                            >
                              <img src={editIcon} alt="" />
                              <input
                                type="file"
                                id="business-logo"
                                multiple
                                accept=".png,.jpeg,.jpg"
                                onChange={(e) => {
                                  setBrandLogo(e.target.files[0]);
                                }}
                                className="hidden"
                              />
                            </label>
                          </Button>
                        </ButtonGroup>
                      </div>

                      <img
                        src={
                          typeof brandLogo === "object"
                            ? URL.createObjectURL(brandLogo)
                            : brandLogo
                        }
                        alt=""
                        className="object-cover w-full h-full rounded-md"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      setBrandLogo(e.dataTransfer.files[0]);
                    }}
                  >
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                      <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                    </div>
                    <label
                      htmlFor="business-logo"
                      className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                    >
                      <p className="mb-0">Upload File</p>
                      <input
                        type="file"
                        id="business-logo"
                        multiple
                        accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          setBrandLogo(e.target.files[0]);
                        }}
                        className="hidden"
                      />
                    </label>

                    <p className="text-sm text-gray2">
                      or drop files to upload
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className=" bg-white p-5 space-y-5 mt-5">
            <h1 className="mb-3 font-medium text-[#3D4356]">
              Official Store
            </h1>
            {brandSelect}
          </div>

          <div className="bg-white p-5 space-y-5 mt-5">
            {productSelect}
          </div>
          <div className="flex justify-end items-center space-x-3 mt-5">
            <Button
              variant="contained"
              className="capitalize bg-white shadow-none"
              onClick={() => setmodalOpen(true)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`text-white capitalize shadow-none ${
                description === "" || brandName === ""
                  ? "bg-orange/30 cursor-not-allowed"
                  : "bg-orange cursor-pointer"
              }`}
              onClick={handleSave}
              disabled={description === "" || brandName === ""}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      <SaveModal
        isOpen={saving}
        close={() => setSaving(false)}
        icon={brandIcon}
        update={true}
        progressLabel={"Updating Brand"}
        successLabel={"Brand Updated"}
        busy={busy}
      />
      <UnsavedChanges isOpen={modalOpen} close={() => setmodalOpen(false)} />
    </Layout>
  );
};

export default EditBrand;
