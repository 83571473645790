import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import useTableMethods from '../../customHooks/useTableMethods'

//icons
// import edit from '../../assets/edit.svg'
// import view from '../../assets/view.svg'
import loader from '../../assets/loader.svg'
import { formatDate } from '../../utils/index'

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  // Checkbox,
  // ButtonGroup,
  // Button,
  Avatar,
  TablePagination,
} from '@material-ui/core'

//components
import TableHead from './CustomTableHead'

const VouchersTable = ({
  rows,
  search = '',
  notFound = false,
  busy = false,
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}) => {
  const history = useHistory()

  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows)

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <TableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{' '}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <TableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />

          <TableBody className="bg-white">
            {rows.map((row, index) => {
              // const isItemSelected = isSelected(index)

              return (
                <TableRow
                  hover
                  // role="checkbox"
                  // aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  // selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell> */}
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.code}
                  </TableCell>
                  <TableCell>{row.order_id || '-'}</TableCell>
                  <TableCell className=" text-sm font-medium text-gray2">
                    <div className="flex items-center space-x-2">
                      <Avatar
                        className="w-8 h-8"
                        src={row.user?.profile?.picture}
                      />
                      <span>
                        {row.user
                          ? [
                              row.user.profile.first_name,
                              row.user.profile.last_name,
                            ]
                              .filter(Boolean)
                              .join(' ')
                          : ''}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.is_issued ? formatDate(row.updated_at) : '-'}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`px-3 py-1 w-[fit-content] rounded-lg ${
                        row.is_issued
                          ? 'bg-green/20 text-green'
                          : 'bg-blue/20 text-blue'
                      }`}
                    >
                      {row.is_issued ? 'Issued' : 'Pending'}
                    </span>
                  </TableCell>

                  {/* <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button
                        onClick={() => history.push(`/brand/edit/${row.uuid}`)}
                      >
                        <img src={edit} alt="" />
                      </Button>
                      <Button
                        onClick={() => history.push(`/brand/${row.uuid}`)}
                      >
                        <img src={view} alt="" />
                      </Button>
                    </ButtonGroup>
                  </TableCell> */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 40]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1)
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value)
          }}
        />
      )}
    </div>
  )
}

export default VouchersTable
