import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { toast } from "react-toastify";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import PaymentChannel from "../../components/PaymentChannel/PaymentChannel";
import Loader from "@components/Loader/Loader";
import { RootContext } from "../..";

//assets
import DateActions from "../../components/DateActions/DateActions";
import FinanceCompactGraph from "../../components/FinanceCompactGraph/FinanceCompactGraph";

//services
import { getTransactionsByPeriod, getTransactionsByDateRange } from "../../services/finance";

const Index = () => {
  const { authStore: { user } } = useContext(RootContext);
  const [busy, setBusy] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [pending, setPending] = useState([]);
  const [completed,  setCompleted] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [total, setTotal] = useState(0);
  const [grossData, setGrossData] = useState([0,0,0,0,0,0,0,0,0,0]);
  const [activePeriod, setActivePeriod] = useState("today");
  const [cashData, setCashData] = useState({total: 0, percentage: 0});
  const [chequeData, setChequeData] = useState({total: 0, percentage: 0});
  const [digitalData, setDigitalData] = useState({total: 0, percentage: 0});
  const [dateRange, setDateRange] = useState({start: moment(), end: moment()})

  const tickCallback = (value, index, values) => {
    const date = new Date();
    const hour = date.getHours();
    if (compareHours(hour, value, index)) return `Now, ${value}`;
    else if (value === "12:00am") return "12:00am";
    else if (value === "11:59pm") return "11:59pm";
    return null;
  };

  const compareHours = (hour, tickValue, tickIndex) => {
    const tickHour = parseInt(tickValue.split(":")[0]);
    if (tickIndex === 0 && hour === 0) return true;
    else if (tickIndex <= 12 && tickHour === hour) return true;
    else if (tickIndex > 12 && tickHour + 12 === hour) return true;
    return false;
  };

  const computeTotal = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.order.final_cost.amount);
    const total = data.reduce(amountReducer, 0);
    setTotal(total);
  }

  const sortTransactionsByStatus = (data) => {
    const pending = data.filter((transaction) => transaction.order.status === "Pending");
    const cancelled = data.filter((transaction) => transaction.order.status === "Cancelled");
    const completed = data.filter((transaction) => transaction.order.status === "Completed");

    setPending(pending);
    setCancelled(cancelled);
    setCompleted(completed);
  }

  const parseChequeData = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.order.final_cost.amount);
    const chequeTransactions = data.filter((transaction) => transaction.payment_channel === "Cheque");
    const chequeTotal = chequeTransactions.reduce(amountReducer, 0);
    const chequePercentage = (chequeTransactions.length / data.length) * 100;
    setChequeData({total: chequeTotal, percentage: chequePercentage.toFixed(0)});
  }

  const parseDigitalData = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.order.final_cost.amount);
    const digitalTransactions = data.filter((transaction) => transaction.payment_channel === "Paystack");
    const digitalTotal = digitalTransactions.reduce(amountReducer, 0);
    const digitalPercentage = (digitalTransactions.length / data.length) * 100;
    setDigitalData({total: digitalTotal, percentage: digitalPercentage.toFixed(0)});
  }

  const parseCashData = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.order.final_cost.amount);
    const cashTransactions = data.filter((transaction) => transaction.payment_channel === "Cash");
    const cashTotal = cashTransactions.reduce(amountReducer, 0);
    const cashPercentage = (cashTransactions.length / data.length) * 100;
    setCashData({total: cashTotal, percentage: cashPercentage.toFixed(0)});
  }

  const parseHourlyData = (data) => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.order.final_cost.amount);
    let graphData = [];

    for(let i = 0; i < 24; i++) {
      const hourArray = data.filter((transaction) => new Date(transaction.order.created_at).getHours() === i);
      if(hourArray.length > 0) {
        const total = hourArray.reduce(amountReducer, 0);
        graphData.push(total)
      } else {
        graphData.push(0)
      }
    }
    setGrossData(graphData);
  }

  // generate data for gray graph
  const generateGrayData = () => {
    const currentHour = new Date().getHours();

    //select data up until the current hour;
    const coloredData = grossData.slice(0, currentHour + 1);
    
    //rest of data that will be gray
    const grayData = grossData.slice(currentHour + 1);

    const grayArray = [];
    for(let i = 0; i < coloredData.length; i++) {
      grayArray.push(0);
    }

    const newGrossData = grossData.map((data, index) => {
      if(index <= currentHour)
        return data;
      return null;
    })

    grayArray.push(...grayData);
    let graphData = {...chartData};
    graphData.datasets[0].data = [...newGrossData];
    graphData.datasets[1].data = [...grayArray];
    setChartData(graphData);
  }

  useEffect(() => {
    if(grossData.length > 0)
      generateGrayData();
  }, [grossData]);


  const handleDateRangeChange = async(range) => {
    setDateRange(range);
    setBusy(true);
    try {
      const { data } = await getTransactionsByDateRange(user.retailerUuid, range.end.format('YYYY-MM-DD'), range.start.format('YYYY-MM-DD'))
        parseHourlyData(data.results);
        parseCashData(data.results);
        parseChequeData(data.results);
        parseDigitalData(data.results);
        sortTransactionsByStatus(data.results);
        setTransactions(data.results);
        setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch requested data.")
    }
  }

  const filterByPeriod = async() => {
    setBusy(true);
    try {
      const { data } = await getTransactionsByPeriod(user.retailerUuid, activePeriod);
      parseHourlyData(data.results);
      parseCashData(data.results);
      parseChequeData(data.results);
      parseDigitalData(data.results);
      sortTransactionsByStatus(data.results);
      setTransactions(data.results);
      computeTotal(data.results);
      setBusy(false);
    } catch (error) {
      toast.error("An error occurred. Unable to fetch requested data.");
      setBusy(false);
    }
  }

  useEffect(() => {
    filterByPeriod();
  }, [activePeriod])

  const chartOptions = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            callback: tickCallback,
            maxRotation: 0,
            minRotation: 0,
            fontColor: ["#9CA7B8"],
            beginAtZero: false,
            autoSkip: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  const [chartData, setChartData] = useState({
    labels: [
      "12:00am", "1:00am", "2:00am", "3:00am", "4:00am", "5:00am", "6:00am", "7:00am",
      "8:00am", "9:00am", "10:00am", "11:00am", "12:00pm", "1:00pm", "2:00pm", "3:00pm",
      "4:00pm", "5:00pm", "6:00pm", "7:00pm", "8:00pm", "9:00pm", "10:00pm", "11:00pm",
      "11:59pm",
    ],
    datasets: [
      {
        data: [0,0,0,0,0,0,0,0,0,0],
        fill: true,
        backgroundColor: "rgba(243, 100, 31, 0.2)",
        borderColor: ["#F3641F"],
        borderWidth: 2,
        tension: 0,
        pointStyle: "line",
      },
      {
        data: [
          null, null, null, null, null, null, null, null, null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          18, 33, 28, 38, 24, 56,
        ],
        fill: true,
        backgroundColor: "#EEF2F5",
        borderColor: "#EEF2F5",
        tension: 0,
        pointStyle: "line",
      },
    ],
  });

  const datasetKeyProvider=()=>{ 
    return Date.now().toString();
  }

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <div className="px-[30px] pt-5 font-bold">
          <PageTitle title="Finance Overview" />
          <div className="w-full h-[346px] px-[12px] py-[20px] flex flex-row bg-white rounded-md">
            <div className="flex flex-col w-[65%]">
              <p className="text-[#3D4356] text-[16px] font-normal">
                <span className="font-bold capitalize">{activePeriod}</span> - Sales
              </p>
              <div className="flex flex-row items-center">
                <p className="text-[#040A1D] font-bold text-[26px]">
                  ₦{Number(total).toLocaleString("en")}
                </p>
                {/* {changeValue && (
                  <span className="flex flex-row items-center ml-2">
                    {changeType === "rise" ? (
                      <img
                        src={greenArrow}
                        alt="green arrow pointing up"
                        className="w-5 h-6 lg:w-4 lg:h-5"
                      />
                    ) : (
                      <img
                        src={redArrow}
                        alt="red arrow pointing down"
                        className="w-5 h-6"
                      />
                    )}
                    <p
                      className={`${
                        changeType === "rise" ? "text-appGreen" : "text-appRed"
                      } font-medium lg:text-[14px]`}
                    >
                      {changeValue}%
                    </p>
                  </span>
                )} */}
              </div>
              <div className="h-[245px]">
                <Line
                  data={chartData}
                  options={chartOptions}
                  id="chart-finance"
                  className="chart-canvas"
                  height={null}
                  width={null}
                  redraw={true}
                  datasetKeyProvider={datasetKeyProvider}
                />
              </div>
            </div>
            <div className="flex flex-col w-[35%] ml-4">
              <p className="text-[#3D4356] text-[16px] font-medium">
                Payment Methods
              </p>
              <div className="w-full h-[1px] bg-[#EEF2F5] mt-2 mb-4"></div>
              <PaymentChannel
                label="Cash"
                amount={cashData.total}
                percentage={cashData.percentage}
              />
              <PaymentChannel
                label="Cheque"
                amount={chequeData.total}
                percentage={chequeData.percentage}
              />
              <PaymentChannel
                label="Digital/Mobile Wallet"
                amount={digitalData.total}
                percentage={digitalData.percentage}
              />
            </div>
          </div>
          <div className="py-10">
            <div className="flex-col w-full pt-1 px-[20px] pb-0 mt-6 mb-6 bg-white rounded-md">
              <DateActions
                activePeriod={activePeriod}
                changePeriod={setActivePeriod}
                className="mt-4"
                dateRange={dateRange}
                onDateRangeSelect={handleDateRangeChange}
              />
              <div className="w-full h-[1px] bg-[#E3E7ED] mt-2"></div>
              <div className="flex flex-row">
                <FinanceCompactGraph
                  changeType={"rise"}
                  changeValue={35}
                  label={"Revenue"}
                  borderColor={"#F3641F"}
                  backgroundColor={"rgba(243, 100, 31, 0.1)"}
                  data={transactions}
                />
                <FinanceCompactGraph
                  changeType={"rise"}
                  changeValue={35}
                  label={"Completed Orders"}
                  borderColor={"#38C985"}
                  backgroundColor={"rgba(56, 201, 133, 0.1)"}
                  count={true}
                  data={completed}
                  border={true}
                />
              </div>
              <div className="w-full h-[1px] bg-[#E3E7ED]"></div>
              <div className="flex flex-row">
                <FinanceCompactGraph
                  changeType={"fall"}
                  changeValue={35}
                  label={"Pending Orders"}
                  borderColor={"#477DFB"}
                  backgroundColor={"rgba(71, 125, 251, 0.1)"}
                  count={true}
                  data={pending}
                />
                <FinanceCompactGraph
                  changeType={"rise"}
                  changeValue={35}
                  label={"Cancelled Orders"}
                  borderColor={"#FF3D22"}
                  backgroundColor={"rgba(255, 61, 34, 0.1)"}
                  border={true}
                  count={true}
                  data={cancelled}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Index;
