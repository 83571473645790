import AuthStore from "./auth";
import Products from "./products";
import Retailers from "./retailers";
import Users from "./users";
import Inventory from "./inventory";
import Orders from "./orders";

export default class RootStore {
  authStore;
  productsStore;
  ordersStore;
  retailersStore;
  usersStore;
  inventoryStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.productsStore = new Products(this);
    this.retailersStore = new Retailers(this);
    this.usersStore = new Users(this);
    this.inventoryStore = new Inventory(this);
    this.ordersStore = new Orders(this);
    this.env = {
      googleAPIKey: process.env.REACT_APP_GOOGLE_API_KEY,
    }
  }
}
