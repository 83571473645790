import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// icons
import pen from "../../assets/pen.svg";

//mui
import { Button, Avatar } from "@material-ui/core";
import { List } from "antd";

import { copyToClipboard } from '@/utils'

const BrandProfile = ({ brand, brandProducts }) => {
  const history = useHistory();
  const [viewId, setViewId] = React.useState(true)

  const copyId = async () => {
    const text = viewId ? brand.id : brand.uuid
    await copyToClipboard(text)
    toast.success(`Copied brand id "${text}"`)
  }

  return (
    <div className="p-10 space-y-4 bg-white divide-y rounded-md">
      <div className="pb-10 space-y-5">
        <div>
          <p className="text-sm mb-1">
            Click the text beside the button to switch between&nbsp;
            <span className={`font-semibold ${viewId ? 'text-orange' : ''}`}>id</span> and&nbsp;
            <span className={`font-semibold ${!viewId ? 'text-orange' : ''}`}>uuid</span>
          </p>
          <div className="bg-[#F4F5F7] rounded-lg p-1 pr-2 flex items-center mb-5 w-[fit-content]">
            <button className="bg-white p-1 rounded-md" onClick={copyId}>Copy</button>
            <span className="ml-3 font-bold" style={{ 'cursor': 'default' }} onClick={() => setViewId(!viewId)}>{ viewId ? brand.id : brand.uuid }</span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <h1 className="font-semibold text-gray1 text-[20px] capitalize">
            Brand Information
          </h1>

          <Button
            variant="contained"
            className="text-white capitalize shadow-none bg-orange"
            startIcon={<img src={pen} alt="" />}
            onClick={() => history.push(`/brand/edit/${brand.uuid}`)}
          >
            Update Info
          </Button>
        </div>

        <div className="grid grid-cols-[5fr,1fr] gap-10">
          <div className="flex flex-col justify-between py-5 space-y-10">
            <div className="flex items-center divide-x ">
              <div className="pr-12">
                <h1 className="text-sm capitalize text-gray3">Brand Name</h1>

                <p className="text-gray1">{brand.name}</p>
              </div>
            </div>

            <div className="flex items-center divide-x ">
              <div className="">
                <h1 className="text-sm capitalize text-gray3">Description</h1>

                <p
                  className="text-gray1"
                  dangerouslySetInnerHTML={{ __html: brand.description }}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <h1 className="text-sm capitalize text-gray3">Products</h1>
              <div className="h-60 overflow-auto w-[60%] mt-1">
                <List
                  size="small"
                  bordered
                  dataSource={brandProducts}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.image}>N/A</Avatar>}
                        title={item.name}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <span className="text-xs text-gray3 opacity-50">
                please scroll to view more products
              </span>
            </div>
          </div>

          <div className="flex items-center h-full">
            <Avatar className="object-contain w-52 h-52" src={brand.logo}>
              SM
            </Avatar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandProfile;
