import { IoCaretDown, IoCaretUp } from 'react-icons/io5'
import { preventNonNumericalInput } from '../../utils'
import { Button, Checkbox } from '@material-ui/core'
import loader from '../../assets/loader.svg'

const InventoryInput = ({
  title,
  subtitle,
  field,
  value,
  hook,
  busy,
  type,
  min = 0,
  max = Infinity,
  placeholder = '',
  marginX = 'pr-5',
  margin = '',
  padding = 'p-2',
  width = '200px',
  pdL = 'px-2',
  pdX = '',
  disabled,
  justify = '',
  handleChange = () => {},
  handleSubmit = () => {},
}) => {
  const isMaxReached = value >= max
  const isMinReached = value <= min

  const handleKeyPress = (e) => {
    const isNumberKey = /^[0-9-]$/.test(e.key)
    const inputValue = e.target.value + e.key

    const isValidInput =
      parseInt(inputValue, 10) <= max &&
      parseInt(inputValue, 10) >= min

    if (!isNumberKey || !isValidInput) {
      e.preventDefault()
    }
  }

  return (
    <>
      <div className="flex items-center space-x-20">
        <div style={{ width: width }}>
          <h6 className="text-sm capitalize text-gray3 w-[100px]">{title}</h6>
          {type === 'price' ? (
            <p className="font-bold text-[18px] text-gray1">
              ₦
              {field === null || field === undefined
                ? '0'
                : Number(field).toLocaleString('en')}
            </p>
          ) : type === 'quantity' ? (
            <p className="font-bold text-[18px] text-gray1">
              {field} {value !== 0 && '+'}{' '}
              {value !== 0 && <span className="text-orange">{value}</span>}{' '}
              {value !== 0 && '='}{' '}
              {value !== 0 && (
                <span className="text-green">{field + value}</span>
              )}
            </p>
          ) : type === 'percentage' ? (
            <p className="font-bold text-[18px] text-gray1">{Number(field)}</p>
          ) : (
            <p className="font-bold text-[18px] text-gray1">
              {value ? 'Yes' : 'No'}
            </p>
          )}
        </div>

        <div
          className={`flex items-center bg-[#F4F5F7] ${padding} border-b-blue border-b-2 w-72 rounded-tl-md rounded-tr-md ${
            type !== 'quantity' && 'justify-between'
          } ${justify}`}
        >
          <div className="w-[50%]">
            <h6 className="text-sm capitalize text-blue">{subtitle}</h6>
            {type === 'price' ? (
              <input
                id="price"
                value={value}
                onChange={handleChange(hook)}
                placeholder={placeholder}
                onKeyPress={preventNonNumericalInput}
                className="bg-transparent text-[18px] font-bold w-full"
              />
            ) : type === 'quantity' ? (
              <input
                type="number"
                min={min}
                max={max}
                className={`w-full ${pdL} bg-transparent border-0 shadow-none outline-none focus:outline-none focus:border-0 focus:ring-transparent font-bold text-[18px] text-gray-1`}
                onKeyPress={handleKeyPress}
                value={value}
                onChange={(e) => handleChange(e.target.value, hook)}
              />
            ) : type === 'percentage' ? (
              <input
                id="dicount"
                type="number"
                min="0"
                value={value}
                onChange={(e) => hook(e.target.value)}
                className={`bg-transparent text-[18px] font-bold w-full border-0 ${pdX}`}
              />
            ) : (
              <Checkbox checked={value} onClick={() => hook(!value)} />
            )}
          </div>

          {type === 'quantity' && (
            <div className="flex flex-col w-10 space-y-1">
              <button
                className={`inline-flex justify-center rounded-md bg-[#E3E7ED] ${
                  isMaxReached ? 'cursor-default' : ''
                }`}
                onClick={() => hook(value + 1)}
                disabled={isMaxReached}
              >
                <IoCaretUp
                  className={`text-gray3 ${isMaxReached ? 'text-gray1' : ''}`}
                />
              </button>
              <button
                className={`inline-flex justify-center rounded-md bg-[#E3E7ED] ${
                  isMinReached ? 'cursor-default' : ''
                }`}
                onClick={() => hook(value - 1)}
                disabled={isMinReached}
              >
                <IoCaretDown
                  className={`text-gray3 ${isMinReached ? 'text-gray1' : ''}`}
                />
              </button>
            </div>
          )}

          <div className={`ml-3 ${margin} ${type !== 'quantity' && marginX}`}>
            <Button
              variant="contained"
              className={`text-white capitalize flex ${
                value === '' || value === 0 || value === '₦' || (busy && !disabled)
                  ? 'bg-orange/20'
                  : 'bg-orange'
              } relative`}
              onClick={handleSubmit}
              disabled={disabled}
            >
              Save
              {busy && !disabled && (
                <img src={loader} alt="" className="w-5 animate-spin ml-1" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
export default InventoryInput
