import React from "react";

//mui
import { Button } from "@material-ui/core";

const TabButton = ({ title, onClick, activeTab, tab = title }) => {
  return (
    <Button
      variant="outlined"
      className={`py-3 capitalize  hover:bg-transparent rounded-none border-t-[4px] border-b-[4px] border-l-0 border-r-0 min-w-0 px-0 text-base ${
        activeTab.toLowerCase() === tab.toLowerCase()
          ? "border-b-orange border-t-transparent  text-gray1"
          : "border-transparent text-gray3"
      }`}
      disableRipple
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export default TabButton;
