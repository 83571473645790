export function jsonToFormData(object, output, prefix = '') {
    const formData = output || new FormData()
  
    for (let key of Object.keys(object)) {
      const value = object[key]
      if (value === null || value === undefined) continue
      key = prefix ? `${prefix}.${key}` : key
  
      const isArray = Array.isArray(value)
      const isFile = value instanceof File
  
      if (isArray) {
        value.forEach((item) => formData.append(key, item))
      } else if (value instanceof Object && !isArray && !isFile) {
        jsonToFormData(value, formData, key)
      } else if (isFile) {
        formData.set(key, value, value.name)
      } else {
        formData.append(key, value)
      }
    }
  
    return formData
  }