import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'

//mui
import { Button } from '@material-ui/core'

import FormInput from '../FormInput/FormInput'
import { RootContext } from '../..'

// import { preventNonNumericalInput } from '../../utils'

const TableTotals = observer(
  ({
    subtotal,
    shipping,
    discount,
    tax,
    ordertotal,
    delivery,
    handlePromoCodeChange,
    handleDiscountChange,
    applyCoupon,
    updating = false,
  }) => {
    const root = useContext(RootContext)

    // const preventNonNumericalInput = (e) => {
    //   e = e || window.event;
    //   var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    //   var charStr = String.fromCharCode(charCode);

    //   if (!charStr.match(/^[0-9]+$/))
    //     e.preventDefault();
    // }

    return (
      <div className="flex flex-col items-end pl-[30px] mt-[45px]">
        <span className="w-[300px] mr-10">
          <table width="100%">
            <tr>
              <th className="w-[250px]"></th>
              <th className="w-[250px]"></th>
            </tr>
            <tr className="h-[30px]">
              <td className="text-right text-[#9CA7B8] text-[12px] font-medium mb-[20px]">
                Sub Total
              </td>
              <td className="text-right">
                ₦{Number(subtotal).toLocaleString('en')}
              </td>
            </tr>
            {root.authStore.user.account_type.includes('Admin') && (
              <tr className="h-[30px]">
                <td className="text-right text-[#9CA7B8] text-[12px] font-medium">
                  Shipping
                </td>
                <td className="text-right">
                  ₦{Number(shipping).toLocaleString('en')}
                </td>
              </tr>
            )}
            {root.authStore.user.account_type.includes('Retailer') && (
              <tr className="h-[30px]">
                <td className="text-right text-[#9CA7B8] text-[12px] font-medium">
                  Delivery Cost
                </td>
                <td className="text-right">
                  ₦{Number(shipping).toLocaleString('en')}
                </td>
              </tr>
            )}
            {!updating && (
              <tr className="h-[30px]">
                <td className="text-right text-[#9CA7B8] text-[12px] font-medium">
                  Reduction
                </td>
                <td className="text-right">
                  ₦{Number(discount).toLocaleString('en')}
                  {/* <div className="bg-white rounded-md flex items-center px-2 ml-4">
                    <span>₦</span>
                    <input
                      type="text"
                      value={discount}
                      onKeyPress={preventNonNumericalInput}
                      className="w-full h-full bg-transparent border-0 min-w-0 text-right px-0"
                      onChange={(e) => handleDiscountChange(e.target.value)}
                    />
                  </div> */}
                </td>
              </tr>
            )}
            {!updating && (
              <tr className="h-[30px]">
                <td className="text-right text-[#9CA7B8] text-[12px] font-medium">
                  Order Amount
                </td>
                <td className="text-right text-[#F3641F] text-[20px]">
                  ₦{Number(ordertotal).toLocaleString('en')}
                </td>
              </tr>
            )}
          </table>
        </span>
        {updating && (
          <div className="w-full bg-white rounded shadow-sm h-[60px] flex flex-row justify-end items-center mt-4 px-4">
            <p className="text-[#4C536A] text-[14px] mr-10">Coupon Code</p>
            <div className="w-[206px] h-[40px]">
              <FormInput
                id="coupon-code"
                name="coupon-code"
                placeholder="Coupon"
                onChange={handlePromoCodeChange}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 capitalize shadow-none"
              onClick={applyCoupon}
            >
              Apply
            </Button>
          </div>
        )}
        {updating && (
          <span className="w-[300px] mr-10">
            <table width="100%">
              <tr>
                <th className="w-[250px]"></th>
                <th className="w-[250px]"></th>
              </tr>
              <tr className="h-[30px]">
                <td className="text-right text-[#9CA7B8] text-[12px] font-medium">
                  Total Order Amount
                </td>
                <td className="text-right text-[#F3641F] text-[20px]">
                  ₦{Number(ordertotal).toLocaleString('en')}
                </td>
              </tr>
            </table>
          </span>
        )}
      </div>
    )
  },
)

export default TableTotals
