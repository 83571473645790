import React from 'react';

import redArrow from "../../assets/red-arrow.svg";
import greenArrow from "../../assets/green-arrow.svg";

const SummaryCard = ({label, amount, changeType, changeValue, summaryValue, accentColor}) => {
  return (
    <div className="flex flex-row items-center w-full h-20 pr-5 bg-white rounded-lg lg:pr-2">
      <span 
        className={`${accentColor ? `${accentColor} rounded-md` : 'bg-white rounded-md'} h-full w-1 mr-5 lg:mr-2`}></span>
      <div className="flex flex-col w-full">
        <p className="text-sm text-appGray">{label}</p>
        <div className="flex flex-row justify-between w-full pt-1">
          <div className="flex flex-row w-full">
            <h2 className="font-bold text-[20px] lg:text-[16px]">{summaryValue ? Number(summaryValue).toLocaleString('en') : 0}</h2>
            {/* {changeValue && (
              <span className="flex flex-row items-center ml-2">
                {changeType === 'rise' ? (
                  <img src={greenArrow} alt="green arrow pointing up" className="w-5 h-6 lg:w-4 lg:h-5"/>
                ) : (
                  <img src={redArrow} alt="red arrow pointing down" className="w-5 h-6"/>
                )}
                <p 
                  className={`${changeType === 'rise' ? 'text-appGreen' : 'text-appRed'} font-medium lg:text-[14px]`}>
                  {changeValue}%
                </p>
              </span>
            )} */}
          </div>
          <p className="text-black text-base font-medium lg:text-[14px]">₦{amount ? Number(amount).toLocaleString('en') : 0}</p>
        </div>
      </div>
    </div>
  )
}

export default SummaryCard;