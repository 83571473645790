import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { IoChevronDownOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

//mui
import { IconButton, Popover, Avatar, Button } from "@material-ui/core";
import { RootContext } from "../..";

//assets
import loader from "../../assets/loader.svg";


const DropdownMenu = observer(() => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const root = useContext(RootContext);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    try {
      await root.authStore.signOut();
      history.push("/login");
    } catch (error) {
      toast.error("Sign out failed. Please try again later.");
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        className="p-0 hover:bg-transparent"
        onClick={handleClick}
        disableRipple
      >
        <IoChevronDownOutline className="w-5 text-gray3" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          root: "",
          paper: "bg-transparent shadow-none pt-4 pb-5",
        }}
      >
        <div className="w-[250px] rounded-md bg-white p-4">
          <div className="flex items-center space-x-2">
            <Avatar className="w-8 h-8" src={root.authStore.user.profile.picture}></Avatar>
            <div>
              <h1 className="font-medium capitalize text-gray1">
                {root.authStore.user.first_name} {root.authStore.user.last_name}
              </h1>
              <p className="text-[12px] text-gray2">{root.authStore.user.email}</p>
            </div>
          </div>

          <div className="py-2">
            <Button
              className="flex justify-start w-full p-2 text-base font-medium capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange text-gray1"
              onClick={() => history.push("/profile")}
            >
              Profile
            </Button>

            <Button
              className="flex flex-row items-center justify-between w-full p-2 text-base font-medium capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange text-gray1"
              onClick={signOut}
            >
              Sign Out
              {root.authStore.busy && (
                <img src={loader} alt="" className="w-5 animate-spin" />
              )}
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
});

export default DropdownMenu;
