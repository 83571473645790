import React, { useEffect } from 'react'

//icons
import orderIcon from '../../assets/orders-inactive.svg'
import view from '../../assets/view.svg'
import pending from '../../assets/pending.svg'
import cancelled from '../../assets/cancelled.svg'
import completed from '../../assets/completed.svg'
import processing from '../../assets/orange-clock.svg'
import delivery from '../../assets/delivery-bike.svg'
import pickup from '../../assets/package-box.svg'
import loader from '../../assets/loader.svg'

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Avatar,
  ButtonGroup,
  Button,
} from '@material-ui/core'

//components
import ReceiptModal from '@/components/ReceiptModal/ReceiptModal'
import TableHead from './CustomTableHead'
import { formatDate, formatTime } from '../../utils'
import { statuses } from '../../enums'

const RetailerOrderHistory = ({
  summary,
  data,
  busy,
  search,
  page,
  count = 0,
  rowsPerPage = 0,
  receipt,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
}) => {
  const [showReceipt, setShowReceipt] = React.useState(false)

  useEffect(() => {
    if (receipt) {
      setShowReceipt(receipt)
    }
  }, [receipt])
  return (
    <>
      <div className="w-full mt-5">
        {busy || data.length === 0 ? (
          <>
            <Table className="min-w-[750px]" size="small">
              <TableHead rowCount={count} />
            </Table>
            <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
              {busy ? (
                <img src={loader} alt="loading" className="w-5 animate-spin" />
              ) : search.length > 0 && !count ? (
                <>
                  <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                    No Search Result for "{search}"
                  </p>
                  <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                    <span className="text-[#8892A2] text-[14px] font-bold">
                      Search tips:{' '}
                    </span>
                    Some search terms require an exact match. Try typing the
                    entire term, or use a different word or phrase.
                  </p>
                </>
              ) : (
                <>
                  <img src={orderIcon} alt="" className="w-[72px] h-[72px]" />
                  <p className="text-[18px] text-[#3D4356] font-bold mt-2">
                    Transactions will show here.
                  </p>
                </>
              )}
            </div>
          </>
        ) : (
          <Table className="min-w-[750px]" size="small">
            <TableHead rowCount={count} />
            <TableBody className="bg-white">
              {data.map((row) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                  >
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.order_id}
                    </TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <Avatar
                        className="w-8 h-8"
                        src={row.user.profile.picture}
                      />
                      <p className="text-sm font-medium text-gray2 mb-0">
                        {row.user.profile.first_name}{' '}
                        {row.user.profile.last_name}
                      </p>
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.user.phone_number}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.status === statuses.DRAFTING
                        ? formatDate(row.created_at)
                        : row.payment_date
                        ? formatDate(row.payment_date)
                        : '-'}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.status === statuses.DRAFTING
                        ? formatTime(row.created_at)
                        : row.payment_date
                        ? formatTime(row.payment_date)
                        : '-'}
                    </TableCell>
                    <TableCell>
                      <span
                        className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 ${
                          (row.status === statuses.PENDING ||
                            row.status === statuses.DRAFTING) &&
                          'bg-blue/20 text-blue'
                        } ${
                          (row.status === statuses.CANCELLED ||
                            row.status === statuses.REFUNDED) &&
                          'bg-red/20 text-red'
                        } ${
                          row.status === statuses.COMPLETED &&
                          'bg-green/20 text-green'
                        } ${
                          row.status === statuses.READY_FOR_PICKUP &&
                          'bg-accent-orange/20 text-accent-orange'
                        } ${
                          row.status === statuses.PROCESSING &&
                          'bg-accent-yellow/20 text-accent-yellow'
                        } ${
                          row.status === statuses.ON_DELIVERY &&
                          'bg-secondary/20 text-secondary'
                        }`}
                      >
                        {row.status === statuses.PENDING && (
                          <img src={pending} alt="" />
                        )}
                        {row.status === statuses.READY_FOR_PICKUP && (
                          <img src={pickup} alt="" />
                        )}
                        {row.status === statuses.ON_DELIVERY && (
                          <img src={delivery} alt="" />
                        )}
                        {row.status === statuses.PROCESSING && (
                          <img src={processing} alt="" />
                        )}
                        {row.status === statuses.REFUNDED && (
                          <img src={cancelled} alt="" />
                        )}
                        {row.status === statuses.DRAFTING && (
                          <img src={pending} alt="" />
                        )}
                        {row.status === statuses.CANCELLED && (
                          <img src={cancelled} alt="" />
                        )}
                        {row.status === statuses.COMPLETED && (
                          <img src={completed} alt="" />
                        )}

                        <p className="mb-0">{row.status}</p>
                      </span>
                    </TableCell>
                    <TableCell className="font-medium text-black" align="right">
                      ₦{' '}
                      {Number(row?.final_cost?.amount || 0).toLocaleString(
                        'en',
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup size="medium">
                        <Button onClick={() => setShowReceipt(row)}>
                          <img src={view} alt="" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(_, pg) => onPageChange(pg + 1)}
          onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
        />
      </div>
      {showReceipt ? (
        <ReceiptModal
          isOpen={true}
          closeModal={() => setShowReceipt(null)}
          data={showReceipt}
        />
      ) : null}
    </>
  )
}

export default RetailerOrderHistory
