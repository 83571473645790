import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

//utils
import validateEmail from '../../utils/validateEmail'
import validatePin from '../../utils/validatePin'
import validatePhoneNumber from '../../utils/validatePhoneNumber'

//mui
import { Switch, FormControlLabel } from '@material-ui/core'

//components
import AuthCard from '../../components/AuthCard/AuthCard'
import FormInput from '../../components/FormInput/FormInput'
import CustomButton from '../../components/CustomButton/CustomButton'
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert'
import { RootContext } from '../..'
import CustomSelect from '../../components/CustomSelect/CustomSelect'

const Index = () => {
  const history = useHistory()
  const root = useContext(RootContext)
  const [method, setMethod] = useState('email')
  const [country, setCountry] = useState('+234')

  const [credentials, setCredentials] = useState({
    password: '',
    email: '',
    phoneNumber: '',
    pin: '',
  })
  const [oneWeek, setOneWeek] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    setCredentials({
      ...credentials,
      [name]: value,
    })
  }

  const handleLoginException = (error) => {
    const { data } = error.response
    const message = data.non_field_errors?.[0] || data.message
    setError(message || 'An unexpected error occurred. Please try again later')
  }

  const loginWithEmail = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    const isEmailValid = validateEmail(credentials.email)

    if (!isEmailValid) {
      setError('Invalid email address')
      setLoading(false)
      return
    }

    const info = {
      email: credentials.email,
      password: credentials.password,
    }

    await root.authStore
      .signInWithEmail(info)
      .then(() => {
        setLoading(false)
        history.push(`/login/${credentials.email}/verify-identity/`)
      })
      .catch((e) => {
        setLoading(false)
        handleLoginException(e)
      })
  }

  const sanitizePhoneNumber = () => {
    if (credentials.phoneNumber.substring(0, 1) === '0')
      return `${country}${credentials.phoneNumber.substring(1)}`
    else return `${country}${credentials.phoneNumber}`
  }

  const loginWithPhoneNumber = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    const phoneNumber = sanitizePhoneNumber()

    const isPhoneNumberValid = validatePhoneNumber(phoneNumber)
    const isPinValid = validatePin(credentials.pin)

    if (!isPhoneNumberValid) {
      setError('Invalid number, kindly use +xxxxxxxxxx format')
      setLoading(false)
      return
    }

    if (!isPinValid) {
      setError('Invalid pin')
      setLoading(false)
      return
    }

    const info = {
      phone_number: phoneNumber,
      pin: credentials.pin,
    }

    await root.authStore
      .signInWithPhoneNumber(info, oneWeek)
      .then(() => {
        setLoading(false)
        history.push(
          `/login/${credentials.phoneNumber}/verify-identity/${oneWeek}`,
        )
      })
      .catch((e) => {
        setLoading(false)
        handleLoginException(e)
      })
  }

  const switchMethod = (method) => {
    setMethod(method)
    setError('')
    setCredentials({
      password: '',
      email: '',
      phoneNumber: '',
      pin: '',
    })
  }

  return (
    <AuthCard>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-[22px] font-bold text-center">
          Sign in to your business account
        </h1>

        {/*<div className="flex items-center px-2 py-1 space-x-3 border rounded-3xl">
          <button
            className={`px-3 py-1 text-sm font-medium ${
              method === "email"
                ? "bg-orange/10 rounded-3xl text-gray1"
                : " text-gray3"
            }`}
            onClick={() => {
              switchMethod("email");
            }}
          >
            Use Email Address
          </button>
          <button
            className={`px-3 py-1 text-sm font-medium ${
              method === "phone"
                ? "bg-orange/10 rounded-3xl text-gray1"
                : " text-gray3"
            }`}
            onClick={() => {
              switchMethod("phone");
            }}
          >
            Use Phone Number
          </button>
        </div>*/}

        {method === 'email' && (
          <p className="leading-none text-center text-gray2 w-[60%] text-sm">
            Enter your email address and password to get access to your account
          </p>
        )}

        {method === 'phone' && (
          <p className="leading-none text-center text-gray2 w-[60%] text-sm">
            Enter your phone number to get access to your account
          </p>
        )}
      </div>

      <div className="w-[70%]">
        <form className="space-y-1">
          {error && <ErrorAlert error={error} />}

          {method === 'email' && (
            <FormInput
              id="email"
              name="email"
              type="email"
              placeholder="Email Address"
              label="Email Address"
              onChange={handleChange}
              value={credentials.email}
              error={error}
            />
          )}

          {method === 'phone' && (
            <div className="flex flex-row items-center space-x-1">
              <CustomSelect
                label="Country"
                name="country-codes"
                id="country-codes"
                value={country}
                options={['+233', '+234']}
                setValue={setCountry}
              />
              <FormInput
                id="phone"
                name="phoneNumber"
                placeholder="Phone Number"
                label="Phone Number"
                onChange={handleChange}
                value={credentials.phoneNumber}
                error={error}
              />
            </div>
          )}

          {method === 'email' && (
            <FormInput
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              label="Password"
              onChange={handleChange}
              value={credentials.password}
              error={error}
            />
          )}

          {method === 'phone' && (
            <FormInput
              id="pin"
              name="pin"
              type="password"
              placeholder="Enter Pin"
              label="Enter Pin"
              onChange={handleChange}
              value={credentials.pin}
              error={error}
            />
          )}
        </form>

        <div className="flex items-center justify-end mt-2">
          {/*<FormControlLabel
            control={
              <Switch
                size="small"
                checked={oneWeek}
                onChange={() => {
                  setOneWeek(!oneWeek);
                }}
                color="primary"
                classes={{
                  thumb: "bg-white",
                }}
              />
            }
            label="Stay signed in for a week"
            classes={{
              label: "font-medium text-gray3 text-[12px]",
            }}
          />*/}

          {method === 'email' && (
            <Link
              to="/reset-password"
              className="text-[12px] underline text-blue"
            >
              Forgot Password?
            </Link>
          )}

          {method === 'phone' && (
            <Link to="/reset-pin" className="text-[12px] underline text-blue">
              Forgot Pin?
            </Link>
          )}
        </div>

        <CustomButton
          text="Continue"
          type="submit"
          onClick={method === 'email' ? loginWithEmail : loginWithPhoneNumber}
          disabled={
            method === 'email'
              ? credentials.email === '' || credentials.password === ''
              : credentials.phoneNumber === '' || credentials.pin === ''
          }
          loading={loading}
        />

        <div className="flex items-center justify-center mt-3">
          <span className="text-[12px] text-gray-400">
            Don't have an account?{' '}
            <a
              href={process.env.REACT_APP_RETAILER}
              className="font-bold underline text-blue"
            >
              Sign up
            </a>
          </span>
        </div>
      </div>
    </AuthCard>
  )
}

export default Index
