import React from "react";

//icons
import excel from "../../assets/excel.svg";
import searchIcon from "../../assets/search.svg";

//mui
import { Button } from "@material-ui/core";

//components

const SearchBar = ({
  children,
  filterButton,
  onChange,
  value,
  placeholder,
  onSearchKeyUp,
  onSearchKeyDown,
  onExport
}) => {
  return (
    <div className="flex justify-between px-5 bg-white rounded-md  h-[56px]">
      <div className="space-x-6">{children}</div>

      <div className="flex items-center space-x-3">
        {filterButton}

        <div className="bg-[#F4F5F7] px-2 rounded-md w-[300px] flex items-center">
          <img src={searchIcon} alt="" />
          <input
            type="search"
            placeholder={placeholder}
            className="w-full px-2 py-2 text-sm bg-transparent border-0 focus:outline-none focus:ring-0"
            onChange={onChange}
            value={value}
            onKeyDown={onSearchKeyDown}
            onKeyUp={onSearchKeyUp}
          />
        </div>

       {onExport && (
          <Button
            variant="outlined"
            className="capitalize border border-[#E3E7ED] text-gray3"
            size="small"
            endIcon={<img src={excel} alt="" />}
            onClick={onExport}
          >
            Export
          </Button>
       )}
      </div>
    </div>
  );
};

export default SearchBar;
