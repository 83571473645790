import React from 'react'

const AnalyticsSummaryCard = ({ data, active, label, onPress }) => {
  return (
    <div
      onClick={() => onPress(label)}
      className={`flex flex-col pb-3 bg-white w-full transition-all duration-75 ease-in-out cursor-pointer ${
        active ? 'h-[120px]' : 'h-[110px] mb-[10px] shadow-sm rounded-md'
      }`}
    >
      <div
        className={`${active ? 'bg-[#F3641F]' : 'bg-white'} rounded-lg h-1`}
      ></div>
      <p className="text-[#4C536A] text-[16px] mt-2 font-medium pl-4">
        {label}
      </p>
      <div className="flex flex-row items-center justify-between px-4">
        {data.map((item, index) => (
          <div className="flex flex-col mr-12">
            <div
              className={`flex ${data.length > 1 ? 'flex-row' : 'flex-col'}`}
              key={index}
            >
              <p className="text-[#040A1D] xl:text-[22px] font-bold mr-2 lg:text-[20px]">
                ₦{Number(item.amount).toLocaleString('en')}
              </p>
              {/* {item.changeValue && (
                <span className="flex flex-row items-center">
                  {item.changeType === "rise" ? (
                    <img
                      src={greenArrow}
                      alt="green arrow pointing up"
                      className="w-5 h-6 lg:w-4 lg:h-5"
                    />
                  ) : (
                    <img
                      src={redArrow}
                      alt="red arrow pointing down"
                      className="w-5 h-6"
                    />
                  )}
                  <p
                    className={`${
                      item.changeType === "rise"
                        ? "text-appGreen"
                        : "text-appRed"
                    } font-medium lg:text-[14px]`}
                  >
                    {item.changeValue}%
                  </p>
                </span>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnalyticsSummaryCard
