import React, { useState, useEffect } from "react";
import filter from "../../assets/filter.svg";

//mui
import { Popover, Button } from "@material-ui/core";

const FilterButton = ({
  className,
  activeTab = "all",
  categories,
  onFinish=()=>{},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const createFiltersObject = () => {
    const filters = {};
    categories.forEach((element) => {
      filters[element.name] = false;
    });
    return filters;
  };

  const INITIAL_FILTERS = createFiltersObject();

  const [filters, setFilters] = useState(createFiltersObject());
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const setFilter = (value, filterName) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  const updateSelectedList = (event, filter) => {
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, filter]);
    } else {
      const newlist = selectedCategories.filter((item) => item !== filter);
      setSelectedCategories(newlist);
    }
  };

  const clearFilters = () => {
    setFilters(INITIAL_FILTERS);
    setSelectedCategories([]);
  };

  useEffect(() => {
    clearFilters();
  }, [activeTab]);

  return (
    <>
      <Button
        variant="outlined"
        className={`capitalize border border-[#E3E7ED] text-gray3 ${className} ${
          selectedCategories.length > 0 ? "bg-orange/20 text-orange" : ""
        }`}
        size="small"
        startIcon={<img src={filter} alt="" />}
        onClick={handleClick}
      >
        Filter
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          root: "mt-1",
          paper: "bg-transparent shadow-lg",
        }}
      >
        <div className="w-64 bg-white border rounded-md shadow-lg h-[500px] overflow-y-auto">
          <div className="flex items-center justify-between p-2 border-b sticky top-0 bg-white">
            <Button
              className="capitalize shadow-none text-gray2 bg-[#F4F5F7]"
              size="small"
              variant="contained"
              onClick={clearFilters}
            >
              Clear
            </Button>

            <h1 className="text-[12px] font-medium text-[#1C345D]">Filters</h1>

            <Button
              className="capitalize shadow-none text-orange bg-orange/10"
              size="small"
              variant="contained"
              onClick={() => {
                onFinish(selectedCategories);
                handleClose();
              }}
            >
              Done
            </Button>
          </div>

          <ul className="divide-y">
            {categories
              .filter((category) => category.parent === null)
              .map((filter, idx) => {
                return (
                  <div key={idx}>
                    <li className="flex items-center px-3 py-2 space-x-2">
                      <input
                        type="checkbox"
                        className="border-gray-200 rounded shadow-md focus-visible:outline-none focus:ring-offset-0 focus:ring-0"
                        checked={filters[filter.name]}
                        onChange={(e) => {
                          setFilter(e.target.checked, filter.name);
                          updateSelectedList(e, filter.uuid);
                        }}
                      />
                      <span className="text-sm font-semibold capitalize text-gray1">
                        {filter.name}
                      </span>
                    </li>

                    <li>
                      <ul className="bg-[#F4F5F7]">
                        {categories
                          .filter((category) => category.parent === filter.id)
                          .map((subFilter, idx) => {
                            return (
                              <li
                                key={idx}
                                className="flex items-center px-3 py-2 space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  className="border-gray-200 rounded shadow-md focus-visible:outline-none focus:ring-offset-0 focus:ring-0"
                                  checked={filters[subFilter.name]}
                                  onChange={(e) => {
                                    setFilter(e.target.checked, subFilter.name);
                                    updateSelectedList(e, subFilter.uuid);
                                    // setSelectedCategories([
                                    //   ...selectedCategories,
                                    //   subFilter.uuid,
                                    // ]);
                                  }}
                                />
                                <span className="text-sm capitalize text-gray1">
                                  {subFilter.name}
                                </span>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
      </Popover>
    </>
  );
};

export default FilterButton;
