import { makeAutoObservable, action, runInAction } from 'mobx'
import Cookies from 'js-cookie'

import { getOrders, getOrdersSummary } from '../services/orders'

import { statuses } from '../enums'

export default class Orders {
  rootStore
  busy = false
  orders = []
  pending = { orders: 0, total: 0 }
  completed = { orders: 0, total: 0 }
  cancelled = { orders: 0, total: 0 }
  processing = { orders: 0, total: 0 }
  delivery = { orders: 0, total: 0 }
  pickup = { orders: 0, total: 0 }
  total = { orders: 0, total: 0 }

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      fetchOrders: action,
    })

    this.rootStore = rootStore
  }

  async fetchOrders(uuid) {
    runInAction(() => {
      this.busy = true
    })
    const token = this.getToken()
    try {
      const { data } = await getOrders(uuid, { ordered: true })
      this.orders = data.results
      // this.getSummaryCardStats();
      runInAction(() => {
        this.busy = false
      })
    } catch (error) {
      runInAction(() => {
        this.busy = false
      })
      throw error
    }
  }

  async getSummaryCardStats(uuid) {
    runInAction(() => {
      this.busy = true
    })
    try {
      const { data } = await getOrdersSummary(uuid)
      this.total.orders = data.total_orders.count
      this.total.total = data.total_orders.amount.amount
      this.pending.orders = data.pending.count
      this.pending.total = data.pending.amount.amount
      this.completed.orders = data.completed.count
      this.completed.total = data.completed.amount.amount
      this.cancelled.orders = data.cancelled.count
      this.cancelled.total = data.cancelled.amount.amount
      this.delivery.orders = data.on_delivery.count
      this.delivery.total = data.on_delivery.amount.amount
      this.pickup.orders = data.ready_for_pickup.count
      this.pickup.total = data.ready_for_pickup.amount.amount
      this.processing.orders = data.processing.count
      this.processing.total = data.processing.amount.amount

      runInAction(() => {
        this.busy = false
      })
    } catch (error) {
      runInAction(() => {
        this.busy = false
      })
      throw error
    }
  }

  /*
  getSummaryCardStats() {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.final_cost?.amount || 0);

    // total
    const total = this.orders.reduce(amountReducer, 0);
    this.total = total;

    // pending
    const pending = this.orders.filter((order) => order.status === statuses.PENDING);
    const pendingTotal = pending.reduce(amountReducer, 0);
    this.pending.orders = pending.length;
    this.pending.total = pendingTotal;

    // completed
    const completed = this.orders.filter((order) => order.status === statuses.COMPLETED);
    const completedTotal = completed.reduce(amountReducer, 0);
    this.completed.orders = completed.length;
    this.completed.total = completedTotal;

    // cancelled
    const cancelled = this.orders.filter((order) => order.status === statuses.CANCELLED);
    const cancelledTotal = cancelled.reduce(amountReducer, 0);
    this.cancelled.orders = cancelled.length;
    this.cancelled.total = cancelledTotal;
  }
  */

  getToken() {
    return Cookies.get('auth_token')
  }
}
