import React from "react";
import useTableMethods from "../../customHooks/useTableMethods";

//icons
import view from "../../assets/view.svg";
import edit from "../../assets/edit.svg";
import pending from "../../assets/pending.svg";
import cancelled from "../../assets/cancelled.svg";
import completed from "../../assets/completed.svg";
import processing from "../../assets/orange-clock.svg";
import delivery from "../../assets/delivery-bike.svg";
import pickup from "../../assets/package-box.svg";
import loader from "../../assets/loader.svg";

import { statuses } from "../../enums";

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
  Avatar,
  ButtonGroup,
  Button,
} from "@material-ui/core";

//components
import CustomTableHead from "./TableHead";

//utils
import { formatDate, formatTime } from "../../utils/index";

const OrdersTable = ({
  rows,
  onEditClick,
  onViewClick,
  search = "",
  busy = false,
  notFound = false,
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}) => {
  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows);

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{" "}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <CustomTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody className="bg-white">
            {rows.map((row, index) => {
              const isItemSelected = isSelected(index);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.order_id}
                  </TableCell>
                  <TableCell className="flex items-center space-x-2">
                    <Avatar
                      className="w-8 h-8"
                      src={row.user.profile.picture}
                    />
                    <p className="text-sm font-medium text-gray2 mb-0">
                      {row.user.profile.first_name} {row.user.profile.last_name}
                    </p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.user.phone_number}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.status === statuses.DRAFTING
                      ? formatDate(row.created_at)
                      : row.payment_date
                      ? formatDate(row.payment_date)
                      : "-"
                    }
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.status === statuses.DRAFTING
                      ? formatTime(row.created_at)
                      : row.payment_date
                      ? formatTime(row.payment_date)
                      : "-"
                    }
                  </TableCell>
                  <TableCell>
                    <span
                      className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 ${
                        (row.status === statuses.PENDING ||
                          row.status === statuses.DRAFTING
                        ) &&
                        "bg-blue/20 text-blue"
                      } ${
                        (row.status === statuses.CANCELLED ||
                          row.status === statuses.REFUNDED) &&
                        "bg-red/20 text-red"
                      } ${
                        row.status === statuses.COMPLETED &&
                        "bg-green/20 text-green"
                      } ${
                        row.status === statuses.READY_FOR_PICKUP &&
                        "bg-accent-orange/20 text-accent-orange"
                      } ${
                        row.status === statuses.PROCESSING &&
                        "bg-accent-yellow/20 text-accent-yellow"
                      } ${
                        row.status === statuses.ON_DELIVERY &&
                        "bg-secondary/20 text-secondary"
                      }`
                    }
                    >
                      {row.status === statuses.PENDING && (
                        <img src={pending} alt="" />
                      )}
                      {row.status === statuses.READY_FOR_PICKUP && (
                        <img src={pickup} alt="" />
                      )}
                      {row.status === statuses.ON_DELIVERY && (
                        <img src={delivery} alt="" />
                      )}
                      {row.status === statuses.PROCESSING && (
                        <img src={processing} alt="" />
                      )}
                      {row.status === statuses.REFUNDED && (
                        <img src={cancelled} alt="" />
                      )}
                      {row.status === statuses.DRAFTING && (
                        <img src={pending} alt="" />
                      )}
                      {row.status === statuses.CANCELLED && (
                        <img src={cancelled} alt="" />
                      )}
                      {row.status === statuses.COMPLETED && (
                        <img src={completed} alt="" />
                      )}

                      <p className="mb-0">{row.status}</p>
                    </span>
                  </TableCell>
                  <TableCell className="font-medium text-black" align="right">
                    ₦{" "}
                    {Number(row?.final_cost?.amount || 0).toLocaleString("en")}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button onClick={() => onEditClick(row)}>
                        <img src={edit} alt="" />
                      </Button>
                      <Button onClick={() => onViewClick(row)}>
                        <img src={view} alt="" />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1);
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default OrdersTable;
