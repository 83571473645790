import React, { useState, useEffect, useRef } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";

const CustomSelect = ({
  placeholder,
  name,
  id,
  labelKey = 'name',
  label,
  options,
  setValue,
  value,
  subCategory = false,
  newBtnLabel,
  onNewClick
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef()
  const optionsRef = useRef()

  function handleClick(ev) {
    if(!selectRef.current?.contains?.(ev.target)) {
      setShowOptions(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClick)
    return () => document.body.removeEventListener('click', handleClick)
  }, [])

  useEffect(() => {
    if (showOptions) {
      optionsRef.current.firstChild.focus()
    }
  }, [showOptions])

  return (
    <div ref={selectRef} className="relative group">
      <div
        className={`relative flex items-end bg-[#F4F5F7] rounded-md w-full h-14 group`}
      >
        <input
          id={id}
          name={name}
          className={`w-full h-10 pl-4 placeholder-transparent transition duration-500 capitalize ease-in-out bg-transparent border-b-2 text-gray1 caret-blue font-medium $ group-focus:border-blue peer focus:outline-none border-transparent`}
          placeholder={placeholder}
          value={subCategory ? value[labelKey] : value}
          onChange={() => {}}
          onFocus={() => setShowOptions(true)}
        />
        <label
          htmlFor={id}
          className={`absolute capitalize left-4 top-[5px] text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray2 peer-placeholder-shown:top-4 peer-focus:top-[5px] peer-focus:text-blue peer-focus:text-sm`}
        >
          {label}
        </label>
        <IoChevronDownOutline className="absolute right-5 top-6 text-gray2" />
      </div>

      {showOptions && (
        <div ref={optionsRef} className={`absolute z-50 hidden w-full p-3 mt-2 bg-white border rounded-md shadow-lg group-focus-within:block ${options.length > 0 && 'h-[fit-content'} max-h-[300px] overflow-y-scroll`}>
          {newBtnLabel && (
            <button
              type="button"
              onClick={onNewClick}
              className="border border-[#E3E7ED] rounded-md w-full flex justify-center items-center text-[#4C536A] text-[16px] font-bold py-1 mb-2"
            >
              <AiOutlinePlus className="mr-8" size={18} /> {newBtnLabel}
            </button>
          )}
          {options.map((option, idx) => {
            return (
              <button
                type="button"
                key={idx}
                onClick={() => {
                  if (setValue) {
                    setValue(option);
                    setShowOptions(false);
                  }
                }}
                className="flex justify-start block w-full p-2 capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange"
              >
                {subCategory ? option[labelKey] : option}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
