import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";

//assets
import redArrow from "../../assets/red-arrow.svg";
import greenArrow from "../../assets/green-arrow.svg";

const BlueCheckbox = withStyles({
  root: {
    color: "#477DFB",
    "&$checked": {
      color: "#477DFB",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StackedGraphs = ({ data, group }) => {
  const tickCallback = (value, index, values) => {
    const date = new Date();
    const hour = date.getHours();
    if (compareHours(hour, value, index)) return `Now, ${value}`;
    else if (value === "12:00am") return "12:00am";
    else if (value === "11:59pm") return "11:59pm";
    return null;
  };

  const compareHours = (hour, tickValue, tickIndex) => {
    const tickHour = parseInt(tickValue.split(":")[0]);
    if (tickIndex === 0 && hour === 0) return true;
    else if (tickIndex <= 12 && tickHour === hour) return true;
    else if (tickIndex > 12 && tickHour + 12 === hour) return true;
    return false;
  };

  const generateChartOptions = (showXAxes) => {
    return {
      aspectRatio: 1,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            display: false,
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            display: showXAxes,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              callback: tickCallback,
              maxRotation: 0,
              minRotation: 0,
              fontColor: ["#9CA7B8"],
              beginAtZero: false,
              autoSkip: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#FAFAFA",
        titleFontColor: "#040A1D",
        bodyFontColor: "#477DFB",
        usePointStyle: true,
        callbacks: {
          title: function (tooltipItem, data) {
            return `₦${Number(
              data["datasets"][0]["data"][tooltipItem[0]["index"]]
            ).toLocaleString("en")}`;
          },
          label: function (tooltipItem, data) {
            return data["labels"][tooltipItem["index"]];
          },
        },
      },
    };
  };

  return (
    <div className="flex flex-col justify-between">
      {data.map((item, index) => (
        <div className="flex flex-row items-end mt-2" key={index}>
          <div className="flex flex-col w-[25%] border-b border-[#E3E7ED] pb-2 mr-10">
            <p className="text-[#9CA7B8] text-[12px]">{item.label}</p>
            <p className="text-[#040A1D] text-[16px] font-bold">
              {Number(item.summaryValue).toLocaleString("en")}
            </p>
            <span className="flex flex-row">
              <p className="text-[#040A1D] text-[26px] font-medium">
                ₦{Number(item.amount).toLocaleString("en")}
              </p>
              {/* {item.changeValue && (
                <span className="flex items-center">
                  {item.changeType === "rise" ? (
                    <img
                      src={greenArrow}
                      alt="green arrow pointing up"
                      className="w-5 h-6 lg:w-4 lg:h-5"
                    />
                  ) : (
                    <img
                      src={redArrow}
                      alt="red arrow pointing down"
                      className="w-5 h-6"
                    />
                  )}
                  <p
                    className={`${
                      item.changeType === "rise"
                        ? "text-appGreen"
                        : "text-appRed"
                    } font-medium lg:text-[14px]`}
                  >
                    {item.changeValue}%
                  </p>
                </span>
              )} */}
            </span>
          </div>

          <div
            className={`w-full ${
              group === "Total Orders" ? "h-[110px]" : "h-[180px]"
            }`}
          >
            <Line
              data={item.chartData}
              options={generateChartOptions(index === data.length - 1)}
              id={item.label}
              className="chart-canvas"
              height={null}
              width={null}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default StackedGraphs;
