import React, { useState, useEffect, useContext } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

//icons
import usersIcon from '../../assets/users-inactive.svg'

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import TabButton from '../../components/TabButton/TabButton'
import UsersTable from '../../components/UsersTable/UsersTable'
import Loader from '@components/Loader/Loader'
import SearchBar from '../../components/SearchBar/SearchBar'

//utils
import { exportData, formatDate } from '../../utils/index'
import { deleteSingleUser, getUsers, getUsersTeam } from '../../services/users'
import { RootContext } from '../..'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'

const Index = observer(() => {
  const history = useHistory()
  const root = useContext(RootContext)

  const [activeTab, setActiveTab] = useState('All')
  const [busy, setBusy] = useState(true)
  const [users, setUsers] = useState([])
  const [filteredUsers] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [notFound, setNotFound] = useState(false)
  const [controller, setController] = useState(null)
  const [open, setOpen] = useState(false)

  //delete data
  const [deleteId, setDeleteId] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const openDelete = (data) => {
    setOpen(true)
    setDeleteId(data.uuid)
    setDeleteName(data.profile.first_name)
  }

  const fetchUsers = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)

    const types = ['Retailer', 'Drinks Admin']

    try {
      let data
      const params = {
        search: searchValue,
        page: currentPage,
        page_size: rowsPerPage,
      }

      const { retailerUuid } = root.authStore.user || {}
      if (retailerUuid) {
        const res = await getUsersTeam(
          {
            ...params,
            retailer_uuid: retailerUuid,
          },
          abc.signal,
        )
        data = res.data
      } else {
        const res = await getUsers(
          { ...params, account_type: types.join(",") },
          abc.signal,
        )
        data = res.data
      }

      setCount(data.count)
      setUsers(data.results)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('An error occurred. Unable to fetch users')
        setBusy(false)
      }
    }
  }

  const deleteUser = async () => {
    try {
      toast.info('Deleting user...')
      await deleteSingleUser(deleteId)
      fetchUsers()
      toast.success('User deleted successfully.')
      setOpen(false)
    } catch {
      toast.error('Failed to delete user.')
      setOpen(false)
    }
  }

  const exportUsers = async () => {
    toast.info('Fetching data to export...')
    try {
      const { data } = await getUsers({ search: searchValue, paginate: 'no' })
      exportData(
        data.map((entry) => ({
          Name: [entry.profile.first_name, entry.profile.last_name]
            .filter(Boolean)
            .join(' '),
          Email: entry.email || '',
          'Date Joined': formatDate(entry.created_at),
          'Account Type': entry.account_type,
          'Phone Number': entry.phone_number || '',
        })),
        'Users',
      )
      toast.info('Users data successfully exported')
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later!')
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [currentPage, rowsPerPage, searchValue])

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Users" />

        <SearchBar
          placeholder="Search Users"
          onChange={(e) => {
            setCurrentPage(1)
            setSearchValue(e.target.value)
          }}
          value={searchValue}
          onExport={exportUsers}
        >
          <TabButton
            activeTab={activeTab}
            onClick={() => setActiveTab('All')}
            title="All"
          />
        </SearchBar>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : (
          <>
            {activeTab === 'All' && (
              <>
                {users.length ? (
                  <div className="py-5 space-y-5">
                    <div className="flex justify-end">
                      <Button
                        variant="contained"
                        color="primary"
                        className="capitalize shadow-none"
                        startIcon={<IoAddOutline />}
                        onClick={() => history.push('/users/add-new-user')}
                      >
                        Add New User
                      </Button>
                    </div>

                    <UsersTable
                      rows={users}
                      search={searchValue}
                      busy={busy}
                      notFound={notFound}
                      onDeleteClick={openDelete}
                      onPageChange={setCurrentPage}
                      onRowsPerPageChange={(rows) => {
                        setCurrentPage(1)
                        setRowsPerPage(rows)
                      }}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={currentPage - 1}
                    />
                  </div>
                ) : (
                  <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                    <img src={usersIcon} alt="" className="w-16 h-16" />
                    <p className="text-[18px] font-semibold text-gray1">
                      All your users will show here
                    </p>

                    <p className="text-sm text-gray2">
                      This is where you add and update your users
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      className="capitalize shadow-none"
                      startIcon={<IoAddOutline />}
                      onClick={() => history.push('/users/add-new-user')}
                    >
                      Add New User
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <ConfirmDeleteModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={deleteUser}
      >
        <p className="text-center max-w-[40ch] mx-auto">
          You're about to delete
          <br />
          <span className="text-orange font-medium">"{deleteName}"</span>
          <br />
          <br />
          This action is irreversible.
        </p>
      </ConfirmDeleteModal>
    </Layout>
  )
})

export default Index
