import { makeAutoObservable, action, observable } from "mobx";

export default class Inventory {
  rootStore;
  newInventory = [];


  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      addToInventory: action,
      updateInventory: action,
      removeProductFromInventory: action,
      newInventory: observable
    });

    this.rootStore = rootStore;
  }

  addToInventory(productList) {
    const list = []
    productList.forEach(({ attribute, id, ...element }) => {
      attribute.forEach(item => {
        list.push({
          ...element,
          productId: id,
          attribute: item,
          id: id+item.id,
          quantity: 1
        })
      })
    });
    this.newInventory.replace(list)
  }

  updateInventory(productId, quantity) {    
    this.newInventory.forEach(element => {
      if(element.id === productId)
        element.quantity = quantity
    });
  }

  removeProductFromInventory(productId) {
    const basket = this.newInventory.filter(item => item.id !== productId);
    this.newInventory = basket;
  }
}
