import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import FormInput from "../../components/FormInput/FormInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

import { getFaqCategories, updateFaq, getFaq } from "../../services/design"

const EditFAQ = () => {
  const history = useHistory();
  const params = useParams()
  const [busy, setBusy] = useState(false);
  const [categories, setCategories] = useState([])
  const [faq, setFaq] = useState({});
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [showAddModal, setShowAddModal] = useState(false)

  async function fetchCategories() {
    try {
      const data = await getFaqCategories({ page_size: 50 })
      setCategories(data.results)
    } catch {}
  }

  async function getFaqData() {
    try {
      const data = await getFaq(params.id)
      setQuestion(data.title)
      setAnswer(data.content)
      setCategory(data.category)
      setFaq(data)
    } catch(e) {}
  }

  async function applyChanges() {
    try {
      toast.info('Updating FAQ...')
      setBusy(true)
      await updateFaq(params.id, {
        title: question,
        content: answer,
        category: category.id,
      })
      toast.success('FAQ updated successfully')
      // setShowSavedModal(true)
      history.goBack();
    } catch(e) {
      toast.error('Failed to update FAQ')

    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    fetchCategories()
    getFaqData()
  }, [])
  
  const disabled = !(category && question && answer)

  return (
    <Layout>
      <div className="w-[750px] mx-auto pb-[100px]">
        <PageTitle 
          onBackPress={() => history.goBack()}
          title="Edit"
          subtitle={`FAQ (${faq.title})`}
        />
        <form
          autoComplete="off"
          className="bg-white p-[20px] shadow-sm"
          onSubmit={(e) => {e.preventDefault()}}
        >
          <CustomSelect
            options={categories}
            newBtnLabel={'Add a New Category'}
            onNewClick={() => setShowAddModal(true)}
            name="FAQ input"
            id="FAQ"
            label="Category"
            value={category}
            subCategory={true}
            setValue={setCategory}
          />
          <div className="h-4"/>
          <FormInput 
            id="question"
            name="question"
            label="Question"
            placeholder="Question"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <div className="h-4"/>
          <FormInput 
            id="answer"
            name="answer"
            label="Answer"
            placeholder="Answer"
            type="textarea"
            rows={4}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </form>
        <div
          className="flex flex-row items-center justify-end space-x-4 mt-[20px]"
        >
          <Button
            variant="outlined"
            className='capitalize bg-white text-[16px] text-[#4C536A] font-semibold'
          >Cancel</Button>
          <Button
            variant="contained"
            disableElevation
            className={`capitalize ${busy || disabled ? "bg-orange/20" : "bg-orange"}
            text-white font-semibold text-[16px]`}
            disabled={disabled}
            onClick={applyChanges}
          >
            Save
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default EditFAQ;
