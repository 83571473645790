import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  createRef,
} from 'react'
import { IoArrowUp, IoCloseCircle } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Editor from '@components/TinyMCE'

//icons
import productsInactive from '../../assets/products-inactive.svg'
import loader from '../../assets/loader.svg'
import success from '../../assets/success.svg'
import editIcon from '../../assets/edit.svg'

//mui
import { Button, Dialog, Checkbox } from '@material-ui/core'

//components
import FormInput from '../FormInput/FormInput'
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect'
import CreateAttributeModal from '../CreateAttributeModal/CreateAttributeModal'
import CreateCategoryModal from '../CreateCategoryModal/CreateCategoryModal'

//utils
import {
  fetchAllAttributes,
  fetchAllProductCategories,
  fetchAllBrands,
} from '../../utils'

//services
import {
  addProductMedia,
  createProduct,
  makeProductRequest,
  updateProduct,
  addProductAttribute,
} from '../../services/products'
import { RootContext } from '../..'

const AddNewProductForm = ({ request = false }) => {
  const root = useContext(RootContext)

  const history = useHistory()
  const attributeMediaInput = createRef()
  const [busy, setBusy] = useState(false)
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  // const [price, setPrice] = useState("₦");
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [metaTitle, setMetaTitle] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [metaDesc, setMetaDesc] = useState('')
  const [featured, setFeatured] = useState(false)
  const [engrave, setEngrave] = useState(false)
  const [imageAlt, setImageAlt] = useState('')
  const [attributes, setAttributes] = useState([])
  const [selectedAttributes, setSelectedAttributes] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])
  const [showCreateAttributeModal, setShowCreateAtrributeModal] =
    useState(false)
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false)
  const [parentAttributes, setParentAttributes] = useState([])
  const [parentCategories, setParentCategories] = useState([])
  const [attributesMedia, setAttributesMedia] = useState([])
  const [activeAttribute, setActiveAttribute] = useState()

  const handleClose = () => {
    setOpen(false)
  }

  // const handlePriceChange = (e) => {
  //   let regex = /^[0-9]*$/;
  //   if(e.target.value.length === 0) {
  //     return;
  //   }
  //   if(!e.target.value.substring(1).match(regex))
  //     return
  //   setPrice(e.target.value);
  // }

  const fetchProductCategories = async () => {
    try {
      const data = await fetchAllProductCategories()
      const sortedList = await sortCategoriesOrAttributes(data, 'category')
      setCategories(sortedList)
    } catch (error) {
      toast.error('Unable to fetch product categories. Please try again later.')
      history.goBack()
    }
  }
  const fetchBrands = async () => {
    try {
      const data = await fetchAllBrands()
      setBrands(data)
    } catch (error) {
      toast.error('Unable to fetch brands. Please try again later.')
      history.goBack()
    }
  }

  // add parent information to subcategories
  const sortCategoriesOrAttributes = async (categories, type) => {
    const parents = []
    categories.forEach((element) => {
      if (element.parent) {
        const parent = categories.find((item) => item.id === element.parent)
        element.parent_name = parent.name
      } else {
        parents.push(element)
      }
    })
    if (type === 'category') setParentCategories(parents)
    else setParentAttributes(parents)
    return categories
  }

  const fetchAttributes = async () => {
    try {
      const data = await fetchAllAttributes()
      const sortedList = await sortCategoriesOrAttributes(data, 'attribute')
      setAttributes(sortedList)
    } catch (error) {
      toast.error('Unable to fetch product attributes. Please try again later.')
      history.goBack()
    }
  }

  const getIdList = (data) => {
    return data.map((item) => item.id)
  }

  const generateForm = () => {
    const form = {
      name: name,
      title_meta: metaTitle,
      description: desc,
      featured,
      description_meta: metaDesc,
      engrave,
      image_alt: imageAlt,
      // 'price': price.substring(1),
      brand: getIdList(selectedBrands),
      category: getIdList(selectedCategories),
      attribute: getIdList(selectedAttributes),
    }
    return form
  }

  const generateRequestForm = () => {
    const form = {
      name: name,
      title_meta: metaTitle,
      description: desc,
      description_meta: metaDesc,
      image_alt: imageAlt,
      // 'price': price.substring(1),
      category: getIdList(selectedCategories),
      attribute: getIdList(selectedAttributes),
      is_request: true,
      request_by: root.authStore.user.retailerId,
    }
    return form
  }

  const handleProductMedia = async (data) => {
    const media = images.filter((item, index) => index !== 0)
    media.forEach(async (element) => {
      const form = new FormData()
      form.append('product', data.id)
      form.append('media', element)
      await addProductMedia(form).catch((err) => {})
    })
  }

  const handleSubmit = async () => {
    setBusy(true)
    setOpen(true)
    const form = generateForm()
    try {
      await createProduct(form).then(async (res) => {
        const formData = new FormData()
        formData.append('image', images[0])
        await updateProduct(formData, res.data.uuid)
        if (images.length > 1) await handleProductMedia(res.data)
        await postNewAttributeMedia(res.data)
        setBusy(false)
      })
    } catch (error) {
      setOpen(false)
      toast.error('Unable to create product. Please try again later.')
    }
  }

  const makeRequest = async () => {
    setBusy(true)
    setOpen(true)
    const form = generateRequestForm()
    try {
      await makeProductRequest(form).then(async (res) => {
        const formData = new FormData()
        formData.append('image', images[0])
        await updateProduct(formData, res.data.uuid)
        if (images.length > 1) await handleProductMedia(res.data)
        await postNewAttributeMedia(res.data)
        setBusy(false)
      })
    } catch (error) {
      setOpen(false)
      toast.error('Unable to create product. Please try again later.')
    }
  }

  const clearForm = () => {
    setName('')
    setDesc('')
    // setPrice("");
    setSelectedAttributes([])
    setSelectedCategories([])
    setSelectedBrands([])
    setImages([])
  }

  const checkFormForEmptyFields = useCallback(() => {
    if (
      name.length > 0 &&
      desc.length > 0 /*&& price.length > 1*/ &&
      selectedCategories.length > 0 &&
      images.length > 0 &&
      selectedAttributes.length > 0 /* &&
      metaTitle.length > 0 &&
      metaDesc.length > 0 */
    )
      setDisabled(false)
    else {
      setDisabled(true)
    }
  }, [
    name.length,
    desc.length,
    /*price.length,*/ selectedAttributes.length,
    selectedCategories.length,
    images.length,
    // metaTitle.length,
    // metaDesc.length,
  ])

  const handleAttributeAdd = (attribute) => {
    let attributesCopy = [...selectedAttributes]
    const isDuplicate = attributesCopy.find(
      (item) => item.uuid === attribute.uuid
    )
    if (!isDuplicate) {
      attributesCopy.push(attribute)
      setSelectedAttributes(attributesCopy)
    }
    openAttributeMediaDialog(attribute)
  }

  const handleAttributeRemove = (attribute) => {
    let updatedArray = selectedAttributes.filter(
      (item) => item.uuid !== attribute.uuid
    )
    setSelectedAttributes(updatedArray)
    removeAttributeMedia(attribute)
  }

  const handleCategoryAdd = (category) => {
    let categoriesCopy = [...selectedCategories]
    const isDuplicate = categoriesCopy.find(
      (item) => item.uuid === category.uuid
    )
    if (!isDuplicate) {
      categoriesCopy.push(category)
      setSelectedCategories(categoriesCopy)
    }
  }

  const handleCategoryRemove = (category) => {
    let updatedArray = selectedCategories.filter(
      (item) => item.uuid !== category.uuid
    )
    setSelectedCategories(updatedArray)
  }

  const handleBrandAdd = (brand) => {
    let brandsCopy = [...selectedBrands]
    const isDuplicate = brandsCopy.find((item) => item.uuid === brand.uuid)
    if (!isDuplicate) {
      brandsCopy.push(brand)
      setSelectedBrands(brandsCopy)
    }
  }

  const handleBrandRemove = (brand) => {
    let updatedArray = selectedBrands.filter((item) => item.uuid !== brand.uuid)
    setSelectedBrands(updatedArray)
  }

  const addAttributeMedia = (attribute, media) => {
    const form = {
      attribute,
      image: media,
      id: 'new',
    }
    setAttributesMedia([...attributesMedia, form])
  }

  const updateAttributeMedia = (attribute, newMedia) => {
    const copy = [...attributesMedia]
    copy.forEach((element) => {
      if (attribute.attribute.uuid === element.attribute.uuid)
        attribute.image = newMedia
    })
    setActiveAttribute(null)
    setAttributesMedia(copy)
  }

  const removeAttributeMedia = (attribute) => {
    const media = attributesMedia.filter(
      (item) => item.attribute.uuid !== attribute.uuid
    )
    setAttributesMedia(media)
  }

  const openAttributeMediaDialog = (attribute) => {
    if (attribute.image) return
    attributeMediaInput.current.click()
    setActiveAttribute(attribute)
  }

  const postNewAttributeMedia = async (product) => {
    attributesMedia.forEach(async (element, index) => {
      const form = new FormData()
      form.append('image', element.image)
      form.append('product', product.id)
      form.append('attribute', element.attribute.id)
      form.append('sequence', index)
      await addProductAttribute(form).catch(() => {})
    })
  }

  useEffect(() => {
    fetchProductCategories()
    fetchBrands()
    fetchAttributes()
  }, [])

  useEffect(() => {
    checkFormForEmptyFields()
  }, [
    images,
    selectedAttributes,
    selectedCategories,
    checkFormForEmptyFields,
    name,
    desc,
    metaTitle,
    metaDesc,
  ])

  return (
    <>
      <div className="bg-white w-[70%] p-5 space-y-5">
        <FormInput
          type="text"
          label="Product Name"
          placeholder="Product Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="col-span-2 p-5 space-y-3 bg-[#F4F5F7] rounded-md">
          <label>Product Details</label>
          <Editor
            init={{
              height: 300,
            }}
            value={desc}
            onChange={setDesc}
          />
        </div>
        {!request ? (
          <FormInput
            type="text"
            label="Product Meta Title"
            placeholder="Product Meta Title"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        ) : null}
        {!request ? (
          <FormInput
            type="text"
            label="Product Meta Description"
            placeholder="Product Meta Description"
            value={metaDesc}
            onChange={(e) => setMetaDesc(e.target.value)}
          />
        ) : null}
        {!request && (
          <div className="px-4 bg-[#F4F5F7] rounded-md">
            <label className="mr-2">Featured</label>
            <Checkbox
              checked={featured}
              onClick={() => setFeatured(!featured)}
            />
            <label className="mr-2 ml-3">Engraving</label>
            <Checkbox checked={engrave} onClick={() => setEngrave(!engrave)} />
          </div>
        )}
      </div>
      {/* Metadata */}
      {/*<div className="bg-white w-[70%] p-5 space-y-3">
        <h1 className="mb-3 font-medium text-[#3D4356]">Pricing</h1>
        <FormInput
          type="text"
          label="Price"
          placeholder="Price"
          pattern="[0-9+\.]*"
          onChange={handlePriceChange}
          value={price}
        />
      </div>*/}

      <div className="bg-white w-[70%] p-5 space-y-3">
        <h1 className="mb-3 font-medium text-[#3D4356]">Category</h1>
        <CustomMultiSelect
          options={categories}
          selections={selectedCategories}
          addItem={handleCategoryAdd}
          removeItem={handleCategoryRemove}
          onNewClick={() => setShowCreateCategoryModal(true)}
          newBtnLabel={'Create New Category'}
        />
      </div>
      <div className="bg-white w-[70%] p-5 space-y-3">
        <h1 className="mb-3 font-medium text-[#3D4356]">Attributes</h1>
        <CustomMultiSelect
          options={attributes}
          selections={selectedAttributes}
          addItem={handleAttributeAdd}
          removeItem={handleAttributeRemove}
          onNewClick={() => setShowCreateAtrributeModal(true)}
          newBtnLabel={'Create New Attribute'}
        />
      </div>
      <div className="bg-white w-[70%] p-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Attributes Media</h1>
        <input
          ref={attributeMediaInput}
          type="file"
          hidden
          accept=".png,.jpeg,.jpg"
          onChange={(e) => {
            addAttributeMedia(activeAttribute, e.target.files[0])
          }}
        />
        <div className="grid grid-cols-5 gap-5">
          {attributesMedia.map((item, idx) => (
            <div className="flex flex-col">
              <div
                key={idx}
                className="relative mb-1 border border-gray-300 border-dashed rounded-md"
              >
                <img
                  src={
                    typeof item.image === 'object'
                      ? URL.createObjectURL(item.image)
                      : item.image
                  }
                  alt=""
                  className="object-cover w-[216px] h-[219px] rounded-md"
                />
                <label
                  htmlFor={`main-image${idx}`}
                  className="absolute left-0 right-0 p-2 mx-auto bg-white rounded bottom-6 w-[fit-content] cursor-pointer shadow"
                >
                  <img src={editIcon} alt="" className="w-[20px] h-[20px]" />
                </label>
                <input
                  type="file"
                  id={`main-image${idx}`}
                  accept=".png,.jpeg,.jpg"
                  onChange={(e) =>
                    updateAttributeMedia(item, e.target.files[0])
                  }
                  className="hidden"
                />
              </div>
              <p className="m-0 text-lg font-medium text-center">
                {item.attribute ? item.attribute.name : item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white w-[70%] p-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Media</h1>

        <div
          className={`grid mb-4 gap-5 ${
            images.length > 0
              ? 'grid-cols-[2fr,1fr]'
              : 'grid-cols-[1fr,1fr,1fr]'
          }`}
        >
          {images.length > 0 && (
            <div className="grid grid-cols-2 gap-5 ">
              {images.map((image, idx) => {
                return (
                  <div className="relative border border-gray-300 border-dashed rounded-md">
                    <IoCloseCircle
                      className="absolute cursor-pointer w-7 h-7 top-5 right-5"
                      onClick={() => {
                        setImages(images.filter((_, index) => index !== idx))
                      }}
                    />
                    <img
                      key={idx}
                      src={URL.createObjectURL(image)}
                      alt=""
                      className="object-cover w-[216px] h-[219px] rounded-md"
                    />
                  </div>
                )
              })}
            </div>
          )}

          <div className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52">
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
              <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
            </div>
            <label
              htmlFor="product-images"
              className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
            >
              <p>Upload Files</p>
              <input
                type="file"
                id="product-images"
                accept=".png,.jpeg,.jpg"
                onChange={(e) => {
                  setImages([...images, ...e.target.files])
                }}
                multiple
                className="hidden"
              />
            </label>

            <p className="text-sm text-gray2">or drop files to upload</p>
          </div>
        </div>
        {!request ? (
          <FormInput
            type="text"
            label="Media Alt Text"
            placeholder="Media Alt Text"
            value={imageAlt}
            onChange={(e) => setImageAlt(e.target.value)}
          />
        ) : null}
      </div>

      {/* brands */}
      {!request ? (
        <div className="bg-white w-[70%] p-5 space-y-3">
          <h1 className="mb-3 font-medium text-[#3D4356]">Brands</h1>
          <CustomMultiSelect
            options={brands}
            selections={selectedBrands}
            addItem={handleBrandAdd}
            removeItem={handleBrandRemove}
            imageKey="logo"
          />
        </div>
      ) : null}

      <div className="w-[70%] flex justify-end items-center space-x-3 pb-20">
        <Button
          variant="contained"
          className="capitalize bg-white shadow-none"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={`text-white capitalize shadow-none ${
            disabled
              ? 'bg-orange/30 cursor-not-allowed'
              : 'bg-orange cursor-pointer'
          }`}
          onClick={() => {
            if (!request) handleSubmit()
            else makeRequest()
          }}
          disabled={disabled}
        >
          Save
        </Button>
      </div>

      <CreateAttributeModal
        open={showCreateAttributeModal}
        closeModal={() => setShowCreateAtrributeModal(false)}
        parents={parentAttributes}
      />
      <CreateCategoryModal
        open={showCreateCategoryModal}
        closeModal={() => setShowCreateCategoryModal(false)}
        parents={parentCategories}
      />

      <Dialog open={open}>
        <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
          {busy ? (
            <>
              <img src={productsInactive} alt="" className="w-9 h-9" />
              {request ? (
                <p className="font-medium capitalize text-gray2">
                  Requesting new product
                </p>
              ) : (
                <p className="font-medium capitalize text-gray2">
                  Saving new product
                </p>
              )}
              <img src={loader} alt="" className="animate-spin" />
            </>
          ) : (
            <>
              <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                <img src={success} alt="" className="" />
              </span>

              {request ? (
                <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                  New Product Requested Successfully
                </p>
              ) : (
                <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                  New Product Added Successfully
                </p>
              )}

              <div className="space-x-3">
                <Button
                  variant="contained"
                  className="capitalize bg-white border shadow-none"
                  onClick={() => {
                    handleClose()
                    clearForm()
                  }}
                >
                  {request ? 'Request New Product' : 'Add New Product'}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  onClick={() => {
                    handleClose()
                    history.push('/products')
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default AddNewProductForm
