import { action, makeAutoObservable, observable } from "mobx";

export default class Products {
  rootStore;
  newInventory = [];

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });

    this.rootStore = rootStore;
  }
}
