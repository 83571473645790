import React from 'react'

import { TableCell, TableHead, TableRow } from '@material-ui/core'

const headCells = [
  { id: 'id', label: 'invoice id' },
  { id: 'customer', label: 'customer' },
  { id: 'phone_number', label: 'phone number' },
  { id: 'date', label: 'date' },
  { id: 'time', label: 'time' },
  { id: 'status', label: 'status' },
  { id: 'amount', numeric: true, label: 'amount' },
  { id: 'actions', numeric: true, label: 'actions' },
]

const CustomTableHead = () => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="uppercase text-[12px]"
            align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default CustomTableHead
