import React, { useState, useEffect, useContext } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

//icons
import productsIcon from '../../assets/products-inactive.svg'
// import success from "../../assets/success.svg";
// import error from "../../assets/alt-close.svg";

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import AvailableProducts from '../../components/ProductsTables/AvailableProducts'
import ProductRequests from '../../components/ProductsTables/ProductRequestsTable'
import DeclinedProducts from '../../components/ProductsTables/DeclinedProductsTable'
import SearchBar from '../../components/SearchBar/SearchBar'
import TabButton from '@components/TabButton/TabButton'
import FilterButton from '../../components/FilterButton/FilterButton'
import Loader from '@components/Loader/Loader'
import ConfirmDeleteModal from '@components/ConfirmDeleteModal'
import { RootContext } from '../..'

//services
import {
  getProducts,
  getRequestedProducts,
  getDeclinedProducts,
  deleteProduct,
  disableProduct,
  activateProduct,
} from '../../services/products'
import { getStockSummary } from '../../services/stock'

//utils
import {
  exportData,
  fetchAllProductCategories,
  getTextContent,
} from '../../utils'

const tabs = [
  { id: 'available', label: 'Available Products' },
  { id: 'requests', label: 'Product Requests' },
  { id: 'declined', label: 'Declined Products' },
]
const Index = observer(() => {
  const root = useContext(RootContext)
  const history = useHistory()

  const [totalProducts, setTotalProducts] = useState(0)
  const [summaryData, setSummaryData] = useState([
    {
      title: 'All Products',
      amount: null,
      quantity: 0,
      growth: '20%',
    },
    {
      title: 'Available Stock on Hand',
      amount: 0,
      quantity: 0,
      growth: '20%',
    },
  ])
  const [products, setProducts] = useState([])
  const [activeTab, setActiveTab] = useState(tabs[0].id)
  const [searchValue, setSearchValue] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [busy, setBusy] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [controller, setController] = useState(null)

  const fetchSummary = async () => {
    const { data = {} } = await getStockSummary().catch((e) =>
      toast.error('An error occurred. Unable to fetch stock summary.'),
    )
    let copy = summaryData.map((summary) => {
      if (summary.title === 'Available Stock on Hand') {
        summary.amount = data.total_stock_revenue || 0
        summary.quantity = data.total_stock_quantity || 0
      }
      return summary
    })
    setSummaryData(copy)
  }

  const fetchProducts = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)

    try {
      const params = {
        category__uuid: String(selectedCategories),
        search: searchValue,
        page_size: rowsPerPage,
        page: currentPage,
      }
      let data

      switch (activeTab) {
        case tabs[2].id:
          data = (await getDeclinedProducts(params, abc.signal)).data
          break
        case tabs[1].id:
          data = (await getRequestedProducts(params, abc.signal)).data
          break
        default:
          data = (await getProducts(params, abc.signal)).data
          break
      }
      setTotalProducts(data.count)
      setProducts(data.results)
      setBusy(false)
    } catch (error) {
      // console.log(error.code, error.name)
      // console.error(error)
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch products. Please try again later.')
        setBusy(false)
      }
    }
  }

  const computeSummaryValues = () => {
    const newSummary = summaryData.map((summary) => {
      if (summary.title !== 'Available Stock on Hand') {
        summary.quantity = totalProducts
      }
      return summary
    })
    setSummaryData(newSummary)
  }

  // const viewProduct = (uuid) => {
  //   history.push(`products/single-product/${uuid}`);
  // }

  // const editProduct = (uuid) => {
  //   history.push(`/products/requests/edit/${uuid}`);
  // }

  const deleteProductItem = async (item) => {
    toast.info(`Deleting "${item.name}"...`)
    try {
      await deleteProduct(item.uuid)
      toast.success(`"${item.name}" deleted successfully.`)
      if (searchValue) {
        // handleSearch(1, rowsPerPage);
      } else {
        // fetchProducts(rowsPerPage, 1);
        fetchProducts()
      }
    } catch (err) {
      toast.error(err.message || `Failed to delete ${item.name}`)
    } finally {
    }
  }

  const fetchCategories = async () => {
    try {
      const data = await fetchAllProductCategories()
      setCategories(data)
    } catch (error) {
      toast.error('An error occurred. Unable to fetch product categories.')
    }
  }

  const handleProductStatus = async (row) => {
    try {
      toast.info('updating status....')
      if (row.is_active) {
        await disableProduct(row.uuid)
        toast.success(`${row.name} has been disabled successfully!`)
      } else {
        await activateProduct(row.uuid)
        toast.success(`${row.name} has been enabled successfully!`)
      }

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.uuid === row.uuid
            ? { ...product, is_active: !row.is_active }
            : product,
        ),
      )
    } catch (error) {
      console.log(error)
      toast.error('Error updating product product status!')
    }
  }

  const getProductsExportData = async () => {
    let tableData
    toast.info('Fetching data to export')
    try {
      let request

      switch (activeTab) {
        case tabs[2].id:
          request = getDeclinedProducts
          break
        case tabs[1].id:
          request = getRequestedProducts
          break
        default:
          request = getProducts
          break
      }

      const { data } = await request({
        category__uuid: String(selectedCategories),
        paginate: 'no',
      })

      const stringifyNames = (arr) =>
        arr?.map?.((item) => item.name)?.join?.(', ') || ''

      exportData(
        data.map((item) => ({
          ID: item.id,
          Name: item.name,
          Price: `${item.price.currency} ${item.price.amount}`,
          Description: getTextContent(item.description),
          Image: item.image,
          URL: `${process.env.REACT_APP_WEB_URL}/product/${item.slug}`,
          Featured: item.featured ? 'Yes' : 'No',
          'Average Rating': item.average_rating,
          Category: stringifyNames(item.category),
          Attribute: stringifyNames(item.attribute),
          Brand: stringifyNames(item.brand),
          Requested: item.is_request ? 'Yes' : 'No',
          Retailers: item.allowed_retailer.length,
        })),
        'Products',
      )
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later')
      console.error(error)
    } finally {
      toast.dismiss()
    }
  }

  useEffect(() => {
    if (products.length > 0) computeSummaryValues()
  }, [products])

  useEffect(() => {
    fetchProducts()
  }, [activeTab, searchValue, currentPage, rowsPerPage, selectedCategories])

  useEffect(() => {
    fetchCategories()
    fetchSummary()
  }, [])

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Products" />

        <div className="grid grid-cols-4 gap-5">
          {summaryData.map((d, idx) => {
            return (
              <div key={idx} className="px-5 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">{d.title}</h1>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-1">
                    <p className="text-[20px] font-semibold">
                      {d.amount
                        ? `₦${Number(d.amount).toLocaleString('en')}`
                        : d.quantity.toLocaleString()}
                    </p>
                    {/* {d.growth && (
                      <div className="flex items-center text-gray3">
                        <IoArrowUpOutline className="w-3 h-3" />

                        <p className="text-sm font-medium">{d.growth}</p>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <SearchBar
          filterButton={
            <FilterButton
              activeTab={activeTab}
              categories={categories}
              onFinish={(selections) => {
                setCurrentPage(1)
                setSelectedCategories(selections)
              }}
            />
          }
          placeholder="Search Products"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onExport={getProductsExportData}
        >
          {tabs.map((item) => (
            <TabButton
              key={item.id}
              activeTab={activeTab}
              onClick={() => {
                setCurrentPage(1)
                setActiveTab(item.id)
              }}
              tab={item.id}
              title={item.label}
            />
          ))}
        </SearchBar>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : (
          <>
            {activeTab === tabs[0].id && (
              <>
                {products.length > 0 || searchValue.length > 0 ? (
                  <div className="py-5 space-y-5">
                    <div className="flex justify-end">
                      <Button
                        variant="contained"
                        color="primary"
                        className="capitalize shadow-none"
                        startIcon={<IoAddOutline />}
                        onClick={() =>
                          history.push('/products/add-new-product')
                        }
                      >
                        Add New Product
                      </Button>
                    </div>
                    <AvailableProducts
                      rows={products}
                      editLink="/products/requests/edit/{uuid}"
                      viewLink="products/single-product/{uuid}"
                      onDeleteClick={setConfirmDelete}
                      onSwitchClick={handleProductStatus}
                      busy={busy}
                      notFound={notFound}
                      search={searchValue}
                      count={totalProducts}
                      rowsPerPage={rowsPerPage}
                      page={currentPage}
                      onRowsPerPageChange={(rows) => {
                        setRowsPerPage(rows)
                        setCurrentPage(1)
                      }}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                ) : (
                  <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                    <img src={productsIcon} alt="" className="w-16 h-16" />
                    <p className="text-[18px] font-semibold text-gray1">
                      Your products will show here
                    </p>

                    <p className="text-sm text-gray2">
                      This is where you add and update your products
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      className="capitalize shadow-none"
                      startIcon={<IoAddOutline />}
                      onClick={() => history.push('/products/add-new-product')}
                    >
                      Add New Product
                    </Button>
                  </div>
                )}
              </>
            )}

            {activeTab === tabs[1].id && (
              <>
                {products.length > 0 || searchValue.length > 0 ? (
                  <div className="py-5">
                    <ProductRequests
                      rows={products}
                      busy={busy}
                      search={searchValue}
                      count={totalProducts}
                      page={currentPage - 1}
                      notFound={notFound}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(rows) => {
                        setRowsPerPage(rows)
                        setCurrentPage(1)
                      }}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                ) : (
                  <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                    <img src={productsIcon} alt="" className="w-16 h-16" />
                    <p className="text-[18px] font-semibold text-gray1">
                      Requested products will show here
                    </p>

                    <p className="text-sm text-gray2">
                      This is where you add and update your products
                    </p>
                  </div>
                )}
              </>
            )}

            {activeTab === tabs[2].id && (
              <>
                {products.length > 0 || searchValue.length > 0 ? (
                  <div className="py-5">
                    <DeclinedProducts
                      rows={products}
                      busy={busy}
                      search={searchValue}
                      count={totalProducts}
                      page={currentPage - 1}
                      notFound={notFound}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(rows) => {
                        setRowsPerPage(rows)
                        setCurrentPage(1)
                      }}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                ) : (
                  <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                    <img src={productsIcon} alt="" className="w-16 h-16" />
                    <p className="text-[18px] font-semibold text-gray1">
                      Declined products will show here
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <ConfirmDeleteModal
          open={Boolean(confirmDelete)}
          onClose={() => setConfirmDelete(null)}
          onConfirm={() => {
            deleteProductItem(confirmDelete)
            setConfirmDelete(null)
          }}
        >
          <p className="text-center max-w-[40ch] mx-auto">
            You're about to delete
            <br />
            <span className="text-orange font-medium">
              "{confirmDelete?.name}"
            </span>
            <br />
            <br />
            This action is irreversible.
          </p>
        </ConfirmDeleteModal>
      </div>
    </Layout>
  )
})

export default Index
