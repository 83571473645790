import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

//icons
import excel from '../../assets/excel.svg'
import searchIcon from '../../assets/search.svg'

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import TabButton from '../../components/TabButton/TabButton'
import BusinessProfile from '../../components/RetailerBusinessProfile/BusinessProfile'
import RetailerProducts from '../../components/RetailerProducts/RetailerProducts'
// import RetailerBrands from '../../components/RetailerBrands/RetailerBrands'
import TransactionHistory from '../../components/RetailerTransactionHistory/TransactionHistory'
import OrderHistory from '../../components/RetailerOrderHistory/OrderHistory'
import DateActions from '../../components/DateActions/DateActions'
import Loader from '@components/Loader/Loader'

// services
import { getRetailer } from '../../services/retailers'
import { searchRetailerTransactions } from '../../services/finance'
import { getRetailerStock } from '../../services/stock'
import { getOrder, getOrders, getOrdersSummary } from '../../services/orders'
import { exportData, formatDate, formatTime } from '../../utils'

const tabs = {
  profile: 'Business Profile',
  products: 'Products',
  // brands: 'Brands',
  orders: 'Orders',
  transactions: 'Transaction History',
}

const SingleRetailer = () => {
  const params = useParams()
  const location = new URLSearchParams(window.location.search)
  const tab = location.get('tab')
  const orderId = location.get('order')

  const history = useHistory()

  const [busy, setBusy] = useState(true)
  const [retailer, setRetailer] = useState({
    id: 0,
    uuid: '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
    name: 'string',
    manager: {
      id: 0,
      uuid: '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
      email: 'user@example.com',
      phone_number: 'string',
      is_active: true,
      account_type: 'Customer',
      profile: {
        uuid: '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
        id: 0,
        first_name: 'string',
        last_name: 'string',
        gender: 'male',
        picture: 'http://example.com',
        date_of_birth: '2019-08-24',
        business_name: 'string',
        business_email: 'user@example.com',
        website: 'string',
        business_mobile: 'string',
        bio: 'string',
      },
      referral: {
        refer_code: 'string',
        reward: [
          {
            points: 'string',
          },
        ],
      },
      delivery_address: [0],
    },
    logo: 'http://example.com',
    store_image: 'http://example.com',
    bio: 'string',
    business_email: 'user@example.com',
    business_phone: 'string',
    website: 'string',
    address: {
      id: 0,
      uuid: '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
      created_at: '2019-08-24T14:15:22Z',
      updated_at: '2019-08-24T14:15:22Z',
      is_active: true,
      address: 'string',
      street_name: 'string',
      town: 'string',
      city: 'string',
      country: 'string',
      administrative_area_level_1: 'string',
      administrative_area_level_2: 'string',
      post_code: 'string',
      plus_code: 'string',
      lon: 'string',
      lat: 'string',
    },
    media: [
      {
        id: 0,
        uuid: '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
        media: 'http://example.com',
      },
    ],
    users: [0],
    status: 'Pending',
    average_rating: 0,
  })
  const [search, setSearch] = useState('')
  // const [searchResults, setSearchResults] = useState([])
  const [receipt, setReceipt] = useState(null)
  const [activeTab, setActiveTab] = useState('Business Profile')
  const [products, setProducts] = useState({
    data: [],
    search: '',
    count: 0,
    page: 1,
    rowsPerPage: 10,
    exporting: false,
  })
  const [productsController, setProductsController] = useState(null)

  const [transactions, setTransactions] = useState({
    data: [],
    search: '',
    count: 0,
    page: 1,
    rowsPerPage: 10,
    period: 'today',
    dateFilter: 'period',
    dateRange: {
      start: moment(),
      end: moment().add(2, 'M'),
    },
    exporting: false,
  })
  const [transactionsController, setTransactionsController] = useState(null)

  const [orders, setOrders] = useState({
    summary: {
      total: 0,
      cancellationRate: 0,
    },
    data: [],
    search: '',
    count: 0,
    page: 1,
    rowsPerPage: 10,
    period: 'today',
    dateFilter: 'period',
    dateRange: {
      start: moment(),
      end: moment().add(2, 'M'),
    },
    tab: 'All',
    exporting: false,
  })
  const [ordersController, setOrdersController] = useState(null)

  const setProductsFieldData = (fieldData) => {
    setProducts((prev) => {
      return {
        ...prev,
        ...fieldData,
      }
    })
  }

  const setOrdersFieldData = (fieldData) => {
    setOrders((prev) => {
      return {
        ...prev,
        ...fieldData,
      }
    })
  }

  const setTransactionsFieldData = (fieldData) => {
    setTransactions((prev) => {
      return {
        ...prev,
        ...fieldData,
      }
    })
  }

  const fetchRetailer = async () => {
    try {
      const { data } = await getRetailer(params.retailer)
      setRetailer(data)
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch retailer data.')
      history.goBack()
    }
  }

  const fetchRetailerProducts = async () => {
    productsController?.abort?.()
    const abc = new AbortController()
    setProductsController(abc)

    setBusy(true)

    try {
      const { data } = await getRetailerStock(
        {
          search: products.search,
          retailer: params.retailer,
          page: products.page,
          page_size: products.rowsPerPage,
        },
        abc.signal,
      )
      setProductsFieldData({
        data: data.results,
        count: data.count,
      })
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        setBusy(false)
        toast.error("Failed to fetch retailer's products.")
      }
    }
  }

  const fetchRetailerOrdersSummary = async () => {
    try {
      const { data } = await getOrdersSummary(params.retailer)
      setOrdersFieldData({
        summary: {
          total: data.total_orders.count,
          cancellationRate:
            (data.cancelled.count / data.total_orders.count) * 100,
        },
      })
    } catch {}
  }

  const fetchOrder = async () => {
    setBusy(true)

    try {
      const { data } = await getOrder(orderId)

      // setOrdersFieldData({
      //   data: data,
      //   count: data.count,
      // })
      setReceipt(data)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch orders. Please try again later.')
        setBusy(false)
      }
    }
  }

  const fetchRetailerOrders = async () => {
    ordersController?.abort?.()
    const abc = new AbortController()
    setOrdersController(abc)

    setBusy(true)

    try {
      const { data } = await getOrders(
        params.retailer,
        {
          search: orders.search,
          ...(orders.dateFilter === 'range'
            ? {
                date_range_before: orders.dateRange.end.format('YYYY-MM-DD'),
                date_range_after: orders.dateRange.start.format('YYYY-MM-DD'),
              }
            : {
                date: orders.period,
              }),
          ...(orders.tab !== 'All' ? { status: orders.tab } : {}),
          ordered: true,
          page: orders.page,
          page_size: orders.rowsPerPage,
        },
        abc.signal,
      )
      setOrdersFieldData({
        data: data.results,
        count: data.count,
      })
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error("Failed to fetch retailer's transaction history")
        setBusy(false)
      }
    }
  }

  const fetchTransactionHistory = async () => {
    transactionsController?.abort?.()
    const abc = new AbortController()
    setTransactionsController(abc)

    setBusy(true)

    try {
      const { data } = await searchRetailerTransactions(
        {
          search: transactions.search,
          retailer: params.retailer,
          ...(transactions.dateFilter === 'range'
            ? {
                date_range_before:
                  transactions.dateRange.end.format('YYYY-MM-DD'),
                date_range_after:
                  transactions.dateRange.start.format('YYYY-MM-DD'),
              }
            : {
                date: transactions.period,
              }),
          page: transactions.page,
          page_size: transactions.rowsPerPage,
        },
        abc.signal,
      )
      setTransactionsFieldData({
        data: data.results,
        count: data.count,
      })
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error("Failed to fetch retailer's transaction history")
        setBusy(false)
      }
    }
  }

  const exportTableData = async () => {
    switch (activeTab) {
      case tabs.products:
        exportRetailerProducts()
        break
      case tabs.transactions:
        exportTransactions()
        break
      case tabs.orders:
        exportRetailerOrders()
        break
    }
  }

  const exportRetailerProducts = async () => {
    setProductsFieldData({ exporting: true })
    toast.info("Exporting retailer's products")
    try {
      const { data } = await getRetailerStock({
        search: products.search,
        retailer: params.retailer,
        paginate: 'no',
      })
      exportData(
        data.map((entry) => ({
          Product: entry.product.name,
          'Retail Price': `${entry.selling_price.currency} ${entry.selling_price.amount}`,
          Quantity: entry.quantity,
          Status: entry.out_of_stock ? 'Out of Stock' : 'In Stock',
          Store: entry.store,
        })),
        'Retailer Products',
      )
      toast.success("Successfully exported retailer's products.")
    } catch {
      toast.error("Failed to export retailer's products")
    } finally {
      setProductsFieldData({ exporting: false })
    }
  }

  const exportTransactions = async () => {
    setTransactionsFieldData({ exporting: true })
    toast.info("Exporting retailer's transaction history.")
    try {
      const { data } = await searchRetailerTransactions({
        search: transactions.search,
        retailer: params.retailer,
        ...(transactions.dateFilter === 'range'
          ? {
              date_range_before:
                transactions.dateRange.end.format('YYYY-MM-DD'),
              date_range_after:
                transactions.dateRange.start.format('YYYY-MM-DD'),
            }
          : {
              date: transactions.period,
            }),
        paginate: 'no',
      })
      exportData(
        data.map((entry) => ({
          'Invoice ID': entry.order.order_id,
          Customer: [
            entry.order.user.profile.first_name,
            entry.order.user.profile.last_name,
          ]
            .filter(Boolean)
            .join(' '),
          Date: formatDate(entry.order.created_at),
          Time: formatTime(entry.order.created_at),
          Amount: `${entry.amount.currency} ${entry.amount.amount}`,
          Status: entry.status,
        })),
        'Retailer Transactions',
      )
      toast.success("Successfully exported retailer's transaction history.")
    } catch {
      toast.error("Failed to export retailer's transaction history.")
    } finally {
      setTransactionsFieldData({ exporting: false })
    }
  }

  const exportRetailerOrders = async () => {
    setOrdersFieldData({ exporting: true })
    toast.info("Exporting retailer's orders history.")

    try {
      const res = await getOrders(params.retailer, {
        search: orders.search,
        ...(orders.dateFilter === 'range'
          ? {
              date_range_before: orders.dateRange.end.format('YYYY-MM-DD'),
              date_range_after: orders.dateRange.start.format('YYYY-MM-DD'),
            }
          : {
              date: orders.period,
            }),
        ...(orders.tab !== 'All' ? { status: orders.tab } : {}),
        ordered: true,
        paginate: 'no',
      })
      exportData(
        res.data.map((entry) => ({
          'Invoice ID': entry.order_id,
          Customer: `${entry.user.profile.first_name} ${entry.user.profile.last_name}`,
          'Phone Number': entry.user.phone_number || '',
          'Date Created': formatDate(entry.created_at),
          'Payment Date': entry.payment_date
            ? formatDate(entry.payment_date)
            : '',
          'Payment Time': entry.payment_date
            ? formatTime(entry.payment_date)
            : '',
          Status: entry.status,
          'Delivery Address': entry.delivery_address?.address,
          'Delivery Date': formatDate(entry.delivery_date),
          'Delivery Time': entry.delivery_time || '',
          'Delivery Fee': `${entry.delivery_fee?.currency} ${entry.delivery_fee?.amount}`,
          Tax: entry.tax,
          Amount: `${entry.final_cost?.currency} ${entry.final_cost?.amount}`,
        })),
        'Retailer Orders',
      )
      toast.success("Successfully exported retailer's order history.")
    } catch {
      toast.error("Failed to export retailer's order history.")
    } finally {
      setOrdersFieldData({ exporting: false })
    }
  }

  function setTabSearch(v) {
    switch (activeTab) {
      case tabs.transactions:
        setTransactionsFieldData({ page: 1, search: v })
        break
      case tabs.products:
        setProductsFieldData({ page: 1, search: v })
        break
      case tabs.orders:
        setOrdersFieldData({ page: 1, search: v })
        break
      default:
        break
    }
    setSearch(v)
  }

  useEffect(() => {
    fetchRetailer()
  }, [])

  useEffect(() => {
    switch (activeTab) {
      case tabs.transactions:
        setSearch(transactions.search)
        if (!transactions.count) {
          fetchTransactionHistory()
        }
        break
      case tabs.products:
        setSearch(products.search)
        if (!products.count) {
          fetchRetailerProducts()
        }
        break
      case tabs.orders:
        setSearch(orders.search)
        if (!orders.count) {
          fetchRetailerOrders()
        }
        if (!orders.summary.total) {
          fetchRetailerOrdersSummary()
        }
        break
      default:
        setSearch('')
        break
    }
  }, [activeTab])

  useEffect(() => {
    if (activeTab === tabs.transactions) {
      fetchTransactionHistory()
    }
  }, [
    transactions.dateRange,
    transactions.page,
    transactions.search,
    transactions.period,
    transactions.rowsPerPage,
  ])

  useEffect(() => {
    if (activeTab === tabs.orders) {
      fetchRetailerOrders()
    }
  }, [
    orders.dateRange,
    orders.page,
    orders.tab,
    orders.search,
    orders.period,
    orders.rowsPerPage,
  ])

  useEffect(() => {
    if (activeTab === tabs.products) {
      fetchRetailerProducts()
    }
  }, [products.page, products.search, products.rowsPerPage])

  useEffect(() => {
    if (tab && orderId) {
      setActiveTab('Orders')

      fetchOrder()
    }
  }, [])

  return (
    <Layout>
      <div className="px-[30px] py-5">
        <PageTitle
          onBackPress={() => history.goBack()}
          title={retailer ? retailer.name : ''}
        />

        <div className="flex justify-between px-3 bg-white rounded-md">
          <div className="space-x-5">
            {Object.values(tabs).map((title) => (
              <TabButton
                key={title}
                activeTab={activeTab}
                onClick={() => setActiveTab(title)}
                title={title}
              />
            ))}
          </div>

          <div className="flex items-center space-x-3">
            <div className="bg-[#F4F5F7] px-2 rounded-md w-[300px] flex items-center">
              <img src={searchIcon} alt="" />
              <input
                type="search"
                placeholder="Search"
                value={search}
                className="w-full px-2 py-1 bg-transparent border-0 focus:outline-none focus:ring-0"
                onChange={(e) => setTabSearch(e.target.value)}
              />
            </div>

            <Button
              variant="outlined"
              className="capitalize border border-[#E3E7ED] text-gray3"
              size="small"
              endIcon={<img src={excel} alt="" />}
              disabled={
                activeTab === tabs.products
                  ? products.exporting
                  : activeTab === tabs.transactions
                  ? transactions.exporting
                  : activeTab === tabs.orders
                  ? orders.exporting
                  : false
              }
              onClick={exportTableData}
            >
              Export
            </Button>
          </div>
        </div>

        {busy && !retailer ? (
          <Loader />
        ) : (
          <div className="mt-5">
            {activeTab === tabs.profile && (
              <BusinessProfile retailer={retailer} />
            )}
            {activeTab === tabs.products && (
              <RetailerProducts
                data={products.data}
                busy={busy}
                search={products.search}
                page={products.page}
                count={products.count}
                rowsPerPage={products.rowsPerPage}
                onPageChange={(pg) => setProductsFieldData({ page: pg })}
                onRowsPerPageChange={(rows) => {
                  setProductsFieldData({ page: 1, rowsPerPage: rows })
                }}
              />
            )}
            {/* {activeTab === tabs.brands && (
              <RetailerBrands
                retailerId={params.retailer}
                search={search}
                notFound={brandsNotFound}
                searchResults={searchResults}
              />
            )} */}
            {activeTab === tabs.orders && (
              <div className="">
                <div className="grid grid-cols-4 gap-3 mb-3">
                  <div className="px-5 py-2 bg-white rounded-md">
                    <div className="text-[12px] text-gray3 capitalize">
                      Total Orders
                    </div>
                    <div className="text-[20px] font-semibold">
                      {Number(orders.summary.total).toLocaleString('en')}
                    </div>
                  </div>
                  <div className="px-5 py-2 bg-white rounded-md">
                    <div className="text-[12px] text-gray3 capitalize">
                      Cancellation Rate
                    </div>
                    <div className="text-[20px] font-semibold">
                      {orders.summary.cancellationRate.toFixed(2)}%
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center h-full bg-white px-4 mb-3">
                  {[
                    'All',
                    'Completed',
                    'Pending',
                    // 'Processing',
                    // 'Ready for Pickup',
                    // 'On Delivery',
                    'Cancelled',
                  ].map((item, index) => (
                    <span
                      key={index}
                      onClick={() => {
                        setOrdersFieldData({ tab: item })
                      }}
                      className={`border-b-4 cursor-pointer pt-5 font-medium capitalize text-[16px] pb-2 mr-8 ${
                        orders.tab === item
                          ? 'text-[#040A1D] border-[#F3641F] h-full'
                          : 'border-white text-[#9CA7B8]'
                      }`}
                    >
                      {item}
                    </span>
                  ))}
                </div>
                <DateActions
                  activePeriod={
                    orders.dateFilter === 'period' ? orders.period : undefined
                  }
                  changePeriod={(period) => {
                    setOrdersFieldData({
                      dateFilter: 'period',
                      period,
                      page: 1,
                    })
                  }}
                  className="justify-end mb-6"
                  dateRange={orders.dateRange}
                  onDateRangeSelect={(range) => {
                    setOrdersFieldData({
                      page: 1,
                      dateFilter: 'range',
                      dateRange: range,
                    })
                  }}
                />
                <OrderHistory
                  data={orders.data}
                  busy={busy}
                  search={orders.search}
                  page={orders.page}
                  count={orders.count}
                  receipt={receipt}
                  rowsPerPage={orders.rowsPerPage}
                  onPageChange={(pg) => setOrdersFieldData({ page: pg })}
                  onRowsPerPageChange={(rows) => {
                    setOrdersFieldData({ page: 1, rowsPerPage: rows })
                  }}
                />
              </div>
            )}
            {activeTab === tabs.transactions && (
              <div className="space-y-3">
                <DateActions
                  activePeriod={
                    transactions.dateFilter === 'period'
                      ? transactions.period
                      : undefined
                  }
                  changePeriod={(period) => {
                    setTransactionsFieldData({
                      dateFilter: 'period',
                      period,
                      page: 1,
                    })
                  }}
                  className="justify-end"
                  dateRange={transactions.dateRange}
                  onDateRangeSelect={(range) => {
                    setTransactionsFieldData({
                      page: 1,
                      dateFilter: 'range',
                      dateRange: range,
                    })
                  }}
                />
                <TransactionHistory
                  data={transactions.data}
                  busy={busy}
                  search={transactions.search}
                  page={transactions.page}
                  count={transactions.count}
                  rowsPerPage={transactions.rowsPerPage}
                  onPageChange={(pg) => setTransactionsFieldData({ page: pg })}
                  onRowsPerPageChange={(rows) => {
                    setTransactionsFieldData({ page: 1, rowsPerPage: rows })
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SingleRetailer
