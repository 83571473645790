import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Radio, RadioGroup, FormControlLabel, Popover } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
// import Cookies from "js-cookie";
import { Calendar, utils } from 'react-modern-calendar-datepicker'
import { TimePicker } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router'
import { BsFillCalendarFill } from 'react-icons/bs'
import { toast } from 'react-toastify'

// assets
import Layout from '../../components/Layout/Layout'
import backArrow from '../../assets/back-arrow.svg'
import blackArrow from '../../assets/black-round-arrow.svg'
import searchIcon from '../../assets/search.svg'
import deleteIcon from '../../assets/delete.svg'
import loader from '../../assets/loader.svg'
// import orders from "../../assets/orders-inactive.svg";

// components
import CustomerListMenu from '../../components/CustomerListMenu/CustomerListMenu'
import CreateCustomerModal from '../../components/CreateCustomerModal/CreateCustomerModal'
import ProductListMenu from '../../components/ProductListMenu/ProductListMenu'
import TableTotals from '../../components/TableTotals/TableTotals'
import UnsavedChanges from '../../components/UnsavedChanges/UnsavedChanges'
import OrdersTableAlt from '../../components/OrdersTableAlt/OrdersTableAlt'
// import SaveModal from "../../components/SaveModal/SaveModal.jsx";
import EmailInvoice from '../../components/EmailInvoice/EmailInvoice.jsx'
import { payments, statuses, deliveryOptions } from '../../enums.js'
import FormInput from '../../components/FormInput/FormInput'
import { RootContext } from '../..'

// services
import { getStock } from '../../services/stock.js'
import {
  createCustomer,
  getDeliveryAddress,
  searchCustomers,
  updateProfile,
  updateAddress,
} from '../../services/users.js'
// import { addPaymentMethod, createOrder } from "../../services/orders.js";
import SplitPayment from './splitPayment'
// import { formatDate } from "../../utils";

const format = 'HH:mm'

const CreateOrder = observer(() => {
  const root = useContext(RootContext)

  // customer list menu
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null)
  const [customers, setCustomers] = useState([])

  // stock list menu
  const [itemAnchorEl, setItemAnchorEl] = useState(null)
  const [stock, setStock] = useState([])

  // customer search input
  const [customerSearch, setCustomerSearch] = useState('')

  // stock search input
  const [stockSearch, setStockSearch] = useState([])
  const [searchingStock, setSearchingStock] = useState(false)

  // active customer
  const [customer, setCustomer] = useState(null)

  const [busy, setBusy] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [status, setStatus] = useState('Pending')
  const [paymentType, setPaymentType] = useState(payments[0].value)
  const [deliverOption, setDeliverOption] = useState(deliveryOptions.DELIVER)
  const [showExitModal, setShowExitModal] = useState(false)
  const [adding, setAdding] = useState(false)
  const [subtotal, setSubtotal] = useState(0)
  const [shipping] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [tax] = useState(0)
  const [proceed, setProceed] = useState(false)
  // const [delivery, setDelivery] = useState();
  const [showEmail, setShowEmail] = useState(false)
  const [notFound, setNotFound] = useState(false)

  // delivery date
  const [selectedDay, setSelectedDay] = useState(null)
  const [date, setDate] = useState('')

  // delivery time
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [orderNote, setOrderNote] = useState('')

  // calendar popover
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'calendar-popover' : undefined

  const [searchController, setSearchController] = useState(null)

  const history = useHistory()

  // customer search input timer
  let typingTimer

  // const getToken = () => {
  //   return Cookies.get("auth_token");
  // };

  const handlePopoverClose = () => {
    setAnchorEl(false)
  }

  useEffect(() => {
    if (selectedDay)
      setDate(
        `${selectedDay.year}-${formatMonth(selectedDay.month)}-${
          selectedDay.day
        }`,
      )
  }, [selectedDay])

  const formatMonth = (month) => {
    if (parseInt(month) < 10) return `0${month}`
    return month
  }

  const handleClear = () => {
    setSelectedProducts([])
    setCustomer(null)
    setShowSaveModal(false)
    setProceed(false)
  }

  const handleTimeChange = (time) => {
    setDeliveryTime(time)
  }

  // const handleDeliveryChange = (event) => {
  //   if(event.target.value.length === 1 && event.target.value.substring(0, 1) !== '₦') {
  //     setDelivery(`₦${event.target.value}`)
  //     return;
  //   }
  //   setDelivery(`${event.target.value}`)
  // }

  const handleCustomerSearchChange = (event) => {
    setNotFound(false)
    setCustomerSearch(event.target.value)
  }

  const handleCustomerInputFocus = (event) => {
    setCustomerAnchorEl(event.currentTarget)
  }

  const handleStockInputFocus = (event) => {
    setItemAnchorEl(event.currentTarget)
  }

  const handleCustomerMenuClose = () => {
    setCustomerAnchorEl(null)
  }

  // const handleItemSearch = (event) => {
  //   setStockSearch(event.target.value);
  // };

  const handleItemMenuClose = () => {
    setItemAnchorEl(null)
  }

  const handleCustomerPress = async (newCustomer) => {
    setCustomerAnchorEl(null)
    const address = newCustomer.delivery_address[0]
    if (address) {
      try {
        const { data } = await getDeliveryAddress(address)
        newCustomer.address = data
      } catch (error) {
        // toast.error("Unable to select this customer. Please make sure they have a delivery address.")
      }
    }
    setCustomer(newCustomer)
  }

  const isDuplicate = (selectedProduct) => {
    const found = selectedProducts.find(
      (product) => product.id === selectedProduct.id,
    )
    return Boolean(found)
  }

  const handleProductPress = (selectedProduct) => {
    if (selectedProduct.out_of_stock || selectedProduct.quantity === 0) {
      toast.info('This product is out of stock')
      return
    }
    if (isDuplicate(selectedProduct)) {
      toast.info('This product is a duplicate')
      return
    }
    setItemAnchorEl(null)
    let newProduct = { ...selectedProduct }
    newProduct.stock_count = newProduct.quantity
    newProduct.quantity = 1
    setSelectedProducts((prevState) => [...prevState, newProduct])
    // setStockSearch("");
  }

  const leavePage = () => {
    if (proceed) setProceed(false)
    else if (selectedProducts.length > 0 || customer) setShowExitModal(true)
    else history.goBack()
  }

  const handleCreateCustomer = async (form) => {
    setShowCreateModal(false)
    setAdding(true)
    form.related_retailers.push(root.authStore.user.retailerId)
    const profile = form.profile
    let address = form.address
    delete form.address
    delete form.profile
    try {
      const { data } = await createCustomer(form).then(async (res) => {
        const response = await updateProfile(profile, res.data.profile)
        address.user = res.data.id
        const { data } = await updateAddress(address)
        res.data.address = data
        res.data.profile = response.data
        return res
      })
      setCustomer(data)
      toast.success('Customer created successfully')
      setAdding(false)
      setCustomerAnchorEl(null)
    } catch (error) {
      const msg =
        error.response.data?.email?.[0] ||
        error.response.data?.phone_number?.[0] ||
        'Unable to create customer. Please try again later'
      toast.error(msg)
      setAdding(false)
      setCustomerAnchorEl(null)
    }
  }

  const handleCustomerSearchKeyUp = () => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(searchCustomer, 500)
  }

  const handleCustomerSearchKeyDown = () => {
    clearTimeout(typingTimer)
  }

  const searchCustomer = async () => {
    if (customerSearch.length === 0) return

    setBusy(true)
    try {
      const { data } = await searchCustomers(customerSearch, 1, 100)
      setCustomers(data.results)
      if (data.count === 0) setNotFound(true)
      else setNotFound(false)
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('Unable to fetch customer data. Please try again later.')
    }
  }

  const searchRetailerStock = async () => {
    searchController?.abort?.()
    const abc = new AbortController()
    setSearchController(abc)

    setSearchingStock(true)

    try {
      const { data } = await getStock(
        root.authStore.user.retailerUuid,
        {
          search: stockSearch,
          // page: 1,
          page_size: 50,
        },
        abc.signal,
      )
      setStock(data.results)
      setSearchingStock(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch inventory. Please try again later.')
        setSearchingStock(false)
      }
    }
  }

  const setProductPrice = (hook, value, id, amount) => {
    setSelectedProducts(
      selectedProducts.map((prod) => {
        if (prod.id === id) {
          prod.selling_price.amount = amount
        }
        return prod
      }),
    )
  }

  const adjustProductQuantity = (hook, value, qty, id) => {
    const products = [...selectedProducts]
    products.forEach((prod) => {
      if (prod.id === id) {
        prod.quantity = qty
      }
    })
    setSelectedProducts(products)
  }

  const deleteProduct = (hook, value, id) => {
    const newProducts = selectedProducts.filter((item) => item.id !== id)
    setSelectedProducts(newProducts)
  }

  const updateTotals = useCallback(() => {
    let total = 0
    selectedProducts.forEach((element) => {
      total += element.selling_price.amount * element.quantity
    })
    setSubtotal(total)
  }, [selectedProducts])

  useEffect(() => {
    if (selectedProducts) updateTotals()
  }, [selectedProducts, updateTotals])

  const validateProducts = () => {
    let status = true
    selectedProducts.forEach((product) => {
      if (product.quantity === 0) {
        toast.info(
          `Please enter your desired quantity of ${product.product.name}`,
        )
        status = false
      }
    })
    return status
  }

  const validateOrder = () => {
    if (!customer) {
      toast.info('Please select a customer')
      return false
    }
    if (deliverOption === deliveryOptions.DELIVER && !customer.address) {
      toast.info('Please make sure the customer has a delivery address.')
      return false
    }
    if (selectedProducts.length === 0) {
      toast.info('Please select a product')
      return false
    }
    if (!validateProducts()) return false
    if (!status) {
      toast.info('Please select a payment status')
      return false
    }
    if (!paymentType) {
      toast.info('Please select a payment method')
      return false
    }
    if (!date) {
      toast.info('Please select a delivery date')
      return false
    }
    if (!deliveryTime) {
      toast.info('Please select a delivery time')
      return false
    }
    return true
  }

  const generateOrderForm = () => {
    const items = selectedProducts.map((product) => ({
      stock: product.id,
      quantity: product.quantity,
      price: product.selling_price.amount,
    }))
    return {
      discount_total: discount,
      status: status,
      order_item: items,
      delivery_date: date,
      delivery_time: deliveryTime.format('LT'),
      order_note: orderNote,
      retailer: root.authStore.user.retailerId,
      user: customer.id,
      delivery_address: customer.address.id,
      delivery_options: deliverOption,
      sale_point: 'In-store',
    }
  }

  const [storedForm, setStoredForm] = useState(null)

  const makePayment = () => {
    if (!validateOrder()) return false

    const form = generateOrderForm()
    setStoredForm(form)
    setProceed(true)
  }

  //   const submitOrder = async () => {
  //     if (!validateOrder()) return false;

  //     setBusy(true);
  //     setShowSaveModal(true);
  // const token = getToken();
  //     const form = generateOrderForm();
  //     setStoredForm(form);

  //     try {
  //       await createOrder(token, form).then(async (res) => {

  //     await addPaymentMethod(token, res.data.uuid, {
  //           payment_type: paymentType,
  //           is_dashboard: true,
  //           customer_uuid: customer.uuid,
  //         });
  //       });
  //       setBusy(false);
  //     } catch (error) {
  //       setShowSaveModal(false);
  //       setBusy(false);
  //       toast.error(
  //         error.error ||
  //           error.message ||
  //           "Unable to create order. Please try again later."
  //       );
  //     }
  //   };

  const handleProceed = (state) => {
    setProceed(state)
  }

  useEffect(() => {
    searchRetailerStock()
  }, [stockSearch])

  const delivery =
    selectedProducts.length > 0
      ? deliverOption === deliveryOptions.DELIVER
        ? +(root.authStore.user?.delivery_price || 0)
        : 0
      : 0

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center mb-10">
        <div className="flex flex-row items-center w-[830px] mb-[21px]">
          <button className="bg-transparent" onClick={leavePage}>
            <img
              src={backArrow}
              alt="back arrow"
              className="w-[40px] h-[40px]"
            />
          </button>
          <h1 className="text-[#040A1D] text-[30px] font-bold ml-[22px]">
            Create Order
          </h1>
        </div>
        <div className="w-[830px] bg-white rounded px-[30px] flex flex-row relative mb-[41px]">
          <div className="flex flex-col pt-[22px] pb-[30px]">
            <img
              src={root.authStore.user.logo}
              alt="logo"
              className="w-[auto] h-[auto] mb-[20px] max-h-[100px] max-w-[100px]"
            />
            <p className="w-[181px] text-[#4C536A] text-[14px]">
              {root.authStore.user.name}
            </p>
            <p className="w-[181px] text-[#4C536A] text-[14px]">
              {root.authStore.user.address &&
                root.authStore.user.address.address}
            </p>
            <p className="text-[#4C536A] text-[14px] mt-[10px]">
              {root.authStore.user.phone_number}
            </p>
          </div>
          <img
            src={blackArrow}
            alt="arrow"
            className="w-[19.2px] h-[19.2px] absolute top-[45%] left-[285px]"
          />
          <div className="border-dashed border border-[#E3E7ED] ml-[83px] border-b-0 border-t-0 border-l-0 mr-[60px]"></div>
          <div className="flex flex-col  pt-[22px]">
            {customer ? ( // customer meta
              <div className="flex flex-col w-[425px]">
                <div className="flex flex-row items-center justify-between">
                  <span className="flex flex-col">
                    <p className="text-[#040A1D] text-[16px] font-bold mb-0">
                      {customer.profile.first_name} {customer.profile.last_name}
                    </p>
                    {customer.email ? (
                      <p className="text-[#477DFB] text-[14px] mb-0">
                        {customer.email}
                      </p>
                    ) : null}
                  </span>
                  <button
                    onClick={() => setCustomer(null)}
                    className="bg-transparent border border-[#E3E7ED] rounded-md w-[37px] h-[32px] flex justify-center items-center"
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
                <div className="h-[1px] w-full bg-[#E3E7ED] my-2"></div>
                {customer.address ? (
                  <div className="flex flex-row">
                    <span className="flex flex-col mr-[41px]">
                      <p className="w-[141px] text-[#4C536A] text-[14px]">
                        {customer.address.address}
                      </p>
                      <p className="w-[141px] text-[#4C536A] text-[14px]">
                        {customer.address.city}
                      </p>
                    </span>
                    <span className="flex flex-col">
                      <p className="w-[141px] text-[#4C536A] text-[14px]">
                        {customer.address.state} {customer.address.state && ','}{' '}
                        {customer.address.country}
                      </p>
                      <p className="w-[141px] text-[#4C536A] text-[14px]">
                        {customer.address.postal}
                      </p>
                      <p className="w-[141px] text-[#477DFB] text-[14px]">
                        {customer.phone_number}
                      </p>
                    </span>
                  </div>
                ) : (
                  <p className="text-[#4C536A] text-[14px]">
                    No address available
                  </p>
                )}
              </div>
            ) : customer && customer.phone_number ? (
              <div className="flex flex-col w-[425px]">
                <div className="flex flex-row justify-between">
                  <span className="flex flex-col">
                    <p className="text-[#040A1D] text-[16px] font-bold">
                      {customer.email}
                    </p>
                    <p className="text-[#477DFB] text-[14px]">
                      {customer.phone_number}
                    </p>
                  </span>
                  <button
                    onClick={() => setCustomer(null)}
                    className="bg-transparent border border-[#E3E7ED] rounded-md w-[37px] h-[32px] flex justify-center items-center"
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              </div>
            ) : adding ? (
              <div className="flex flex-col items-center justify-center w-[400px] h-full">
                <p className="text-[#4C536A] font-medium">
                  Adding Customer Details
                </p>
                <img src={loader} alt="loading" className="w-5 animate-spin" />
              </div>
            ) : (
              <>
                <p className="text-[#3D4356] text-[16px] font-medium mb-[17px]">
                  Find or create a customer
                </p>
                <span
                  aria-controls="customers-menu"
                  aria-haspopup="true"
                  className="flex flex-row items-center bg-[#F4F5F7] rounded-md w-[320px] px-[10px] h-[32px] focus-within:border-b focus-within:border-appBlue"
                >
                  <img
                    src={searchIcon}
                    alt="search"
                    className="w-[16px] h-[16px]"
                  />
                  <input
                    className="bg-transparent text-[14px] outline-none pl-[10px] font-medium w-full"
                    placeholder="Search Customer"
                    onChange={handleCustomerSearchChange}
                    onFocus={handleCustomerInputFocus}
                    onKeyUp={handleCustomerSearchKeyUp}
                    onKeyDown={handleCustomerSearchKeyDown}
                  />
                </span>
                <CustomerListMenu
                  anchorEl={customerAnchorEl}
                  onItemPress={handleCustomerPress}
                  handleClose={handleCustomerMenuClose}
                  style={{
                    width: '320px',
                    maxHeight: '301px',
                    marginTop: '50px',
                    marginLeft: '-8px',
                    padding: '0px 10px 0px 10px',
                  }}
                  onCreateCustomerClick={() => setShowCreateModal(true)}
                  customers={customers}
                  search={customerSearch}
                  busy={busy}
                  notFound={notFound}
                />
              </>
            )}
          </div>
        </div>
        {!proceed ? (
          <div className="w-[830px]">
            <span
              aria-controls="items-menu"
              aria-haspopup="true"
              className="flex flex-row items-center bg-transparent rounded-md w-full px-[10px] h-[32px] border-b border-[#E3E7ED] focus-within:border-appBlue"
            >
              <img
                src={searchIcon}
                alt="search"
                className="w-[16px] h-[16px]"
              />
              <input
                className="bg-transparent text-[14px] outline-none pl-[10px] font-medium w-full"
                placeholder="Search Item"
                value={stockSearch}
                onChange={(e) => {
                  setStockSearch(e.target.value)
                }}
                onFocus={handleStockInputFocus}
              />
            </span>
            <ProductListMenu
              anchorEl={itemAnchorEl}
              onItemPress={handleProductPress}
              handleClose={handleItemMenuClose}
              products={stock}
              fetching={searchingStock}
              style={{
                width: '830px',
                maxHeight: '301px',
                overflowY: 'auto',
                // minHeight: "100px",
                marginTop: '10px',
                marginLeft: '-8px',
                padding: '0px 10px 0px 10px',
              }}
            />
            {selectedProducts.length === 0 ? (
              <div className="w-[830px] h-[115px] bg-white rounded flex justify-center items-center mt-4">
                <p className="text-[#8892A2] text-[14px] font-normal">
                  Browse items in the search bar above to continue
                </p>
              </div>
            ) : (
              <OrdersTableAlt
                products={selectedProducts}
                hook={setSelectedProducts}
                setQuantity={adjustProductQuantity}
                removeItem={deleteProduct}
                onPriceChange={setProductPrice}
              />
            )}
            <TableTotals
              subtotal={subtotal}
              shipping={shipping}
              tax={tax}
              discount={discount}
              delivery={delivery}
              ordertotal={subtotal + shipping + delivery + tax - discount}
              handleDiscountChange={setDiscount}
            />
            <div className="flex flex-row justify-end mt-4 mr-10">
              <div className="w-[250px] flex flex-col items-end relative">
                <div className="flex flex-row items-center">
                  <p className="pr-6 m-0 text-md text-gray2">Delivery Time</p>
                  <TimePicker
                    className="border-orange"
                    defaultValue={moment('12:08', format)}
                    format={format}
                    use12Hours={true}
                    onChange={handleTimeChange}
                  />
                </div>
                <div className="h-4"></div>
                <FormInput
                  id="calendar-popover"
                  name="date"
                  placeholder="Delivery Date"
                  label="Delivery Date"
                  onFocus={(e) => setAnchorEl(e.currentTarget)}
                  value={date}
                />
                <BsFillCalendarFill
                  className="absolute top-[70px] right-4"
                  color={''}
                />
                <Popover
                  id={id}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Calendar
                    value={selectedDay}
                    onChange={setSelectedDay}
                    shouldHighlightWeekends={false}
                    colorPrimary="#F3641F"
                    colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                    minimumDate={utils().getToday()}
                  />
                </Popover>
              </div>
            </div>
            {/* order note */}
            <textarea
              name="order-note"
              rows="4"
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
              placeholder="Enter invoice note"
              className="bg-white rounded-md border border-transparent w-full mt-4"
            ></textarea>
            {/* delivery options */}
            {selectedProducts.length > 0 &&
              root.authStore.user.account_type.includes('Retailer') && (
                <div className="flex flex-row items-center py-2  border-t border-b border-[#E3E7ED] justify-end mt-6">
                  <p className="text-[#9CA7B8] text-[12px] font-bold mr-[20px] md:uppercase mb-0">
                    Delivery Option
                  </p>
                  <RadioGroup
                    className="flex flex-row items-center"
                    value={deliverOption}
                    onChange={(e) => setDeliverOption(e.target.value)}
                  >
                    <FormControlLabel
                      className={`${
                        deliverOption === deliveryOptions.DELIVER
                          ? 'text-[#F3641F] bg-white'
                          : 'text-[#4C536A] bg-transparent '
                      } font-medium border border-[#E3E7ED] text-[14px] justify-center h-[32px] rounded-md pr-3`}
                      control={<Radio color={'secondary'} />}
                      value={deliveryOptions.DELIVER}
                      label="Home/Office Delivery"
                    />
                    <FormControlLabel
                      className={`${
                        deliverOption === deliveryOptions.PICKUP
                          ? 'text-[#F3641F] bg-white'
                          : 'text-[#4C536A] bg-transparent '
                      } font-medium border border-[#E3E7ED] text-[14px] justify-center h-[32px] rounded-md pr-3`}
                      control={<Radio color={'secondary'} />}
                      value={deliveryOptions.PICKUP}
                      label="Pickup"
                    />
                  </RadioGroup>
                </div>
              )}
            {selectedProducts.length > 0 && (
              <div className="flex flex-row items-center py-2 border-b border-[#E3E7ED] justify-end">
                {/* <button 
                onClick={() => setShowEmail(true)}
                className="bg-[#F3641F] rounded-md text-[white] text-[16px] font-bold w-[129px] h-[43px]">
                Send Invoice
              </button> */}
                <span className="flex flex-row items-center">
                  <p className="text-[#9CA7B8] text-[12px] font-bold mr-[20px] md:uppercase mb-0">
                    Payment Status
                  </p>
                  <RadioGroup
                    className="flex flex-row items-center"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <FormControlLabel
                      className={`${
                        status === statuses.COMPLETED
                          ? 'text-[#F3641F] bg-white'
                          : 'text-[#4C536A] bg-transparent '
                      } font-medium border border-[#E3E7ED] text-[14px] justify-center h-[32px] rounded-md pr-3`}
                      control={<Radio color={'secondary'} />}
                      value={statuses.COMPLETED}
                      label="Mark as Completed"
                    />
                    <FormControlLabel
                      className={`${
                        status === statuses.PENDING
                          ? 'text-[#F3641F] bg-white'
                          : 'text-[#4C536A] bg-transparent '
                      } font-medium border border-[#E3E7ED] text-[14px] justify-center h-[32px] rounded-md pr-3`}
                      control={<Radio color={'secondary'} />}
                      value={statuses.PENDING}
                      label="Mark as Pending"
                    />
                  </RadioGroup>
                </span>
              </div>
            )}
            {selectedProducts.length > 0 &&
              root.authStore.user.account_type.includes('Retailer') && (
                <div className="flex flex-row items-center py-2 border-b border-[#E3E7ED] justify-end">
                  <p className="text-[#9CA7B8] text-[12px] font-bold mr-[20px] md:uppercase mb-0 flex-shrink-0">
                    Payment Type
                  </p>
                  <RadioGroup
                    className="flex flex-row items-center justify-end"
                    style={{ rowGap: '.5rem' }}
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    {payments.map((option) => (
                      <FormControlLabel
                        className={`${
                          paymentType === option.value
                            ? 'text-[#F3641F] bg-white'
                            : 'text-[#4C536A] bg-transparent '
                        } font-medium border border-[#E3E7ED] text-[14px] justify-center h-[32px] rounded-md pr-3`}
                        control={<Radio color={'secondary'} />}
                        value={option.value}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </div>
              )}
            {selectedProducts.length > 0 && (
              <div className="flex flex-row items-center justify-end mt-[20px]">
                <button
                  onClick={leavePage}
                  className="border border-[#E3E7ED] text-[#4C536A] text-[16px] w-[83px] h-[43px] bg-white rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={makePayment}
                  className="bg-[#F3641F] w-[220px] h-[43px] text-white text-[16px] rounded-md ml-[20px]"
                >
                  Continue to payment
                </button>
              </div>
            )}
          </div>
        ) : (
          <SplitPayment
            orderDetails={storedForm}
            final={subtotal + shipping + delivery + tax - discount}
            onUpdate={handleProceed}
            customer={customer}
            clearForm={handleClear}
          />
        )}
      </div>

      <CreateCustomerModal
        isOpen={showCreateModal}
        close={() => setShowCreateModal(false)}
        onSubmit={handleCreateCustomer}
      />
      <UnsavedChanges
        isOpen={showExitModal}
        close={() => setShowExitModal(false)}
      />
      {/* <SaveModal
        isOpen={showSaveModal}
        close={() => setShowSaveModal(false)}
        handleAddPress={clearForm}
        icon={orders}
        progressLabel={"Saving your order"}
        successLabel={"Your order has been"}
        btnLabel={"Add New Order"}
        busy={busy}
      /> */}
      <EmailInvoice open={showEmail} close={() => setShowEmail(false)} />
    </Layout>
  )
})

export default CreateOrder
