import React, { useState, useEffect, useContext } from 'react'
import filter from '../../assets/filter.svg'
import arrow from '../../assets/chevron-right.svg'
import searchIcon from '../../assets/search.svg'

//mui
import { Popover, Button } from '@material-ui/core'
// import Back from '@mui/icons-material/ArrowBackIos'
import { orderStatus, payments } from '../../enums'
import { getRetailers } from '../../services/retailers'
import { toast } from 'react-toastify'
import Loader from '../Loader/Loader'
import { RootContext } from '../..'
import { ArrowBackIos } from '@material-ui/icons'

const TransactionFilter = ({
  className,
  activeTab = 'all',
  menu,
  onFinish = () => {},
}) => {
  const root = useContext(RootContext)
  const isRetailer =
    root.authStore.user && root.authStore.user.account_type.includes('Retailer')

  const [anchorEl, setAnchorEl] = useState(null)

  const [retailers, setRetailers] = useState([])

  const createFiltersObject = (item, field) => {
    const filters = {}
    item.forEach((element) => {
      filters[element[field]] = false
    })
    return filters
  }

  const INITIAL_RETAILERS = createFiltersObject(retailers, 'name')
  const INITIAL_STATUS = createFiltersObject(orderStatus, 'label')
  const INITIAL_TYPES = createFiltersObject(payments, 'value')

  const [retailerFilters, setRetailerFilters] = useState([
    createFiltersObject(retailers, 'name'),
  ])
  const [statusFilters, setStatusFilters] = useState([
    createFiltersObject(orderStatus, 'label'),
  ])
  const [typeFilters, setTypeFilters] = useState([
    createFiltersObject(payments, 'value'),
  ])

  const [selectedRetailers, setSelectedRetailers] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])

  const [mainMenu, setMainMenu] = useState('')
  const [subMenu, setSubMenu] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const handleMenu = (label) => {
    setSubMenu(true)
    setMainMenu(label)

    if (label === 'Retailers') {
      fetchRetailers()
    }
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const setFilter = (hook, field, value, filterName) => {
    hook({
      ...field,
      [filterName]: value,
    })
  }

  const updateSelectedList = (hook, field, event, filter) => {
    if (event.target.checked) {
      hook([...field, filter])
    } else {
      const newlist = field.filter((item) => item !== filter)
      hook(newlist)
    }
  }

  const clearFilters = () => {
    setRetailerFilters(INITIAL_RETAILERS)
    setStatusFilters(INITIAL_STATUS)
    setTypeFilters(INITIAL_TYPES)
    setSelectedRetailers([])
    setSelectedStatus([])
    setSelectedTypes([])
    setSubMenu(false)
  }

  const [controller, setController] = useState(null)
  const [busy, setBusy] = useState(false)

  const fetchRetailers = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)

    try {
      const { data } = await getRetailers(
        {
          status: 'Approved',
          search: searchValue,
        },
        abc.signal,
      )

      setRetailers(data.results)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('An error occurred. Unable to fetch retailers')
        setBusy(false)
      }
    }
  }

  useEffect(() => {
    fetchRetailers()
  }, [searchValue])

  useEffect(() => {
    clearFilters()
  }, [activeTab])

  return (
    <>
      <Button
        variant="outlined"
        className={`capitalize border border-[#E3E7ED] text-gray3 ${className} ${
          selectedRetailers.length > 0 ||
          selectedStatus.length > 0 ||
          selectedTypes.length > 0
            ? 'bg-orange/20 text-orange'
            : ''
        }`}
        size="small"
        startIcon={<img src={filter} alt="" />}
        onClick={handleClick}
      >
        Filter
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          root: 'mt-1',
          paper: 'bg-transparent shadow-lg',
        }}
      >
        <div
          className="w-64 bg-white border rounded-md shadow-lg overflow-y-auto"
          style={{ maxHeight: '500px', minHeight: '180px' }}
        >
          <div className="flex items-center justify-between p-2 border-b sticky top-0 bg-white">
            <div className="flex flex-row gap-2 items-center">
              {subMenu && (
                <ArrowBackIos
                  className="text-gray2 w-4 h-4 cursor-pointer"
                  onClick={() => setSubMenu(false)}
                />
              )}
              <Button
                className="capitalize shadow-none text-gray2 bg-[#F4F5F7]"
                size="small"
                variant="contained"
                onClick={clearFilters}
              >
                Clear
              </Button>
            </div>

            <h1 className="text-[12px] mb-0 font-medium text-[#1C345D]">Filters</h1>

            <Button
              className="capitalize shadow-none text-orange bg-orange/10"
              size="small"
              variant="contained"
              onClick={() => {
                onFinish(selectedRetailers, selectedStatus, selectedTypes)
                handleClose()
              }}
            >
              Done
            </Button>
          </div>

          <div className="divide-y">
            {!subMenu ? (
              menu
                .filter((item) => !(item.label === 'Retailers' && isRetailer))
                .map((item) => (
                  <li
                    className="w-full flex items-center justify-between px-3 py-2 cursor-pointer"
                    onClick={() => handleMenu(item.label)}
                  >
                    <span className="text-sm font-semibold text-gray1">
                      {item.label}
                    </span>
                    <img src={arrow} alt="" className="w-5 h-5" />
                  </li>
                ))
            ) : (
              <div className="divide-y">
                {mainMenu === 'Retailers' && (
                  <>
                    <div className="bg-[#F4F5F7] px-2 rounded-md w-full flex items-center">
                      <img src={searchIcon} alt="" />
                      <input
                        type="search"
                        placeholder="Search by name..."
                        className="w-full px-2 py-2 text-sm bg-transparent border-0 focus:outline-none focus:ring-0"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                      />
                    </div>
                    {busy ? (
                      <Loader />
                    ) : (
                      retailers.map((item, idx) => (
                        <li
                          key={idx}
                          className="flex items-center px-3 py-2 space-x-2"
                        >
                          <input
                            type="checkbox"
                            className="border-gray-200 rounded shadow-md focus-visible:outline-none focus:ring-offset-0 focus:ring-0"
                            checked={retailerFilters[item?.name]}
                            onChange={(e) => {
                              setFilter(
                                setRetailerFilters,
                                retailerFilters,
                                e.target.checked,
                                item?.name,
                              )
                              updateSelectedList(
                                setSelectedRetailers,
                                selectedRetailers,
                                e,
                                item?.uuid,
                              )
                            }}
                          />
                          <span className="text-sm capitalize text-gray1">
                            {item?.name}
                          </span>
                        </li>
                      ))
                    )}
                  </>
                )}

                {mainMenu === 'Payment Type' &&
                  payments.map((item, idx) => (
                    <li
                      key={idx}
                      className="flex items-center px-3 py-2 space-x-2"
                    >
                      <input
                        type="checkbox"
                        className="border-gray-200 rounded shadow-md focus-visible:outline-none focus:ring-offset-0 focus:ring-0"
                        checked={typeFilters[item.value]}
                        onChange={(e) => {
                          setFilter(
                            setTypeFilters,
                            typeFilters,
                            e.target.checked,
                            item.value,
                          )
                          updateSelectedList(
                            setSelectedTypes,
                            selectedTypes,
                            e,
                            item.value,
                          )
                        }}
                      />
                      <span className="text-sm capitalize text-gray1">
                        {item.value}
                      </span>
                    </li>
                  ))}

                {mainMenu === 'Order Status' &&
                  orderStatus.map((item, idx) => (
                    <li
                      key={idx}
                      className="flex items-center px-3 py-2 space-x-2"
                    >
                      <input
                        type="checkbox"
                        className="border-gray-200 rounded shadow-md focus-visible:outline-none focus:ring-offset-0 focus:ring-0"
                        checked={statusFilters[item.label]}
                        onChange={(e) => {
                          setFilter(
                            setStatusFilters,
                            statusFilters,
                            e.target.checked,
                            item.label,
                          )
                          updateSelectedList(
                            setSelectedStatus,
                            selectedStatus,
                            e,
                            item.label,
                          )
                        }}
                      />
                      <span className="text-sm capitalize text-gray1">
                        {item.label}
                      </span>
                    </li>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Popover>
    </>
  )
}

export default TransactionFilter
