import React from "react";
import backArrow from "../../assets/back-arrow.svg";

const PageTitle = ({ title, onBackPress, subtitle }) => {
  return (
    <div className="flex items-center pb-[20px]">
      {onBackPress && (
        <button className="bg-transparent mr-[23px]" onClick={onBackPress}>
          <img src={backArrow} alt="back arrow" className="w-[40px] h-[40px]" />
        </button>
      )}
      <h1 className="text-appBlack font-bold mb-0 text-[30px] capitalize">
        {title} {subtitle && "-"}
      </h1>
      {subtitle && (
        <p className="text-appBlack text-[30px] mb-0 ml-2">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default PageTitle;
