import { IoCaretDown, IoCaretUp } from 'react-icons/io5'
import { preventNonNumericalInput } from '../../utils'

const InventoryNumberInput = ({
  value,
  handleChange,
  field,
  min = 0,
  max = Infinity,
  handleIncrease = () => {},
  handleDecrease = () => {},
}) => {
  const isMaxReached = value >= max
  const isMinReached = value <= min

  return (
    <div
      className={`flex justify-around py-1 items-center bg-[#F4F5F7] rounded-md ${
        field === 'percentage' ? 'w-28' : 'w-32'
      }`}
    >
      <div className="w-[50%]">
        <input
          type="number"
          min={min}
          max={max}
          className="w-full px-2 bg-transparent border-0 shadow-none outline-none focus:outline-none focus:border-0 focus:ring-transparent"
          onKeyPress={preventNonNumericalInput}
          value={value}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="flex flex-col space-y-1 w-7">
        <button
          className={`inline-flex justify-center rounded-md bg-[#E3E7ED] ${
            isMaxReached ? 'cursor-default' : ''
          }`}
          onClick={() => handleIncrease()}
          disabled={isMaxReached}
        >
          <IoCaretUp
            className={`text-gray3 ${isMaxReached ? 'text-gray1' : ''}`}
          />
        </button>
        <button
          className={`inline-flex justify-center rounded-md bg-[#E3E7ED] ${
            isMinReached ? 'cursor-default' : ''
          }`}
          onClick={() => handleDecrease()}
          disabled={isMinReached}
        >
          <IoCaretDown
            className={`text-gray3 ${isMinReached ? 'text-gray1' : ''}`}
          />
        </button>
      </div>
    </div>
  )
}
export default InventoryNumberInput
