import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'
import { SentryRoute as Route } from '../sentry-config'

import logo from '../assets/logo.svg'
import loader from '../assets/loader.svg'
import { RootContext } from '..'

const AdminRoute = observer(({ children, ...props }) => {
  const root = useContext(RootContext)
  const isAdmin =
    root.authStore.user && root.authStore.user.account_type.includes('Admin')
  const isAuthenticated = Boolean(root.authStore.user)

  return root.authStore.busy ? (
    <div className="flex flex-col items-center justify-center w-[100vw] h-[100vh] bg-white">
      <img src={logo} alt="" className="w-[220px] h-[auto] mb-4" />
      <img src={loader} alt="" className="w-10 animate-spin" />
    </div>
  ) : (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? (
          isAdmin ? (
            children
          ) : (
            <Redirect to="/home" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
})

export default AdminRoute
