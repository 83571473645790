import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router";
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import { toast } from "react-toastify";

//components
import Layout from "../../components/Layout/Layout"
import PageTitle from "../../components/PageTitle/PageTitle"
import Loader from "@components/Loader/Loader";
import TableTotals from "../../components/TableTotals/TableTotals";

//assets
import cancelled from "../../assets/cancelled.svg";
import paid from "../../assets/paid.svg";
import pending from "../../assets/pending.svg";
import blackArrow from "../../assets/black-round-arrow.svg";

//services
import { getTransaction } from "../../services/finance";

//utils
import { formatDate, formatTime } from "../../utils";
import { getDeliveryAddress } from "../../services/users";

const center = {
  lat: 5.6221056,
  lng: -0.1755388
};

const containerStyle = {
  width: '596px',
  height: '121px',
  borderRadius: '10px'
};

const TransactionDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const [busy, setBusy] = useState(true);
  const [transaction, setTransaction] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const goBack = () => {
    history.push('/finance/history');
  }

  const fetchTransaction = async() => {
    try {
      const { data } = await getTransaction(id)
        .then(async(res) => {
          if(res.data.customer.delivery_address.length === 0)
            return res
          const { data } = await getDeliveryAddress(res.data.customer.delivery_address[0])
            .catch((err) => {});
          setDeliveryAddress(data);
          return res;
        })
      setTransaction(data);
      setBusy(false);
    } catch (error) {
      console.log(error)
      toast.error("An error occurred. Unable to fetch requested transaction");
      history.goBack();
    }
  }

  useEffect(() => {
    fetchTransaction();
  }, [])

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <div className="px-[30px] pt-5">
          <PageTitle title="Transaction Details" onBackPress={goBack} />
          <div className="bg-white rounded-md p-[20px] flex flex-col w-full mb-10">
            <div className="flex flex-col">
              <p className="text-[#3D4356] text-[16px] font-medium">Payment</p>
              <div className="flex flex-row items-end">
                <p className="text-[#9CA7B8] text-[30px] font-normal">
                  NGN <span className="text-[30px] text-[#040A1D] font-bold">₦{Number(transaction.order.final_cost.amount).toLocaleString('en')}</span>
                </p>
                <div className="flex flex-col pl-[80px]">
                  <p className="text-[14px] text-[#9CA7B8] pb-3 font-normal">Date</p>
                  <p className="text-[16px] text-[#040A1D]">{formatDate(transaction.order.created_at)} {formatTime(transaction.order.created_at)}</p>
                </div>
                <div className="h-[56px] w-[1px] bg-[#E3E7ED] mx-6"></div>
                <div className="flex flex-col">
                  <p className="text-[14px] text-[#9CA7B8] pb-1 font-normal">Payment Type</p>
                  <span className="flex flex-row items-center">
                    <p className="text-[16px] text-[#040A1D]">{transaction.payment_channel}</p>
                  </span>
                </div>
                <div className="h-[56px] w-[1px] bg-[#E3E7ED] mx-6"></div>
                <div className="flex flex-col">
                  <p className="text-[14px] text-[#9CA7B8] pb-3 font-normal">Customer</p>
                  <p className="text-[16px] text-[#040A1D]">
                    {transaction.customer.profile.first_name} {transaction.customer.profile.last_name}
                  </p>
                </div>
                <div className="h-[56px] w-[1px] bg-[#E3E7ED] mx-6"></div>
                <div className="flex flex-col">
                  <p className="text-[14px] text-[#9CA7B8] pb-1 font-normal">Payment Status</p>
                  <span
                    className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 ${
                      transaction.status.toLowerCase() === "Cancelled" &&
                      "bg-red/20 text-red"
                    }
                    ${
                      transaction.status.toLowerCase() === "Paid" &&
                      "bg-green/20 text-green"
                    }
                    ${
                      transaction.status.toLowerCase() === 'not paid' &&
                      "bg-blue/20 text-blue"
                    }`}
                  >
                    {transaction.status.toLowerCase() === "cancelled" && (
                      <img src={cancelled} alt="" />
                    )}
                    {transaction.status.toLowerCase() === "Paid" && (
                      <img src={paid} alt="" />
                    )}
                    {transaction.status.toLowerCase() === "not paid" && (
                      <img src={pending} alt="" />
                    )}      
                    <p>{transaction.status}</p>
                  </span>
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#E3E7ED] mt-6"></div>
              <div className="pt-8 pb-4">
                <p className="text-[#040A1D] text-[20px] font-bold">Billing Information</p>
                <div className="relative flex flex-row items-end justify-between">
                  <div className="flex flex-row">
                  <div className="flex flex-col">
                    <p className="text-[12px] text-[#F3641F] font-bold mt-6 mb-2">BILL FROM:</p>
                    <p className="text-[18px] text-[#040A1D] mb-2">
                    {transaction.retailer.name}
                    </p>
                    <p className="text-[#4C536A] text-[14px] w-[181px] mb-2">
                      {transaction.retailer.address.address}
                    </p>
                    <p className="text-[#4C536A] text-[14px] w-[181px] mb-2">{transaction.retailer.business_phone}</p>
                    <p className="text-[#4C536A] text-[14px] w-[181px]">{transaction.retailer.business_email}</p>
                  </div>
                  <img
                    src={blackArrow}
                    alt="arrow"
                    className="w-[19.2px] h-[19.2px] absolute top-[45%] left-[255px]"
                  />
                  <div className="border-dashed border border-[#E3E7ED] ml-[83px] border-b-0 border-t-0 border-l-0 mr-[60px] h-[165px] mt-4"></div>
                  <div className="flex flex-col">
                    <p className="text-[12px] text-[#38C985] font-bold mt-6 mb-2">BILL TO:</p>
                    <p className="text-[18px] text-[#040A1D] mb-2">
                    {transaction.customer.profile.first_name} {transaction.customer.profile.last_name}
                    </p>
                    <p className="text-[#4C536A] text-[14px] w-[181px] mb-2">
                      {deliveryAddress && deliveryAddress.address}
                    </p>
                    <p className="text-[#4C536A] text-[14px] w-[181px] mb-2">{transaction.customer.phone_number}</p>
                    <p className="text-[#4C536A] text-[14px] w-[181px] mb-4">{transaction.customer.email}</p>
                    <div>
                    {isLoaded && deliveryAddress && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{lng: parseFloat(deliveryAddress.lon), lat: parseFloat(deliveryAddress.lat)}}
                        zoom={10}
                      >
                        <Marker position={{lng: parseFloat(deliveryAddress.lon), lat: parseFloat(deliveryAddress.lat)}}></Marker>
                      </GoogleMap>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#E3E7ED] mt-6 mb-10"></div>
              <p className="text-[#040A1D] text-[20px] font-bold">Order Details</p>
              <div>
                <table width="100%">
                  <tr className="h-[40px]">
                    <th></th>
                    <th className="text-[#9CA7B8] text-[12px] font-medium text-left pl-4">Item</th>
                    <th className="text-[#9CA7B8] text-[12px] font-medium text-right">Qty</th>
                    <th className="text-[#9CA7B8] text-[12px] font-medium text-right">Price</th>
                    <th className="text-[#9CA7B8] text-[12px] font-medium text-right pr-4">Total</th>
                  </tr>
                  {transaction.order.order_item.map((item, index) => (
                    <tr className=" h-[40px]">
                      <td className="text-[#3D4356] text-[14px] bg-white border-white">{index + 1}</td>
                      <td className="text-[#3D4356] text-[14px] bg-white border-b border-[#E3E7ED] pl-4">{item.product} <br/> {item.variant}</td>
                      <td className="text-[#3D4356] text-[14px] bg-white text-right border-b border-[#E3E7ED]">{item.quantity}</td>
                      <td className="text-[#3D4356] text-[14px] bg-white text-right border-b border-[#E3E7ED]">₦{Number(item.price.amount).toLocaleString('en')}</td>
                      <td className="text-[#3D4356] text-[14px] bg-white text-right border-b border-[#E3E7ED] pr-4">₦{Number(item.total_price.amount).toLocaleString('en')}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <div className="pr-4">
                <TableTotals 
                  subtotal={transaction.order.sub_total.amount} 
                  shipping={transaction.order.delivery_fee ? transaction.order.delivery_fee.amount : 0}
                  discount={transaction.order.discount_fee ? transaction.order.delivery_fee.amount : 0} 
                  tax={0} 
                  ordertotal={transaction.amount.amount}
                />
              </div>
              <div className="w-full h-[1px] bg-[#E3E7ED] mt-6 mb-20"></div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default TransactionDetail