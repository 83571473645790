import api from '../apiInstance'

export const getStock = (retailerId, params, signal) =>
  api.get(`/api/stock/`, {
    params: {
      ...params,
      retailer: retailerId,
    },
    signal,
  })

export const getStockItem = (id) => api.get(`/api/stock/${id}/`)

export const updateStockItem = (id, data) =>
  api.patch(`/api/stock/${id}/`, data)

export const addStock = (form) => api.post('/api/stock/', form)

export const deleteStock = (id) => api.delete(`/api/stock/${id}`)

export const getInventoryHistory = (id) => api.get(`/api/stock/${id}/history/`)

export const getRetailerStock = (params, signal) =>
  api.get('/api/stock/', {
    params,
    signal,
  })

export const getRetailerStockSummary = (retailer) =>
  api.get('/api/stock/stock-summary/', {
    params: {
      retailer,
      in_stock: true,
    },
  })

export const getStockSummary = () =>
  api.get('/api/stock/stock-summary/', {
    params: {
      in_stock: true,
    },
  })

export const syncStockWithCin7 = (form) => api.post('/api/stock/cin7-stock-sync/', form)
