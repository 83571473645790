import { makeAutoObservable, observable, runInAction } from "mobx";
import Cookies from "js-cookie";
import { getRetailers, createNewRetailer } from "../services/retailers";

class Retailers {
  rootStore;
  loading = false;
  approvedRetailers = [];

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      loading: observable,
      retailers: observable,
    });

    this.rootStore = rootStore;
  }

  getApprovedRetailers = async () => {
    runInAction(() => {
      this.loading = true;
    });

    const token = this.getToken();

    if (token) {
      try {
        const response = await getRetailers();

        const approvedRetailers = response.data.results.filter(
          (retailer) => retailer.status.toLowerCase() === "approved"
        );
        this.approvedRetailers = approvedRetailers;

        runInAction(() => {
          this.loading = false;
        });
      } catch (error) {
        runInAction(() => {
          this.loading = false;
        });

        throw error;
      }
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  createRetailer = async (data) => {
    const token = this.getToken();

    if (token) {
      try {
        return await createNewRetailer(token, data);
      } catch (error) {
        throw error;
      }
    } else {
      return;
    }
  };

  getToken() {
    return Cookies.get("auth_token");
  }
}

export default Retailers;
