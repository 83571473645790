import React from "react";
import useTableMethods from "../../customHooks/useTableMethods";

//icons
import view from "../../assets/view.svg";
import cancelled from "../../assets/cancelled.svg";
import paid from "../../assets/paid.svg";
import loader from "../../assets/loader.svg";
import pending from "../../assets/pending.svg";

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
  Avatar,
  ButtonGroup,
  Button,
} from "@material-ui/core";

//components
import CustomTableHead from "./TableHead";
import { formatDate, formatTime } from "../../utils";

const FinanceTable = ({ rows, onViewClick, 
    search = '', busy = false, notFound = false, count, page, rowsPerPage, onPageChange,
    onRowsPerPageChange }) => {
  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected,
  } = useTableMethods(rows);

  return (
    <div className="w-full mt-5">        
        {(busy || rows.length === 0) ? (
          <>
            <Table className="min-w-[750px]" size="small">
              <CustomTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
              />
            </Table>
            <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
              {busy || !notFound ? (
                <img src={loader} alt="loading" className="w-5 animate-spin" />
              ) : (
                <>
                  <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">No Search Result for "{search}"</p>
                  <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                    <span className="text-[#8892A2] text-[14px] font-bold">Search tips: </span>
                    Some search terms require an exact match. Try typing the entire term, or use a different word or phrase.
                  </p>
                </>
              )}
            </div>
          </>
        ) : (
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody className="bg-white">
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(index);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.order?.order_id}
                      selected={isItemSelected}
                      className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, index)}
                        />
                      </TableCell>
                      <TableCell className="text-sm font-medium text-gray2">
                        {row.order?.order_id}
                      </TableCell>
                      <TableCell className="text-sm font-medium text-gray2">
                        {row?.retailer.name}
                      </TableCell>
                      <TableCell className="flex items-center space-x-2">
                        <Avatar className="w-8 h-8" src={row.order?.user.profile.picture} />
                        <p className="text-sm font-medium text-gray2">
                          {row.order?.user.profile.first_name} {row.order?.user.profile.last_name}
                        </p>
                      </TableCell>
                      <TableCell className="text-sm font-medium text-gray2">
                        {row.order?.payment_date ? formatDate(row.order.payment_date) : '-'}
                      </TableCell>
                      <TableCell className="text-sm font-medium text-gray2">
                        {row.order?.payment_date ? formatTime(row.order.payment_date) : '-'}
                      </TableCell>
                      <TableCell>
                        <span
                          className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 ${
                            row?.status.toLowerCase() === "cancelled" &&
                            "bg-red/20 text-red"
                          }
                          ${
                            row?.status.toLowerCase() === "paid" &&
                            "bg-green/20 text-green"
                          }
                          ${
                            row?.status.toLowerCase() === 'not paid' &&
                            "bg-blue/20 text-blue"
                          }`}
                        >
                          {row?.status.toLowerCase() === "cancelled" && (
                            <img src={cancelled} alt="" />
                          )}
                          {row?.status.toLowerCase() === "paid" && (
                            <img src={paid} alt="" />
                          )}
                          {row?.status.toLowerCase() === "not paid" && (
                            <img src={pending} alt="" />
                          )}
                          <p>{row?.status}</p>
                        </span>
                      </TableCell>
                      <TableCell className="text-sm font-medium text-gray2 ">
                        <p>{row?.payment_channel}</p>
                      </TableCell>
                      <TableCell className="font-medium text-black" align="right">
                        ₦{Number(row.order?.final_cost.amount).toLocaleString('en')}
                      </TableCell>
                      <TableCell align="right">
                        <ButtonGroup size="medium">
                          <Button onClick={() => onViewClick(row.uuid)}>
                            <img src={view} alt="" />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1);
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default FinanceTable;
