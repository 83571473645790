import React from 'react'

import PaymentChannel from '../PaymentChannel/PaymentChannel'

//icons
import productIcon from '../../assets/products-inactive.svg'
import brandIcon from '../../assets/brands.svg'
import retailerIcon from '../../assets/retailors-inactive.svg'
import ExportButton from '../ExportButton/ExportButton'

const SimpleAnalyticsCard = ({ label, items, itemType, onExport }) => {
  return (
    <div className="flex flex-col h-[335px] p-[20px] bg-white rounded">
      <div className="flex flex-row justify-between border-b border-[#EEF2F5] pb-4 mb-4">
        <span className="text-[#3D4356] text-[16px] font-medium mr-auto">
          {label}
        </span>
        {onExport ? <ExportButton onClick={onExport} /> : null}
      </div>
      <div className="overflow-y-scroll">
        {itemType === 'progress' &&
          items.map((item, index) => (
            <div className="mb-2">
              <PaymentChannel
                label={item.label}
                amount={item.amount}
                percentage={item.percentage}
                key={index}
              />
            </div>
          ))}
        {itemType === 'retailers' &&
          items.map((item, index) => (
            <div
              className="flex flex-row items-center justify-between pb-2 border-b border-[#E3E7ED] mb-4"
              key={index}
            >
              <span className="flex flex-row items-center">
                <img
                  src={item.logo ? item.logo : retailerIcon}
                  alt=""
                  className="w-[40px] h-[40px] rounded-full"
                />
                <div className="flex flex-col ml-2">
                  <p className="text-[#4C536A] text-[14px] font-medium">
                    {item.name}
                  </p>
                  <p className="text-[#9CA7B8] text-[12px]">
                    {item.order_completed} Sold
                  </p>
                </div>
              </span>
              <p className="text-[#151515] text-[16px] font-medium">
                ₦{Number(item.revenue).toLocaleString('en')}
              </p>
            </div>
          ))}
        {itemType === 'brands' &&
          items.map((item, index) => (
            <div
              className="flex flex-row items-center justify-between pb-2 border-b border-[#E3E7ED] mb-4"
              key={index}
            >
              <span className="flex flex-row items-center">
                <img
                  src={item.image ? item.image : brandIcon}
                  alt=""
                  className="w-[40px] h-[40px] rounded-full"
                />
                <div className="flex flex-col ml-2">
                  <p className="text-[#4C536A] text-[14px] font-medium">
                    {item.name}
                  </p>
                  <p className="text-[#9CA7B8] text-[12px]">
                    {item.total_sold} Sold
                  </p>
                </div>
              </span>
              <p className="text-[#151515] text-[16px] font-medium">
                ₦{Number(item.revenue).toLocaleString('en')}
              </p>
            </div>
          ))}
        {itemType === 'products' &&
          items.map((item, index) => (
            <div
              className="flex flex-row items-center justify-between pb-2 border-b border-[#E3E7ED] mb-4"
              key={index}
            >
              <span className="flex flex-row items-center">
                <img
                  src={item.image ? item.image : productIcon}
                  alt=""
                  className="w-[40px] h-[40px] rounded-full"
                />
                <div className="flex flex-col ml-2">
                  <p className="text-[#4C536A] text-[14px] font-medium">
                    {item.name}
                  </p>
                  <p className="text-[#9CA7B8] text-[12px]">
                    {item.total_sold} Sold
                  </p>
                </div>
              </span>
              <p className="text-[#151515] text-[16px] font-medium">
                ₦{Number(item.revenue).toLocaleString('en')}
              </p>
            </div>
          ))}
        {itemType === 'orders' &&
          items.map((item, index) => (
            <div className="flex flex-row items-center pb-2 border-b border-[#E3E7ED] justify-between mb-4">
              <p className="text-[#4C536A] text-[14px] font-medium w-[20%]">
                {item.name}
              </p>
              <p className="text-[#4C536A] text-[14px] font-medium">
                {item.total}
              </p>
              <p className="text-[#151515] text-[16px] font-medium">
                ₦{Number(item.amount).toLocaleString('en')}
              </p>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SimpleAnalyticsCard
