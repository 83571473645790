import axios from 'axios'
import Cookies from 'js-cookie'
import Sentry from './sentry-config'

export class APIError extends Error {
  name
  constructor(message) {
    super(message)
    this.name = 'APIError'
  }
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  maxRedirects: 0,
  headers: {
    accept: 'Application/json',
  },
})

function errorHandler(error) {
  let { status } = error.response || {}
  status = error.code === 'ERR_CANCELED' ? 499 : status || 500

  if (status === 401) {
    Cookies.remove('auth_token')
  } else {
    Sentry.captureException(new APIError(error.message), {
      extra: {
        payload: error.request?.__sentry_xhr_v2__?.body,
        response: error.response?.data,
      },
    })
  }

  throw error
}

instance.interceptors.request.use(
  (req) => {
    const token = Cookies.get('auth_token')
    if (token) {
      req.headers.Authorization = `Token ${token}`
    }
    return req
  },
  (error) => errorHandler(error),
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => errorHandler(error),
)

export default instance
