import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Button } from 'antd'

//components
import PreparationCheckbox from '../../../components/Settings/PreparationCheckbox'

//services
import { RootContext } from '../../..'
import { updateWorkingHours } from '../../../services/retailers'

//icons
import bulb from '../../../assets/bulb.svg'
import loader from '../../../assets/loader.svg'

const PreparationHours = ({ data, fetchData }) => {
  const root = useContext(RootContext)
  const history = useHistory()

  //variables
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const [submitting, setSubmitting] = useState(false)
  const [workingHours, setWorkingHours] = useState(data)
  const { watch, control } = useForm()

  //functions
  const duplicateTime = () => {
    const time = watch('Monday')

    const hrs =
      time?.hours === null ? '00' : String(time?.hours).padStart(2, '0')
    const mins =
      time?.mins === null ? '00' : String(time?.mins).padStart(2, '0')

    const new_workingHours = workingHours.map((item) => {
      return {
        ...item,
        order_prep_time: `${hrs}:${mins}`,
      }
    })

    setWorkingHours(new_workingHours)
  }

  function convertToHMS(timeValue) {
    if (timeValue === null) {
      return null
    } else {
      const formattedHours =
        timeValue.hours === null
          ? '00'
          : String(timeValue.hours).padStart(2, '0')
      const formattedMinutes =
        timeValue.mins === null ? '00' : String(timeValue.mins).padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}:00`
    }
  }

  const handleOnSubmit = async () => {
    setSubmitting(true)

    const working_hours = workingHours.map((item) => ({
      ...item,
      order_prep_time: convertToHMS(watch(item.day)),
    }))

    try {
      await updateWorkingHours(root.authStore.user.retailerUuid, {
        working_hours,
      })
      toast.success('Order preparation time updated!')
      fetchData()
    } catch (error) {
      toast.error('Error updating order preparation time! try again')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-[80%]">
        <div className="bg-white rounded-md p-5 space-y-10">
          <div>
            <p className="font-semibold text-[#040A1D] text-xl">
              Order Preparation Hours
            </p>
            <p className="text-sm text-[#4C536A]">
              Choose the standard Preparation hours for your business
            </p>
          </div>

          <div className="w-[50%]">
            <div className="space-y-5">
              {days.map((item) => (
                <div
                  className="flex flex-col items-end border-b pb-2"
                  key={item}
                >
                  <Controller
                    name={item}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <PreparationCheckbox
                        day={item}
                        onChange={onChange}
                        workingHours={workingHours}
                      />
                    )}
                  />
                  {watch(item) && item === 'Monday' && (
                    <div className="flex justify-center text-sm w-[80%] gap-3 my-2 bg-teal/10 rounded-md border border-teal py-2">
                      <img src={bulb} alt="hint" className="h-5 w-5" />{' '}
                      <span>
                        Copy order preparation hours to all days?{' '}
                        <button
                          type="button"
                          className="font-semibold text-teal underline"
                          onClick={duplicateTime}
                        >
                          Yes Copy
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="flex justify-end items-center mt-20 gap-5">
              <Button
                variant="outlined"
                className={`text-[#4C536A] capitalize shadow-none relative bg-white border border-[#E3E7ED]`}
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={`text-white capitalize shadow-none relative flex ${
                  submitting
                    ? 'bg-orange/30 cursor-not-allowed'
                    : 'bg-orange cursor-pointer'
                }`}
                onClick={handleOnSubmit}
              >
                Save
                {submitting && (
                  <img src={loader} className="w-5 animate-spin ml-1" alt="" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PreparationHours
