import React from "react";
import { getComparator, stableSort } from "../../utils/tableFunctions";
import useTableMethods from "../../customHooks/useTableMethods";

// icons
import view from "../../assets/view.svg";
import loader from "../../assets/loader.svg";
import pending from "../../assets/pending.svg";
import cancelled from "../../assets/cancelled.svg";
import completed from "../../assets/completed.svg";

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
} from "@material-ui/core";

//components
import CustomTableHead from "./TableHead";
import { formatDate, formatTime } from "../../utils";

const CustomerOrdersTable = ({ rows, onViewClick, search = '', busy = false, notFound = true }) => {
  const {
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    emptyRows,
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
  } = useTableMethods(rows);

  return (
    <div>
      {(busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead 
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
          {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">No Search Result for "{search}"</p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">Search tips: </span>
                  Some search terms require an exact match. Try typing the entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ): (
        <Table className="min-w-[750px]" size="small">
          <CustomTableHead 
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody className="bg-white">
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(index);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, index)}
                      />
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.order_id}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {formatDate(row.created_at)}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {formatTime(row.created_at)}
                    </TableCell>
                    <TableCell>
                      <span
                        className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 ${
                          row.status.toLowerCase() === "pending" &&
                          "bg-blue/20 text-blue"
                        } ${
                          row.status.toLowerCase() === "cancelled" &&
                          "bg-red/20 text-red"
                        }
                          ${
                            row.status.toLowerCase() === "completed" &&
                            "bg-green/20 text-green"
                          }`}
                      >
                        {row.status.toLowerCase() === "pending" && (
                          <img src={pending} alt="" />
                        )}
                        {row.status.toLowerCase() === "cancelled" && (
                          <img src={cancelled} alt="" />
                        )}
                        {row.status.toLowerCase() === "completed" && (
                          <img src={completed} alt="" />
                        )}

                        <p>{row.status}</p>
                      </span>
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2" align="right">
                      ₦{Number(row.final_cost.amount).toLocaleString('en')}
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup size="medium">
                        <Button onClick={() => onViewClick(row)}>
                          <img src={view} alt="" /> 
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
          </TableBody>
        </Table>
      ))}
      {!busy && search.length === 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default CustomerOrdersTable;