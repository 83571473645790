import React, { useState, useEffect, createRef } from "react";
import { useHistory, useParams } from "react-router";
import { Popover } from "@material-ui/core";
import { Calendar } from "react-modern-calendar-datepicker";
import { GoogleMap, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// components
import Layout from "../../components/Layout/Layout";
import FormSelect from "../../components/FormSelect/FormSelect";
import FormInput from "../../components/FormInput/FormInput";
import SaveModal from "../../components/SaveModal/SaveModal";
import UnsavedChanges from "../../components/UnsavedChanges/UnsavedChanges";
import Loader from "@components/Loader/Loader";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

// assets
import backArrow from "../../assets/back-arrow.svg";
import upArrow from "../../assets/up-arrow.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import customersIcon from "../../assets/customers-inactive.svg";
import location from "../../assets/location.svg";

//services
import {
  getUser,
  updateProfile,
  updateEmailOrPhoneNumber,
  getDeliveryAddress,
  updateDeliveryAddress,
  createDeliveryAddress,
} from "../../services/users";
import { trimDigits } from "../../utils";

const containerStyle = {
  width: '100%',
  height: '167px',
  borderRadius: '10px'
};

const libraries = ["places"];


const UpdateCustomer = () => {
  const { slug } = useParams();
  const [busy, setBusy] = useState(true);
  const [saving, setSaving] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  // user details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+234")
  const [phone, setPhone] = useState("");
  // const [date, setDate] = useState("");
  const [picture, setPicture] = useState();
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [file, setFile] = useState(null);
  // const [selectedDay, setSelectedDay] = useState(null);
  const [gender, setGender] = useState("");


  // address information
  const [address, setAddress] = useState(null);
  const [apartment, setApartment] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState('');

  //marker coords
  const [coords, setCoord] = useState(null);
  const [center, setCenter] = useState();

  const fileInput = createRef();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const id = open ? "calendar-popover" : undefined;

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const getToken = () => {
    return Cookies.get("auth_token");
  }

  const fetchCustomer = async() => {
    try {
      await getUser(slug)
        .then(async(res) => {
          let address;
          if(res.data.delivery_address.length > 0) {
            address = await fetchAddress(res.data.delivery_address[0]);
            res.data.address = address.data;
            setAddress(address.data.address);
            setCoord({lng: parseFloat(address.data.lon), lat: parseFloat(address.data.lat)});
          }
          setCustomer(res.data);
          setBusy(false);
        })
      
    } catch (error) {
      toast.error('An error occured. Unable to fetch customer');
      history.goBack();
    }
  }

  const fetchAddress = async(id) => {
    try {
      return await getDeliveryAddress(id);
    } catch (error) {
      //toast.error('An error occurred. Unable to fetch address data.');
    }
  }

  const populateFields = () => {
    setEmail(customer.email);
    if(customer.phone_number && customer.phone_number.substring(0, 2) === '+2') {
      setCountryCode(customer.phone_number.substring(0, 4))
      setPhone(customer.phone_number.substring(4));
    } else {
      setPhone(customer.phone_number);
    }
    setFirstName(customer.profile.first_name);
    setLastName(customer.profile.last_name);
    setGender(customer.profile.gender);
    // setDate(customer.profile.date_of_birth);
    setPicture(customer.profile.picture);
  }

  useEffect(() => {
    if(customer)
      populateFields();
  }, [customer])

  useEffect(() => {
    fetchCustomer();
  }, [])

  const generateProfileForm = () => {
    const form = new FormData();
    form.append("gender", gender);
    if(firstName !== customer.profile.first_name)
      form.append("first_name", firstName)
    if (lastName !== customer.profile.last_name)
      form.append("last_name", lastName)
    // if (date !== customer.profile.date_of_birth && date !== "")
    //   form.append("date_of_birth", date)
    if (selectedAvatar)
      form.append("picture", file)
    return form;
  }

  const generateUserForm = () => {
    const form = {
      is_active: true,
      account_type: "Customer",
    };
    if(email !== customer.email)
      form.email = email
    if (phone !== customer.phone_number)
      form.phone_number = countryCode+phone.replace(/^0|[\s-]/g, '')
    
    return form;
  }

  const generateAddressForm = () => (
    {
      is_dashboard: true,
      user: customer.id,
      is_active: true,
      address: address,
      street_name: "",
      town: "",
      city: city,
      country: country,
      administrative_area_level_1: region,
      administrative_area_level_2: "",
      post_code: postalCode,
      lon: coords?.lng ? trimDigits(coords?.lng) : null,
      lat: coords?.lat ? trimDigits(coords?.lat) : null,
    }
  )

  const goBack = () => {
    setShowExitModal(true);
  }

  const handlePopoverClose = () => {
    setAnchorEl(false);
  }

  // useEffect(() => {
  //   if(selectedDay)
  //     setDate(`${selectedDay.year}-${formatMonth(selectedDay.month)}-${selectedDay.day}`)
  // }, [selectedDay])

  // const formatMonth = (month) => {
  //   if(parseInt(month) < 10)
  //     return `0${month}`;
  //   return month;
  // }

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
    e.preventDefault();
  }
 
  const dragLeave = (e) => {
    e.preventDefault();
  }

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if(files.length) {
      handleFiles(files);
    }
  }

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if(validTypes.indexOf(file.type) === -1) {
      return false;
    } else if(file.size > 100000000) {
      return false;
    }
    return true
  }

  const genderChangeHandler = value => {
    setGender(value);
  }

  const handleFiles = (files) => {
    if(validateFile(files[0])) {
      setSelectedAvatar(URL.createObjectURL(files[0]));
      setFile(files[0])
    }
  }

  const handleFileSelect = (e) => {
    e.preventDefault();
    fileInput.current.click();
    fileInput.current.onchange = () => {
      if(fileInput.current.files.length) {
        handleFiles(fileInput.current.files);
      }
    }
  }

  const handleInput = (e) => {
    const { value } = e.target;
    setValue(value);
    setAddress(value);
  };

  const checkEmailOrPhoneChange = () => {
    if(email !== customer.email)
      return true;
    if(phone !== customer.phone_number)
      return true;
    return false;
  }

  const checkProfileChange = () => {
    if(firstName !== customer.profile.first_name)
      return true
    if (lastName !== customer.profile.last_name)
      return true
    // if (date !== customer.profile.date_of_birth || date === "")
    //   return true
    if (gender !== customer.profile.gender)
      return true
    if (selectedAvatar)
      return true
    return false
  }

  const checkAddressChange = () => {
    if(address !== customer.address?.address)
      return true
  }

  const submitProfileData = async() => {
    const form = generateProfileForm();
    try {
      await updateProfile(form, customer.profile.uuid);
    } catch (error) {
      console.error(error)
      toast.error("An error occurred. Unable to update profile data");
    }
  }

  const submitAddressData = async() => {
    const form = generateAddressForm();
    try {
      if (customer.delivery_address[0]) {
        await updateDeliveryAddress(customer.delivery_address[0], form);
      } else {
        await createDeliveryAddress(form)
      }
    } catch (error) {
      console.error(error)
      toast.error("An error occurred. Unable to update address data");
    }
  }

  const submitUserData = async() => {
    const form = generateUserForm();
    const token = getToken();
    try {
      await updateEmailOrPhoneNumber(customer.uuid, form);
    } catch (error) {
      toast.error(
        error.response?.data?.phone_number?.[0]
        || error.response?.data?.email?.[0]
        || error.message
        || "An error occurred. Unable to update user data"
      );
      throw error
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setShowSaveModal(true);
    try {
      if(checkProfileChange())
        await submitProfileData();
      if(checkEmailOrPhoneChange())
        await submitUserData();
      if(checkAddressChange())
        await submitAddressData();
      toast.success('Customer information has been successfully updated.')
    } finally {
      setSaving(false);
      setShowSaveModal(false);
    }
  }

  const onSelect = async (suggestion) => {
    const results = await getGeocode({ placeId: suggestion.place_id });
    const coordinates = await getLatLng(results[0]);

    const addressComps = results[0].address_components;

    const country = addressComps.find((info) => info.types.includes("country"));
    const city = addressComps.find((info) => info.types.includes("locality"));
    const district = addressComps.find((info) => info.types.includes("sublocality"));
    const region = addressComps.find((info) => 
      info.types.includes("administrative_area_level_1"));
    const postalCode = addressComps.find((info) =>
      info.types.includes("postal_code"))

    setValue(suggestion.description, false);

    clearSuggestions();

    setCoord(coordinates);
    setCenter(coordinates);
    setAddress(suggestion.description);
    setDistrict(district?.long_name);
    setCity(city?.long_name);
    setPostalCode(postalCode?.long_name);
    setRegion(region?.long_name);
    setCountry(country?.long_name);
  }


  return (
    <Layout>
      {busy ? (
        <Loader />
      ): (
        <>
          <div className="flex items-center justify-center">
            <div className="w-[730px] flex flex-col">
              <div className="flex items-center">
                <button onClick={goBack}>
                  <img src={backArrow} alt="back" className="w-[40px] h-[40px] mr-4"/>
                </button>
                <h1 className="text-appBlack font-bold text-[30px]">Update Customer</h1>
              </div>
              <div className="w-full bg-white rounded-md p-[30px] flex flex-col mt-4 shadow-md">
                <p className="text-[#3D4356] text-[16px] mb-3">Personal Details</p>
                <div className="flex items-center space-x-4 w-full mb-3">
                  <FormInput 
                    id="first-name"
                    name="first-name"
                    placeholder="First Name"
                    label="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    defaultValue={customer ? customer.profile.first_name : ""}
                  />
                  <FormInput 
                    id="last-name"
                    name="last-name"
                    placeholder="Last Name"
                    label="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    defaultValue={customer ? customer.profile.last_name : ""}
                  />
                </div>
                <div className="flex items-center mb-3">
                  {/*<FormInput
                    id="calendar-popover"
                    name="date"
                    placeholder="Date of Birth"
                    label="Date of Birth"
                    onFocus={(e) => setAnchorEl(e.currentTarget)}
                    value={date}
                  />
                  <Popover
                    id={id}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Calendar 
                      value={selectedDay}
                      onChange={setSelectedDay}
                      shouldHighlightWeekends={false}
                      colorPrimary="#F3641F"
                      colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                    />
                  </Popover>*/}
                  <div className="flex items-center w-full space-x-2">
                    <CustomSelect
                      label="Country Code"
                      name="country-codes"
                      id="country-codes"
                      value={countryCode}
                      options={['+233', '+234']}
                      setValue={setCountryCode}
                    />
                    <FormInput
                      type="text"
                      id="phone-number"
                      name="phone-number"
                      placeholder="Phone Number"
                      label="Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </div>
                </div>
                <div className="flex space-x-4 items-center">
                  <FormInput
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    label="Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={customer ? customer.email : ""}
                  />
                  <FormSelect 
                    options={[{label:"male", value: "male"}, {label:"female", value: "female"}]}
                    label={"Gender"}
                    value={gender}
                    handleChange={genderChangeHandler}
                  />
                </div>
              </div>
              <div className="w-full bg-white rounded-md p-[30px] flex flex-col mt-6 shadow-md">
                <p className="text-[#3D4356] text-[16px]">Profile Photo</p>
                <input type="file" hidden ref={fileInput}/>
                {selectedAvatar || picture ? (
                  <div className="w-[200px] border-dashed rounded h-[210px] relative mt-6 border border-[#E5E5E5]">
                    <img src={selectedAvatar ? selectedAvatar : picture} alt="" className="w-full h-full rounded-md"/>
                    <div className="absolute left-0 right-0 flex items-center mx-auto bg-white rounded-md bottom-4 w-[fit-content]">
                      <button 
                        onClick={() => {
                          setFile(null);
                          setSelectedAvatar(null);
                        }}
                        className="p-2"
                      >
                        <img src={deleteIcon} alt="" className="w-[22.03px] h-[22.33px]" />
                      </button>
                      <div className="h-[38px] w-[1px] bg-[#E3E7ED]"></div>
                      <button 
                        className="p-2"
                        onClick={handleFileSelect}
                      >
                        <img src={editIcon} alt="" className="w-[22.03px] h-[22.33px]"/>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div 
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    className="flex flex-col items-center justify-center w-[200px] mt-6 border border-dashed rounded-md py-[40px]">
                    <img src={upArrow} alt="" className="w-[41.67px] h-[41.36px]" />
                    <button 
                      onClick={handleFileSelect}
                      className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mt-3 font-medium"
                    >
                      Upload files
                    </button>
                    <p className="text-[#4C536A] text-[14px] mt-3">or drop files to upload</p>
                  </div>
                )}
              </div>
              <div className="w-full bg-white rounded-md p-[30px] flex flex-col mt-6 shadow-md relative">
                <p className="text-[#3D4356] text-[16px]">Shipping Address</p>
                <FormInput 
                  id="address-1"
                  name="address-1"
                  placeholder="Address 1"
                  label="Address 1"
                  onChange={handleInput}
                  disabled={!ready}
                  value={address}
                />
                {data.length > 0 && (
                  <ul className="absolute z-50 p-3 bg-white rounded-md shadow-lg top-[120px] w-[670px]">
                    {data.map((suggestion, idx) => {
                      return (
                        <li
                          key={idx}
                          onClick={() => onSelect(suggestion)}
                          className="flex items-center p-2 space-x-3 capitalize cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                        >
                          <img src={location} alt="" />
                          <p className="font-medium">{suggestion.description}</p>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="h-2"></div>
                {address && coords ? (
                  <div
                    className="relative"
                  >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      zoom={10}
                      center={coords}
                    >
                      <Marker position={coords}></Marker>
                    </GoogleMap>
                  </div>
                ) : null}
              </div>
              <div className="flex items-center justify-end mt-8 mb-28">
                <button 
                  onClick={() => setShowExitModal(true)}
                  className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mr-4">
                  Cancel
                </button>
                <button 
                  className="bg-[#F3641F] rounded-md px-4 py-2 text-white font-medium"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <SaveModal 
            isOpen={showSaveModal}
            close={() => setShowSaveModal(false)}
            icon={customersIcon}
            progressLabel={'Updating Customer'}
            successLabel={"Customer updated successfully"}
            busy={saving}
            update={true}
          />
          <UnsavedChanges 
            close={() => setShowExitModal(false)}
            isOpen={showExitModal}
          />
        </>
      )}
    </Layout>
  );
}

export default UpdateCustomer;
