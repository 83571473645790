import { TableCell, TableHead, TableRow, Checkbox } from "@material-ui/core";

const headCells = [
  { id: "id", label: "id" },
  { id: "name", label: "category name" },
  { id: "parent", label: "parent" },
  { id: "featured", label: "featured" },
  { id: "actions", numeric: true, label: "action" },
];

const CategoriesTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
}) => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            color="primary"
            className={`${
              numSelected > 0 && numSelected < rowCount && "text-orange"
            }`}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="uppercase text-[12px]"
            align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CategoriesTableHead;
