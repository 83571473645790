import React from 'react'
import useTableMethods from '../../customHooks/useTableMethods'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

//icons
import del from '../../assets/delete.svg'
import edit from '../../assets/edit.svg'
import view from '../../assets/view.svg'
import loader from '../../assets/loader.svg'

//temp
import productIcon from '../../assets/products-inactive.svg'

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
  TablePagination,
  Switch,
} from '@material-ui/core'

//components
import TableHead from './AvailableProductsTableHead'

import { copyToClipboard } from '@/utils'
import { CheckOutlined, CloseOutlined } from '@material-ui/icons'

const AvailableProducts = ({
  rows,
  viewLink,
  editLink,
  onDeleteClick = () => {},
  onSwitchClick = () => {},
  busy = false,
  notFound = false,
  search = '',
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}) => {
  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows)

  const addDefaultImage = (event) => {
    event.target.src = productIcon
  }

  const copyUuid = async (uuid) => {
    await copyToClipboard(uuid)
    toast.success(`Copied uuid "${uuid}"`)
  }

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <TableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{' '}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <TableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />

          <TableBody className="bg-white">
            {rows.map((row, index) => {
              const isItemSelected = isSelected(index)

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.id}
                  </TableCell>
                  <TableCell
                    title={`Click to copy uuid "${row.uuid}"`}
                    className="flex items-center space-x-2"
                    style={{height: "52px"}}
                    onClick={() => copyUuid(row.uuid)}
                  >
                    <img
                      className="w-8 h-8"
                      src={row.image}
                      alt=""
                      onError={addDefaultImage}
                    />
                    <p className="text-sm font-medium text-gray2 mb-0">
                      {row.name}
                    </p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.retailer_count}
                  </TableCell>
                  <TableCell padding="checkbox">
                    {row.featured ? 'Yes' : 'No'}
                  </TableCell>

                  <TableCell className="font-medium text-black" align="right">
                    ₦ {Number(row.price.amount).toLocaleString('en')}
                  </TableCell>

                  <TableCell align='center' className='xl:ml-10'>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={row.is_active}
                      onChange={() => onSwitchClick(row)}
                    />
                  </TableCell>

                  <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button>
                        <Link to={editLink.replace('{uuid}', row.uuid)}>
                          <img src={edit} alt="edit product" />
                        </Link>
                      </Button>
                      <Button>
                        <Link to={viewLink.replace('{uuid}', row.uuid)}>
                          <img src={view} alt="view product details" />
                        </Link>
                      </Button>
                      {/* <Button onClick={() => onDeleteClick(row)}>
                        <img src={del} alt="delete product" />
                      </Button> */}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1)
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value)
          }}
        />
      )}
    </div>
  )
}

export default AvailableProducts
