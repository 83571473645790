import React, { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { IoCloseOutline, IoArrowBackCircleOutline } from 'react-icons/io5'

//mui
import { IconButton, Button, Checkbox } from '@material-ui/core'

//components
import Layout from '@components/Layout/Layout'
import { RootContext } from '../..'
import { toast } from 'react-toastify'
import { addStock } from '../../services/stock'
import loader from '../../assets/loader.svg'
import CustomSelect from '../../components/CustomSelect/CustomSelect'

//utils
import { fetchAllAttributes, preventNonNumericalInput } from '../../utils'
import InventoryNumberInput from '../../components/InventoryNumberInput/InventoryNumberInput'

const ProductsToAdd = observer(() => {
  const root = useContext(RootContext)
  const [error, setError] = useState()
  // const [attributes, setAttributes] = useState([]);
  const [inventory, setInventory] = useState(
    root.inventoryStore.newInventory.map((item) => ({
      ...item,
      moq: 1,
      percentage: 0,
      freeDelivery: false,
    })),
  )
  const history = useHistory()
  const [busy, setBusy] = useState(false)

  const [discountPercentage, setDiscountPercentage] = useState(0) // Discount percentage state

  const handleLeavePage = () => {
    if (inventory.length === 0) history.push('/inventory')
  }

  const toastOptions = {
    onclose: () => handleLeavePage(),
  }

  // const fetchAttributes = async() => {
  //   try {
  //     const data = await fetchAllAttributes();
  //     const sortedList = await sortCategoriesOrAttributes(data, 'attribute');
  //     setAttributes(sortedList);
  //   } catch (error) {
  //     toast.error("Unable to fetch product attributes. Please try again later.");
  //     history.goBack();
  //   }
  // }

  const removeSuccessful = (list) => {
    let basket = [...inventory]
    list.forEach((element) => {
      basket = basket.filter((item) => item.id !== element)
    })
    setInventory(basket)
  }

  const sortCategoriesOrAttributes = async (categories, type) => {
    const parents = []
    categories.forEach((element) => {
      if (element.parent) {
        const parent = categories.find((item) => item.id === element.parent)
        element.parent_name = parent.name
      } else {
        parents.push(element)
      }
    })
    return categories
  }

  const increaseQuantity = (field, id) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) element[field]++
    })
    setInventory(basket)
  }

  const decreaseQuantity = (field, id) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id && element[field] > 0) element[field]--
    })
    setInventory(basket)
  }

  const setQuantity = (value, id, field) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) {
        if (value.substring(0, 1) === '0') {
          element[field] = value.substring(1)
        } else element[field] = value
      }
    })
    setInventory(basket)
  }

  const updateAttribute = (id, val) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) {
        element.attribute = val
      }
    })
  }

  const removeProduct = (id) => {
    const basket = inventory.filter((item) => item.id !== id)
    setInventory(basket)
  }

  const updatePrice = (id, value) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) {
        element.selling_price = value
      }
    })
  }

  const increaseDiscount = (id) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) {
        element.percentage++
      }
    })
    setInventory(basket)
  }

  const decreaseDiscount = (id) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id && element.percentage > 0) {
        element.percentage--
      }
    })
    setInventory(basket)
  }

  const setDiscount = (value, id) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) {
        if (value.substring(0, 1) === '0') {
          element.percentage = value.substring(1)
        } else element.percentage = value
      }
    })
    setInventory(basket)
  }

  const setFreeDelivery = (value, id) => {
    const basket = [...inventory]
    basket.forEach((element) => {
      if (element.id === id) {
        element.freeDelivery = value
      }
    })
    setInventory(basket)
  }

  const generateForm = (data) => {
    let form = {
      owner: root.authStore.user.retailerId,
      product: data.productId,
      quantity: data.quantity,
      moq: data.moq,
      attribute: data.attribute.id,
      free_delivery: data.freeDelivery,
      // add discount to item
      percentage: data.percentage,
      is_discounted: data.percentage > 0 ? true : false,
    }
    if (data.selling_price) form.selling_price = data.selling_price
    else form.selling_price = data.price.amount

    return form
  }

  const handleError = (error, name) => {
    setError(error)
    try {
      const messageArray = Object.values(error.response.data)
      if (
        messageArray[0][0] ===
        'The fields owner, product, attribute must make a unique set.'
      )
        toast.error(
          `You already have ${name} with the same attribute in stock.`,
        )
      else toast.error('An unexpected error occurred. Please try again later.')
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.')
    }
  }

  const updateInventory = async () => {
    try {
      setBusy(true)
      const success = []
      for (var i = 0; i < inventory.length; i++) {
        const element = inventory[i]

        if (!element.selling_price) {
          toast.info('Please add a selling price for your products')
          return
        }

        if (!element.attribute) {
          toast.info(`Please select an attribute for ${element.name}.`)
          return
        }
        const form = generateForm(element)
        await addStock(form)
          .then(() => {
            setBusy(false)
            success.push(element.id)
            toast.success(
              `${element.name} has been added to your inventory`,
              toastOptions,
            )
          })
          .catch((error) => {
            setBusy(false)
            handleError(error, element.name)
          })
      }
      removeSuccessful(success)
    } catch (error) {
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    if (inventory.length === 0) history.goBack()
  }, [inventory])

  // useEffect(() => {
  //   fetchAttributes();
  // }, [])

  const headers = [
    'Product Name',
    'RRP',
    'Selling Price',
    'Quantity',
    'Discount (%)',
    'MOQ',
    'Free Delivery',
    'SKU',
    'Sub Total',
    '',
  ]

  return (
    <Layout>
      <div className="space-y-10 page-padding">
        <div className="flex items-center space-x-3">
          <IconButton
            onClick={() => history.goBack()}
            disableRipple
            className="p-0 hover:bg-transparent"
          >
            <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
          </IconButton>
          <h1 className="font-bold text-[30px] text-gray1">Product List</h1>
        </div>
        {inventory.length > 0 ? (
          <div>
            <table className="w-full">
              <thead>
                <tr>
                  {headers.map((text) =>
                    text ? (
                      <th
                        className="uppercase text-[12px] font-semibold text-gray2 py-2 px-3"
                        key={text}
                        align="left"
                      >
                        {text}
                      </th>
                    ) : (
                      <th key={0}></th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y">
                {inventory.map((prod) => (
                  <tr key={prod.id}>
                    <td className="text-sm font-medium text-gray2 py-2 px-3">
                      {prod.name}
                    </td>
                    <td className="text-sm font-medium text-gray2 py-2 px-3">
                      ₦{Number(prod.price.amount).toLocaleString('en')}
                    </td>
                    <td className="text-sm font-medium text-gray2 py-2 px-3">
                      <div className="bg-[#F4F5F7] rounded-md flex items-center px-2 ">
                        <span>₦</span>
                        <input
                          type="text"
                          onKeyPress={preventNonNumericalInput}
                          className="w-full h-full bg-transparent border-0"
                          onChange={(e) => updatePrice(prod.id, e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="text-sm py-2 px-3">
                      <InventoryNumberInput
                        value={prod.quantity}
                        min={1}
                        handleChange={(e) =>
                          setQuantity(e.target.value, prod.id, 'quantity')
                        }
                        field="quantity"
                        handleIncrease={() =>
                          increaseQuantity('quantity', prod.id)
                        }
                        handleDecrease={() =>
                          decreaseQuantity('quantity', prod.id)
                        }
                      />
                    </td>
                    <td className="text-sm font-medium text-gray2 py-2 px-3">
                      <InventoryNumberInput
                        value={prod.percentage}
                        handleChange={(e) =>
                          setDiscount(e.target.value, prod.id)
                        }
                        field="percentage"
                        handleIncrease={() => increaseDiscount(prod.id)}
                        handleDecrease={() => decreaseDiscount(prod.id)}
                      />
                    </td>
                    <td className="text-sm py-2 px-3">
                      <InventoryNumberInput
                        value={prod.moq}
                        min={1}
                        handleChange={(e) =>
                          setQuantity(e.target.value, prod.id, 'moq')
                        }
                        field="moq"
                        handleIncrease={() => increaseQuantity('moq', prod.id)}
                        handleDecrease={() => decreaseQuantity('moq', prod.id)}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={prod.freeDelivery}
                        onClick={() =>
                          setFreeDelivery(!prod.freeDelivery, prod.id)
                        }
                      />
                    </td>
                    <td className="text-sm font-medium text-gray2 py-2 px-3">
                      {prod.attribute?.name}
                    </td>
                    <td className="text-sm font-medium text-gray2 py-2 px-3">
                      ₦
                      {Number(
                        prod.quantity *
                          parseInt(
                            prod.selling_price
                              ? prod.selling_price
                              : prod.price.amount,
                          ),
                      ).toLocaleString('en')}
                    </td>
                    <td className="text-sm py-2 px-3">
                      <button
                        className="p-1 rounded-full"
                        style={{ backgroundColor: '#FF3D22' }}
                        onClick={() => removeProduct(prod.id)}
                      >
                        <IoCloseOutline className="w-5 h-5 text-white" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex items-center justify-end pt-4 space-x-3">
              <Button
                variant="contained"
                className="capitalize bg-white border shadow-none"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={updateInventory}
                className={`relative capitalize shadow-none flex ${
                  busy ? 'bg-orange/20' : 'bg-orange'
                } text-white`}
              >
                Add Inventory
                {busy && (
                    <img src={loader} alt="" className="w-5 animate-spin ml-1" />
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div>no products</div>
        )}
      </div>
    </Layout>
  )
})

export default ProductsToAdd
