import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import searchIcon from '../../assets/search.svg'
import { observer } from 'mobx-react-lite'
import loader from '../../assets/loader.svg'

//mui
import { IconButton, Button } from '@material-ui/core'

//components
import Layout from '@components/Layout/Layout'
import FilterButton from '@components/FilterButton/FilterButton'
import ProductsTable from './Tables/ProductsTable'
import EmptySearch from './Tables/EmptySearch'
import { toast } from 'react-toastify'

//utils
import { fetchAllProductCategories } from '../../utils'

//services
import {
  getProducts,
  searchProducts,
  getProductsByCategory,
} from '../../services/products'
import { RootContext } from '../..'

const AddNewProduct = observer(() => {
  const root = useContext(RootContext)
  const history = useHistory()
  const [busy, setBusy] = useState(true)
  const [product, setProduct] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dataToFilter, setDataToFilter] = useState([])
  const [tableData, setTableData] = useState([])
  const [categories, setCategories] = useState([])

  let typingTimer

  const onSearchKeyDown = () => {
    clearTimeout(typingTimer)
  }

  const onSearchKeyUp = () => {
    typingTimer = setTimeout(() => {
      makeQuery()
    }, 1000)
  }

  const makeQuery = async () => {
    if (searchValue.length === 0) return
    setBusy(true)
    try {
      const { data } = await searchProducts({
        search: searchValue,
        page: 1,
        page_size: 100,
        all: 'no',
      })
      setTableData(data.results)
      setBusy(false)
    } catch (error) {
      toast.error('Unable to fetch requested data. Please try again later.')
      setBusy(false)
    }
  }

  const [controller, setController] = useState(null)
  const fetchProducts = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    try {
      const { data } = await getProducts({all: 'no'}, abc.signal)
      setTableData(data.results)
      setBusy(false)
    } catch (error) {
      toast.error('Unable to fetch products. Please try again later.')
      history.goBack()
    }
  }

  const fetchCategories = async () => {
    try {
      const data = await fetchAllProductCategories()
      setCategories(data)
    } catch (error) {
      toast.error('An error occurred. Unable to fetch product categories.')
    }
  }

  const handleFilterFinish = async (selectedCategories) => {
    setBusy(true)
    try {
      const { data } = await getProductsByCategory(selectedCategories, 100, 1)
      setTableData(data.results)
      setBusy(false)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch requested data.')
    }
  }

  useEffect(() => {
    fetchProducts()
    fetchCategories()
  }, [])

  return (
    <Layout>
      <div className="page-padding">
        <div className="flex items-center space-x-3">
          <IconButton
            onClick={() => history.goBack()}
            disableRipple
            className="p-0 hover:bg-transparent"
          >
            <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
          </IconButton>
          <h1 className="font-bold text-[30px] text-gray1">Add New Product</h1>
        </div>

        <div className="flex items-center space-x-5 mt-7">
          <div className="flex items-center flex-grow border-b border-b-blue">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              name=""
              placeholder="Search products"
              className="w-full bg-transparent border-0 focus:outline-none focus:ring-0"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={onSearchKeyDown}
              onKeyUp={onSearchKeyUp}
            />
          </div>

          <FilterButton
            categories={categories}
            onFinish={handleFilterFinish}
            className="bg-white"
          />
        </div>

        <div className="flex items-center justify-between p-2 my-5 rounded-md bg-orange/10">
          <p className="text-sm font-medium text-orange">
            When you don't find the products you searched for, please fill the
            product request form, and our team will make them available to you
            within 24 hours.
          </p>

          <Button
            className="text-white capitalize rounded-md shadow-none bg-orange"
            variant="contained"
            onClick={() => history.push('/inventory/requests/add')}
          >
            Fill Product Request Form
          </Button>
        </div>

        {busy ? (
          <div className="flex items-center justify-center h-40">
            <img src={loader} alt="" className="w-10 h-10 animate-spin" />
          </div>
        ) : (
          <>
            {tableData.length > 0 ? (
              <ProductsTable rows={tableData} searchValue={searchValue} />
            ) : (
              <EmptySearch value={searchValue} />
            )}
          </>
        )}
      </div>
    </Layout>
  )
})

export default AddNewProduct
