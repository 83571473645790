import React, { useState, useEffect, useContext } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import loader from '../../assets/loader.svg'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '@components/Layout/Layout'
import PageTitle from '@components/PageTitle/PageTitle'
import TabButton from '@components/TabButton/TabButton'
import InventoryTable from './Tables/InventoryTable'
import EmptySearch from './Tables/EmptySearch'
import FilterButton from '@components/FilterButton/FilterButton'
import Loader from '@components/Loader/Loader'
import ProductRequests from '@components/ProductsTables/ProductRequestsTable'
import SearchBar from '../../components/SearchBar/SearchBar'

//utils
import { exportData, fetchAllProductCategories } from '../../utils'

// services
import {
  deleteStock,
  getStock,
  getRetailerStockSummary,
} from '../../services/stock'

//store
import { RootContext } from '../..'
import InventoryModal from '../../components/InventoryModal/InventoryModal'

const tabs = [
  { label: 'All', id: 'all' },
  { label: 'In Stock', id: 'in_stock' },
  { label: 'Out of Stock', id: 'out_of_stock' },
  // { label: 'Requests' id: 'requests' },
  // { label: 'Deals' id: 'deals' },
]
const Index = observer(() => {
  const root = useContext(RootContext)
  // const history = useHistory();

  const [open, setOpen] = useState(false)
  const [productData, setProductData] = useState([])
  const [busy, setBusy] = useState(false)
  const [inventory, setInventory] = useState([])
  const [activeTab, setActiveTab] = useState('all')
  // const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [notFound, setNotFound] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [summaryData, setSummaryData] = useState([
    {
      title: 'All Products',
      amount: null,
      quantity: 0,
      growth: '0%',
    },
    {
      title: 'Available Stock on Hand',
      amount: 0,
      quantity: 0,
      growth: '0%',
    },
  ])
  const [controller, setController] = useState(null)
  const [exportController, setExportController] = useState(null)

  const fetchCategories = async () => {
    try {
      const data = await fetchAllProductCategories()
      setCategories(data)
    } catch (error) {
      toast.error('An error occurred. Unable to fetch product categories.')
    }
  }

  const fetchSummaryData = async () => {
    try {
      const { data: stockSummary } = await getRetailerStockSummary(
        root.authStore.user.retailerUuid,
      )
      const { data: stock } = await getStock(root.authStore.user.retailerUuid, {
        page: 1,
        page_size: 1,
        in_stock: true,
      })

      const copy = [...summaryData]

      // all products (stock products count)
      copy[0].quantity = stock.count
      // available stock at hand
      copy[1].amount = stockSummary.total_stock_revenue
      copy[1].quantity = stockSummary.total_stock_quantity
      setSummaryData(copy)
    } catch (e) {
      console.error(e)
      toast.error('An error occurred. Unable to fetch stock summary')
    }
  }

  const fetchInventory = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)

    try {
      const { data } = await getStock(
        root.authStore.user.retailerUuid,
        {
          category__uuid: String(selectedCategories),
          search: searchValue,
          page: currentPage,
          page_size: rowsPerPage,
          ...(activeTab !== 'all'
            ? { in_stock: activeTab === 'in_stock' }
            : {}),
        },
        abc.signal,
      )

      setInventory(data.results)
      setCount(data.count)

      if (data.count === 0) setNotFound(true)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch inventory. Please try again later.')
        setBusy(false)
      }
    }
  }

  const deleteFromInventory = async (id) => {
    try {
      await deleteStock(id)
      fetchInventory()
    } catch (error) {
      toast.error('Unable to update inventory. Please try again later.')
    }
  }

  const editProduct = (data) => {
    setProductData(data)
    setOpen(true)
  }

  const getInventoryExportData = async () => {
    exportController?.abort?.()
    const abc = new AbortController()
    setExportController(abc)

    toast.info('Fetching data to export')

    try {
      const { data } = await getStock(
        root.authStore.user.retailerUuid,
        {
          category__uuid: String(selectedCategories),
          ...(activeTab !== 'all'
            ? { in_stock: activeTab === 'in_stock' }
            : {}),
          paginate: 'no',
        },
        abc.signal,
      )

      exportData(
        data.map((item) => ({
          Store: item.store,
          Name: item.product.name,
          Attribute: item.attribute.name,
          Quantity: item.quantity,
          Status: item.out_of_stock ? 'Out of Stock' : 'In Stock',
          'Initial Price': item.initial_price
            ? `${item.initial_price.currency} ${item.initial_price.amount}`
            : '',
          'Retail Price': item.selling_price
            ? `${item.selling_price.currency} ${item.selling_price.amount}`
            : '',
        })),
        'Inventory',
      )
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later')
    } finally {
      toast.dismiss()
    }
  }

  useEffect(() => {
    fetchInventory()
  }, [activeTab, searchValue, currentPage, rowsPerPage, selectedCategories])

  useEffect(() => {
    fetchCategories()
    fetchSummaryData()
  }, [])

  return (
    <Layout>
      <div className="p-[30px] pt-5 space-y-3">
        <PageTitle title="Inventory" />

        <div className="grid grid-cols-4 gap-5">
          {summaryData.map((d, idx) => {
            return (
              <div key={idx} className="px-5 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">{d.title}</h1>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-1">
                    <p className="text-[20px] font-semibold">
                      {d.amount
                        ? `₦${Number(d.amount).toLocaleString('en')}`
                        : d.quantity?.toLocaleString('en') || 0}
                    </p>

                    {/* {d.growth && (
                      <div className="flex items-center text-green">
                        <IoArrowUpOutline className="w-3 h-3" />

                        <p className="text-sm font-medium">{d.growth}</p>
                      </div>
                    )} */}
                  </div>

                  <p className="text-sm font-medium text-gray1">
                    {d.amount && d.quantity.toLocaleString()}
                  </p>
                </div>
              </div>
            )
          })}
        </div>

        <SearchBar
          filterButton={
            <FilterButton
              categories={categories}
              activeTab={activeTab}
              onFinish={(selections) => {
                setCurrentPage(1)
                setSelectedCategories(selections)
              }}
            />
          }
          placeholder="Search Product"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onExport={getInventoryExportData}
        >
          {tabs.map(({ label, id }) => (
            <TabButton
              key={id}
              tab={id}
              activeTab={activeTab}
              onClick={() => {
                setCurrentPage(1)
                setActiveTab(id)
              }}
              title={label}
            />
          ))}
        </SearchBar>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : (
          <>
            {['all', 'in_stock', 'out_of_stock'].includes(activeTab) ? (
              <div className="py-5 space-y-5">
                <div className="flex justify-end">
                  <AddToInventoryButton />
                </div>

                {inventory.length ? (
                  <InventoryTable
                    rows={inventory}
                    search={searchValue}
                    busy={busy}
                    notFound={notFound}
                    onEditClick={editProduct}
                    onRemoveClick={deleteFromInventory}
                    count={count}
                    onRowsPerPageChange={(rows) => {
                      setRowsPerPage(rows)
                      setCurrentPage(1)
                    }}
                    onPageChange={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                  />
                ) : (
                  <EmptySearch value={searchValue} />
                )}
              </div>
            ) : null}

            {/*activeTab === "requests" && (
              <div className="py-5 space-y-5">
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="capitalize shadow-none"
                    startIcon={<IoAddOutline />}
                    onClick={() => {}}
                  >
                    Add New Request
                  </Button>
                </div>

                {filteredData.length > 0 ? (
                  <ProductRequests rows={filteredData} requests />
                ) : (
                  <EmptySearch value={searchValue} />
                )}
              </div>
            )*/}

            {/*activeTab === "deals" && (
              <div className="py-5 space-y-5">
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="capitalize shadow-none"
                    startIcon={<IoAddOutline />}
                    onClick={() => {}}
                  >
                    Add New Deal
                  </Button>
                </div>

                {filteredData.length > 0 ? (
                  <InventoryTable
                    rows={filteredData}
                    search={searchValue}
                    busy={busy}
                    notFound={notFound}
                    onViewClick={viewProduct}
                    onRemoveClick={deleteFromInventory}
                    count={count}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onPageChange={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                    page={currentPage - 1}
                  />
                ) : (
                  <EmptySearch value={searchValue} />
                )}
              </div>
            )*/}
          </>
        )}
      </div>

      {open && (
        <InventoryModal
          open={open}
          onClose={() => {
            setOpen(false)
            setProductData([])
            fetchInventory()
          }}
          data={productData}
        />
      )}
    </Layout>
  )
})

export default Index

const AddToInventoryButton = () => {
  const history = useHistory()
  return (
    <Button
      variant="contained"
      color="primary"
      className="capitalize shadow-none"
      startIcon={<IoAddOutline />}
      onClick={() => history.push(`/inventory/product/add`)}
    >
      Add New Product
    </Button>
  )
}
