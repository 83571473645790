import { withStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import { useHistory } from "react-router";

//assets
import ellipsis from "../../assets/horizontal-ellipsis.svg";
import couponIcon from "../../assets/coupon.svg";
import { formatDate } from "../../utils";

function CouponListItem ({
  value,
  type,
  code,
  limit,
  usage,
  quantity,
  used,
  creationDate,
  expiryDate,
  id
}) {

  const isExpired = new Date() > new Date(expiryDate)
  const history = useHistory();

  return (
    <div
      className={`border-[2px] border-dashed border-${used >= quantity ? 'red' : 'white'} bg-white rounded-md p-[20px] relative shadow cursor-pointer`}
      onClick={() => history.push(`/coupons/single-coupon/${id}`)}
    >
      <div className="flex flex-row items-center mb-[10px]">
        <img 
          src={couponIcon}
          alt="coupon icon"
          className="w-[57px] h-[57px] mr-[23.75px]"
        />
        <div className="flex flex-col">
          <h1
            className="font-semibold text-[#040A1D] text-[24px] m-0 leading-none"
          >
            {type.toLowerCase() === 'fixed amount' && '₦'} {value}{type.toLowerCase() === 'percent amount' && '%'} off
          </h1>
          <p className="font-medium text-[14px] text-[#9CA7B8] m-0">Any purchase</p>
        </div>
      </div>
      <img 
        src={ellipsis}
        alt=""
        className="absolute top-[20px] right-[20px]"
      />
      <div
        className="flex flex-row justify-between px-[20px] py-[10px] rounded-md bg-[#F4F5F7] items-center mb-[20px]"
      >
          <p className="text-[#8892A2] text-[14px] m-0">
            Code Name
          </p>
          <p className="text-[#040A1D] text-[16px] font-medium m-0">
            { code }
          </p>
      </div>
      <div className="flex flex-row items-center justify-between mb-[6px]">
        <div className="flex flex-col">
          <p className="text-[#9CA7B8] m-0">Usage/Limit</p>
          <p className="text-[#040A1D] font-semibold text-[14px] m-0">{usage}/{limit}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-[#9CA7B8] text-[14px] m-0">Used</p>
          <p className="text-[#8892A2] text-[14px] font-semibold m-0">{used}/{quantity}</p>
        </div>
      </div>
      {type.toLowerCase() === "fixed amount" ? (
        <GreenCustomLinearProgress 
          variant="determinate"
          value={(used/quantity) * 100}
        />
      ) : (
        <BlueCustomLinearProgress 
          variant="determinate"
          value={(used/quantity) * 100}
        />
      )}
      <div
        className={`${isExpired ? "bg-red bg-opacity-10" : "bg-[#F4F5F7]"} rounded-md flex flex-row items-center mt-[20px] px-[20px] py-[13px]`}
      >
        <div className="flex flex-col mr-[30px]">
          <p className={`text-${isExpired ? 'red' : '[#8892A2]'} text-[14px] m-0`}>Date Created</p>
          <p className={`text-${isExpired ? 'red' : '[#040A1D]'} font-semibold text-[14px] m-0`}>
            {formatDate(creationDate)}
          </p>
        </div>
        <div className="flex flex-col">
          <p className={`text-${isExpired ? 'red' : '[#8892A2]'} text-[14px] m-0`}>Expiration Date</p>
          <p className={`text-${isExpired ? 'red' : '[#040A1D]'} font-semibold text-[14px] m-0`}>
            {formatDate(expiryDate)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CouponListItem;

const GreenCustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#b7ebd3'
  },
  colorSecondary: {
    backgroundColor: "#38C985"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#38C985'
  }
}))(LinearProgress);

const BlueCustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: "#cddcfe"
  },
  colorSecondary: {
    backgroundColor: "#477DFB"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#477DFB"
  }
}))(LinearProgress);
