import React from "react";
import logo from "../../assets/logo.svg";
import completed from "../../assets/completed.svg";
import print from "../../assets/print.svg";
import pdf from "../../assets/pdf.svg";
import { IoCloseOutline } from "react-icons/io5";

//mui
import { Dialog, IconButton, ButtonGroup, Button } from "@material-ui/core";

const OrderHistoryModal = ({ onClose, open }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paperWidthSm: "max-w-none",
        paper: "",
      }}
    >
      <div className="overflow-x-hidden overscroll-y-auto order-history">
        <div className="bg-white w-[900px] p-10  border-t-4 border-t-orange">
          <div className="flex items-center justify-between pb-10">
            <img src={logo} alt="" className="w-32" />

            <IconButton className="p-0" onClick={onClose}>
              <IoCloseOutline className="w-8 h-8" />
            </IconButton>
          </div>

          <div className="p-6 rounded-lg bg-orange/10 space-y-7">
            <h1 className="text-[26px] text-gray1">Invoice</h1>

            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <p className="font-medium text-gray1">
                  <span className="inline-block mr-2 text-sm text-gray3">
                    Date:
                  </span>
                  07/28/2021 19:37
                </p>

                <p className="font-medium text-gray1">DND100928</p>
              </div>

              <span className="flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 bg-green/20 text-green">
                <img src={completed} alt="" />

                <p>Completed</p>
              </span>
            </div>

            <div className="grid grid-cols-3 gap-5">
              <div>
                <h1 className="font-semibold uppercase text-orange text-[12px]">
                  Bill From:
                </h1>

                <h2 className="text-gray1 text-[18px] font-medium py-2">
                  Drinks NG Limited
                </h2>

                <div className="space-y-5 text-sm text-gray2">
                  <p className="w-[80%]">
                    Tetteh Ashong Street, Accra 00233 Ghana GA-202-4895
                  </p>

                  <p className="">+233 7837 892 89</p>
                </div>
              </div>

              <div />

              <div>
                <h1 className="font-semibold uppercase text-green text-[12px]">
                  Bill To:
                </h1>

                <h2 className="text-gray1 text-[18px] font-medium py-2">
                  Done Little
                </h2>

                <div className="space-y-5 text-sm text-gray2">
                  <p className=" w-[80%]">
                    Tetteh Ashong Street, Accra 00233 Ghana GA-202-4895
                  </p>

                  <p className="">+233 7837 892 89</p>

                  <p>done@little.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="flex items-center py-3">
              <div className="w-10" />

              <div className="w-full grid grid-cols-[6fr,2fr,2fr,2fr] pl-2 pr-6">
                <h1 className="text-[12px] text-gray3 font-medium">Item</h1>
                <h1 className="text-right text-[12px] text-gray3 font-medium">
                  Qty
                </h1>
                <h1 className="text-right text-[12px] text-gray3 font-medium">
                  Price
                </h1>
                <h1 className="text-right text-[12px] text-gray3 font-medium">
                  Total
                </h1>
              </div>
            </div>

            <div className="h-40 space-y-1 overflow-y-scroll">
              <div className="flex items-center pr-2">
                <div className="flex items-center w-10 text-sm text-gray3">
                  1
                </div>

                <div className="w-full grid grid-cols-[6fr,2fr,2fr,2fr] bg-[#EEF2F5] rounded-md py-2 px-2 hover:cursor-pointer hover:bg-orange text-gray1 hover:text-white">
                  <h1 className="text-sm font-medium ">
                    Chivas XV 15yrs – 70cl + Notebook
                  </h1>
                  <h1 className="text-sm font-medium text-right ">2</h1>
                  <h1 className="text-sm font-medium text-right ">₵250</h1>
                  <h1 className="text-sm font-medium text-right ">₵500</h1>
                </div>
              </div>

              <div className="flex items-center pr-2">
                <div className="flex items-center w-10 text-sm text-gray3">
                  2
                </div>

                <div className="w-full grid grid-cols-[6fr,2fr,2fr,2fr] bg-[#EEF2F5] rounded-md py-2 px-2 hover:cursor-pointer hover:bg-orange text-gray1 hover:text-white">
                  <h1 className="text-sm font-medium ">
                    Chivas XV 15yrs – 70cl + Notebook
                  </h1>
                  <h1 className="text-sm font-medium text-right ">2</h1>
                  <h1 className="text-sm font-medium text-right ">₵250</h1>
                  <h1 className="text-sm font-medium text-right ">₵500</h1>
                </div>
              </div>

              <div className="flex items-center pr-2">
                <div className="flex items-center w-10 text-sm text-gray3">
                  3
                </div>

                <div className="w-full grid grid-cols-[6fr,2fr,2fr,2fr] bg-[#EEF2F5] rounded-md py-2 px-2 hover:cursor-pointer hover:bg-orange text-gray1 hover:text-white">
                  <h1 className="text-sm font-medium ">
                    Chivas XV 15yrs – 70cl + Notebook
                  </h1>
                  <h1 className="text-sm font-medium text-right ">2</h1>
                  <h1 className="text-sm font-medium text-right ">₵250</h1>
                  <h1 className="text-sm font-medium text-right ">₵500</h1>
                </div>
              </div>

              <div className="flex items-center pr-2">
                <div className="flex items-center w-10 text-sm text-gray3">
                  4
                </div>

                <div className="w-full grid grid-cols-[6fr,2fr,2fr,2fr] bg-[#EEF2F5] rounded-md py-2 px-2 hover:cursor-pointer hover:bg-orange text-gray1 hover:text-white">
                  <h1 className="text-sm font-medium ">
                    Chivas XV 15yrs – 70cl + Notebook
                  </h1>
                  <h1 className="text-sm font-medium text-right ">2</h1>
                  <h1 className="text-sm font-medium text-right ">₵250</h1>
                  <h1 className="text-sm font-medium text-right ">₵500</h1>
                </div>
              </div>

              <div className="flex items-center pr-2">
                <div className="flex items-center w-10 text-sm text-gray3">
                  5
                </div>

                <div className="w-full grid grid-cols-[6fr,2fr,2fr,2fr] bg-[#EEF2F5] rounded-md py-2 px-2 hover:cursor-pointer hover:bg-orange text-gray1 hover:text-white">
                  <h1 className="text-sm font-medium ">
                    Chivas XV 15yrs – 70cl + Notebook
                  </h1>
                  <h1 className="text-sm font-medium text-right ">2</h1>
                  <h1 className="text-sm font-medium text-right ">₵250</h1>
                  <h1 className="text-sm font-medium text-right ">₵500</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3">
            <div className="flex items-end justify-start">
              <ButtonGroup className="">
                <Button className="border-[#E3E7ED]">
                  <img src={pdf} alt="" />
                </Button>
                <Button className="border-[#E3E7ED]">
                  <img src={print} alt="" />
                </Button>
              </ButtonGroup>
            </div>

            <div />

            <div className="flex items-end justify-end pt-5">
              <div className="w-full space-y-3">
                <div className="flex items-center justify-between">
                  <p className="text-[12px] text-gray2 font-medium capitalize">
                    sub total
                  </p>

                  <p className="text-sm font-medium text-gray1">
                    ₦{Number(1500).toLocaleString("en")}
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p className="text-[12px] text-gray2 font-medium capitalize">
                    shipping
                  </p>

                  <p className="text-sm font-medium text-gray1">
                    ₦{Number(100).toLocaleString("en")}
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p className="text-[12px] text-gray2 font-medium capitalize">
                    tax
                  </p>

                  <p className="text-sm font-medium text-gray1">
                    ₦{Number(50).toLocaleString("en")}
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p className="text-[12px] text-gray2 font-medium capitalize">
                    order amount
                  </p>

                  <p className="text-[20px] font-medium text-orange">
                    ₦{Number(1650).toLocaleString("en")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OrderHistoryModal;
