import { observer } from "mobx-react-lite";
import { useState, useContext } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { RootContext } from "../..";

// icons
import brandsIcon from "../../assets/brands.svg";

//mui
import { Button } from "@material-ui/core";

// components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchBar from "../../components/SearchBar/SearchBar";
import TabButton from "../../components/TabButton/TabButton";

// services
import { exportData, getTextContent } from "../../utils";
import BrandsTable from "../../components/BrandsTable/BrandsTable";
import { exportAllBrands, getBrands, searchBrand } from "../../services/brands";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Index = observer(() => {
  const root = useContext(RootContext);
  const history = useHistory();

  const [busy, setBusy] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [activeTab, setActiveTab] = useState("Brands");
  const [brands, setBrands] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [controller, setController] = useState(null)

  const fetchBrands = async (pageSize, pageNumber) => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true);
    try {
      const { data } = await getBrands({
        page: currentPage,
        page_size: rowsPerPage,
        search: searchValue,
      }, abc.signal);

      setBrands(data.results);
      setCount(data.count);
      setBusy(false);

    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error("An error occurred. Unable to fetch brands");
        setBusy(false);
      }
    }
  };

  const fetchExportData = async () => {
    toast.info("Fetching data to export...");
    try {
      const { data } = await getBrands({ search: searchValue, paginate: 'no' });
      exportData(data.map(entry => ({
        'ID': entry.id,
        'Name': entry.name,
        'Description': getTextContent(entry.description),
        'Logo': entry.logo || '',
        'Cover Photo': entry.cover_photo || '',
        'Parent Brand': entry.parent || 'N/A'
      })), "Brands");
    } catch (error) {
      toast.error("Unable to export requested data. Please try again later!");
    } finally {
      toast.dismiss();
    }
  };

  useEffect(() => {
    fetchBrands();
  }, [
    activeTab,
    searchValue,
    currentPage,
    rowsPerPage
  ]);

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Brands" />

        <SearchBar
          placeholder="Search Brands"
          onChange={(e) => {
            setCurrentPage(1)
            setSearchValue(e.target.value)
          }}
          value={searchValue}
          onExport={fetchExportData}
        >
          <TabButton
            activeTab={activeTab}
            onClick={() => setActiveTab("Brands")}
            title="Brands"
          />
        </SearchBar>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : (
          <>
            {brands.length === 0 && searchValue.length === 0 ? (
              <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
                <img src={brandsIcon} alt="" className="w-16 h-16" />
                <p className="text-[18px] font-semibold text-gray1">
                  All your brands will show here
                </p>

                <p className="text-sm text-gray2">
                  This is where you add and update your brands
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={() => history.push("/brands/add-new-brand")}
                >
                  Add New Brand
                </Button>
              </div>
            ) : (
              <>
                {activeTab === "Brands" && (
                  <div className="py-5 space-y-5">
                    <div className="flex justify-end">
                      <Button
                        variant="contained"
                        color="primary"
                        className="capitalize shadow-none"
                        startIcon={<IoAddOutline />}
                        onClick={() => history.push("/brands/add-new-brand")}
                      >
                        Add New Brand
                      </Button>
                    </div>

                    <BrandsTable
                      rows={brands}
                      search={searchValue}
                      busy={busy}
                      notFound={notFound}
                      count={count}
                      onRowsPerPageChange={(rows) => {
                        setCurrentPage(1)
                        setRowsPerPage(rows)
                      }}
                      onPageChange={setCurrentPage}
                      page={currentPage - 1}
                      rowsPerPage={rowsPerPage}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
});

export default Index;
