import React from "react";
import { Button } from "@material-ui/core"
import { useHistory } from "react-router";

//components
import Layout from "../components/Layout/Layout";

const NotFound = () => {
  const history = useHistory();

  return(
    <Layout>
      <div className="page-padding bg-white rounded h-[609px] mx-[30px] mt-[120px] flex flex-col justify-center items-center">
        <h1 className="font-semibold text-[68px] text-[#3D4356] mb-[12px]">Oops!</h1>
        <h3 className="text-[#3D4356] font-semibold text-[18px] my-0">Sorry, something went wrong</h3>
        <p className="text-[14px] text-[#8892A2] font-normal mt-[23px]">We can't seem to find the page you're looking for</p>
        <Button
          variant={"contained"}
          disableElevation
          className="mt-8 text-white capitalize bg-orange"
          onClick={() => history.push('/')}
        >
          Go back to Dashboard
        </Button>
      </div>
    </Layout>
  )
}

export default NotFound;