import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const FinanceCompactGraph = ({changeType, changeValue, label, borderColor, backgroundColor, border = false, count = false, data}) => {
  const [chartData, setChartData] = useState({
    labels: ['12:00am', '1:00am', '2:00am', '3:00am', '4:00am', '5:00am', 
      '6:00am', '7:00am', '8:00am', '9:00am', '10:00am', '11:00am', '12:00pm', 
      '1:00pm', '2:00pm', '3:00pm', '4:00pm', '5:00pm', '6:00pm', '7:00pm', 
      '8:00pm', '9:00pm', '10:00pm', '11:00pm', '11:59pm'],
    datasets: [
      {
        label: "Sales Value",
        data: [20, 40, 10, 20, 23, 1, 0, 12, 9, 10, 20, 54, 23, 1, 43, 12, 45, 23, 30, 12, 9, 23, 43, 13],
        fill: true,
        backgroundColor: backgroundColor,
        borderColor: [
          borderColor
        ],
        borderWidth: 2,
        tension: 0,
        pointStyle: 'line'
      },
    ]
  });
  const [total, setTotal] = useState(0);

  const tickCallback = (value) => {
    if(value === '12:00am')
      return '12:00am';
    else if(value === '11:59pm')
      return '11:59pm';
    return null;
  }

  const chartOptions = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
        },
      }],
      xAxes: [{
        display: true,
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: tickCallback,
          maxRotation: 0,
          minRotation: 0,
          fontColor: ['#9CA7B8'],
          beginAtZero: false,
          autoSkip:false
        },
      }]
    },
    legend: {
      display: false
    },
  }

  const parseHourlyData = () => {
    const amountReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.order.final_cost.amount);
    const totalReducer = (previousValue, currentValue) => previousValue + parseInt(currentValue);
    let graphData = [];

    for(let i = 0; i < 24; i++) {
      const hourArray = data.filter((transaction) => new Date(transaction.order.created_at).getHours() === i);
      if(hourArray.length > 0) {
        const total = hourArray.reduce(amountReducer, 0);
        graphData.push(total)
      } else {
        graphData.push(0)
      }
    }
    const total = graphData.reduce(totalReducer, 0);
    let chartDataCopy = {...chartData};
    chartDataCopy.datasets[0].data = [...graphData];
    setChartData(chartDataCopy);
    setTotal(total);
  }

  useEffect(() => {
    parseHourlyData();
  }, [])

  return (
    <div className={`flex flex-col w-full pb-2 ${border ? "border-l border-[#E3E7ED] px-2" : 'pr-2'} relative`}>
      <p className="text-[#3D4356] text-[14px] font-medium pt-4">{label}</p>
      {count && (
        <p className="absolute text-[#9CA7B8] text-[26px] font-bold top-[35px] right-[20px]">{data.length}</p>
      )}
      <div className="flex flex-row items-center">
        <p className="text-[#040A1D] font-bold text-[26px]">₦{Number(total).toLocaleString('en')}</p>
        {/* {changeValue && (
          <span className="flex flex-row items-center ml-2">
            {changeType === 'rise' ? (
              <img src={greenArrow} alt="green arrow pointing up" className="w-5 h-6 lg:w-4 lg:h-5"/>
            ) : (
              <img src={redArrow} alt="red arrow pointing down" className="w-5 h-6"/>
            )}
            <p 
              className={`${changeType === 'rise' ? 'text-appGreen' : 'text-appRed'} font-medium lg:text-[14px]`}>
              {changeValue}%
            </p>
          </span>
        )} */}
      </div>
      <div className="h-[156px]">
        <Line 
          data={chartData}
          options={chartOptions}
          id="chart-finance"
          className="chart-canvas"
          height={null}
          width={null}
        />
      </div>
    </div>
  )
}

export default FinanceCompactGraph;