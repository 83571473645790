import api from '../apiInstance'

export const getRetailers = (params, signal) =>
  api.get('/api/retailer/', {
    params,
    signal,
  })

export const createNewRetailer = (data) => api.post('/api/retailer/', data)

export const getRetailerProfile = () => api.get('/api/retailer/')

export const getTopRetailers = (params, signal) =>
  api.get('/api/retailer/top-retailer/', { params, signal })

export const getRetailer = (retailerId) =>
  api.get(`/api/retailer/${retailerId}/`)

export const updateRetailerStatus = (retailerId, status) =>
  api.post(`/api/retailer-approval/${retailerId}`, { status: status })

export const updateRetailer = (retailerId, form) =>
  api.patch(`/api/retailer/${retailerId}/`, form)

export const updateWorkingHours = (retailerId, data) =>
  api.patch(`/api/retailer/${retailerId}/working_hours_update/`, data)
export const getWorkingHours = (retailerId) =>
  api.get(`/api/retailer/${retailerId}/working_hours/`)
