import React, { createContext } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './utils/muiTheme'

// Sentry
import './sentry-config'

//components
import App from './App'
import PageScrollToTop from './components/PageScrollToTop/PageScrollTop'
import RootStore from './stores/root'

//styles
import 'react-toastify/dist/ReactToastify.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-daterange-picker/dist/css/react-calendar.css'
import './styles/globals.css'
import 'antd/dist/antd.css'

const RootContext = createContext()

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <PageScrollToTop />
      <RootContext.Provider value={new RootStore()}>
        <App />
      </RootContext.Provider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
)

export { RootContext }
