import React, { Fragment } from 'react'

import useTableMethods from '../../../customHooks/useTableMethods'

//icons
import view from '../../../assets/view.svg'
import edit from '../../../assets/edit.svg'
import loader from '../../../assets/loader.svg'

//temp
import drink from '../../../assets/drink.png'

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
  TablePagination,
} from '@material-ui/core'

//components
import TableHead from './CustomTableHead'
import CustomTableHead from './CustomTableHead'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Inventory = ({
  rows,
  busy = false,
  search = '',
  onEditClick,
  notFound = false,
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}) => {
  const { handleSelectAllClick, handleClick, isSelected, selected } =
    useTableMethods(rows)

  const addDefaultImage = (event) => {
    event.target.src = drink
  }

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
            {busy || !notFound ? (
              <img src={loader} alt="loading" className="w-5 animate-spin" />
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{' '}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <TableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />

          <TableBody className="bg-white">
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row)

              return (
                <Fragment key={index}>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row)}
                      />
                    </TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <img
                        src={row.product.image}
                        alt=""
                        className="w-[44px] h-[44px]"
                        onError={addDefaultImage}
                      />

                      <p className="text-sm font-medium text-gray2">
                        {row.product.name}
                      </p>
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.attribute && row.attribute.name}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      {row.quantity}
                    </TableCell>
                    <TableCell className="text-sm font-medium text-gray2">
                      <span
                        className={`px-3 py-1 rounded-2xl capitalize ${
                          !row.out_of_stock && 'bg-gray2/20 text-gray2'
                        } ${row.out_of_stock && 'bg-red/20 text-red'}
                        `}
                      >
                        {row.out_of_stock ? 'Out of Stock' : 'In Stock'}
                      </span>
                    </TableCell>

                    <TableCell className="font-medium text-black" align="right">
                      {row.initial_price
                        ? `₦${Number(row.initial_price.amount).toLocaleString(
                            'en',
                          )}`
                        : '-'}
                    </TableCell>
                    <TableCell className="font-medium text-black" align="right">
                      ₦{Number(row.selling_price.amount).toLocaleString('en')}
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup size="medium">
                        <Button onClick={() => onEditClick(row)}>
                          <img src={edit} alt="" />
                        </Button>
                        <Button>
                          <Link to={`/inventory/${row.uuid}`}>
                            <img src={view} alt="" />
                          </Link>
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                  {row.deals && row.deals.length > 0 && (
                    <TableRow className="bg-[#EEF2F5] text-blue">
                      <TableCell colSpan={7}>
                        <div className="flex items-center px-12 py-2 space-x-5">
                          <p className="font-medium text-blue text-[12px]">
                            Deals/Promo Items
                          </p>

                          <div className="space-x-2">
                            {row.deals.map((deal, idx) => {
                              return (
                                <span
                                  key={idx}
                                  className="p-1 text-sm font-medium border rounded-md text-gray2 border-blue"
                                >
                                  {deal}
                                </span>
                              )
                            })}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={(e, newPage) => {
            onPageChange(newPage + 1)
          }}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e.target.value)
          }}
        />
      )}
    </div>
  )
}

export default Inventory

// const CustomTableCell = ({ children, ...otherProps }) => {
//   return (
//     <TableCell
//       classes={{
//         root: "border-b-0",
//       }}
//       {...otherProps}
//     >
//       {children}
//     </TableCell>
//   );
// };
