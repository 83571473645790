import qs from 'qs'
import api from '../apiInstance'

export const getFinanceTransactions = (retailer, params, signal) =>
  api.get('/api/finance-transactions/', {
    params: {
      ...params,
      ...(retailer ? { retailer } : {}),
    },
    signal,
  })

export const getTransaction = (id) =>
  api.get(`/api/finance-transactions/${id}/`)

export const getTransactionsByPeriod = (
  retailer,
  period,
  pageNumber,
  pageSize,
) =>
  api.get('/api/finance-transactions/', {
    params: {
      ...(retailer ? { retailer } : {}),
      ...(pageNumber ? { page: pageNumber } : {}),
      ...(pageSize ? { page_size: pageSize } : {}),
      date: period,
    },
  })

export const getTransactionsByDateRange = (
  retailer,
  start,
  end,
  pageNumber,
  pageSize,
) =>
  api.get('/api/finance-transactions/', {
    params: {
      end_date: start,
      start_date: end,
      ...(retailer ? { retailer } : {}),
      ...(pageNumber ? { page: pageNumber } : {}),
      ...(pageSize ? { page_size: pageSize } : {}),
    },
  })

export const searchRetailerTransactions = (params, signal) =>
  api.get(`/api/finance-transactions/`, {
    params: {
      page: 1,
      page_size: 100,
      ...params,
    },
    signal,
  })

export const getFinanceOverview = () =>
  api.get(`/api/finance-transactions/finance-overview/`)

export const getRetailerFinanceOverview = (retailerId) =>
  api.get(`/api/finance-transactions/finance-overview/`, {
    params: {
      retailer: retailerId,
    },
  })

export const exportTransaction = async (parameters) => {
  const params = qs.stringify(parameters)
  try {
    let next = `/api/finance-transactions/?${params}`
    let results = []
    do {
      const { data } = await api.get(next)
      next = data.next
      results = results.concat(data.results)
    } while (next)
    return results
  } catch (error) {
    throw error
  }
}
