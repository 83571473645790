import React, { useState, useContext } from "react";
import { IoArrowUp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Editor from "@components/TinyMCE";

//icons
import brandIcon from "../../assets/brands.svg";
import loader from "../../assets/loader.svg";
import success from "../../assets/success.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";

//mui
import { Button, Dialog, ButtonGroup } from "@material-ui/core";

//components
import UnsavedChanges from "../UnsavedChanges/UnsavedChanges";
import { RootContext } from "../..";
import { createBrand, getBrands } from "../../services/brands";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import CustomMultiSelectSearch from "../CustomMultiSelect/CustomMultiSelectSearch";

// services
import {
  getProducts,
  searchProducts_,
  updateProduct,
} from "../../services/products";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import Slider from "react-slick";
import CustomSelect from "../CustomSelect/CustomSelect";

const sliderSettings = {
  dots: true,
  dotsClass: "custom-slick-dots",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0,
};

const AddNewBrandForm = () => {
  const root = useContext(RootContext);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [controller, setController] = useState(null)
  //modal state
  const [open, setOpen] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  //brand details
  const [brandName, setBrandName] = useState("");
  const [description, setDescription] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [officialStore,setOfficialStore]=useState({name:'',value:''})

  // product
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);


  //brands
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      controller?.abort?.()
      const abc = new AbortController()
      setController(abc)

      setBusy(true)
      try {
        const [response1, response2] = await Promise.all([
          getBrands({
            paginate: 'no',
          },abc.signal),
          getProducts({},abc.signal),
        ]);

        const brandItems = response1.data.map((brand) => ({
          name: brand.name,
          value: brand.id,
        }));

        const productItems = response2.data.results.map((product) => ({
          label: (
              <div className="flex gap-2">
                <img src={product.image} alt="" className="w-6" />
                <span>{product.name}</span>
              </div>
          ),
          value: product.uuid,
          image: product.image,
        }));


        setBrands(brandItems);
        setProducts(productItems);
        setBusy(false)
      } catch (error) {
        toast.error('Error fetching data:', error);
        setBusy(false)
      }
    };

    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("logo", brandLogo);
    formData.append("cover_photo", bannerImage);
    formData.append("name", brandName);
    formData.append("description", description);
    formData.append("parent", officialStore.value);

    handleClickOpen();
    setLoading(true);

    try {
      await createBrand(formData).then((res) => {
        try {
          const brandId = res.data.id;
          if (selectedProducts.length > 0) {
            selectedProducts.forEach(async (product) => {
              const data = { brand: [brandId] };
              await updateProduct(data, product.value);
            });
          }
        } catch (error) {
          setLoading(false);
          handleClose();
          toast.error("Error creating brand.");
        }
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleClose();
      toast.error("Error creating brand.");
    }
  };

  const clearInfo = () => {
    setBrandName("");
    setDescription("");
    setBrandLogo("");
    setBannerImage("");
    setSelectedProducts([]);
  };

  const fetchSearchProduct = async (value) => {
    try {
      const res = await searchProducts_(value);

      const items = res.data.results.map((product) => ({
        label: (
          <div className="flex gap-2">
            <img src={product.image} alt="" className="w-6" />
            <span>{product.name}</span>
          </div>
        ),
        value: product.uuid,
      }));

      return items;
    } catch (error) {
    }
  };


  return (
    <>
      {busy && products.length === 0 && brands.length === 0? (
        <Loader />
      ) : (
        <>
          <p className="text-[#040A1D] text-[16px] font-semibold w-[70%] text-left">
            Banner Preview
          </p>
          {bannerImage ? (
            <div className="w-[70%]">
              <Slider {...sliderSettings}>
                <div>
                  <img
                    src={URL.createObjectURL(bannerImage)}
                    alt="img"
                    className="w-full object-cover"
                    style={{
                      aspectRatio: "1440/428",
                      objectPosition: "left top",
                    }}
                  />
                </div>
              </Slider>
            </div>
          ) : (
            <p>Preview of selected image will appear here</p>
          )}
          <p className="text-[#4C536A] text-[14px] mb-1 text-left w-[70%]">
            Regular banner image size sholud be&nbsp;
            <span className="font-bold"> 1440x428 </span> pixels
          </p>
          <div className="bg-white w-[70%] p-5">
            <div
              className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-36 w-40"
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                setBannerImage(e.dataTransfer.files[0]);
              }}
            >
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
              </div>
              <label
                htmlFor="banner-image"
                className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
              >
                <p className="mb-0">Upload File</p>
                <input
                  type="file"
                  id="banner-image"
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    setBannerImage(e.target.files[0]);
                  }}
                  className="hidden"
                />
              </label>

              <p className="text-sm text-gray2">or drop files to upload</p>
            </div>
          </div>
          <div className="bg-white w-[70%] p-5 space-y-5">
            <h1 className="mb-3 font-medium text-[#3D4356]">Brand Name</h1>
            <div className="relative flex items-end bg-[#F4F5F7] rounded-md w-full h-14">
              <input
                id="manager-search"
                placeholder="Search Manager"
                onChange={(e) => setBrandName(e.target.value)}
                value={brandName}
                autoComplete="new-password"
                className="w-full h-10 pl-4 font-medium placeholder-transparent transition duration-500 ease-in-out bg-transparent border-b-2 border-transparent text-gray1 focus:ring-0 peer focus:outline-none focus:border-b-blue caret-blue"
              />
              <label
                htmlFor="manager-search"
                className="absolute left-4 top-[5px] text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray2 peer-placeholder-shown:top-4 peer-focus:top-[5px] peer-focus:text-blue peer-focus:text-sm"
              >
                Name
              </label>
            </div>
          </div>
          <div className="bg-white w-[70%] p-5 space-y-5">
            <h1 className="mb-3 font-medium text-[#3D4356]">
              Brand Description
            </h1>

            <Editor
              init={{
                height: 300,
              }}
              value={description}
              onChange={setDescription}
            />
          </div>

          <div className="bg-white w-[70%] p-5 grid grid-cols-2 gap-10">
            <div>
              <h1 className="mb-3 font-medium text-[#3D4356]">Brand Logo</h1>
              <div className="flex items-center space-x-5">
                {brandLogo ? (
                  <div className=" w-60">
                    <div className="relative border border-gray-300 border-dashed rounded-md">
                      <div className="absolute left-0 flex justify-center w-full bottom-10">
                        <ButtonGroup className="bg-white">
                          <Button
                            onClick={() => {
                              setBrandLogo("");
                            }}
                          >
                            <img src={deleteIcon} alt="" />
                          </Button>
                          <Button>
                            <label
                              htmlFor="business-logo"
                              className="w-full h-full cursor-pointer"
                            >
                              <img src={editIcon} alt="" />
                              <input
                                type="file"
                                id="business-logo"
                                multiple
                                accept=".png,.jpeg,.jpg"
                                onChange={(e) => {
                                  setBrandLogo(e.target.files[0]);
                                }}
                                className="hidden"
                              />
                            </label>
                          </Button>
                        </ButtonGroup>
                      </div>

                      <img
                        src={URL.createObjectURL(brandLogo)}
                        alt=""
                        className="object-cover w-full h-full rounded-md"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      setBrandLogo(e.dataTransfer.files[0]);
                    }}
                  >
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                      <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                    </div>
                    <label
                      htmlFor="business-logo"
                      className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                    >
                      <p className="mb-0">Upload File</p>
                      <input
                        type="file"
                        id="business-logo"
                        multiple
                        accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          setBrandLogo(e.target.files[0]);
                        }}
                        className="hidden"
                      />
                    </label>

                    <p className="text-sm text-gray2">
                      or drop files to upload
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className=" bg-white w-[70%] p-5 space-y-5">
            <h1 className="mb-3 font-medium text-[#3D4356]">
              Official Store
            </h1>
            <CustomSelect
                label={'Select a parent store'}
                name="official-store"
                id="official-store"
                labelKey={'name'}
                subCategory
                value={officialStore}
                options={brands}
                setValue={setOfficialStore}
            />
          </div>

          <div className=" bg-white w-[70%] p-5 space-y-5">
            {products.length>0&&<CustomMultiSelectSearch
                mode="multiple"
                value={selectedProducts}
                placeholder="Select products"
                fetchOptions={fetchSearchProduct}
                data={products}
                onChange={(newValue) => {
                  setSelectedProducts(newValue);
                }}
                style={{
                  width: "100%",
                }}
            />}
          </div>

          <div className="w-[70%] flex justify-end items-center space-x-3">
            <Button
              variant="contained"
              className="capitalize bg-white shadow-none"
              onClick={() => setmodalOpen(true)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`text-white capitalize shadow-none ${
                description === "" ||
                // brandLogo === "" ||
                // bannerImage === "" ||
                brandName === ""
                  ? "bg-orange/30 cursor-not-allowed"
                  : "bg-orange cursor-pointer"
              }`}
              onClick={handleSubmit}
              disabled={
                description === "" ||
                // brandLogo === "" ||
                // bannerImage === "" ||
                brandName === ""
              }
            >
              Save
            </Button>
          </div>

          <Dialog open={open} onClose={handleClose}>
            <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
              {loading ? (
                <>
                  <img src={brandIcon} alt="" className="w-9 h-9" />
                  <p className="font-medium capitalize text-gray2">
                    Saving new Brand
                  </p>
                  <img src={loader} alt="" className="animate-spin" />
                </>
              ) : (
                <>
                  <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                    <img src={success} alt="" className="" />
                  </span>

                  <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                    New Brand Added Successfully
                  </p>

                  <div className="space-x-3">
                    <Button
                      variant="contained"
                      className="capitalize bg-white border shadow-none"
                      onClick={() => {
                        handleClose();
                        clearInfo();
                      }}
                    >
                      Add Another Brand
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="capitalize shadow-none"
                      onClick={() => {
                        handleClose();
                        history.push("/brands");
                      }}
                    >
                      Done
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Dialog>

          <UnsavedChanges
            isOpen={modalOpen}
            close={() => setmodalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default AddNewBrandForm;
