import { useCallback, useState, useEffect } from "react";

//components
import FormInput from "../FormInput/FormInput";
import UnsavedChanges from "../UnsavedChanges/UnsavedChanges";

//icons
import CampaignInactive from "../../assets/campaign-inactive.svg";
import loader from "../../assets/loader.svg";
import success from "../../assets/success.svg";

//mui
import { Button, Checkbox, Dialog } from "@material-ui/core";
import { toast } from "react-toastify";

const AddNewSegmentForm = ({
  request = false,
  onUpdate,
  onInfinity,
  onForm,
  onEdit,
  prevValue = "",
  formId = 1,
}) => {
  //state management
  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);

  //form values
  const [id, setId] = useState(formId);
  const [name, setName] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [qualifying, setQualifying] = useState("");
  const [users, setUsers] = useState(null);
  const [packages, setPackages] = useState({ packages: [] });
  const [lastSegment, setLastSegment] = useState(false);
  const [previousMax, setPreviousMax] = useState(prevValue);

  //form management
  const [showExitModal, setShowExitModal] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setPackages({ packages: newValue.split(/[,\n]/) });
  };

  //form functions
  const checkFormForEmptyFields = useCallback(() => {
    if (
      name.length > 0 &&
      (min.length > 0 || previousMax.length > 0) &&
      (max.length > 0 || lastSegment) &&
      qualifying.length > 0 &&
      packages.packages.length > 0
    )
      setDisabled(false);
    else {
      setDisabled(true);
    }
  }, [
    name.length,
    min.length,
    max.length,
    qualifying.length,
    packages.packages.length,
    previousMax.length,
    lastSegment,
  ]);

  const formValues = {
    name,
    min: previousMax !== "" ? previousMax + ".5" : min,
    max: lastSegment ? "" : max,
    qualifying,
    users: users,
    packages,
    infinity: lastSegment,
  };

  const clearForm = () => {
    setId(id + 1);
    setName("");
    setMin("");
    setMax("");
    setQualifying("");
    setUsers(null);
    setPackages({ packages: [] });
  };

  const setEditInputs = () => {
    if (request) {
      setName(onEdit?.name);
      setMin(onEdit?.min);
      setMax(onEdit?.max);
      setQualifying(onEdit?.qualifying);
      setUsers(parseInt(onEdit?.users));
      setPackages(onEdit?.packages);
      setPreviousMax("");
      setId(onEdit?.id);

      if (onEdit?.infinity) {
        setLastSegment(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    if (parseFloat(qualifying) > parseFloat(min)) {
      toast.error("Qualifying treshold should be less than Minimum treshold!");
      return false;
    }

    if (parseFloat(min) >= parseFloat(max) && !lastSegment) {
      toast.error("Minimum treshold should be less than Maximum treshold!");
      return false;
    }
    
    formValues.id = id;
    setBusy(true);
    setOpen(true);
    setPreviousMax(formValues.max);
    onForm(formValues);
    if (lastSegment) {
      onInfinity(true);
    } else {
      onInfinity(false);
    }
    setTimeout(() => {
      setBusy(false);
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  useEffect(() => {
    checkFormForEmptyFields();
  }, [checkFormForEmptyFields, name, min, max, qualifying, packages]);

  useEffect(() => {
    setEditInputs();
  }, []);

  return (
    <>
      <div className="bg-white w-[70%] p-5 space-y-5">
        <div className="flex items-center justify-between mb-3">
          <p className="text-[#3D4356] text-[16px] mb-0">Segment Details</p>
          <div className="flex items-center">
            <Checkbox
              checked={lastSegment}
              onChange={() => {
                setLastSegment(!lastSegment);
                setMax("");
              }}
            />
            <p className="mb-0 font-medium text-[#3D4356]">Last Segment</p>
          </div>
        </div>

        <FormInput
          id="segment-name"
          name="segment-name"
          type="text"
          label="Segment Name"
          placeholder="Segment Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div className="flex space-x-4 items-center w-full mb-3">
          <FormInput
            id="min-threshold"
            type="number"
            name="min-threshold"
            placeholder="Min. Threshold"
            label="Min. Threshold"
            value={previousMax !== "" ? Number(previousMax) + 0.5 : min}
            onChange={(e) => {
              if (previousMax !== "") {
                setMin(previousMax);
              } else {
                setMin(e.target.value);
              }
            }}
          />
          <FormInput
            type={lastSegment ? "text" : "number"}
            id="max-threshold"
            name="max-threshold"
            placeholder="Max. Threshold"
            label="Max. Threshold"
            value={lastSegment ? Infinity : max}
            onChange={(e) => {
              if (lastSegment) {
                setMax(Infinity);
              } else setMax(e.target.value);
            }}
          />
        </div>

        <div className="flex space-x-4 items-center w-full mb-3">
          <FormInput
            id="qualifying-threshold"
            name="qualifying-threshold"
            type="number"
            placeholder="Qualifying Threshold"
            label="Qualifying Threshold"
            value={qualifying}
            onChange={(e) => setQualifying(e.target.value)}
          />
          <FormInput
            id="users"
            name="users"
            type="number"
            placeholder="Win limit for segment"
            label="Win limit for segment"
            value={users}
            onChange={(e) => setUsers(e.target.value)}
          />
        </div>
      </div>

      <div className="bg-white w-[70%] p-5 space-y-5">
        <p className="text-[#3D4356] text-[16px] mb-3">Segment Package</p>
        <FormInput
          id="segment-package"
          name="segment-package"
          type="textarea"
          label="Packages"
          placeholder="Package"
          value={packages.packages.join(",")}
          onChange={handleInputChange}
        />
      </div>

      <div className="w-[70%] flex justify-end items-center space-x-3 pb-20">
        <Button
          variant="contained"
          className="capitalize bg-white shadow-none"
          onClick={() => onUpdate(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={`text-white capitalize shadow-none ${
            disabled
              ? "bg-orange/30 cursor-not-allowed"
              : "bg-orange cursor-pointer"
          }`}
          onClick={() => {
            handleSubmit();
          }}
          disabled={disabled}
        >
          {!request ? "Save" : "Update"}
        </Button>
      </div>

      {open ? (
        <Dialog open={true}>
          <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
            {busy ? (
              <>
                <img src={CampaignInactive} alt="" className="w-9 h-9" />
                <p className="font-medium capitalize text-gray2">
                  {!request ? "Saving New Segment" : "Updating Segment"}
                </p>
                <img src={loader} alt="" className="animate-spin" />
              </>
            ) : (
              <>
                <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                  <img src={success} alt="" className="" />
                </span>
                <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                  {!request
                    ? "New Segment Added Successfully"
                    : "Segment Updated Successfully"}
                </p>
                <div className="space-x-3">
                  {lastSegment ? null : (
                    <Button
                      variant="contained"
                      className="capitalize bg-white border shadow-none"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Add New Segment
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    className="capitalize shadow-none"
                    onClick={() => {
                      onUpdate(false);
                      setId(id + 1);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </>
            )}
          </div>
        </Dialog>
      ) : null}

      <UnsavedChanges
        close={() => setShowExitModal(false)}
        isOpen={showExitModal}
      />
    </>
  );
};
export default AddNewSegmentForm;
