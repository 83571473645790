import excelIcon from '../../assets/excel.svg'

export default function ({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-transparent rounded-md border border-[#E9ECEF] px-[10px] py-[5px] text-[12px] text-[#9CA7B8] flex flex-row items-center ml-3"
    >
      Export
      <img src={excelIcon} alt="search" className="w-[24px] h-[24px] ml-2" />
    </button>
  )
}
