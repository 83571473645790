import React, { useState } from "react";

//mui
import { Button } from "@material-ui/core";

const TextInput = ({
  type,
  label,
  placeholder,
  onChange,
  value,
  id,
  name,
  error,
  pattern,
  onFocus,
  disabled = false,
  rows = 2,
  color,
  defaultValue,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className={`relative flex items-end bg-[#F4F5F7] rounded-md w-full ${
        type === "textarea" ? "" : "h-14"
      }`}
    >
      {type === "textarea" ? (
        <textarea
          rows={rows}
          className={`w-full pl-4 ${
            type === "textarea" && "pt-6 resize-none"
          } placeholder-transparent transition duration-500 ease-in-out bg-transparent border-b-2 text-gray1 caret-blue focus:ring-0 font-medium border-transparent ${
            error ? "border-b-red" : "border-b-transparent"
          } focus:border-b-blue peer focus:outline-none`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
        ></textarea>
      ) : (
        <input
          id={id}
          name={name}
          pattern={pattern}
          type={type !== "password" ? type : showPassword ? "text" : type}
          className={`w-full h-10 pl-4 placeholder-transparent transition duration-500 ease-in-out bg-transparent border-transparent border-b-2 text-gray1 ${
            color
              ? "focus:border-b-orange caret-orange"
              : "focus:border-b-blue caret-blue"
          }  font-medium focus:ring-0  peer focus:outline-none ${
            error ? "border-b-red" : "border-b-transparent"
          } `}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete="new-password"
        />
      )}

      <label
        htmlFor={id}
        className={`absolute left-4 top-[5px] ${
          error ? "text-red" : "text-gray2"
        } text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray2 peer-placeholder-shown:top-4 peer-focus:top-[5px] peer-focus:text-blue ${
          color && "peer-focus:text-orange"
        }
        peer-focus:text-sm`}
      >
        {label}
      </label>

      {type === "password" && (
        <Button
          className="absolute right-0 h-full hover:bg-transparent text-gray3"
          disableRipple
          classes={{
            label: "text-[12px] font-medium",
          }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {" "}
          {showPassword ? "Hide" : "Show"}
        </Button>
      )}
    </div>
  );
};

export default TextInput;
