import api from '../apiInstance'
import Sentry from '../sentry-config'

export const loginWithEmail = (form) =>
  api.post(`/api/auth/dashboard/token/login/`, form)

export const loginWithPhoneNumber = (form) =>
  api.post(`/api/auth/dashboard/phone_number/token/login/`, form)

export const verifyOtp = (form) =>
  api.post('/api/auth/dashboard/confirm-login/', form)

export const getUserDetails = async () => {
  const res = await api.get(`/api/users/me/`)
  const { first_name: fn, last_name: ln } = res.data.profile
  Sentry.setUser({
    email: res.data.email,
    id: res.data.id,
    username: [fn, ln].filter(Boolean).join(' '),
    segment: res.data.account_type,
    ip_address: '{{auto}}',
  })
  return res
}

export const getProfileDetails = () => api.get(`/api/profile/`)

export const logout = () => api.post('/api/auth/token/logout/')

export const sendResetPasswordEmail = (form) =>
  api.post('/api/users/reset-password-or-pin/', form)

export const sendResetPasswordOtp = (form) =>
  api.post('/api/users/verify-otp/', form)

export const resetPassword = (form) =>
  api.post('/api/users/reset-password-or-pin-confirm/', form)

export const setNewPassword = (form) =>
  api.post(`/api/users/set-password-or-pin/`, form)
