import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

//utils
// import generateHourlyChartConfig from '../../utils/generateHourlyChartConfig'

//icons
import loader from '../../assets/loader.svg'

//components
import Loader from '@components/Loader/Loader'
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import DateActions from '../../components/DateActions/DateActions'
// import NetRevenueChart from '../../components/AnalyticsGraphs/NetRevenueChart'
import RevenueGrowthChart from '../../components/AnalyticsGraphs/RevenueGrowthChart'
import AnalyticsSummaryCard from '../../components/AnalyticsSummaryCard/AnalyticsSummaryCard'
import StackedGraphs from '../../components/AnalyticsGraphs/StackedGraphs'
// import SimpleGraphCard from '../../components/AnalyticsGraphCards/SimpleGraphCard'
import SimpleAnalyticsCard from '../../components/SimpleAnalyticsCard/SimpleAnalyticsCard'
import { RootContext } from '../..'

//utils
import {
  fetchAllOrdersByDate,
  fetchAllOrdersByDateRange,
  exportData,
} from '../../utils'

//services
import {
  getTransactionsByDateRange,
  getTransactionsByPeriod,
} from '../../services/finance'
import { getTopSellingBrands } from '../../services/brands'
import { getTopRetailers } from '../../services/retailers'
import { getTopSellingProducts } from '../../services/products'

const Index = () => {
  const {
    authStore: { user },
  } = useContext(RootContext)
  const [busy, setBusy] = useState(true)
  const [activePeriod, setActivePeriod] = useState('today')
  const [activeCard, setActiveCard] = useState('Sales')
  const [sales, setSales] = useState()
  const [orders, setOrders] = useState([])
  const [dateRange, setDateRange] = useState({ start: moment(), end: moment() })
  const [selectedFilter, setSelectedFilter] = useState('period')
  const [salesChart, setSalesChart] = useState({
    labels: [
      '12:00am',
      '1:00am',
      '2:00am',
      '3:00am',
      '4:00am',
      '5:00am',
      '6:00am',
      '7:00am',
      '8:00am',
      '9:00am',
      '10:00am',
      '11:00am',
      '12:00pm',
      '1:00pm',
      '2:00pm',
      '3:00pm',
      '4:00pm',
      '5:00pm',
      '6:00pm',
      '7:00pm',
      '8:00pm',
      '9:00pm',
      '10:00pm',
      '11:59pm',
    ],
    datasets: [
      {
        label: 'Sales',
        data: [],
        borderColor: '#F3641F',
        backgroundColor: 'rgba(243, 100, 31, 0.15)',
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
    ],
  })
  const [completedGraph, setCompletedGraph] = useState({
    labels: [
      '12:00am',
      '1:00am',
      '2:00am',
      '3:00am',
      '4:00am',
      '5:00am',
      '6:00am',
      '7:00am',
      '8:00am',
      '9:00am',
      '10:00am',
      '11:00am',
      '12:00pm',
      '1:00pm',
      '2:00pm',
      '3:00pm',
      '4:00pm',
      '5:00pm',
      '6:00pm',
      '7:00pm',
      '8:00pm',
      '9:00pm',
      '10:00pm',
      '11:59pm',
    ],
    datasets: [
      {
        label: 'Orders',
        data: [],
        borderColor: '#38C985',
        backgroundColor: 'rgba(56, 201, 133, 0.1)',
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
      {
        label: 'Value',
        data: [],
        fill: true,
        backgroundColor: '#EEF2F5',
        borderColor: '#EEF2F5',
        tension: 0,
        pointStyle: 'line',
      },
    ],
  })
  const [pendingGraph, setPendingGraph] = useState({
    labels: [
      '12:00am',
      '1:00am',
      '2:00am',
      '3:00am',
      '4:00am',
      '5:00am',
      '6:00am',
      '7:00am',
      '8:00am',
      '9:00am',
      '10:00am',
      '11:00am',
      '12:00pm',
      '1:00pm',
      '2:00pm',
      '3:00pm',
      '4:00pm',
      '5:00pm',
      '6:00pm',
      '7:00pm',
      '8:00pm',
      '9:00pm',
      '10:00pm',
      '11:59pm',
    ],
    datasets: [
      {
        label: 'Orders',
        data: [],
        borderColor: '#477DFB',
        backgroundColor: 'rgba(71, 125, 251, 0.1)',
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
      {
        label: 'Value',
        data: [],
        fill: true,
        backgroundColor: '#EEF2F5',
        borderColor: '#EEF2F5',
        tension: 0,
        pointStyle: 'line',
      },
    ],
  })
  const [cancelledGraph, setCancelledGraph] = useState({
    labels: [
      '12:00am',
      '1:00am',
      '2:00am',
      '3:00am',
      '4:00am',
      '5:00am',
      '6:00am',
      '7:00am',
      '8:00am',
      '9:00am',
      '10:00am',
      '11:00am',
      '12:00pm',
      '1:00pm',
      '2:00pm',
      '3:00pm',
      '4:00pm',
      '5:00pm',
      '6:00pm',
      '7:00pm',
      '8:00pm',
      '9:00pm',
      '10:00pm',
      '11:59pm',
    ],
    datasets: [
      {
        label: 'Orders',
        data: [],
        borderColor: '#FF3D22',
        backgroundColor: 'rgba(255, 61, 34, 0.1)',
        borderWidth: 2,
        tension: 0,
        fill: true,
        pointRadius: 0,
      },
    ],
  })
  const [topProducts, setTopProducts] = useState([])
  const [topBrands, setTopBrands] = useState([])
  const [topRetailers, setTopRetailers] = useState([])
  const [salesTotal, setSalesTotal] = useState(0)
  const [ordersTotal, setOrdersTotal] = useState(0)
  const [completed, setCompleted] = useState({ count: 0, amount: 0 })
  const [pending, setPending] = useState({ count: 0, amount: 0 })
  const [cancelled, setCancelled] = useState({ count: 0, amount: 0 })

  const [controllers, setControllers] = useState({
    topRetailers: null,
    topProducts: null,
    topBrands: null,
    orders: null,
  })

  const sharedParams = {
    page_size: 10,
    ...(selectedFilter === 'range'
      ? {
          end_date: dateRange.end.format('YYYY-MM-DD'),
          start_date: dateRange.start.format('YYYY-MM-DD'),
        }
      : { payment_day: activePeriod }),
  }

  function createController(key) {
    const abc = new AbortController()
    setControllers((prev) => ({
      ...prev,
      [key]: abc,
    }))
    return abc
  }

  const fetchSalesByDateRange = async () => {
    try {
      const { data } = await getTransactionsByDateRange(
        user.retailerUuid,
        dateRange.end.format('YYYY-MM-DD'),
        dateRange.start.format('YYYY-MM-DD'),
      )
      const parsedData = parseHourlyData(data.results)
      const graphData = { ...salesChart }
      graphData.datasets[0].data = [...parsedData]
      setSalesChart(graphData)
      setSales(data.results)
      computeSalesTotal(data.results)
      setBusy(false)
    } catch (error) {
      toast.error('An error occurred. Unable to fetch data.')
      setBusy(false)
    }
  }

  const filterByPeriod = async () => {
    setBusy(true)
    try {
      const { data } = await getTransactionsByPeriod(
        user.retailerUuid,
        activePeriod,
      )
      const parsedData = parseHourlyData(data.results)
      const graphData = { ...salesChart }
      graphData.datasets[0].data = [...parsedData]
      setSalesChart(graphData)
      computeSalesTotal(data.results)
      setSales(data.results)
      setBusy(false)
    } catch (error) {
      toast.error('An error occurred. Unable to fetch requested data.')
      setBusy(false)
    }
  }

  const parseHourlyData = (data) => {
    const amountReducer = (previousValue, currentValue) =>
      previousValue + parseInt(currentValue.order.final_cost.amount)
    let graphData = []

    for (let i = 0; i < 24; i++) {
      const hourArray = data.filter(
        (transaction) =>
          new Date(transaction.order.created_at).getHours() === i,
      )
      if (hourArray.length > 0) {
        const total = hourArray.reduce(amountReducer, 0)
        graphData.push(total)
      } else {
        graphData.push(0)
      }
    }
    return graphData
  }

  const parseHourlyOrderData = (data) => {
    const amountReducer = (previousValue, currentValue) =>
      previousValue + parseInt(currentValue.final_cost.amount)
    let graphData = []

    for (let i = 0; i < 24; i++) {
      const hourArray = data.filter(
        (order) => new Date(order.created_at).getHours() === i,
      )
      if (hourArray.length > 0) {
        const total = hourArray.reduce(amountReducer, 0)
        graphData.push(total)
      } else {
        graphData.push(0)
      }
    }
    return graphData
  }

  const computeSalesTotal = (data) => {
    const amountReducer = (previousValue, currentValue) =>
      previousValue + parseInt(currentValue.order.final_cost.amount)
    const total = data.reduce(amountReducer, 0)
    setSalesTotal(total)
  }

  const computeOrdersTotal = (data) => {
    const amountReducer = (previousValue, currentValue) =>
      previousValue + parseInt(currentValue.final_cost.amount)
    const total = data.reduce(amountReducer, 0)
    setOrdersTotal(total)
  }

  const fetchOrders = async () => {
    setBusy(true)
    try {
      let data
      if (selectedFilter === 'period') {
        data = await fetchAllOrdersByDate(activePeriod)
      } else {
        data = await fetchAllOrdersByDateRange(
          dateRange.end.format('YYYY-MM-DD'),
          dateRange.start.format('YYYY-MM-DD'),
        )
      }
      setOrders(data)
      sortOrders(data)
      computeOrdersTotal(data)
      setBusy(false)
    } catch (error) {
      console.log(error)
      setBusy(false)
      toast.error('An error occurred. Unable to fetch order data.')
    }
  }

  const sortOrders = (data) => {
    const amountReducer = (previousValue, currentValue) =>
      previousValue + parseInt(currentValue.final_cost.amount)
    const completed = data.filter((item) => item.status === 'Completed')
    const cancelled = data.filter((item) => item.status === 'Cancelled')
    const pending = data.filter((item) => item.status === 'Pending')

    setCompleted({
      amount: completed.reduce(amountReducer, 0),
      count: completed.length,
    })
    setPending({
      amount: pending.reduce(amountReducer, 0),
      count: pending.length,
    })
    setCancelled({
      amount: cancelled.reduce(amountReducer, 0),
      count: cancelled.length,
    })

    const completedGraphData = parseHourlyOrderData(completed)
    let completedData = { ...completedGraph }
    completedData.datasets[0].data = [...completedGraphData]
    setCompletedGraph(completedData)

    const pendingGraphData = parseHourlyOrderData(pending)
    let pendingData = { ...pendingGraph }
    pendingData.datasets[0].data = [...pendingGraphData]
    setPendingGraph(pendingData)

    const cancelledGraphData = parseHourlyOrderData(cancelled)
    let cancelledData = { ...cancelledGraph }
    cancelledData.datasets[0].data = [...cancelledGraphData]
    setCancelledGraph(cancelledData)
  }

  const fetchTopSellingProducts = async () => {
    controllers.topProducts?.abort?.()
    const abc = createController('topProducts')
    try {
      const { data } = await getTopSellingProducts(sharedParams, abc.signal)
      setTopProducts(data.results)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch top selling products.')
    }
  }

  const fetchTopBrands = async () => {
    // TODO: Top brands endpoint is missing date filters
    controllers.topBrands?.abort?.()
    const abc = createController('topBrands')
    try {
      const { data } = await getTopSellingBrands(sharedParams, abc.signal)
      setTopBrands(data.results)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch top brands.')
    }
  }

  const fetchTopRetailers = async () => {
    controllers.topRetailers?.abort?.()
    const abc = createController('topRetailers')
    try {
      const { data } = await getTopRetailers(sharedParams, abc.signal)
      setTopRetailers(data.results)
    } catch (error) {
      setBusy(false)
      toast.error('An error occurred. Unable to fetch top retailers.')
    }
  }

  async function handleDataExport(title, handler, transformer) {
    let results = []
    let page = 1

    try {
      do {
        const res = await handler({
          ...sharedParams,
          page_size: 200,
          page,
        })
        results = results.concat(res.data.results)
        page = res.data.next ? page + 1 : 0
      } while (page)
    } catch {}

    exportData(
      results.map(transformer),
      `${title} | ${
        sharedParams.payment_day ||
        `${sharedParams.start_date} to ${sharedParams.end_date}`
      }`,
    )
  }

  useEffect(() => {
    filterByPeriod()
    fetchOrders()
    fetchTopBrands()
    fetchTopRetailers()
    fetchTopSellingProducts()
  }, [activePeriod])

  useEffect(() => {
    switch (activeCard) {
      case 'Sales':
        fetchSalesByDateRange()
        break
      case 'Total Orders':
        fetchOrders()
        break
      default:
        break
    }
    fetchTopBrands()
    fetchTopRetailers()
    fetchTopSellingProducts()
  }, [dateRange])

  useEffect(() => {
    return () => {
      controllers.topRetailers?.abort?.()
      controllers.topProducts?.abort?.()
      controllers.topBrands?.abort?.()
    }
  }, [])

  return (
    <Layout>
      {busy && !sales ? (
        <Loader />
      ) : (
        <div className="px-[30px] pt-5 overflow-x-hidden pb-10">
          <PageTitle title="Analytics" />
          <DateActions
            activePeriod={
              selectedFilter === 'period' ? activePeriod : undefined
            }
            changePeriod={setActivePeriod}
            dateRange={dateRange}
            onDateRangeSelect={(range) => {
              setSelectedFilter('range')
              setDateRange(range)
            }}
            className="flex justify-end"
          />
          <div className="flex items-center mt-2 space-x-5">
            <AnalyticsSummaryCard
              label={'Sales'}
              active={activeCard === 'Sales'}
              data={[
                {
                  amount: salesTotal,
                  changeType: 'rise',
                  changeValue: 23,
                },
              ]}
              onPress={setActiveCard}
            />

            <AnalyticsSummaryCard
              label={'Total Orders'}
              active={activeCard === 'Total Orders'}
              data={[
                {
                  amount: ordersTotal,
                  changeType: 'rise',
                  changeValue: 20,
                },
              ]}
              onPress={setActiveCard}
            />

            {/* <AnalyticsSummaryCard
              label={"Customers"}
              active={activeCard === "Customers"}
              data={[
                {
                  amount: "3462000",
                  changeType: "rise",
                  changeValue: 20,
                  title: "New Customers",
                },
              ]}
              onPress={setActiveCard}
            /> */}
          </div>
          <div className="px-4 py-4 bg-white rounded-md h-[450px] relative">
            {activeCard === 'Sales' && <RevenueGrowthChart data={salesChart} />}
            {/* {activeCard === "Customers" && <NetRevenueChart />} */}
            {activeCard === 'Total Orders' && (
              <StackedGraphs
                group="Total Orders"
                data={[
                  {
                    label: 'Completed',
                    summaryValue: completed.count,
                    changeType: 'rise',
                    changeValue: '20',
                    amount: completed.amount,
                    chartData: completedGraph,
                  },
                  {
                    label: 'Pending',
                    summaryValue: pending.count,
                    changeType: 'fall',
                    changeValue: '20',
                    amount: pending.amount,
                    chartData: pendingGraph,
                  },
                  {
                    label: 'Cancelled',
                    summaryValue: cancelled.count,
                    changeType: 'fall',
                    changeValue: '20',
                    amount: cancelled.amount,
                    chartData: cancelledGraph,
                  },
                ]}
              />
            )}
            {busy && (
              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-5">
                <img src={loader} alt="" className="w-6 animate-spin" />
              </div>
            )}
          </div>
          {/* <SalesTarget /> */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            {/* <div className="col-span-2">
              <SimpleGraphCard
                label="Refund"
                data={{
                  title: "Refund In Value",
                  summaryValue: "1456",
                  amount: 3559.13,
                  changeType: "rise",
                  changeValue: 30,
                  chartData: generateHourlyChartConfig(
                    "rgba(243, 100, 31, 0.1)",
                    "#F3641F",
                    [
                      20, 10, 34, 23, 54, 23, 56, 23, 91, 10, 15, 34, 56, 67, 32,
                      43, 56, 23, 38, 26, 78, 34, 63, 48, 23,
                    ]
                  ),
                }}
              />
            </div>
            <SimpleAnalyticsCard
              label="Payment Channels"
              itemType="progress"
              items={[
                {
                  label: "Debit Card",
                  amount: 2559.13,
                  percentage: 25,
                },
                {
                  label: "Bank Transfer",
                  amount: 583,
                  percentage: 15,
                },
                {
                  label: "Cash on Delivery",
                  amount: 5891.14,
                  percentage: 29.4,
                },
                {
                  label: "Digital/Money Wallet",
                  amount: 2000,
                  percentage: 8,
                },
              ]}
            />
            <div className="col-span-2">
              <FilterGraphCard
                label="Items Sold"
                data={{
                  title: "All Items Sold",
                  summaryValue: "1456",
                  amount: 3559.13,
                  changeType: "rise",
                  changeValue: 30,
                  chartData: generateHourlyChartConfig(
                    "rgba(243, 100, 31, 0.1)",
                    "#F3641F",
                    [
                      20, 10, 34, 23, 54, 23, 56, 23, 91, 10, 15, 34, 56, 67, 32,
                      43, 56, 23, 38, 26, 78, 34, 63, 48, 23,
                    ]
                  ),
                }}
              />
            </div>
            <SimpleAnalyticsCard
              label="Source of Sales"
              itemType="progress"
              items={[
                {
                  label: "Online or marketplace",
                  amount: 2559.13,
                  percentage: 25,
                },
                {
                  label: "Store Front",
                  amount: 583,
                  percentage: 15,
                },
                {
                  label: "Sale Team",
                  amount: 5891.14,
                  percentage: 29.4,
                },
              ]}
            /> */}
            <SimpleAnalyticsCard
              label="Top Retailers"
              itemType="retailers"
              items={topRetailers}
              onExport={() => {
                handleDataExport('Top Retailers', getTopRetailers, (entry) => ({
                  'Retailer Name': entry.name,
                  'Orders completed':
                    entry.order_completed.toLocaleString('en'),
                  'Revenue generated': `₦ ${Number(
                    entry.revenue,
                  ).toLocaleString('en')}`,
                }))
              }}
            />
            <SimpleAnalyticsCard
              label="Top Selling Brands"
              itemType="brands"
              items={topBrands}
              onExport={() => {
                handleDataExport(
                  'Top Selling Brands',
                  getTopSellingBrands,
                  (entry) => ({
                    'Brand name': entry.name,
                    'Quantity sold': entry.total_sold.toLocaleString('en'),
                    'Revenue generated': `₦ ${Number(
                      entry.revenue,
                    ).toLocaleString('en')}`,
                  }),
                )
              }}
            />
            <SimpleAnalyticsCard
              label="Top Selling Products"
              itemType="products"
              items={topProducts}
              onExport={() => {
                handleDataExport(
                  'Top Selling Products',
                  getTopSellingProducts,
                  (entry) => ({
                    'Product name': entry.name,
                    'Quantity sold': entry.total_sold.toLocaleString('en'),
                    'Revenue generated': `₦ ${Number(
                      entry.revenue,
                    ).toLocaleString('en')}`,
                  }),
                )
              }}
            />
            {/* <div className="col-span-2">
              <SimpleGraphCard
                label="Tax"
                data={{
                  title: "Tax In Value",
                  summaryValue: "1456",
                  amount: 3559.13,
                  changeType: "rise",
                  changeValue: 30,
                  chartData: generateHourlyChartConfig(
                    "rgba(243, 100, 31, 0.1)",
                    "#F3641F",
                    [
                      20, 10, 34, 23, 54, 23, 56, 23, 91, 10, 15, 34, 56, 67, 32,
                      43, 56, 23, 38, 26, 78, 34, 63, 48, 23,
                    ]
                  ),
                }}
              />
            </div>
            <SimpleAnalyticsCard
              label="Orders by State"
              itemType="orders"
              items={[
                {
                  name: "Adamawa",
                  total: 56,
                  amount: 1559,
                },
                {
                  name: "Bauchi",
                  total: 56,
                  amount: 1559,
                },
              ]}
            /> */}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Index
