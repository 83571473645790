import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Popover, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import {Calendar, utils} from "react-modern-calendar-datepicker";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import FormInput from "../../components/FormInput/FormInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import SaveModal from "../../components/SaveModal/SaveModal";
import Loader from "../../components/Loader/Loader";

//assets
import couponIcon from "../../assets/coupons-inactive.svg";

//services
import { getCoupon, updateCoupon } from "../../services/coupons";

const EditCoupon = () => {
  const history = useHistory();
  const { slug } = useParams();
  const [coupon, setCoupon] = useState();
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [limits, setLimits] = useState("");
  const [usage, setUsage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const minimumYear = new Date().getFullYear();
  const open = Boolean(anchorEl);
  const id = open ? "calendar-popover" : undefined;

  const formatMonth = (month) => {
    if(parseInt(month) < 10)
      return `0${month}`;
    return month;
  }

  const fetchCoupon = async() => {
    setBusy(true);
    try {
      const { data } = await getCoupon(slug);
      setCoupon(data);
      setInputValues(data);
      setBusy(false);
    } catch (error) {
      console.log(error);
      toast.error('An error occurred. Unable to fetch coupon');
      history.goBack();
    }
  }

  const setInputValues = (data) => {
    setName(data.code);
    setType(data.coupon_type);
    setCouponValue(data.value);
    setQuantity(data.quantity);
    setLimits(data.limit);
    setUsage(data.usage);
    let newDate = new Date(data.expiry_date);
    newDate = `${newDate.getFullYear()}-${newDate.getMonth()+1}-${newDate.getDate()}`;
    setDate(newDate)
  }

  const handlePopoverClose = () => {
    setAnchorEl(false);
  }

  const handleAddPress = () => {
    history.push('/coupons/add-coupon')
  }

  const clearForm = () => {
    setName("");
    setType("");
    setQuantity("");
    setLimits("");
    setCouponValue("");
    setDate("");
    setSelectedDay("");
    setUsage("");
  }

  const generateForm = () => {
    return {
      "code": name,
      "value": couponValue,
      "description": "desc",
      "coupon_type": type,
      "usage": usage,
      "expiry_date": new Date(date),
      "limit": limits,
      "quantity": quantity,
    }
  }

  const handleSubmit = async() => {
    setBusy(true);
    setShowSaveModal(true);
    const form = generateForm();
    try {
      await updateCoupon(coupon.id, form);
      setBusy(false);
    } catch (error) {
      setShowSaveModal(false);
      setBusy(false)
      toast.error("An error occurred. Unable to add new coupon code.");
    }
  }

  useEffect(() => {
    fetchCoupon();
  }, [])

  useEffect(() => {
    if(selectedDay)
      setDate(`${selectedDay.year}-${formatMonth(selectedDay.month)}-${selectedDay.day}`)
  }, [selectedDay])

  return(
    <Layout>
      {busy && !coupon ? (
        <Loader />
      ) : (
        <div className="page-padding">
          <div className="w-[730px] mx-auto">
            <PageTitle 
              title="Edit Coupon Code"
              onBackPress={() => history.goBack()}
            />
          </div>
          <div
            className="w-[730px] flex flex-col mx-auto"
          >
            <div
              className="w-full bg-white rounded-md p-[30px] flex flex-col shadow-md"
            >
              <p className="text-[#3D4356] text-[16px] mb-3">Details</p>
              <FormInput 
                id="coupon-name"
                name="coupon-name"
                placeholder="Coupon Name"
                label="Coupon Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="h-6"/>
              <FormInput 
                id="expiry-date"
                name="expiry-date"
                placeholder="Expiry Date"
                label="Expiry Date"
                value={date}
                onFocus={(e) => setAnchorEl(e.currentTarget)}
              />
              <Popover
                id={id}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Calendar 
                  value={selectedDay}
                  onChange={setSelectedDay}
                  shouldHighlightWeekends={false}
                  colorPrimary="#F3641F"
                  colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                  minimumDate={utils().getToday()}
                  selectorStartingYear={minimumYear}
                />
              </Popover>
            </div>
            <div
              className="w-full bg-white rounded-md p-[30px] flex flex-col mt-4 shadow-md"
            >
              <p className="text-[#3D4356] text-[16px] mb-3">Value</p>
              <form
                autoComplete="off"
                className="grid items-center grid-cols-2 space-x-4"
              >
                <CustomSelect 
                  label="Coupon Type"
                  name="counpon-type"
                  id="coupon-type"
                  value={type}
                  options={['Fixed amount', 'Percent amount']}
                  setValue={setType}
                />
                <FormInput 
                  id="coupon-value"
                  name="coupon-value"
                  placeholder="Coupon Value"
                  label="Coupon Value"
                  value={couponValue}
                  onChange={(e) => setCouponValue(e.target.value)}
                />
              </form>
            </div>
            <div
              className="w-full bg-white rounded-md p-[30px] flex flex-col mt-4 shadow-md"
            >
              <p className="text-[#3D4356] text-[16px] mb-3">Quantity / Usage</p>
              <form
                className="grid grid-cols-3 gap-4"
                autoComplete="off"
              >
                <FormInput 
                  id="quantity-value"
                  name="quantity-value"
                  placeholder="Quantity"
                  label="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <CustomSelect 
                  label="Limits"
                  name="limits"
                  id="limits"
                  value={limits}
                  options={['Daily', 'Weekly', 'Monthly']}
                  setValue={setLimits}
                />
                <FormInput 
                  id="usage"
                  name="usage"
                  placeholder="Usage"
                  label="Usage"
                  value={usage}
                  onChange={(e) => setUsage(e.target.value)}
                />
              </form>
            </div>
            <div
              className="flex flex-row items-center justify-end space-x-4 mt-[30px]"
            >
              <Button 
                variant="outlined"
                className='capitalize bg-white text-[16px] text-[#4C536A] font-semibold'
                onClick={() => history.goBack()}
              >Cancel</Button>
              <Button
                variant="contained"
                disableElevation
                className={`capitalize ${busy ? "bg-orange/20" : "bg-orange"} 
                text-white font-semibold text-[16px]`}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      <SaveModal 
        isOpen={showSaveModal}
        close={() => setShowSaveModal(false)}
        icon={couponIcon}
        progressLabel="Updating Coupon Code"
        successLabel="Coupon Code Updated Succesfully"
        btnLabel="Add A Coupon"
        handleAddPress={handleAddPress}
        busy={busy}
        update
      />
    </Layout>
  );
}

export default EditCoupon;