import React from "react";

 import OrderRow from "../OrderRow/OrderRow";

 const OrdersTableAlt = ({
  products,
  setQuantity,
  onPriceChange,
  onIncrease,
  onDecrease,
  hook,
  removeItem
}) => {
  return (
    <div className="w-[830px] mt-[30px]">
      <table width="100%">
        <tr className="h-[40px]">
          <th className="font-bold text-[#4C536A] text-[12px] text-left">ITEM DETAILS</th>
          <th className="font-bold text-[#4C536A] text-[12px] text-left">UNIT PRICE</th>
          <th className="font-bold text-[#4C536A] text-[12px] text-left">QUANTITY</th>
          <th align="right" className="font-bold text-[#4C536A] text-[12px]">SUB TOTAL</th>
          <th></th>
        </tr>
        {products && products.map((item, index) => (
          <>
            <OrderRow 
              product={item}
              value={products}
              hook={hook} 
              key={index} 
              onPriceChange={onPriceChange}
              setQuantity={setQuantity} 
              onIncrease={onIncrease} 
              onDecrease={onDecrease}
              removeItem={removeItem}
              maxQuantity={item.stock_count}
            />
            <div className="h-1"></div>
          </>
        ))}
      </table>
    </div>
  )
}

 export default OrdersTableAlt; 
