import { useState, createRef, useEffect } from "react";
import { IoChevronDownOutline, IoClose } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";

const CustomMultiSelect = ({removeItem, addItem, options = [], 
  selections = [], onNewClick, newBtnLabel, optionKey = 'name', imageKey, placeholder = "", onItemPress = () => {}}) => {
  const [showPopup, setShowPopup] = useState(null);
  const [items, setItems] = useState([...options])
  const [search, setSearch] = useState("");
  const inputRef = createRef();
  let typingTimer;

  const handleKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const handleKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleSearch, 500);
  }

  const handleSearch = () => {
    const quarry = options.filter(item => item[optionKey].toLowerCase().includes(search.toLowerCase()));
    setItems(quarry);
  }
  
  useEffect(() => {
    setItems(options)
  }, [options])

  useEffect(() => {
    if(search.length === 0)
      setItems([...options])
  }, [search])

  const closePopup = (e) => {
    if(e.target.matches("#multi-select-options, #multi-select-options *"))
      return;
    setShowPopup(false)
  }

  return (
    <div className="relative h-auto group">
      <div 
        id="multi-select-items"
        className="bg-[#F4F5F7] rounded-md w-full min-h-[60px] max-h-[200px] relative flex flex-wrap items-center px-4 pt-4 pb-2 focus:ring-0 focus:ring-transparent focus:outline-none"
        onFocus={() => {
          setShowPopup(true);
          inputRef.current.focus();
        }}
        tabIndex={1}
      >
        {selections.map((item, index) => (
          <div
            key={index}
            onDoubleClick={() => onItemPress(item)}
            className="bg-[#E3E7ED] rounded px-3 py-2 text-[14px] flex mr-2 text-[#040A1D] font-medium mb-2"
          >
            {item[optionKey] && item[optionKey]}
            <button
              className="p-[1px] ml-2 bg-[#9CA7B8] rounded-2xl"
              onClick={() => {
                removeItem(item)
                setShowPopup(false);
                inputRef.current.blur();
              }}
            >
              <IoClose color={'white'} size={20}/>
            </button>
          </div>
        ))}
        <input 
          ref={inputRef}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          placeholder={placeholder}
          className="bg-transparent font-medium text-[14px] w-[150px] focus:ring-0 focus:ring-transparent focus:outline-none"
        />
        <IoChevronDownOutline className="absolute top-6 right-5" />
      </div>
      {showPopup && (
        <div 
          id="multi-select-options"
          className={`absolute z-50 hidden w-full p-3 mt-2 
          bg-white border rounded-md shadow-lg group-focus-within:block 
          ${items.length > 0 && 'h-[380px]'}`}
          onBlur={closePopup}
        >
          <div className="h-[90%] overflow-y-scroll pb-3">
            {items.map((option, idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    addItem(option);
                    if(search.length > 0)
                      setSearch('');
                      setItems([...options])
                  }}
                  className="flex flex-col justify-start w-full p-2 capitalize rounded-md cursor-pointer hover:bg-orange/20 hover:text-orange"
                >
                  <div className="flex items-center">
                    { imageKey && option[imageKey]
                      ? <img
                          src={option[imageKey]}
                          alt={option[optionKey]}
                          className="w-[30px] h-[30px] object-contain mr-3"
                        />
                      : null
                    }
                    <span className="p-0 m-0 text-[16px] font-medium">{option[optionKey]}</span>
                  </div>
                  {option.parent && (
                    <p className="p-0 m-0 text-[13px]">{option.parent_name}</p>
                  )}
                </button>
              )
            })}
          </div>
          {newBtnLabel && (
            <button
              onClick={onNewClick}
              className="border border-[#E3E7ED] rounded-md w-full flex justify-center items-center text-[#4C536A] text-[16px] font-bold py-1"
            >
              <AiOutlinePlus className="mr-8" size={18}/> {newBtnLabel}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default CustomMultiSelect;
