import React, { useEffect, useState } from "react";

function TimeCheckbox({ day, onChange, workingHours }) {
  //variables
  const [selected, setSelected] = useState(false);
  const [time, setTime] = useState({
    opening_time: "",
    closing_time: "",
    order_prep_time: "",
  });

  //cleaning up entry data
  useEffect(() => {
    if (workingHours.length > 0) {
      setSelected(
        workingHours.find((time) => time.day === day)?.opening_time
          ? true
          : false
      );

      const opening_time = workingHours.find(
        (time) => time.day === day
      )?.opening_time;
      const closing_time = workingHours.find(
        (time) => time.day === day
      )?.closing_time;
      const order_prep_time = workingHours.find(
        (time) => time.day === day
      )?.order_prep_time;

      setTime({
        opening_time: opening_time,
        closing_time: closing_time,
        order_prep_time: order_prep_time,
      });
      onChange({
        opening_time: opening_time,
        closing_time: closing_time,
        order_prep_time: order_prep_time,
      });
    }
  }, [workingHours]);

  //form management
  const onTimeChange = (e) => {
    if (selected) {
      setTime({ ...time, [e.target.name]: e.target.value });
      onChange({ ...time, [e.target.name]: e.target.value });
    }
  };

  const checkboxChange = () => {
    if (selected) {
      setTime({
        ...time,
        opening_time: null,
        closing_time: null,
      });
      onChange({
        ...time,
        opening_time: null,
        closing_time: null,
      });
    }
    setSelected(!selected);
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex gap-5 items-center">
        <input
          type="checkbox"
          className="accent-[#38C985] rounded-sm"
          checked={selected}
          value={selected}
          onChange={checkboxChange}
        />
        <span className="text-[#28283E] font-semibold text-md leading-none">
          {day}
        </span>
      </div>
      <div className="flex items-center gap-5">
        <input
          type="time"
          name="opening_time"
          onChange={onTimeChange}
          value={time.opening_time ?? ""}
          className="rounded-md bg-[#F4F4F6] border-0 py-3"
        />
        <div className="font-medium text-[#28283E] text-lg h-full">to</div>
        <input
          type="time"
          name="closing_time"
          onChange={onTimeChange}
          value={time.closing_time ?? ""}
          className="rounded-md bg-[#F4F4F6] border-0 py-3"
        />
      </div>
    </div>
  );
}

export default TimeCheckbox;
