import { makeAutoObservable, action, observable, runInAction } from 'mobx'
import Cookies from 'js-cookie'

import {
  loginWithEmail,
  loginWithPhoneNumber,
  getUserDetails,
  logout,
  getProfileDetails,
} from '../services/auth'

import { getRetailerProfile } from '../services/retailers'
import { toast } from 'react-toastify'

export default class Auth {
  rootStore
  busy = true
  user = null
  credentials = null

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      signIn: action,
      signOut: action,
      isSignedIn: observable,
      busy: observable,
      user: observable,
      autoSignIn: action,
      toggleRole: action,
      updateProfilePicture: action,
      updateDeliveryProperty: action,
    })

    this.rootStore = rootStore
  }

  async signInWithEmail(form) {
    runInAction(() => {
      this.busy = true
    })
    this.cacheUserCredentials(form)
    try {
      await loginWithEmail(form)
    } catch (error) {
      runInAction(() => {
        this.busy = false
      })
      throw error
    }
  }

  async signInWithPhoneNumber(form) {
    runInAction(() => {
      this.busy = true
    })
    this.cacheUserCredentials(form)
    try {
      await loginWithPhoneNumber(form)
    } catch (error) {
      runInAction(() => {
        this.busy = false
      })
      throw error
    }
  }

  async signOut() {
    runInAction(() => {
      this.busy = true
    })
    const token = this.getToken()
    if (token) {
      try {
        await logout(token)
        this.deleteToken()
        runInAction(() => {
          this.user = null
          this.credentials = null
          this.busy = false
        })
      } catch (error) {
        runInAction(() => {
          this.busy = false
        })
        throw error
      }
    }
  }

  async autoSignIn() {
    runInAction(() => {
      this.busy = true
    })
    try {
      await this.fetchUserData()
    } catch (error) {
      runInAction(() => {
        this.busy = false
      })

      this.deleteToken()
      this.user = null
      this.credentials = null

      throw error
    }
  }

  async resendOtp() {
    try {
      if (this.credentials.email) {
        await loginWithEmail(this.credentials)
      } else {
        await loginWithPhoneNumber(this.credentials)
      }
    } catch (error) {
      throw error
    }
  }

  async fetchUserData() {
    runInAction(() => {
      this.busy = true
    })
    const token = this.getToken()
    if (token) {
      try {
        await getUserDetails(token).then(async (res) => {
          this.user = res.data
          const { profile } = res.data
          // const { data } = await getProfileDetails(token);
          this.user.gender = profile.gender
          this.user.address = profile.address
          this.user.picture = profile.picture
          this.user.first_name = profile.first_name
          this.user.last_name = profile.last_name
          this.user.date_of_birth = profile.date_of_birth
          if (this.user.account_type.includes('Retailer')) {
            await this.fetchRetailerData(res.data.uuid)
          }
        })
        runInAction(() => {
          this.busy = false
        })
      } catch (error) {
        runInAction(() => {
          this.busy = false
        })
        throw error
      }
    } else {
      runInAction(() => {
        this.busy = false
      })
    }
  }

  async fetchRetailerData() {
    const { data } = await getRetailerProfile()
    const retailer = data.results[0]
    if (!retailer) {
      await this.signOut()
      toast.error('Unable to find retailer information for this account.')
      return
    }
    if (retailer.status !== 'Approved') {
      await this.signOut()
      toast.error('Sorry, your retailer account has not been approved.')
      return
    }
    this.user.name = retailer.name
    this.user.logo = retailer.logo
    this.user.storeImage = retailer.store_image
    this.user.bio = retailer.bio
    this.user.businessEmail = retailer.business_email
    this.user.businessPhone = retailer.business_phone
    this.user.website = retailer.website
    this.user.address = retailer.address
    this.user.retailerUuid = retailer.uuid
    this.user.retailerId = retailer.id
    this.user.allow_delivery = retailer.allow_delivery
    this.user.allow_pickup = retailer.allow_pickup
    this.user.delivery_price = +retailer.delivery_price
    this.user.store_availability = retailer.store_availability
    this.user.cin7_available = retailer.cin7_integration_available
    this.user.cin7_accountId = retailer.cin7_application_account_id
    this.user.cin7_apiKey = retailer.cin7_application_key
  }

  updateProfilePicture(picture) {
    this.user.profile.picture = picture
  }

  updateStoreAvailability(value) {
    this.user.store_availability = value
  }

  updateDeliveryProperty(name, value) {
    this.user[name] = value
  }
  updateDeliveryPrice(value) {
    this.user.delivery_price = value
  }

  cacheUserCredentials(form) {
    this.credentials = form
  }

  clearUserCredentials() {
    this.credentials = null
  }

  cacheToken(token) {
    Cookies.set('auth_token', token, { expires: 365 })
  }

  getToken() {
    return Cookies.get('auth_token')
  }

  deleteToken() {
    Cookies.remove('auth_token')
  }

  updateCin7Data(id, key) {
    this.user.cin7_accountId = id
    this.user.cin7_apiKey = key
  }
}
