import React from "react";

import { TableCell, TableHead, TableRow, Checkbox } from "@material-ui/core";

const headCells = [
  { id: "retailer", label: "retailer" },
  { id: "email", label: "email" },
  { id: "phoneNumber", label: "phone number" },
  { id: "address", label: "address" },
  { id: "actions", numeric: true, label: "actions" },
];

const CustomTableHead = ({ onSelectAllClick, numSelected, rowCount }) => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            color="primary"
            className={`${
              numSelected > 0 && numSelected < rowCount && "text-orange"
            }`}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="uppercase text-[12px]"
            align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
