import React from "react";
import { Modal } from "@material-ui/core";
import { useHistory } from "react-router";

//assets
import loader from "../../assets/loader.svg";
import success from "../../assets/success.svg";

const SaveModal = ({isOpen, close, icon, progressLabel, successLabel, 
  btnLabel, handleAddPress, busy, update = false}) => {
  const history = useHistory();

  const onFinish = () => {
    close();
    history.goBack();
  }

  return (
    <Modal
      className="flex items-center justify-center overflow-y-scroll"  
      open={isOpen}
      onClose={close}
      disableBackdropClick
    >
      {busy ? (
        <div className="bg-white rounded-md w-[404px] h-[170px] flex flex-col justify-center items-center">
          <img src={icon} alt="" className="w-[42px] h-[42px]" />
          <p className="text-[#4C536A] text-[16px]">{progressLabel}</p>
          <img src={loader} alt="" className="w-5 mt-2 animate-spin" />
        </div>
      ) : (
        <div className="bg-white rounded-md w-[404px] h-[251px] flex flex-col justify-center items-center">
          <div className="w-[fit-content] bg-green/30 rounded-full p-4">
            <img src={success} alt="" className="w-[30px] h-[30px]" />
          </div>
          <p className="text-[#4C536A] text-[16px] font-medium mt-2">{successLabel}</p>
          {!update && <p className="text-[#4C536A] text-[16px] font-medium">Added Successfully</p>}
          <div className="flex flex-row items-center mt-4">
            {btnLabel && (
              <button 
                onClick={handleAddPress}
                className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mr-4">
                {btnLabel}
              </button>
            )}
            <button 
              className="bg-[#F3641F] rounded-md px-4 py-2 text-white font-medium"
              onClick={onFinish}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default SaveModal;
