import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IoAddOutline, IoArrowBackCircleOutline } from 'react-icons/io5'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

//mui
import {
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  Switch,
} from '@material-ui/core'
import { CheckOutlined, CloseOutlined } from '@material-ui/icons'

//icons
import del from '../../assets/delete.svg'
// import edit from "../../assets/edit.svg";
import view from '../../assets/view.svg'
import success from '../../assets/success.svg'
import error from '../../assets/alt-close.svg'

//components
import Loader from '../../components/Loader/Loader'
import Layout from '../../components/Layout/Layout'

//utils
import {
  campaignStatus,
  deleteSegment,
  getSingleCampaign,
} from '../../services/campaigns'
import { formatDate } from '../../utils'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'

const SingleCampaign = () => {
  const history = useHistory()
  const { id } = useParams()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [busy, setBusy] = useState(true)
  const [campaign, setCampaign] = useState(null)
  const [infinity, setInfinity] = useState(true)

  //fetch data
  const fetchCampaign = async () => {
    setBusy(true)
    try {
      await getSingleCampaign(id).then(async (res) => {
        setCampaign(res.data)
        setBusy(false)
      })
    } catch (error) {
      toast.error('Unable to fetch requested campaign. Please try again later.')
      history.goBack()
    }
  }

  // activate/deactivate function
  const handleCampaignStatus = async () => {
    try {
      setLoading(true)
      if (campaign.is_active) {
        await campaignStatus(id)
        toast.success('Campaign has been disabled successfully!')
      } else {
        await campaignStatus(id)
        toast.success('Campaign has been enabled successfully!')
      }
      await fetchCampaign()
      setLoading(false)
      setOpen(false)
    } catch (error) {
      console.log(error)
      toast.error('Error updating campaign status!')
      setLoading(false)
      setOpen(false)
    }
  }

  //delete segment
  const [deleteStatus, setDeleteStatus] = useState(false)

  const handleDelete = async (id) => {
    try {
      await deleteSegment(id).then(async (_) => {
        toast.success('Segment deleted successfully')
        await fetchCampaign()
      })
    } catch (error) {
      toast.error('Error deleteing segment!')
    }
  }

  useEffect(() => {
    fetchCampaign()
  }, [])

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <>
          <div className="w-[70%] flex flex-col items-center py-10 space-y-5 mb-[20px] mx-auto">
            <div className="w-full flex justify-between">
              <div className="flex items-center space-x-3">
                <IconButton
                  onClick={() => history.goBack()}
                  disableRipple
                  className="p-0 hover:bg-transparent"
                >
                  <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
                </IconButton>
                <h1 className="font-bold text-[30px] text-gray1 mb-0">
                  {campaign?.name}
                </h1>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="capitalize shadow-none"
                startIcon={<IoAddOutline />}
                onClick={() => history.push(`/campaigns/edit-campaign/${id}`)}
              >
                Update Campaign Info
              </Button>
            </div>

            <div className="w-full bg-white p-5 space-y-5">
              <p className="text-[#040A1D] font-semibold text-[20px] mb-3">
                Campaign Details
              </p>

              <div>
                <p className="text-[14px] text-[#9CA7B8] mb-0">Campaign Name</p>
                <p className="text-base text-[#4C536A] font-medium">
                  {campaign?.name}
                </p>
              </div>

              <div>
                <p className="text-[14px] text-[#9CA7B8] mb-0">
                  Campaign Description
                </p>
                <p className="text-base text-[#4C536A] font-medium">
                  {campaign?.description}
                </p>
              </div>

              <div>
                <p className="text-[14px] text-[#9CA7B8] mb-0">Retailers</p>
                <p
                  className="text-base text-[#4C536A] font-medium"
                  style={{
                    height: 'auto',
                    maxHeight: '250px',
                    overflowY: 'scroll',
                  }}
                >
                  {campaign.retailer.length > 0
                    ? campaign.retailer?.map((item) => item?.name).join(', ')
                    : 'All Retailers'}
                </p>
              </div>

              <div>
                <p className="text-[14px] text-[#9CA7B8] mb-0">Product List</p>
                <p
                  className="text-base text-[#4C536A] font-medium"
                  style={{
                    height: 'auto',
                    maxHeight: '250px',
                    overflowY: 'scroll',
                  }}
                >
                  {campaign.eligible_product.length > 0
                    ? campaign?.eligible_product
                        .map((item) => item?.name)
                        .join(', ')
                    : 'All Products'}
                </p>
              </div>

              <div className="grid grid-cols-2 items-center w-full mb-3">
                <div>
                  <p className="text-[14px] text-[#9CA7B8] mb-0">Start Date</p>
                  <p className="text-base text-[#4C536A] font-medium">
                    {formatDate(campaign?.start_date)}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] text-[#9CA7B8] mb-0">End Date</p>
                  <p className="text-base text-[#4C536A] font-medium">
                    {formatDate(campaign?.end_date)}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full bg-white p-5 space-y-5">
              <p className="text-[#040A1D] font-semibold text-[20px] mb-3">
                Brands
              </p>
              <div
                className="flex gap-2"
                style={{
                  height: 'auto',
                  maxHeight: '250px',
                  overflowY: 'scroll',
                }}
              >
                {campaign?.eligible_brand.length > 0 ? (
                  campaign?.eligible_brand.map((item) => (
                    <div
                      className="py-2 px-4 bg-[#E3E7ED] rounded text-[#040A1D] text-sm font-medium"
                      key={item?.id}
                    >
                      {item?.name}
                    </div>
                  ))
                ) : (
                  <div className="py-2 px-4 bg-[#E3E7ED] rounded text-[#040A1D] text-sm font-medium">
                    All Brands
                  </div>
                )}
              </div>
            </div>

            <div className="bg-white w-full p-5 space-y-5">
              <p className="text-[#040A1D] text-[20px] mb-3 font-semibold">
                Segments
              </p>

              {campaign?.segment.length > 0
                ? campaign?.segment.map((item) => (
                    <div
                      className="flex items-center justify-between border-b-2 border-b-[#4C536A]"
                      key={item.id}
                    >
                      <>
                        <div className="w-[80%] space-y-5">
                          <div>
                            <p className="text-[14px] text-[#9CA7B8] mb-0">
                              Threshold
                            </p>
                            <p className="text-base text-[#4C536A] font-medium">
                              NGN{item?.min_purchase_value} -{' '}
                              {item?.max_purchase_value === null
                                ? 'Infinity'
                                : `NGN
                              ${item?.max_purchase_value}`}
                            </p>
                          </div>
                          <div>
                            <p className="text-[14px] text-[#9CA7B8] mb-0">
                              Package
                            </p>
                            <p className="text-base text-[#4C536A] font-medium">
                              {item.package.packages.join(', ')}
                            </p>
                          </div>
                        </div>

                        <ButtonGroup size="medium">
                          <Button onClick={() => setDeleteStatus(true)}>
                            <img src={del} alt="delete segment" />
                          </Button>
                          {/* <Button>
                            <Link
                              to={`/campaigns/${id}/edit-segment/${item.uuid}`}
                            >
                              <img src={edit} alt="edit segment" />
                            </Link>
                          </Button> */}
                          <Button>
                            <Link
                              to={`/campaigns/${id}/single-segment/${item.uuid}/`}
                            >
                              <img src={view} alt="view segment" />
                            </Link>
                          </Button>
                        </ButtonGroup>
                      </>

                      <ConfirmDeleteModal
                        open={deleteStatus}
                        onClose={() => setDeleteStatus(false)}
                        onConfirm={() => {
                          handleDelete(item.uuid)
                          if (item?.max_purchase_value === null)
                            setInfinity(false)
                        }}
                      >
                        <p className="text-center max-w-[40ch] mx-auto">
                          You're about to delete
                          <br />
                          <span className="text-orange font-medium">
                            "{item?.name}"
                          </span>
                          <br />
                          <br />
                          This action is irreversible.
                        </p>
                      </ConfirmDeleteModal>
                    </div>
                  ))
                : null}

              {infinity ? null : (
                <Button
                  variant="outlined"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={() =>
                    history.push(`/campaigns/${id}/add-new-segment/`)
                  }
                >
                  Add new segment
                </Button>
              )}
            </div>

            <div className="bg-white w-full p-5 space-y-5">
              <p className="text-[#040A1D] text-[20px] mb-3 font-semibold">
                Campaign Settings
              </p>
              <p className=" text-[#9CA7B8]  text-base">
                This campaign is currently{' '}
                <span className="text-[#4C536A] font-medium">
                  {campaign.is_active ? 'active' : 'inactive'}
                </span>
              </p>

              <div className="flex flex-col items-start gap-1">
                <div
                  className="flex items-center gap-3 text-sm border border-[#DEDEE4] bg-[#EFEFF2] p-3 rounded-lg"
                  id="store-status"
                >
                  <span className={`${campaign.is_active && '#28283E'}`}>
                    Activate
                  </span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={campaign.is_active}
                    onChange={() => setOpen(true)}
                  />
                  <span className={`${campaign.is_active && '#8B8B9A'}`}>
                    Deactivate
                  </span>
                </div>
              </div>

              <div>
                <p className="text-[#28283E] text-[10px] py-2 px-3 rounded-xl bg-orange bg-opacity-10 w-[50%] text-center">
                  When you deactivate this campaign, customers will be unable to
                  redeem.
                </p>
              </div>
            </div>
          </div>

          <Dialog open={open}>
            <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
              {campaign.is_active ? (
                <span className="flex items-center justify-center w-16 h-16 rounded-full bg-red/20">
                  <img src={error} alt="" className="" />
                </span>
              ) : (
                <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                  <img src={success} alt="" className="" />
                </span>
              )}

              <p className="font-medium capitalize text-gray2 w-[70%] text-center">
                Are you sure you want to{' '}
                {campaign.is_active ? 'Deactivate' : 'Activate'} this campaign?
              </p>

              {loading ? (
                <Loader />
              ) : (
                <div className="space-x-3">
                  <Button
                    variant="contained"
                    className="capitalize bg-white border shadow-none"
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    No, Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className="capitalize shadow-none"
                    onClick={handleCampaignStatus}
                  >
                    Yes, {campaign.is_active ? 'Deactivate' : 'Activate'}
                  </Button>
                </div>
              )}
            </div>
          </Dialog>
        </>
      )}
    </Layout>
  )
}
export default SingleCampaign
