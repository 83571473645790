import React from "react";
import { Line } from "react-chartjs-2";

const compareHours = (hour, tickValue, tickIndex) => {
  const tickHour = parseInt(tickValue.split(":")[0]);
  if (tickIndex === 0 && hour === 0) return true;
  else if (tickIndex <= 12 && tickHour === hour) return true;
  else if (tickIndex > 12 && tickHour + 12 === hour) return true;
  return false;
};

const tickCallback = (value, index, values) => {
  const date = new Date();
  const hour = date.getHours();
  if (compareHours(hour, value, index)) return `Now, ${value}`;
  else if (value === "12:00am") return "12:00am";
  else if (value === "11:59pm") return "11:59pm";
  return null;
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    intersect: false,
    backgroundColor: "#FAFAFA",
    titleFontColor: "#040A1D",
    bodyFontColor: "#477DFB",
    // usePointStyle: true,
    callbacks: {
      title: function (tooltipItem, data) {
        return `₦${Number(
          data["datasets"][tooltipItem[0]["datasetIndex"]]["data"][tooltipItem[0]["index"]]
        ).toLocaleString("en")}`;
      },
      label: function (tooltipItem, data) {
        return data["labels"][tooltipItem["index"]];
      },
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          fontColor: ["#9CA7B8"],
          beginAtZero: false,
          autoSkip: false,
        },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
};


const RevenueGrowthChart = ({data}) => {
  return (
    <div className="w-full h-[245px]">
      <Line data={data} options={options} className="" />
    </div>
  );
};

export default RevenueGrowthChart;
