import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router'
// import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

// assets
import Layout from '../../components/Layout/Layout'
import backArrow from '../../assets/back-arrow.svg'
import logo from '../../assets/logo.svg'
import blackArrow from '../../assets/black-round-arrow.svg'
import orders from '../../assets/orders-inactive.svg'
import searchIcon from '../../assets/search.svg'

// components
import SaveModal from '../../components/SaveModal/SaveModal.jsx'
import TableTotals from '../../components/TableTotals/TableTotals'
import UnsavedChanges from '../../components/UnsavedChanges/UnsavedChanges'
import UpdateOrderTable from '../../components/UpdateOrderTable/UpdateOrderTable'
import Loader from '@components/Loader/Loader'

//services
import {
  getOrder,
  updateOrder,
  // updateOrderItem,
  updateOrderStatus,
} from '../../services/orders.js'

//store
import { RootContext } from '../../index.jsx'

//enums
import { deliveryOptions, statuses } from '../../enums.js'
import moment from 'moment'
import ProductListMenu from '../../components/ProductListMenu/ProductListMenu.jsx'
import { getStock } from '../../services/stock.js'
// import OrdersTableAlt from '../../components/OrdersTableAlt/OrdersTableAlt.jsx'

const UpdateOrder = observer(() => {
  const root = useContext(RootContext)
  const { id } = useParams()
  const [busy, setBusy] = useState(true)
  const [order, setOrder] = useState({})

  const [selectedProducts, setSelectedProducts] = useState([])
  const [addProducts, setAddProducts] = useState([])

  // stock list menu
  const [itemAnchorEl, setItemAnchorEl] = useState(null)
  const [stock, setStock] = useState([])

  // stock search input
  const [stockSearch, setStockSearch] = useState([])
  const [searchingStock, setSearchingStock] = useState(false)

  const [searchController, setSearchController] = useState(null)

  const [status, setStatus] = useState('')
  const [showExitModal, setShowExitModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [shipping, setShipping] = useState(null)
  // const [tax, setTax] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [subtotal, setSubtotal] = useState(null)

  const { address, city, town, post_code, country, phone_number } =
    order.delivery_address || {}

  const history = useHistory()

  const searchRetailerStock = async () => {
    searchController?.abort?.()
    const abc = new AbortController()
    setSearchController(abc)

    setSearchingStock(true)

    try {
      const { data } = await getStock(
        root.authStore.user.retailerUuid,
        {
          search: stockSearch,
          // page: 1,
          page_size: 50,
        },
        abc.signal,
      )
      setStock(data.results)
      setSearchingStock(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch inventory. Please try again later.')
        setSearchingStock(false)
      }
    }
  }

  // const getToken = () => {
  //   return Cookies.get('auth_token')
  // }

  const fetchOrder = async () => {
    // const token = getToken()
    try {
      const { data } = await getOrder(id)
      setOrder(data)
      setSelectedProducts(data.order_item)
      setSubtotal(data.sub_total.amount)
      setBusy(false)
    } catch (error) {
      toast.error('Unable to fetch order.')
      history.goBack()
    }
  }

  const isDuplicate = (addProduct) => {
    const found = addProducts.find((product) => product.id === addProduct.id)
    const exist = selectedProducts.find(
      (product) => product.product_id === addProduct.product.id,
    )

    if (Boolean(found) || Boolean(exist)) {
      return true
    } else {
      return false
    }
  }

  const handleStockInputFocus = (event) => {
    setItemAnchorEl(event.currentTarget)
  }

  const handleItemMenuClose = () => {
    setItemAnchorEl(null)
  }

  const handleProductPress = (selectedProduct) => {
    if (selectedProduct.out_of_stock || selectedProduct.quantity === 0) {
      toast.info('This product is out of stock')
      return
    }
    if (isDuplicate(selectedProduct)) {
      toast.info('This product is a duplicate')
      return
    }
    setItemAnchorEl(null)
    let newProduct = { ...selectedProduct }
    newProduct.stock_count = newProduct.quantity
    newProduct.quantity = 1
    setAddProducts((prevState) => [...prevState, newProduct])
    // setStockSearch("");
  }

  useEffect(() => {
    fetchOrder()
  }, [])

  useEffect(() => {
    searchRetailerStock()
  }, [stockSearch])

  const adjustProductQuantity = (hook, value, qty, id) => {
    const products = [...value]
    products.forEach((prod) => {
      if (prod.id === id) {
        prod.quantity = qty
      }
    })
    hook(products)
  }

  const deleteProduct = (hook, value, id) => {
    const newProducts = value.filter((item) => item.id !== id)
    hook(newProducts)
  }

  const setProductPrice = (hook, value, id, amount) => {
    hook(
      value.map((prod) => {
        if (prod.id === id) {
          prod.selling_price.amount = amount
        }
        return prod
      }),
    )
  }

  const updateTotals = useCallback(() => {
    let total = 0
    selectedProducts.forEach((element) => {
      total += parseInt(element.price.amount) * element.quantity
    })
    addProducts.forEach((element) => {
      total += parseInt(element.selling_price.amount) * element.quantity
    })
    setSubtotal(total)
  }, [selectedProducts, addProducts])

  useEffect(() => {
    if (selectedProducts || addProducts) updateTotals()
  }, [selectedProducts, addProducts, updateTotals])

  useEffect(() => {
    if (order) {
      setStatus(order.status)
      setDiscount(Number(order.discount_total?.amount || 0))
      setShipping(
        parseInt(
          order?.delivery_options === deliveryOptions.PICKUP
            ? 0
            : order?.delivery_fee?.amount || 0,
        ),
      )
    }
  }, [order])

  const createNewOrder = () => {
    history.replace('/orders/create-order')
  }

  const leavePage = () => {
    if (order.status !== status) setShowExitModal(true)
    else history.goBack()
  }

  const submitUpdate = async () => {
    setBusy(true)
    setShowSaveModal(true)
    const form = { status }

    const combinedArray = []

    selectedProducts.map((item) => {
      return combinedArray.push({
        stock: item.stock_id,
        quantity: item.quantity,
        price: item.price.amount,
      })
    })

    addProducts.map((item) => {
      return combinedArray.push({
        stock: item.id,
        quantity: item.quantity,
        price: item.selling_price.amount,
      })
    })

    try {
      await updateOrderStatus(form, order.uuid).then(async (_) => {
        await updateOrder(
          { order_item: combinedArray, discount_total: discount },
          order.uuid,
        )
      })
      setBusy(false)
    } catch (error) {
      setBusy(false)
      setShowSaveModal(false)
      toast.error('Unable to update order. Please try again later')
    }
  }

  return (
    <Layout>
      {busy && !showSaveModal ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col items-center justify-center py-5">
            <div className="flex flex-row items-center w-[830px] mb-[21px]">
              <button onClick={leavePage} className="bg-transparent">
                <img
                  src={backArrow}
                  alt="back arrow"
                  className="w-[40px] h-[40px]"
                />
              </button>
              <h1 className="text-[#040A1D] text-[30px] font-bold ml-[22px] mb-0">
                {order.order_id}
              </h1>
              {/* <p className="text-[#040A1D] text-[14px] ml-3">Updated by Jame Oluwa 21 April, 5:30 pm</p> */}
            </div>
            <div className="w-[830px] bg-white rounded px-[30px] flex flex-row relative mb-[41px]">
              <div className="flex flex-col pt-[22px] pb-[30px] w-[181px]">
                <img src={logo} alt="logo" className="w-[92px] mb-[20px]" />
                <p className="text-[#4C536A] text-[14px] mb-2">
                  {order.retailer.name}
                </p>
                <p className="text-[#4C536A] text-[14px] mb-2">
                  {order.retailer.address.address}
                </p>
                <p className="text-[#4C536A] text-[14px] mb-2">
                  {order.retailer.business_phone}
                </p>
              </div>
              <img
                src={blackArrow}
                alt="arrow"
                className="w-[22px] h-[22px] absolute top-[45%] left-[284px]"
              />
              <div className="border-dashed border border-[#E3E7ED] ml-[83px] border-b-0 border-t-0 border-l-0 mr-[60px]"></div>
              <div className="flex flex-col  pt-[22px]">
                <div className="flex flex-col w-[425px]">
                  <div className="flex flex-row justify-between">
                    <span className="flex flex-col">
                      <p className="text-[#040A1D] text-[16px] font-bold">
                        {order.user.profile.firstName}{' '}
                        {order.user.profile.lastName}
                      </p>
                      <p className="text-[#477DFB] text-[14px] mb-0">
                        {order.user.email}
                      </p>
                    </span>
                  </div>
                  <div className="h-[1px] w-full bg-[#E3E7ED] my-2"></div>
                  <h5 className="font-bold my-2">Address Details</h5>
                  <div className="flex flex-row">
                    <span className="flex flex-col mr-[41px]">
                      {address && (
                        <p className="w-[141px] text-[#4C536A] text-[14px] mb-1">
                          {address}
                        </p>
                      )}
                      {city && (
                        <p className="w-[141px] text-[#4C536A] text-[14px] mb-1">
                          {city}
                        </p>
                      )}
                    </span>
                    <span className="flex flex-col">
                      <p className="w-[141px] text-[#4C536A] text-[14px] mb-1">
                        {[town, country].filter(Boolean).join(', ')}
                      </p>
                      {post_code && (
                        <p className="w-[141px] text-[#4C536A] text-[14px] mb-1">
                          {post_code}
                        </p>
                      )}
                      <p className="w-[141px] text-[#477DFB] text-[14px] mb-1">
                        {phone_number || order.user.phone_number}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="py-3">
                  {/*<h5 className="font-bold mb-2">Delivery Date & Time</h5>*/}
                  <p className="mt-3 mb-1">
                    <span className="font-bold">
                      {order?.delivery_options === deliveryOptions.PICKUP
                        ? 'Pick up Date: '
                        : 'Delivery Date:: '}
                    </span>
                    {order.delivery_date}
                  </p>
                  <p className="mb-0">
                    <span className="font-bold">
                      {order?.delivery_options === deliveryOptions.PICKUP
                        ? 'Pick up time: '
                        : 'Delivery Time: '}
                    </span>
                    {`${moment(
                      order.delivery_start_time?.split(':').join(''),
                      'hmm',
                    ).format('hh:mm a')} - ${moment(
                      order.delivery_time?.split(':').join(''),
                      'hmm',
                    ).format('hh:mm a')}`}
                  </p>
                </div>
                <div className="py-3">
                  <h5 className="font-bold mb-2">Delivery Note</h5>
                  <p className="mt-3 mb-1">
                    {order.delivery_address?.delivery_note || '-'}
                  </p>
                </div>
                {order.engraving ? (
                  <div className="py-3">
                    <h5 className="font-bold mb-2">Engraving</h5>
                    <p className="mt-3 mb-1">{order.engraving}</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-[830px]">
              <span
                aria-controls="items-menu"
                aria-haspopup="true"
                className="flex flex-row items-center bg-transparent rounded-md w-full px-[10px] h-[32px] border-b border-[#E3E7ED] focus-within:border-appBlue"
              >
                <img
                  src={searchIcon}
                  alt="search"
                  className="w-[16px] h-[16px]"
                />
                <input
                  className="bg-transparent text-[14px] outline-none pl-[10px] font-medium w-full"
                  placeholder="Search Item"
                  value={stockSearch}
                  onChange={(e) => {
                    setStockSearch(e.target.value)
                  }}
                  onFocus={handleStockInputFocus}
                />
              </span>
              <ProductListMenu
                anchorEl={itemAnchorEl}
                onItemPress={handleProductPress}
                handleClose={handleItemMenuClose}
                products={stock}
                fetching={searchingStock}
                style={{
                  width: '830px',
                  maxHeight: '301px',
                  overflowY: 'auto',
                  // minHeight: "100px",
                  marginTop: '10px',
                  marginLeft: '-8px',
                  padding: '0px 10px 0px 10px',
                }}
              />

              {selectedProducts.length === 0 ? (
                addProducts.length === 0 ? (
                  <div className="w-[830px] h-[115px] bg-white rounded flex justify-center items-center mt-4">
                    <p className="text-[#8892A2] text-[14px] font-normal">
                      Browse items in the search bar above to continue
                    </p>
                  </div>
                ) : (
                  <UpdateOrderTable
                    existingProducts={selectedProducts}
                    newProducts={addProducts}
                    setQuantity={adjustProductQuantity}
                    removeItem={deleteProduct}
                    onPriceChange={setProductPrice}
                    productHook={setSelectedProducts}
                    newProductHook={setAddProducts}
                  />
                )
              ) : (
                <UpdateOrderTable
                  existingProducts={selectedProducts}
                  newProducts={addProducts}
                  setQuantity={adjustProductQuantity}
                  removeItem={deleteProduct}
                  onPriceChange={setProductPrice}
                  productHook={setSelectedProducts}
                  newProductHook={setAddProducts}
                />
              )}

              <TableTotals
                subtotal={parseInt(subtotal)}
                shipping={shipping}
                ordertotal={+subtotal + +order.delivery_fee.amount}
                delivery={shipping}
                discount={discount}
                handleDiscountChange={setDiscount}
              />
              {order.order_item.length > 0 && (
                <div className="flex flex-row items-center py-2 border-t border-b border-[#E3E7ED] justify-end mt-6">
                  {/* <button 
                    onClick={() => setShowEmail(true)}
                    className="bg-[#F3641F] rounded-md text-[white] text-[16px] font-bold w-[129px] h-[43px]">
                    Send Invoice
                  </button> */}
                  <span className="flex flex-row items-center">
                    <p className="text-[#9CA7B8] text-[12px] font-bold mr-[20px] mb-0 md:uppercase">
                      Order Status
                    </p>
                    <RadioGroup
                      className="flex flex-row items-center"
                      style={{ 'row-gap': '.3rem' }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <StatusControl
                        label="Completed"
                        value={statuses.COMPLETED}
                        selected={status === statuses.COMPLETED}
                      />
                      <StatusControl
                        label="Pending"
                        value={statuses.PENDING}
                        selected={status === statuses.PENDING}
                      />
                      <StatusControl
                        label="Processing"
                        value={statuses.PROCESSING}
                        selected={status === statuses.PROCESSING}
                      />

                      {order.delivery_options === deliveryOptions.PICKUP ? (
                        <StatusControl
                          label="Ready For Pickup"
                          value={statuses.READY_FOR_PICKUP}
                          selected={status === statuses.READY_FOR_PICKUP}
                        />
                      ) : (
                        <StatusControl
                          label="On Delivery"
                          value={statuses.ON_DELIVERY}
                          selected={status === statuses.ON_DELIVERY}
                        />
                      )}
                      <StatusControl
                        label="Refunded"
                        value={statuses.REFUNDED}
                        selected={status === statuses.REFUNDED}
                      />
                      <StatusControl
                        label="Cancelled"
                        value={statuses.CANCELLED}
                        selected={status === statuses.CANCELLED}
                      />
                    </RadioGroup>
                  </span>
                </div>
              )}
              {order.order_item.length > 0 && (
                <div className="flex flex-row items-center justify-end mt-[20px]">
                  <button
                    onClick={leavePage}
                    className="border border-[#E3E7ED] text-[#4C536A] text-[16px] w-[83px] h-[43px] bg-white rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={submitUpdate}
                    disabled={
                      subtotal <= 0 ||
                      (selectedProducts.length <= 0 && addProducts.length <= 0)
                    }
                    className={`${
                      (selectedProducts.length <= 0 &&
                        addProducts.length <= 0) ||
                      subtotal <= 0
                        ? 'bg-orange/20'
                        : 'bg-orange'
                    } w-[114px] h-[43px] text-white text-[16px] rounded-md ml-[20px]`}
                  >
                    Update Order
                  </button>
                </div>
              )}
            </div>
          </div>
          <UnsavedChanges
            isOpen={showExitModal}
            close={() => setShowExitModal(false)}
          />
          <SaveModal
            isOpen={showSaveModal}
            close={() => setShowSaveModal(false)}
            handleAddPress={createNewOrder}
            icon={orders}
            progressLabel={'Updating your order'}
            successLabel={'Your order has been updated'}
            btnLabel={'Add New Order'}
            update={true}
            busy={busy}
          />
          {/* <EmailInvoice 
            open={showEmail}
            close={() => setShowEmail(false)}
          /> */}
        </>
      )}
    </Layout>
  )
})

function StatusControl({ selected, value, label }) {
  return (
    <FormControlLabel
      className={`${
        selected ? 'text-[#F3641F] bg-white' : 'text-[#4C536A] bg-transparent'
      } font-medium border border-[#E3E7ED] text-[14px] justify-center h-[32px] rounded-md pr-3`}
      control={<Radio color={'secondary'} />}
      value={value}
      label={label}
    />
  )
}

export default UpdateOrder
