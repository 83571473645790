import DateRangePicker from "react-daterange-picker";

import styles from "./custom.module.css";

const CustomDateRange = ({onSelect, dates}) => {
  return (
    <DateRangePicker
      firstOfWeek={1}
      numberOfCalendars={2}
      selectionType='range'
      className={`${styles}`}
      onSelect={onSelect}
      value={dates}
      maximumDate={new Date()}
    />
  )
}

export default CustomDateRange;
