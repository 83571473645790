import React from "react";
import useTableMethods from "../../customHooks/useTableMethods";

//icons
import view from "../../assets/view.svg";
import loader from "../../assets/loader.svg";
import customersInactive from "../../assets/customers-inactive.svg";


import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  ButtonGroup,
  Button,
  TablePagination,
  Avatar
} from "@material-ui/core";

//components
import CustomTableHead from "./CouponOrdersTableHead";
import { formatDate, formatTime } from "../../utils";

const CouponOrdersTable = ({
  rows,
  onViewClick=()=>{},
  busy=false,
  notFound=false,
  search="",
  count=0,
  onPageChange=()=>{},
  page=1,
  type,
  value
}) => {

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected
  } = useTableMethods(rows);
  
  const computeSavedValue = amount => {
    return amount - type === 'Fixed amount'
      ? +value
      : ((+value / 100) * amount)
  }

  return (
    <div className="w-full mt-5">
      {busy || rows.length === 0 ? (
        <>
          <Table className="min-w-[750px]" size="small">
            <CustomTableHead 
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
          </Table>
          <div
            className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2"
          >
            {busy ? (
              <img src={loader} alt="loading" className="w-5 animate-spin"/>
            ) : !rows.length ? (
                <div
                  className="flex flex-col items-center mt-4 justify-center w-full bg-white rounded-md h-[451px]"
                >
                  <img src={customersInactive} alt="" className="w-[50px] h-[50px]" />
                  <p className="font-semibold text-[#3D4356] text-[18px] mb-[10px]">
                    Customers who have used this coupon will show here
                  </p>
                </div>
            ) : (
              <>
                <p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
                  No Search Result for "{search}"
                </p>
                <p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
                  <span className="text-[#8892A2] text-[14px] font-bold">
                    Search tips:{" "}
                  </span>
                  Some search terms require an exact match. Try typing the
                  entire term, or use a different word or phrase.
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <Table className="min-w-[750px]" size="small">
          <CustomTableHead 
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody
            className="bg-white"
          >
            {rows.map((row, index) => {
              const isItemSelected = isSelected(index);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, index)}
                    />
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray2">
                    {row.order_id}
                  </TableCell>
                  <TableCell className="flex items-center space-x-2">
                    <Avatar className="w-8 h-8" src={row.user.profile.picture} />
                    <p className="text-sm font-medium text-gray2">{row.user.profile.first_name} {row.user.profile.last_name}</p>
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray-2">
                    {formatDate(row.created_at)}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray-2">
                    {formatTime(row.created_at)}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray-2" align="right">
                    ₦{Number(row.final_cost.amount).toLocaleString('en')}
                  </TableCell>
                  <TableCell className="text-sm font-medium text-gray-2" align="right">
                    ₦{Number(computeSavedValue(row.final_cost.amount)).toLocaleString('en')}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup size="medium">
                      <Button onClick={() => onViewClick(row)}>
                        <img src={view} alt="" />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
      {!busy && (
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={count}
          rowsPerPage={10}
          page={page}
          onPageChange={(e, newPage) => {
            onPageChange(e, newPage+1);
          }}
        />
      )}
    </div>
  )
}

export default CouponOrdersTable;
