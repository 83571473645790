import { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { toast } from 'react-toastify'
// import { GoogleMap, Marker } from '@react-google-maps/api'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

//assets
import location from '../../assets/location.svg'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import FormInput from '../../components/FormInput/FormInput'

import { getPolicy, updatePolicy, createPolicy } from '../../services/design'
import { trimDigits } from '../../utils'

const Contact = () => {
  const policyTitle = 'Contact'

  const [busy, setBusy] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [extraInfo, setExtraInfo] = useState('')
  const [address, setAddress] = useState()
  const [addressSearch, setAddressSearch] = useState('')
  const [policyId, setPolicyId] = useState(null)

  const { suggestions, setValue, clearSuggestions } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      componentRestrictions: {
        country: ['ng', 'gh'],
      },
    },
  })

  async function updateContactInformation() {
    try {
      toast.info()
      setBusy(true)
      toast.info('Updating contact information...')
      const content = JSON.stringify({
        email,
        phone_number: phone,
        address,
        extras: extraInfo,
      })
      if (policyId) {
        await updatePolicy(policyId, { content })
      } else {
        await createPolicy({ title: policyTitle, content })
      }
      toast.success('Contact information updated successfully.')
    } catch {
      toast.error('Failed to update contact information.')
    } finally {
      setBusy(false)
    }
  }

  async function getContactInfo() {
    try {
      const data = await getPolicy(policyTitle)
      setPolicyId(data.id)
      const info = JSON.parse(data.content || '{}')
      setEmail(info.email)
      setPhone(info.phone_number)
      setAddress(info.address)
      setAddressSearch(info.address?.address)
      setExtraInfo(info.extras)
    } catch {}
  }

  const onSelect = async (suggestion) => {
    const results = await getGeocode({ placeId: suggestion.place_id })
    const coordinates = await getLatLng(results[0])

    const comps = results[0].address_components

    setValue(suggestion.description, false)
    setAddressSearch(suggestion.description)

    clearSuggestions()

    setAddress({
      address: suggestion.description,
      country: comps.find((info) => info.types.includes('country')).long_name,
      city: comps.find((info) => info.types.includes('locality')).long_name,
      administrative_area_level_1: comps.find((info) =>
        info.types.includes('administrative_area_level_1')
      ).long_name,
      post_code: comps.find((info) => info.types.includes('postal_code'))
        .long_name,
      lon: trimDigits(coordinates.lng),
      lat: trimDigits(coordinates.lat),
    })
  }

  const handleAddressInput = (e) => {
    const { value } = e.target
    setAddressSearch(value)
    setValue(value)
  }

  useEffect(() => {
    getContactInfo()
  }, [])

  return (
    <Layout>
      <div className="w-[950px] mx-auto pb-[100px]">
        <PageTitle title="Design" subtitle="Contact" />
        <div className="bg-white w-[70%] p-5 space-y-5 mx-auto">
          <FormInput
            value={email}
            label="Email"
            placeholder="Enter company email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormInput
            value={phone}
            label="Phone Number"
            placeholder="Enter company phone number"
            onChange={(e) => setPhone(e.target.value)}
          />
          {/* address */}
          <div className="relative">
            <FormInput
              id="address-1"
              name="address-1"
              placeholder="Address"
              label="Address"
              value={addressSearch}
              onChange={handleAddressInput}
            />
            {suggestions.data.length > 0 && (
              <ul className="absolute z-50 p-3 bg-white rounded-md shadow-lg top-1 w-full">
                {suggestions.data.map((suggestion, idx) => {
                  return (
                    <li
                      key={idx}
                      onClick={() => onSelect(suggestion)}
                      className="flex items-center p-2 space-x-3 capitalize cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                    >
                      <img src={location} alt="" />
                      <p className="font-medium">{suggestion.description}</p>
                    </li>
                  )
                })}
              </ul>
            )}
            {/* <div className="h-4"></div>
            {address && coords && (
              <div className="relative">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={coords}
                  zoom={10}
                >
                  <Marker position={coords}></Marker>
                </GoogleMap>
              </div>
            )} */}
          </div>

          <div>
            <FormInput
              type="textarea"
              value={extraInfo}
              rows={7}
              label="Extra Information"
              placeholder="Eg; For Store Pickups
              Store Opens From
              Monday - Sunday (9AM - 10PM)"
              onChange={(e) => setExtraInfo(e.target.value)}
            />
            <p className="text-gray-400 mt-1">
              Enter any extra information about the company like opening hours{' '}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end space-x-4 mt-[30px]">
          <Button
            variant="contained"
            disableElevation
            className={`capitalize bg-orange text-white font-semibold text-[16px] flex items-center`}
            disabled={busy}
            onClick={updateContactInformation}
          >
            Save
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
