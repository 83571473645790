import api from '../apiInstance'

export const getTopSellingBrands = (params, signal) =>
  api.get('/api/order/top-selling-brand/', {
    params,
    signal,
  })

export const getBrands = (params, signal) =>
  api.get('/api/brand/', {
    params,
    signal,
  })

export const getSingleBrand = (brandId) => api.get(`/api/brand/${brandId}`)

export const getSingleBrandProducts = (brandId) =>
  api.get('/api/product/', {
    params: {
      brand__uuid: brandId,
    },
  })

export const searchBrand = (query) =>
  api.get('/api/brand/', {
    params: {
      search: query,
    },
  })

export const updateBrand = (brandId, data) =>
  api.patch(`/api/brand/${brandId}`, data)

export const createBrand = (data) => api.post('/api/brand/', data)

export const exportAllBrands = () =>
  api.get('/api/brand/', {
    params: {
      paginate: 'no',
    },
  })
