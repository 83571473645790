import React from 'react'
import pen from '../../assets/pen.svg'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

//mui
import { Button, Avatar } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const center = {
  lat: 5.6221056,
  lng: -0.1755388,
}

const containerStyle = {
  width: '70%',
  height: '167px',
  borderRadius: '10px',
}

const BusinessProfile = ({ retailer }) => {
  const navigate = useHistory()

  return (
    <div className="p-10 space-y-4 bg-white divide-y rounded-md">
      <div className="pb-10 space-y-5">
        <div className="flex items-center justify-between">
          <h1 className="font-semibold text-gray1 text-[20px] capitalize">
            Business Information
          </h1>

          <Button
            variant="contained"
            className="text-white capitalize shadow-none bg-orange"
            startIcon={<img src={pen} alt="" />}
            onClick={() => navigate.push(`/retailers/edit/${retailer.uuid}`)}
          >
            Update Info
          </Button>
        </div>

        <div className="grid grid-cols-[5fr,1fr] gap-10">
          <div className="flex flex-col justify-between py-5 space-y-10">
            <div className="flex items-center divide-x ">
              <div className="pr-12">
                <h1 className="text-sm capitalize text-gray3">Business Name</h1>

                <p className="text-gray1">{retailer.name}</p>
              </div>

              {retailer.website && (
                <div className="px-12 ">
                  <h1 className="text-sm capitalize text-gray3">Website</h1>

                  <p className="text-blue">{retailer.website}</p>
                </div>
              )}

              {retailer.business_phone && (
                <div className="px-12 ">
                  <h1 className="text-sm capitalize text-gray3">
                    Phone Number
                  </h1>

                  <p className="text-blue">{retailer.business_phone}</p>
                </div>
              )}
            </div>

            <div className="flex items-center divide-x ">
              <div className="">
                <h1 className="text-sm capitalize text-gray3">Bio</h1>

                <p className="text-gray1">{retailer.bio}</p>
              </div>
            </div>
          </div>

          <div className="flex items-center h-full">
            <Avatar className="object-contain w-52 h-52" src={retailer.logo}>
              SM
            </Avatar>
          </div>
        </div>
      </div>

      <div className="pt-10 space-y-5">
        <h1 className="font-semibold text-gray1 text-[20px] capitalize">
          Physical Address
        </h1>

        <div className="flex items-center">
          <div className="space-y-4">
            <p>
              {retailer.manager.profile.first_name}{' '}
              {retailer.manager.profile.last_name}
            </p>
            <div className="text-gray1">
              <p className="w-[140.14px]">
                {retailer.address && retailer.address.address}
              </p>
              {retailer.address && (
                <p>
                  {retailer.address.city}, {retailer.address.country}
                </p>
              )}
              <p>{retailer.address && retailer.address.post_code}</p>
              <p>{retailer.business_phone}</p>
            </div>
          </div>

          {retailer.address && (
            <div className="flex items-center justify-end flex-grow">
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lng: parseFloat(retailer.address.lon),
                    lat: parseFloat(retailer.address.lat),
                  }}
                  zoom={10}
                >
                  <Marker
                    position={{
                      lng: parseFloat(retailer.address.lon),
                      lat: parseFloat(retailer.address.lat),
                    }}
                  ></Marker>
                </GoogleMap>
              </LoadScript>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BusinessProfile
