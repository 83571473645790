import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { Line } from "react-chartjs-2";  
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";


//components
import Layout from "../../components/Layout/Layout";
import TableActions from "../../components/TableActions/TableActions";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import ReceiptModal from "../../components/ReceiptModal/ReceiptModal";
import Loader from "@components/Loader/Loader";
import CustomerOrdersTable from "../../components/CustomerOrdersTable/CustomerOrdersTable";
import EmptyTableView from "../../components/EmptyTableVIew/EmptyTableView";

// assets
import backArrow from "../../assets/back-arrow.svg";
import editIcon from "../../assets/white-edit.svg";
import ordersIcon from "../../assets/orders-inactive.svg";

//services
import { getDeliveryAddress, getUser } from "../../services/users";
import { getCustomersOrders, searchCustomersOrders } from "../../services/orders";
import { exportData, formatDate, formatTime } from "../../utils";


const chartOptions = {
  aspectRatio: 1,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: false
      }
    ],
    xAxes: [
      {
        display: false
      }
    ]
  },
  legend: {
    display: false
  }
}

const containerStyle = {
  width: '596px',
  height: '167px',
  borderRadius: '10px'
};

const CustomerDetails = () => {
  const { slug } = useParams();
  const [customer, setCustomer] = useState(null);
  const [address, setAddress] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    datasets: [
      {
        label: "Sales Value",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        fill: true,
        backgroundColor: 'rgba(156, 167, 184, 0.3)',
        borderColor: '#9CA7B8',
        borderWidth: 2,
        tension: 0,
        pointStyle: "line",
        borderRadius: 6
      }
    ]
  });
  const [search, setSearch] = useState('');
  const [orders, setOrders] = useState([]);
  const [totals, setTotals] = useState({
    all: 0,
    completed: 0,
    cancelled: 0
  })
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [activeTab, setActiveTab] = useState('customer details');
  const [busy, setBusy] = useState(true);
  const [showReceipt, setShowReceipt] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [receipt, setReceipt] = useState();
  const history = useHistory();
  let typingTimer;


  const fetchCustomer = async() => {
    try {
      const { data } = await getUser(slug);
      fetchAddress(data);
      setCustomer(data);
      setBusy(false);
    } catch (error) {
      toast.error("An error occurred. Unable to fetch customer");
      history.goBack();
    }
  }

  const fetchAddress = async(customer) => {
    if(customer.delivery_address.length === 0)
      return;
    try {
      const { data } = await getDeliveryAddress(customer.delivery_address[0]);
      setAddress(data)
    } catch (error) {}
  }

  const fetchOrders = async() => {
    try {
      const { data } = await getCustomersOrders(slug);
      setOrders(data.results);
      sortOrdersForGraph(data.results);
      sortAndExtractMetadata(data.results);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Unable to fetch order data");
    }
  }

  const handleSearch = async() => {
    if(search.length === 0) {
      setNotFound(false);
      return;
    }
    setBusy(true);
    try {
      const { data } = await searchCustomersOrders(slug, search);
      setOrders(data.results);
      if(data.count)
        setNotFound(true);
      setBusy(false);
    } catch (error) {
      setNotFound(true);
      setBusy(false);
      toast.error("An error occurred. Unable to fetch requested data.")
    }
  }

  const handleSearchKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const handleSearchKeyUp = () => {
    clearTimeout(typingTimer);
    setTimeout(() => {
      if(activeTab !== 'order history') {
        setActiveTab('order history');
        handleSearch();
      } else
        handleSearch();
    }, 500)
  }

  useEffect(() => {
    fetchCustomer();
    fetchOrders();
  }, [])

  const sortAndExtractMetadata = (orders) => {
    const completed = orders.filter(item => item.status === "Completed");
    const cancelled = orders.filter(item => item.status === "Cancelled");
    const completedTotal = computeTotal(completed);
    const cancelledTotal = computeTotal(cancelled);
    const total = computeTotal(orders);

    setCompletedOrders(completed);
    setCancelledOrders(cancelled);
    setTotals({
      all: total,
      completed: completedTotal,
      cancelled: cancelledTotal
    })
  }

  const computeTotal = (orders) => {
    const reducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.final_cost.amount);

    const total = orders.reduce(reducer, 0);
    return total;
  }

  const sortOrdersForGraph = (orders) => {
    const completed = orders.filter(item => item.status === "Completed");
    if(completed.length === 0)
      return
    const reducer = (previousValue, currentValue) => previousValue + parseInt(currentValue.final_cost.amount);
    const graphData = [];
    for(let i = 0; i < 12; i++) {
      const dayArray = completed.filter((order) => new Date(order.created_at).getMonth() === i);
      if(dayArray.length > 0) {
        const total  = dayArray.reduce(reducer, 0);
        graphData.push(total);
      } else {
        graphData.push(0);
      }
    }
    let graph = {...chartData};
    graph.datasets[0].data = [...graphData];
    setChartData(graph);
  }

  const sanitizeData = () => {
    if(orders.length === 0)
      return
    let copy = [...orders];
    copy.forEach(element => {
      element['Retailer'] = element.retailer.name;
      element['Invoice ID'] = element.order_id;
      element['Date'] = formatDate(element.created_at);
      element['Time'] = formatTime(element.created_at);
      element['Amount'] = `${element.final_cost.currency} ${element.final_cost.amount}`;
      element['Status'] = element.status;
      element['Delivery Address'] = element.delivery_address.address;
      delete element.delivery_start_time;
      delete element.delivery_time_range;
      delete element.updated_at;
      delete element.ordered;
      delete element.order_item;
      delete element.delivery_date;
      delete element.final_cost;
      delete element.delivery_address;
      delete element.delivery_fee;
      delete element.retailer;
      delete element.user;
      delete element.status;
      delete element.transaction_status;
      delete element.uuid;
      delete element.created_at;
      delete element.sub_total;
      delete element.sale_point;
    });
    exportData(copy, 'Orders');
  }

  const goBack = () => {
    history.goBack();
  }

  const openUpdateCustomer = () => {
    history.push(`/customers/update-customer/${slug}`);
  }

  const viewReceipt = (order) => {
    setReceipt(order);
    setShowReceipt(true);
  }

  useEffect(() => {
    if(search.length === 0)
      fetchOrders();
  }, [])

  return (
    <Layout>
      {busy && !customer ? (
        <Loader />
      ) : (
        <div className="px-[30px] pt-5">
          <button onClick={goBack}>
            <img src={backArrow} alt="back" className="w-[40px] h-[40px]"/>
          </button>
          <div className="flex flex-row items-start w-full">
            <div className="w-[20%] flex flex-col ">
              <h1 className="text-[#040A1D] text-[30px] font-bold">{customer.profile.first_name} {customer.profile.last_name}</h1>
              <p className="text-[#4C536A] text-[16px] mb-[31px]">{customer.email}</p>
              <SummaryCard 
                label={"Total Orders"}
                amount={totals.all}
                summaryValue={orders.length}
              />
              <div className="h-3"></div>
              <SummaryCard 
                label={"Completed"}
                amount={totals.completed}
                summaryValue={completedOrders.length}
                accentColor={"bg-appGreen"}
              />
              <div className="h-3"></div>
              <SummaryCard 
                label={"Cancelled"}
                amount={totals.cancelled}
                summaryValue={cancelledOrders.length}
                accentColor={"bg-appRed"}
              />
              <div className="h-3"></div>
              <div className="rounded-xl bg-white w-full h-[242px] border-4 border-white">
                <p className="text-[#9CA7B8] text-[12px] ml-4 mt-3">Sales</p>
                <h2 className="text-[#040A1D] text-[26px] ml-4">₦{Number(totals.completed).toLocaleString('en')}</h2>
                <div className="h-[121px] w-full rounded-b-xl">
                  <Line 
                    data={chartData}
                    options={chartOptions}
                    id="chart-customer"
                    className="chart-canvas"
                    height={null}
                    width={null}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col pl-[30px] w-[80%]">
              <TableActions 
                activeTab={activeTab}
                changeTab={setActiveTab}
                onSearchChange={(e) => setSearch(e.target.value)}
                onSearchKeyDown={handleSearchKeyDown}
                onSearchKeyUp={handleSearchKeyUp}
                searchPlaceholder={"Search"}
                tabLabels={['customer details', 'order history']}
                exportData={sanitizeData}
              />
              {activeTab === 'customer details' ? (
                <>
                  <div className="flex flex-col bg-white rounded-md px-[40px] mt-[2px] py-[25px]">
                    <div className="flex flex-row justify-between mb-6">
                      <h2 className="text-[#040A1D] text-[20px] font-bold">Personal Details</h2>
                      <button 
                        onClick={openUpdateCustomer}
                        className="bg-[#F3641F] text-white flex flex-row items-center px-4 py-3 rounded-lg font-bold">
                        <img src={editIcon} className="w-[21px] h-[21px] mr-4" alt="" />
                        Update Customer Info
                      </button>
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="flex flex-col">
                        <Avatar 
                          className="w-[177px] h-[177px] rounded-full"
                          src={customer.profile.picture}/>
                      </div>
                      <div className="flex flex-col ml-[80px]">
                        <div className="flex flex-row">
                          <div className="flex flex-col">
                            <p className="text-[14px] text-[#9CA7B8] pb-2 font-normal">First Name</p>
                            <p className="text-[16px] text-[#040A1D]">{customer.profile.first_name}</p>
                          </div>
                          <div className="h-[56px] w-[1px] bg-[#E3E7ED] mx-6"></div>
                          <div className="flex flex-col">
                            <p className="text-[14px] text-[#9CA7B8] pb-2 font-normal">Last Name</p>
                            <p className="text-[16px] text-[#040A1D]">{customer.profile.last_name}</p>
                          </div>
                          <div className="h-[56px] w-[1px] bg-[#E3E7ED] mx-6"></div>
                          <div className="flex flex-col">
                            <p className="text-[14px] text-[#9CA7B8] pb-2 font-normal">Date of Birth</p>
                            <p className="text-[16px] text-[#040A1D]">{customer.profile.date_of_birth}</p>
                          </div>
                        </div>
                        <div className="flex flex-row mt-8">
                          <div className="flex flex-col">
                            <p className="text-[14px] text-[#9CA7B8] pb-2 font-normal">Email Address</p>
                            <p className="text-[16px] text-[#477DFB]">{customer.email}</p>
                          </div>
                          <div className="h-[56px] w-[1px] bg-[#E3E7ED] mx-6"></div>
                          <div className="flex flex-col">
                            <p className="text-[14px] text-[#9CA7B8] pb-2 font-normal">Phone Number</p>
                            <p className="text-[16px] text-[#477DFB]">{customer.phone_number}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {address && (
                    <div className="mt-4 bg-white px-[40px] rounded-lg py-6 xl:px-[20px] lg:px-[20px]">
                      <h2 className="text-[#040A1D] text-[20px] font-bold mb-6">Shipping Information</h2>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                          <p className="text-[#040A1D] text-[16px] mb-2">{customer.profile.first_name} {customer.profile.last_name}</p>
                          <p className="text-[#040A1D] w-[161px] text-[16px]">{address.address}</p>
                          <p className="text-[#040A1D] text-[16px]">{address.administrative_level_1}</p>
                          <p className="text-[#040A1D] text-[16px]">{address.city}, {address.country}</p>
                          <p className="text-[#040A1D] text-[16px]">{address.post_code}</p>
                          <p className="text-[#040A1D] text-[16px]">{customer.phone_number}</p>
                        </div>
                        <div>
                          <LoadScript
                            googleMapsApiKey="AIzaSyDADnRE-sSrmMBS4BadpSkPwxJygrdSC24"
                          >
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{lng: parseFloat(address.lon), 
                                lat: parseFloat(address.lat)}}
                              zoom={10}
                            >
                              <Marker position={{lng: parseFloat(address.lon), 
                                lat: parseFloat(address.lat)}}>
                              </Marker>
                            </GoogleMap>
                          </LoadScript>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : orders.length === 0 ? (
                <EmptyTableView 
                  title={`${customer.profile.first_name}'s orders will show here`}
                  icon={ordersIcon}
                />
              ) : (
                <div className="mt-2">
                  <CustomerOrdersTable 
                    onViewClick={viewReceipt}
                    rows={orders}
                    busy={busy}
                    search={search}
                    notFound={notFound}
                  />
                </div>
              )}
            </div>
          </div>
          {receipt && (
            <ReceiptModal
              isOpen={showReceipt}
              data={receipt}
              closeModal={() => setShowReceipt(false)}
            />
          )}
        </div>
      )}
    </Layout>
  )
}

export default CustomerDetails;