import React, { useState, useContext } from 'react'
import { IoArrowUp } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import validatePhoneNumber from '../../../utils/validatePhoneNumber'
import { toast } from 'react-toastify'

//icons
import productsInactive from '../../../assets/products-inactive.svg'
import loader from '../../../assets/loader.svg'
import success from '../../../assets/success.svg'
import deleteIcon from '../../../assets/delete.svg'
import editIcon from '../../../assets/edit.svg'

//mui
import { Button, Dialog, ButtonGroup } from '@material-ui/core'

//components
import FormInput from '../../../components/FormInput/FormInput'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import UnsavedChanges from '../../../components/UnsavedChanges/UnsavedChanges'

//services
import { updateProfile, updateUserDetails } from '../../../services/users'

//store
import { RootContext } from '../../..'
import ImageInput from '../../../components/ImageInput/ImageInput'

const EditUserForm = ({ user = {} }) => {
  const root = useContext(RootContext)
  const isRetailer =
    root.authStore.user && root.authStore.user.account_type.includes('Retailer')

  const history = useHistory()

  const [loading, setLoading] = useState(false)

  //modal state
  const [open, setOpen] = useState(false)
  const [existing, setExisting] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)

  //user details
  const [firstName, setFirstName] = useState(user?.profile.first_name)
  const [lastName, setLastName] = useState(user?.profile.last_name)
  const [phoneNumber, setPhoneNumber] = useState(
    user?.phone_number.substring(4),
  )
  const [countryCode, setCountryCode] = useState(
    user?.phone_number.substring(0, 4),
  )
  const [accountType, setAccountType] = useState(user?.account_type)
  const [gender, setGender] = useState(user?.profile.gender)
  const [image, setImage] = useState(user?.profile?.picture)
  const [userCreateSuccess, setUserCreateSuccess] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setUserCreateSuccess(false)
    setOpen(false)
  }

  const handleSubmit = async () => {
    const phone = sanitizePhoneNumber()
    const isPhoneNumberValid = validatePhoneNumber(phone)

    if (!isPhoneNumberValid) {
      return toast.error('Phone Number must be in +XXXXXXX format')
    }

    const formData = new FormData()
    if (image && typeof image !== 'string') {
      formData.append('picture', image)
    }
    formData.append('first_name', firstName)
    formData.append('last_name', lastName)
    formData.append('gender', gender)

    const data = {
      phone_number: phone,
      account_type: accountType,
    }

    handleClickOpen()
    setLoading(true)

    try {
      await updateProfile(formData, user?.profile.uuid)
      await updateUserDetails(user?.uuid, data)
      setLoading(false)
      setUserCreateSuccess(true)
    } catch (error) {
      setOpen(false)
      setUserCreateSuccess(false)
      if (error.response.data) {
        for (let err in error.response.data) {
          toast.error(error.response.data[err][0])
        }
      } else {
        toast.error('Error updating user.')
      }
    } finally {
      setLoading(false)
    }
  }

  const sanitizePhoneNumber = () => {
    if (phoneNumber.substring(0, 1) === '0')
      return `${countryCode}${phoneNumber.substring(1)}`
    else return `${countryCode}${phoneNumber}`
  }

  return (
    <>
      <div className="bg-white w-[70%] p-5 space-y-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Personal Details</h1>
        <div className="grid grid-cols-2 gap-5">
          <FormInput
            type="text"
            label="First Name"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <FormInput
            type="text"
            label="Last Name"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <div className="flex flex-row items-center space-x-1">
            <CustomSelect
              label="Country Code"
              name="country-codes"
              id="country-codes"
              value={countryCode}
              options={['+233', '+234']}
              setValue={setCountryCode}
            />
            <FormInput
              type="text"
              label="Phone Number"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <CustomSelect
            label="Account Type"
            placeholder="Account Type"
            id="accountType"
            value={accountType}
            setValue={setAccountType}
            options={
              isRetailer
                ? ['Customer', 'Retailer Staff']
                : [
                    'Customer',
                    'Retailer',
                    'Drinks Sales Person',
                    'Brands Analytics Admin',
                    'Brands Analytics User',
                    'Drinks Admin',
                  ]
            }
          />
          <CustomSelect
            options={['male', 'female']}
            label={'Gender'}
            value={gender}
            setValue={setGender}
          />
        </div>
      </div>

      <div className="bg-white w-[70%] p-5">
        <ImageInput
          title="Profile Photo"
          value={image}
          hook={setImage}
          state={existing}
          hookState={setExisting}
          id="profile-image"
        />
      </div>

      <div className="w-[70%] flex justify-end items-center space-x-3">
        <Button
          variant="contained"
          className="capitalize bg-white shadow-none"
          onClick={() => {
            setModalOpen(true)
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={`text-white capitalize shadow-none ${
            firstName === '' ||
            lastName === '' ||
            phoneNumber === '' ||
            gender === '' ||
            accountType === ''
              ? 'bg-orange/30 cursor-not-allowed'
              : 'bg-orange cursor-pointer'
          }`}
          onClick={handleSubmit}
          disabled={
            firstName === '' ||
            lastName === '' ||
            phoneNumber === '' ||
            gender === '' ||
            accountType === ''
          }
        >
          Save
        </Button>
      </div>

      <Dialog open={open}>
        {loading && (
          <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
            <img src={productsInactive} alt="" className="w-9 h-9" />
            <p className="font-medium capitalize text-gray2">Updating User</p>
            <img src={loader} alt="" className="animate-spin" />
          </div>
        )}
        {userCreateSuccess && (
          <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
            <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
              <img src={success} alt="" className="" />
            </span>

            <p className="font-medium capitalize text-gray2 w-[38%] text-center">
              User Updated Successfully
            </p>

            <div className="space-x-3">
              <Button
                variant="contained"
                color="primary"
                className="capitalize shadow-none"
                onClick={() => {
                  handleClose()
                  history.push('/users')
                }}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </Dialog>

      <UnsavedChanges isOpen={modalOpen} close={() => setModalOpen(false)} />
    </>
  )
}

export default EditUserForm
