import React from 'react'

import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core'

const headCells = [
  { id: 'id', label: 'invoice id' },
  { id: 'customer', label: 'customer' },
  { id: 'date', label: 'date' },
  { id: 'time', label: 'time' },
  { id: 'amount', numeric: true, label: 'amount' },
]

const CustomTableHead = ({ onSelectAllClick, numSelected, rowCount }) => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            className=""
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="uppercase text-[12px]"
            align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default CustomTableHead
