import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IoAddOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { Calendar, utils } from "react-modern-calendar-datepicker";

//components
import FormInput from "../FormInput/FormInput";
import UnsavedChanges from "../UnsavedChanges/UnsavedChanges";
import AddNewSegmentForm from "../AddNewSegmentForm/AddNewSegmentForm";

//mui
import {
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  Popover,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";

//icons
import CampaignInactive from "../../assets/campaign-inactive.svg";
import loader from "../../assets/loader.svg";
import success from "../../assets/success.svg";
import del from "../../assets/delete.svg";
import edit from "../../assets/edit.svg";

//utils
import {
  createCampaign,
  findBrands,
  findProducts,
  findRetailer,
} from "../../services/campaigns";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import CustomMultiSelectSearch from "../CustomMultiSelect/CustomMultiSelectSearch";

const AddNewCampaignForm = ({ request = false }) => {
  const history = useHistory();

  //campaign fields
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [allProducts, setAllProducts] = useState(false);
  const [allRetailers, setAllRetailers] = useState(false);

  //segment field
  const [itemList, setItemList] = useState([]);

  //state managements
  const [showExitModal, setShowExitModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showSegment, setShowSegment] = useState(false);
  const [infinite, setInfinite] = useState(false);
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);

  //date management
  const [date, setDate] = useState("");
  const [expiry, setExpiry] = useState("");
  const [anchorStart, setAnchorStart] = useState(null);
  const [anchorEnd, setAnchorEnd] = useState(null);

  //update segment
  const [editSegment, setEditSegment] = useState({});
  const [editRequest, setEditRequest] = useState(false);

  const handleToggle = (state) => {
    setShowSegment(state);
  };

  const handleInfinity = (state) => {
    setInfinite(state);
  };

  const handleDelete = (id) => {
    const updatedItems = itemList.filter((item) => item.id !== id);
    setItemList(updatedItems);
  };

  //fetching dropdown data
  const fetchSearchRetailers = async (value) => {
    try {
      const res = await findRetailer(value);

      const items = res.data.results.map((retailer) => ({
        label: (
          <div className="flex gap-2">
            <span>{retailer.name}</span>
          </div>
        ),
        value: retailer.id,
      }));

      return items;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSearchBrand = async (value) => {
    try {
      const res = await findBrands(value);

      const items = res.data.results.map((brand) => ({
        label: (
          <div className="flex gap-2">
            <span>{brand.name}</span>
          </div>
        ),
        value: brand.id,
      }));

      return items;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSearchProduct = async (value) => {
    try {
      const res = await findProducts(value);

      const items = res.data.results.map((product) => ({
        label: (
          <div className="flex gap-2">
            <span>{product.name}</span>
          </div>
        ),
        value: product.id,
      }));

      return items;
    } catch (error) {
      console.log(error);
    }
  };

  //form management
  const clearForm = () => {
    setName("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setItemList([]);
    setSelectedBrands([]);
    setSelectedProducts([]);
    setSelectedRetailers([]);
    setAllProducts(false);
    setAllRetailers(false);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const addItemToList = (newItem) => {
    setItemList([...itemList, newItem]);
  };

  const updateItemList = (newItem) => {
    const update = itemList.map((item) =>
      item.id === newItem.id ? newItem : item
    );
    setItemList(update);
  };

  const getIdList = (data) => {
    return data.map((item) => item.value);
  };

  const generateForm = () => {
    const form = {
      name: name,
      description: description,
      start_date: new Date(startDate),
      end_date: new Date(endDate),
      eligible_brand: getIdList(selectedBrands),
      eligible_product: getIdList(selectedProducts),
      retailer: getIdList(selectedRetailers),
    };
    return form;
  };

  const generateSegmentForm = () => {
    const form = itemList.map((item) => ({
      name: item.name,
      limit: item.users,
      alert_threshold: parseFloat(item.qualifying),
      min_purchase_value: parseFloat(item.min),
      max_purchase_value: parseFloat(item.max),
      package: item.packages,
      start_date: new Date(startDate),
      end_date: new Date(endDate),
    }));

    return form;
  };

  const handleSubmit = async () => {
    setOpen(true);
    setBusy(true);

    const form = generateForm();
    const segment = generateSegmentForm();

    try {
      await createCampaign({ segment_campaign: segment, ...form });
      setBusy(false);
    } catch (error) {
      setOpen(false);
      setBusy(false);
      toast.error("An error occurred. Unable to create new campaign");
    }
  };

  const checkFormForEmptyFields = useCallback(() => {
    if (
      name.length > 0 &&
      description.length > 0 &&
      startDate.length > 0 &&
      endDate.length > 0 &&
      (selectedBrands.length > 0 ||
        selectedProducts.length > 0 ||
        allProducts) &&
      (selectedRetailers.length > 0 || allRetailers) &&
      itemList.length > 0 &&
      infinite
    )
      setDisabled(false);
    else {
      setDisabled(true);
    }
  }, [
    name.length,
    description.length,
    startDate.length,
    endDate.length,
    selectedBrands.length,
    selectedProducts.length,
    selectedRetailers.length,
    itemList.length,
    allRetailers,
    allProducts,
    infinite,
  ]);

  useEffect(() => {
    checkFormForEmptyFields();
  }, [
    name,
    description,
    startDate,
    endDate,
    selectedBrands,
    selectedProducts,
    selectedRetailers,
    itemList,
    allProducts,
    allRetailers,
    infinite,
    checkFormForEmptyFields,
  ]);

  return (
    <>
      <div className="w-[70%] flex items-center space-x-3">
        <IconButton
          onClick={() => {
            if (!showSegment) history.goBack();
            else setShowSegment(false);
          }}
          disableRipple
          className="p-0 hover:bg-transparent"
        >
          <IoArrowBackCircleOutline className="w-10 h-10 text-gray2" />
        </IconButton>
        <h1 className="font-bold text-[30px] text-gray1 mb-0">
          New {!showSegment ? "Campaign" : "Segment"}
        </h1>
      </div>

      {!showSegment ? (
        <>
          <div className="bg-white w-[70%] p-5 space-y-5">
            <p className="text-[#3D4356] text-[16px] mb-3">Campaign Details</p>
            <FormInput
              id="campaign-name"
              name="campaign-name"
              type="text"
              label="Campaign Name"
              placeholder="Campaign Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FormInput
              id="campaign-description"
              name="campaign-description"
              type="textarea"
              label="Campaign Description"
              placeholder="Campaign Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="flex space-x-4 items-center w-full mb-3">
              <>
                <FormInput
                  id="start-date"
                  name="start-date"
                  placeholder="Start Date"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  onFocus={(e) => setAnchorStart(e.target)}
                />
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(anchorStart)}
                  anchorEl={anchorStart}
                  onClose={() => {
                    setAnchorStart(null);
                  }}
                  disableRestoreFocus
                >
                  <Calendar
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                      setStartDate(
                        `${date.year}/${`${date.month}`.padStart(2, "0")}/${
                          date.day
                        }`
                      );
                      setAnchorStart(null);
                    }}
                    shouldHighlightWeekends={false}
                    colorPrimary="#F3641F"
                    colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                    minimumDate={utils().getToday()}
                    selectorStartingYear={new Date().getFullYear()}
                  />
                </Popover>
              </>

              <>
                <FormInput
                  id="end-date"
                  name="end-date"
                  placeholder="End Date"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  onFocus={(e) => setAnchorEnd(e.target)}
                />
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(anchorEnd)}
                  anchorEl={anchorEnd}
                  onClose={() => setAnchorEnd(null)}
                  disableRestoreFocus
                >
                  <Calendar
                    value={expiry}
                    onChange={(date) => {
                      setExpiry(date);
                      setEndDate(
                        `${date.year}/${`${date.month}`.padStart(2, "0")}/${
                          date.day
                        }`
                      );
                      setAnchorEnd(null);
                    }}
                    shouldHighlightWeekends={false}
                    colorPrimary="#F3641F"
                    colorPrimaryLight="rgba(243, 100, 31, 0.1)"
                    minimumDate={utils().getToday()}
                    selectorStartingYear={new Date().getFullYear()}
                  />
                </Popover>
              </>
            </div>{" "}
          </div>

          {!request ? (
            <>
              <div
                className={`w-[70%] p-5 space-y-3 ${
                  !allRetailers ? "bg-white" : "bg-grayBg1"
                }`}
              >
                <div className="mb-3 flex items-center justify-between">
                  <h1 className="font-medium text-[#3D4356]">Retailers</h1>
                  <div className="flex gap-1 items-center">
                    <Checkbox
                      checked={allRetailers}
                      onChange={() => {
                        setAllRetailers(!allRetailers);
                      }}
                    />
                    <p className="mb-0 font-medium text-[#3D4356]">
                      Select All Retailers
                    </p>
                  </div>
                </div>

                {!allRetailers ? (
                  <CustomMultiSelectSearch
                    mode="multiple"
                    value={selectedRetailers}
                    placeholder="Select retailers"
                    fetchOptions={fetchSearchRetailers}
                    data={retailers}
                    onChange={(newValue) => {
                      setSelectedRetailers(newValue);
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  <div className="py-2 px-4 bg-[#E3E7ED] rounded text-[#040A1D] text-sm font-medium">
                    All Retailers
                  </div>
                )}
              </div>

              <div className="bg-white w-[70%] p-5 space-y-3">
                <h1 className="mb-3 font-medium text-[#3D4356]">Brands</h1>
                <CustomMultiSelectSearch
                  mode="multiple"
                  value={selectedBrands}
                  placeholder="Select brands"
                  fetchOptions={fetchSearchBrand}
                  data={brands}
                  onChange={(newValue) => {
                    setSelectedBrands(newValue);
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <div
                className={`w-[70%] p-5 space-y-3 ${
                  !allProducts ? "bg-white" : "bg-grayBg1"
                }`}
              >
                <div className="mb-3 flex items-center justify-between">
                  <h1 className="font-medium text-[#3D4356]">Products</h1>
                  <div className="flex gap-1 items-center">
                    <Checkbox
                      checked={allProducts}
                      onChange={() => {
                        setAllProducts(!allProducts);
                      }}
                    />
                    <p className="mb-0 font-medium text-[#3D4356]">
                      Select All Products
                    </p>
                  </div>
                </div>

                {!allProducts ? (
                  <>
                    <CustomMultiSelectSearch
                      mode="multiple"
                      value={selectedProducts}
                      placeholder="Select products"
                      fetchOptions={fetchSearchProduct}
                      data={products}
                      onChange={(newValue) => {
                        setSelectedProducts(newValue);
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </>
                ) : (
                  <div className="py-2 px-4 bg-[#E3E7ED] rounded text-[#040A1D] text-sm font-medium">
                    All Products
                  </div>
                )}
              </div>
            </>
          ) : null}

          <>
            <div className="bg-white w-[70%] p-5 space-y-5">
              <p className="text-[#040A1D] text-[20px] mb-3 font-semibold">
                Segments
              </p>

              {itemList.length > 0
                ? itemList.map((item) => (
                    <>
                      <div
                        className="flex items-center justify-between border-b-2 border-b-[#4C536A]"
                        key={item?.name}
                      >
                        <div className="w-[80%] space-y-5">
                          <div>
                            <p className="text-[14px] text-[#9CA7B8] mb-0">
                              Threshold
                            </p>
                            <p className="text-base text-[#4C536A] font-medium">
                              NGN{item?.min} -{" "}
                              {item.infinity ? "Infinity" : `NGN${item?.max}`}
                            </p>
                          </div>
                          <div>
                            <p className="text-[14px] text-[#9CA7B8] mb-0">
                              Package
                            </p>
                            <p className="text-base text-[#4C536A] font-medium">
                              {item.packages.packages.join(", ")}
                            </p>
                          </div>
                        </div>

                        <ButtonGroup size="medium">
                          <Button onClick={() => setDeleteStatus(true)}>
                            <img src={del} alt="delete segment" />
                          </Button>
                          <Button
                            onClick={() => {
                              setShowSegment(true);
                              setEditSegment(item);
                              setEditRequest(true);
                            }}
                          >
                            <img src={edit} alt="edit segment" />
                          </Button>
                          {/* <Button>
                          <Link to="/campaigns/single-segment">
                            <img src={view} alt="view segment" />
                          </Link>
                        </Button> */}
                        </ButtonGroup>
                      </div>

                      {/*delete a segment modal that re-activates the add button if the final segment was deleted*/}
                      <ConfirmDeleteModal
                        open={deleteStatus}
                        onClose={() => setDeleteStatus(false)}
                        onConfirm={() => {
                          handleDelete(item.id);
                          if (item?.max_purchase_value === null)
                            setInfinite(false);
                        }}
                      >
                        <p className="text-center max-w-[40ch] mx-auto">
                          You're about to delete
                          <br />
                          <span className="text-orange font-medium">
                            "{item?.name}"
                          </span>
                          <br />
                          <br />
                          This action is irreversible.
                        </p>
                      </ConfirmDeleteModal>
                    </>
                  ))
                : null}

              {infinite ? null : (
                <Button
                  variant="outlined"
                  color="primary"
                  className="capitalize shadow-none"
                  startIcon={<IoAddOutline />}
                  onClick={() => {
                    setShowSegment(true);
                    setEditRequest(false);
                  }}
                >
                  Add new segment
                </Button>
              )}
            </div>

            <div className="w-[70%] flex justify-end items-center space-x-3 pb-20">
              <Button
                variant="contained"
                className="capitalize bg-white shadow-none"
                onClick={() => {
                  setShowExitModal(true);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={`text-white capitalize shadow-none ${
                  disabled
                    ? "bg-orange/30 cursor-not-allowed"
                    : "bg-orange cursor-pointer"
                }`}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={disabled}
              >
                Save
              </Button>
            </div>
          </>
        </>
      ) : null}

      {showSegment ? (
        <AddNewSegmentForm
          onUpdate={handleToggle}
          onInfinity={handleInfinity}
          onForm={editRequest ? updateItemList : addItemToList}
          formId={
            itemList.length > 0 ? itemList[itemList.length - 1]?.id + 1 : 1
          }
          onEdit={editSegment}
          request={editRequest}
          prevValue={itemList[itemList.length - 1]?.max}
        />
      ) : null}

      <Dialog open={open}>
        <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
          {busy ? (
            <>
              <img src={CampaignInactive} alt="" className="w-9 h-9" />
              <p className="font-medium capitalize text-gray2">
                Saving New Campaign
              </p>
              <img src={loader} alt="" className="animate-spin" />
            </>
          ) : (
            <>
              <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                <img src={success} alt="" className="" />
              </span>
              <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                New Campaign Added Successfully
              </p>
              <div className="space-x-3">
                <Button
                  variant="contained"
                  className="capitalize bg-white border shadow-none"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Add New Campaign
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  onClick={() => {
                    history.push("/campaigns");
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>

      <UnsavedChanges
        close={() => setShowExitModal(false)}
        isOpen={showExitModal}
      />
    </>
  );
};
export default AddNewCampaignForm;
