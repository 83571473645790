import React from "react";

import { TableCell, TableHead, TableRow, Checkbox } from "@material-ui/core";

const headCells = [
  { id: "date", label: "date" },
  { id: "time", label: "time" },
  { id: "previous qty", label: "previous quantity" },
  { id: "qty change", label: "quantity change" },
  { id: "available qty", numeric: true, label: "available quantity" },
];

const OrderHistoryTableHead = ({ onSelectAllClick, numSelected, rowCount }) => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            className=""
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="uppercase text-[12px]"
            align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderHistoryTableHead;
