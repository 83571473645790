import React, { useState, useContext } from "react";
import { IoArrowUp, IoClose } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import validateEmail from "../../utils/validateEmail";
import validatePhoneNumber from "../../utils/validatePhoneNumber";
import { toast } from "react-toastify";

//icons
import productsInactive from "../../assets/products-inactive.svg";
import loader from "../../assets/loader.svg";
import success from "../../assets/success.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import location from "../../assets/location.svg";

//mui
import {
  Button,
  Dialog,
  ButtonGroup,
  IconButton,
  Avatar,
} from "@material-ui/core";

//components
import FormInput from "../FormInput/FormInput";
import UnsavedChanges from "../UnsavedChanges/UnsavedChanges";
import { RootContext } from "../..";
import { getUsers } from "../../services/users";
import CustomSelect from "../CustomSelect/CustomSelect";

//services
import { updateRetailer, createNewRetailer } from "../../services/retailers";

const libraries = ["places"];

const containerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "10px",
};

const AddNewProductForm = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const root = useContext(RootContext);

  const history = useHistory();

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const [search, setSearch] = useState("");
  const [manager, setManager] = useState();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  //modal state
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  //business details
  const [businessName, setBusinessName] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+234");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bio, setBio] = useState("");
  const [businessLogo, setBusinessLogo] = useState();
  const [bannerImage, setBannerImage] = useState();

  //address Infomation
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  //marker coords
  const [coords, setCoord] = useState(null);
  const [center, setCenter] = useState();

  let typingTimer;

  const handleSearchKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleSearch, 500);
  };

  const handleSearch = async () => {
    if (search.length === 0) {
      setSearchResults([]);
      return;
    }
    try {
      const { data } = await getUsers({ page_size: 100, page: 1, search });
      setSearchResults(data.results);
    } catch (error) {
      toast.error("An error occurred. Unable to fetch requested data.");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = () => {
    setEdit(true);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setValue(value);
    setAddress(value);
  };

  const onSelect = async (suggestion) => {
    const results = await getGeocode({ placeId: suggestion.place_id });
    const coordinates = await getLatLng(results[0]);

    const addressComps = results[0].address_components;

    const country = addressComps.find((info) => info.types.includes("country"));
    const city = addressComps.find((info) => info.types.includes("locality"));
    const district = addressComps.find((info) =>
      info.types.includes("sublocality")
    );
    // const city = addressComps.find((info) =>
    //   info.types.includes("administrative_area_level_2")
    // );
    const region = addressComps.find((info) =>
      info.types.includes("administrative_area_level_1")
    );
    const postalCode = addressComps.find((info) =>
      info.types.includes("postal_code")
    );

    setValue(suggestion.description, false);

    clearSuggestions();

    setCoord(coordinates);
    setCenter(coordinates);
    setAddress(suggestion.description);
    setDistrict(district?.long_name);
    setCity(city?.long_name);
    setPostalCode(postalCode?.long_name);
    setRegion(region?.long_name);
    setCountry(country?.long_name);
  };

  const sanitizePhoneNumber = () => {
    if (phoneNumber.substring(0, 1) === "0")
      return `${countryCode}${phoneNumber.substring(1)}`;
    else return `${countryCode}${phoneNumber}`;
  };

  const [retailerId, setRetailerId] = useState("");

  const handleSubmit = async () => {
    const isBusinessEmailValid = validateEmail(email);

    const sanitizedPhone = sanitizePhoneNumber();

    const isBusinessPhoneNumberValid = validatePhoneNumber(sanitizedPhone);

    if (!isBusinessEmailValid) {
      return toast.error("Check email format and try again.");
    }

    if (!isBusinessPhoneNumberValid) {
      return toast.error("Phone Number be in +XXXXXXX format");
    }

    let lat = `${coords?.lat}`;
    let lon = `${coords?.lng}`;
    const latValue = parseFloat(lat).toFixed(8);
    const lonValue = parseFloat(lon).toFixed(8);

    const data = {
      manager: manager.id,
      name: businessName,
      bio: bio,
      business_email: email,
      business_phone: sanitizedPhone,
      website: website,
      address: {
        is_active: true,
        address: address,
        street_name: "",
        town: "",
        city: city,
        country: country,
        administrative_area_level_1: region,
        administrative_area_level_2: "",
        post_code: postalCode,
        plus_code: "",
        lon: lonValue,
        lat: latValue,
      },
    };

    const formData = new FormData();
    formData.append("logo", businessLogo);
    formData.append("store_image", bannerImage);

    handleClickOpen();
    setLoading(true);

    try {
      if (retailerId === "") {
        const res = await createNewRetailer(data);
        setRetailerId(res.data.uuid);
        await updateRetailer(res.data.uuid, formData);
      } else {
        await updateRetailer(retailerId, formData);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      handleClose();
      toast.error("Error creating retailer. Please try again");
    }
  };

  const clearInfo = () => {
    setBusinessName("");
    setWebsite("");
    setEmail("");
    setPhoneNumber("");
    setBio("");
    setBusinessLogo("");
    setBannerImage("");
    setAddress("");
    setApartment("");
    setDistrict("");
    setCity("");
    setRegion("");
    setPostalCode("");
    setCountry("");
    setManager(null);
    setValue("");
  };

  return (
    <>
      <div className="bg-white w-[70%] p-5 space-y-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Manager</h1>
        <div className="relative flex items-end bg-[#F4F5F7] rounded-md w-full h-14">
          <input
            id="manager-search"
            placeholder="Search Manager"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onKeyDown={handleSearchKeyDown}
            onKeyUp={handleSearchKeyUp}
            autoComplete="new-password"
            className="w-full h-10 pl-4 font-medium placeholder-transparent transition duration-500 ease-in-out bg-transparent border-b-2 border-transparent text-gray1 focus:ring-0 peer focus:outline-none focus:border-b-blue caret-blue"
          />
          <label
            htmlFor="manager-search"
            className="absolute left-4 top-[5px] text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray2 peer-placeholder-shown:top-4 peer-focus:top-[5px] peer-focus:text-blue peer-focus:text-sm"
          >
            Manager
          </label>
          {searchResults.length > 0 && (
            <ul className="absolute z-50 w-full p-3 bg-white rounded-md shadow-lg top-16 h-[250px] overflow-y-scroll">
              {searchResults.map((user, idx) => {
                return (
                  <li
                    key={idx}
                    onClick={() => {
                      setSearch(
                        `${user.profile.first_name} ${user.profile.last_name}`
                      );
                      setManager(user);
                      setSearchResults([]);
                    }}
                    className="flex items-center p-2 space-x-3 cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                  >
                    <Avatar src={user.profile.picture} />
                    <span className="flex flex-col">
                      <p className="font-medium capitalize">
                        {user.profile.first_name} {user.profile.last_name}
                      </p>
                      <p className="font-medium">{user.email}</p>
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <div className="bg-white w-[70%] p-5 space-y-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">Business Details</h1>
        <div className="grid grid-cols-2 gap-5">
          <FormInput
            type="text"
            label="Business Name"
            placeholder="Business Name"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />

          <FormInput
            type="text"
            label="Website"
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />

          <FormInput
            type="text"
            label="Email Address"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="flex flex-row items-center space-x-1">
            <CustomSelect
              label="Country Code"
              name="country-codes"
              id="country-codes"
              value={countryCode}
              options={["+233", "+234"]}
              setValue={setCountryCode}
            />
            <FormInput
              type="text"
              label="Phone Number"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <FormInput
          id="Bio"
          type="textarea"
          label="Bio"
          placeholder="Bio"
          rows={4}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </div>

      <div className="bg-white w-[70%] p-5 space-y-5">
        <h1 className="mb-3 font-medium text-[#3D4356]">
          Address (Business Location)
        </h1>

        <div className="relative space-y-3">
          <FormInput
            type="text"
            label="Address"
            placeholder="Address"
            value={value}
            onChange={handleInput}
            // disabled={!ready}
          />

          {address && country && (
            <div className="bg-[#F4F4F6] rounded-md p-5 grid grid-cols-[10fr,1fr]">
              <div className="grid grid-cols-3 gap-7">
                <div>
                  <h1 className="text-sm text-gray2">Address</h1>
                  <p className="font-medium text-gray1">
                    {address || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>

                <div>
                  <h1 className="text-sm text-gray2">Apt./Suite etc</h1>
                  <p className="font-medium text-gray1">
                    {apartment || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>

                <div>
                  <h1 className="text-sm text-gray2">District</h1>
                  <p className="font-medium text-gray1">
                    {district || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>

                <div>
                  <h1 className="text-sm text-gray2">City</h1>
                  <p className="font-medium text-gray1">
                    {city || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>

                <div>
                  <h1 className="text-sm text-gray2">Region</h1>
                  <p className="font-medium text-gray1">
                    {region || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>

                <div>
                  <h1 className="text-sm text-gray2">Postal Code</h1>
                  <p className="font-medium text-gray1">
                    {postalCode || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>

                <div>
                  <h1 className="text-sm text-gray2">Country</h1>
                  <p className="font-medium text-gray1">
                    {country || (
                      <Button
                        className="p-0 text-base capitalize min-w-min text-blue hover:bg-transparent"
                        disableRipple
                        onClick={handleEditOpen}
                      >
                        +Add
                      </Button>
                    )}
                  </p>
                </div>
              </div>

              <div>
                <Button
                  className="capitalize text-blue"
                  onClick={handleEditOpen}
                >
                  Edit
                </Button>
              </div>
            </div>
          )}

          {data.length > 0 && (
            <ul className="absolute z-50 w-full p-3 bg-white rounded-md shadow-lg top-16">
              {data.map((suggestion, idx) => {
                return (
                  <li
                    key={idx}
                    onClick={() => onSelect(suggestion)}
                    className="flex items-center p-2 space-x-3 capitalize cursor-pointer text-gray1 hover:text-orange hover:bg-orange/10 hover:rounded-md group"
                  >
                    <img src={location} alt="" />
                    <p className="font-medium">{suggestion.description}</p>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      {isLoaded && address && coords && (
        <div className="bg-white w-[70%] p-5 space-y-5">
          <h1 className="mb-3 font-medium text-[#3D4356]">Map Location</h1>

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            <Marker position={coords}></Marker>
          </GoogleMap>
        </div>
      )}

      {loadError && <div>could not load map</div>}

      <div className="bg-white w-[70%] p-5 grid grid-cols-2 gap-10">
        <div>
          <h1 className="mb-3 font-medium text-[#3D4356]">Business Logo</h1>

          <div className="flex items-center space-x-5">
            {businessLogo ? (
              <div className=" w-60">
                <div className="relative border border-gray-300 border-dashed rounded-md">
                  <div className="absolute left-0 flex justify-center w-full bottom-10">
                    <ButtonGroup className="bg-white">
                      <Button
                        onClick={() => {
                          setBusinessLogo("");
                        }}
                      >
                        <img src={deleteIcon} alt="" />
                      </Button>
                      <Button>
                        <label
                          htmlFor="business-logo"
                          className="w-full h-full cursor-pointer"
                        >
                          <img src={editIcon} alt="" />
                          <input
                            type="file"
                            id="business-logo"
                            multiple
                            accept=".png,.jpeg,.jpg"
                            onChange={(e) => {
                              setBusinessLogo(e.target.files[0]);
                            }}
                            className="hidden"
                          />
                        </label>
                      </Button>
                    </ButtonGroup>
                  </div>

                  <img
                    src={URL.createObjectURL(businessLogo)}
                    alt=""
                    className="object-cover w-full h-full rounded-md"
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  setBusinessLogo(e.dataTransfer.files[0]);
                }}
              >
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                  <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                </div>
                <label
                  htmlFor="business-logo"
                  className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                >
                  <p>Upload File</p>
                  <input
                    type="file"
                    id="business-logo"
                    multiple
                    accept=".png,.jpeg,.jpg"
                    onChange={(e) => {
                      setBusinessLogo(e.target.files[0]);
                    }}
                    className="hidden"
                  />
                </label>

                <p className="text-sm text-gray2">or drop files to upload</p>
              </div>
            )}
          </div>
        </div>

        <div>
          <h1 className="mb-3 font-medium text-[#3D4356]">Banner Image</h1>

          <div className="flex items-center space-x-5">
            {bannerImage ? (
              <div className=" w-60">
                <div className="relative border border-gray-300 border-dashed rounded-md">
                  <div className="absolute left-0 flex justify-center w-full bottom-10">
                    <ButtonGroup className="bg-white">
                      <Button
                        onClick={() => {
                          setBannerImage("");
                        }}
                      >
                        <img src={deleteIcon} alt="" />
                      </Button>
                      <Button>
                        <label
                          htmlFor="banner-image"
                          className="w-full h-full cursor-pointer"
                        >
                          <img src={editIcon} alt="" />
                          <input
                            type="file"
                            id="banner-image"
                            multiple
                            accept=".png,.jpeg,.jpg"
                            onChange={(e) => {
                              setBannerImage(e.target.files[0]);
                            }}
                            className="hidden"
                          />
                        </label>
                      </Button>
                    </ButtonGroup>
                  </div>

                  <img
                    src={URL.createObjectURL(bannerImage)}
                    alt=""
                    className="object-cover w-full h-full rounded-md"
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  setBannerImage(e.dataTransfer.files[0]);
                }}
              >
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                  <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                </div>
                <label
                  htmlFor="banner-image"
                  className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                >
                  <p>Upload File</p>
                  <input
                    type="file"
                    id="banner-image"
                    multiple
                    accept=".png,.jpeg,.jpg"
                    onChange={(e) => {
                      setBannerImage(e.target.files[0]);
                    }}
                    className="hidden"
                  />
                </label>

                <p className="text-sm text-gray2">or drop files to upload</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[70%] flex justify-end items-center space-x-3">
        <Button
          variant="contained"
          className="capitalize bg-white shadow-none"
          onClick={() => setmodalOpen(true)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={`text-white capitalize shadow-none ${
            businessName === "" ||
            website === "" ||
            email === "" ||
            phoneNumber === "" ||
            bio === "" ||
            businessLogo === "" ||
            bannerImage === "" ||
            address === "" ||
            district === "" ||
            city === "" ||
            region === "" ||
            country === "" ||
            !manager
              ? "bg-orange/30 cursor-not-allowed"
              : "bg-orange cursor-pointer"
          }`}
          onClick={handleSubmit}
          disabled={
            businessName === "" ||
            website === "" ||
            email === "" ||
            phoneNumber === "" ||
            bio === "" ||
            businessLogo === "" ||
            bannerImage === "" ||
            address === "" ||
            district === "" ||
            city === "" ||
            region === "" ||
            country === "" ||
            !manager
          }
        >
          Save
        </Button>
      </div>

      <Dialog open={edit} onClose={handleEditClose}>
        <div className="bg-white divide-y w-[500px]">
          <div className="relative flex justify-center py-2 ">
            <p className="font-bold text-[20px] text-[#040A1D]">
              Edit Business Address
            </p>

            <IconButton
              className="absolute p-0 top-2 right-5"
              onClick={handleEditClose}
            >
              <IoClose className="w-7 h-7 text-gray2" />
            </IconButton>
          </div>

          <form className="p-5 space-y-2">
            <div>
              <div className="space-y-5">
                <FormInput
                  type="text"
                  label="Address"
                  placeholder="Address"
                  value={address}
                  onChange={handleInput}
                  disabled={!ready}
                />

                <FormInput
                  type="text"
                  label="Apt./Suite etc"
                  placeholder="Apt./Suite etc"
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                />

                <div className="grid grid-cols-2 gap-5">
                  <FormInput
                    type="text"
                    label="City"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />

                  <FormInput
                    type="text"
                    label="District"
                    placeholder="District"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  />

                  <FormInput
                    type="text"
                    label="Region"
                    placeholder="Region"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  />

                  <FormInput
                    type="text"
                    label="Country"
                    placeholder="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    disabled
                  />

                  <FormInput
                    type="text"
                    label="Postal Code"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end py-2 space-x-3">
              <Button
                variant="outlined"
                className="capitalize shadow-none"
                onClick={() => {
                  handleEditClose();
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                className={`text-white capitalize shadow-none bg-orange cursor-pointer`}
                onClick={handleEditClose}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog open={open} onClose={handleClose}>
        <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
          {loading ? (
            <>
              <img src={productsInactive} alt="" className="w-9 h-9" />
              <p className="font-medium capitalize text-gray2">
                Saving new retailer
              </p>
              <img src={loader} alt="" className="animate-spin" />
            </>
          ) : (
            <>
              <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                <img src={success} alt="" className="" />
              </span>

              <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                New Retailer Added Successfully
              </p>

              <div className="space-x-3">
                <Button
                  variant="contained"
                  className="capitalize bg-white border shadow-none"
                  onClick={() => {
                    handleClose();
                    clearInfo();
                    setRetailerId("");
                  }}
                >
                  Add Another Retailer
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  onClick={() => {
                    handleClose();
                    history.push("/retailers");
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>

      <UnsavedChanges isOpen={modalOpen} close={() => setmodalOpen(false)} />
    </>
  );
};

export default AddNewProductForm;
