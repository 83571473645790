import React from "react";

import { initials } from "../../utils/index";

const CustomerListItem = ({customer, onClick}) => {
  return (
    <div 
      onClick={() => onClick(customer)}
      className="flex flex-row items-center w-full rounded-md p-[10px] group hover:bg-[#F3641F] hover:bg-opacity-10 cursor-pointer mb-2">
      {customer.profile.picture?  (
        <img src={customer.profile.picture} alt="avatar" className="w-[35px] h-[35px] rounded-2xl" />
      ) : (
        <span className="bg-[#E3E7ED] w-[35px] h-[35px] flex justify-center items-center text-[#000000] text-[14px] font-bold rounded-2xl">
          {initials(customer.profile.first_name, customer.profile.last_name)}
        </span>
      )}
      <div className="flex flex-col ml-[10px]">
        <p className="text-[#040A1D] text-[16px] font-medium group-hover:text-[#F3641F]">{customer.profile.first_name} {customer.profile.last_name}</p>
        <p className="text-[#4C536A] text-[13px] group-hover:text-[#F3641F]">{customer.email}</p>
      </div>
    </div>
  )
}

export default CustomerListItem;