import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

//utils
import checkStrength from "../../utils/checkPasswordStrength";

//mui
import { LinearProgress } from "@material-ui/core";

//components
import AuthCard from "../../components/AuthCard/AuthCard";
import FormInput from "../../components/FormInput/FormInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

import { resetPassword } from "../../services/auth";

const NewPassword = () => {
  const history = useHistory();
  const { token } = useParams();

  const [reset, setReset] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState("");
  const [progress, setProgess] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const result = checkStrength(reset.newPassword);

    if (name === "newPassword") {
      if (value === "") {
        setProgess(0);
        setStrength("");
      } else {
        setStrength(result.strength);
        setProgess(result.progress);
      }
    }

    setReset({
      ...reset,
      [name]: value,
    });
  };

  const handleError = (error) => {
    if (typeof error.response.data === "object")
      setError(error.response.data.non_field_errors[0]);
    else setError("An unexpected error occurred. Please try again later");
  };

  const submit = async () => {
    const { newPassword, confirmNewPassword } = reset;

    setLoading(true);
    setError("");

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match, please try again.");
      setLoading(false);
      return;
    }

    const form = {
      token: token,
      new_password: newPassword,
    };

    try {
      await resetPassword(form);
      history.push(`/reset-password/reset-success`);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  return (
    <AuthCard>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-2xl font-bold text-center">Reset Password</h1>
      </div>

      <div className="w-[65%]">
        <form className="space-y-2">
          <div>
            <FormInput
              id="newPassword"
              name="newPassword"
              type="password"
              placeholder="New Password"
              label="New Password"
              onChange={handleChange}
              value={reset.newPassword}
            />

            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{
                root: "bg-[#E3E7ED] mt-px",
                bar: "bg-[#11CDEF]",
              }}
            />

            <div className="flex items-center justify-center text-[12px] text-gray-300">
              Password strength:
              <span className="pl-[5px] font-semibold text-gray1">
                {strength}
              </span>
            </div>
          </div>

          <ul className="text-[12px] text-gray2  w-[80%]">
            <li className="flex space-x-1">
              <span>&#8226;</span>
              <p>Use at least 8 characters.</p>
            </li>
            <li className="flex space-x-1">
              <span>&#8226;</span>
              <p className="">
                Besides letters, include at least a number or symbol
                (!@#$%^&*-_=+).
              </p>
            </li>
            <li className="flex space-x-1">
              <span>&#8226;</span>
              <p>Password is case sensitive.</p>
            </li>
          </ul>

          <FormInput
            id="confirmNewPassword"
            name="confirmNewPassword"
            type="password"
            placeholder="Confirm Password"
            label="Confirm Password"
            onChange={handleChange}
            value={reset.confirmNewPassword}
            error={error}
          />

          {error && <ErrorAlert error={error} />}
        </form>

        <CustomButton
          text="Continue"
          onClick={submit}
          disabled={
            reset.newPassword === "" ||
            reset.confirmNewPassword === "" ||
            reset.newPassword.length < 8
          }
          loading={loading}
        />

        <div className="flex items-center justify-center mt-2">
          <Link
            to="/login"
            className="font-bold text-[12px] underline text-blue"
          >
            Return to login
          </Link>
        </div>
      </div>
    </AuthCard>
  );
};

export default NewPassword;
