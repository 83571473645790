import React, { useState } from 'react'
import { Popover } from '@material-ui/core'

import roundArrow from '../../assets/round-arrow.svg'
import roundArrowWhite from '../../assets/round-arrow-white.svg'
import CustomDateRange from '../CustomDateRange/CustomDateRange'

import { datePeriods } from '../../enums'

const DateActions = ({
  activePeriod,
  changePeriod,
  align = 'right',
  className,
  onDateRangeSelect,
  dateRange,
}) => {
  const [range, setRange] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [highlightRange, setHighlightRange] = useState(false)
  const open = Boolean(anchorEl)
  const id = open ? 'calendar-popover' : undefined

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDateRangeChange = (data) => {
    setRange(data)
    onDateRangeSelect(data)
  }

  return (
    <div className={`flex items-center  bg-transparent ${className}`}>
      {datePeriods.map((p, index) => (
        <button
          key={index}
          onClick={() => {
            setHighlightRange(false)
            changePeriod(p.value)
          }}
          className={`rounded-md font-medium text-[14px] mr-3 ${
            !highlightRange && activePeriod === p.value
              ? `bg-[#F3641F] text-white border-0 w-[71px] h-[35px]`
              : `text-appGray border border-[#E3E7ED] bg-white w-[71px] h-[35px]`
          }`}
        >
          {p.label}
        </button>
      ))}
      <button
        aria-describedby={id}
        onClick={handlePopoverOpen}
        className={`flex flex-row items-center border h-[35px] rounded-md px-4 ${
          highlightRange
            ? 'bg-[#F3641F] border-[#F3641F] text-white'
            : 'border-[#E3E7ED] bg-white text-[#9CA7B8]'
        }`}
      >
        <p className="text-[14px] font-medium mr-3 mb-0">
          {dateRange.start.format('ll')}
        </p>
        <img
          src={highlightRange ? roundArrowWhite : roundArrow}
          alt="round arrow"
          className="w-[15px] h-[15px]"
        />
        <p className="text-[14px] font-medium ml-3 mb-0">
          {dateRange.end.format('ll')}
        </p>
      </button>
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <CustomDateRange
          onSelect={(e) => {
            setHighlightRange(true)
            handleDateRangeChange(e)
          }}
          dates={range}
        />
      </Popover>
    </div>
  )
}

export default DateActions
