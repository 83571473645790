import React from "react";
import { Modal } from "@material-ui/core";
import { useHistory } from "react-router";

import closeIcon from "../../assets/close.svg";

const UnsavedChanges = ({isOpen, close}) => {
  const history = useHistory();

  return (
    <Modal
      open={isOpen}
      onClose={close}
      className="flex flex-row items-center justify-center overflow-y-scroll focus:ring-transparent focus:outline-none focus:border-0"
    >
      <div
        className="w-[404px] h-[309px] bg-[#F4F5F7] rounded flex flex-col py-[20px] relative items-center"
      >
        <h1 className="text-center text-[20px] font-bold text-[#040A1D]">Unsaved Changes</h1>
        <button className="absolute top-[25px] right-[20.48px]" onClick={close}>
          <img src={closeIcon} alt="close" className="w-[24px] h-[24px]" />
        </button>
        <div className="bg-[#E3E7ED] h-[1px] w-full my-[20px]"></div>
        <div className="flex flex-col mt-[30px]">
          <p className="w-[239px] text-[#3D4356] text-[16px] text-center">If you leave this page any unsaved changes will be lost.</p>
          <p className="w-[239px] text-[#3D4356] text-[16px] font-bold text-center">Are you sure you want to leave?</p>
        </div>
        <div className="flex flex-row items-center mt-[48px]">
          <button 
            onClick={close}
            className="w-[83px] h-[43px] bg-white rounded text-[#3D4356] text-[16px] mr-2 font-medium">
            Cancel
          </button>
          <button 
            onClick={() => history.goBack()}
            className="w-[116px] h-[43px] bg-[#FF3D22] rounded text-white text-[16px] ml-2 font-medium">
            Leave Page
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default UnsavedChanges;