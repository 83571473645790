import { makeAutoObservable, observable, runInAction } from "mobx";
import Cookies from "js-cookie";
import { getAllUsers, createNewUser } from "../services/users";

class Users {
  rootStore;
  loading = true;
  users = [];

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      loading: observable,
      users: observable,
    });

    this.rootStore = rootStore;
  }

  getUsers = async () => {
    const token = this.getToken();

    runInAction(() => {
      this.loading = true;
    });

    if (token) {
      try {
        const response = await getAllUsers(token);
        this.users = response.data.results;

        runInAction(() => {
          this.loading = false;
        });
      } catch (error) {
        runInAction(() => {
          this.loading = false;
        });

        throw error;
      }
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  createUser = async (data) => {
    try {
      await createNewUser(data);
    } catch (error) {
      throw error;
    }
  };

  getToken() {
    return Cookies.get("auth_token");
  }
}

export default Users;
