import React from 'react'
import { preventNonNumericalInput } from '../../utils'

import closeIcon from '../../assets/close.svg'
import caretUp from '../../assets/caret-up.svg'
import caretDown from '../../assets/caret-down.svg'
import productIcon from '../../assets/products-inactive.svg'
import styles from './orderRow.module.css'

const OrderRow = ({
  product,
  maxQuantity,
  onPriceChange,
  setQuantity,
  removeItem,
  hook,
  value,
}) => {
  function handlePriceChange(quantity) {
    quantity =
      quantity > maxQuantity ? maxQuantity : quantity < 0 ? 0 : quantity
    setQuantity(hook, value, quantity, product.id)
  }

  return (
    <tr className={`${styles} bg-white rounded shadow-sm`}>
      <td className="py-1">
        <div className="flex flex-row items-center h-full pl-2">
          {product.product.image ? (
            <img
              src={product.product.image}
              alt="product"
              className="w-[33px] h-[33px]"
            />
          ) : (
            <img
              src={productIcon}
              alt="product"
              className="w-[33px] h-[33px]"
            />
          )}
          <p className="text-[#4C536A] text-[14px] font-medium ml-4 w-[220px] overflow-ellipsis mb-0">
            {product.product.name}
            <br />
            <small>{product.attribute.name}</small>
            <span className="bg-[#4C536A] bg-opacity-10 text-[12px] rounded-md py-1 px-2 ml-2">
              Available: {maxQuantity}
            </span>
          </p>
        </div>
      </td>
      <td>
        {onPriceChange ? (
          <div className="bg-[#F4F5F7] rounded-md flex items-center px-2 min-w-0 mr-4">
            <span>₦</span>
            <input
              type="text"
              value={product.selling_price.amount}
              onKeyPress={preventNonNumericalInput}
              className="w-full h-full bg-transparent border-0 min-w-0"
              onChange={(e) =>
                onPriceChange(hook, value, product.id, e.target.value)
              }
            />
          </div>
        ) : product.selling_price ? (
          <p className="text-[#4C536A] text-[14px] font-medium mb-0">
            ₦{Number(product.selling_price.amount).toLocaleString('en')}
          </p>
        ) : (
          <p className="text-[#4C536A] text-[14px] font-medium mb-0">
            ₦{Number(product.total_price.amount).toLocaleString('en')}
          </p>
        )}
      </td>
      <td>
        <div className="flex flex-row items-center bg-[#F4F5F7] rounded-md min-w-[106px] max-w-[136px] h-[44px] pr-2 my-2">
          <input
            type="number"
            min="0"
            className="w-full px-2 bg-transparent border-0 shadow-none outline-none focus:outline-none focus:border-0 focus:ring-transparent"
            onKeyPress={preventNonNumericalInput}
            value={product.quantity}
            onChange={(e) => handlePriceChange(+e.target.value)}
          />
          <span className="flex flex-col">
            <button
              onClick={() => handlePriceChange(product.quantity + 1)}
              className="bg-[#E3E7ED] rounded-md flex justify-center items-center w-[28px] h-[15px] mb-1"
            >
              <img src={caretUp} alt="up arrow" className="w-[10px] h-[10px]" />
            </button>
            <button
              onClick={() => handlePriceChange(product.quantity - 1)}
              className="bg-[#E3E7ED] rounded-md flex justify-center items-center w-[28px] h-[15px]"
            >
              <img
                src={caretDown}
                alt="down arrow"
                className="w-[10px] h-[10px]"
              />
            </button>
          </span>
        </div>
      </td>
      <td align="right">
        {product.selling_price ? (
          <p className="text-[#151515] text-[16px] font-medium mb-0">
            ₦
            {Number(
              product.selling_price.amount * product.quantity,
            ).toLocaleString('en')}
          </p>
        ) : (
          <p className="text-[#151515] text-[16px] font-medium mb-0">
            ₦
            {Number(
              product.total_price.amount * product.quantity,
            ).toLocaleString('en')}
          </p>
        )}
      </td>
      <td>
        <button
          onClick={() => removeItem(hook, value, product.id)}
          className="mt-2 ml-3 mr-2"
        >
          <img src={closeIcon} alt="close" className="w-[20px] h-[20px]" />
        </button>
      </td>
    </tr>
  )
}

export default OrderRow
