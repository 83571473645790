import React from 'react'

import UpdateOrderRow from '../UpdateOrderRow/UpdateOrderRow'
import OrderRow from '../OrderRow/OrderRow'

const UpdateOrderTable = ({
  existingProducts,
  newProducts,
  setQuantity,
  onPriceChange,
  onIncrease,
  productHook,
  newProductHook,
  onDecrease,
  removeItem,
}) => {
  return (
    <div className="w-[830px] mt-[30px]">
      <table width="100%">
        <tr className="h-[40px]">
          <th className="font-bold text-[#4C536A] text-[12px] text-left">
            ITEM DETAILS
          </th>
          <th className="font-bold text-[#4C536A] text-[12px] text-left">
            UNIT PRICE
          </th>
          <th className="font-bold text-[#4C536A] text-[12px] text-left">
            QUANTITY
          </th>
          <th align="right" className="font-bold text-[#4C536A] text-[12px]">
            SUB TOTAL
          </th>
          <th></th>
        </tr>
        {existingProducts &&
          existingProducts.map((item, index) => (
            <>
              <UpdateOrderRow
                product={item}
                key={index}
                onPriceChange={onPriceChange}
                setQuantity={setQuantity}
                onIncrease={onIncrease}
                onDecrease={onDecrease}
                removeItem={removeItem}
                hook={productHook}
                value={existingProducts}
                maxQuantity={item.stock_available}
              />
              <div className="h-1"></div>
            </>
          ))}
        {newProducts &&
          newProducts.map((item, index) => (
            <>
              <OrderRow
                product={item}
                key={index}
                onPriceChange={onPriceChange}
                hook={newProductHook}
                value={newProducts}
                setQuantity={setQuantity}
                onIncrease={onIncrease}
                onDecrease={onDecrease}
                removeItem={removeItem}
                maxQuantity={item.stock_count}
              />
              <div className="h-1"></div>
            </>
          ))}
      </table>
    </div>
  )
}

export default UpdateOrderTable
