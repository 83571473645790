import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#F3641F",
    },
    secondary: {
      main: "#F3641F"
    }
  },
});
