import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { toast } from 'react-toastify'

import Cookies from 'js-cookie'

//assets
import orders from '../../assets/orders-inactive.svg'
import coloredOrders from '../../assets/colored-orders.svg'
import plus from '../../assets/plus.svg'

//mui
import { Button } from '@material-ui/core'

//components
import Layout from '../../components/Layout/Layout'
import SummaryCard from '../../components/SummaryCard/SummaryCard'
import EmptyTableView from '../../components/EmptyTableVIew/EmptyTableView'
import TableActions from '../../components/TableActions/TableActions'
import DateActions from '../../components/DateActions/DateActions'
import PageTitle from '../../components/PageTitle/PageTitle'
import OrdersTable from '../../components/OrdersTable/OrdersTable'
import ReceiptModal from '../../components/ReceiptModal/ReceiptModal'
import Loader from '@components/Loader/Loader'

// services
import { getOrder, getOrders } from '../../services/orders.js'

//store
import { RootContext } from '../..'

//utils
import { exportData, formatDate, formatTime } from '../../utils/index'

const Index = observer(() => {
  const root = useContext(RootContext)
  const location = new URLSearchParams(window.location.search)
  const orderId = location.get('order')

  const [tableData, setTableData] = useState([])
  const [activeTab, setActiveTab] = useState(
    Cookies.get('active-order-tab') ?? 'All',
  )
  const [search, setSearch] = useState('')
  const [notFound, setNotFound] = useState(false)
  const [activePeriod, setActivePeriod] = useState(
    Cookies.get('active-order-period') ?? 'today',
  )
  const [busy, setBusy] = useState(true)
  const [dateRange, setDateRange] = useState({
    start: moment(),
    end: moment(),
  })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [showReceipt, setShowReceipt] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState({
    type: 'period',
    value: 'today',
  })
  const [controller, setController] = useState(null)

  const history = useHistory()

  const openCreateOrder = () => {
    history.push('/orders/create-order')
  }

  const openUpdateOrder = (order) => {
    history.push(`/orders/update-order/${order.uuid}`)
  }

  const fetchOrder = async () => {
    setBusy(true)
    setNotFound(false)

    try {
      const { data } = await getOrder(orderId)

      setShowReceipt(data)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch orders. Please try again later.')
        setBusy(false)
      }
    }
  }

  const fetchOrders = async () => {
    controller?.abort?.()
    const abc = new AbortController()
    setController(abc)

    setBusy(true)
    setNotFound(false)

    try {
      const { data } = await getOrders(
        root.authStore.user.retailerUuid,
        {
          search,
          page: currentPage,
          page_size: rowsPerPage,
          ...(activeTab === 'Draft' ? {} : { ordered: true }),
          ...(activeTab !== 'All'
            ? { status: activeTab === 'Draft' ? 'Drafting' : activeTab }
            : {}),
          ...(activeTab === 'Draft'
            ? {
                o_start_date: dateRange.start.format('YYYY-MM-DD'),
                o_end_date: dateRange.end.format('YYYY-MM-DD'),
              }
            : selectedFilter.type === 'range'
            ? {
                end_date: dateRange.end.format('YYYY-MM-DD'),
                start_date: dateRange.start.format('YYYY-MM-DD'),
              }
            : {
                payment_day: activePeriod,
              }),
        },
        abc.signal,
      )

      setNotFound(!data.results.length)
      setTableData(data.results)
      setCount(data.count)
      setBusy(false)
    } catch (error) {
      if (error.name !== 'CanceledError') {
        toast.error('Unable to fetch orders. Please try again later.')
        setBusy(false)
      }
    }
  }

  const sanitizeData = async () => {
    toast.info('Fetching data to export')

    try {
      const res = await getOrders(root.authStore.user.retailerUuid, {
        search,
        ...(activeTab === 'Draft' ? {} : { ordered: true }),
        ...(activeTab !== 'All'
          ? { status: activeTab === 'Draft' ? 'Drafting' : activeTab }
          : {}),
        ...(activeTab === 'Draft'
          ? {
              o_start_date: dateRange.start.format('YYYY-MM-DD'),
              o_end_date: dateRange.end.format('YYYY-MM-DD'),
            }
          : selectedFilter.type === 'range'
          ? {
              end_date: dateRange.end.format('YYYY-MM-DD'),
              start_date: dateRange.start.format('YYYY-MM-DD'),
            }
          : {
              payment_day: activePeriod,
            }),
        paginate: 'no',
      })
      exportData(
        res.data.map((entry) => ({
          'Invoice ID': entry.order_id,
          Customer: `${entry.user.profile.first_name} ${entry.user.profile.last_name}`,
          'Phone Number': entry.user.phone_number || '',
          'Date Created': formatDate(entry.created_at),
          'Payment Date': entry.payment_date
            ? formatDate(entry.payment_date)
            : '',
          'Payment Time': entry.payment_date
            ? formatTime(entry.payment_date)
            : '',
          Status: entry.status,
          'Delivery Address': entry.delivery_address?.address,
          'Delivery Date': formatDate(entry.delivery_date),
          'Delivery Time': entry.delivery_time || '',
          'Delivery Fee': `${entry.delivery_fee?.currency} ${entry.delivery_fee?.amount}`,
          Tax: entry.tax,
          Amount: `${entry.final_cost?.currency} ${entry.final_cost?.amount}`,
        })),
        'Orders',
      )
    } catch (error) {
      toast.error('Unable to export requested data. Please try again later')
      setBusy(false)
    } finally {
      toast.dismiss()
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [search, activePeriod, dateRange, activeTab, currentPage, rowsPerPage])

  useEffect(() => {
    root.ordersStore.getSummaryCardStats(root.authStore.user.retailerUuid)
  }, [])

  const handleTabChange = (tab) => {
    Cookies.set('active-order-tab', tab)
    setCurrentPage(1)
    setActiveTab(tab)
  }

  const handleActivePeriodChange = (period) => {
    Cookies.set('active-order-period', period)
    setSelectedFilter({ type: 'period', value: period })
    setCurrentPage(1)
    setActivePeriod(period)
  }

  useEffect(() => {
    if (orderId) {
      fetchOrder()
    }
  }, [])

  return (
    <Layout>
      <div className="px-[30px] pt-5 font-bold">
        <PageTitle title="Orders" />
        <div className="flex flex-row items-center w-full mb-4">
          <SummaryCard
            label={'Total Orders'}
            amount={root.ordersStore.total.total}
            changeType={'rise'}
            changeValue={20}
            summaryValue={root.ordersStore.total.orders}
          />
          <div className="w-12"></div>
          <SummaryCard
            label={'Completed'}
            amount={root.ordersStore.completed.total}
            changeType={'drop'}
            changeValue={20}
            summaryValue={root.ordersStore.completed.orders}
            accentColor={'bg-appGreen'}
          />
          <div className="w-12"></div>
          <SummaryCard
            label={'Pending'}
            amount={root.ordersStore.pending.total}
            changeType={'rise'}
            changeValue={20}
            summaryValue={root.ordersStore.pending.orders}
            accentColor={'bg-appBlue'}
          />
        </div>
        <div className="flex flex-row items-center w-full">
          <SummaryCard
            label={'On Delivery'}
            amount={root.ordersStore.delivery.total}
            changeType={'rise'}
            changeValue={20}
            summaryValue={root.ordersStore.delivery.orders}
            accentColor={'bg-secondary'}
          />
          <div className="w-12"></div>
          <SummaryCard
            label={'Ready for pickup'}
            amount={root.ordersStore.pickup.total}
            changeType={'rise'}
            changeValue={20}
            summaryValue={root.ordersStore.pickup.orders}
            accentColor={'bg-accent-orange'}
          />
          <div className="w-12"></div>
          <SummaryCard
            label={'Processing'}
            amount={root.ordersStore.processing.total}
            changeType={'rise'}
            changeValue={20}
            summaryValue={root.ordersStore.processing.orders}
            accentColor={'bg-accent-yellow'}
          />
          <div className="w-12"></div>
          <SummaryCard
            label={'Cancelled'}
            amount={root.ordersStore.cancelled.total}
            changeType={'rise'}
            changeValue={20}
            summaryValue={root.ordersStore.cancelled.orders}
            accentColor={'bg-appRed'}
          />
        </div>
        <TableActions
          activeTab={activeTab}
          changeTab={handleTabChange}
          onSearchChange={(e) => {
            setCurrentPage(1)
            setSearch(e.target.value.replace(/^#/, ''))
          }}
          searchPlaceholder={'Search orders'}
          tabLabels={[
            'All',
            'Completed',
            'Pending',
            'Processing',
            'Ready for Pickup',
            'On Delivery',
            'Cancelled',
            'Draft',
          ]}
          exportData={sanitizeData}
        />

        <DateActions
          activePeriod={
            selectedFilter.type === 'period' ? activePeriod : undefined
          }
          changePeriod={handleActivePeriodChange}
          className="justify-end w-full mt-4"
          dateRange={dateRange}
          onDateRangeSelect={(range) => {
            setSelectedFilter({ type: 'range', value: range })
            setCurrentPage(1)
            setDateRange(range)
          }}
        />

        <div className="flex flex-row justify-end w-full mt-4">
          <Button
            variant="contained"
            color="primary"
            className="capitalize shadow-none"
            startIcon={<img src={plus} alt="plus" className="" />}
            onClick={openCreateOrder}
          >
            Add New Order
          </Button>
        </div>
        {busy && search.length === 0 ? (
          <Loader />
        ) : tableData.length === 0 && search.length === 0 ? (
          <EmptyTableView
            title={'Your orders will show here'}
            subtitle={
              'This is where you’ll fulfill orders, collect payments, and track order progress.'
            }
            btnLabel={'Create an Order'}
            icon={
              root.authStore.user.account_type.includes('Admin')
                ? orders
                : coloredOrders
            }
            onClick={openCreateOrder}
          />
        ) : (
          <OrdersTable
            rows={tableData}
            onEditClick={openUpdateOrder}
            onViewClick={setShowReceipt}
            search={search}
            busy={busy}
            notFound={notFound}
            onPageChange={(n) => {
              setCurrentPage(n)
            }}
            onRowsPerPageChange={(rows) => {
              setCurrentPage(1)
              setRowsPerPage(rows)
            }}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
          />
        )}
      </div>
      {showReceipt ? (
        <ReceiptModal
          isOpen={true}
          closeModal={() => setShowReceipt(null)}
          data={showReceipt}
        />
      ) : null}
    </Layout>
  )
})

export default Index
