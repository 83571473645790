import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import TabButton from "../../components/TabButton/TabButton";

import Loader from "@components/Loader/Loader";

//services
import { getSingleBrand, getSingleBrandProducts } from "../../services/brands";
import BrandProfile from "../../components/BrandProfile/BrandProfile";

const SingleBrand = () => {
  const params = useParams();

  const history = useHistory();

  const [busy, setBusy] = useState(true);
  const [brand, setBrand] = useState();
  const [brandProducts, setBrandProducts] = useState([]);

  const [activeTab, setActiveTab] = useState("Brand Profile");

  const fetchBrand = async () => {
    try {
      await getSingleBrand(params.brand).then(async (res) => {
        setBrand(res.data);
        const req = await getSingleBrandProducts(res.data.uuid);
        setBrandProducts(req.data.results);
      });
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch retailer data.");
      history.goBack();
    }
  };

  useEffect(() => {
    fetchBrand();
  }, []);

  return (
    <Layout>
      <div className="px-[30px] py-5">
        <PageTitle
          onBackPress={() => history.goBack()}
          title={brand ? brand.name : ""}
        />

        <div className="flex justify-between px-3 bg-white rounded-md">
          <div className="space-x-3">
            <TabButton
              activeTab={activeTab}
              onClick={() => setActiveTab("Business Profile")}
              title="Brand Profile"
            />
          </div>
        </div>

        {busy && !brand ? (
          <Loader />
        ) : (
          <div className="mt-5">
            {activeTab === "Brand Profile" && (
              <BrandProfile brand={brand} brandProducts={brandProducts} />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SingleBrand;
