import { useState, useEffect } from "react";

import Slider from "react-slick";
import { toast } from "react-toastify";
import { Button, Checkbox } from "@material-ui/core";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import FormInput from "../../components/FormInput/FormInput";
import ImageInput from "../../components/ImageInput";
import Loader from "@components/Loader/Loader";

//assets
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import upArrow from "../../assets/up-arrow.svg";

import { webslider } from "../../services/design";

const getFileURL = (file) => {
  if (file) {
    const url = window.webkitURL || window.URL;
    return url.createObjectURL(file);
  }
  return null;
};

function SlideShow() {
  const sliderSettings = {
    dots: true,
    dotsClass: "custom-slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
  };
  const dimensions = {
    regular: ["1440", "428"],
    ad: ["1280", "190"],
    mobileBanner: ["352", "176"],
  };

  const [busy, setBusy] = useState(false);
  const [pageBusy, setPageBusy] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [sequenceChanged, setSequenceChanged] = useState(false);
  const [activeSliderType, setActiveSliderType] = useState("desktop");

  let preview = slides[activeSlide === slides.length ? 0 : activeSlide] || {};

  const disabled = !(
    (
      preview.image &&
      // && preview.title
      // && preview.description
      // && preview.button_text
      preview.button_link
    )
    // && preview.sequence
  );

  // fetch web sliders from backend
  useEffect(() => {
    if (!slides.length) {
      setPageBusy(true);
      webslider
        .getWebSliders()
        .then((res) => setSlides(res.data.results))
        .catch(() => toast.error("Failed fetch web sliders."))
        .finally(() => setPageBusy(false));
    }
  }, []);

  const handleFileUpload = (files) => {
    const newSlides = [
      ...slides,
      {
        file_d: files[0],
        file_m: files[1],
        image: getFileURL(files[0]),
        title: "",
        description: files[0].name,
        is_ad: false,
        button_text: "",
        button_link: "",
        mobile_image: getFileURL(files[1]),
      },
    ];

    setSlides(newSlides);
    setActiveSlide(newSlides.length - 1);
  };

  const handleFieldChange = (name) => {
    return ({ target }) => {
      const tempSlides = [...slides];
      const item = tempSlides[activeSlide] || {};
      item[name] = target.value;
      setSlides(tempSlides);
    };
  };

  const handleUpdateBannerType = (file, index, type, action = "edit") => {
    if (action === "edit") {
      const tempSlides = [...slides];
      if (type.toLowerCase() === "desktop") {
        tempSlides[index].file_d = file;
        tempSlides[index].image = getFileURL(file);
      } else {
        tempSlides[index].file_m = file;
        tempSlides[index].mobile_image = getFileURL(file);
      }
      setSlides(tempSlides);
    }
  };

  const saveSlider = async () => {
    setBusy(true);

    try {
      toast.info(
        `${preview.id ? "Updating" : "Creating"} ${
          !preview.is_ad ? "web slider" : "ad"
        }...`
      );
      const formData = new FormData();
      if (preview.file_d) {
        formData.set("image", preview.file_d, preview.file_d.name);
      }
      if (preview.file_m) {
        formData.set("mobile_image", preview.file_m, preview.file_m.name);
      }
      formData.set("title", preview.title);
      formData.set("description", preview.description);
      formData.set("button_text", preview.button_text);
      formData.set("button_link", preview.button_link);
      formData.set("is_ad", preview.is_ad);
      formData.set("sequence", activeSlide + 1);

      const slider = preview.id
        ? await webslider.updateWebSlider(preview.id, formData)
        : await webslider.createWebSlider(formData);

      const updatedSlides = [...slides];
      updatedSlides[activeSlide] = slider.data;
      setSlides(updatedSlides);
      toast.success(
        `${!preview.is_ad ? "Web slider" : "Ad"} "${preview.title}" ${
          preview.id ? "updated" : "created"
        } successfully.`
      );
    } catch (err) {
      toast.error(err.message || "Failed to save web slider");
    } finally {
      setBusy(false);
    }
  };

  const deleteSlider = async (index) => {
    try {
      const { id, title, is_ad } = slides[index];
      if (id) {
        toast.info(`Deleting ${!is_ad ? "web slider" : "ad"}...`);
        await webslider.deleteWebSlider(id);
      }
      const newSlides = slides.filter((_, i) => i !== index);
      const active =
        activeSlide === index
          ? index - 1 < 0
            ? 0
            : index - 1
          : activeSlide - 1;

      newSlides[active] = {
        title: "",
        description: "",
        button_text: "",
        sequence: null,
        is_ad: false,
        button_link: "",
        ...newSlides[active],
      };
      setSlides(newSlides);
      setActiveSlide(active);

      if (id) {
        toast.success(
          `${!is_ad ? "Web slider" : "Ad"} "${title}" deleted successfully.`
        );
      }
    } catch (err) {
      toast.error(err.message || "Failed to delete web slider.");
    } finally {
    }
  };

  const moveSlide = (index, d = "<") => {
    const tempSlides = [...slides];
    const n = index + (d === ">" ? 1 : -1);
    [tempSlides[n], tempSlides[index]] = [tempSlides[index], tempSlides[n]];
    const active = activeSlide === index ? n : activeSlide;
    setSlides(tempSlides);
    setActiveSlide(active);
    setSequenceChanged(true);
  };

  const updateSequence = async () => {
    toast.info("Updating sliders sequence...");
    try {
      let i = 0;
      const fd = new FormData();
      for (const item of slides) {
        if (item.id) {
          fd.set("sequence", i);
          await webslider.updateWebSlider(item.id, fd);
        }
        i++;
      }
      setSequenceChanged(false);
      toast.success("Sequence updated successfully.");
    } catch (err) {
      setSequenceChanged(true);
      toast.error(err.message || "Failed to update sequence");
    }
  };

  return (
    <Layout>
      <div className="w-[950px] mx-auto pb-[100px]">
        <PageTitle title="Design" subtitle="Slide Show" />
        {pageBusy ? (
          <Loader />
        ) : (
          <>
            <p className="text-[#040A1D] text-[16px] font-semibold">
              Slide Show Preview
            </p>
            {preview.image ? (
              <div className="w-full">
                <Slider {...sliderSettings}>
                  <div>
                    <img
                      src={
                        activeSliderType.toLowerCase() === "desktop"
                          ? preview.image
                          : preview.mobile_image
                      }
                      alt={preview.description || "slider image"}
                      className="w-full object-cover"
                      style={{
                        aspectRatio: preview.is_ad
                          ? dimensions["ad"].join("/")
                          : dimensions[
                              activeSliderType.toLowerCase() === "desktop"
                                ? "regular"
                                : "mobileBanner"
                            ].join("/"),
                        objectPosition: "left top",
                      }}
                    />
                  </div>
                </Slider>
              </div>
            ) : (
              <p>Preview of selected image will appear here</p>
            )}

            <div className="mt-10">
              <p className="text-[#4C536A] text-[14px] mb-1">
                Desktop Banner:&nbsp;
                <span className="ml-2 font-bold">
                  {dimensions.regular.join(" x ")}
                </span>{" "}
                pixels
              </p>
              <p className="text-[#4C536A] text-[14px] mb-1">
                Mobile Banner:&nbsp;
                <span className="ml-2 font-bold">
                  {dimensions.mobileBanner.join(" x ")}
                </span>{" "}
                pixels
              </p>
              <p className="text-[#4C536A] text-[14px]">
                Ad banner:&nbsp;
                <span className="ml-2 font-bold">
                  {dimensions.ad.join(" x ")}
                </span>{" "}
                pixels
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-white rounded-tl-md rounded-tr-md shadow-sm overflow-x-auto">
              <div className="flex border-b-[4px] flex-row items-start space-x-4 border-b-orange py-[25px] px-[30px]">
                <Banner
                  activeSlide={activeSlide}
                  type="Mobile"
                  slides={slides}
                  image={slides[activeSlide]?.mobile_image}
                  handleUpdateBannerType={handleUpdateBannerType}
                  setActiveSliderType={setActiveSliderType}
                  activeSliderType={activeSliderType}
                />
                <Banner
                  activeSlide={activeSlide}
                  type="Desktop"
                  slides={slides}
                  image={slides[activeSlide]?.image}
                  handleUpdateBannerType={handleUpdateBannerType}
                  setActiveSliderType={setActiveSliderType}
                  activeSliderType={activeSliderType}
                />
              </div>
              <div className="flex flex-row items-start space-x-4 py-[10px] px-[30px] overflow-x-auto mb-3">
                {slides.map((item, index) => (
                  <div className="w-[171.2px] flex-shrink-0" key={index}>
                    <div
                      className={`rounded-md h-[171.2px] relative overflow-hidden
                      ${activeSlide === index && "outline-orange"} cursor-move`}
                      id={`slide-${index}`}
                    >
                      {item.is_ad ? (
                        <span className="absolute top-0 right-0 px-2 py-1 rounded-bl-md text-[12px] font-medium bg-orange text-white">
                          Ad
                        </span>
                      ) : null}
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-full h-full rounded object-cover"
                        key={item.image}
                        id={index}
                        onClick={() => {
                          setActiveSliderType("desktop");
                          setActiveSlide(index);
                        }}
                      />
                      <div className="absolute left-0 right-0 flex flex-row items-center mx-auto bg-white rounded-md bottom-4 w-[fit-content]">
                        <button
                          onClick={() => deleteSlider(index)}
                          className="p-2 border-r"
                        >
                          <img
                            src={deleteIcon}
                            alt="delete icon"
                            className="w-[15.03px] h-[15.33px]"
                          />
                        </button>
                        <button className="p-2 relative overflow-hidden">
                          <ImageInput
                            onChange={(file) => {
                              const tempSlides = [...slides];
                              tempSlides[index].image = getFileURL(file);
                              tempSlides[index].file_d = file;
                              setSlides(tempSlides);
                            }}
                            title=""
                          />
                          <img
                            src={editIcon}
                            alt="edit icon"
                            className="w-[15.03px] h-[15.33px]"
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      className="flex items-center justify-center w-full mt-3"
                      style={{ top: "calc(50% - 1.5rem / 2)" }}
                    >
                      <AiFillLeftCircle
                        size="1.5rem"
                        color="rgb(226, 109, 54)"
                        className={`${
                          !index
                            ? "opacity-10 cursor-not-allowed"
                            : "cursor-pointer"
                        } mx-1`}
                        onClick={() => {
                          if (index) {
                            moveSlide(index);
                          }
                        }}
                      />
                      <AiFillRightCircle
                        size="1.5rem"
                        color="rgb(226, 109, 54)"
                        onClick={() => {
                          if (index < slides.length - 1) {
                            moveSlide(index, ">");
                          }
                        }}
                        className={`${
                          index === slides.length - 1
                            ? "opacity-10  cursor-not-allowed"
                            : "cursor-pointer"
                        } mx-1`}
                      />
                    </div>
                  </div>
                ))}
                <div className="h-[171.2px] w-[171.2px] rounded-md relative flex-shrink-0 flex flex-col items-center justify-center border border-dashed">
                  <ImageInput
                    onChange={handleFileUpload}
                    title="Click to upload"
                    multiple={true}
                  />
                  <img
                    src={upArrow}
                    alt=""
                    className="w-[41.67px] h-[41.36px]"
                  />
                  <button className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mt-3 font-medium">
                    Add Slide
                  </button>
                  <p className="text-[#4C536A] text-[14px] mt-3">
                    or drop file to upload
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-b border-t-[4px] border-t-orange px-[140px] py-[51px]">
              <div className="px-5 bg-[#F4F5F7] rounded-md">
                <label>Ad</label>
                <Checkbox
                  checked={preview.is_ad}
                  onClick={() =>
                    handleFieldChange("is_ad")({
                      target: { value: !preview.is_ad },
                    })
                  }
                />
              </div>
              <div className="h-4" />
              <FormInput
                id="title"
                name="title"
                label="Title"
                placeholder="Title"
                value={preview.title}
                onChange={handleFieldChange("title")}
              />
              <div className="h-4" />
              <FormInput
                id="description"
                name="description"
                label="Description (Alt Text)"
                placeholder="Description (Alt Text)"
                type="textarea"
                rows={4}
                value={preview.description}
                onChange={handleFieldChange("description")}
              />
              <div className="h-4" />
              <FormInput
                id="btn-text"
                name="btn-text"
                label="Button Text"
                placeholder="Button Text"
                value={preview.button_text}
                onChange={handleFieldChange("button_text")}
              />
              <div className="h-4" />
              <FormInput
                id="btn-link"
                type="url"
                name="btn-link"
                label="URL"
                placeholder="URL"
                value={preview.button_link}
                onChange={handleFieldChange("button_link")}
              />
              <div className="h-4" />
              <div className="flex flex-row items-center justify-end space-x-4 mt-[30px]">
                <Button
                  variant="outlined"
                  className="capitalize bg-white text-[16px] text-[#4C536A] font-semibold"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  className={`capitalize ${
                    busy || disabled ? "bg-orange/20" : "bg-orange"
                  }
                  text-white font-semibold text-[16px]`}
                  disabled={disabled}
                  onClick={saveSlider}
                >
                  {preview.id ? "Update" : "Save"}
                </Button>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end space-x-4 mt-[30px]">
              {/*
              <Button
                variant="outlined"
                className='capitalize bg-white text-[16px] text-[#4C536A] font-semibold'
              >Cancel</Button>
            */}
              <Button
                variant="contained"
                disableElevation
                className={`capitalize ${
                  !sequenceChanged ? "bg-orange/20" : "bg-orange"
                }
                text-white font-semibold text-[16px]`}
                onClick={updateSequence}
              >
                Save sequence
              </Button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default SlideShow;

const Banner = ({
  activeSlide,
  type,
  image,
  handleUpdateBannerType,
  setActiveSliderType,
  activeSliderType,
}) => {
  return (
    <div className="w-[171.2px] flex-shrink-0">
      <div
        className={`rounded-t-md h-[165px] relative overflow-hidden
                      ${
                        activeSliderType === type.toLowerCase() &&
                        "outline-orange"
                      } cursor-move`}
        id={`slide-${activeSlide}`}
      >
        {image ? (
          <div className="relative w-full h-full">
            <img
              src={image}
              alt={image.title}
              className="w-full h-full rounded object-cover"
              key={image.image}
              id={type}
              onClick={() => setActiveSliderType(type.toLowerCase())}
            />
            <div className="absolute left-0 right-0 flex flex-row items-center mx-auto bg-white rounded-md bottom-4 w-[fit-content]">
              <button className="p-2 relative overflow-hidden">
                <ImageInput
                  onChange={(e) => handleUpdateBannerType(e, activeSlide, type)}
                  title="Click to upload"
                />
                <img
                  src={editIcon}
                  alt="edit icon"
                  className="w-[15.03px] h-[15.33px]"
                />
              </button>
            </div>
          </div>
        ) : (
          <div className="h-[171.2px] w-[171.2px] rounded-t-md relative flex-shrink-0 flex flex-col items-center justify-center border-t border-r border-l border-dashed">
            <ImageInput
              onChange={(e) => handleUpdateBannerType(e, activeSlide, type)}
              title="Click to upload"
            />
            <img src={upArrow} alt="" className="w-[41.67px] h-[41.36px]" />
            <button className="px-4 py-2 bg-white border border-[#E3E7ED] rounded-md text-[#4C536A] mt-3 font-medium">
              Upload Image
            </button>
            <p className="text-[#4C536A] text-[14px] mt-3">
              or drop file to upload
            </p>
          </div>
        )}
      </div>
      <div
        className={`bg-orange py-2 px-3 text-white rounded-b-md text-center  ${
          activeSliderType.toLowerCase() === type.toLowerCase() &&
          "outline-orange"
        }`}
      >
        {type}
      </div>
    </div>
  );
};
