import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EEF2F5',
  },
  colorSecondary: {
    backgroundColor: '#F3641F'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#F3641F',
  },
}))(LinearProgress);

const PaymentChannel = ({label, amount, percentage}) => {
  return (
    <div className="flex flex-col mb-4">
      <div className='flex flex-row items-center justify-between mb-1'>
        <p className="text-[#3D4356] text-[14px] font-medium">{label}</p>
        <span className="flex flex-row items-center">
          <p className="text-[#040A1D] text-[14px] font-medium">₦{Number(amount).toLocaleString('en')}</p>
          <p className="text-[#F3641F] text-[14px] font-medium ml-4 w-[45px] text-right">{amount === 0 ? 0 : percentage}%</p>
        </span>
      </div>
      <CustomLinearProgress 
        variant="determinate"
        value={percentage}
      />
    </div>
  )
}

export default PaymentChannel;