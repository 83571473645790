import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'
import { SentryRoute as Route } from '../sentry-config'
import { RootContext } from '..'

const AuthRoute = observer(({ children, ...restOfProps }) => {
  const root = useContext(RootContext)
  const isAuthenticated = Boolean(root.authStore.user)

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        !isAuthenticated ? children : <Redirect to="/home" />
      }
    />
  )
})

export default AuthRoute
