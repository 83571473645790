import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IoArrowUpOutline, IoArrowDownOutline } from "react-icons/io5";
import { toast } from "react-toastify";

//icons
import pen from "../../assets/pen.svg";

import productIcon from "../../assets/products-inactive.svg";

//mui
import { Button } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import ProductInfo from "../../components/ProductInfo/ProductInfo";
import TabButton from "../../components/TabButton/TabButton";
import Loader from "@components/Loader/Loader";

//utils
import { formatNumber } from "../../utils/formatNumber";

//services
import {
  getProduct,
  getOrderSummary,
  getAvailableStock,
  disableProduct,
  activateProduct,
} from "../../services/products";
import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@material-ui/icons";

const SingleProduct = () => {
  const { id } = useParams();
  const history = useHistory();

  const [busy, setBusy] = useState(true);
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState("Product Details");
  const [summary, setSummary] = useState({
    name: "string",
    uuid: "095be615-a8ad-4c33-8e9c-c7612fbf6c9f",
    total_order: 0,
    pending: 0,
    cancelled: 0,
    completed: 0,
  });
  const [stock, setStock] = useState();

  const fetchProduct = async () => {
    setBusy(true);
    try {
      const { data } = await getProduct(id);
      setProduct(data);
      setBusy(false);
    } catch (error) {
      toast.error("Unable to fetch requested product. Please try again later.");
      history.goBack();
    }
  };

  const addDefaultImage = (event) => {
    event.target.src = productIcon;
  };

  const fetchOrderSummary = async () => {
    try {
      const { data } = await getOrderSummary(id);
      setSummary(data);
    } catch (error) {
      toast.error("Unable to fetch order summary.");
    }
  };

  const fetchAvailableStock = async () => {
    try {
      const { data } = await getAvailableStock(id);
      const stock = computeQuantity(data.results);
      setStock(stock);
    } catch (error) {}
  };

  const computeQuantity = (data) => {
    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue.quantity;

    const total = data.reduce(reducer, 0);
    return total;
  };

  const handleProductStatus = async () => {
    try {
      toast.info("updating status....");
      if (product.is_active) {
        await disableProduct(product.uuid);
        toast.success("Product has been disabled successfully!");
      } else {
        await activateProduct(product.uuid);
        toast.success("Product has been enabled successfully!");
      }
      await fetchProduct();
    } catch (error) {
      console.log(error);
      toast.error("Error updating product product status!");
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchOrderSummary();
    fetchAvailableStock();
  }, []);

  return (
    <Layout>
      {busy ? (
        <Loader />
      ) : (
        <div className="px-[30px] py-5">
          <div className="flex items-center justify-between">
            <PageTitle
              onBackPress={() => history.goBack()}
              title={product.name}
            />

            <Button
              variant="contained"
              className="text-white capitalize shadow-none bg-orange"
              startIcon={<img src={pen} alt="" />}
              onClick={() => history.push(`/products/requests/edit/${id}`)}
            >
              Update Product Info
            </Button>
          </div>

          <div className="grid grid-cols-[1fr,4fr] gap-5">
            <div className="space-y-3">
              <div className="space-y-3">
                <img
                  src={product.image}
                  alt=""
                  className="w-full h-full"
                  onError={addDefaultImage}
                />

                <h1 className="font-bold text-[18px] text-center">
                  {product.name}
                </h1>
              </div>

              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  available stock
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">
                    {Number(stock).toLocaleString("en")}
                  </p>

                  {/* <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div> */}
                </div>
              </div>

              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  total orders
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">
                    {summary.total_order
                      ? Number(summary.total_order).toLocaleString("en")
                      : 0}
                  </p>

                  {/* <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div> */}
                </div>

                <div className="flex flex-wrap items-center py-2 ">
                  <div className="flex items-center mb-2 mr-2 rounded-xl bg-red/10 text-red">
                    <span className="inline-block  text-white rounded-full bg-red p-[4px] text-[12px] ">
                      {summary.cancelled ? formatNumber(summary.cancelled) : 0}
                    </span>
                    <p className="px-2 text-sm">Cancelled</p>
                  </div>

                  <div className="flex items-center mb-2 mr-2 rounded-xl bg-blue/10 text-blue">
                    <span className="inline-block text-white rounded-full bg-blue p-[4px] text-[12px]">
                      {summary.pending ? formatNumber(summary.pending) : 0}
                    </span>
                    <p className="px-2 text-sm">Pending</p>
                  </div>

                  <div className="flex items-center mb-2 mr-2 rounded-xl bg-green/10 text-green">
                    <span className="inline-block text-white rounded-full bg-green p-[4px] text-[12px]">
                      {summary.completed ? formatNumber(summary.completed) : 0}
                    </span>
                    <p className="px-2 text-sm">Completed</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center gap-1">
                <span className="text-xs text-[#8B8B9A]">Product Status</span>
                <div
                  className="flex items-center gap-3 text-sm border border-[#DEDEE4] bg-[#EFEFF2] p-3 rounded-lg"
                  id="store-status"
                >
                  <span className={`${product.is_active && "#28283E"}`}>
                    Activate
                  </span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={product.is_active}
                    onChange={handleProductStatus}
                  />
                  <span className={`${product.is_active && "#8B8B9A"}`}>
                    Deactivate
                  </span>
                </div>
                <p className="text-[10px] text-[#28283E] text-center bg-[#FFB919] rounded-md px-1 mt-1">
                  When you deactivate this product, customers will not see and
                  place orders for this product.
                </p>
              </div>

              {/* <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  today's revenue
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">₦3,559.13</p>

                  <div className="flex items-center text-red">
                    <IoArrowDownOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div>
                </div>
              </div>
              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  today's net profit
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">₦3,559.13</p>

                  <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="">
              <div className="px-3 space-x-3 bg-white border-b">
                <TabButton
                  activeTab={activeTab}
                  onClick={() => setActiveTab("Product Details")}
                  title="Product Details"
                />
              </div>

              <>
                {activeTab === "Product Details" && (
                  <ProductInfo product={product} />
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SingleProduct;
