const ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "k" },
];

export const formatNumber = (num) => {
  for (let i = 0; i < ranges.length; i++) {
    if (num >= ranges[i].divider) {
      return (num / ranges[i].divider).toString() + ranges[i].suffix;
    }
  }
  return num.toString();
};

export function numberFormat(value) {
  function _formatter(value, options) {
    const formatter = Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
      trailingZeroDisplay: 'stripIfInteger',
      ...options
    })
    return formatter.format(value)
  }

  return {
    toPercent(options={}) {
      return _formatter(value / 100, {
        style: 'percent',
        maximumFractionDigits: 2,
        ...options
      })
    },
    toCurrency(currency='NGN', options={}) {
      return _formatter(value, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        // notation: 'compact',
        // minimumSignificantDigits: 1,
        // maximumSignificantDigits: 5,
        roundingMode: 'ceil',
        ...options
      })
    },
    toDecimal(options) {
      return _formatter(value, options)
    },
  }
}
