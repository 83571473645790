import { useState, useEffect } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//icons
import searchIcon from "../../assets/search.svg";
import campaignIcon from "../../assets/campaign-inactive.svg";

//mui
import { Button } from "@material-ui/core";

//components
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/PageTitle/PageTitle";
import CampaignTable from "../../components/CampaignsTable/CampaignTable";
import Loader from "../../components/Loader/Loader";

//utils
import {
  deleteSingleCampaign,
  getCampaigns,
  searchCampaign,
} from "../../services/campaigns";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";

const Index = () => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [busy, setBusy] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [controller, setController] = useState(null);

  const [open, setOpen] = useState(false);
  let typingTimer;

  //search function
  const handleSearch = async () => {
    if (searchValue.length === 0) return;
    setBusy(true);
    try {
      const { data } = await searchCampaign(searchValue);

      if (data.count === 0) setNotFound(true);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      toast.error("An error occurred. Unable to fetch requested data.");
    }
  };

  const handleSearchKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      handleSearch();
    }, 500);
  };

  //fetch data
  const fetchCampaigns = async () => {
    controller?.abort?.();
    const abc = new AbortController();
    setController(abc);

    setBusy(true);

    try {
      const params = {
        search: searchValue,
        page_size: rowsPerPage,
        page: currentPage,
      };
      let data;

      data = (await getCampaigns(params, abc.signal)).data;

      setTotalCampaigns(data.count);
      setCampaigns(data.results);
      setBusy(false);
    } catch (error) {
      if (error.name !== "CanceledError") {
        toast.error("Unable to fetch campaigns. Please try again later.");
        setBusy(false);
      }
    }
  };

  //data update
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const openDelete = (post) => {
    setOpen(true);
    setDeleteId(post.uuid);
    setDeleteName(post.name);
  };

  const deleteCampaign = async () => {
    try {
      toast.info("Deleting campaign post...");
      await deleteSingleCampaign(deleteId);
      fetchCampaigns();
      toast.success("Campaign deleted successfully.");
      setOpen(false);
    } catch {
      toast.error("Failed to delete campaign.");
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [searchValue, currentPage, rowsPerPage]);

  return (
    <Layout>
      <div className="space-y-3 page-padding">
        <PageTitle title="Campaigns" />

        <div className="flex justify-between items-center">
          <div className="bg-white px-2 rounded-md flex items-center border border-[#E3E7ED] w-[450px]">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              placeholder="Search campaign by name"
              className="w-full px-2 py-2 text-sm bg-transparent border-0 focus:outline-none focus:ring-0"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              onKeyDown={handleSearchKeyDown}
              onKeyUp={handleSearchKeyUp}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            className="capitalize shadow-none"
            startIcon={<IoAddOutline />}
            onClick={() => history.push("/campaigns/add-new-campaign")}
          >
            Add New Campaign
          </Button>
        </div>

        {busy && searchValue.length === 0 ? (
          <Loader />
        ) : campaigns.length > 0 || searchValue.length > 0 ? (
          <div className="py-5 space-y-5">
            <CampaignTable
              rows={campaigns}
              editLink="/campaigns/{uuid}"
              viewLink="/campaigns/{uuid}"
              onDeleteClick={openDelete}
              busy={busy}
              notFound={notFound}
              search={searchValue}
              count={totalCampaigns}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onRowsPerPageChange={(rows) => {
                setRowsPerPage(rows);
                setCurrentPage(1);
              }}
              onPageChange={setCurrentPage}
            />
          </div>
        ) : (
          <div className="h-[350px] bg-white rounded-md flex flex-col items-center justify-center space-y-3 ">
            <img src={campaignIcon} alt="" className="w-16 h-16" />
            <p className="text-[18px] font-semibold text-gray1">
              Your campaigns will show here
            </p>

            <p className="text-sm text-gray2">
              This is where you add and update your campaigns
            </p>
            <Button
              variant="contained"
              color="primary"
              className="capitalize shadow-none"
              startIcon={<IoAddOutline />}
              onClick={() => history.push("/campaigns/add-new-campaign")}
            >
              Add New Campaign
            </Button>
          </div>
        )}
      </div>

      {/*delete a campaign modal*/}
      <ConfirmDeleteModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={deleteCampaign}
      >
        <p className="text-center max-w-[40ch] mx-auto">
          You're about to delete
          <br />
          <span className="text-orange font-medium">"{deleteName}"</span>
          <br />
          <br />
          This action is irreversible.
        </p>
      </ConfirmDeleteModal>
    </Layout>
  );
};
export default Index;
