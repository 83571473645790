import { useEffect, useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { IoClose, IoCloseCircle, IoArrowUp } from "react-icons/io5";

//assets
import loader from "../../assets/loader.svg";

//components
import FormInput from "../FormInput/FormInput";
import CustomSelect from "../CustomSelect/CustomSelect";

const EditCategoryModal = ({ open, closeModal, category, onSubmit, parents}) => {
  const [busy, setBusy] = useState(false)
  const [name, setName] = useState(category.category_name);
  const [parent, setParent] = useState(category.parent);
  const [desc, setDesc] = useState(category.description ? category.description : "");
  const [images, setImages] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    clearForm();
    closeModal();
  }

  const generateForm = () => {
    const form = new FormData();
    form.append("category_name", name);
    if(parent.id)
      form.append("parent", parent.id);
    form.append("description", desc);
    if(category.image !== images[0])
      form.append("image", images[0]);
    return form;
  }

  const handleSubmit = async() => {
    setBusy(true);
    const form = generateForm();
    await onSubmit(category.uuid, form);
    setBusy(false);
    clearForm();
    closeModal();
  }

  const checkForEmptyFields = () => {
    if(name.length > 0 && desc.length > 0 && images.length > 0)
      setDisabled(false);
  }

  const clearForm = () => {
    setName("");
    setParent("");
    setImages([]);
    setDesc("");
  }

  useEffect(() => {
    checkForEmptyFields();
  }, [name, desc, images, parent])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <div className="bg-white w-[606px] h-[800px] rounded">
        <div className="relative flex flex-row items-center justify-center py-4 border-b-[#E3E7ED] border-b">
          <h2 className="text-[#040A1D] text-[20px] font-bold m-0">Edit Blog Category</h2>
          <button
            className="absolute ml-10 top-4 right-10"
            onClick={closeModal}
          >
            <IoClose size={30} color={'#8892A2'}/>
          </button>
        </div>  
        <div className="p-10 px-14">
          <FormInput 
            id="name"
            name="name"
            placeholder="Add Category Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="h-10"></div>
          <CustomSelect 
            subCategory={true}
            name="parent"
            id="parent"
            placeholder="Select Parent if any"
            label="Parent"
            labelKey="category_name"
            value={parent ? parent : ""}
            options={parents}
            setValue={(item) => setParent(item)}
          />
          <div className="h-10"></div>
          <FormInput 
            id="description"
            name="description"
            label="Add Description"
            rows={4}
            type="textarea"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <h1 className="mt-8 font-medium text-[#3D4356]">Upload Category Image</h1>
          <div className="w-full bg-white">
            <div>
              {(images.length > 0 || category.image) && (
                <div className="">
                  <div className="relative border border-gray-300 border-dashed rounded-md">
                    <IoCloseCircle
                      color={"white"}
                      className="absolute cursor-pointer w-7 h-7 top-5 right-5"
                      onClick={() => {
                        setImages([]);
                      }}
                    />
                    <img
                      src={category.image ? category.image : URL.createObjectURL(images[0])}
                      alt=""
                      className="w-full h-[219px] rounded-md"
                    />
                  </div>
                </div>
              )}

              {(images.length === 0 && !category.image) && (
                <div className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52">
                  <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                    <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                  </div>
                  <label
                    htmlFor="product-category-images"
                    className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                  >
                    <p>Upload File</p>
                    <input
                      type="file"
                      id="product-category-images"
                      accept=".png,.jpeg,.jpg"
                      onChange={(e) => {
                        setImages([...e.target.files]);
                      }}
                      className="hidden"
                    />
                  </label>

                  <p className="text-sm text-gray2">or drop file to upload</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-end mt-8 space-x-4">
            <Button
              variant="outlined"
              className="capitalize"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={`text-white capitalize shadow-none relative ${
                disabled || busy
                  ? "bg-orange/30 cursor-not-allowed"
                  : "bg-orange cursor-pointer"
              }`}
            >
              Save
              {busy && (
                <img src={loader} className="absolute w-5 animate-spin" alt=""/> 
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditCategoryModal
