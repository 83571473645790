import React from 'react';

//icons
import logo from "../assets/compact-icon.svg";
import playStore from "../assets/play-store.svg";


const MobileView = () => {
  return (
    <div className="flex-col justify-center hidden h-[100vh] items-center bg-[#EEF2F5] tooSmall:flex w-full">
      <div className="bg-white h-full w-[400px] flex flex-col items-center justify-center">
        <img src={logo} alt="" className="w-[48px] h-[48px]"/>
        <h1 className="text-[24px] font-bold mt-2">Log In From Your Computer</h1>
        <p className="w-[70%] text-center text-[#666676] leading-[130%] text-[14px]">
          To access your dashboard, please log in from your computer
        </p>
        <a href="https://play.google.com/store/apps/details?id=ng.drinks.merchant_app&hl=en&gl=US"
          className="w-[fit-content] p-0 mt-[20px]"
        >
          <img src={playStore} className="" alt=""/>
        </a>
      </div>
    </div>
  ); 
}

export default MobileView