import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router-dom'
import { formatNumber } from '../../utils/formatNumber'
import { toast } from 'react-toastify'
import moment from 'moment'

//mui

//icons
// import pen from '../../assets/pen.svg'

//components
import Layout from '../../components/Layout/Layout'
import PageTitle from '../../components/PageTitle/PageTitle'
import ProductInfo from '../../components/ProductInfo/ProductInfo'
import TabButton from '../../components/TabButton/TabButton'
import OrderHistoryTable from '../../components/OrderHistoryTable/OrderHistoryTable'
import InventoryHistoryTable from '../../components/InventoryHistoryTable/CustomTable'
import RevenueGrowthChart from '../../components/RevenueGrowth/RevenueGrowth'
import Loader from '@components/Loader/Loader'

//services
import { getStockItem, updateStockItem } from '../../services/stock'
import { getProduct } from '../../services/products'

//utils
import { RootContext } from '../..'
import { getStockOrderSummary } from '../../services/orders'
import InventoryInput from '../../components/InventoryInput/InventoryInput'

const SingleProduct = observer(() => {
  const root = useContext(RootContext)
  const history = useHistory()
  const { product } = useParams()
  const deals = false

  const [busy, setBusy] = useState(false)
  const [loading, setLoading] = useState(true)

  const [activeTab, setActiveTab] = useState(
    deals ? 'Order History' : 'Product Details',
  )
  const [updateValue, setUpdateValue] = useState(0)
  const [updateMoq, setUpdateMoq] = useState(0)
  const [stockItem, setStockItem] = useState({})
  const [productInfo, setProductInfo] = useState(null)
  const [newPrice, setNewPrice] = useState()
  const [newDiscountPercentage, setNewDiscountPercentage] = useState(0)
  const [newInitialPrice, setNewInitialPrice] = useState()
  const [freeDelivery, setFreeDelivery] = useState()
  const [dateRange, setDateRange] = useState({
    start: moment(),
    end: moment().add(2, 'M'),
  })
  const [summary, setSummary] = useState({
    total_orders: {
      count: 0,
      amount: {
        amount: '0',
      },
    },
    pending: {
      count: 0,
      amount: {
        amount: '0',
      },
    },
    cancelled: {
      count: 0,
      amount: {
        amount: '0',
      },
    },
    completed: {
      count: 0,
      amount: {
        amount: '0',
      },
    },
  })

  const fetchStockItem = async () => {
    try {
      const { data } = await getStockItem(product).then(async (res) => {
        const { data } = await getProduct(res.data.product.uuid)
        setProductInfo(data)
        return res
      })
      data.initial_price = data.initial_price || {}
      await getSummary()
      setStockItem(data)
      setFreeDelivery(data.free_delivery)
      setLoading(false)
    } catch (error) {
      toast.error('Unable to fetch product. Please try again later.')
      history.goBack()
    }
  }

  const getSummary = async () => {
    try {
      const { data } = await getStockOrderSummary(
        root.authStore.user.retailerUuid,
        product,
      )
      setSummary(data)
    } catch (error) {
      toast.error('Unable to fetch inventory summary. Please try again later.')
    }
  }

  const handlePriceChange = (stateHook) => {
    return (event) => {
      if (
        event.target.value.length === 1 &&
        event.target.value.substring(0, 1) !== '₦'
      ) {
        stateHook(`₦${event.target.value}`)
        return
      }
      stateHook(`${event.target.value}`)
    }
  }

  const updateStock = async (formData, callback) => {
    setBusy(true)
    try {
      const { data } = await updateStockItem(stockItem.uuid, formData)

      updateInventory(data)
      callback()
      setBusy(false)
      toast.success('Your inventory has been updated.')
    } catch (error) {
      setBusy(false)
      toast.error('Unable to update inventory. Please try again later.')
    }
  }

  const updateInventory = (data) => {
    const item = { ...stockItem }
    item.quantity = data.quantity
    item.initial_price.amount = data.initial_price
    item.selling_price.amount = data.selling_price
    item.percentage = data.percentage
    item.is_discounted = data.is_discounted
    item.free_delivery = data.free_delivery
    item.moq = data.moq
    setStockItem(item)
  }

  useEffect(() => {
    fetchStockItem()
  }, [])

  // const increase = (hook, field) => {
  //   hook(field + 1)
  // }

  // const decrease = (hook, field, value) => {
  //   if (stockItem[value] + (field - 1) < 0) return
  //   hook(field - 1)
  // }

  const setQuantity = (value, hook) => {
    if (value === '') {
      hook('')
      return
    }
    if (value.substring(0, 1) === '0') {
      if (value.substring(1).length > 0) hook(parseInt(value.substring(1)))
      else hook('')
    } else hook(parseInt(value))
  }

  return (
    <Layout>
      {loading && updateValue === 0 && !newPrice && !newInitialPrice ? (
        <Loader />
      ) : (
        <div className="px-[30px] py-5">
          <div className="flex items-center justify-between">
            <PageTitle
              onBackPress={() => history.goBack()}
              title={stockItem.product.name}
            />
          </div>

          <div className="grid grid-cols-[1fr,4fr] gap-5">
            <div className="space-y-3">
              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  available stock
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">
                    {stockItem.quantity}
                  </p>

                  {/* <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div> */}
                </div>
              </div>

              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  today's orders
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">
                    {summary.total_orders.count}
                  </p>

                  {/* <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div> */}
                </div>

                <div className="flex flex-wrap items-center py-2 ">
                  <div className="flex items-center mb-2 mr-2 rounded-xl bg-red/10 text-red">
                    <span className="inline-block  text-white rounded-full bg-red p-[4px] text-[12px] ">
                      {formatNumber(summary.cancelled.count)}
                    </span>
                    <p className="px-2 text-sm">Cancelled</p>
                  </div>

                  <div className="flex items-center mb-2 mr-2 rounded-xl bg-blue/10 text-blue">
                    <span className="inline-block text-white rounded-full bg-blue p-[4px] text-[12px]">
                      {formatNumber(summary.pending.count)}
                    </span>
                    <p className="px-2 text-sm">Pending</p>
                  </div>

                  <div className="flex items-center mb-2 mr-2 rounded-xl bg-green/10 text-green">
                    <span className="inline-block text-white rounded-full bg-green p-[4px] text-[12px]">
                      {formatNumber(summary.completed.count)}
                    </span>
                    <p className="px-2 text-sm">Completed</p>
                  </div>
                </div>
              </div>

              <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  today's revenue
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">
                    ₦
                    {summary.total_orders.count === 0
                      ? 0
                      : Number(
                          summary.total_orders.amount.amount,
                        ).toLocaleString('en')}
                  </p>

                  {/* <div className="flex items-center text-red">
                    <IoArrowDownOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div> */}
                </div>
              </div>

              {/* <div className="px-3 py-2 bg-white rounded-md">
                <h1 className="text-[12px] text-gray3 capitalize">
                  today's net profit
                </h1>

                <div className="flex items-center justify-between">
                  <p className="text-[20px] font-semibold">₦3,559.13</p>

                  <div className="flex items-center text-green">
                    <IoArrowUpOutline className="w-3 h-3" />

                    <p className="text-sm font-medium">20%</p>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="">
              <div className="p-10 mb-5 space-y-10 bg-white rounded-md">
                <div className="flex items-center">
                  <div className="mr-10 space-y-3 w-60">
                    <img
                      src={stockItem.product.image}
                      alt=""
                      className="w-full h-full"
                    />
                  </div>

                  <div className="flex-grow space-y-5">
                    <div>
                      <h6 className="text-sm capitalize text-gray3">
                        Product Name
                      </h6>
                      <p className="font-bold text-[18px] text-gray1">
                        {stockItem.product.name} -{' '}
                        {stockItem.attribute && stockItem.attribute.name}
                      </p>
                    </div>

                    <InventoryInput
                      title="Initial Price"
                      subtitle="Update Initial Price"
                      field={stockItem.initial_price?.amount}
                      value={newInitialPrice}
                      hook={setNewInitialPrice}
                      busy={busy}
                      name="initial_price"
                      type="price"
                      handleChange={handlePriceChange}
                      handleSubmit={() =>
                        updateStock(
                          {
                            initial_price: newInitialPrice.substring(1),
                            percentage:
                              Number(newInitialPrice.substring(1)) <=
                              Number(stockItem.selling_price.amount)
                                ? 0
                                : (
                                    ((Number(newInitialPrice.substring(1)) -
                                      Number(stockItem.selling_price.amount)) /
                                      Number(newInitialPrice.substring(1))) *
                                    100
                                  ).toFixed(2),
                            is_discounted:
                              Number(newInitialPrice.substring(1)) >
                              Number(stockItem.selling_price.amount)
                                ? true
                                : false,
                          },
                          () => setNewInitialPrice(),
                        )
                      }
                      disabled={Boolean(!newInitialPrice)}
                    />

                    <InventoryInput
                      title="Current Price"
                      subtitle="Update Current Price"
                      field={stockItem.selling_price.amount}
                      value={newPrice}
                      hook={setNewPrice}
                      busy={busy}
                      name="selling_price"
                      type="price"
                      handleChange={handlePriceChange}
                      handleSubmit={() =>
                        updateStock(
                          {
                            selling_price: newPrice.substring(1),
                            percentage:
                              stockItem.initial_price === null ||
                              Number(newPrice.substring(1)) >=
                                Number(stockItem.initial_price.amount)
                                ? 0
                                : (
                                    ((Number(stockItem.initial_price.amount) -
                                      Number(newPrice.substring(1))) /
                                      Number(stockItem.initial_price.amount)) *
                                    100
                                  ).toFixed(2),
                            is_discounted:
                              Number(newPrice.substring(1)) <
                                Number(stockItem.selling_price.amount) &&
                              stockItem.initial_price !== null
                                ? true
                                : false,
                          },
                          () => setNewPrice(),
                        )
                      }
                      disabled={Boolean(!newPrice)}
                    />

                    <InventoryInput
                      title="Available Stock"
                      subtitle="Update Stock"
                      field={stockItem.quantity}
                      value={updateValue}
                      hook={setUpdateValue}
                      busy={busy}
                      min={-stockItem.quantity}
                      // name="quantity"
                      type="quantity"
                      // handleIncrease={increase}
                      // handleDecrease={decrease}
                      handleChange={setQuantity}
                      handleSubmit={() =>
                        updateStock(
                          { quantity: updateValue + stockItem.quantity },
                          () => {
                            setUpdateValue(0)
                          },
                        )
                      }
                      disabled={updateValue === 0}
                    />

                    <InventoryInput
                      title="Discount (%)"
                      subtitle="Update Discount (%)"
                      field={stockItem.percentage}
                      value={newDiscountPercentage}
                      hook={setNewDiscountPercentage}
                      busy={busy}
                      type="percentage"
                      handleSubmit={() =>
                        updateStock(
                          {
                            initial_price: (
                              Number(stockItem.selling_price.amount) /
                              (1 - Number(newDiscountPercentage) / 100)
                            ).toFixed(2),
                            percentage: newDiscountPercentage,
                            is_discounted:
                              newDiscountPercentage > 0 ? true : false,
                          },
                          () => {
                            setNewDiscountPercentage(0)
                          },
                        )
                      }
                      disabled={
                        Boolean(newDiscountPercentage <= 0) ||
                        Number(newDiscountPercentage) ===
                          Number(stockItem.percentage)
                      }
                    />

                    <InventoryInput
                      title="MOQ"
                      subtitle="Update MOQ"
                      field={stockItem.moq}
                      value={updateMoq}
                      hook={setUpdateMoq}
                      busy={busy}
                      min={-stockItem.moq + 1}
                      max={stockItem.quantity - stockItem.moq}
                      name="moq"
                      type="quantity"
                      // handleIncrease={increase}
                      // handleDecrease={decrease}
                      handleChange={setQuantity}
                      handleSubmit={() =>
                        updateStock({ moq: updateMoq + stockItem.moq }, () => {
                          setUpdateMoq(0)
                        })
                      }
                      disabled={updateMoq === 0}
                    />

                    <InventoryInput
                      title="Free Delivery"
                      subtitle="Update Free Delivery"
                      value={freeDelivery}
                      hook={setFreeDelivery}
                      busy={busy}
                      type="delivery"
                      handleSubmit={() =>
                        updateStock({ free_delivery: freeDelivery }, () => {})
                      }
                      disabled={freeDelivery === stockItem.free_delivery}
                    />
                  </div>
                </div>

                {deals && <div className="p-10 border-t">deals/promo</div>}
              </div>

              <div className="px-3 space-x-3 bg-white border-b rounded-md">
                {!deals && (
                  <TabButton
                    activeTab={activeTab}
                    onClick={() => setActiveTab('Product Details')}
                    title="Product Details"
                  />
                )}

                {/* <TabButton
                  activeTab={activeTab}
                  onClick={() => setActiveTab("Order History")}
                  title="Order History"
                /> */}

                <TabButton
                  activeTab={activeTab}
                  onClick={() => setActiveTab('Inventory History')}
                  title="Inventory History"
                />

                {/* <TabButton
                  activeTab={activeTab}
                  onClick={() => setActiveTab("Revenue Growth")}
                  title="Revenue Growth"
                /> */}
              </div>

              <>
                {!deals && activeTab === 'Product Details' && (
                  <ProductInfo stock={stockItem} product={productInfo} />
                )}
                {activeTab === 'Order History' && <OrderHistoryTable />}
                {activeTab === 'Inventory History' && (
                  <InventoryHistoryTable product={product} />
                )}
                {activeTab === 'Revenue Growth' && (
                  <RevenueGrowthChart
                    title="Product Growth"
                    dateRange={dateRange}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
})

export default SingleProduct
