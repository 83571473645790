import React from "react";

const CouponSummaryCard = ({label, amount, accentColor}) => {
  return (
    <div className="flex flex-col relative w-full h-[110px] px-[20px] pt-[16px] rounded-lg bg-white">
      <span
        className={`absolute left-0 top-[4px] ${accentColor ? `bg-[${accentColor}] rounded-md` : 'bg-white rounded-md'} h-[95%] w-1 mr-5 lg:mr-2`}></span>
      <div className="flex flex-col w-full">
        <p className="text-[16px] text-[#4C536A] font-medium m-0">{label}</p>
        <p className="text-[#040A1D] text-[26px] font-semibold">
          ₦{amount ? Number(amount).toLocaleString('en') : 0}
        </p>
      </div>
    </div>
  )
}

export default CouponSummaryCard;