import React from 'react'

import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core'

const headCells = [
  { id: 'code', label: 'code' },
  { id: 'order', label: 'order' },
  { id: 'customer', label: 'customer' },
  { id: 'issued', label: 'issued date' },
  { id: 'status', label: 'status' },
]

const CustomTableHead = ({ onSelectAllClick, numSelected, rowCount }) => {
  return (
    <TableHead className="bg-transparent">
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all vouchers' }}
            color="primary"
            className={`${
              numSelected > 0 && numSelected < rowCount && 'text-orange'
            }`}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className="uppercase text-[12px]">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default CustomTableHead
