import React, { useEffect, useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { IoClose  } from "react-icons/io5";

//assets
import loader from "../../assets/loader.svg";

//components
import FormInput from "../FormInput/FormInput";

const CreateTagModal = ({open, closeModal, onSubmit}) => {
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    clearForm();
    closeModal();
  }

  const generateForm = () => {
    const form ={
      "tag_name": name
    }
    return form;
  }

  const handleSubmit = async() => {
    setBusy(true);
    const form = generateForm();
    await onSubmit(form);
    setBusy(false);
    clearForm();
  }

  const checkForEmptyFields = () => {
    if(name.length > 0)
      setDisabled(false);
  }

  const clearForm = () => {
    setName("");
  }

  useEffect(() => {
    checkForEmptyFields();
  }, [name])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <div className="bg-white w-[606px] h-[300px] rounded">
        <div className="relative flex flex-row items-center justify-center py-4 border-b-[#E3E7ED] border-b">
          <h2 className="text-[#040A1D] text-[20px] font-bold m-0">Create a New Blog Tag</h2>
          <button
            className="absolute ml-10 top-4 right-10"
            onClick={closeModal}
          >
            <IoClose size={30} color={'#8892A2'}/>
          </button>
        </div>  
        <div className="p-10 px-14">
          <FormInput 
            id="name"
            name="name"
            placeholder="Add Category Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="h-10"></div>
          <div className="flex flex-row items-center justify-end mt-8 space-x-4">
            <Button
              variant="outlined"
              className="capitalize"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={`text-white capitalize shadow-none relative ${
                disabled || busy
                  ? "bg-orange/30 cursor-not-allowed"
                  : "bg-orange cursor-pointer"
              }`}
            >
              Save Tag
              {busy && (
                <img src={loader} className="absolute w-5 animate-spin" alt=""/> 
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateTagModal;